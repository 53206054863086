import { gql } from '@apollo/client';

export const SET_STUDENT_GROUP_CAPACITY = gql`
  mutation setStudentGroupCapacity($studentGroupId: String!, $capacity: Int) {
    setStudentGroupCapacity(input: { studentGroupId: $studentGroupId, capacity: $capacity }) {
      mutationStatus
      configuredCapacity
      studentGroupId
    }
  }
`;
