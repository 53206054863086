import { useQuery } from '@apollo/client';
import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { EmptyStateDataDisplay, Spinner } from '@uva-glass/component-library';

import type { Row } from '@tanstack/react-table';
import type {
  GetCourseOfferingTimetableActivitiesQuery,
  GetCourseOfferingTimetableActivitiesQueryVariables,
} from 'types/__generated__';

import { GET_COURSE_OFFERING_TIMETABLE_ACTIVITIES } from 'graphql/queries/getCourseOfferingTimetableActivities';
import { useGetCourseOffering } from 'hooks/useGetCourseOffering';
import { DataTable } from 'App/shared/DataTable/DataTable';
import { TABLE_STORAGE_KEYS } from 'util/storagekeys';

type RowData = GetCourseOfferingTimetableActivitiesQuery['courseOfferingTimetableActivities'][number];

const columnHelper = createColumnHelper<RowData>();

const initialState = {
  sorting: [{ id: 'name', desc: false }],
};

export function CourseOfferingTimetable() {
  const { t } = useTranslation('course-offering', { keyPrefix: 'course-offering-timetable' });
  const { marblesCourseOffering } = useGetCourseOffering();
  const { id: courseOfferingId } = marblesCourseOffering;
  const query = useQuery<GetCourseOfferingTimetableActivitiesQuery, GetCourseOfferingTimetableActivitiesQueryVariables>(
    GET_COURSE_OFFERING_TIMETABLE_ACTIVITIES,
    { variables: { courseOfferingId } }
  );

  const columns = [
    columnHelper.accessor('name', {
      header: t('headers.name'),
      meta: { tableCellProps: { noWrap: true } },
    }),

    columnHelper.accessor('description', {
      header: t('headers.description'),
      meta: { tableCellProps: { noWrap: true } },
    }),

    columnHelper.accessor(({ studentGroups }) => studentGroups.map(({ name }) => name).join(', '), {
      id: 'studentGroups',
      header: t('headers.studentGroups'),
    }),

    columnHelper.accessor(({ weeks }) => weeks.join(', '), {
      id: 'weeks',
      header: t('headers.weeks'),
      filterFn: (row: Row<RowData>, _, filterValue) => row.original.weeks.includes(Number.parseInt(filterValue, 10)),
    }),

    columnHelper.accessor('dayOfWeek', {
      id: 'dayOfWeek',
      header: t('headers.dayOfWeek'),
      cell: ({ cell }) => t(`values.${cell.getValue()}`),
      enableColumnFilter: false,
    }),

    columnHelper.accessor('startTime', {
      header: t('headers.startTime'),
      enableColumnFilter: false,
    }),

    columnHelper.accessor('endTime', {
      header: t('headers.endTime'),
      enableColumnFilter: false,
    }),

    columnHelper.accessor(({ locations }) => locations.join(', '), {
      id: 'locations',
      header: t('headers.locations'),
    }),

    columnHelper.accessor('isPublishedForStudents', {
      id: 'isPublishedForStudents',
      header: t('headers.published'),
      cell: ({ cell }) => (cell.getValue() ? t('values.published') : t('values.not-published')),
      enableColumnFilter: false,
    }),
  ];

  if (query.loading) {
    return <Spinner ariaValueText={''} />;
  }
  if (query.error || !query.data) {
    return <>Error</>;
  }

  return query.data.courseOfferingTimetableActivities.length ? (
    <DataTable
      data={query.data.courseOfferingTimetableActivities}
      columns={columns}
      initialState={initialState}
      tableId={TABLE_STORAGE_KEYS.COURSE_OFFERING_TIMETABLE}
    />
  ) : (
    <EmptyStateDataDisplay label={t('empty-state.label')} paragraph={t('empty-state.paragraph')} />
  );
}
