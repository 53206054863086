import { gql } from '@apollo/client';

export const SET_COURSE_OFFERING_CREDIT_LIMIT_EXCEPTION = gql`
  mutation setCourseOfferingCreditLimit($courseOfferingId: String!, $hasCreditLimitException: Boolean!) {
    setCourseOfferingCreditLimitException(
      input: { courseOfferingId: $courseOfferingId, hasCreditLimitException: $hasCreditLimitException }
    ) {
      mutationStatus
      courseOfferingId
      hasCreditLimitException
    }
  }
`;
