import { gql } from '@apollo/client';

import { SUBPLAN_FRAGMENT } from './subplanFragment';

export const PLAN_FRAGMENT = gql`
  fragment plan on Plan {
    id
    code
    description
    subplans {
      ...subplan
    }
  }
  ${SUBPLAN_FRAGMENT}
`;
