import { useMutation } from '@apollo/client';
import { useOverlayTriggerState } from '@react-stately/overlays';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button, IconButton, Icon, FeedbackBox, ModalDialog, ButtonGroup } from '@uva-glass/component-library';

import type { FormEvent } from 'react';
import type {
  RemoveRequiredCoursesMutation,
  RemoveRequiredCoursesMutationVariables,
  RequiredCourses,
} from 'types/__generated__';

import { REMOVE_REQUIRED_COURSES } from 'graphql/mutations/removeRequiredCourses';
import { useCurrentLanguage } from 'hooks/useCurrentLanguage';
import { useGetReadableMutationStatus } from 'hooks/useGetReadableMutationStatus';
import { MutationStatus } from 'types/__generated__';

interface Props {
  requirement: RequiredCourses;
  ruleId: string;
}

export function RemoveRequiredCourses({ requirement, ruleId }: Props) {
  const { t } = useTranslation('requirement-rules', { keyPrefix: 'remove-required-courses' });
  const currentLanguage = useCurrentLanguage();

  const [error, setError] = useState<string>();
  const readableMutationStatus = useGetReadableMutationStatus();
  const { open, close, isOpen } = useOverlayTriggerState({});

  const [mutate, mutation] = useMutation<RemoveRequiredCoursesMutation, RemoveRequiredCoursesMutationVariables>(
    REMOVE_REQUIRED_COURSES
  );

  function onClose() {
    setError(undefined);
    close();
  }

  function onSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    mutate({
      variables: { ruleId, nodeId: requirement.id },
      onCompleted(data) {
        const { mutationStatus } = data.removeRequiredCourses;

        if (mutationStatus !== MutationStatus.Success) {
          setError(readableMutationStatus(mutationStatus));
        }
      },
      onError() {
        setError(readableMutationStatus('unknown'));
      },
    });
  }

  return (
    <>
      <IconButton aria-label={t('trigger')} variant="destructive" onClick={open}>
        <Icon name="Trash" />
      </IconButton>

      {isOpen && (
        <ModalDialog
          title={t('modal.title')}
          isOpen={isOpen}
          onClose={onClose}
          onSubmit={onSubmit}
          buttons={
            <ButtonGroup>
              <Button onClick={onClose} variant="secondary">
                {t('modal.buttons.cancel')}
              </Button>
              <Button variant="destructive-primary" disabled={mutation.loading} type="submit">
                {t('modal.buttons.remove')}
              </Button>
            </ButtonGroup>
          }
        >
          <p>
            <Trans
              t={t}
              i18nKey="modal.description"
              values={{ name: requirement.description[currentLanguage] }}
              components={{ strong: <strong /> }}
            />
          </p>
          {error && <FeedbackBox level="error" feedback={error} />}
        </ModalDialog>
      )}
    </>
  );
}
