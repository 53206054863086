import { useMutation } from '@apollo/client';

import type { StoreObject } from '@apollo/client';
import type { SetTopicForStudentGroupMutation, SetTopicForStudentGroupMutationVariables } from 'types/__generated__';

import { MutationStatus } from 'types/__generated__';
import { SET_STUDENT_GROUP_TOPIC } from 'graphql/mutations/setStudentGroupTopic';

export const useSetStudentGroupTopic = () =>
  useMutation<SetTopicForStudentGroupMutation, SetTopicForStudentGroupMutationVariables>(SET_STUDENT_GROUP_TOPIC, {
    update(cache, { data }) {
      if (data?.setStudentGroupTopic?.mutationStatus !== MutationStatus.Success) return;

      const { studentGroupId, topic } = data.setStudentGroupTopic;
      const studentGroup: StoreObject = { __typename: 'StudentGroup', id: studentGroupId };

      cache.modify({
        id: cache.identify(studentGroup),
        fields: {
          topic() {
            return topic ?? null;
          },
        },
      });
    },
  });
