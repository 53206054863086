import { useMutation } from '@apollo/client';

import type { StoreObject } from '@apollo/client';
import type {
  SetCreditLimitExceptionByStudentIdMutation,
  SetCreditLimitExceptionByStudentIdMutationVariables,
} from 'types/__generated__';

import { SET_CREDIT_LIMIT_EXCEPTION_BY_STUDENT_ID } from 'graphql/mutations/setCreditLimitExceptionByStudentId';
import { GET_STUDENT_BY_STUDENT_ID } from 'graphql/queries/getStudentByStudentId';
import { MutationStatus } from 'types/__generated__';

export const useSetCreditLimitExceptionByStudentId = () =>
  useMutation<SetCreditLimitExceptionByStudentIdMutation, SetCreditLimitExceptionByStudentIdMutationVariables>(
    SET_CREDIT_LIMIT_EXCEPTION_BY_STUDENT_ID,
    {
      update(cache, { data }) {
        if (data?.setCreditLimitException.mutationStatus !== MutationStatus.Success) return;

        const { studentId, hasCreditLimitException } = data.setCreditLimitException;
        const student: StoreObject = { __typename: 'Student', id: studentId };

        cache.modify({
          id: cache.identify(student),
          fields: {
            hasCreditLimitException() {
              return hasCreditLimitException;
            },
          },
        });
      },
      refetchQueries({ data }) {
        const mutationStatus = data?.setCreditLimitException.mutationStatus;

        if (mutationStatus === MutationStatus.Success) return [];

        return [GET_STUDENT_BY_STUDENT_ID];
      },
    }
  );
