import { CreditLimitException } from './CreditLimitException/CreditLimitException';
import { GroupSwap } from './GroupSwap/GroupSwap';
import { Status } from './Status/Status';

import { CardCollection } from 'components/CardCollection/CardCollection';

export const CourseOfferingSettings = () => (
  <CardCollection>
    <Status />
    <GroupSwap />
    <CreditLimitException />
  </CardCollection>
);
