import { gql } from '@apollo/client';

import { RULE_FRAGMENT } from 'graphql/fragments/ruleFragment';

export const REMOVE_INFO_REQUIREMENT = gql`
  mutation removeInfoRequirement($ruleId: String!, $nodeId: String!) {
    removeInfoRequirement(input: { ruleId: $ruleId, nodeId: $nodeId }) {
      nodeId
      mutationStatus
      rule {
        ...rule
      }
    }
  }
  ${RULE_FRAGMENT}
`;
