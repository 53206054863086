import { Navigate, generatePath, useLocation, useParams } from 'react-router-dom';

import type { PropsWithChildren } from 'react';
import type { MarblesAcademicYear, MarblesDepartment } from 'types/__generated__';

import { usePersistCourseOfferingsFilter } from 'components/AcademicYearAndDepartmentSelector/hooks/usePersistCourseOfferingsFilter';
import { useFilters } from 'providers/FiltersProvider';
import { COURSE_OFFERINGS, COURSE_REGISTRATION, UNAUTHORISED } from 'routes';
import { UserAction } from 'types/__generated__';
import { getExpectedFilterParams } from 'util/getExpectedFilterParams';

const academicYearParamName = 'academicYear';
const departmentIdParamName = 'departmentId';

export function AcademicYearAndDepartmentRouteProvider({ children }: PropsWithChildren) {
  const { academicYears, departments, courseOfferingsFilter } = useFilters();
  const { persistAcademicYear, persistDepartmentId } = usePersistCourseOfferingsFilter();
  const { academicYear, departmentId } = useParams();
  const location = useLocation();

  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  const leadingPathSegment = location.pathname.split('/').slice(0, 3).join('/');

  const expectedFilterParams = getExpectedFilterParams(leadingPathSegment);
  const expectsAcademicYearParam = expectedFilterParams?.includes(academicYearParamName);
  const expectsDepartmentIdParam = expectedFilterParams?.includes(departmentIdParamName);

  const isValidAcademicYearParam =
    expectsAcademicYearParam && academicYear && academicYears.map(({ id }) => id).includes(Number(academicYear));
  const isValidDepartmentIdParam =
    expectsDepartmentIdParam &&
    departmentId &&
    departments
      .map(({ id, allowedUserActions }) => allowedUserActions.includes(UserAction.View) && id)
      .includes(departmentId);

  // return children if url is already complete with valid academicYear and/or departmentId params
  if (
    academicYear &&
    isValidAcademicYearParam &&
    (!expectsDepartmentIdParam || (departmentId && isValidDepartmentIdParam))
  ) {
    persistAcademicYear({ academicYear: Number(academicYear) });
    if (departmentId && isValidDepartmentIdParam) persistDepartmentId({ departmentId: departmentId });

    return <>{children}</>;
  }

  const courseOfferingsPath =
    leadingPathSegment === COURSE_REGISTRATION
      ? `${leadingPathSegment}/${COURSE_OFFERINGS}`
      : `${leadingPathSegment}/${expectedFilterParams?.map((param) => `:${param}`).join('/')}`;

  const isValidFilteredAcademicYear =
    expectsAcademicYearParam &&
    courseOfferingsFilter.academicYear &&
    academicYears.map(({ id }) => id).includes(courseOfferingsFilter.academicYear);
  const isValidFilteredDepartmentId =
    expectsDepartmentIdParam &&
    courseOfferingsFilter.departmentId &&
    departments
      .map(({ id, allowedUserActions }) => allowedUserActions.includes(UserAction.View) && id)
      .includes(courseOfferingsFilter.departmentId);

  // route to current filter stored academicYear and departmentId if invalid or no params are passed but filter values are valid
  if (
    courseOfferingsFilter.academicYear &&
    isValidFilteredAcademicYear &&
    (!expectsDepartmentIdParam || (courseOfferingsFilter.departmentId && isValidFilteredDepartmentId))
  ) {
    const filterParamsPathname = generatePath(courseOfferingsPath, {
      academicYear: courseOfferingsFilter.academicYear,
      departmentId: expectsDepartmentIdParam ? courseOfferingsFilter.departmentId : undefined,
    });

    return <Navigate to={{ pathname: filterParamsPathname, search: location.search }} replace />;
  }

  const currentAcademicYear = academicYears.find(
    ({ isCurrentAcademicYearForAdmins }) => isCurrentAcademicYearForAdmins
  ) as MarblesAcademicYear;
  const firstViewableDepartment = departments.find(
    ({ id, allowedUserActions }) => id !== 'oh:department:UvA' && allowedUserActions.includes(UserAction.View)
  ) as MarblesDepartment;

  if (!firstViewableDepartment) {
    return <Navigate to={UNAUTHORISED} />;
  }

  // route to current active academicYear and first viewable departmentId if invalid or non viewable params are passed
  const defaultParamsPathname = generatePath(courseOfferingsPath, {
    academicYear: `${currentAcademicYear.id}`,
    departmentId: firstViewableDepartment.id,
  });

  persistAcademicYear({ academicYear: currentAcademicYear.id });
  persistDepartmentId({ departmentId: firstViewableDepartment.id });

  return <Navigate to={{ pathname: defaultParamsPathname, search: location.search }} replace />;
}
