import { gql } from '@apollo/client';

import { PARTIAL_RULE_FRAGMENT } from 'graphql/fragments/partialRuleFragment';

export const CREATE_RULE_REQUIRED_CAREER = gql`
  mutation createRuleRequiredCareer(
    $departmentId: String!
    $academicYear: Int!
    $name: String!
    $careerIds: [String!]!
  ) {
    createRuleRequiredCareer(
      input: { departmentId: $departmentId, academicYear: $academicYear, name: $name, careerIds: $careerIds }
    ) {
      mutationStatus
      academicYear
      rule {
        ...partialRule
      }
    }
  }
  ${PARTIAL_RULE_FRAGMENT}
`;
