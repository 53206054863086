import { gql } from '@apollo/client';

export const SET_COURSE_OFFERING_STATUS = gql`
  mutation setCourseOfferingStatus($courseOfferingId: String!, $status: MarblesCourseOfferingStatus!) {
    setCourseOfferingStatus(input: { courseOfferingId: $courseOfferingId, status: $status }) {
      mutationStatus
      courseOfferingId
      status
    }
  }
`;
