import { useOverlayTriggerState } from '@react-stately/overlays';
import {
  Button,
  FeedbackBox,
  Icon,
  Label,
  ModalDialog,
  Spinner,
  Checkbox,
  ButtonGroup,
  FormField,
} from '@uva-glass/component-library';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { ChangeEvent, FormEvent } from 'react';
import type { UploadCoursePendingStudentsMutation } from 'types/__generated__';

import { useUploadPendingCourseOfferings } from './hooks/useUploadPendingCourseOfferings';

import { UploadResults } from 'App/shared/UploadResults/UploadResults';
import { UploadRowErrors } from 'App/shared/UploadRowErrors/UploadRowErrors';
import { Input } from 'components/Input/Input';
import { useCurrentLanguage } from 'hooks/useCurrentLanguage';
import { useGetCourseOffering } from 'hooks/useGetCourseOffering';
import { useGetReadableMissingColumn } from 'hooks/useGetReadableMissingColumns';
import { useGetReadableMutationStatus } from 'hooks/useGetReadableMutationStatus';
import { MutationStatus } from 'types/__generated__';
import { ACCEPTED_FILE_TYPES } from 'util/acceptedFileTypes';

const FILE_UPLOAD_ID = 'file-upload';
const FILE_UPLOAD_ERROR_ID = 'file-upload-error';

type UploadCoursePendingStudentsMutationResult =
  UploadCoursePendingStudentsMutation['uploadCoursePendingStudents']['result'];
type UploadCoursePendingStudentsMissingColumns =
  UploadCoursePendingStudentsMutation['uploadCoursePendingStudents']['missingColumns'];

export function UploadCoursePendingStudentsModal() {
  const { t } = useTranslation('course-offering', { keyPrefix: 'import-pending-course-offerings' });
  const currentLanguage = useCurrentLanguage();
  const { close, isOpen, open } = useOverlayTriggerState({});
  const readableMutationStatus = useGetReadableMutationStatus();
  const readableMissingColumn = useGetReadableMissingColumn();
  const { marblesCourseOffering } = useGetCourseOffering();
  const { id: courseOfferingId, info } = marblesCourseOffering;
  const academicYear = marblesCourseOffering.academicYear.id;

  const fileInputRef = useRef<HTMLInputElement>(null);

  const [mutate, mutation] = useUploadPendingCourseOfferings(academicYear);
  const [error, setError] = useState('');
  const [file, setFile] = useState<File>();
  const [switchTopicIfAlreadyPending, setSwitchTopicIfAlreadyPending] = useState(false);
  const [skipRequirements, setSkipRequirements] = useState(true);
  const [results, setResults] = useState<UploadCoursePendingStudentsMutationResult>([]);
  const [currentMissingColumns, setCurrentMissingColumns] = useState<UploadCoursePendingStudentsMissingColumns>(null);

  const errorRows = results.filter(({ rowStatus }) => rowStatus !== MutationStatus.Success);
  const successRowCount = results.length - errorRows.length;

  function reset() {
    setError('');
    setResults([]);
    setCurrentMissingColumns(null);
    setSwitchTopicIfAlreadyPending(false);
    setSkipRequirements(true);

    mutation.reset();
  }

  function resetAndClose() {
    reset();
    close();
  }

  function onChange({ target }: ChangeEvent<HTMLInputElement>) {
    setError('');
    const { files } = target;

    if (!files) return;

    const file = files[0];

    if (!ACCEPTED_FILE_TYPES.includes(file.type)) {
      setError(t('modal.errors.unsupported-type'));
      if (fileInputRef.current) {
        // reset the value of the <input type="file">
        fileInputRef.current.value = '';
      }
      return;
    }

    setFile(file);
  }

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (!file) {
      setError(t('modal.errors.file-missing'));
      return;
    }

    const { data } = await mutate({
      variables: { courseOfferingId, file, switchTopicIfAlreadyPending, skipRequirements },
    });

    if (!data?.uploadCoursePendingStudents) {
      setError(readableMutationStatus('unknown'));
      return;
    }

    const { mutationStatus, result, missingColumns } = data.uploadCoursePendingStudents;

    if (mutationStatus === MutationStatus.Success) {
      setResults(result);
      return;
    }

    if (mutationStatus === MutationStatus.UploadMissingColumns) {
      setCurrentMissingColumns(missingColumns);
      setError(readableMutationStatus(mutationStatus, { details: missingColumns ?? undefined }));
      return;
    }

    setError(readableMutationStatus(mutationStatus));
  }

  return (
    <>
      <Button onClick={open} variant="secondary">
        <Icon name="ArrowDownOnSquare" size={20} />
        {t('trigger')}
      </Button>

      <ModalDialog
        isDismissable={false}
        isOpen={isOpen}
        noValidate={true}
        onClose={resetAndClose}
        onSubmit={handleSubmit}
        title={t('modal.title')}
        buttons={
          <>
            {!mutation.called && (
              <ButtonGroup reversed>
                <Button variant="primary" type="submit" disabled={mutation.loading}>
                  {t('modal.buttons.import')}
                </Button>

                <Button onClick={resetAndClose} variant="secondary">
                  {t('modal.buttons.cancel')}
                </Button>
              </ButtonGroup>
            )}

            {mutation.called && !mutation.loading && (
              <ButtonGroup reversed>
                <Button onClick={resetAndClose} variant="primary">
                  {t('modal.buttons.confirm')}
                </Button>
                <Button onClick={reset} variant="secondary">
                  {t('modal.buttons.reset-and-retry')}
                </Button>
              </ButtonGroup>
            )}
          </>
        }
        wide
      >
        {!mutation.called && (
          <>
            {info && (
              <p>
                {info.title[currentLanguage]} ({info.catalogNumber})
              </p>
            )}
            <p>
              {marblesCourseOffering.topicSet !== null ? t('modal.description') : t('modal.description-no-topicset')}
            </p>

            <FormField outerSpace>
              <Label htmlFor={FILE_UPLOAD_ID}>{t('modal.choose-file')}</Label>
              <Input
                id={FILE_UPLOAD_ID}
                type="file"
                accept={ACCEPTED_FILE_TYPES.join(',')}
                onChange={onChange}
                aria-describedby={error ? FILE_UPLOAD_ERROR_ID : undefined}
                ref={fileInputRef}
              />
            </FormField>

            {error && <FeedbackBox id={FILE_UPLOAD_ERROR_ID} level="error" feedback={error} />}

            {marblesCourseOffering.topicSet !== null && (
              <FormField outerSpace inline>
                <Checkbox
                  checked={switchTopicIfAlreadyPending}
                  id="switchTopicIfAlreadyPending"
                  name="switchTopicIfAlreadyPending"
                  onChange={() => {
                    setSwitchTopicIfAlreadyPending(!switchTopicIfAlreadyPending);
                  }}
                />
                <Label htmlFor="switchTopicIfAlreadyPending">{t('modal.overwrite-existing-topic')}</Label>
              </FormField>
            )}

            <FormField outerSpace inline>
              <Checkbox
                checked={skipRequirements}
                id="skipRequirements"
                name="skipRequirements"
                onChange={() => {
                  setSkipRequirements(!skipRequirements);
                }}
              />
              <Label htmlFor="skipRequirements">{t('modal.ignore-requirements')}</Label>
            </FormField>
          </>
        )}

        {mutation.called && mutation.loading && <Spinner ariaValueText={t('results-modal.loading')} />}

        {mutation.called &&
          !mutation.loading &&
          (results.length > 0 ? (
            <UploadResults>
              {successRowCount > 0 && (
                <FeedbackBox level="success" feedback={t('results-modal.success', { count: successRowCount })} />
              )}
              {errorRows.length > 0 && (
                <>
                  <FeedbackBox level="error" feedback={t('results-modal.failure', { count: errorRows.length })} />
                  <UploadRowErrors errorRows={errorRows} />
                </>
              )}
            </UploadResults>
          ) : (
            <>
              {error && <FeedbackBox level="error" feedback={error} />}
              {currentMissingColumns ? (
                <ul>
                  {currentMissingColumns.map((column, index) => (
                    <li key={index}>{readableMissingColumn(column)}</li>
                  ))}
                </ul>
              ) : (
                <p>{t('results-modal.nothing-imported')}</p>
              )}
            </>
          ))}
      </ModalDialog>
    </>
  );
}
