import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import type { DateTimeFormatOptions } from 'luxon';
import type { StatusIndicatorProps } from 'components/StatusIndicator/StatusIndicator';
import type { GetStudentByStudentIdQuery, Plan } from 'types/__generated__';

import { DataTable } from 'App/shared/DataTable/DataTable';
import { useCurrentLanguage } from 'hooks/useCurrentLanguage';
import { useFormattedDate } from 'hooks/useFormattedDate';
import { ProgrammeStatusIndicator } from 'App/Student/StudentProgrammes/ProgrammeStatusIndicator';
import { TABLE_STORAGE_KEYS } from 'util/storagekeys';

type Enrolments = NonNullable<GetStudentByStudentIdQuery['student']>['enrolments'];
type RowData = Enrolments[number];

interface Props {
  enrolments: Enrolments;
}

const columnHelper = createColumnHelper<RowData>();

const initialState = { sorting: [{ id: 'endDate', desc: true }] };

export function getProgrammeStatus(enrolment: RowData): StatusIndicatorProps['status'] {
  const dateEnd = Date.parse(enrolment.endDate);
  const dateStart = Date.parse(enrolment.startDate);

  if (dateEnd !== Date.parse(enrolment.endDate) || dateStart !== Date.parse(enrolment.startDate)) return 'fail';

  const hasStarted = dateStart <= Date.now();

  if (!hasStarted) return 'pending';

  if (dateEnd === dateStart || dateEnd < dateStart) return 'ended';

  const hasEnded = dateEnd < Date.now();

  if (hasEnded) return 'completed';

  return 'active';
}

export function StudentProgrammes({ enrolments }: Props) {
  const { t } = useTranslation('student', { keyPrefix: 'student-programmes' });
  const currentLanguage = useCurrentLanguage();
  const formattedDate = useFormattedDate();

  const startAndEndDateFormat: DateTimeFormatOptions = {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  };

  const statusLabels: Record<StatusIndicatorProps['status'], string> = {
    fail: t('status.fail'),
    pending: t('status.future'),
    active: t('status.current'),
    ended: t('status.ended'),
    completed: t('status.completed'),
  };

  const columns = [
    columnHelper.accessor(({ plan }) => plan.code, {
      id: 'code',
      header: t('headers.code'),
      cell: ({ cell, row }) => {
        const programmeStatus = getProgrammeStatus(row.original);

        return (
          <>
            {row.depth === 0 ? (
              <ProgrammeStatusIndicator
                status={programmeStatus}
                title={statusLabels[programmeStatus]}
                label={cell.getValue()}
              />
            ) : (
              cell.getValue()
            )}
          </>
        );
      },
    }),

    columnHelper.accessor(({ plan }) => plan.description, {
      id: 'plan',
      header: t('headers.programme'),
    }),

    columnHelper.accessor('startDate', {
      header: t('headers.start-date'),
      cell: ({ cell }) => <>{cell.getValue() && formattedDate(cell.getValue(), startAndEndDateFormat)}</>,
      sortingFn: 'datetime',
      filterFn: (row, _, filterValue: string) =>
        row.original.startDate
          ? formattedDate(row.original.startDate, startAndEndDateFormat)
              .toLocaleLowerCase()
              .includes(filterValue.toLocaleLowerCase())
          : false,
    }),

    columnHelper.accessor('endDate', {
      header: t('headers.end-date'),
      cell: ({ cell }) => (
        <>
          {cell.getValue<RowData['endDate']>() &&
            formattedDate(cell.getValue<RowData['endDate']>(), startAndEndDateFormat)}
        </>
      ),
      sortingFn: 'datetime',
      filterFn: (row, _, filterValue: string) =>
        row.original.startDate
          ? formattedDate(row.original.endDate, startAndEndDateFormat)
              .toLocaleLowerCase()
              .includes(filterValue.toLocaleLowerCase())
          : false,
    }),

    columnHelper.accessor('career', {
      header: t('headers.career'),
      cell: ({ cell }) =>
        cell.getValue().code && `${cell.getValue().code} (${cell.getValue().description[currentLanguage]})`,
    }),
  ];

  const getSubRows = (row: RowData) => {
    if (!row?.subplans?.length) return [];

    return row.subplans.map((subplan) => ({
      career: { id: '', code: '', description: { NL: '', EN: ' ' } },
      endDate: undefined,
      id: subplan.id,
      plan: subplan as Plan,
      startDate: undefined,
      subplans: [],
    }));
  };

  return (
    <DataTable
      columns={columns}
      data={enrolments}
      initialState={initialState}
      getSubRows={getSubRows}
      tableId={TABLE_STORAGE_KEYS.STUDENT_PROGRAMMES}
    />
  );
}
