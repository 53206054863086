import { gql } from '@apollo/client';

export const REMOVE_RULE = gql`
  mutation removeRule($ruleId: String!) {
    removeRule(input: { ruleId: $ruleId }) {
      mutationStatus
      academicYear
      departmentId
      ruleId
    }
  }
`;
