import { gql } from '@apollo/client';

import { STUDENT_FRAGMENT } from 'graphql/fragments/studentFragment';

export const FIND_STUDENTS = gql`
  query findStudents($query: String!) {
    students(query: $query) {
      searchResults {
        ...student
      }
    }
  }
  ${STUDENT_FRAGMENT}
`;
