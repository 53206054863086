import { gql } from '@apollo/client';

import { PARTIAL_RULE_FRAGMENT } from 'graphql/fragments/partialRuleFragment';

export const GET_RULES_BY_DEPARTMENT_ID = gql`
  query getRulesByDepartmentId($academicYear: Int!, $departmentId: String!, $includeParentDepartments: Boolean = true) {
    rules(
      academicYear: $academicYear
      departmentId: $departmentId
      includeParentDepartments: $includeParentDepartments
    ) {
      ...partialRule
    }
  }
  ${PARTIAL_RULE_FRAGMENT}
`;
