import { gql } from '@apollo/client';

export const UPLOAD_BULK_COURSE_REGISTRATIONS = gql`
  mutation uploadBulkCourseRegistrations(
    $file: Upload!
    $academicYear: Int!
    $skipRequirements: Boolean! = true
    $switchGroupIfAlreadyRegistered: Boolean!
  ) {
    uploadBulkCourseRegistrations(
      input: {
        academicYear: $academicYear
        file: $file
        skipRequirements: $skipRequirements
        switchGroupIfAlreadyRegistered: $switchGroupIfAlreadyRegistered
      }
    ) {
      mutationStatus
      details
      result {
        groupCode
        line
        rowStatus
        studentNumber
      }
      missingColumns
    }
  }
`;
