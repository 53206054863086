export const APP_ROOT = '/';
export const AUTHORISATIONS = '/authorisations';
export const CALLBACK = '/callback';
export const CONSTRAINED_CHOICE = 'constrained-choice/:constrainedChoiceClusterId';
export const CONSTRAINED_CHOICES = 'constrained-choice/:academicYear/:departmentId';
export const CONSTRAINED_CHOICES_NO_PARAMS = 'constrained-choice';
export const COURSE_OFFERING = 'course-offerings/:courseOfferingId';
export const COURSE_OFFERINGS = 'course-offerings/:academicYear/:departmentId';
export const COURSE_OFFERINGS_NO_PARAMS = 'course-offerings';
export const COURSE_REGISTRATION = '/course-registration';
export const ENTRY_REQUIREMENT = 'entry-requirements/:id';
export const ENTRY_REQUIREMENTS = 'entry-requirements/:academicYear/:departmentId';
export const ENTRY_REQUIREMENTS_NO_PARAMS = 'entry-requirements';
export const ERROR = '/error';
export const GROUP_SWAP = 'group-swap/:academicYear/:departmentId';
export const GROUP_SWAP_NO_PARAMS = 'group-swap';
export const LOGIN = '/login';
export const LOGOUT = '/logout';
export const REGISTRATION_ROUNDS = 'registration-rounds/:academicYear';
export const REGISTRATION_ROUNDS_NO_PARAMS = 'registration-rounds';
export const RULE = 'requirement-rules/:ruleId';
export const REQUIREMENT_RULES = 'requirement-rules/:academicYear/:departmentId';
export const REQUIREMENT_RULES_NO_PARAMS = 'requirement-rules';
export const STUDENTS = '/students';
export const TOPIC_SET = 'topic-sets/:topicSetId';
export const TOPIC_SETS = 'topic-sets/:academicYear/:departmentId';
export const TOPIC_SETS_NO_PARAMS = 'topic-sets';
export const UNAUTHORISED = '/unauthorised';
export const USER_BLOCKED = '/user-blocked';
