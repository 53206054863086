import { useMutation } from '@apollo/client';

import type { StoreObject } from '@apollo/client';
import type { RemoveRequiredPlanMutation, RemoveRequiredPlanMutationVariables } from 'types/__generated__';

import { MutationStatus } from 'types/__generated__';
import { REMOVE_REQUIRED_PLAN } from 'graphql/mutations/removeRequiredPlan';

export const useRemoveRequiredPlan = () =>
  useMutation<RemoveRequiredPlanMutation, RemoveRequiredPlanMutationVariables>(REMOVE_REQUIRED_PLAN, {
    update: (cache, { data }) => {
      if (data?.removeRequiredPlan.mutationStatus !== MutationStatus.Success) return;

      const { nodeId } = data.removeRequiredPlan;

      const requiredPlanToEvict: StoreObject = { __typename: 'RequiredPlan', id: nodeId };

      cache.evict({ id: cache.identify(requiredPlanToEvict) });
      cache.gc();
    },
  });
