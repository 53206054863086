import { ApolloClient, ApolloLink, ApolloProvider, concat } from '@apollo/client';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';

import type { ReactNode } from 'react';

import { graphqlUri as uri } from 'env';
import { cache } from 'graphql/cache';
import { useAuth } from 'providers/AuthProvider';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const connectToDevTools = DEV;
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const isLogging = DEV;

const terminatingLink = createUploadLink({ uri });

interface Props {
  children: ReactNode;
}

export const GraphqlProvider = ({ children }: Props) => {
  const user = useAuth();
  const authMiddleWare = new ApolloLink((operation, forward) => {
    if (user !== null) {
      operation.setContext({
        headers: {
          authorization: `Bearer ${user.access_token}`,
          // additional header GraphQL-Preflight needed to ensure that our request
          // is no longer treated as a simple request: https://github.com/ChilliCream/graphql-platform/issues/6246
          'GraphQL-Preflight': 1,
        },
      });
    }

    if (isLogging) {
      // eslint-disable-next-line no-console
      console.info('GraphqlProvider::authMiddleware: "%s"', operation.operationName, operation.variables);
    }

    return forward(operation);
  });
  const link = concat(authMiddleWare, terminatingLink);
  const client = new ApolloClient({ cache, link, connectToDevTools });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
