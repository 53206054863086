import { useLazyQuery } from '@apollo/client';
import { useOverlayTriggerState } from '@react-stately/overlays';
import {
  Button,
  Label,
  FieldHint,
  InputField,
  FeedbackBox,
  ModalDialog,
  ButtonGroup,
  Fieldset,
  FormField,
  MultiSelect,
} from '@uva-glass/component-library';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';

import type { ChangeEvent, FormEvent } from 'react';
import type {
  CreateRuleRequiredPlanMutationVariables,
  GetPlanByCodeQuery,
  GetPlanByCodeQueryVariables,
} from 'types/__generated__';

import { useCreateRuleRequiredPlan } from './hooks/useCreateRuleRequiredPlan';
import styles from './Plan.module.css';

import { GET_PLAN_BY_CODE } from 'graphql/queries/getPlanByCode';
import { MutationStatus } from 'types/__generated__';
import { useGetReadableMutationStatus } from 'hooks/useGetReadableMutationStatus';
import { COURSE_REGISTRATION, RULE } from 'routes';

const PLAN_CODE_LENGTH = 6;

const ID_INPUT_DESCRIPTION = 'ID_INPUT_DESCRIPTION';
const ID_INPUT_PLAN_CODE = 'ID_INPUT_PLAN_CODE';
const ID_PLAN_ERROR = 'ID_PLAN_ERROR';
const ID_DESCRIPTION_ERROR = 'ID_DESCRIPTION_ERROR';

export function Plan({
  departmentId,
  academicYear,
}: Pick<CreateRuleRequiredPlanMutationVariables, 'academicYear' | 'departmentId'>) {
  const { t } = useTranslation('requirement-rules', { keyPrefix: 'new-plan-rule' });
  const { open, close, isOpen } = useOverlayTriggerState({});
  const readableMutationStatus = useGetReadableMutationStatus();
  const navigate = useNavigate();

  const [plan, setPlan] = useState<GetPlanByCodeQuery['plan']>();
  const [name, setName] = useState<string>();
  const [planCode, setPlanCode] = useState<string>('');
  const [subplanIds, setSubplanIds] = useState<string[]>([]);
  const [fieldError, setFieldError] = useState<string>();
  const [planError, setPlanError] = useState<string>('');

  const [mutate, mutation] = useCreateRuleRequiredPlan();
  const [query, lazyQuery] = useLazyQuery<GetPlanByCodeQuery, GetPlanByCodeQueryVariables>(GET_PLAN_BY_CODE);

  function resetAndClose() {
    setPlan(undefined);
    setName(undefined);
    setPlanCode('');
    setSubplanIds([]);
    setFieldError(undefined);
    setPlanError('');
    close();
  }

  function onSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (!name || name.trim() === '' || !plan || !planCode || planCode.length < PLAN_CODE_LENGTH) {
      if (!name || name.trim() === '') {
        setFieldError(t('form.fill-in-a-type', { type: t('terms.name') }));
      }

      if (!plan || !planCode) {
        setPlanError(t('fill-in-a-valid-plan-code'));
      }

      if (plan && planCode && planCode.length < PLAN_CODE_LENGTH) {
        setPlanError(t('plan-code-too-short'));
      }

      return;
    }

    mutate({
      variables: {
        academicYear,
        departmentId,
        name: name,
        plan: {
          planId: plan.id,
          subplanIds,
        },
      },
      onCompleted(data) {
        if (data.createRuleRequiredPlan.mutationStatus === MutationStatus.Success) {
          if (data.createRuleRequiredPlan.rule) {
            navigate(
              generatePath(`${COURSE_REGISTRATION}/${RULE}`, {
                ruleId: data.createRuleRequiredPlan.rule.id,
              })
            );
          }

          return;
        }

        setFieldError(readableMutationStatus(data.createRuleRequiredPlan.mutationStatus));
      },
    });
  }

  function onChange(event: ChangeEvent<HTMLInputElement>) {
    setFieldError(undefined);
    setName(event.target.value);
  }

  function onCancel() {
    resetAndClose();
  }

  function onChangePlanCode(event: ChangeEvent<HTMLInputElement>) {
    const { value, validity } = event.target;

    setPlanError('');

    if (value.length > PLAN_CODE_LENGTH) return;

    const newPlanCode = value.replace(/[^\d]/g, '');

    if (value !== newPlanCode) return;

    setPlanCode(value);

    if (!validity.valid) {
      setPlan(undefined);
      return;
    }

    query({
      variables: { planCode: value },
      onCompleted(data) {
        setPlan(data.plan);

        if (data.plan === null) {
          setPlanError(t('plan-not-found', { planCode: value }));
        }
      },
    });
  }

  function onChangeSubplans(event: ChangeEvent<HTMLInputElement>) {
    const { checked, value } = event.target;

    setSubplanIds((prevState) =>
      checked ? [...prevState, value] : prevState.filter((subplanId) => subplanId !== value)
    );
  }

  return (
    <>
      <button onClick={open}>{t('trigger')}</button>

      <ModalDialog
        title={t('modal.title')}
        isOpen={isOpen}
        onClose={resetAndClose}
        isDismissable={!mutation.loading && !lazyQuery.loading}
        buttons={
          <ButtonGroup reversed>
            <Button variant="primary" type="submit" disabled={mutation.loading}>
              {t('modal.buttons.add-and-edit')}
            </Button>
            <Button variant="secondary" onClick={onCancel} disabled={mutation.loading}>
              {t('modal.buttons.cancel')}
            </Button>
          </ButtonGroup>
        }
        noValidate
        onSubmit={onSubmit}
        wide
      >
        <FormField outerSpace>
          <Label htmlFor={ID_INPUT_DESCRIPTION}>{t('modal.name-label')}</Label>
          <InputField
            aria-describedby={fieldError ? ID_DESCRIPTION_ERROR : undefined}
            id={ID_INPUT_DESCRIPTION}
            onChange={onChange}
            value={name || ''}
          />
          {fieldError && <FeedbackBox id={ID_DESCRIPTION_ERROR} feedback={fieldError} level="error" />}
        </FormField>

        <FormField outerSpace>
          <Label id={`${ID_INPUT_PLAN_CODE}-label`} htmlFor={ID_INPUT_PLAN_CODE}>
            {t('modal.plan-label')}
          </Label>
          <span id={`${ID_INPUT_PLAN_CODE}-sublabel`} className={styles['plan__sublabel']}>
            <Trans t={t} i18nKey="modal.student-must-be-registered" components={{ strong: <strong /> }} />
          </span>

          <InputField
            aria-describedby={plan === null ? ID_PLAN_ERROR : undefined}
            aria-labelledby={`${ID_INPUT_PLAN_CODE}-sublabel`}
            disabled={mutation.loading}
            id={ID_INPUT_PLAN_CODE}
            inputMode="numeric"
            maxLength={PLAN_CODE_LENGTH}
            onChange={onChangePlanCode}
            pattern="[0-9]{6}"
            placeholder={t('modal.plan-code')}
            value={planCode}
          />
          <FieldHint
            value={plan ? `[${plan.description}]` : undefined}
            loading={lazyQuery.loading}
            spinnerAriaValueText={t('modal.loading-plan')}
          />

          {planError && <FeedbackBox id={ID_PLAN_ERROR} feedback={planError} level="error" />}
        </FormField>

        {plan?.subplans && plan.subplans.length > 0 && (
          <Fieldset legend={`${t('modal.subplans-label')} (${t('terms.optional')})`}>
            <MultiSelect>
              {plan.subplans.map(({ id, code, description }) => (
                <MultiSelect.MultiSelectItem
                  key={id}
                  label={`${code} - ${description}`}
                  checked={subplanIds.includes(id)}
                  value={id}
                  onChange={onChangeSubplans}
                />
              ))}
            </MultiSelect>
          </Fieldset>
        )}
      </ModalDialog>
    </>
  );
}
