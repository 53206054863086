import { useOverlayTriggerState } from '@react-stately/overlays';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonGroup, FeedbackBox, Icon, ModalDialog } from '@uva-glass/component-library';

import { MutationStatus, UserAction } from 'types/__generated__';
import { useGetCourseOffering } from 'hooks/useGetCourseOffering';
import { useGetReadableMutationStatus } from 'hooks/useGetReadableMutationStatus';
import { useSetTopicSetByCourseOfferingId } from 'hooks/useSetTopicSetByCourseOfferingId';
import { useAuthorisation } from 'providers/AuthorisationProvider';

export function UnlinkTopicSet() {
  const { t } = useTranslation('course-offering', { keyPrefix: 'unlink-topic-set' });
  const { close, open, isOpen } = useOverlayTriggerState({});
  const readableMutationStatus = useGetReadableMutationStatus();
  const { marblesCourseOffering } = useGetCourseOffering();
  const { departmentId, academicYear } = marblesCourseOffering;
  const userCanEdit = useAuthorisation(UserAction.EditCourseSettings, departmentId);

  const [mutationError, setMutationError] = useState('');
  const [mutate, mutation] = useSetTopicSetByCourseOfferingId({ academicYear: academicYear.id, departmentId });

  function onClick() {
    mutate({
      variables: { courseOfferingId: marblesCourseOffering?.id },
      onCompleted(data) {
        const { mutationStatus } = data.setCourseOfferingTopicSet;

        switch (mutationStatus) {
          case MutationStatus.Success:
          case MutationStatus.Duplicate:
            resetAndClose();
            break;

          default:
            setMutationError(readableMutationStatus(mutationStatus || 'unknown'));
            break;
        }
      },
      onError(error) {
        if (error.graphQLErrors.length) {
          setMutationError(readableMutationStatus(MutationStatus.Corrupt));
        } else {
          setMutationError(readableMutationStatus(MutationStatus.Error));
        }
      },
    });
  }

  function resetAndClose() {
    setMutationError('');
    mutation.reset();
    close();
  }

  return (
    <>
      {userCanEdit && !academicYear.isArchived && (
        <Button variant="secondary" onClick={open}>
          <Icon name="LinkSlash" size={20} />
          {t('trigger')}
        </Button>
      )}
      <ModalDialog
        title={t('modal.title')}
        buttons={
          <ButtonGroup>
            <Button variant="secondary" disabled={mutation.loading} onClick={close}>
              {t('modal.buttons.cancel')}
            </Button>
            <Button variant="destructive-primary" disabled={mutation.loading} onClick={onClick}>
              {t('modal.buttons.unlink')}
            </Button>
          </ButtonGroup>
        }
        isOpen={isOpen}
        onClose={close}
        isDismissable={!mutation.loading}
      >
        <p>{t('modal.description')}</p>
        {mutationError && <FeedbackBox level="error" feedback={mutationError} />}
      </ModalDialog>
    </>
  );
}
