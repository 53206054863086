import { gql } from '@apollo/client';

export const GET_USERS = gql`
  query getUsers {
    marblesUsers {
      name
      familyName
      givenName
      username
      status
      roleAssignments {
        id
        role
        department {
          id
          code
        }
      }
    }
  }
`;
