import { gql } from '@apollo/client';

import { PARTIAL_RULE_FRAGMENT } from 'graphql/fragments/partialRuleFragment';

export const CREATE_RULE_REQUIRED_STUDENT_SET = gql`
  mutation createRuleRequiredStudentSet(
    $academicYear: Int!
    $departmentId: String!
    $name: String!
    $studentSetId: String!
  ) {
    createRuleRequiredStudentSet(
      input: { academicYear: $academicYear, departmentId: $departmentId, name: $name, studentSetId: $studentSetId }
    ) {
      academicYear
      rule {
        ...partialRule
      }
      mutationStatus
    }
  }
  ${PARTIAL_RULE_FRAGMENT}
`;
