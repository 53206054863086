import { gql } from '@apollo/client';

export const DEPARTMENT_FRAGMENT = gql`
  fragment department on MarblesDepartment {
    id
    code
    name {
      EN
      NL
    }
    allowedUserActions
    parentId
  }
`;
