import { gql } from '@apollo/client';

export const UPLOAD_CREDIT_LIMIT_EXCEPTIONS = gql`
  mutation uploadCreditLimitExceptions($file: Upload!) {
    uploadCreditLimitExceptions(input: { file: $file }) {
      mutationStatus
      result {
        line
        rowStatus
        studentNumber
      }
      missingColumns
    }
  }
`;
