import { gql } from '@apollo/client';

export const GET_REGISTRATIONS_EXPORT_LINK = gql`
  query getRegistrationsExportLink(
    $courseOfferingId: String
    $departmentId: String
    $status: PendingOrRegistered!
    $academicYear: Int
  ) {
    courseRegistrationsExport(
      courseOfferingId: $courseOfferingId
      departmentId: $departmentId
      status: $status
      academicYear: $academicYear
    ) {
      url
    }
  }
`;
