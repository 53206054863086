import {
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Fragment, useEffect, useState } from 'react';

import type { ColumnFiltersState, SortingState, TableOptions } from '@tanstack/react-table';
import type { Period } from 'types/__generated__';
import type { TABLE_STORAGE_KEYS } from 'util/storagekeys';

import { Table } from './components/Table/Table';
import { TableBody } from './components/TableBody/TableBody';
import { TableCell } from './components/TableCell/TableCell';
import { TableFilterInput } from './components/TableFilterInput/TableFilterInput';
import { TableHead } from './components/TableHead/TableHead';
import { TableHeader } from './components/TableHeader/TableHeader';
import { TableRow } from './components/TableRow/TableRow';
import { TableSortHeader } from './components/TableSortHeader/TableSortHeader';

import { read, write } from 'util/localStorage';

type PeriodBasic = Omit<Period, 'academicYear' | 'endDate' | 'startDate' | 'semester'>;

interface Props<T> extends Omit<TableOptions<T>, 'getCoreRowModel' | 'filterFns'> {
  tableId: (typeof TABLE_STORAGE_KEYS)[keyof typeof TABLE_STORAGE_KEYS];
}

const defaultRowSpan = 1;
const defaultColSpan = 1;

export const periodsFilterFn = (periods: PeriodBasic[], filterValue: string) =>
  filterValue.match(/((\d)(\.(\d)?)?)|(\.(\d)?)/) === null
    ? false
    : periods
        .map(({ semesterNumber, periodInSemester }) => `${semesterNumber}.${periodInSemester}`)
        .some((period) => period.includes(filterValue));

export function DataTable<T>(props: Props<T>) {
  const [sorting, setSorting] = useState<SortingState>(read(props.tableId) || props.initialState?.sorting || []);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(props?.initialState?.columnFilters || []);

  // write current selected sorting of current table to local storage
  useEffect(() => {
    write(props.tableId, sorting);
  }, [props.tableId, sorting]);

  const table = useReactTable({
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    state: {
      rowSelection: props.initialState?.rowSelection ?? {},
      sorting,
      columnFilters,
      columnVisibility: props?.initialState?.columnVisibility,
    },
    onColumnFiltersChange: setColumnFilters,
    onSortingChange: setSorting,
    ...props,
  });

  const hasFilters = table.getAllLeafColumns().some((column) => column.getCanFilter());

  return (
    <Table>
      <TableHead>
        {table.getHeaderGroups().map((headerGroup) => (
          <TableRow key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              const sortMatch = sorting.find(({ id }) => header.column.id === id);

              return (
                <TableHeader
                  key={header.id}
                  colSpan={header.colSpan || defaultColSpan}
                  rowSpan={header.rowSpan || defaultRowSpan}
                  onClick={header.column.getToggleSortingHandler()}
                >
                  {header.isPlaceholder ? null : (
                    <TableSortHeader
                      // column sorting is enabled by default and can be disabled on a per-column basis
                      canSort={header.column.getCanSort()}
                      sorted={sortMatch !== undefined}
                      descending={sortMatch?.desc}
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                    </TableSortHeader>
                  )}
                </TableHeader>
              );
            })}
          </TableRow>
        ))}

        {hasFilters &&
          table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                const hasFilterComponent = Boolean(header.column.columnDef.meta?.Filter);

                return (
                  <TableHeader key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : header.column.getCanFilter() &&
                        (hasFilterComponent ? (
                          flexRender(header.column.columnDef.meta?.Filter, header.getContext())
                        ) : (
                          <TableFilterInput column={header.column} />
                        ))}
                  </TableHeader>
                );
              })}
            </TableRow>
          ))}
      </TableHead>

      <TableBody>
        {table.getRowModel().rows.map((row) => (
          <Fragment key={row.id}>
            <TableRow isSelected={row.getIsSelected()}>
              {row.getVisibleCells().map((cell) => (
                <TableCell key={cell.id} {...cell.column.columnDef.meta?.tableCellProps}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              ))}
            </TableRow>

            {row.subRows.map((subrow, index) => (
              // eslint-disable-next-line @typescript-eslint/no-magic-numbers
              <TableRow key={subrow.id} isSubRow isLast={index === row.subRows.length - 1}>
                {subrow.getVisibleCells().map((subrowCell) => (
                  <TableCell key={subrowCell.id} {...subrowCell.column.columnDef.meta?.tableCellProps}>
                    {flexRender(subrowCell.column.columnDef.cell, subrowCell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </Fragment>
        ))}
      </TableBody>
    </Table>
  );
}
