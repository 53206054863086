import { gql } from '@apollo/client';

export const SET_GROUP_SWAP_ENABLED = gql`
  mutation setGroupSwapEnabled($courseOfferingId: String!, $groupSwapEnabled: Boolean!) {
    setGroupSwapEnabled(input: { courseOfferingId: $courseOfferingId, groupSwapEnabled: $groupSwapEnabled }) {
      mutationStatus
      courseOfferingId
      groupSwapEnabled
    }
  }
`;
