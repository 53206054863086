import { gql } from '@apollo/client';

export const REMOVE_COURSE_OFFERING_FROM_CLUSTER = gql`
  mutation removeCourseOfferingFromCluster($courseOfferingId: String!) {
    setCourseOfferingConstrainedChoiceCluster(input: { courseOfferingId: $courseOfferingId }) {
      courseOffering {
        id
      }
      clusterId
      mutationStatus
    }
  }
`;
