import { useEffect, useRef } from 'react';

import type { UserState } from 'oidc-clients-ts';

import { useLocationState } from 'hooks/useLocationState';
import { login } from 'services/authService';

export function Login() {
  const state = useLocationState<UserState>();
  const didLogin = useRef(false);

  useEffect(() => {
    async function asyncLogin() {
      try {
        if (didLogin.current) return;

        didLogin.current = true;

        await login(state);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
    }

    asyncLogin();
  }, [state]);

  return null;
}
