import { gql } from '@apollo/client';

import { STUDENT_GROUP_FRAGMENT } from 'graphql/fragments/studentGroupFragment';

export const PROCESS_GROUP_SWAP = gql`
  mutation processGroupSwap($groupSwapRequestId: String!, $studentGroupId: String!) {
    processGroupSwap(input: { groupSwapRequestId: $groupSwapRequestId, studentGroupId: $studentGroupId }) {
      groupSwapRequest {
        id
        newStudentGroup {
          id
          name
          code
        }
        processedBy
        updateDate
        status
        courseOfferingId
      }
      studentGroups {
        ...studentGroup
        topic {
          id
          title {
            NL
            EN
          }
        }
        groupSwapDemand
      }
      mutationStatus
    }
  }
  ${STUDENT_GROUP_FRAGMENT}
`;
