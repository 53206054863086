import { useMutation } from '@apollo/client';

import type { StoreObject } from '@apollo/client';
import type {
  CreatePendingCoursePursuitMutation,
  CreatePendingCoursePursuitMutationVariables,
  GetPendingPursuitsByCourseOfferingIdQuery,
  GetPendingPursuitsByCourseOfferingIdQueryVariables,
  PendingCoursePursuitFragment,
} from 'types/__generated__';

import { CREATE_PENDING_COURSE_PURSUIT } from 'graphql/mutations/createPendingCoursePursuit';
import { GET_PENDING_PURSUITS_BY_COURSE_OFFERING_ID } from 'graphql/queries/getPendingPursuitsByCourseOfferingId';
import { MutationStatus } from 'types/__generated__';

export const useCreatePendingCoursePursuit = (academicYear: number) =>
  useMutation<CreatePendingCoursePursuitMutation, CreatePendingCoursePursuitMutationVariables>(
    CREATE_PENDING_COURSE_PURSUIT,
    {
      update(cache, { data }) {
        if (data?.administrativelySelectCourseForRegistration.mutationStatus !== MutationStatus.Success) return;

        const { courseOfferingId, coursePursuitId, student, clusterPreference, requirementsCheck, topicId } =
          data.administrativelySelectCourseForRegistration;

        if (!courseOfferingId || !coursePursuitId || !student) {
          throw new Error('One or more of "courseOfferingId", "coursePursuitId" or "student" not defined.');
        }

        const courseOffering: StoreObject = { __typename: 'MarblesCourseOffering', id: courseOfferingId };

        const newCoursePursuit: PendingCoursePursuitFragment = {
          __typename: 'CoursePursuit',
          id: coursePursuitId,
          topicId,
          student,
          requirementsCheck,
          clusterPreference,
        };

        const pending = cache.updateQuery<
          GetPendingPursuitsByCourseOfferingIdQuery,
          GetPendingPursuitsByCourseOfferingIdQueryVariables
        >(
          { query: GET_PENDING_PURSUITS_BY_COURSE_OFFERING_ID, variables: { courseOfferingId, academicYear } },
          (cached) => ({
            courseOfferingPursuits: cached ? [...cached.courseOfferingPursuits, newCoursePursuit] : [newCoursePursuit],
          })
        );

        cache.modify({
          id: cache.identify(courseOffering),
          fields: {
            numberOfPendingStudents(currentValue) {
              return pending?.courseOfferingPursuits.length ?? currentValue;
            },
          },
        });
      },
      refetchQueries({ data }) {
        const mutationStatus = data?.administrativelySelectCourseForRegistration.mutationStatus;

        switch (mutationStatus) {
          case MutationStatus.Locked:
            return [GET_PENDING_PURSUITS_BY_COURSE_OFFERING_ID];
          case MutationStatus.Success:
          case MutationStatus.Duplicate:
          default:
            return [];
        }
      },
    }
  );
