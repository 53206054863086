import { gql } from '@apollo/client';

import { REQUIREMENTS_FRAGMENT } from 'graphql/fragments/requirementsFragment';
import { STUDENT_WITH_ENROLMENTS_BY_ACADEMIC_YEAR_FRAGMENT } from 'graphql/fragments/studentWithEnrolmentsByAcademicYearFragment';

export const GET_STUDENT_REQUIREMENT_CHECK_BY_COURSE_OFFERING_ID = gql`
  query getStudentRequirementCheckByCourseOfferingId(
    $courseOfferingId: String!
    $studentId: String
    $studentNumber: String
    $academicYear: Int
  ) {
    studentRequirementsCheck(
      courseOfferingId: $courseOfferingId
      studentId: $studentId
      studentNumber: $studentNumber
    ) {
      requirementsCheck {
        ...requirements
      }
      student {
        ...studentWithEnrolmentsByAcademicYear
      }
    }
  }
  ${REQUIREMENTS_FRAGMENT}
  ${STUDENT_WITH_ENROLMENTS_BY_ACADEMIC_YEAR_FRAGMENT}
`;
