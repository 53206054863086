import type { GetCourseOfferingByIdQuery } from 'types/__generated__';

export const getGroupUnavailabilityStatus = (
  groupId: string,
  allGroups: NonNullable<GetCourseOfferingByIdQuery['marblesCourseOffering']>['studentGroups']
) => {
  const groupDetails = allGroups.find((group) => group.id === groupId);
  if (!groupDetails) return '';

  const { locked, capacity, numberOfRegisteredStudents } = groupDetails;
  const full = capacity <= numberOfRegisteredStudents;

  if (locked) return 'locked';
  if (full) return 'full';
  return '';
};
