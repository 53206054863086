import type { ThHTMLAttributes } from 'react';

import styles from './TableHeader.module.css';

type Props = Omit<ThHTMLAttributes<HTMLTableCellElement>, 'className' | 'style'>;

export const TableHeader = ({ children, ...restProps }: Props) => (
  <th {...restProps} className={styles['th']}>
    {children}
  </th>
);
