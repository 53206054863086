import classNames from 'clsx';

import type { PropsWithChildren } from 'react';

import styles from './ToastContainer.module.css';

export const ToastContainer = ({ children }: PropsWithChildren) => (
  <div
    aria-live="polite"
    aria-atomic="true"
    aria-relevant="additions"
    className={classNames(styles['toast-container'])}
  >
    {children}
  </div>
);
