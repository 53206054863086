import { useQuery } from '@apollo/client';
import { createColumnHelper } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EmptyStateDataDisplay, Icon, Spinner } from '@uva-glass/component-library';

import type { VisibilityState } from '@tanstack/react-table';

import {
  UserAction,
  type GetGroupsByCourseOfferingIdQuery,
  type GetGroupsByCourseOfferingIdQueryVariables,
} from 'types/__generated__';
import { ChangeGroupTopic } from 'App/CourseOffering/ChangeGroupTopic/ChangeGroupTopic';
import { SetStudentGroupCapacity } from 'App/CourseOffering/SetStudentGroupCapacity/SetStudentGroupCapacity';
import { TableFilterLocked } from 'App/CourseOffering/CourseOfferingGroups/TableFilterLocked/TableFilterLocked';
import { ToggleGroupLocked } from 'App/CourseOffering/ToggleGroupLocked/ToggleGroupLocked';
import { DataTable } from 'App/shared/DataTable/DataTable';
import { GroupsExportLink } from 'App/CourseOffering/CourseOfferingGroups/GroupsExportLink/GroupsExportLink';
import { ToggleSwitch } from 'components/ToggleSwitch/ToggleSwitch';
import { Toolbar } from 'components/Toolbar/Toolbar';
import { GET_GROUPS_BY_COURSE_OFFERING_ID } from 'graphql/queries/getGroupsByCourseOfferingId';
import { useCurrentLanguage } from 'hooks/useCurrentLanguage';
import { useGetCourseOffering } from 'hooks/useGetCourseOffering';
import { TABLE_STORAGE_KEYS } from 'util/storagekeys';

type RowData = NonNullable<GetGroupsByCourseOfferingIdQuery['marblesCourseOffering']>['studentGroups'][number];

const columnHelper = createColumnHelper<RowData>();

export function CourseOfferingGroups() {
  const { t } = useTranslation('course-offering', { keyPrefix: 'course-offering-groups' });
  const currentLanguage = useCurrentLanguage();
  const { marblesCourseOffering } = useGetCourseOffering();
  const { id: courseOfferingId, department, academicYear } = marblesCourseOffering;
  const userCanEditCourseSettings = department.allowedUserActions.includes(UserAction.EditCourseSettings);
  const query = useQuery<GetGroupsByCourseOfferingIdQuery, GetGroupsByCourseOfferingIdQueryVariables>(
    GET_GROUPS_BY_COURSE_OFFERING_ID,
    { variables: { courseOfferingId }, fetchPolicy: 'cache-and-network' }
  );
  const [isEditMode, setIsEditMode] = useState(false);

  const initialState = useMemo(() => {
    const columnVisibility: VisibilityState = {};

    if (isEditMode) {
      columnVisibility.capacity = false;
    } else {
      columnVisibility.initialCapacity = false;
      columnVisibility.configuredCapacity = false;
    }

    if (!query.loading && !query?.data?.marblesCourseOffering?.topicSet) {
      columnVisibility.topic = false;
    }

    return {
      sorting: [{ id: 'name', desc: false }],
      columnVisibility,
    };
  }, [isEditMode, query?.data?.marblesCourseOffering?.topicSet, query.loading]);

  const columns = [
    columnHelper.accessor('code', {
      header: t('headers.group-code'),
      meta: { tableCellProps: { maxWidth: 'small' } },
    }),

    columnHelper.accessor('name', {
      header: t('headers.name'),
    }),

    columnHelper.accessor('capacity', {
      header: t('headers.capacity'),
      meta: { tableCellProps: { isNumeric: true } },
      enableColumnFilter: false,
    }),

    columnHelper.accessor('initialCapacity', {
      header: t('headers.initial-capacity'),
      cell: ({ row, cell }) => (row.original.directRegistrationEnabled ? null : <>{cell.getValue()}</>),
      meta: { tableCellProps: { isNumeric: true } },
      enableColumnFilter: false,
    }),

    columnHelper.accessor('configuredCapacity', {
      header: t('headers.adjusted-capacity'),
      cell: ({ row, cell }) =>
        row.original.directRegistrationEnabled ? null : (
          <SetStudentGroupCapacity capacity={cell.getValue()} studentGroupId={row.original.id} />
        ),
      meta: { tableCellProps: { isNumeric: true } },
      enableColumnFilter: false,
    }),

    columnHelper.accessor('numberOfRegisteredStudents', {
      header: t('headers.enrolled'),
      meta: { tableCellProps: { isNumeric: true } },
      enableColumnFilter: false,
    }),

    columnHelper.accessor(({ topic }) => topic?.title[currentLanguage], {
      id: 'topic',
      header: t('headers.topic'),
      cell: ({ row, cell }) =>
        isEditMode ? (
          <ChangeGroupTopic
            courseOfferingId={courseOfferingId}
            studentGroupId={row.original.id}
            topic={row.original.topic}
          />
        ) : (
          <>{cell.getValue()}</>
        ),
      enableColumnFilter: false,
    }),

    columnHelper.accessor(({ timetableSummary }) => timetableSummary[currentLanguage], {
      id: 'timetableSummary',
      header: t('headers.timetable'),
    }),

    columnHelper.accessor('locked', {
      header: t('headers.status'),
      cell: ({ row, cell }) =>
        isEditMode && !row.original.directRegistrationEnabled ? (
          <ToggleGroupLocked groupId={row.original.id} locked={cell.getValue()} />
        ) : (
          <Icon name={cell.getValue() ? 'LockClosedFill' : 'LockOpenFill'} />
        ),
      meta: {
        tableCellProps: { hasButton: true },
        Filter: TableFilterLocked,
      },
    }),
  ];

  if (query.loading) return <Spinner ariaValueText={''} />;

  if (query.error || !query.data?.marblesCourseOffering) return <>Error</>;

  const { studentGroups } = query.data.marblesCourseOffering;

  return studentGroups.length ? (
    <>
      <Toolbar>
        <GroupsExportLink courseOfferingId={courseOfferingId} />
        {userCanEditCourseSettings && !academicYear.isArchived && (
          <ToggleSwitch
            onChange={() => setIsEditMode((previousState) => !previousState)}
            checked={isEditMode}
            label={t('edit-toggle')}
            labelPosition="before"
            extraLeftGap
          />
        )}
      </Toolbar>

      <DataTable
        columns={columns}
        data={studentGroups}
        initialState={initialState}
        tableId={TABLE_STORAGE_KEYS.COURSE_OFFERING_GROUPS}
      />
    </>
  ) : (
    <EmptyStateDataDisplay label={t('empty-state.label')} paragraph={t('empty-state.paragraph')} />
  );
}
