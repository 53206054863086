import classNames from 'clsx';
import { Icon } from '@uva-glass/component-library';

import type { GetStudentRequirementCheckByCourseOfferingIdQuery } from 'types/__generated__';

import styles from './RequirementsCheckGroupRowHeader.module.css';

import { useCurrentLanguage } from 'hooks/useCurrentLanguage';
import { RequirementStatus } from 'types/__generated__';

type RowData = NonNullable<
  GetStudentRequirementCheckByCourseOfferingIdQuery['studentRequirementsCheck']
>['requirementsCheck']['groups'][number]['rows'][number];

interface Props {
  row: RowData;
  header?: 'span' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
}

function getIconName(status: RequirementStatus) {
  if (status === RequirementStatus.Pass) return 'CheckCircle';
  if (status === RequirementStatus.Fail) return 'CrossCircle';
  return 'ExclamationCircle';
}

function getIconClassName(status: RequirementStatus) {
  let className = styles['requirements-check-group-row-header__icon--inconclusive'];

  if (status === RequirementStatus.Pass) {
    className = styles['requirements-check-group-row-header__icon--pass'];
  }

  if (status === RequirementStatus.Fail) {
    className = styles['requirements-check-group-row-header__icon--fail'];
  }

  return classNames(styles['requirements-check-group-row-header__icon'], className);
}

export function RequirementsCheckGroupRowHeader({ row, header = 'h3' }: Props) {
  const currentLanguage = useCurrentLanguage();

  if (!row.title) return null;
  const { status, title } = row;
  const progress = row.progress && row.progress[currentLanguage];
  const Header = header;

  return (
    <Header
      className={
        styles[header === 'span' ? 'requirements-check-group-row-sub-header' : 'requirements-check-group-row-header']
      }
    >
      <Icon className={getIconClassName(status)} size={20} name={getIconName(status)} />
      <div>
        {title[currentLanguage]} {progress && <strong>({progress})</strong>}
      </div>
    </Header>
  );
}
