import { gql } from '@apollo/client';

export const SET_REQUIREMENT_NODE_BY_COURSE_OFFERING_ID = gql`
  mutation setRequirementNodeByCourseOfferingId($courseOfferingId: String!, $requirementNodeId: String) {
    setCourseOfferingRequirementNode(
      input: { courseOfferingId: $courseOfferingId, requirementNodeId: $requirementNodeId }
    ) {
      mutationStatus
      courseOfferingId
      requirementNodeId
      courseOfferingRequirementCapacities {
        allocationRound
        description {
          NL
          EN
        }
        pending
        registered
        priority
        priorityLimit
        includeInRetry
        ruleId
      }
    }
  }
`;
