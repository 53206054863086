import { IconButton, Icon } from '@uva-glass/component-library';

import styles from './HelpLink.module.css';

export const HelpLink = () => {
  const onClick = () => {
    window.open('https://confluence.ic.uva.nl/display/GLASS/GLASS+handleidingen', '_blank');
  };

  return (
    <IconButton aria-label="Help" noBorder wide onClick={onClick}>
      <div className={styles['board-column-credits-wrapper']}>
        <span>Help</span>
        <Icon name="ArrowTopRightOnSquare" size={20} />
      </div>
    </IconButton>
  );
};
