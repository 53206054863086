import { gql } from '@apollo/client';

import { REQUIREMENT_FRAGMENT } from 'graphql/fragments/requirementFragment';

export const UPDATE_REQUIREMENT_RULES = gql`
  mutation updateRequirementRules($requirementId: String!, $rules: [RuleInRequirementInput!]!) {
    updateRequirementRules(input: { requirementId: $requirementId, rules: $rules }) {
      mutationStatus
      requirement {
        ...requirement
      }
    }
  }
  ${REQUIREMENT_FRAGMENT}
`;
