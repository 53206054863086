import { useTranslation } from 'react-i18next';
import { InfoMessage, Periods } from '@uva-glass/component-library';

import type { ChangeEvent } from 'react';
import type { GetCourseOfferingsByCatalogNumberQuery } from 'types/__generated__';

import styles from './CourseOfferingSelector.module.css';

import { useCurrentLanguage } from 'hooks/useCurrentLanguage';

type Props = {
  courseOfferings: GetCourseOfferingsByCatalogNumberQuery['marblesCourseOfferings'];
  onOfferingSelect: (event: ChangeEvent<HTMLInputElement>) => void;
  onlyAllowedRegistrationPeriod: number | undefined;
};

type MarblesCourseOffering = GetCourseOfferingsByCatalogNumberQuery['marblesCourseOfferings'][number];

export function CourseOfferingSelector({ courseOfferings, onOfferingSelect, onlyAllowedRegistrationPeriod }: Props) {
  const { t } = useTranslation('constrained-choices', { keyPrefix: 'add-course-offering' });
  const currentLanguage = useCurrentLanguage();

  // check if courseOffering has pending students
  function hasPendingStudents(courseOffering: MarblesCourseOffering) {
    return courseOffering.numberOfPendingStudents > 0;
  }

  // check if 42 EC limit is active on courseOffering
  function hasCreditLimitException(courseOffering: MarblesCourseOffering) {
    return courseOffering.hasCreditLimitException;
  }

  // check if the courseOffering is already part of another cluster
  function isAlreadyPartOfCluster(courseOffering: MarblesCourseOffering) {
    return courseOffering.constrainedChoiceClusterId !== null;
  }

  // check if the course does not start in same semester as current added offerings
  function doesNotStartInSameSemester(courseOffering: MarblesCourseOffering) {
    return (
      onlyAllowedRegistrationPeriod !== undefined &&
      courseOffering.registrationPeriod.semesterNumber !== onlyAllowedRegistrationPeriod
    );
  }

  return (
    <div className={styles['course-offering-selector']}>
      <label className={styles['course-offering-selector__label']}>{t('course-offering-selector.label')}</label>
      <p className={styles['course-offering-selector__description']}>{t('course-offering-selector.description')}</p>
      <div className={styles['course-offering-selector__cards']}>
        {courseOfferings.map((courseOffering) => (
          <div className={styles['course-offering-selector__card']} key={courseOffering.id}>
            <label className={styles['course-offering-selector__card-content']} htmlFor={courseOffering.id}>
              <input
                className={styles['course-offering-selector__card-input']}
                id={courseOffering.id}
                type="radio"
                name="course-offering"
                aria-label={`${courseOffering.info?.title[currentLanguage]} ${courseOffering.periods[0].number}`}
                value={courseOffering.id}
                onChange={onOfferingSelect}
                disabled={
                  hasPendingStudents(courseOffering) ||
                  hasCreditLimitException(courseOffering) ||
                  isAlreadyPartOfCluster(courseOffering) ||
                  doesNotStartInSameSemester(courseOffering)
                }
              />
              <div className={styles['course-offering-selector__card-details']}>
                <div className={styles['course-offering-selector__card-items']}>
                  <span>{courseOffering.info?.title[currentLanguage]}</span>
                  <span className={styles['course-offering-selector__card-item-ec']}>{courseOffering.info?.ec} EC</span>
                </div>
                <div className={styles['course-offering-selector__card-items']}>
                  <span>{courseOffering.info?.catalogNumber}</span>
                  <span className={styles['course-offering-selector__card-item-periods']}>
                    <Periods
                      periods={courseOffering.periods}
                      tooltipTranslations={{
                        periods: t('periods.periods'),
                        period: t('periods.period'),
                        and: t('periods.and'),
                      }}
                    />
                  </span>
                </div>
                {hasPendingStudents(courseOffering) && (
                  <InfoMessage message={t('course-offering-selector.pending-students')} />
                )}
                {hasCreditLimitException(courseOffering) && (
                  <InfoMessage message={t('course-offering-selector.credit-limit-exception')} />
                )}
                {isAlreadyPartOfCluster(courseOffering) && (
                  <InfoMessage message={t('course-offering-selector.already-part-of-constrained-choice')} />
                )}
                {doesNotStartInSameSemester(courseOffering) && (
                  <InfoMessage message={t('course-offering-selector.not-same-semester')} />
                )}
              </div>
            </label>
          </div>
        ))}
      </div>
    </div>
  );
}
