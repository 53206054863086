import { gql } from '@apollo/client';

export const STUDENT_SET_FRAGMENT = gql`
  fragment studentSet on StudentSet {
    id
    code
    title {
      EN
      NL
    }
  }
`;
