import { useQuery } from '@apollo/client';
import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { Link, generatePath } from 'react-router-dom';
import { EmptyStateDataDisplay, Spinner, StatusPill } from '@uva-glass/component-library';

import type {
  GetCourseOfferingPendingGroupSwapsByDepartmentIdQuery,
  GetCourseOfferingPendingGroupSwapsByDepartmentIdQueryVariables,
} from 'types/__generated__';

import { COURSE_OFFERING, COURSE_REGISTRATION } from 'routes';
import { DataTable } from 'App/shared/DataTable/DataTable';
import { DateTime } from 'App/shared/DateTime/DateTime';
import { GET_COURSE_OFFERING_PENDING_GROUP_SWAPS_BY_DEPARTMENT_ID } from 'graphql/queries/getCourseOfferingPendingGroupSwapsByDepartmentId';
import { TABLE_STORAGE_KEYS } from 'util/storagekeys';
import { useCurrentLanguage } from 'hooks/useCurrentLanguage';
import { useFormattedDate } from 'hooks/useFormattedDate';

type RowData = GetCourseOfferingPendingGroupSwapsByDepartmentIdQuery['courseOfferingPendingGroupSwaps'][number];

const columnHelper = createColumnHelper<RowData>();

const initialState = { sorting: [{ id: 'request-expiration-date', desc: false }] };

export function GroupSwapTable({
  departmentId,
  academicYear,
}: GetCourseOfferingPendingGroupSwapsByDepartmentIdQueryVariables) {
  const { t } = useTranslation('group-swap', { keyPrefix: 'group-swap-table' });
  const currentLanguage = useCurrentLanguage();
  const formattedDate = useFormattedDate();

  const courseOfferingPendingGroupSwapsQuery = useQuery<
    GetCourseOfferingPendingGroupSwapsByDepartmentIdQuery,
    GetCourseOfferingPendingGroupSwapsByDepartmentIdQueryVariables
  >(GET_COURSE_OFFERING_PENDING_GROUP_SWAPS_BY_DEPARTMENT_ID, {
    variables: { academicYear, departmentId },
    fetchPolicy: 'cache-and-network',
  });

  const columns = [
    columnHelper.accessor(({ info }) => info?.catalogNumber, {
      id: 'catalog-number',
      header: t('headers.catalog-number'),
      meta: { tableCellProps: { fixedWidth: 'small' } },
    }),

    columnHelper.accessor(({ info }) => info?.title[currentLanguage], {
      id: 'course-title',
      header: t('headers.course-title'),
      cell: ({ row, cell }) => (
        <Link
          to={generatePath(`${COURSE_REGISTRATION}/${COURSE_OFFERING}/group-swap`, {
            courseOfferingId: row.original.courseOfferingId,
          })}
        >
          {cell.getValue()}
        </Link>
      ),
      meta: { tableCellProps: { minWidth: 'medium' } },
    }),

    columnHelper.accessor('requestExpirationDate', {
      id: 'request-expiration-date',
      header: t('headers.request-expiration-date'),
      cell: ({ cell }) => <DateTime isoDate={cell.getValue()} format={{ dateStyle: 'medium', timeStyle: 'short' }} />,
      filterFn: (row, _, filterValue: string) =>
        formattedDate(row.original.requestExpirationDate, { dateStyle: 'medium', timeStyle: 'short' })
          .toLocaleLowerCase()
          .includes(filterValue.toLocaleLowerCase()),
    }),

    columnHelper.accessor('lastRequestDate', {
      id: 'last-request-date',
      header: t('headers.last-request-date'),
      cell: ({ cell }) => <DateTime isoDate={cell.getValue()} format={{ dateStyle: 'medium', timeStyle: 'short' }} />,
      filterFn: (row, _, filterValue: string) =>
        formattedDate(row.original.lastRequestDate, { dateStyle: 'medium', timeStyle: 'short' })
          .toLocaleLowerCase()
          .includes(filterValue.toLocaleLowerCase()),
    }),

    columnHelper.accessor('requestCount', {
      id: 'request-count',
      header: t('headers.request-count'),
      enableColumnFilter: false,
      meta: { tableCellProps: { fixedWidth: 'small' } },
    }),

    columnHelper.accessor('requestPossible', {
      id: 'swap-status',
      header: t('headers.swap-status'),
      cell: ({ row }) => (
        <StatusPill
          status={row.original.requestPossible ? 'possible' : 'impossible'}
          label={row.original.requestPossible ? t('values.possible') : t('values.impossible')}
        />
      ),
      enableColumnFilter: false,
      meta: { tableCellProps: { fixedWidth: 'medium', noWrap: true } },
    }),
  ];

  if (courseOfferingPendingGroupSwapsQuery.loading) return <Spinner ariaValueText={''} />;

  if (!courseOfferingPendingGroupSwapsQuery.data?.courseOfferingPendingGroupSwaps) return null;

  const { courseOfferingPendingGroupSwaps } = courseOfferingPendingGroupSwapsQuery.data;

  return (
    <>
      {courseOfferingPendingGroupSwaps.length ? (
        <DataTable
          columns={columns}
          data={courseOfferingPendingGroupSwaps}
          initialState={initialState}
          tableId={TABLE_STORAGE_KEYS.GROUP_SWAP_TABLE}
        />
      ) : (
        <EmptyStateDataDisplay label={t('empty-state.label')} paragraph={t('empty-state.paragraph')} />
      )}
    </>
  );
}
