import { gql } from '@apollo/client';

import { CAREER_FRAGMENT } from 'graphql/fragments/careerFragment';

export const GET_CAREERS = gql`
  query getCareers {
    careers {
      ...career
    }
  }
  ${CAREER_FRAGMENT}
`;
