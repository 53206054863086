import { useTranslation } from 'react-i18next';

import type { MutationTuple } from '@apollo/client';
import type { ChangeEvent } from 'react';
import type {
  GetRequirementByIdQuery,
  RuleInRequirementInput,
  UpdateRequirementRulesMutation,
  UpdateRequirementRulesMutationVariables,
} from 'types/__generated__';

import { MutationStatus } from 'types/__generated__';
import { ToggleSwitch } from 'components/ToggleSwitch/ToggleSwitch';
import { createRulesInRequirement } from 'util/createRulesInRequirement';

interface Props {
  cellValue: boolean;
  ruleId: string;
  requirement: NonNullable<GetRequirementByIdQuery['requirement']>;
  updateRequirementRulesMutation: MutationTuple<
    UpdateRequirementRulesMutation,
    UpdateRequirementRulesMutationVariables
  >;
  disabled?: boolean;
}

export function BooleanInput({ cellValue, ruleId, requirement, updateRequirementRulesMutation, disabled }: Props) {
  const [mutate, mutation] = updateRequirementRulesMutation;
  const { t } = useTranslation('entry-requirements', { keyPrefix: 'entry-requirement' });

  const rules = createRulesInRequirement(requirement);
  const thisRule = rules.find((rule) => rule.ruleId === ruleId) as RuleInRequirementInput;

  function handleMutation(event: ChangeEvent<HTMLInputElement>) {
    const { checked } = event.currentTarget;

    const updatedRule = { ...thisRule, includeInRetry: checked };
    const updatedRules = rules.map((rule) => (rule.ruleId === ruleId ? updatedRule : rule));

    mutate({
      variables: {
        requirementId: requirement.id,
        rules: updatedRules,
      },
      optimisticResponse: {
        updateRequirementRules: {
          mutationStatus: MutationStatus.Success,
          requirement: {
            ...requirement,
            rulesInRequirement: requirement.rulesInRequirement.map((ruleInRequirement) =>
              ruleInRequirement.rule.id === updatedRule?.ruleId
                ? { ...ruleInRequirement, includeInRetry: updatedRule?.includeInRetry ?? false }
                : ruleInRequirement
            ),
          },
        },
      },
    });
  }

  return (
    <ToggleSwitch
      checked={cellValue}
      disabled={disabled}
      loading={mutation.loading}
      label={t('table.values.include-in-retry')}
      labelVisible={false}
      onChange={handleMutation}
    />
  );
}
