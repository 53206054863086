import { DateTime } from 'luxon';

import type { DateTimeFormatOptions } from 'luxon';
import type { BilingualStringIndex } from 'types/BilingualStringIndex';

import { useCurrentLanguage } from 'hooks/useCurrentLanguage';

export const defaultTimezone = 'Europe/Amsterdam';

export const defaultDateFormat: Intl.DateTimeFormatOptions = {
  day: 'numeric',
  month: 'long',
  year: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
};

interface DateFormatterArgs {
  timezone?: string;
  currentLanguage: BilingualStringIndex;
}

export const dateFormatter = ({ timezone = defaultTimezone, currentLanguage }: DateFormatterArgs) =>
  function formattedDate(isoDate: string, format: DateTimeFormatOptions = defaultDateFormat) {
    const dateTime = DateTime.fromISO(isoDate, { locale: currentLanguage === 'EN' ? 'en-gb' : 'NL', zone: timezone });

    if (!dateTime.isValid) {
      throw new Error(`'${isoDate}' cannot be converted to a valid date/time string`);
    }

    return dateTime.toLocaleString(format);
  };

export function useFormattedDate() {
  const currentLanguage = useCurrentLanguage();
  const formattedDate = dateFormatter({ currentLanguage });

  return formattedDate;
}
