import { useMutation } from '@apollo/client';

import type { StoreObject } from '@apollo/client';
import type { SetCourseOfferingStatusMutation, SetCourseOfferingStatusMutationVariables } from 'types/__generated__';

import { MutationStatus } from 'types/__generated__';
import { SET_COURSE_OFFERING_STATUS } from 'graphql/mutations/setCourseOfferingStatus';
import { GET_COURSE_OFFERING_BY_ID } from 'graphql/queries/getCourseOfferingById';

export const useSetCourseOfferingStatus = () =>
  useMutation<SetCourseOfferingStatusMutation, SetCourseOfferingStatusMutationVariables>(SET_COURSE_OFFERING_STATUS, {
    update(cache, { data }) {
      if (data?.setCourseOfferingStatus.mutationStatus !== MutationStatus.Success) return;

      const { courseOfferingId, status } = data.setCourseOfferingStatus;
      const courseOffering: StoreObject = { __typename: 'MarblesCourseOffering', id: courseOfferingId };

      cache.modify({
        id: cache.identify(courseOffering),
        fields: {
          status() {
            return status;
          },
        },
      });
    },
    refetchQueries({ data }) {
      const mutationStatus = data?.setCourseOfferingStatus.mutationStatus;

      if (mutationStatus === MutationStatus.Success) return [];

      return [GET_COURSE_OFFERING_BY_ID];
    },
  });
