import { gql } from '@apollo/client';

export const ENROLMENT_FRAGMENT = gql`
  fragment enrolment on Enrolment {
    id
    endDate
    startDate
    career {
      id
      code
      description {
        NL
        EN
      }
    }
    plan {
      id
      code
      description
    }
    subplans {
      id
      code
      description
    }
  }
`;
