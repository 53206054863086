import { useMutation } from '@apollo/client';

import type {
  CreateRuleRequiredPlanMutation,
  CreateRuleRequiredPlanMutationVariables,
  GetRulesByDepartmentIdQuery,
  GetRulesByDepartmentIdQueryVariables,
} from 'types/__generated__';

import { MutationStatus } from 'types/__generated__';
import { CREATE_RULE_REQUIRED_PLAN } from 'graphql/mutations/createRuleRequiredPlan';
import { GET_RULES_BY_DEPARTMENT_ID } from 'graphql/queries/getRulesByDepartmentId';

export const useCreateRuleRequiredPlan = () =>
  useMutation<CreateRuleRequiredPlanMutation, CreateRuleRequiredPlanMutationVariables>(CREATE_RULE_REQUIRED_PLAN, {
    update: (cache, { data }) => {
      if (data?.createRuleRequiredPlan.mutationStatus !== MutationStatus.Success) return;

      // If creating a new Plan Rule was successful the NewCareerRule component will automatically navigate the user to
      // the newly created Plan Rule
      // That would usually require the cache to be manually updated here for the `getRuleById` query for the new
      // `rule.id`, however, because (for now!) rules are real time, this isn't necessary.

      const { academicYear, rule } = data.createRuleRequiredPlan;

      if (!rule || !academicYear) {
        throw new Error('"rule" is not defined.');
      }

      cache.updateQuery<GetRulesByDepartmentIdQuery, GetRulesByDepartmentIdQueryVariables>(
        {
          query: GET_RULES_BY_DEPARTMENT_ID,
          variables: {
            departmentId: rule.department.id,
            academicYear: academicYear,
          },
        },
        (cached) => ({ rules: cached ? [...cached.rules, rule] : [rule] })
      );
    },
  });
