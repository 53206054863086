import { clsx } from 'clsx';

import type { TdHTMLAttributes } from 'react';

import styles from './TableCell.module.css';

export type TableCellProps = {
  isNumeric?: boolean;
  hasButton?: boolean;
  flex?: boolean;
  noWrap?: boolean;
  maxWidth?: 'small' | 'medium' | 'large';
  minWidth?: 'small' | 'medium' | 'large';
  fixedWidth?: 'small' | 'medium' | 'large';
};

type Props = Omit<TdHTMLAttributes<HTMLTableCellElement>, 'classname' | 'style'> & TableCellProps;

export const TableCell = ({
  isNumeric,
  hasButton,
  flex,
  noWrap,
  maxWidth,
  minWidth,
  fixedWidth,
  children,
  ...restProps
}: Props) => {
  const className = clsx(styles['td'], {
    [styles['td--numeric']]: isNumeric,
    [styles['td--button']]: hasButton,
    [styles['td--no-wrap']]: noWrap,
    [styles['td--max-width-small']]: maxWidth === 'small',
    [styles['td--max-width-medium']]: maxWidth === 'medium',
    [styles['td--max-width-large']]: maxWidth === 'large',
    [styles['td--min-width-small']]: minWidth === 'small',
    [styles['td--min-width-medium']]: minWidth === 'medium',
    [styles['td--min-width-large']]: minWidth === 'large',
    [styles['td--fixed-width-small']]: fixedWidth === 'small',
    [styles['td--fixed-width-medium']]: fixedWidth === 'medium',
    [styles['td--fixed-width-large']]: fixedWidth === 'large',
  });

  return (
    <td {...restProps} className={className}>
      {flex ? <span className={styles['td__flex']}>{children}</span> : <>{children}</>}
    </td>
  );
};
