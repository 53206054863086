import { useOverlayTriggerState } from '@react-stately/overlays';
import { Button, ButtonGroup, FeedbackBox, Icon, Label, ModalDialog, FormField } from '@uva-glass/component-library';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { ChangeEvent, FormEvent } from 'react';

import { FetchStudent } from 'App/CourseOffering/FetchStudent/FetchStudent';
import { Select } from 'components/Select/Select';
import { useCurrentLanguage } from 'hooks/useCurrentLanguage';
import { useGetCourseOffering } from 'hooks/useGetCourseOffering';
import { useGetReadableMutationStatus } from 'hooks/useGetReadableMutationStatus';
import { useRegisterStudentForCourse } from 'hooks/useRegisterStudentForCourse';
import { MutationStatus } from 'types/__generated__';
import { getGroupUnavailabilityStatus } from 'util/getGroupUnavailabilityStatus';

const STUDENT_NUMBER_ERROR_ID = 'student-number-error';
const STUDENT_GROUP_ID = 'student-group';

export function RegisterStudentForCourseModal() {
  const { t } = useTranslation('course-offering', { keyPrefix: 'register-student-for-course' });
  const currentLanguage = useCurrentLanguage();
  const { close, isOpen, open } = useOverlayTriggerState({});
  const readableMutationStatus = useGetReadableMutationStatus();
  const { marblesCourseOffering } = useGetCourseOffering();
  const { id: courseOfferingId, studentGroups } = marblesCourseOffering;
  const academicYear = marblesCourseOffering.academicYear.id;
  const topicSetTitle = marblesCourseOffering.topicSet?.title[currentLanguage];
  const [firstStudentGroup] = studentGroups;
  const openButtonRef = useRef<HTMLButtonElement>(null);
  const closeButtonRef = useRef<HTMLButtonElement>(null);

  const getStudentGroupTopicTitle = (group: (typeof studentGroups)[number]) =>
    group.topic?.title[currentLanguage]
      ? group.topic?.title[currentLanguage]
      : `${t('modal.no-topic-title', { topicTitle: topicSetTitle || t('modal.topic') })}`;

  const groupStudentGroupsByTopicTitle = (groups: typeof studentGroups) =>
    groups.reduce<Record<string, typeof studentGroups>>((groupsByTopicTitle, studentGroup) => {
      const studentGroupTopicTitle = getStudentGroupTopicTitle(studentGroup);

      const studentGroupsByTopicTitle = groupsByTopicTitle[studentGroupTopicTitle] || [];

      studentGroupsByTopicTitle.push(studentGroup);
      return { ...groupsByTopicTitle, [studentGroupTopicTitle]: studentGroupsByTopicTitle };
    }, {});

  const studentGroupsByTopic = groupStudentGroupsByTopicTitle(studentGroups);
  const aplhabeticallySortedStudentGroupsByTopic = Object.keys(studentGroupsByTopic)
    .sort()
    .reduce<Record<keyof typeof studentGroupsByTopic, typeof studentGroups>>(
      (sortedGroups, topicTitle) => ({
        ...sortedGroups,
        [topicTitle]: studentGroupsByTopic[topicTitle],
      }),
      {}
    );

  const [studentNumber, setStudentNumber] = useState('');
  const [mutationError, setMutationError] = useState('');
  const [studentGroupId, setStudentGroupId] = useState(firstStudentGroup?.id);
  const [groupUnavailabilityStatus, setGroupUnavailabilityStatus] = useState(
    getGroupUnavailabilityStatus(firstStudentGroup?.id, studentGroups)
  );
  const [mutate, mutation] = useRegisterStudentForCourse(academicYear);

  function resetAndClose() {
    setStudentGroupId(firstStudentGroup?.id);
    setStudentNumber('');
    setGroupUnavailabilityStatus(getGroupUnavailabilityStatus(firstStudentGroup?.id, studentGroups));
    setMutationError('');
    close();
  }

  function onChangeStudentNumber(number: string) {
    setStudentNumber(number);
    setMutationError('');
  }

  function onChangeStudentGroupId(event: ChangeEvent<HTMLSelectElement>) {
    const selectedGroupId = event.target.value;
    setStudentGroupId(selectedGroupId);
    setGroupUnavailabilityStatus(getGroupUnavailabilityStatus(selectedGroupId, studentGroups));
  }

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (studentNumber.trim() === '') {
      setMutationError(t('modal.errors.no-value'));
      return;
    }

    const { data } = await mutate({
      variables: { studentId: studentNumber.trim(), studentGroupId, courseOfferingId, academicYear },
    });

    const mutationStatus = data?.registerStudentForCourse.mutationStatus;

    if (mutationStatus === MutationStatus.Success) {
      resetAndClose();
      return;
    }

    setMutationError(readableMutationStatus(mutationStatus || 'unknown', { student: studentNumber.trim() }));
  }

  if (!studentGroups.length) return null;

  return (
    <>
      <Button onClick={open} variant="primary" ref={openButtonRef}>
        <Icon name="Plus" size={20} />
        {t('trigger')}
      </Button>
      <ModalDialog
        title={t('modal.title')}
        onSubmit={handleSubmit}
        buttons={
          <ButtonGroup reversed>
            <Button variant="primary" type="submit" disabled={mutation.loading}>
              {t('modal.buttons.confirm')}
            </Button>
            <Button onClick={resetAndClose} variant="secondary" ref={closeButtonRef}>
              {t('modal.buttons.cancel')}
            </Button>
          </ButtonGroup>
        }
        isOpen={isOpen}
        onClose={resetAndClose}
      >
        <FormField outerSpace>
          <FetchStudent
            fieldErrorId={STUDENT_NUMBER_ERROR_ID}
            onSetStudentNumber={onChangeStudentNumber}
            label={t`modal.student-number`}
          />
          {mutationError && <FeedbackBox id={STUDENT_NUMBER_ERROR_ID} level="error" feedback={mutationError} />}
        </FormField>

        <FormField outerSpace>
          <Label htmlFor={STUDENT_GROUP_ID}>{t('modal.student-group')}</Label>
          <Select id={STUDENT_GROUP_ID} onChange={onChangeStudentGroupId} value={studentGroupId}>
            {Object.keys(aplhabeticallySortedStudentGroupsByTopic).map((topicName) => (
              <Select.OptionGroup label={topicName} key={topicName}>
                {aplhabeticallySortedStudentGroupsByTopic[topicName].map(
                  ({ id, name, numberOfRegisteredStudents, capacity }) => (
                    <Select.Option value={id} key={id}>
                      {name} — ({numberOfRegisteredStudents}/{capacity})
                      {getGroupUnavailabilityStatus(id, studentGroups) &&
                        ` — ${t(`modal.values.${getGroupUnavailabilityStatus(id, studentGroups)}`)}`}
                    </Select.Option>
                  )
                )}
              </Select.OptionGroup>
            ))}
          </Select>
          {groupUnavailabilityStatus && (
            <FeedbackBox
              id={STUDENT_GROUP_ID}
              level="warning"
              feedback={t('modal.errors.group-unavailable', {
                unavailable: t(`modal.values.${groupUnavailabilityStatus}`).toLocaleLowerCase(),
              })}
            />
          )}
        </FormField>
      </ModalDialog>
    </>
  );
}
