import { gql } from '@apollo/client';

export const GET_COURSE_OFFERING_TIMETABLE_ACTIVITIES = gql`
  query getCourseOfferingTimetableActivities($courseOfferingId: String!) {
    courseOfferingTimetableActivities(courseOfferingId: $courseOfferingId) {
      id
      name
      description
      isPublishedForStudents
      type
      weeks
      dayOfWeek
      startTime
      endTime
      locations
      studentGroups {
        name
      }
    }
  }
`;
