import { useMutation } from '@apollo/client';

import type {
  CreateConstrainedChoiceClusterMutation,
  CreateConstrainedChoiceClusterMutationVariables,
  GetConstrainedChoiceClustersByDepartmentIdQuery,
  GetConstrainedChoiceClustersByDepartmentIdQueryVariables,
} from 'types/__generated__';

import { CREATE_CONSTRAINED_CHOICE_CLUSTER } from 'graphql/mutations/createConstrainedChoiceCluster';
import { GET_CONSTRAINED_CHOICE_CLUSTERS_BY_DEPARTMENT_ID } from 'graphql/queries/getConstrainedChoiceClustersByDepartmentId';
import { MutationStatus } from 'types/__generated__';

export const useCreateConstrainedChoiceCluster = (departmentId: string) =>
  useMutation<CreateConstrainedChoiceClusterMutation, CreateConstrainedChoiceClusterMutationVariables>(
    CREATE_CONSTRAINED_CHOICE_CLUSTER,
    {
      update(cache, { data }) {
        if (data?.createConstrainedChoiceCluster.mutationStatus !== MutationStatus.Success) return;

        const { academicYear, constrainedChoiceCluster } = data.createConstrainedChoiceCluster;

        if (!constrainedChoiceCluster) {
          throw new Error('No "constrained choice" in payload');
        }

        cache.updateQuery<
          GetConstrainedChoiceClustersByDepartmentIdQuery,
          GetConstrainedChoiceClustersByDepartmentIdQueryVariables
        >(
          {
            query: GET_CONSTRAINED_CHOICE_CLUSTERS_BY_DEPARTMENT_ID,
            variables: { academicYear, departmentId },
          },
          (cached) => ({
            constrainedChoiceClusters: cached?.constrainedChoiceClusters
              ? [...cached.constrainedChoiceClusters, constrainedChoiceCluster]
              : [constrainedChoiceCluster],
          })
        );
      },
    }
  );
