import { useMutation } from '@apollo/client';

import type { StoreObject } from '@apollo/client';
import type {
  GetPendingPursuitsByCourseOfferingIdQuery,
  GetPendingPursuitsByCourseOfferingIdQueryVariables,
  GetRegisteredPursuitsByCourseOfferingIdQuery,
  GetRegisteredPursuitsByCourseOfferingIdQueryVariables,
  UploadCourseRegistrationsMutation,
  UploadCourseRegistrationsMutationVariables,
} from 'types/__generated__';

import { MutationStatus } from 'types/__generated__';
import { UPLOAD_COURSE_REGISTRATIONS } from 'graphql/mutations/uploadCourseRegistrations';
import { GET_PENDING_PURSUITS_BY_COURSE_OFFERING_ID } from 'graphql/queries/getPendingPursuitsByCourseOfferingId';
import { GET_REGISTERED_PURSUITS_BY_COURSE_OFFERING_ID } from 'graphql/queries/getRegisteredPursuitsByCourseOfferingId';

export const useUploadCourseRegistrations = (academicYear: number) =>
  useMutation<UploadCourseRegistrationsMutation, UploadCourseRegistrationsMutationVariables>(
    UPLOAD_COURSE_REGISTRATIONS,
    {
      update: (cache, { data }) => {
        if (data?.uploadCourseRegistrations.mutationStatus !== MutationStatus.Success) return;

        const { pursuits, courseOfferingId } = data.uploadCourseRegistrations;
        const pursuitIds = pursuits.map(({ id }) => id);

        const updatedRegistered = cache.updateQuery<
          GetRegisteredPursuitsByCourseOfferingIdQuery,
          GetRegisteredPursuitsByCourseOfferingIdQueryVariables
        >(
          { query: GET_REGISTERED_PURSUITS_BY_COURSE_OFFERING_ID, variables: { courseOfferingId, academicYear } },
          (cached) => ({
            courseOfferingPursuits: cached
              ? [...cached.courseOfferingPursuits.filter(({ id }) => !pursuitIds.includes(id)), ...pursuits]
              : pursuits,
          })
        );

        const updatedPending = cache.updateQuery<
          GetPendingPursuitsByCourseOfferingIdQuery,
          GetPendingPursuitsByCourseOfferingIdQueryVariables
        >(
          { query: GET_PENDING_PURSUITS_BY_COURSE_OFFERING_ID, variables: { courseOfferingId, academicYear } },
          (cached) =>
            cached
              ? // only update the cache for pending if it already existed in the cache
                {
                  courseOfferingPursuits: [
                    ...cached.courseOfferingPursuits.filter(
                      ({ id }) => !pursuits.find((registeredPursuit) => id === registeredPursuit.id)
                    ),
                  ],
                }
              : // returning undefined leaves the non-existing cache alone
                undefined
        );

        const courseOffering: StoreObject = { __typename: 'MarblesCourseOffering', id: courseOfferingId };

        cache.modify({
          id: cache.identify(courseOffering),
          fields: {
            numberOfRegisteredStudents(currentValue) {
              return updatedRegistered?.courseOfferingPursuits.length ?? currentValue;
            },
            numberOfPendingStudents(currentValue) {
              return updatedPending?.courseOfferingPursuits.length ?? currentValue;
            },
          },
        });
      },
    }
  );
