import { gql } from '@apollo/client';

import { ENROLMENT_FRAGMENT } from 'graphql/fragments/enrolmentFragment';

export const STUDENT_WITH_ENROLMENTS_BY_ACADEMIC_YEAR_FRAGMENT = gql`
  fragment studentWithEnrolmentsByAcademicYear on Student {
    id
    studentNumber
    name
    surname
    givenName
    enrolments(academicYear: $academicYear) {
      ...enrolment
    }
  }
  ${ENROLMENT_FRAGMENT}
`;
