import { createColumnHelper } from '@tanstack/react-table';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { SectionNotification, Card, MetaDataList, Spinner } from '@uva-glass/component-library';

import {
  UserStatus,
  type GetAuthorisationRightsQuery,
  type GetUserAuthorisationsQuery,
  type GetUserAuthorisationsQueryVariables,
  UserAction,
} from 'types/__generated__';
import { AddAuthorisation } from 'App/Authorisations/AddAutorisation/AddAuthorisation';
import { DataTable } from 'App/shared/DataTable/DataTable';
import { DateTime } from 'App/shared/DateTime/DateTime';
import { GET_AUTHORISATION_RIGHTS } from 'graphql/queries/getAuthorisationRights';
import { GET_USER_AUTHORISATIONS } from 'graphql/queries/getUserAuthorisations';
import { LiftSuspension } from 'App/Authorisations/UserAuthorisation/LiftSuspension';
import { Page } from 'components/Page/Page';
import { RevokeAuthorisation } from 'App/Authorisations/UserAuthorisation/RevokeAuthorisation';
import { RowActions } from 'App/shared/DataTable/RowActions/RowActions';
import { SidebarLayout } from 'components/SidebarLayout/SidebarLayout';
import { TABLE_STORAGE_KEYS } from 'util/storagekeys';
import { Toolbar } from 'components/Toolbar/Toolbar';
import { useAuthorisation } from 'providers/AuthorisationProvider';
import { useCurrentLanguage } from 'hooks/useCurrentLanguage';
import { useFormattedDate } from 'hooks/useFormattedDate';
import { useGetReadableUserStatus } from 'hooks/useGetReadableUserStatus';

type RowData = GetUserAuthorisationsQuery['marblesUser']['roleAssignments'][number];
const columnHelper = createColumnHelper<RowData>();

const initialState = { sorting: [{ id: 'department', desc: false }] };

export function UserAuthorisation() {
  const { t } = useTranslation('authorisations', { keyPrefix: 'user-authorisation-page' });
  const currentLanguage = useCurrentLanguage();
  const { username } = useParams();
  const formattedDate = useFormattedDate();
  const readableUserStatus = useGetReadableUserStatus();
  const navigate = useNavigate();
  const isSuperAdmin = useAuthorisation(UserAction.AdminAction);
  const authorisationRightsQuery = useQuery<GetAuthorisationRightsQuery>(GET_AUTHORISATION_RIGHTS);

  if (!username) {
    throw new Error('No username found for user authorisations');
  }

  const userAuthorisationQuery = useQuery<GetUserAuthorisationsQuery, GetUserAuthorisationsQueryVariables>(
    GET_USER_AUTHORISATIONS,
    {
      variables: { username },
    }
  );

  const columns = [
    columnHelper.accessor(({ department }) => department.name[currentLanguage], {
      id: 'department',
      header: t('table.headers.department'),
      enableColumnFilter: false,
      sortingFn: 'text',
    }),
    columnHelper.accessor(({ userRole }) => userRole.description[currentLanguage], {
      id: 'userRole',
      header: t('table.headers.role'),
      enableColumnFilter: false,
      sortingFn: 'text',
    }),
    columnHelper.accessor(({ createdBy }) => createdBy, {
      id: 'createdBy',
      header: t('table.headers.created-by'),
      enableColumnFilter: false,
      sortingFn: 'text',
    }),
    columnHelper.accessor(({ creationDate }) => creationDate, {
      id: 'creationDate',
      header: t('table.headers.created-on'),
      cell: ({ cell }) => <DateTime isoDate={cell.getValue()} format={{ dateStyle: 'long', timeStyle: 'short' }} />,
      enableColumnFilter: false,
      filterFn: (row, _, filterValue: string) =>
        formattedDate(row.original.creationDate, { dateStyle: 'long', timeStyle: 'short' })
          .toLocaleLowerCase()
          .includes(filterValue.toLocaleLowerCase()),
    }),
    columnHelper.display({
      id: 'actions',
      header: () => <span className="visually-hidden">{t('table.headers.actions')}</span>,
      cell: ({ row }) => (
        <RowActions>
          <RevokeAuthorisation
            authorisation={row.original}
            userName={username}
            fullName={`${givenName} ${familyName}`}
            isDisabled={
              !marblesAuthorisationRights.departments.some(
                (department) => department.id === row.original.department.id
              ) ||
              !marblesAuthorisationRights.userRoles.some((userRole) => userRole.name === row.original.userRole.name)
            }
          />
        </RowActions>
      ),
      meta: { tableCellProps: { hasButton: true } },
    }),
  ];

  if (userAuthorisationQuery.loading || authorisationRightsQuery.loading) return <Spinner ariaValueText={''} />;

  if (userAuthorisationQuery.error) {
    // send user back to main authorisations table if user is not found or the user has no authorisation within your rights
    if (userAuthorisationQuery.error.message.includes('403') || userAuthorisationQuery.error.message.includes('404')) {
      navigate('/authorisations');
    }
    throw userAuthorisationQuery.error;
  }

  if (authorisationRightsQuery.error) {
    throw authorisationRightsQuery.error;
  }

  if (!userAuthorisationQuery.data || !authorisationRightsQuery.data) return null;

  const { roleAssignments, givenName, familyName, status } = userAuthorisationQuery.data.marblesUser;
  const userIsSuspended = status === UserStatus.Suspended;
  const { marblesAuthorisationRights } = authorisationRightsQuery.data;

  const roleAssignmentsWithDepartmentRights = roleAssignments.filter((roleAssignment) =>
    marblesAuthorisationRights.departments.some((department) => department.id === roleAssignment.department.id)
  );

  if (roleAssignmentsWithDepartmentRights.length <= 0) navigate('/authorisations');

  return (
    <Page
      title={`${t('title')} ${givenName && familyName ? `${givenName} ${familyName}` : username}`}
      backlink={{ to: '..', text: t('back') }}
      actions={!userIsSuspended && <AddAuthorisation defaultUsername={username} />}
    >
      {userIsSuspended && (
        <Toolbar>
          <SectionNotification fullWidth>
            <div dangerouslySetInnerHTML={{ __html: `${t('blocked-message')}` }} />
          </SectionNotification>
          {isSuperAdmin && <LiftSuspension userName={username} />}
        </Toolbar>
      )}

      <SidebarLayout>
        <aside>
          <Card>
            <MetaDataList
              items={{
                [t('credentials.username')]: username,
                [t('credentials.name')]: `${givenName} ${familyName}`,
                [t('credentials.status')]: readableUserStatus(status),
              }}
            />
          </Card>
        </aside>
        <section>
          <DataTable
            columns={columns}
            data={roleAssignments}
            initialState={initialState}
            tableId={TABLE_STORAGE_KEYS.USER_AUTHORISATION_TABLE}
          />
        </section>
      </SidebarLayout>
    </Page>
  );
}
