import { gql } from '@apollo/client';

import { COURSE_INFO_FRAGMENT } from 'graphql/fragments/courseInfoFragment';
import { PERIOD_FRAGMENT } from 'graphql/fragments/periodFragment';

export const ADD_COURSE_OFFERING_TO_CONSTRAINED_CHOICE = gql`
  mutation addCourseOfferingToConstrainedChoice($courseOfferingId: String!, $clusterId: String) {
    setCourseOfferingConstrainedChoiceCluster(input: { courseOfferingId: $courseOfferingId, clusterId: $clusterId }) {
      courseOffering {
        id
        info {
          ...courseInfo
        }
        periods {
          ...period
        }
        numberOfPendingStudents
        registrationPeriod {
          ...period
        }
      }
      clusterId
      mutationStatus
    }
  }
  ${COURSE_INFO_FRAGMENT}
  ${PERIOD_FRAGMENT}
`;
