import { useMutation } from '@apollo/client';
import { useOverlayTriggerState } from '@react-stately/overlays';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button, IconButton, Icon, FeedbackBox, ModalDialog, ButtonGroup } from '@uva-glass/component-library';

import type { FormEvent } from 'react';
import type {
  InfoRequirement,
  RemoveInfoRequirementMutation,
  RemoveInfoRequirementMutationVariables,
} from 'types/__generated__';

import { REMOVE_INFO_REQUIREMENT } from 'graphql/mutations/removeInfoRequirement';
import { useCurrentLanguage } from 'hooks/useCurrentLanguage';
import { useGetReadableMutationStatus } from 'hooks/useGetReadableMutationStatus';
import { MutationStatus } from 'types/__generated__';

interface Props {
  requirement: InfoRequirement;
  ruleId: string;
}

export function RemoveInfoRequirement({ requirement, ruleId }: Props) {
  const { t } = useTranslation('requirement-rules', { keyPrefix: 'remove-info-requirement' });
  const currentLanguage = useCurrentLanguage();

  const readableMutationStatus = useGetReadableMutationStatus();
  const [error, setError] = useState<string>();
  const { open, close, isOpen } = useOverlayTriggerState({});

  const [mutate, mutation] = useMutation<RemoveInfoRequirementMutation, RemoveInfoRequirementMutationVariables>(
    REMOVE_INFO_REQUIREMENT
  );

  function onCompleted() {
    setError(undefined);
    close();
  }

  function onSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    mutate({
      variables: { ruleId, nodeId: requirement.id },
      onCompleted(data) {
        const { mutationStatus } = data.removeInfoRequirement;

        if (mutationStatus === MutationStatus.Success) {
          onCompleted();
          return;
        }

        setError(readableMutationStatus(mutationStatus));
      },
      onError() {
        setError(readableMutationStatus('unknown'));
      },
    });
  }

  return (
    <>
      <IconButton aria-label={t('trigger')} variant="destructive" onClick={open}>
        <Icon name="Trash" />
      </IconButton>

      {isOpen && (
        <ModalDialog
          title={t('modal.title')}
          isOpen={isOpen}
          onClose={onCompleted}
          onSubmit={onSubmit}
          buttons={
            <ButtonGroup>
              <Button onClick={onCompleted} variant="secondary">
                {t('modal.buttons.cancel')}
              </Button>
              <Button variant="destructive-primary" disabled={mutation.loading} type="submit">
                {t('modal.buttons.remove')}
              </Button>
            </ButtonGroup>
          }
        >
          <p>
            <Trans
              t={t}
              i18nKey="modal.description"
              values={{ name: requirement.description[currentLanguage] }}
              components={{ strong: <strong /> }}
            />
          </p>
          {error && <FeedbackBox level="error" feedback={error} />}
        </ModalDialog>
      )}
    </>
  );
}
