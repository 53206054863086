import { useLazyQuery } from '@apollo/client';
import { useOverlayTriggerState } from '@react-stately/overlays';
import {
  Button,
  ButtonGroup,
  FeedbackBox,
  Icon,
  InputField,
  Label,
  ModalDialog,
  Spinner,
  FormField,
} from '@uva-glass/component-library';
import { useState, type ChangeEvent, type FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';

import type { GetAuthorisationRightsQuery } from 'types/__generated__';

import { useAuthoriseUser } from 'App/Authorisations/AddAutorisation/hooks/useAuthoriseUser';
import { Select } from 'components/Select/Select';
import { GET_AUTHORISATION_RIGHTS } from 'graphql/queries/getAuthorisationRights';
import { useCurrentLanguage } from 'hooks/useCurrentLanguage';
import { useGetReadableMutationStatus } from 'hooks/useGetReadableMutationStatus';
import { AUTHORISATIONS } from 'routes';
import { MutationStatus } from 'types/__generated__';

const TITLE_USERNAME = 'title-username';
const USERNAME_ERROR = 'username-error';
const DEPARTMENT_SELECT = 'department-select';
const DEPARTMENT_ERROR = 'department-error';
const ROLE_SELECT = 'role-select';
const ROLE_ERROR = 'role-error';

interface Props {
  defaultUsername?: string;
}

export function AddAuthorisation({ defaultUsername }: Props) {
  const { t } = useTranslation('authorisations', { keyPrefix: 'add-authorisation' });
  const currentLanguage = useCurrentLanguage();
  const readableMutationStatus = useGetReadableMutationStatus();
  const { open, close, isOpen } = useOverlayTriggerState({});
  const navigate = useNavigate();

  const [authorisationRights, setAuthorisationRights] =
    useState<GetAuthorisationRightsQuery['marblesAuthorisationRights']>();
  const [userName, setUserName] = useState<string>(defaultUsername || '');
  const [usernameError, setUsernameError] = useState<string | undefined>();
  const [selectedDepartmentId, setSelectedDepartmentId] = useState<string>('');
  const [departmentError, setDepartmentError] = useState<string | undefined>();
  const [selectedRole, setSelectedRole] = useState<string>('');
  const [roleError, setRoleError] = useState<string | undefined>();
  const [mutationError, setMutationError] = useState<string>('');
  const [queryError, setQueryError] = useState<string>('');

  const [mutate, mutation] = useAuthoriseUser();
  const [query, lazyGetAuthorisationRightsQuery] = useLazyQuery<GetAuthorisationRightsQuery>(GET_AUTHORISATION_RIGHTS);

  const resetAndClose = () => {
    setUserName(defaultUsername || '');
    setSelectedRole('');
    setSelectedDepartmentId('');
    setUsernameError(undefined);
    setDepartmentError(undefined);
    setRoleError(undefined);
    setMutationError('');
    close();
  };

  const onClick = () => {
    open();
    query({
      fetchPolicy: 'cache-and-network',
      onCompleted(data) {
        if (!data.marblesAuthorisationRights) {
          setQueryError(t('modal.no-authorisation-rights'));
          return;
        }
        setAuthorisationRights(data.marblesAuthorisationRights);
        return;
      },
      onError() {
        setAuthorisationRights(undefined);
        setQueryError('modal.no-authorisation-rights');
      },
    });
  };

  const onUsernameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setUsernameError(undefined);
    setUserName(event.target.value);
  };

  const onDepartmentChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setDepartmentError(undefined);
    setSelectedDepartmentId(event.target.value);
  };

  const onRoleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setRoleError(undefined);
    setSelectedRole(event.target.value);
  };

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!userName) {
      setUsernameError(t(`modal.${USERNAME_ERROR}`));
      return;
    }

    if (selectedDepartmentId === '') {
      setDepartmentError(t(`modal.${DEPARTMENT_ERROR}`));
      return;
    }

    if (selectedRole === '') {
      setRoleError(t(`modal.${ROLE_ERROR}`));
      return;
    }

    mutate({
      variables: {
        username: defaultUsername || userName,
        departmentId: selectedDepartmentId,
        role: selectedRole,
      },
      onCompleted(data) {
        const { mutationStatus, username } = data.marblesAuthoriseUser;

        if (mutationStatus === MutationStatus.Success) {
          !defaultUsername
            ? navigate(
                generatePath(`${AUTHORISATIONS}/:username`, {
                  username: username,
                })
              )
            : resetAndClose();
          return;
        }

        setMutationError(readableMutationStatus(mutationStatus));
      },
      onError(error) {
        if (error.graphQLErrors.length) {
          setMutationError(readableMutationStatus(MutationStatus.Corrupt));
        } else {
          setMutationError(readableMutationStatus(MutationStatus.Error));
        }
      },
    });
  };

  return (
    <>
      <Button variant="primary" onClick={onClick} disabled={lazyGetAuthorisationRightsQuery.loading}>
        <Icon name="Plus" size={20} />
        {t('trigger')}
      </Button>
      <ModalDialog
        title={t('modal.title')}
        isOpen={isOpen}
        onClose={resetAndClose}
        isDismissable={!mutation.loading}
        buttons={
          <ButtonGroup reversed>
            <Button variant="primary" type="submit" disabled={mutation.loading}>
              {t('modal.buttons.authorise')}
            </Button>
            <Button variant="secondary" onClick={resetAndClose} disabled={mutation.loading}>
              {t('modal.buttons.cancel')}
            </Button>
          </ButtonGroup>
        }
        onSubmit={onSubmit}
      >
        {lazyGetAuthorisationRightsQuery.loading && <Spinner ariaValueText={t('modal.authorisation-loading')} />}
        {authorisationRights && (
          <>
            <FormField outerSpace>
              <Label htmlFor={TITLE_USERNAME}>{t('modal.username-label')}</Label>
              {defaultUsername ? (
                <span>{defaultUsername}</span>
              ) : (
                <>
                  <InputField
                    id={TITLE_USERNAME}
                    value={userName}
                    onChange={onUsernameChange}
                    autoComplete="off"
                    pattern="[a-zA-Z0-9]{3,9}$"
                    aria-describedby={usernameError ? USERNAME_ERROR : undefined}
                  />
                  {usernameError && <FeedbackBox level="error" feedback={usernameError} />}
                </>
              )}
            </FormField>
            <FormField outerSpace>
              <Label htmlFor={DEPARTMENT_SELECT}>{t('modal.department-label')}</Label>
              <Select
                id={DEPARTMENT_SELECT}
                value={selectedDepartmentId}
                onChange={onDepartmentChange}
                aria-describedby={departmentError ? DEPARTMENT_ERROR : undefined}
              >
                <Select.Option key={'department'} value={''}>
                  {t('modal.placeholder-department-label')}
                </Select.Option>
                {[...authorisationRights.departments].map((department) => (
                  <Select.Option key={department.id} value={department.id}>
                    {department.name[currentLanguage]}
                  </Select.Option>
                ))}
              </Select>
              {departmentError && <FeedbackBox id={DEPARTMENT_ERROR} level="error" feedback={departmentError} />}
            </FormField>

            <FormField outerSpace>
              <Label htmlFor={ROLE_SELECT}>{t('modal.role-label')}</Label>
              <Select
                id={ROLE_SELECT}
                value={selectedRole}
                onChange={onRoleChange}
                aria-describedby={roleError ? ROLE_ERROR : undefined}
              >
                <Select.Option key={'role'} value={''}>
                  {t('modal.placeholder-role-label')}
                </Select.Option>
                {[...authorisationRights.userRoles].map((role) => (
                  <Select.Option key={role.name} value={role.name}>
                    {role.description[currentLanguage]}
                  </Select.Option>
                ))}
              </Select>
              {roleError && <FeedbackBox id={ROLE_ERROR} level="error" feedback={roleError} />}
            </FormField>
          </>
        )}
        {queryError && <FeedbackBox level="warning" feedback={queryError} />}
        {mutationError && <FeedbackBox level="error" feedback={mutationError} />}
      </ModalDialog>
    </>
  );
}
