import { useOverlayTriggerState } from '@react-stately/overlays';
import {
  Button,
  ButtonGroup,
  FeedbackBox,
  Icon,
  Label,
  ModalDialog,
  Spinner,
  FormField,
} from '@uva-glass/component-library';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { ChangeEvent, FormEvent } from 'react';
import type {
  GetTopicSetsByDepartmentIdQuery,
  SetTopicSetByCourseOfferingIdMutationVariables,
} from 'types/__generated__';

import { Select } from 'components/Select/Select';
import { useGetCourseOffering } from 'hooks/useGetCourseOffering';
import { useGetReadableMutationStatus } from 'hooks/useGetReadableMutationStatus';
import { useSetTopicSetByCourseOfferingId } from 'hooks/useSetTopicSetByCourseOfferingId';
import { useAuthorisation } from 'providers/AuthorisationProvider';
import { MutationStatus, UserAction } from 'types/__generated__';
import { alphabeticalSortBy } from 'util/alphabeticalSortBy';

const SELECT_ID = 'link-topic-set-select-id';
const SELECT_ERROR_ID = 'link-topic-error-id';

interface Props {
  topicSets?: GetTopicSetsByDepartmentIdQuery['topicSets'];
  isDisabled: boolean;
}

export function LinkTopicSet({ topicSets, isDisabled }: Props) {
  const { t } = useTranslation('course-offering', { keyPrefix: 'link-topic-set' });
  const { close, isOpen, open } = useOverlayTriggerState({});
  const readableMutationStatus = useGetReadableMutationStatus();
  const { marblesCourseOffering } = useGetCourseOffering();
  const { departmentId, academicYear } = marblesCourseOffering;
  const userCanEdit = useAuthorisation(UserAction.EditCourseSettings, departmentId);

  const [topicSetId, setTopicSetId] = useState<SetTopicSetByCourseOfferingIdMutationVariables['topicSetId']>();
  const [mutationError, setMutationError] = useState('');
  const [mutate, mutation] = useSetTopicSetByCourseOfferingId({ academicYear: academicYear.id, departmentId });

  async function onSubmit(event: FormEvent) {
    event.preventDefault();

    if (!topicSetId) {
      setMutationError(t('modal.select-a-topic-set'));
      return;
    }

    mutate({
      variables: { courseOfferingId: marblesCourseOffering?.id, topicSetId },
      onCompleted(data) {
        const { mutationStatus } = data.setCourseOfferingTopicSet;

        switch (mutationStatus) {
          case MutationStatus.Success:
          case MutationStatus.Duplicate:
          case MutationStatus.CourseOfferingPendingPursuits:
            // Not showing an error message; when it turns out that there are pending students for
            // the current course, a re-fetch is performed and this component will be unmounted.
            // This causes the error message to be shown only briefly. There is already an error mesage
            // on screen showing that the topic set cannot be (un)linked.

            resetAndClose();
            break;

          default:
            setMutationError(readableMutationStatus(mutationStatus || 'unknown'));
            break;
        }
      },
      onError(error) {
        if (error.graphQLErrors.length) {
          setMutationError(readableMutationStatus(MutationStatus.Corrupt));
        } else {
          setMutationError(readableMutationStatus(MutationStatus.Error));
        }
      },
    });
  }

  function onChange(event: ChangeEvent<HTMLSelectElement>) {
    const { value } = event.target;

    setTopicSetId(value);
  }

  function resetAndClose() {
    setTopicSetId(undefined);
    setMutationError('');
    mutation.reset();
    close();
  }

  return (
    <>
      {userCanEdit && !academicYear.isArchived ? (
        <>
          <p>{t('no-topics')}</p>
          <Button onClick={open} variant="secondary" disabled={isDisabled || !topicSets?.length}>
            <Icon name="Link" size={20} />
            {t('trigger')}
          </Button>
        </>
      ) : (
        <p>{t('no-topics-no-edit')}</p>
      )}

      <ModalDialog
        title={t('modal.title')}
        onSubmit={onSubmit}
        buttons={
          <ButtonGroup reversed>
            <Button type="submit" variant="primary" disabled={mutation.loading}>
              {t('modal.buttons.link')}
            </Button>
            <Button variant="secondary" onClick={resetAndClose}>
              {t('modal.buttons.cancel')}
            </Button>
          </ButtonGroup>
        }
        isOpen={isOpen}
        onClose={resetAndClose}
      >
        {topicSets?.length ? (
          <FormField>
            <Label htmlFor={SELECT_ID}>{t('modal.label')}</Label>
            <Select id={SELECT_ID} onChange={onChange} value={topicSetId ?? ''}>
              <Select.Option value=""></Select.Option>
              {[...topicSets].sort(alphabeticalSortBy('name')).map(({ id, name }) => (
                <Select.Option key={id} value={id}>
                  {name}
                </Select.Option>
              ))}
            </Select>
            {mutationError && <FeedbackBox id={SELECT_ERROR_ID} level="error" feedback={mutationError} />}
          </FormField>
        ) : (
          <Spinner ariaValueText={t('modal.loading')} />
        )}
      </ModalDialog>
    </>
  );
}
