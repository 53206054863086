import { Fragment } from 'react';
import { ButtonGroup } from '@uva-glass/component-library';

import type { GroupRequirementNode, RequiredCareer, RuleFragment } from 'types/__generated__';

import { EditRequiredCareer } from './EditRequiredCareer';
import styles from './Career.module.css';

import { useCurrentLanguage } from 'hooks/useCurrentLanguage';

interface Props {
  careers: RequiredCareer['careers'];
  nodeId: GroupRequirementNode['id'];
  ruleId: RuleFragment['id'];
  canEdit: boolean;
}

export function Career({ careers, nodeId, ruleId, canEdit }: Props) {
  const currentLanguage = useCurrentLanguage();

  return (
    <div className={styles['career']}>
      <div>
        {[...careers]
          .sort((career1, career2) => Number(career1.code) - Number(career2.code))
          .map((career) => (
            <Fragment key={career.id}>
              {career.code} - {career.description[currentLanguage]}
              <br />
            </Fragment>
          ))}
      </div>

      {canEdit && (
        <ButtonGroup>
          <EditRequiredCareer careers={careers} nodeId={nodeId} ruleId={ruleId} />
        </ButtonGroup>
      )}
    </div>
  );
}
