import type { GetFiltersQuery } from 'types/__generated__';

import { courseOfferingsFilterVar } from 'graphql/courseOfferingsFilterVar';
import { write } from 'util/localStorage';
import { STORAGE_KEYS } from 'util/storagekeys';

type UsePersistCourseOfferingsFilterReturn = {
  persistDepartmentId(value: Pick<GetFiltersQuery['courseOfferingsFilter'], 'departmentId'>): void;
  persistAcademicYear(value: Pick<GetFiltersQuery['courseOfferingsFilter'], 'academicYear'>): void;
};

export function usePersistCourseOfferingsFilter(): UsePersistCourseOfferingsFilterReturn {
  const previousValue = courseOfferingsFilterVar();

  function persistDepartmentId(value: Pick<GetFiltersQuery['courseOfferingsFilter'], 'departmentId'>) {
    if (previousValue.departmentId === value.departmentId) return;

    const courseOfferingsFilter = { ...previousValue, ...value };

    courseOfferingsFilterVar(courseOfferingsFilter);
    write(STORAGE_KEYS.DEPARTMENT_ID, courseOfferingsFilter.departmentId);
  }

  function persistAcademicYear(value: Pick<GetFiltersQuery['courseOfferingsFilter'], 'academicYear'>) {
    if (previousValue.academicYear === value.academicYear || !value.academicYear) return;

    const courseOfferingsFilter = { ...previousValue, ...value };

    courseOfferingsFilterVar(courseOfferingsFilter);
    write(STORAGE_KEYS.ACADEMIC_YEAR, courseOfferingsFilter.academicYear);
  }

  return { persistDepartmentId, persistAcademicYear };
}
