import { gql } from '@apollo/client';

import { RULE_FRAGMENT } from 'graphql/fragments/ruleFragment';

export const UPDATE_REQUIRED_COURSES = gql`
  mutation updateRequiredCourses(
    $ruleId: String!
    $nodeId: String!
    $description: BilingualStringInput!
    $courses: RequiredCoursesInput!
  ) {
    updateRequiredCourses(input: { ruleId: $ruleId, nodeId: $nodeId, description: $description, courses: $courses }) {
      nodeId
      mutationStatus
      rule {
        ...rule
      }
    }
  }
  ${RULE_FRAGMENT}
`;
