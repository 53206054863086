import { gql } from '@apollo/client';

export const DEREGISTER_STUDENT_FOR_COURSE = gql`
  mutation deregisterStudentForCourse($coursePursuitId: String!, $reason: StaffDeregistrationReason) {
    deregisterStudentForCourse(input: { coursePursuitId: $coursePursuitId, reason: $reason }) {
      mutationStatus
      coursePursuitId
      courseOfferingId
    }
  }
`;
