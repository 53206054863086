import { forwardRef } from 'react';
import { clsx } from 'clsx';

import type { InputHTMLAttributes } from 'react';

import styles from './Input.module.css';

interface Props extends Omit<InputHTMLAttributes<HTMLInputElement>, 'className' | 'style'> {
  appearance?: 'error';
  type?: 'text' | 'search' | 'password' | 'email' | 'file';
  fullWidth?: boolean;
  id: string;
  isNumeric?: boolean;
}

export const Input = forwardRef<HTMLInputElement, Props>(function Input(
  { id, appearance, type = 'text', isNumeric, fullWidth, ...restProps }: Props,
  forwardedRef
) {
  return (
    <input
      {...restProps}
      ref={forwardedRef}
      id={id}
      className={clsx(styles['input'], {
        [styles['input--error']]: appearance === 'error',
        [styles['input--full-with']]: fullWidth,
        [styles['input--numeric']]: isNumeric,
      })}
      type={type}
    />
  );
});
