import { useMutation } from '@apollo/client';

import type { UpdateRequirementNameMutation, UpdateRequirementNameMutationVariables } from 'types/__generated__';

import { UPDATE_REQUIREMENT_NAME } from 'graphql/mutations/updateRequirementName';
import { MutationStatus } from 'types/__generated__';
import { GET_REQUIREMENTS_BY_DEPARTMENT_ID } from 'graphql/queries/getRequirementsByDepartmentId';

export const useUpdateRequirementName = () =>
  useMutation<UpdateRequirementNameMutation, UpdateRequirementNameMutationVariables>(UPDATE_REQUIREMENT_NAME, {
    // wait for the refetch to have completed, before returning the result of the mutation; this way
    // the interface is already updated with the correct data before any redirect takes place
    awaitRefetchQueries: true,
    refetchQueries({ data }) {
      if (data?.updateRequirementName.mutationStatus === MutationStatus.NotFound) {
        return [GET_REQUIREMENTS_BY_DEPARTMENT_ID];
      }

      return [];
    },
  });
