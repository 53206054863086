import { useMutation } from '@apollo/client';

import type {
  CreateRequirementMutation,
  CreateRequirementMutationVariables,
  GetRequirementsByDepartmentIdQuery,
  GetRequirementsByDepartmentIdQueryVariables,
} from 'types/__generated__';

import { MutationStatus } from 'types/__generated__';
import { CREATE_REQUIREMENT } from 'graphql/mutations/createRequirement';
import { GET_REQUIREMENTS_BY_DEPARTMENT_ID } from 'graphql/queries/getRequirementsByDepartmentId';

export const useCreateRequirement = () =>
  useMutation<CreateRequirementMutation, CreateRequirementMutationVariables>(CREATE_REQUIREMENT, {
    update(cache, { data }) {
      if (data?.createRequirement.mutationStatus !== MutationStatus.Success) return;

      // If creating a new Entry Requirement was successful the NewEntryRequirement component will automatically
      // navigate the user to the newly created Entry Requirement.
      // That would usually require the cache to be manually updated here for the `getRequirementById` query for the new
      // `requirement.id`, however, because (for now!) requirements are real time, this isn't necessary.

      const { requirement, academicYear } = data.createRequirement;

      if (!requirement) {
        throw new Error('No "requirement" in payload');
      }

      cache.updateQuery<GetRequirementsByDepartmentIdQuery, GetRequirementsByDepartmentIdQueryVariables>(
        {
          query: GET_REQUIREMENTS_BY_DEPARTMENT_ID,
          variables: { academicYear, departmentId: requirement.department.id, includeParentDepartments: true },
        },
        (cached) => ({ requirements: cached ? [...cached.requirements, requirement] : [requirement] })
      );
    },
  });
