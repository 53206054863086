import { gql } from '@apollo/client';

export const GET_TOPICS_BY_TOPIC_SET_ID = gql`
  query getTopicsByTopicSetId($topicSetId: String!) {
    topicSet(topicSetId: $topicSetId) {
      id
      topics {
        id
        title {
          NL
          EN
        }
        requirement {
          id
          name
        }
      }
    }
  }
`;
