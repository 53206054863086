import { gql } from '@apollo/client';

export const STUDENT_GROUP_FRAGMENT = gql`
  fragment studentGroup on StudentGroup {
    id
    code
    name
    initialCapacity
    configuredCapacity
    capacity
    locked
    numberOfRegisteredStudents
    directRegistrationEnabled
    timetableSummary {
      NL
      EN
    }
  }
`;
