import { gql } from '@apollo/client';

import { RULE_NODES_FRAGMENT } from './ruleNodesFragment';

export const RULE_FRAGMENT = gql`
  fragment rule on Rule {
    id
    name
    ruleKind
    requirements {
      id
      department {
        id
        name {
          EN
          NL
        }
        parentId
      }
      name
    }
    department {
      id
      name {
        EN
        NL
      }
      allowedUserActions
    }
    nodes {
      ...ruleNodes
    }
  }
  ${RULE_NODES_FRAGMENT}
`;
