import { gql } from '@apollo/client';

export const GET_STUDENT_PURSUIT_AUDIT_BY_STUDENT_ID = gql`
  query getStudentPursuitAuditByStudentId($studentId: String!) {
    studentPursuitAudit(studentId: $studentId) {
      academicYear {
        id
        name
      }
      timeStamp
      action
      username
      course {
        NL
        EN
      }
      courseOfferingId
      catalogNumber
      new {
        NL
        EN
      }
      old {
        NL
        EN
      }
      deregistrationReason
      registrationRound {
        id
        description {
          NL
          EN
        }
      }
    }
  }
`;
