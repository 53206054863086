import { useLazyQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import type {
  GetCourseOfferingsQueryVariables,
  GetRegistrationsExportLinkQuery,
  GetRegistrationsExportLinkQueryVariables,
} from 'types/__generated__';

import { PendingOrRegistered } from 'types/__generated__';
import { GET_REGISTRATIONS_EXPORT_LINK } from 'graphql/queries/getRegistrationsExportLink';
import { downloadFile } from 'util/downloadFile';

export function ExportRegisteredRegistrations({ departmentId, academicYear }: GetCourseOfferingsQueryVariables) {
  const { t } = useTranslation('course-offerings', { keyPrefix: 'course-offerings' });

  const [lazyQuery] = useLazyQuery<GetRegistrationsExportLinkQuery, GetRegistrationsExportLinkQueryVariables>(
    GET_REGISTRATIONS_EXPORT_LINK,
    {
      variables: { departmentId, academicYear, status: PendingOrRegistered['Registered'] },
      fetchPolicy: 'network-only',
    }
  );

  async function onClick() {
    const { data } = await lazyQuery();

    if (data) {
      downloadFile(data.courseRegistrationsExport.url);
    }
  }

  return (
    <button type="button" onClick={onClick}>
      {t('export-action-list.options.registered-students')}
    </button>
  );
}
