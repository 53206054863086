import { useLazyQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Button, Icon } from '@uva-glass/component-library';

import type { GetGroupsExportLinkQuery, GetGroupsExportLinkQueryVariables } from 'types/__generated__';

import { GET_GROUPS_EXPORT_LINK } from 'graphql/queries/getGroupsExportLink';
import { downloadFile } from 'util/downloadFile';

type Props = GetGroupsExportLinkQueryVariables;

export function GroupsExportLink({ courseOfferingId = null, departmentId = null, academicYear = null }: Props) {
  const { t } = useTranslation('course-offering', { keyPrefix: 'groups-export-link' });
  const [query, lazyQuery] = useLazyQuery<GetGroupsExportLinkQuery, GetGroupsExportLinkQueryVariables>(
    GET_GROUPS_EXPORT_LINK,
    {
      variables: { courseOfferingId, departmentId, academicYear },
      fetchPolicy: 'network-only',
    }
  );

  async function onClick() {
    const { data } = await query();

    if (data) {
      downloadFile(data.studentGroupsExport.url);
    }
  }

  if (lazyQuery.error) {
    return <>Error…</>;
  }

  return (
    <Button variant="secondary" onClick={onClick} disabled={lazyQuery.loading}>
      <Icon name="ArrowUpOnSquare" size={20} />
      {t('button')}
    </Button>
  );
}
