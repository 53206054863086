import { clsx } from 'clsx';

import type { HTMLAttributes, PropsWithChildren } from 'react';
// import type { TableHeaderGroupProps, TableRowProps } from 'react-table';

import styles from './TableRow.module.css';

interface Props extends HTMLAttributes<HTMLTableRowElement> {
  isSelected?: boolean;
  isSubRow?: boolean;
  isLast?: boolean;
}

export const TableRow = ({ children, isSelected, isLast, isSubRow, ...restProps }: PropsWithChildren<Props>) => (
  <tr
    {...restProps}
    className={clsx({
      [styles['table-row--is-selected']]: isSelected,
      [styles['table-row--sub-row']]: isSubRow,
      [styles['table-row--sub-row-last']]: isLast,
    })}
  >
    {children}
  </tr>
);
