import { gql } from '@apollo/client';

import { TOPIC_SET_FRAGMENT } from 'graphql/fragments/topicSetFragment';

export const GET_TOPIC_SETS_BY_DEPARTMENT_ID = gql`
  query getTopicSetsByDepartmentId($departmentId: String!, $academicYear: Int!) {
    topicSets(departmentId: $departmentId, academicYear: $academicYear) {
      ...topicSet
      courseOfferings {
        id
      }
      department {
        id
        name {
          NL
          EN
        }
      }
    }
  }
  ${TOPIC_SET_FRAGMENT}
`;
