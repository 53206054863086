import { gql } from '@apollo/client';

import { PARTIAL_RULE_FRAGMENT } from 'graphql/fragments/partialRuleFragment';

export const CREATE_RULE_REQUIRED_PLAN = gql`
  mutation createRuleRequiredPlan(
    $academicYear: Int!
    $departmentId: String!
    $name: String!
    $plan: RequiredPlanInput!
  ) {
    createRuleRequiredPlan(
      input: { departmentId: $departmentId, academicYear: $academicYear, name: $name, plan: $plan }
    ) {
      academicYear
      rule {
        ...partialRule
      }
      mutationStatus
    }
  }
  ${PARTIAL_RULE_FRAGMENT}
`;
