import { gql } from '@apollo/client';

export const GET_USER_AUTHORISATIONS = gql`
  query getUserAuthorisations($username: String!) {
    marblesUser(username: $username) {
      username
      familyName
      givenName
      status
      roleAssignments {
        id
        createdBy
        creationDate
        department {
          id
          name {
            NL
            EN
          }
        }
        role
        userActions
        userRole {
          name
          description {
            NL
            EN
          }
        }
      }
    }
  }
`;
