import { gql } from '@apollo/client';

export const PERIOD_FRAGMENT = gql`
  fragment period on Period {
    id
    number
    semesterNumber
    semester {
      id
    }
    periodInSemester
  }
`;
