import { gql } from '@apollo/client';

import { RULE_FRAGMENT } from 'graphql/fragments/ruleFragment';

export const UPDATE_RULE_NAME = gql`
  mutation updateRuleName($ruleId: String!, $name: String!) {
    updateRuleName(input: { ruleId: $ruleId, name: $name }) {
      rule {
        ...rule
      }
      mutationStatus
    }
  }
  ${RULE_FRAGMENT}
`;
