import { useQuery } from '@apollo/client';
import { Trans, useTranslation } from 'react-i18next';
import { Navigate, useParams } from 'react-router-dom';
import { Button, Drawer } from '@uva-glass/component-library';
import { useState } from 'react';

import type {
  GetTopicSetByIdQuery,
  GetTopicSetByIdQueryVariables,
  UpdateTopicSetNameMutation,
  UpdateTopicSetNameMutationVariables,
} from 'types/__generated__';

import { NewTopic } from './NewTopic/NewTopic';
import styles from './TopicSet.module.css';
import { TopicsTable } from './TopicsTable/TopicsTable';

import { TopicSetDrawer } from 'App/TopicSets/TopicSetsTable/TopicSetDrawer/TopicSetDrawer';
import { ArchivedYearNotification } from 'App/shared/ArchivedYearNotification/ArchivedYearNotification';
import { EditableHeading } from 'App/shared/EditableHeading/EditableHeading';
import { PageSection } from 'App/shared/PageSection/PageSection';
import { Page } from 'components/Page/Page';
import { GET_TOPIC_SET_BY_ID } from 'graphql/queries/getTopicSetById';
import { useCurrentLanguage } from 'hooks/useCurrentLanguage';
import { UserAction } from 'types/__generated__';
import { useUpdateTopicSetName } from 'App/TopicSets/TopicSet/hooks/useUpdateTopicSetName';
import { PageMultiSubTitle } from 'App/shared/PageMultiSubTitle/PageMultiSubTitle';
import { TOPIC_SETS_NO_PARAMS } from 'routes';

export function TopicSet() {
  const { t } = useTranslation('topic-sets', { keyPrefix: 'topic-set' });
  const currentLanguage = useCurrentLanguage();
  const [topicSetDrawerIsOpen, setTopicSetDrawerIsOpen] = useState(false);

  const updateTopicSetName = useUpdateTopicSetName();
  const { topicSetId } = useParams();

  if (!topicSetId) {
    throw new Error('No id found for topic set');
  }

  const query = useQuery<GetTopicSetByIdQuery, GetTopicSetByIdQueryVariables>(GET_TOPIC_SET_BY_ID, {
    variables: { topicSetId },
  });

  if (query.loading) return null;

  if (!query.data?.topicSet) {
    return <Navigate to="../" />;
  }

  const { topicSet } = query.data;
  const { allowedUserActions } = topicSet.department;
  const userCanEditTopicSet = allowedUserActions.includes(UserAction.EditTopicSets);
  const academicYearIsArchived = topicSet.academicYear.isArchived;

  return (
    <Page
      title={t('title', { name: topicSet?.name })}
      backlink={{
        to: `../${TOPIC_SETS_NO_PARAMS}/${topicSet.academicYear.name}/${topicSet.department.id}`,
        text: t('back'),
      }}
      subTitle={<PageMultiSubTitle values={[topicSet.academicYear.name, topicSet.department.name[currentLanguage]]} />}
      notification={<ArchivedYearNotification academicYear={topicSet.academicYear} />}
    >
      <PageSection
        notification={
          <div className={styles['topics-set__notification']}>
            {`${t('used-in')} `}
            <Button
              variant="blank"
              onClick={() => {
                setTopicSetDrawerIsOpen(true);
              }}
            >
              <Trans
                t={t}
                i18nKey="notification-used"
                values={{ courses: topicSet.courseOfferings.length }}
                components={{ strong: <strong /> }}
              />
            </Button>{' '}
            <Trans
              t={t}
              i18nKey="notification-applied"
              values={{ count: topicSet.courseOfferings.length }}
              components={{ strong: <strong /> }}
            />
          </div>
        }
      >
        {userCanEditTopicSet && !academicYearIsArchived ? (
          <EditableHeading<UpdateTopicSetNameMutation, UpdateTopicSetNameMutationVariables>
            entityId={topicSet.id}
            entityName={topicSet.name}
            mutationName="updateTopicSetName"
            mutationTuple={updateTopicSetName}
            variables={{ topicSetId, name: topicSet.name, titleEN: topicSet.title.EN, titleNL: topicSet.title.NL }}
          />
        ) : (
          <h2 className={styles['topics-set__heading']}>{topicSet.name}</h2>
        )}
        <section className={styles['topics-set__titles']}>
          <h3 className={styles['topics-set__title--heading']}>{t('title-nl')}</h3>
          <p className={styles['topics-set__title']}>{topicSet?.title.NL}</p>
          <h3 className={styles['topics-set__title--heading']}>{t('title-en')}</h3>
          <p className={styles['topics-set__title']}>{topicSet?.title.EN}</p>
        </section>
        <section>
          <header className={styles['topics-set__topics-header']}>
            <h2 className={styles['topics-set__topics-heading']}>{t('topics-title')}</h2>
            {userCanEditTopicSet && !academicYearIsArchived && (
              <NewTopic
                academicYear={topicSet.academicYear.id}
                departmentId={topicSet.departmentId}
                topicSetId={topicSetId}
              />
            )}
          </header>
          <TopicsTable
            academicYear={topicSet.academicYear.id}
            topicSetId={topicSetId}
            departmentId={topicSet.departmentId}
          />
        </section>

        {topicSetDrawerIsOpen && topicSet && (
          <Drawer
            isOpen={topicSetDrawerIsOpen}
            onClose={() => {
              setTopicSetDrawerIsOpen(false);
            }}
            title={topicSet.name}
          >
            <TopicSetDrawer topicSetId={topicSetId} hideTopicsTab={true} />
          </Drawer>
        )}
      </PageSection>
    </Page>
  );
}
