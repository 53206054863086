import { useId, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { ChangeEvent } from 'react';
import type { StudentGroup, SwitchStudentGroupMutationVariables } from 'types/__generated__';

import { useSwitchStudentGroup } from './hooks/useSwitchStudentGroup';

import { Select } from 'components/Select/Select';
import { MutationStatus } from 'types/__generated__';

type StudentGroupPartial = Pick<StudentGroup, 'id' | 'name'>;

interface Props extends Pick<SwitchStudentGroupMutationVariables, 'coursePursuitId'> {
  studentGroups: StudentGroupPartial[];
  currentGroup: StudentGroupPartial;
  studentId?: string;
}

export function SwitchStudentGroup({ coursePursuitId, currentGroup, studentGroups, studentId }: Props) {
  const { t } = useTranslation('common', { keyPrefix: 'switch-student-group' });
  const studentGroupSelectId = useId();
  const [mutate, mutation] = useSwitchStudentGroup(studentId);
  const [selectedStudentGroupId, setSelectedStudentGroupId] = useState(currentGroup.id);

  async function handleChange(event: ChangeEvent<HTMLSelectElement>) {
    const studentGroupId = event.target.value;

    setSelectedStudentGroupId(studentGroupId);

    await mutate({
      variables: { coursePursuitId, studentGroupId },
      optimisticResponse: {
        switchStudentGroup: {
          mutationStatus: MutationStatus.Success,
          coursePursuitId,
          studentGroupId,
        },
      },
    });
  }

  return (
    <>
      <label className="visually-hidden" htmlFor={studentGroupSelectId}>
        {t('label')}
      </label>
      <Select
        id={studentGroupSelectId}
        onChange={handleChange}
        value={selectedStudentGroupId}
        disabled={mutation.loading}
        variant="small"
      >
        {studentGroups.map(({ id, name }) => (
          <Select.Option key={id} value={id}>
            {name}
          </Select.Option>
        ))}
      </Select>
    </>
  );
}
