import { gql } from '@apollo/client';

import { ACADEMIC_YEAR_FRAGMENT } from 'graphql/fragments/academicYearFragment';
import { DEPARTMENT_FRAGMENT } from 'graphql/fragments/departmentFragment';

export const GET_FILTERS = gql`
  query getFilters {
    marblesDepartments {
      ...department
    }
    marblesAcademicYears {
      ...academicYear
    }
    courseOfferingsFilter @client {
      departmentId
      academicYear
    }
  }
  ${DEPARTMENT_FRAGMENT}
  ${ACADEMIC_YEAR_FRAGMENT}
`;
