import { gql } from '@apollo/client';

export const REJECT_PENDING_COURSEPURSUIT = gql`
  mutation rejectPendingCoursePursuit($coursePursuitId: String!) {
    rejectPendingCoursePursuit(input: { coursePursuitId: $coursePursuitId }) {
      mutationStatus
      coursePursuitId
      courseOfferingId
    }
  }
`;
