import { gql } from '@apollo/client';

export const UPLOAD_BULK_COURSE_DEREGISTRATIONS = gql`
  mutation uploadBulkCourseDeregistrations(
    $academicYear: Int!
    $file: Upload!
    $deregistrationReason: StaffDeregistrationReason!
    $ignorePursuitsNotFound: Boolean! = true
  ) {
    uploadBulkCourseDeregistrations(
      input: {
        academicYear: $academicYear
        file: $file
        deregistrationReason: $deregistrationReason
        ignorePursuitsNotFound: $ignorePursuitsNotFound
      }
    ) {
      mutationStatus
      result {
        line
        rowStatus
        studentNumber
      }
      pursuits {
        id
      }
      details
      missingColumns
    }
  }
`;
