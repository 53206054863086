import { useId } from 'react';
import { useTranslation } from 'react-i18next';

import type { Column } from '@tanstack/react-table';

import { Select } from 'components/Select/Select';

interface Props<TData> {
  column: Column<TData>;
  label: string;
  options: {
    value: string;
    content: string;
  }[];
}

export function TableFilterSelect<T>({ column, label, options }: Props<T>) {
  const { t } = useTranslation('common', { keyPrefix: 'table-filter-select' });
  const { getFilterValue, setFilterValue } = column;
  const selectId = useId();

  return (
    <>
      <label className="visually-hidden" htmlFor={selectId}>
        {label}
      </label>
      <Select
        id={selectId}
        onChange={(event) => setFilterValue(event.target.value || undefined)}
        value={getFilterValue() as string | undefined}
        variant="small"
      >
        <Select.Option value="">{t('all')}</Select.Option>

        {options.map(({ value, content }) => (
          <Select.Option key={content} value={value}>
            {content}
          </Select.Option>
        ))}
      </Select>
    </>
  );
}
