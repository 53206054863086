import { useMutation } from '@apollo/client';

import type { StoreObject } from '@apollo/client';
import type {
  GetConstrainedChoiceClustersByDepartmentIdQuery,
  GetConstrainedChoiceClustersByDepartmentIdQueryVariables,
  RemoveConstrainedChoiceClusterInput,
  RemoveConstrainedChoiceClusterMutation,
} from 'types/__generated__';

import { REMOVE_CONSTRAINED_CHOICE_CLUSTER } from 'graphql/mutations/removeConstrainedChoiceCluster';
import { GET_CONSTRAINED_CHOICE_CLUSTERS_BY_DEPARTMENT_ID } from 'graphql/queries/getConstrainedChoiceClustersByDepartmentId';
import { MutationStatus } from 'types/__generated__';

export const useRemoveConstrainedChoiceCluster = ({
  academicYear,
  departmentId,
}: GetConstrainedChoiceClustersByDepartmentIdQueryVariables) =>
  useMutation<RemoveConstrainedChoiceClusterMutation, RemoveConstrainedChoiceClusterInput>(
    REMOVE_CONSTRAINED_CHOICE_CLUSTER,
    {
      update: (cache, { data }) => {
        if (data?.removeConstrainedChoiceCluster.mutationStatus !== MutationStatus.Success) return;

        const { constrainedChoiceClusterId } = data.removeConstrainedChoiceCluster;

        if (!constrainedChoiceClusterId) {
          throw new Error('No "constrainedChoiceClusterId" in payload');
        }

        const constrainedChoiceClusterToEvict: StoreObject = {
          __typename: 'ConstrainedChoiceCluster',
          id: constrainedChoiceClusterId,
        };

        cache.updateQuery<
          GetConstrainedChoiceClustersByDepartmentIdQuery,
          GetConstrainedChoiceClustersByDepartmentIdQueryVariables
        >(
          {
            query: GET_CONSTRAINED_CHOICE_CLUSTERS_BY_DEPARTMENT_ID,
            variables: { academicYear, departmentId },
          },
          (cached) =>
            cached
              ? {
                  constrainedChoiceClusters: cached.constrainedChoiceClusters.filter(
                    (constrainedChoiceCluster) => constrainedChoiceCluster.id !== constrainedChoiceClusterId
                  ),
                }
              : undefined
        );
        cache.evict({ id: cache.identify(constrainedChoiceClusterToEvict) });
        cache.gc();
      },
      refetchQueries({ data }) {
        // Re-fetching the constrainedChoiceCluster(s) to display the correct data on screen
        if (
          // Getting a ConstrainedChoiceClusterNotFound means that the constrainedChoiceCluster has already been removed (other browser / user)
          data?.removeConstrainedChoiceCluster.mutationStatus === MutationStatus.ConstrainedChoiceClusterNotFound
        ) {
          return [GET_CONSTRAINED_CHOICE_CLUSTERS_BY_DEPARTMENT_ID];
        }
        return [];
      },
    }
  );
