import { gql } from '@apollo/client';

export const REQUIREMENTS_FRAGMENT = gql`
  fragment requirements on Requirements {
    groups {
      name
      descriptions {
        NL
        EN
      }
      applies
      rows {
        title {
          NL
          EN
        }
        status
        progress {
          NL
          EN
        }
        details {
          status
          courseInfo {
            id
            catalogNumber
            title {
              EN
              NL
            }
            ec
          }
        }
      }
    }
  }
`;
