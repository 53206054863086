import { useMutation } from '@apollo/client';

import type { StoreObject } from '@apollo/client';
import type {
  SetCourseOfferingCreditLimitMutation,
  SetCourseOfferingCreditLimitMutationVariables,
} from 'types/__generated__';

import { SET_COURSE_OFFERING_CREDIT_LIMIT_EXCEPTION } from 'graphql/mutations/setCourseOfferingCreditLimitException';
import { GET_COURSE_OFFERING_BY_ID } from 'graphql/queries/getCourseOfferingById';
import { MutationStatus } from 'types/__generated__';

export const useSetCourseOfferingCreditLimitException = () =>
  useMutation<SetCourseOfferingCreditLimitMutation, SetCourseOfferingCreditLimitMutationVariables>(
    SET_COURSE_OFFERING_CREDIT_LIMIT_EXCEPTION,
    {
      update(cache, { data }) {
        if (data?.setCourseOfferingCreditLimitException.mutationStatus !== MutationStatus.Success) return;

        const { courseOfferingId, hasCreditLimitException } = data.setCourseOfferingCreditLimitException;
        const CourseOffering: StoreObject = { __typename: 'MarblesCourseOffering', id: courseOfferingId };

        cache.modify({
          id: cache.identify(CourseOffering),
          fields: {
            hasCreditLimitException() {
              return hasCreditLimitException;
            },
          },
        });
      },
      refetchQueries({ data }) {
        const mutationStatus = data?.setCourseOfferingCreditLimitException.mutationStatus;

        if (mutationStatus === MutationStatus.Success) return [];

        return [GET_COURSE_OFFERING_BY_ID];
      },
    }
  );
