import { useMutation } from '@apollo/client';

import type {
  GetTopicsByTopicSetIdQuery,
  GetTopicsByTopicSetIdQueryVariables,
  UpdateTopicMutation,
  UpdateTopicMutationVariables,
} from 'types/__generated__';

import { UPDATE_TOPIC } from 'graphql/mutations/updateTopic';
import { GET_REQUIREMENTS_BY_DEPARTMENT_ID } from 'graphql/queries/getRequirementsByDepartmentId';
import { GET_TOPICS_BY_TOPIC_SET_ID } from 'graphql/queries/getTopicsByTopicSetId';
import { MutationStatus } from 'types/__generated__';

export const useUpdateTopic = () =>
  useMutation<UpdateTopicMutation, UpdateTopicMutationVariables>(UPDATE_TOPIC, {
    update(cache, { data }) {
      if (data?.updateTopic.mutationStatus !== MutationStatus.Success) return;

      const { topic } = data.updateTopic;

      if (!topic) {
        throw new Error('No "topic" in payload');
      }

      // sorts through the topics to find and update the correct topic
      function updateTopicInTopics(
        array: NonNullable<GetTopicsByTopicSetIdQuery['topicSet']>['topics'],
        id: string,
        newValues: typeof topic
      ) {
        return array.map((topic) => {
          if (topic.id === id) {
            return { ...topic, ...newValues };
          }
          return topic;
        });
      }

      cache.updateQuery<GetTopicsByTopicSetIdQuery, GetTopicsByTopicSetIdQueryVariables>(
        {
          query: GET_TOPICS_BY_TOPIC_SET_ID,
          variables: { topicSetId: topic.topicSetId },
        },
        (cached) =>
          cached &&
          cached.topicSet && {
            topicSet: {
              id: topic.topicSetId,
              topics: updateTopicInTopics([...cached.topicSet.topics], topic.id, topic),
            },
          }
      );
    },
    refetchQueries({ data }) {
      switch (data?.updateTopic.mutationStatus) {
        // Getting a RequirementNotFound status means that the requirement has been removed in another browser tab or by another user.
        // Getting a RequirementNotAvailable status means that the departmentId of the chosen requirement does not equal the
        // department of the topic set or one of its parents.
        case MutationStatus.RequirementNotFound:
        case MutationStatus.RequirementNotAvailable:
          // Re-fetching the requirements query to display the correct data on screen
          return [GET_REQUIREMENTS_BY_DEPARTMENT_ID];

        // Getting a TopicNotFound status means that the topic has been removed in another browser tab or by another user.
        case MutationStatus.TopicNotFound:
          // Re-fetching the topics query to display the correct data on screen
          return [GET_TOPICS_BY_TOPIC_SET_ID];

        default:
          return [];
      }
    },
  });
