import { useMutation } from '@apollo/client';

import type { StoreObject } from '@apollo/client';
import type {
  RegisterStudentForCourseMutation,
  RegisterStudentForCourseMutationVariables,
  GetRegisteredPursuitsByCourseOfferingIdQuery,
  GetRegisteredPursuitsByCourseOfferingIdQueryVariables,
  GetPendingPursuitsByCourseOfferingIdQuery,
  GetPendingPursuitsByCourseOfferingIdQueryVariables,
  RegisteredCoursePursuitFragment,
} from 'types/__generated__';

import { MutationStatus } from 'types/__generated__';
import { REGISTER_STUDENT_FOR_COURSE } from 'graphql/mutations/registerStudentForCourse';
import { GET_REGISTERED_PURSUITS_BY_COURSE_OFFERING_ID } from 'graphql/queries/getRegisteredPursuitsByCourseOfferingId';
import { GET_PENDING_PURSUITS_BY_COURSE_OFFERING_ID } from 'graphql/queries/getPendingPursuitsByCourseOfferingId';

export const useRegisterStudentForCourse = (academicYear: number) =>
  useMutation<RegisterStudentForCourseMutation, RegisterStudentForCourseMutationVariables>(
    REGISTER_STUDENT_FOR_COURSE,
    {
      update(cache, { data }) {
        if (data?.registerStudentForCourse.mutationStatus !== MutationStatus.Success) return;

        const { courseOfferingId, coursePursuitId, student, studentGroup, registrationDate, requirementsCheck } =
          data.registerStudentForCourse;

        if (!courseOfferingId || !coursePursuitId || !student || !studentGroup) {
          throw new Error(
            'One or more of "courseOfferingId", "coursePursuitId", "student", or "studentGroupId" not defined.'
          );
        }

        const courseOffering: StoreObject = { __typename: 'MarblesCourseOffering', id: courseOfferingId };

        const newCoursePursuit: RegisteredCoursePursuitFragment = {
          __typename: 'CoursePursuit',
          id: coursePursuitId,
          registrationDate,
          student,
          studentGroup,
          requirementsCheck,
        };

        const updatedRegistered = cache.updateQuery<
          GetRegisteredPursuitsByCourseOfferingIdQuery,
          GetRegisteredPursuitsByCourseOfferingIdQueryVariables
        >(
          { query: GET_REGISTERED_PURSUITS_BY_COURSE_OFFERING_ID, variables: { courseOfferingId, academicYear } },
          (cached) => ({
            courseOfferingPursuits: cached ? [...cached.courseOfferingPursuits, newCoursePursuit] : [newCoursePursuit],
          })
        );

        const updatedPending = cache.updateQuery<
          GetPendingPursuitsByCourseOfferingIdQuery,
          GetPendingPursuitsByCourseOfferingIdQueryVariables
        >(
          { query: GET_PENDING_PURSUITS_BY_COURSE_OFFERING_ID, variables: { courseOfferingId, academicYear } },
          (cached) =>
            cached
              ? // only update the cache for pending if it already existed in the cache
                {
                  courseOfferingPursuits: [...cached.courseOfferingPursuits.filter(({ id }) => id !== coursePursuitId)],
                }
              : // returning undefined leaves the non-existing cache alone
                undefined
        );

        cache.modify({
          id: cache.identify(courseOffering),
          fields: {
            numberOfRegisteredStudents(currentValue) {
              return updatedRegistered?.courseOfferingPursuits.length ?? currentValue;
            },
            numberOfPendingStudents(currentValue) {
              return updatedPending?.courseOfferingPursuits.length ?? currentValue;
            },
          },
        });
      },
    }
  );
