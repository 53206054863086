import { Navigate, useLocation } from 'react-router-dom';

import type { ReactElement } from 'react';

import { LOGIN } from 'routes';
import { useAuth } from 'providers/AuthProvider';

interface Props {
  children: ReactElement;
}

export function RequireAuth({ children }: Props) {
  const user = useAuth();
  const location = useLocation();

  if (user !== null) {
    return children;
  }

  return <Navigate to={LOGIN} state={location} />;
}
