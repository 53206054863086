import styles from './ActionHeader.module.css';

import { HelpLink } from 'App/shared/HelpLink/HelpLink';
import { LanguageToggle } from 'App/shared/LanguageToggle/LanguageToggle';
import { SigninOrSignout } from 'App/shared/SigninOrSignout/SigninOrSignout';
import UvALogo from 'assets/images/UvALogo.svg';

export function ActionHeader() {
  return (
    <div className={styles['action-header']}>
      <div className={styles['action-header__menu']}>
        <a href="/">
          <img src={UvALogo} alt="" className={styles['action-header-logo']} />
        </a>
      </div>
      <div className={styles['action-header__actions']} id="action-header-actions" tabIndex={-1}>
        <LanguageToggle />
        <HelpLink />
        <SigninOrSignout />
      </div>
    </div>
  );
}
