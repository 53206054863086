import type { GetGroupsByCourseOfferingIdQuery } from 'types/__generated__';

export const getGroupIdsWithAvailableCapacity = (
  groupIds: string[],
  allGroups: NonNullable<GetGroupsByCourseOfferingIdQuery['marblesCourseOffering']>['studentGroups']
): string[] => {
  const eligibleGroups = allGroups
    .filter(
      (group) => groupIds.includes(group.id) && !group.locked && group.numberOfRegisteredStudents - group.capacity < 0
    )
    .map((eligibleGroup) => eligibleGroup.id);

  return eligibleGroups;
};
