import { useQuery } from '@apollo/client';
import { createColumnHelper } from '@tanstack/react-table';
import { Button, Drawer, EmptyStateDataDisplay, Spinner } from '@uva-glass/component-library';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, generatePath } from 'react-router-dom';

import type { HeaderContext, VisibilityState } from '@tanstack/react-table';
import type {
  GetRegisteredPursuitsByCourseOfferingIdQuery,
  GetRegisteredPursuitsByCourseOfferingIdQueryVariables,
  GroupSwapStatus,
  RegisteredCoursePursuitFragment,
} from 'types/__generated__';

import { EntryRequirementStatus } from 'App/CourseOffering/EntryRequirementStatus';
import { RegisterStudentForCourseModal } from 'App/CourseOffering/RegisterStudentForCourseModal/RegisterStudentForCourseModal';
import { StudentRequirementStatus } from 'App/CourseOffering/StudentRequirementStatus/StudentRequirementStatus';
import { StudentsExportLink } from 'App/CourseOffering/StudentsExportLink/StudentsExportLink';
import { UploadCourseDeregistrationsModal } from 'App/CourseOffering/UploadCourseDeregistrationsModal/UploadCourseDeregistrationsModal';
import { UploadCourseRegistrationsModal } from 'App/CourseOffering/UploadCourseRegistrationsModal/UploadCourseRegistrationsModal';
import { ActionList } from 'App/shared/ActionList/ActionList';
import { DataTable } from 'App/shared/DataTable/DataTable';
import { DateTime } from 'App/shared/DateTime/DateTime';
import { DeregisterStudentForCourseModal } from 'App/shared/DeregisterStudentForCourseModal/DeregisterStudentForCourseModal';
import { ProgrammeList } from 'App/shared/ProgrammeList/ProgrammeList';
import { SwitchStudentGroup } from 'App/shared/SwitchStudentGroup/SwitchStudentGroup';
import { TableFilterSelect } from 'App/shared/TableFilterSelect/TableFilterSelect';
import { ToggleSwitch } from 'components/ToggleSwitch/ToggleSwitch';
import { Toolbar } from 'components/Toolbar/Toolbar';
import { GET_REGISTERED_PURSUITS_BY_COURSE_OFFERING_ID } from 'graphql/queries/getRegisteredPursuitsByCourseOfferingId';
import { useFormattedDate } from 'hooks/useFormattedDate';
import { useGetCourseOffering } from 'hooks/useGetCourseOffering';
import { useGroupSwapRequestStatus } from 'hooks/useGroupSwapRequestStatus';
import { STUDENTS } from 'routes';
import { PendingOrRegistered, RequirementStatus, UserAction } from 'types/__generated__';
import { TABLE_STORAGE_KEYS } from 'util/storagekeys';

type RowData = RegisteredCoursePursuitFragment;

const columnHelper = createColumnHelper<RowData>();

export function CourseOfferingRegisteredStudents() {
  const { t } = useTranslation('course-offering', { keyPrefix: 'course-offering-registered-students' });
  const formattedDate = useFormattedDate();
  const { marblesCourseOffering, courseOfferingGroupSwaps } = useGetCourseOffering();
  const { studentGroups, id: courseOfferingId, requirementNodeId, department } = marblesCourseOffering;
  const academicYear = marblesCourseOffering.academicYear.id;
  const userCanEditRegistrations = department.allowedUserActions.includes(UserAction.EditRegistrations);
  const [isEditMode, setIsEditMode] = useState(false);
  const query = useQuery<
    GetRegisteredPursuitsByCourseOfferingIdQuery,
    GetRegisteredPursuitsByCourseOfferingIdQueryVariables
  >(GET_REGISTERED_PURSUITS_BY_COURSE_OFFERING_ID, {
    variables: { courseOfferingId, academicYear },
    fetchPolicy: 'cache-and-network',
  });

  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const [currentStudent, setCurrentStudent] = useState<
    GetRegisteredPursuitsByCourseOfferingIdQuery['courseOfferingPursuits'][number]['student'] | null
  >(null);

  const groupSwapRequestStatus = useGroupSwapRequestStatus();

  const initialState = useMemo(() => {
    const columnVisibility: VisibilityState = {};

    if (!requirementNodeId) {
      columnVisibility['requirement-rule'] = false;
      columnVisibility['requirement-status'] = false;
    }

    return {
      sorting: [{ id: 'name', desc: false }],
      columnVisibility,
    };
  }, [requirementNodeId]);

  const columns = [
    columnHelper.accessor(({ student }) => student.studentNumber, {
      id: 'studentNumber',
      header: t('headers.student-number'),
    }),

    columnHelper.accessor(({ student }) => student.name, {
      id: 'name',
      header: t('headers.name'),
      cell: ({ cell, row }) => (
        <Link to={generatePath(`${STUDENTS}/:studentId`, { studentId: row.original.student.id })}>
          {cell.getValue()}
        </Link>
      ),
      meta: { tableCellProps: { noWrap: true } },
    }),

    columnHelper.accessor(({ studentGroup }) => studentGroup?.code, {
      header: t('headers.group-code'),
      meta: { tableCellProps: { maxWidth: 'small' } },
    }),

    columnHelper.accessor(({ studentGroup }) => studentGroup?.name, {
      id: 'group',
      header: t('headers.group'),
      cell: ({ row, cell }) => {
        const currentGroup = row.original.studentGroup;

        if (!currentGroup) return null;

        return isEditMode ? (
          <SwitchStudentGroup
            coursePursuitId={row.original.id}
            currentGroup={currentGroup}
            studentGroups={studentGroups}
          />
        ) : (
          <>{cell.getValue()}</>
        );
      },
      meta: { tableCellProps: { minWidth: 'medium' } },
    }),

    columnHelper.accessor('registrationDate', {
      header: t('headers.registration-date'),
      cell: ({ cell }) =>
        cell.getValue() ? (
          <DateTime isoDate={cell.getValue()} format={{ dateStyle: 'medium', timeStyle: 'short' }} />
        ) : (
          '–'
        ),
      filterFn: (row, _, filterValue: string) =>
        row.original.registrationDate
          ? formattedDate(row.original.registrationDate, { dateStyle: 'medium', timeStyle: 'short' })
              .toLocaleLowerCase()
              .includes(filterValue.toLocaleLowerCase())
          : false,
    }),

    columnHelper.accessor(({ student }) => student.enrolments, {
      id: 'programme',
      header: t('headers.programme'),
      cell: ({ row }) => (
        <ProgrammeList asInlineList includeSubplans includeDates enrolments={row.original.student.enrolments} />
      ),
      filterFn: (row, _, filterValue: string) =>
        row.original.student.enrolments.some(
          ({ plan }) => plan.description.toLocaleLowerCase().indexOf(filterValue.toLocaleLowerCase()) >= 0
        ),
    }),

    columnHelper.accessor(
      ({ requirementsCheck }) =>
        requirementsCheck?.groups.find(({ applies }) => applies)?.name || t('values.not-applicable'),
      {
        id: 'requirement-rule',
        header: t('headers.requirement-rule'),
      }
    ),

    columnHelper.accessor(
      ({ requirementsCheck }) => {
        const applicableRequirementGroup = requirementsCheck?.groups.find(({ applies }) => applies);
        const pass = applicableRequirementGroup?.rows.filter(({ status }) => status === RequirementStatus.Pass).length;
        const total = applicableRequirementGroup?.rows.length;
        const result = pass && total && pass === total ? RequirementStatus.Pass : RequirementStatus.Fail;

        return { pass, total, result };
      },
      {
        id: 'requirement-status',
        header: t('headers.requirement-status'),
        cell: ({ row, cell }) =>
          cell.getValue().pass !== undefined && cell.getValue().total !== undefined ? (
            <Button
              onClick={() => {
                setDrawerIsOpen(true);
                setCurrentStudent(row.original.student);
              }}
              variant="blank"
            >
              <EntryRequirementStatus
                status={cell.getValue().pass === cell.getValue().total ? 'completed' : 'fail'}
                title={cell.getValue().pass === cell.getValue().total ? t('values.pass') : t('values.fail')}
                label={`${cell.getValue().pass}/${cell.getValue().total}`}
              />
            </Button>
          ) : (
            t('values.not-applicable')
          ),
        enableColumnFilter: false,
        sortingFn: (rowA, rowB) => {
          const getPassAndTotal = (group: typeof applicableRequirementGroupA) => {
            if (!group) {
              // include items where group is "n/a" as lowest value of sorting logic
              return { pass: -1, total: -1 };
            }

            const pass = group?.rows.filter(({ status }) => status === RequirementStatus.Pass).length;
            const total = group?.rows.length;
            return { pass, total };
          };

          const applicableRequirementGroupA = rowA.original.requirementsCheck?.groups.find(({ applies }) => applies);
          const applicableRequirementGroupB = rowB.original.requirementsCheck?.groups.find(({ applies }) => applies);

          const { pass: passA, total: totalA } = getPassAndTotal(applicableRequirementGroupA);
          const { pass: passB, total: totalB } = getPassAndTotal(applicableRequirementGroupB);

          return passA === passB ? (totalA === totalB ? 0 : totalB - totalA) : passB - passA;
        },
      }
    ),

    columnHelper.accessor(
      ({ student }) => {
        const status = courseOfferingGroupSwaps.find(({ studentId }) => studentId === student.id)?.status;

        return status || 'None';
      },
      {
        id: 'group-swap-request-status',
        header: t('headers.groups-swap-status'),
        cell: ({ cell }) => groupSwapRequestStatus[cell.getValue()],
        meta: {
          Filter: ({ table, column }: HeaderContext<RowData, unknown>) => {
            const options = Array.from(
              new Set(table.getPreFilteredRowModel().flatRows.map((row) => row.getValue<GroupSwapStatus>(column.id)))
            ).map((value) => ({ value, content: groupSwapRequestStatus[value] }));

            return <TableFilterSelect column={column} label={t('headers.groups-swap-status')} options={options} />;
          },
        },
      }
    ),

    columnHelper.display({
      id: 'remove-student',
      cell: ({ row }) =>
        userCanEditRegistrations &&
        !marblesCourseOffering.academicYear.isArchived && (
          <DeregisterStudentForCourseModal
            coursePursuitId={row.original.id}
            student={row.original.student}
            academicYear={academicYear}
            isDisabled={!isEditMode}
          />
        ),
      meta: { tableCellProps: { hasButton: true } },
      enableColumnFilter: false,
      enableSorting: false,
    }),
  ];

  if (query.loading) {
    return <Spinner ariaValueText={t('loading')} />;
  }
  if (query.error || !query.data) {
    return <>{query.error || 'Error loading data'}</>;
  }

  if (!studentGroups.length) {
    return <p>{t('no-groups')}</p>;
  }

  return (
    <>
      <Toolbar>
        {userCanEditRegistrations && !marblesCourseOffering.academicYear.isArchived && (
          <ActionList label={t('import-list-toggle')} prefixIcon="ArrowDownOnSquare">
            <UploadCourseRegistrationsModal />
            <UploadCourseDeregistrationsModal />
          </ActionList>
        )}
        <StudentsExportLink courseOfferingId={courseOfferingId} status={PendingOrRegistered.Registered} />
        {userCanEditRegistrations && !marblesCourseOffering.academicYear.isArchived && (
          <>
            <RegisterStudentForCourseModal />
            <ToggleSwitch
              onChange={() => setIsEditMode((previousState) => !previousState)}
              checked={isEditMode}
              label={t('edit-toggle')}
              labelPosition="before"
              extraLeftGap
            />
          </>
        )}
      </Toolbar>

      {drawerIsOpen && currentStudent && (
        <Drawer
          isOpen={drawerIsOpen}
          onClose={() => {
            setDrawerIsOpen(false);
            setCurrentStudent(null);
          }}
          title={`${t('entry-requirements')} ${currentStudent.name} (${currentStudent.studentNumber})`}
        >
          <StudentRequirementStatus student={currentStudent} />
        </Drawer>
      )}

      {query.data.courseOfferingPursuits.length ? (
        <DataTable
          columns={columns}
          data={query.data.courseOfferingPursuits}
          initialState={initialState}
          tableId={TABLE_STORAGE_KEYS.COURSE_OFFERING_REGISTERED_STUDENTS}
        />
      ) : (
        <EmptyStateDataDisplay label={t('empty-state.label')} paragraph={t('empty-state.paragraph')} />
      )}
    </>
  );
}
