import { useMutation } from '@apollo/client';

import type { StoreObject } from '@apollo/client';
import type {
  GetRegisteredPursuitsByCourseOfferingIdQuery,
  GetRegisteredPursuitsByCourseOfferingIdQueryVariables,
  UploadCourseDeregistrationsMutation,
  UploadCourseDeregistrationsMutationVariables,
} from 'types/__generated__';

import { MutationStatus } from 'types/__generated__';
import { UPLOAD_COURSE_DEREGISTRATIONS } from 'graphql/mutations/uploadCourseDeregistrations';
import { GET_REGISTERED_PURSUITS_BY_COURSE_OFFERING_ID } from 'graphql/queries/getRegisteredPursuitsByCourseOfferingId';

export const useUploadCourseDeregistrations = (academicYear: number) =>
  useMutation<UploadCourseDeregistrationsMutation, UploadCourseDeregistrationsMutationVariables>(
    UPLOAD_COURSE_DEREGISTRATIONS,
    {
      update: (cache, { data }) => {
        if (data?.uploadCourseDeregistrations.mutationStatus !== MutationStatus.Success) return;

        const { courseOfferingId, result } = data.uploadCourseDeregistrations;
        const studentNumbers = result.map(({ studentNumber }) => studentNumber);

        const updatedRegistered = cache.updateQuery<
          GetRegisteredPursuitsByCourseOfferingIdQuery,
          GetRegisteredPursuitsByCourseOfferingIdQueryVariables
        >(
          { query: GET_REGISTERED_PURSUITS_BY_COURSE_OFFERING_ID, variables: { courseOfferingId, academicYear } },
          (cached) => ({
            courseOfferingPursuits: cached
              ? [
                  ...cached.courseOfferingPursuits.filter(
                    ({ student }) => !studentNumbers.includes(student.studentNumber)
                  ),
                ]
              : [],
          })
        );

        const courseOffering: StoreObject = { __typename: 'MarblesCourseOffering', id: courseOfferingId };

        cache.modify({
          id: cache.identify(courseOffering),
          fields: {
            numberOfRegisteredStudents(currentValue) {
              return updatedRegistered?.courseOfferingPursuits.length ?? currentValue;
            },
          },
        });
      },
    }
  );
