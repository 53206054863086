import { ModalProvider, OverlayProvider } from '@react-aria/overlays';

import type { ReactNode } from 'react';

import { AuthProvider } from 'providers/AuthProvider';
import { GraphqlProvider } from 'providers/GraphqlProvider';
import { ToastProvider } from 'providers/ToastProvider';

interface Props {
  children: ReactNode;
}

export const Providers = ({ children }: Props) => (
  <OverlayProvider>
    <ModalProvider>
      <AuthProvider>
        <GraphqlProvider>
          <ToastProvider>{children}</ToastProvider>
        </GraphqlProvider>
      </AuthProvider>
    </ModalProvider>
  </OverlayProvider>
);
