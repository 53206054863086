import { gql } from '@apollo/client';

import { TOPIC_SET_FRAGMENT } from 'graphql/fragments/topicSetFragment';

export const UPDATE_TOPIC_SET_NAME = gql`
  mutation updateTopicSetName($topicSetId: String!, $name: String!, $titleNL: String!, $titleEN: String!) {
    updateTopicSetName(input: { topicSetId: $topicSetId, name: $name, titleNL: $titleNL, titleEN: $titleEN }) {
      mutationStatus
      topicSet {
        ...topicSet
      }
    }
  }
  ${TOPIC_SET_FRAGMENT}
`;
