import { useTranslation } from 'react-i18next';

import type { MarblesAcademicYear } from 'types/__generated__';

import { StickyStatusBar } from 'App/shared/StickyStatusBar/StickyStatusBar';

type props = {
  academicYear: Omit<MarblesAcademicYear, 'endDate' | 'startDate' | 'isCurrentAcademicYearForAdmins'>;
};

export const ArchivedYearNotification = ({ academicYear }: props) => {
  const { t } = useTranslation('common', { keyPrefix: 'archived-year-notification' });

  if (!academicYear.isArchived) return null;

  return <StickyStatusBar message={t('message', { academicYear: academicYear.name })} />;
};
