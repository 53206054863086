import { gql } from '@apollo/client';

import { DEPARTMENT_FRAGMENT } from 'graphql/fragments/departmentFragment';

export const GET_DEPARTMENTS = gql`
  query getDepartments {
    marblesDepartments {
      ...department
    }
  }
  ${DEPARTMENT_FRAGMENT}
`;
