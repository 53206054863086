import { gql } from '@apollo/client';

import { TOPIC_SET_FRAGMENT } from 'graphql/fragments/topicSetFragment';

export const GET_TOPICS_BY_COURSE_OFFERING_ID = gql`
  query getTopicsByCourseOfferingId($courseOfferingId: String!) {
    marblesCourseOffering(courseOfferingId: $courseOfferingId) {
      id
      topicSet {
        ...topicSet
      }
    }
  }
  ${TOPIC_SET_FRAGMENT}
`;
