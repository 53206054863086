import { gql } from '@apollo/client';

import { RULE_FRAGMENT } from 'graphql/fragments/ruleFragment';

export const ADD_INFO_REQUIREMENT = gql`
  mutation addInfoRequirement($ruleId: String!, $description: BilingualStringInput!) {
    addInfoRequirement(input: { ruleId: $ruleId, description: $description }) {
      mutationStatus
      rule {
        ...rule
      }
    }
  }
  ${RULE_FRAGMENT}
`;
