import { gql } from '@apollo/client';

import { RULE_FRAGMENT } from 'graphql/fragments/ruleFragment';

export const GET_RULE_BY_ID = gql`
  query getRuleById($ruleId: String!) {
    rule(ruleId: $ruleId) {
      ...rule
      academicYear {
        id
        name
        isArchived
      }
    }
  }
  ${RULE_FRAGMENT}
`;
