import { Fragment } from 'react';

import styles from './PageMultiSubTitle.module.css';

interface Props {
  values: string[];
}

export const PageMultiSubTitle = ({ values }: Props) => (
  <div className={styles['page-multi-sub-title']}>
    {values.map((string, index) => (
      <Fragment key={index}>
        <span>{string}</span>
        {index < values.length - 1 && <span>&bull;</span>}
      </Fragment>
    ))}
  </div>
);
