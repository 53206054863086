import { useEffect, useRef, useState } from 'react';
import { clsx } from 'clsx';

import styles from './StickyStatusBar.module.css';

import { MaintenanceMessageSeverity } from 'types/__generated__';

type StickyStatusBarProps = { message: string; severity?: MaintenanceMessageSeverity };

export const StickyStatusBar = ({ message, severity = MaintenanceMessageSeverity.Error }: StickyStatusBarProps) => {
  const [isSticky, setIsSticky] = useState(false);
  const stickyRef = useRef<HTMLDivElement>(null);

  const defaultDistanceFromTop =
    window.scrollY + (stickyRef.current ? stickyRef.current.getBoundingClientRect().top : 0);

  useEffect(() => {
    const handleScroll = () => {
      if (!stickyRef.current) return;
      const { top } = stickyRef.current.getBoundingClientRect();
      const offset = window.scrollY;
      const isSticky = top <= 0 && offset > 0;
      const isReleased = offset <= defaultDistanceFromTop;
      setIsSticky(isSticky && !isReleased);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [defaultDistanceFromTop]);

  return (
    <>
      {isSticky && <div className={styles['sticky-status-bar__placeholder']} style={{ height: '3rem' }} />}
      <div
        className={clsx(
          styles['sticky-status-bar'],
          isSticky && styles['sticky-status-bar--sticky'],
          styles[`sticky-status-bar--${severity.toLowerCase()}`]
        )}
        ref={stickyRef}
      >
        <p className={styles['sticky-status-bar__message']} dangerouslySetInnerHTML={{ __html: message }} />
      </div>
    </>
  );
};
