import { gql } from '@apollo/client';

import { PARTIAL_RULE_FRAGMENT } from 'graphql/fragments/partialRuleFragment';

export const REMOVE_REQUIRED_PLAN = gql`
  mutation removeRequiredPlan($ruleId: String!, $nodeId: String!) {
    removeRequiredPlan(input: { ruleId: $ruleId, nodeId: $nodeId }) {
      rule {
        ...partialRule
      }
      nodeId
      mutationStatus
    }
  }
  ${PARTIAL_RULE_FRAGMENT}
`;
