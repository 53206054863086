import { Trans, useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useOverlayTriggerState } from '@react-stately/overlays';
import { Button, IconButton, Icon, FeedbackBox, ModalDialog, ButtonGroup } from '@uva-glass/component-library';

import type { FormEvent } from 'react';
import type { GetRequirementsByDepartmentIdQuery } from 'types/__generated__';

import { MutationStatus } from 'types/__generated__';
import { useGetReadableMutationStatus } from 'hooks/useGetReadableMutationStatus';
import { useRemoveRequirement } from 'hooks/useRemoveRequirement';

interface Props {
  buttonIsIconOnly?: boolean;
  isDisabled: boolean;
  onCompleted?: () => void;
  requirement: NonNullable<GetRequirementsByDepartmentIdQuery>['requirements'][number] | null;
}

export function RemoveEntryRequirement({ requirement, isDisabled, buttonIsIconOnly = false, onCompleted }: Props) {
  const { t } = useTranslation('entry-requirements', { keyPrefix: 'remove-entry-requirement' });
  const { open, close, isOpen } = useOverlayTriggerState({});
  const [error, setError] = useState<string>();
  const readableMutationStatus = useGetReadableMutationStatus();
  const [mutate, mutation] = useRemoveRequirement();

  function resetAndClose() {
    setError(undefined);

    close();
  }

  function onSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (!requirement) return;

    mutate({
      variables: { requirementId: requirement.id },
      onCompleted(data) {
        const { mutationStatus } = data.removeRequirement;

        if (mutationStatus === MutationStatus.Success) {
          resetAndClose();
          onCompleted && onCompleted();

          return;
        }

        setError(readableMutationStatus(mutationStatus));
      },
      onError() {
        setError(readableMutationStatus('unknown'));
      },
    });
  }

  return (
    <>
      {buttonIsIconOnly ? (
        <IconButton variant="destructive" aria-label={t('trigger')} onClick={open} disabled={isDisabled}>
          <Icon name={isDisabled ? 'TrashStrikethrough' : 'Trash'} />
        </IconButton>
      ) : (
        <Button variant="secondary" onClick={open} disabled={isDisabled}>
          <Icon name={isDisabled ? 'TrashStrikethrough' : 'Trash'} size={20} />
          {t('trigger')}
        </Button>
      )}

      <ModalDialog
        title={t('modal.title')}
        isOpen={isOpen}
        onClose={resetAndClose}
        onSubmit={onSubmit}
        buttons={
          <ButtonGroup>
            <Button onClick={resetAndClose} variant="secondary">
              {t('modal.buttons.cancel')}
            </Button>
            <Button variant="destructive-primary" disabled={mutation.loading} type="submit">
              {t('modal.buttons.confirm')}
            </Button>
          </ButtonGroup>
        }
      >
        <p>
          <Trans
            t={t}
            i18nKey="modal.description"
            values={{ name: requirement?.name }}
            components={{ strong: <strong /> }}
          />
        </p>
        {error && <FeedbackBox level="error" feedback={error} />}
      </ModalDialog>
    </>
  );
}
