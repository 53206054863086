import { useOverlayTriggerState } from '@react-stately/overlays';
import { Button, ButtonGroup, FeedbackBox, Label, ModalDialog, Spinner, FormField } from '@uva-glass/component-library';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { ChangeEvent, FormEvent } from 'react';
import type { UploadCourseDeregistrationsMutation } from 'types/__generated__';

import { useUploadCourseDeregistrations } from './hooks/useUploadCourseDeregistrations';

import { DeregistrationReason } from 'App/shared/DeregistrationReason/DeregistrationReason';
import { UploadResults } from 'App/shared/UploadResults/UploadResults';
import { UploadRowErrors } from 'App/shared/UploadRowErrors/UploadRowErrors';
import { Input } from 'components/Input/Input';
import { useCurrentLanguage } from 'hooks/useCurrentLanguage';
import { useGetCourseOffering } from 'hooks/useGetCourseOffering';
import { useGetReadableMissingColumn } from 'hooks/useGetReadableMissingColumns';
import { useGetReadableMutationStatus } from 'hooks/useGetReadableMutationStatus';
import { MutationStatus, StaffDeregistrationReason } from 'types/__generated__';
import { ACCEPTED_FILE_TYPES } from 'util/acceptedFileTypes';

const FILE_UPLOAD_ID = 'file-upload';
const FILE_UPLOAD_ERROR_ID = 'file-upload-error';

type UploadCourseDeregistrationsMutationResult =
  UploadCourseDeregistrationsMutation['uploadCourseDeregistrations']['result'];
type UploadCourseDeregistrationsMissingColumns =
  UploadCourseDeregistrationsMutation['uploadCourseDeregistrations']['missingColumns'];

export function UploadCourseDeregistrationsModal() {
  const { t } = useTranslation('course-offering', { keyPrefix: 'upload-course-deregistrations-modal' });
  const currentLanguage = useCurrentLanguage();
  const readableMutationStatus = useGetReadableMutationStatus();
  const readableMissingColumn = useGetReadableMissingColumn();
  const { close, isOpen, open } = useOverlayTriggerState({});
  const { marblesCourseOffering } = useGetCourseOffering();

  const { id: courseOfferingId, info } = marblesCourseOffering;
  const academicYear = marblesCourseOffering.academicYear.id;

  const [deregistrationReason, setDeregistrationReason] = useState<StaffDeregistrationReason>(
    StaffDeregistrationReason.Administrative
  );
  const [deregistrationReasonError, setDeregistrationReasonError] = useState('');
  const [file, setFile] = useState<File>();
  const [fileError, setFileError] = useState('');
  const [mutationError, setMutationError] = useState('');
  const [results, setResults] = useState<UploadCourseDeregistrationsMutationResult>([]);
  const [currentMissingColumns, setCurrentMissingColumns] = useState<UploadCourseDeregistrationsMissingColumns>(null);

  const closeButtonRef = useRef<HTMLButtonElement>(null);
  const okButtonRef = useRef<HTMLButtonElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [mutate, mutation] = useUploadCourseDeregistrations(academicYear);
  const errorRows = results.filter(({ rowStatus }) => rowStatus !== MutationStatus.Success);
  const successRowCount = results.length - errorRows.length;

  function reset() {
    setDeregistrationReason(StaffDeregistrationReason.Administrative);
    setDeregistrationReasonError('');
    setFile(undefined);
    setFileError('');
    setMutationError('');
    setResults([]);
    setCurrentMissingColumns(null);

    mutation.reset();
  }

  function resetAndClose() {
    reset();
    close();
  }

  function handleFileChange({ target }: ChangeEvent<HTMLInputElement>) {
    setFileError('');
    const { files } = target;

    if (!files) return;

    const file = files[0];

    if (!ACCEPTED_FILE_TYPES.includes(file.type)) {
      setFileError(t('modal.errors.unsupported-type'));
      if (fileInputRef.current) {
        // reset the value of the <input type="file">
        fileInputRef.current.value = '';
      }
      return;
    }

    setFile(file);
  }

  function onSelectReason(event: ChangeEvent<HTMLSelectElement>) {
    setDeregistrationReasonError('');
    setDeregistrationReason(event.target.value as StaffDeregistrationReason);
  }

  function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (!file) {
      setFileError(t('modal.errors.file-missing'));
      return;
    }

    if (!deregistrationReason) {
      setDeregistrationReasonError(t('modal.errors.reason-missing'));
      return;
    }

    mutate({
      variables: { courseOfferingId, file, reason: deregistrationReason },
      onCompleted(data) {
        const { mutationStatus, result, missingColumns } = data.uploadCourseDeregistrations;

        if (mutationStatus === MutationStatus.Success) {
          setResults(result);
          return;
        }

        if (mutationStatus === MutationStatus.UploadMissingColumns) {
          setCurrentMissingColumns(missingColumns);
          setMutationError(readableMutationStatus(mutationStatus, { details: missingColumns ?? undefined }));
          return;
        }

        setMutationError(readableMutationStatus(mutationStatus));
      },
      onError(error) {
        if (error.graphQLErrors.length) {
          setMutationError(readableMutationStatus(MutationStatus.Corrupt));
        } else {
          setMutationError(readableMutationStatus(MutationStatus.Error));
        }
      },
    });
  }

  return (
    <>
      <button onClick={open}>{t('trigger')}</button>

      <ModalDialog
        title={t('modal.title')}
        onSubmit={handleSubmit}
        buttons={
          <>
            {!mutation.called && (
              <ButtonGroup reversed>
                <Button variant="primary" type="submit" disabled={mutation.loading}>
                  {t('modal.buttons.import')}
                </Button>
                <Button onClick={resetAndClose} variant="secondary" ref={closeButtonRef}>
                  {t('modal.buttons.cancel')}
                </Button>
              </ButtonGroup>
            )}

            {mutation.called && !mutation.loading && (
              <ButtonGroup reversed>
                <Button onClick={resetAndClose} variant="primary" ref={okButtonRef}>
                  {t('modal.buttons.confirm')}
                </Button>
                <Button onClick={reset} variant="secondary">
                  {t('modal.buttons.reset-and-retry')}
                </Button>
              </ButtonGroup>
            )}
          </>
        }
        isOpen={isOpen}
        onClose={resetAndClose}
        wide
      >
        {info && (
          <p>
            {info.title[currentLanguage]} ({info.catalogNumber})
          </p>
        )}

        {!mutation.called && (
          <>
            <p>{t('modal.description')}</p>

            <FormField outerSpace>
              <Label htmlFor={FILE_UPLOAD_ID}>{t('modal.file')}</Label>
              <Input
                id={FILE_UPLOAD_ID}
                type="file"
                accept={ACCEPTED_FILE_TYPES.join(',')}
                onChange={handleFileChange}
                aria-describedby={fileError ? FILE_UPLOAD_ERROR_ID : undefined}
                ref={fileInputRef}
              />
              {fileError && <FeedbackBox id={FILE_UPLOAD_ERROR_ID} level="error" feedback={fileError} />}
            </FormField>

            <FormField outerSpace>
              <DeregistrationReason
                onChange={onSelectReason}
                error={deregistrationReasonError}
                defaultReason={deregistrationReason}
              />
            </FormField>
          </>
        )}

        {mutation.called && mutation.loading && <Spinner ariaValueText={t('results-modal.loading')} />}

        {mutation.called &&
          !mutation.loading &&
          (results.length > 0 ? (
            <UploadResults>
              {successRowCount > 0 && (
                <FeedbackBox level="success" feedback={t('results-modal.success', { count: successRowCount })} />
              )}
              {errorRows.length > 0 && (
                <>
                  <FeedbackBox level="error" feedback={t('results-modal.failure', { count: errorRows.length })} />
                  <UploadRowErrors errorRows={errorRows} />
                </>
              )}
            </UploadResults>
          ) : (
            <>
              {mutationError && <FeedbackBox level="error" feedback={mutationError} />}
              {currentMissingColumns ? (
                <ul>
                  {currentMissingColumns.map((column, index) => (
                    <li key={index}>{readableMissingColumn(column)}</li>
                  ))}
                </ul>
              ) : (
                <p>{t('results-modal.nothing-imported')}</p>
              )}
            </>
          ))}
      </ModalDialog>
    </>
  );
}
