import { useQuery } from '@apollo/client';

import type { GetRegistrationRoundsQuery, GetRegistrationRoundsQueryVariables } from 'types/__generated__';

import { GET_REGISTRATION_ROUNDS } from 'graphql/queries/getRegistrationRounds';

export const useGetRegistrationRounds = (academicYear: number) =>
  useQuery<GetRegistrationRoundsQuery, GetRegistrationRoundsQueryVariables>(GET_REGISTRATION_ROUNDS, {
    variables: { academicYear },
  });
