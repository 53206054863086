import { useMutation } from '@apollo/client';

import type {
  CopyRuleMutation,
  CopyRuleMutationInput,
  GetRulesByDepartmentIdQuery,
  GetRulesByDepartmentIdQueryVariables,
} from 'types/__generated__';

import { COPY_RULE } from 'graphql/mutations/copyRule';
import { GET_RULES_BY_DEPARTMENT_ID } from 'graphql/queries/getRulesByDepartmentId';
import { MutationStatus } from 'types/__generated__';

export const useCopyRule = (academicYear: number, childDepartmentIds: string[]) =>
  useMutation<CopyRuleMutation, CopyRuleMutationInput>(COPY_RULE, {
    update(cache, { data }) {
      if (data?.copyRule.mutationStatus !== MutationStatus.Success) return;

      const { rule } = data.copyRule;

      if (!rule) {
        throw new Error('No "rule" in payload');
      }

      const departmentsToBeUpdated = [...childDepartmentIds, rule.department.id];

      // forEach necessary as we need to update the cache for the selected department and every child applicable to it
      departmentsToBeUpdated.forEach((departmentId) => {
        cache.updateQuery<GetRulesByDepartmentIdQuery, GetRulesByDepartmentIdQueryVariables>(
          {
            query: GET_RULES_BY_DEPARTMENT_ID,
            variables: { academicYear, departmentId, includeParentDepartments: true },
          },
          (cached) => (cached ? { rules: [...cached.rules, rule] } : null)
        );
      });
    },
  });
