import { useMutation } from '@apollo/client';

import type { StoreObject } from '@apollo/client';
import type {
  SetTopicByCoursePursuitIdMutation,
  SetTopicByCoursePursuitIdMutationVariables,
} from 'types/__generated__';

import { MutationStatus } from 'types/__generated__';
import { SET_TOPIC_BY_COURSE_PURSUIT_ID } from 'graphql/mutations/setTopicByCoursePursuitId';

export const useSetCoursePursuitTopic = () =>
  useMutation<SetTopicByCoursePursuitIdMutation, SetTopicByCoursePursuitIdMutationVariables>(
    SET_TOPIC_BY_COURSE_PURSUIT_ID,
    {
      update(cache, { data }) {
        if (data?.setCoursePursuitTopic.mutationStatus !== MutationStatus.Success) return;

        const { topicId, coursePursuitId } = data.setCoursePursuitTopic;
        const coursePursuit: StoreObject = { __typename: 'CoursePursuit', id: coursePursuitId };

        cache.modify({
          id: cache.identify(coursePursuit),
          fields: {
            topicId() {
              return topicId ?? null;
            },
          },
        });
      },
    }
  );
