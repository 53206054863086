import { gql } from '@apollo/client';

import { PARTIAL_REQUIREMENT_FRAGMENT } from 'graphql/fragments/partialRequirementFragment';

export const CREATE_REQUIREMENT = gql`
  mutation createRequirement($academicYear: Int!, $departmentId: String!, $name: String!, $ruleId: String!) {
    createRequirement(
      input: { academicYear: $academicYear, departmentId: $departmentId, name: $name, ruleId: $ruleId }
    ) {
      mutationStatus
      academicYear
      requirement {
        ...partialRequirement
      }
    }
  }
  ${PARTIAL_REQUIREMENT_FRAGMENT}
`;
