import { SectionNotification } from '@uva-glass/component-library';

import type { PropsWithChildren, ReactNode } from 'react';

import styles from './PageSection.module.css';

interface Props {
  notification?: ReactNode;
}

export const PageSection = ({ children, notification }: PropsWithChildren<Props>) => (
  <div className={styles['page-section']}>
    {notification && <SectionNotification>{notification}</SectionNotification>}

    {children}
  </div>
);
