import { gql } from '@apollo/client';

import { COURSE_INFO_FRAGMENT } from 'graphql/fragments/courseInfoFragment';
import { PERIOD_FRAGMENT } from 'graphql/fragments/periodFragment';
import { REQUIREMENT_FRAGMENT } from 'graphql/fragments/requirementFragment';

export const GET_REQUIREMENT_BY_ID = gql`
  query getRequirementById($requirementId: String!) {
    requirement(requirementId: $requirementId) {
      ...requirement
      courseOfferings {
        id
        info {
          ...courseInfo
        }
        periods {
          ...period
        }
      }
      topics {
        id
        title {
          NL
          EN
        }
        topicSetId
        topicSet {
          name
        }
      }
      department {
        id
        name {
          NL
          EN
        }
        allowedUserActions
      }
      academicYear {
        id
        name
        isArchived
      }
    }
  }

  ${REQUIREMENT_FRAGMENT}
  ${COURSE_INFO_FRAGMENT}
  ${PERIOD_FRAGMENT}
`;
