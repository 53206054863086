import { useTranslation } from 'react-i18next';
import { Icon, Tooltip } from '@uva-glass/component-library';

import type { GetCourseOfferingGroupSwapsByIdQuery } from 'types/__generated__';

import styles from './GroupSwapModal.module.css';

interface Props {
  group: GetCourseOfferingGroupSwapsByIdQuery['courseOfferingGroupSwaps'][number]['studentGroup'];
  isLocked: boolean;
  isCurrentGroup?: boolean;
}

export function GroupDetails({ group, isLocked, isCurrentGroup = false }: Props) {
  const { t } = useTranslation('course-offering', { keyPrefix: 'course-offering-group-swap.group-swap-modal' });

  const groupName = group ? group.name : t('deleted-group');

  if (!group) {
    return <span>{groupName}</span>;
  }

  const { capacity, numberOfRegisteredStudents, groupSwapDemand } = group;

  const availablePlaces = capacity - numberOfRegisteredStudents;
  const availabilityText = isLocked
    ? t('locked')
    : availablePlaces <= 0
      ? t('full')
      : t('places', { count: availablePlaces });

  return (
    <>
      <span>{groupName}</span>
      <div className={styles['group-details']}>
        <span className={styles['group-details_span']}>
          <strong className={styles[`group-details__${availablePlaces <= 0 || isLocked ? 'closed' : 'available'}`]}>
            {availabilityText}
          </strong>
          {`${numberOfRegisteredStudents}/${capacity}`}
        </span>
        {(isCurrentGroup ? groupSwapDemand > 0 : groupSwapDemand > 1) && (
          <Tooltip
            element={
              <div className={styles['group-details_tooltip-icon']}>
                <Icon name="Heart" size={16} /> {isCurrentGroup ? groupSwapDemand : groupSwapDemand - 1}
              </div>
            }
            id={`${groupName}-tooltip`}
            text={t('tooltip')}
            size="small"
          />
        )}
      </div>
    </>
  );
}
