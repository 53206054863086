import { gql } from '@apollo/client';

import { COURSE_INFO_FRAGMENT } from 'graphql/fragments/courseInfoFragment';
import { PERIOD_FRAGMENT } from 'graphql/fragments/periodFragment';
import { TOPIC_SET_FRAGMENT } from 'graphql/fragments/topicSetFragment';

export const GET_TOPIC_SET_BY_ID = gql`
  query getTopicSetById($topicSetId: String!) {
    topicSet(topicSetId: $topicSetId) {
      ...topicSet
      department {
        id
        name {
          EN
          NL
        }
        allowedUserActions
      }
      departmentId
      academicYear {
        id
        name
        isArchived
      }
      courseOfferings {
        id
        info {
          ...courseInfo
        }
        periods {
          ...period
        }
      }
    }
  }

  ${TOPIC_SET_FRAGMENT}
  ${COURSE_INFO_FRAGMENT}
  ${PERIOD_FRAGMENT}
`;
