import { MarblesCourseOfferingStatus } from 'types/__generated__';

interface CourseOffering {
  value: string;
  content: string;
}

export const desiredCourseOfferingStatusOrder = [
  MarblesCourseOfferingStatus.ActiveOpen,
  MarblesCourseOfferingStatus.ActiveClosed,
  MarblesCourseOfferingStatus.DirectRegistrationOnly,
  MarblesCourseOfferingStatus.Inactive,
];

export const sortCourseOfferingStatuses = (courseOfferings: CourseOffering[]) => {
  return courseOfferings.sort((offeringA, offeringB) => {
    return (
      desiredCourseOfferingStatusOrder.indexOf(offeringA.value as MarblesCourseOfferingStatus) -
      desiredCourseOfferingStatusOrder.indexOf(offeringB.value as MarblesCourseOfferingStatus)
    );
  });
};
