import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Button,
  Label,
  FieldHint,
  InputField,
  FeedbackBox,
  ModalDialog,
  ButtonGroup,
  Fieldset,
  FormField,
} from '@uva-glass/component-library';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { ChangeEvent, FormEvent } from 'react';
import type {
  GetStudentSetByCodeQuery,
  GetStudentSetByCodeQueryVariables,
  RuleFragment,
  StudentSet,
  UpdateRequiredStudentSetMutation,
  UpdateRequiredStudentSetMutationVariables,
} from 'types/__generated__';

import styles from './EditRequiredStudentSet.module.css';

import { UPDATE_REQUIRED_STUDENT_SET } from 'graphql/mutations/updateRequiredStudentSet';
import { GET_STUDENT_SET_BY_CODE } from 'graphql/queries/getStudentSetByCode';
import { useCurrentLanguage } from 'hooks/useCurrentLanguage';
import { useGetReadableMutationStatus } from 'hooks/useGetReadableMutationStatus';
import { MutationStatus } from 'types/__generated__';

const ID_INPUT_STUDENT_SET = 'ID_INPUT_STUDENT_SET';
const ID_STUDENT_SET_ERROR = 'ID_STUDENT_SET_ERROR';
const ID_FORM_ERROR = 'ID_FORM_ERROR';

interface Props {
  close: () => void;
  nodeId: string;
  ruleId: RuleFragment['id'];
  studentSetInRequirement: StudentSet;
}

export function EditRequiredStudentSet({ ruleId, nodeId, studentSetInRequirement, close }: Props) {
  const { t } = useTranslation('requirement-rules', { keyPrefix: 'edit-required-student-set' });
  const currentLanguage = useCurrentLanguage();
  const readableMutationStatus = useGetReadableMutationStatus();

  const [mutate, mutation] = useMutation<UpdateRequiredStudentSetMutation, UpdateRequiredStudentSetMutationVariables>(
    UPDATE_REQUIRED_STUDENT_SET
  );
  const [query, lazyQuery] = useLazyQuery<GetStudentSetByCodeQuery, GetStudentSetByCodeQueryVariables>(
    GET_STUDENT_SET_BY_CODE
  );

  const [studentSet, setStudentSet] = useState<StudentSet | GetStudentSetByCodeQuery['studentSet']>(
    studentSetInRequirement
  );
  const [studentSetCode, setStudentSetCode] = useState(studentSetInRequirement.code);
  const [studentSetCodeError, setStudentSetCodeError] = useState<string>();
  const [submitError, setSubmitError] = useState<string>();

  function resetAndClose() {
    setStudentSet(null);
    setStudentSetCode('');
    setStudentSetCodeError(undefined);
    setSubmitError(undefined);

    close();
  }

  function onCancel() {
    resetAndClose();
  }

  function onChangeStudentSetCode(event: ChangeEvent<HTMLInputElement>) {
    const { validity, value } = event.target;
    const code = value.trim().toUpperCase();

    setSubmitError(undefined);
    setStudentSetCodeError(undefined);
    setStudentSetCode(code);

    if (!code || !validity.valid) {
      setStudentSet(null);

      return;
    }

    query({
      variables: { code },
      onCompleted(data) {
        setStudentSet(data.studentSet);

        if (!data.studentSet) {
          setStudentSetCodeError(t('invalid-student-set-id'));
        }
      },
      onError() {
        setStudentSetCodeError(readableMutationStatus('unknown'));
      },
    });
  }

  function onSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    setSubmitError(undefined);

    if (!studentSet) {
      setStudentSetCodeError(t('fill-in-a-type', { type: t('modal.student-set-label').toLowerCase() }));
      return;
    }

    mutate({
      variables: {
        nodeId,
        ruleId,
        studentSetId: studentSet.id,
      },
      onCompleted(data) {
        if (data.updateRequiredStudentSet.mutationStatus !== MutationStatus.Success) {
          setStudentSetCodeError(readableMutationStatus(data.updateRequiredStudentSet.mutationStatus));
          return;
        }

        resetAndClose();
      },
      onError() {
        setSubmitError(readableMutationStatus('unknown'));
      },
    });
  }

  return (
    <ModalDialog
      title={t('modal.title')}
      isOpen
      onClose={resetAndClose}
      isDismissable={!mutation.loading && !lazyQuery.loading}
      buttons={
        <ButtonGroup reversed>
          <Button variant="primary" type="submit" disabled={mutation.loading}>
            {t('modal.buttons.save')}
          </Button>
          <Button variant="secondary" onClick={onCancel} disabled={mutation.loading}>
            {t('modal.buttons.cancel')}
          </Button>
        </ButtonGroup>
      }
      noValidate
      onSubmit={onSubmit}
      wide
    >
      <Fieldset legend="">
        <FormField>
          <Label id={`${ID_INPUT_STUDENT_SET}-label`} htmlFor={ID_INPUT_STUDENT_SET}>
            {t('modal.student-set-label')}
          </Label>

          <span className={styles['edit-required-student-set__sublabel']}>{t('modal.student-must-be-registered')}</span>

          <InputField
            aria-describedby={studentSetCodeError ? ID_STUDENT_SET_ERROR : undefined}
            aria-labelledby={`${ID_INPUT_STUDENT_SET}-label`}
            disabled={mutation.loading}
            id={ID_INPUT_STUDENT_SET}
            maxLength={10}
            onChange={onChangeStudentSetCode}
            pattern="\w{10}"
            value={studentSetCode}
          />
          <FieldHint
            value={studentSet ? `[${studentSet.title[currentLanguage]}]` : undefined}
            loading={lazyQuery.loading}
            spinnerAriaValueText={t('modal.loading-student-set')}
          />

          {studentSetCodeError && (
            <FeedbackBox id={ID_STUDENT_SET_ERROR} feedback={studentSetCodeError} level="error" />
          )}
        </FormField>
      </Fieldset>

      {submitError && <FeedbackBox id={ID_FORM_ERROR} feedback={submitError} level="error" />}
    </ModalDialog>
  );
}
