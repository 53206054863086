import { useMutation } from '@apollo/client';

import type { StoreObject } from '@apollo/client';
import type { SetStudentGroupCapacityMutation, SetStudentGroupCapacityMutationVariables } from 'types/__generated__';

import { SET_STUDENT_GROUP_CAPACITY } from 'graphql/mutations/setStudentGroupCapacity';
import { MutationStatus } from 'types/__generated__';

export const useSetStudentGroupCapacity = () =>
  useMutation<SetStudentGroupCapacityMutation, SetStudentGroupCapacityMutationVariables>(SET_STUDENT_GROUP_CAPACITY, {
    update(cache, { data }) {
      if (data?.setStudentGroupCapacity.mutationStatus !== MutationStatus.Success) return;

      const { configuredCapacity, studentGroupId } = data.setStudentGroupCapacity;
      const studentGroup: StoreObject = { __typename: 'StudentGroup', id: studentGroupId };

      cache.modify({
        id: cache.identify(studentGroup),
        fields: {
          configuredCapacity() {
            return configuredCapacity ?? null;
          },
          capacity(_, { readField }) {
            return configuredCapacity ?? readField('initialCapacity');
          },
        },
      });
    },
  });
