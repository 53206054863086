import { gql } from '@apollo/client';

import { STUDENT_WITH_ENROLMENTS_BY_ACADEMIC_YEAR_FRAGMENT } from 'graphql/fragments/studentWithEnrolmentsByAcademicYearFragment';

export const GET_COURSE_OFFERING_REJECTIONS_BY_ID = gql`
  query getCourseOfferingRejectionsById($courseOfferingId: String!, $academicYear: Int) {
    courseOfferingRejections(courseOfferingId: $courseOfferingId) {
      id
      student {
        ...studentWithEnrolmentsByAcademicYear
      }
      registrationRound {
        description {
          NL
          EN
        }
      }
      username
      rejectionDate
      rejectionReason
      topic {
        id
        title {
          NL
          EN
        }
      }
    }
  }
  ${STUDENT_WITH_ENROLMENTS_BY_ACADEMIC_YEAR_FRAGMENT}
`;
