import { gql } from '@apollo/client';

export const GET_GROUPS_BY_COURSE_OFFERING_ID = gql`
  query getGroupsByCourseOfferingId($courseOfferingId: String!) {
    marblesCourseOffering(courseOfferingId: $courseOfferingId) {
      id
      topicSet {
        id
      }
      studentGroups {
        id
        code
        name
        capacity
        initialCapacity
        configuredCapacity
        numberOfRegisteredStudents
        topic {
          id
          title {
            NL
            EN
          }
        }
        timetableSummary {
          NL
          EN
        }
        locked
        directRegistrationEnabled
        groupSwapDemand
      }
    }
  }
`;
