import { gql } from '@apollo/client';

export const GET_TOPIC_CAPACITY_BY_COURSE_OFFERING_ID = gql`
  query getTopicCapacityByCourseOfferingId($courseOfferingId: String!) {
    courseOfferingTopicsCapacity: courseOfferingTopicsCapacityQuery(courseOfferingId: $courseOfferingId) {
      topic {
        id
        title {
          EN
          NL
        }
      }
      groupCount
      availableCapacity
      pending
      registered
    }
  }
`;
