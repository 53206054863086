import { useOverlayTriggerState } from '@react-stately/overlays';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import {
  Button,
  IconButton,
  Icon,
  FeedbackBox,
  ModalDialog,
  ButtonGroup,
  FormField,
} from '@uva-glass/component-library';

import type { FormEvent, ChangeEvent } from 'react';
import type { DeregisterStudentForCourseMutationVariables, Student } from 'types/__generated__';

import { useDeregisterStudentForCourse } from './hooks/useDeregisterStudentForCourse';

import { MutationStatus, StaffDeregistrationReason } from 'types/__generated__';
import { useGetReadableMutationStatus } from 'hooks/useGetReadableMutationStatus';
import { DeregistrationReason } from 'App/shared/DeregistrationReason/DeregistrationReason';

type StudentPartial = Pick<Student, 'name' | 'studentNumber'>;

interface Props {
  coursePursuitId: DeregisterStudentForCourseMutationVariables['coursePursuitId'];
  student: StudentPartial;
  academicYear: number;
  isDisabled: boolean;
}

export function DeregisterStudentForCourseModal({ coursePursuitId, student, academicYear, isDisabled }: Props) {
  const { t } = useTranslation('common', { keyPrefix: 'deregister-student-for-course' });
  const { close, isOpen, open } = useOverlayTriggerState({});
  const [error, setError] = useState<string>();
  const [selectedDeregistrationReason, setSelectedDeregistrationReason] = useState<StaffDeregistrationReason>(
    StaffDeregistrationReason.Administrative
  );
  const [deregistrationReasonError, setDeregistrationReasonError] = useState<string>();
  const [mutate, mutation] = useDeregisterStudentForCourse(academicYear);
  const readableMutationStatus = useGetReadableMutationStatus();

  function resetAndClose() {
    setSelectedDeregistrationReason(StaffDeregistrationReason.Administrative);
    setDeregistrationReasonError(undefined);
    close();
  }

  function onSelectChange(event: ChangeEvent<HTMLSelectElement>) {
    setDeregistrationReasonError(undefined);
    setSelectedDeregistrationReason(event.target.value as StaffDeregistrationReason);
  }

  function onSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (!selectedDeregistrationReason || selectedDeregistrationReason.trim() === '') {
      setDeregistrationReasonError(t('modal.deregistration-reason-error'));
      return;
    }

    mutate({
      variables: { coursePursuitId, reason: selectedDeregistrationReason },
      onCompleted(data) {
        const { mutationStatus } = data.deregisterStudentForCourse;

        if (mutationStatus === MutationStatus.Success) {
          resetAndClose();
          return;
        }

        setError(readableMutationStatus(mutationStatus));
      },
      onError(error) {
        if (error.graphQLErrors.length) {
          setError(readableMutationStatus(MutationStatus.Corrupt));
        } else {
          setError(readableMutationStatus(MutationStatus.Error));
        }
      },
    });
  }

  const { name, studentNumber } = student;

  return (
    <>
      <IconButton variant="destructive" aria-label={t('trigger')} onClick={open} disabled={isDisabled}>
        <Icon name="UserMinusFill" />
      </IconButton>

      <ModalDialog
        title={t('modal.title')}
        buttons={
          <ButtonGroup>
            <Button variant="secondary" disabled={mutation.loading} onClick={resetAndClose}>
              {t('modal.buttons.cancel')}
            </Button>
            <Button variant="destructive-primary" disabled={mutation.loading} type="submit">
              {t('modal.buttons.deregister')}
            </Button>
          </ButtonGroup>
        }
        isOpen={isOpen}
        onClose={close}
        isDismissable={!mutation.loading}
        onSubmit={onSubmit}
      >
        <p>{t('modal.description', { name, studentNumber })}</p>
        <FormField outerSpace>
          <DeregistrationReason
            onChange={onSelectChange}
            error={deregistrationReasonError}
            defaultReason={selectedDeregistrationReason}
          />
        </FormField>

        {error && <FeedbackBox level="error" feedback={error} />}
      </ModalDialog>
    </>
  );
}
