import { Trans, useTranslation } from 'react-i18next';

import styles from './Rule.module.css';
import { StudentSet } from './StudentSet/StudentSet';

import { UserAction, type GetRuleByIdQuery, type RequiredStudentSet } from 'types/__generated__';
import { PageSection } from 'App/shared/PageSection/PageSection';

interface Props {
  rule: NonNullable<GetRuleByIdQuery['rule']>;
}

export function RequiredStudentSetRule({ rule }: Props) {
  const { t } = useTranslation('requirement-rules', { keyPrefix: 'rule.group-requirements' });

  const userCanEditRule = rule.department.allowedUserActions.includes(UserAction.EditRequirements);

  const groupRequirements = rule.nodes.groupRequirements.filter(
    (groupRequirement) => groupRequirement.__typename === 'RequiredStudentSet'
  );

  return (
    <PageSection>
      <div className={styles['rule-requirement-header']}>
        <h2 className={styles['rule-heading']}>{t('title')}</h2>
      </div>

      {groupRequirements.length > 0 && (
        <div className={styles['rule-requirement']}>
          <div className={styles['rule-requirement-header']}>
            <div>
              <strong>{t('student-set.required-student-set')}</strong>
              <p className={styles['rule-requirement-header__subheader']}>
                <Trans t={t} i18nKey="student-set.student-must-be-registered" components={{ strong: <strong /> }} />
              </p>
            </div>
          </div>

          <ul className={styles['group-requirement-list']}>
            {groupRequirements.map(
              (groupRequirement) =>
                groupRequirement.__typename === 'RequiredStudentSet' && (
                  <li key={groupRequirement.id} className={styles['group-requirement-list-item']}>
                    <StudentSet
                      requiredStudentSet={groupRequirement as RequiredStudentSet}
                      ruleId={rule.id}
                      canEdit={userCanEditRule && !rule.academicYear.isArchived}
                    />
                  </li>
                )
            )}
          </ul>
        </div>
      )}
    </PageSection>
  );
}
