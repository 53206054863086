import { gql } from '@apollo/client';

export const REMOVE_REQUIREMENT = gql`
  mutation removeRequirement($requirementId: String!) {
    removeRequirement(input: { requirementId: $requirementId }) {
      mutationStatus
      academicYear
      departmentId
      requirementId
    }
  }
`;
