import type { DateTimeFormatOptions } from 'luxon';

import { defaultDateFormat, useFormattedDate } from 'hooks/useFormattedDate';

interface Props {
  isoDate: string;
  format?: DateTimeFormatOptions;
}

export function DateTime({ isoDate, format = defaultDateFormat }: Props) {
  const formattedDate = useFormattedDate();

  return <time dateTime={isoDate}>{formattedDate(isoDate, format)}</time>;
}
