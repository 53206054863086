import { gql } from '@apollo/client';

import { COURSE_INFO_FRAGMENT } from 'graphql/fragments/courseInfoFragment';

export const GET_COURSE_INFO_BY_CATALOG_NUMBER = gql`
  query getCourseInfoByCatalogNumber($catalogNumber: String) {
    marblesCourseInfo(catalogNumber: $catalogNumber) {
      ...courseInfo
    }
  }
  ${COURSE_INFO_FRAGMENT}
`;
