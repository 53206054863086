import { useQuery } from '@apollo/client';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Card, MetaDataList } from '@uva-glass/component-library';

import type { GetStudentByStudentIdQuery, GetStudentByStudentIdQueryVariables } from 'types/__generated__';

import { StudentProgrammes } from './StudentProgrammes/StudentProgrammes';
import { StudentHistoryLog } from './StudentHistoryLog/StudentHistoryLog';

import { CardCollection } from 'components/CardCollection/CardCollection';
import { CreditLimitExceptionSetting } from 'App/Student/CreditLimitExceptionSetting/CreditLimitExceptionSetting';
import { GET_STUDENT_BY_STUDENT_ID } from 'graphql/queries/getStudentByStudentId';
import { Page } from 'components/Page/Page';
import { ProgrammeList } from 'App/shared/ProgrammeList/ProgrammeList';
import { RequireAuthorisation, useAuthorisation } from 'providers/AuthorisationProvider';
import { SidebarLayout } from 'components/SidebarLayout/SidebarLayout';
import { StudentPursuits } from 'App/Student/StudentPursuits/StudentPursuits';
import { STUDENTS } from 'routes';
import { TabBar } from 'components/TabBar/TabBar';
import { UserAction } from 'types/__generated__';

export function Student() {
  const { studentId } = useParams<keyof GetStudentByStudentIdQueryVariables>();

  if (!studentId) throw new Error('No student id found.');

  const { t } = useTranslation('student', { keyPrefix: 'student' });
  const { loading, error, data } = useQuery<GetStudentByStudentIdQuery, GetStudentByStudentIdQueryVariables>(
    GET_STUDENT_BY_STUDENT_ID,
    { variables: { studentId } }
  );
  const userCanEditStudents = useAuthorisation(UserAction.EditStudents);

  if (loading || error || !data) return null;

  // take a shortcut here and redirect to the student search (for now)
  if (!data.student) return <Navigate to={STUDENTS} replace />;

  const { student } = data;

  return (
    <Page title={student.name}>
      <SidebarLayout>
        <aside>
          <CardCollection>
            <Card>
              <MetaDataList
                items={{
                  [t('meta.name')]: student.name,
                  [t('meta.student-number')]: student.studentNumber,
                  [t('meta.ec-limit-exception')]: t(student.hasCreditLimitException ? 'meta.yes' : 'meta.no'),
                }}
              />
            </Card>

            <Card>
              <ProgrammeList enrolments={student.enrolments} includeDates />
            </Card>
          </CardCollection>
        </aside>

        <section>
          <TabBar
            tabs={[
              { to: `${STUDENTS}/${student.id}/courses`, text: t('tabs.courses') },
              { to: `${STUDENTS}/${student.id}/programmes`, text: t('tabs.programmes') },
              { to: `${STUDENTS}/${student.id}/history`, text: t('tabs.history') },
            ]}
            rest={
              userCanEditStudents ? [{ to: `${STUDENTS}/${student.id}/settings`, text: t('tabs.settings') }] : undefined
            }
          />
          <Routes>
            <Route path="courses" element={<StudentPursuits student={student} />} />
            <Route path="programmes" element={<StudentProgrammes enrolments={student.enrolments} />} />
            <Route path="history" element={<StudentHistoryLog studentId={studentId} />} />
            <Route
              path="settings"
              element={
                <RequireAuthorisation authorisation={UserAction.EditStudents}>
                  {/* Re-add StudentSettings + a CardCollection for more Setting Cards */}
                  <CreditLimitExceptionSetting />
                </RequireAuthorisation>
              }
            />
            <Route path="*" element={<Navigate to="courses" replace />} />
          </Routes>
        </section>
      </SidebarLayout>
    </Page>
  );
}
