import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Career } from './NewRule/Career/Career';
import { Plan } from './NewRule/Plan/Plan';
import { StudentSet } from './NewRule/StudentSet/StudentSet';
import { RequirementRulesTable } from './RequirementRulesTable/RequirementRulesTable';

import { ActionList } from 'App/shared/ActionList/ActionList';
import { ArchivedYearNotification } from 'App/shared/ArchivedYearNotification/ArchivedYearNotification';
import { Page } from 'components/Page/Page';
import { RequireAuthorisation } from 'providers/AuthorisationProvider';
import { useGetAcademicYearDetails } from 'hooks/useGetAcademicYearDetails';
import { UserAction } from 'types/__generated__';
import { PageMultiSubTitle } from 'App/shared/PageMultiSubTitle/PageMultiSubTitle';
import { useCurrentLanguage } from 'hooks/useCurrentLanguage';
import { useGetDepartmentDetails } from 'hooks/useGetDepartmentDetails';

export function RequirementRules() {
  const { t } = useTranslation('requirement-rules', { keyPrefix: 'requirement-rules-page' });
  const currentLanguage = useCurrentLanguage();
  const { academicYear } = useParams();
  const selectedAcademicYear = useGetAcademicYearDetails(Number(academicYear));
  const selectedDepartment = useGetDepartmentDetails();

  return (
    <Page
      title={t('title')}
      subTitle={<PageMultiSubTitle values={[selectedAcademicYear.name, selectedDepartment.name[currentLanguage]]} />}
      notification={<ArchivedYearNotification academicYear={selectedAcademicYear} />}
      actions={
        !selectedAcademicYear.isArchived && (
          <RequireAuthorisation authorisation={UserAction.EditRequirements} departmentId={selectedDepartment.id}>
            <ActionList label={t('new-rule')} prefixIcon="Plus" variant="primary">
              <Plan departmentId={selectedDepartment.id} academicYear={Number(academicYear)} />
              <Career departmentId={selectedDepartment.id} academicYear={Number(academicYear)} />
              <StudentSet departmentId={selectedDepartment.id} academicYear={Number(academicYear)} />
            </ActionList>
          </RequireAuthorisation>
        )
      }
    >
      <section>
        <RequirementRulesTable departmentId={selectedDepartment.id} academicYear={selectedAcademicYear.id} />
      </section>
    </Page>
  );
}
