import { clsx } from 'clsx';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import type { EnrolmentFragment } from 'types/__generated__';

import styles from './ProgrammeList.module.css';

import { useFormattedDate } from 'hooks/useFormattedDate';
import { enrolmentsMapper } from 'util/enrolmentsMapper';

interface Props {
  enrolments: EnrolmentFragment[];
  includeSubplans?: boolean;
  includeDates?: boolean;
  asInlineList?: boolean;
  asSpacedList?: boolean;
}

export function ProgrammeList({ enrolments, includeSubplans, includeDates, asInlineList, asSpacedList }: Props) {
  const { t } = useTranslation('common', { keyPrefix: 'programme-list' });
  const formattedDate = useFormattedDate();
  const mappedEnrolments = enrolmentsMapper(enrolments, { includeSubplans, includeDates });

  const formattedPlanDate = useCallback(
    (timestamp: number) =>
      formattedDate(new Date(timestamp).toISOString(), { day: 'numeric', month: 'long', year: 'numeric' }),
    [formattedDate]
  );

  return (
    <article>
      {!asInlineList && (
        <header>
          <h2 className={styles['programme-list__list-title']}>{t('programme')}</h2>
        </header>
      )}

      <ul
        className={clsx(
          styles['programme-list__list'],
          { [styles['programme-list__list--inline']]: asInlineList },
          { [styles['programme-list__list--spaced']]: asSpacedList }
        )}
      >
        {mappedEnrolments.map(({ plan, subplans, startDate, endDate }, index) => (
          // appending row index to key prop; it can occur that plan values are not unique
          <li className={styles['programme-list__list-item']} key={plan + index}>
            <span className={styles['programme-list__plan']}>{plan}</span>

            {subplans?.length ? ` (${subplans.join(', ')}) ` : null}

            {startDate && (
              <time className={styles['programme-list__date']} dateTime={new Date(startDate).toString()}>
                {`${t('from')} ${formattedPlanDate(startDate)}`}
              </time>
            )}

            {endDate && (
              <>
                {' '}
                <time className={styles['programme-list__date']} dateTime={new Date(endDate).toString()}>
                  {`${t('until')} ${formattedPlanDate(endDate)}`}
                </time>
              </>
            )}
          </li>
        ))}
      </ul>
    </article>
  );
}
