import { gql } from '@apollo/client';

import { ENROLMENT_FRAGMENT } from 'graphql/fragments/enrolmentFragment';

export const STUDENT_FRAGMENT = gql`
  fragment student on Student {
    id
    studentNumber
    name
    surname
    givenName
    enrolments {
      ...enrolment
    }
  }
  ${ENROLMENT_FRAGMENT}
`;
