import { Label, RadioButton, Fieldset, FormField } from '@uva-glass/component-library';

import type { ChangeEvent } from 'react';

import { Select } from 'components/Select/Select';

type Option = {
  id: string;
  label: string;
  value: string;
};

interface Props {
  'aria-describedby'?: string;
  disabled?: boolean;
  emptyLabel?: string;
  id: string;
  label: string;
  onChange: (event: ChangeEvent<HTMLSelectElement | HTMLInputElement>) => void;
  options: Option[];
  renderEmptyValue?: boolean;
  selectedValue?: string;
  inline?: boolean;
}

const optionsThreshold = 2;

export const RadioSelectAlternator = ({
  'aria-describedby': ariaDescribedby,
  disabled,
  emptyLabel,
  id,
  label,
  onChange,
  options,
  renderEmptyValue = true,
  selectedValue,
  inline = true,
}: Props) =>
  options.length <= optionsThreshold ? (
    <Fieldset inline={inline} legend={label} aria-describedby={ariaDescribedby}>
      {renderEmptyValue && emptyLabel && (
        <RadioButton
          defaultChecked={selectedValue === ''}
          disabled={disabled}
          id={`empty-${id}`}
          label={emptyLabel}
          name={id}
          onChange={onChange}
          value=""
          gap="large"
        />
      )}
      {options.map(({ id: optionId, label: optionLabel, value }) => (
        <RadioButton
          defaultChecked={value === selectedValue}
          disabled={disabled}
          id={optionId}
          key={optionId}
          label={optionLabel}
          name={id}
          onChange={onChange}
          value={value}
          gap="large"
        />
      ))}
    </Fieldset>
  ) : (
    <FormField>
      <Label htmlFor={id}>{label}</Label>
      <Select
        aria-describedby={ariaDescribedby}
        defaultValue={selectedValue}
        disabled={disabled}
        id={id}
        onChange={onChange}
      >
        {renderEmptyValue && <Select.Option value=""></Select.Option>}
        {options.map(({ id: optionId, label: optionLabel, value }) => (
          <Select.Option key={optionId} value={value}>
            {optionLabel}
          </Select.Option>
        ))}
      </Select>
    </FormField>
  );
