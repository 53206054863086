import { useQuery } from '@apollo/client';
import { FeedbackBox, Spinner } from '@uva-glass/component-library';
import { useTranslation } from 'react-i18next';

import type { GetRequirementByIdQuery, GetRequirementByIdQueryVariables } from 'types/__generated__';

import styles from './RulesInRequirement.module.css';

import { RuleNodes } from 'App/shared/RuleNodes/RuleNodes';
import { GET_REQUIREMENT_BY_ID } from 'graphql/queries/getRequirementById';

interface Props {
  requirementId: string;
}

export function RulesInRequirement({ requirementId }: Props) {
  const { t } = useTranslation('common', { keyPrefix: 'rules-in-requirement' });

  const query = useQuery<GetRequirementByIdQuery, GetRequirementByIdQueryVariables>(GET_REQUIREMENT_BY_ID, {
    variables: { requirementId },
  });

  if (query.loading) {
    return <Spinner ariaValueText={t('loading')} />;
  }

  if (query.error || !query.data?.requirement) {
    return <FeedbackBox feedback={t('error')} level="error" />;
  }

  const { requirement } = query.data;
  const { rulesInRequirement } = requirement;

  return (
    <>
      {rulesInRequirement.map((ruleInRequirement) => (
        <div className={styles['rules-in-requirement']} key={ruleInRequirement.rule.id}>
          <h2 className={styles['rules-in-requirement-title']}>{ruleInRequirement.rule.name}</h2>
          <RuleNodes rule={ruleInRequirement.rule} />
        </div>
      ))}
    </>
  );
}
