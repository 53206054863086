import { useOverlayTriggerState } from '@react-stately/overlays';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button, IconButton, Icon, FeedbackBox, ModalDialog, ButtonGroup } from '@uva-glass/component-library';

import type { FormEvent } from 'react';
import type {
  GetConstrainedChoiceClustersByDepartmentIdQuery,
  GetConstrainedChoiceClustersByDepartmentIdQueryVariables,
} from 'types/__generated__';

import { useRemoveConstrainedChoiceCluster } from 'App/ConstrainedChoices/ConstrainedChoicesTable/hooks/useRemoveConstrainedChoiceCluster';
import { useCurrentLanguage } from 'hooks/useCurrentLanguage';
import { useGetReadableMutationStatus } from 'hooks/useGetReadableMutationStatus';
import { useToast } from 'providers/ToastProvider';
import { MutationStatus } from 'types/__generated__';

interface Props extends GetConstrainedChoiceClustersByDepartmentIdQueryVariables {
  constrainedChoiceCluster: GetConstrainedChoiceClustersByDepartmentIdQuery['constrainedChoiceClusters'][number];
}

export function RemoveConstrainedChoice({ constrainedChoiceCluster, academicYear, departmentId }: Props) {
  const { t } = useTranslation('constrained-choices', { keyPrefix: 'remove-constrained-choice' });
  const currentLanguage = useCurrentLanguage();
  const { open, close, isOpen } = useOverlayTriggerState({});
  const [mutationError, setMutationError] = useState<string>();
  const [mutate, mutation] = useRemoveConstrainedChoiceCluster({ academicYear, departmentId });
  const readableMutationStatus = useGetReadableMutationStatus();
  const hasOfferings = constrainedChoiceCluster?.offerings.length !== 0;
  const toastContext = useToast();

  function resetAndClose() {
    setMutationError(undefined);
    mutation.reset();

    close();
  }

  function onSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (!constrainedChoiceCluster) return;
    mutate({
      variables: { constrainedChoiceClusterId: constrainedChoiceCluster?.id },
      onCompleted(data) {
        const { mutationStatus } = data.removeConstrainedChoiceCluster;

        if (mutationStatus === MutationStatus.Success) {
          resetAndClose();
          return;
        }

        if (mutationStatus === MutationStatus.ConstrainedChoiceClusterNotFound) {
          resetAndClose();
          toastContext.addToast({
            variant: 'warning',
            title: t('toast.constrained-choice-cluster-not-found'),
            content: readableMutationStatus(mutationStatus),
          });
          return;
        }

        setMutationError(readableMutationStatus(mutationStatus));
      },
      onError() {
        setMutationError(readableMutationStatus('unknown'));
      },
    });
  }

  return (
    <>
      <IconButton variant="destructive" aria-label={t('trigger')} onClick={open} disabled={hasOfferings}>
        <Icon name={hasOfferings ? 'TrashStrikethrough' : 'Trash'} />
      </IconButton>

      <ModalDialog
        isOpen={isOpen}
        title={t('modal.title')}
        onClose={resetAndClose}
        onSubmit={onSubmit}
        buttons={
          <ButtonGroup>
            <Button onClick={resetAndClose} variant="secondary">
              {t('modal.buttons.cancel')}
            </Button>
            <Button variant="destructive-primary" disabled={mutation.loading} type="submit">
              {t('modal.buttons.confirm')}
            </Button>
          </ButtonGroup>
        }
      >
        <p>
          <Trans
            t={t}
            i18nKey="modal.description"
            values={{ name: constrainedChoiceCluster?.title[currentLanguage] }}
            components={{ strong: <strong /> }}
          />
        </p>
        {mutationError && <FeedbackBox level="error" feedback={mutationError} />}
      </ModalDialog>
    </>
  );
}
