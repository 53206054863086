import type { IconProps } from '@uva-glass/component-library';
import type { StatusIndicatorProps } from 'components/StatusIndicator/StatusIndicator';

import { StatusIndicator } from 'components/StatusIndicator/StatusIndicator';

const iconNameMap: Partial<Record<StatusIndicatorProps['status'], IconProps['name']>> = {
  active: 'DotCircleFillMini',
  ended: 'MinusCircleFillMini',
  pending: 'ClockCircleFillMini',
  completed: 'CheckCircleFillMini',
};

export const ProgrammeStatusIndicator = (props: StatusIndicatorProps) => (
  <StatusIndicator {...props} name={iconNameMap[props.status]} />
);
