import { gql } from '@apollo/client';

import { PLAN_FRAGMENT } from 'graphql/fragments/planFragment';

export const GET_PLAN_BY_CODE = gql`
  query getPlanByCode($planCode: String!) {
    plan(planCode: $planCode) {
      ...plan
    }
  }
  ${PLAN_FRAGMENT}
`;
