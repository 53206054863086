import { useMutation } from '@apollo/client';

import type { StoreObject } from '@apollo/client';
import type {
  GetTopicSetsByDepartmentIdQuery,
  GetTopicSetsByDepartmentIdQueryVariables,
  RemoveTopicSetMutation,
  RemoveTopicSetMutationVariables,
} from 'types/__generated__';

import { REMOVE_TOPIC_SET } from 'graphql/mutations/removeTopicSet';
import { GET_TOPIC_SETS_BY_DEPARTMENT_ID } from 'graphql/queries/getTopicSetsByDepartmentId';
import { MutationStatus } from 'types/__generated__';

export const useRemoveTopicSet = ({ academicYear, departmentId }: GetTopicSetsByDepartmentIdQueryVariables) =>
  useMutation<RemoveTopicSetMutation, RemoveTopicSetMutationVariables>(REMOVE_TOPIC_SET, {
    update(cache, { data }) {
      if (data?.removeTopicSet.mutationStatus !== MutationStatus.Success) return;

      const { topicSetId } = data.removeTopicSet;

      if (!topicSetId) {
        throw new Error('No "topicSetId" in payload');
      }
      const topicSetToEvict: StoreObject = { __typename: 'TopicSet', id: topicSetId };

      cache.updateQuery<GetTopicSetsByDepartmentIdQuery, GetTopicSetsByDepartmentIdQueryVariables>(
        {
          query: GET_TOPIC_SETS_BY_DEPARTMENT_ID,
          variables: { academicYear, departmentId },
        },
        (cached) =>
          cached ? { topicSets: cached.topicSets.filter((topicSet) => topicSet.id !== topicSetId) } : undefined
      );

      cache.evict({ id: cache.identify(topicSetToEvict) });
      cache.gc();
    },
    refetchQueries({ data }) {
      // Re-fetching the topicset(s) to display the correct data on screen
      if (
        // Getting a TopicSetNotFound means that the topicSet has already been removed (other browser / user)
        data?.removeTopicSet.mutationStatus === MutationStatus.TopicSetNotFound
      ) {
        return [GET_TOPIC_SETS_BY_DEPARTMENT_ID];
      }
      return [];
    },
  });
