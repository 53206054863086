import { useMutation } from '@apollo/client';

import type { StoreObject } from '@apollo/client';
import type { LockGroupMutation, LockGroupMutationVariables } from 'types/__generated__';

import { LOCK_GROUP } from 'graphql/mutations/lockGroup';
import { GET_COURSE_OFFERING_BY_ID } from 'graphql/queries/getCourseOfferingById';
import { MutationStatus } from 'types/__generated__';

export const useLockGroup = () =>
  useMutation<LockGroupMutation, LockGroupMutationVariables>(LOCK_GROUP, {
    update(cache, { data }) {
      if (data?.lockStudentGroup.mutationStatus !== MutationStatus.Success) return;

      const { studentGroupId } = data.lockStudentGroup;
      const studentGroup: StoreObject = { __typename: 'StudentGroup', id: studentGroupId };

      cache.modify({
        id: cache.identify(studentGroup),
        fields: {
          locked() {
            return true;
          },
        },
      });
    },
    refetchQueries({ data }) {
      const mutationStatus = data?.lockStudentGroup.mutationStatus;

      if (mutationStatus === MutationStatus.Success) return [];

      return [GET_COURSE_OFFERING_BY_ID];
    },
  });
