import { gql } from '@apollo/client';

import { RULE_FRAGMENT } from 'graphql/fragments/ruleFragment';

export const UPDATE_INFO_REQUIREMENT = gql`
  mutation updateInfoRequirement($ruleId: String!, $nodeId: String!, $description: BilingualStringInput!) {
    updateInfoRequirement(input: { ruleId: $ruleId, nodeId: $nodeId, description: $description }) {
      mutationStatus
      rule {
        ...rule
      }
    }
  }
  ${RULE_FRAGMENT}
`;
