import { gql } from '@apollo/client';

export const SET_CREDIT_LIMIT_EXCEPTION_BY_STUDENT_ID = gql`
  mutation setCreditLimitExceptionByStudentId($studentId: String!, $hasCreditLimitException: Boolean!) {
    setCreditLimitException(input: { studentId: $studentId, hasCreditLimitException: $hasCreditLimitException }) {
      mutationStatus
      studentId
      hasCreditLimitException
    }
  }
`;
