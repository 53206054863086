/* eslint-disable @typescript-eslint/no-explicit-any */
// you can pass 'currentLanguage' as optional parameter 'language' in case of sorting on 'BilingualString'
export const alphabeticalSortBy =
  <T extends Record<string, any>, K extends keyof T>(key: K, language?: string) =>
  (object1: T, object2: T) => {
    if (language) {
      return object1[key][language].localeCompare(object2[key][language]);
    } else {
      return object1[key].localeCompare(object2[key]);
    }
  };
