import { useMutation } from '@apollo/client';

import type {
  CreateRuleRequiredStudentSetMutation,
  CreateRuleRequiredStudentSetMutationVariables,
  GetRulesByDepartmentIdQuery,
  GetRulesByDepartmentIdQueryVariables,
} from 'types/__generated__';

import { MutationStatus } from 'types/__generated__';
import { CREATE_RULE_REQUIRED_STUDENT_SET } from 'graphql/mutations/createRuleRequiredStudentSet';
import { GET_RULES_BY_DEPARTMENT_ID } from 'graphql/queries/getRulesByDepartmentId';

export const useCreateRuleRequiredStudentSet = () =>
  useMutation<CreateRuleRequiredStudentSetMutation, CreateRuleRequiredStudentSetMutationVariables>(
    CREATE_RULE_REQUIRED_STUDENT_SET,
    {
      update: (cache, { data }) => {
        if (data?.createRuleRequiredStudentSet.mutationStatus !== MutationStatus.Success) return;

        // If creating a new Studentset Rule was successful the NewStudentSetRule component will automatically navigate
        // the user to the newly created Studentset Rule
        // That would usually require the cache to be manually updated here for the `getRuleById` query for the new
        // `rule.id`, however, because (for now!) rules are real time, this isn't necessary.

        const { academicYear, rule } = data.createRuleRequiredStudentSet;

        if (!rule || !academicYear) {
          throw new Error('"rule" is not defined.');
        }

        cache.updateQuery<GetRulesByDepartmentIdQuery, GetRulesByDepartmentIdQueryVariables>(
          {
            query: GET_RULES_BY_DEPARTMENT_ID,
            variables: {
              departmentId: rule.department.id,
              academicYear: academicYear,
            },
          },
          (cached) => ({ rules: cached ? [...cached.rules, rule] : [rule] })
        );
      },
    }
  );
