import type { TableHTMLAttributes } from 'react';

import styles from './Table.module.css';

import { TableContainer } from 'components/TableContainer/TableContainer';

type Props = Omit<TableHTMLAttributes<HTMLTableElement>, 'className' | 'style'>;

export const Table = ({ children, ...restProps }: Props) => (
  <TableContainer>
    <table {...restProps} className={styles['table']}>
      {children}
    </table>
  </TableContainer>
);
