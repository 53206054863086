import { Trans, useTranslation } from 'react-i18next';
import { Icon, Card, Accordion } from '@uva-glass/component-library';

import type {
  GetCourseOfferingByIdQuery,
  GetStudentRequirementCheckByCourseOfferingIdQuery,
} from 'types/__generated__';

import styles from './Requirements.module.css';

import { EntryRequirementStatus } from 'App/CourseOffering/EntryRequirementStatus';
import { RequirementsCheckGroupRow } from 'App/CourseOffering/RequirementsCheckGroupRow/RequirementsCheckGroupRow';
import { RequirementsCheckGroupRowHeader } from 'App/CourseOffering/RequirementsCheckGroupRow/RequirementsCheckGroupRowHeader/RequirementsCheckGroupRowHeader';
import { useCurrentLanguage } from 'hooks/useCurrentLanguage';

type RequirementsCheck = NonNullable<
  GetStudentRequirementCheckByCourseOfferingIdQuery['studentRequirementsCheck']
>['requirementsCheck'];

interface Props {
  requirementsCheck: RequirementsCheck;
  courseInfo?: NonNullable<GetCourseOfferingByIdQuery['marblesCourseOffering']>['info'];
}

export function Requirements({ requirementsCheck, courseInfo }: Props) {
  const { t } = useTranslation('course-offering', { keyPrefix: 'requirements' });
  const currentLanguage = useCurrentLanguage();
  const { groups } = requirementsCheck;
  const [applicableRequirements] = groups.filter(({ applies }) => applies);

  // student DOES NOT have any applicable requirements
  if (groups.length && !applicableRequirements)
    return (
      <Card
        highContrast
        padding="small"
        header={
          <div className={styles['requirements__no-applicable-requirements-header']}>
            <Icon
              className={styles['requirements__no-applicable-requirements-icon']}
              name={'CrossCircleFillMini'}
              size={20}
            />
            <div>
              <Trans
                t={t}
                i18nKey="student-does-not-fulfill-requirements"
                values={{
                  courseTitle: courseInfo?.title[currentLanguage],
                }}
                components={{
                  bold: <strong />,
                  red: <strong className={styles['requirements__no-applicable-requirements-highlight-text']} />,
                }}
              />
            </div>
          </div>
        }
      >
        <div>
          <span>{t('course-open-for-following-students')}</span>
          <ul className={styles['requirements__no-applicable-requirements-programme-list']}>
            {groups.map(({ descriptions }, groupIndex) =>
              descriptions.map((description, descriptionIndex) => (
                <li key={`${groupIndex}-${descriptionIndex}`}>{description[currentLanguage]}</li>
              ))
            )}
          </ul>
        </div>
      </Card>
    );

  // student DOES have applicable requirements,
  if (applicableRequirements) {
    const passCount = applicableRequirements.rows.filter((row) => row.status === 'PASS').length;

    return (
      <Card
        highContrast
        padding="small"
        header={
          <>
            <strong>{applicableRequirements.name}</strong>
            <EntryRequirementStatus
              status={passCount === applicableRequirements.rows.length ? 'completed' : 'fail'}
              title={passCount === applicableRequirements.rows.length ? t('pass') : t('fail')}
              label={`${passCount}/${applicableRequirements.rows.length}`}
            />
          </>
        }
      >
        {applicableRequirements.rows.map((row) =>
          row.title ? (
            row.details.length ? (
              <Accordion
                key={row.title[currentLanguage]}
                title={<RequirementsCheckGroupRowHeader row={row} header="span" />}
                content={<RequirementsCheckGroupRow row={row} />}
              />
            ) : (
              <div key={row.title[currentLanguage]}>
                <RequirementsCheckGroupRowHeader row={row} header="span" />
              </div>
            )
          ) : null
        )}
      </Card>
    );
  }

  // there are no requirements attached to this course
  return <Card highContrast padding="small" header={t('no-requirements')} />;
}
