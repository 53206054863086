import { useMutation } from '@apollo/client';

import type {
  MarblesAuthoriseUserMutationInput,
  AuthoriseUserMutation,
  GetUsersQuery,
  GetUserAuthorisationsQueryVariables,
  GetUserAuthorisationsQuery,
} from 'types/__generated__';

import { AUTHORISE_USER } from 'graphql/mutations/authoriseUser';
import { MutationStatus, UserStatus } from 'types/__generated__';
import { GET_USERS } from 'graphql/queries/getUsers';
import { GET_USER_AUTHORISATIONS } from 'graphql/queries/getUserAuthorisations';

export const useAuthoriseUser = () =>
  useMutation<AuthoriseUserMutation, MarblesAuthoriseUserMutationInput>(AUTHORISE_USER, {
    update(cache, { data }) {
      if (data?.marblesAuthoriseUser.mutationStatus !== MutationStatus.Success) return;

      const { username, roleAssignment } = data.marblesAuthoriseUser;

      if (!username) throw new Error('No "username" in payload');

      if (!roleAssignment) throw new Error('No "roleAssignment" in payload');

      cache.updateQuery<GetUsersQuery>({ query: GET_USERS }, (cached) => {
        if (!cached) return undefined;

        const isNewUser = !cached.marblesUsers.some((user) => user.username === username);

        if (isNewUser) {
          const marblesUsers = [
            ...cached.marblesUsers,
            {
              name: '',
              familyName: '',
              givenName: '',
              username: username,
              status: UserStatus.Active,
              roleAssignments: [roleAssignment],
            },
          ];
          return { marblesUsers };
        } else {
          const marblesUsers = cached.marblesUsers.map((user) => {
            if (user.username === username) {
              return {
                ...user,
                roleAssignments: [...user.roleAssignments, roleAssignment],
              };
            }
            return user;
          });
          return { marblesUsers };
        }
      });

      cache.updateQuery<GetUserAuthorisationsQuery, GetUserAuthorisationsQueryVariables>(
        {
          query: GET_USER_AUTHORISATIONS,
          variables: { username: username },
        },
        (cached) =>
          cached?.marblesUser
            ? {
                marblesUser: {
                  ...cached.marblesUser,
                  roleAssignments: [...cached.marblesUser.roleAssignments, roleAssignment],
                },
              }
            : undefined
      );
    },
  });
