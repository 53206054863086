import { useState } from 'react';
import { clsx } from 'clsx';

import type { ReactNode } from 'react';

import styles from './Tabs.module.css';

export type TabItem = {
  content: ReactNode;
  disabled?: boolean;
  id: string;
  label: string;
};

interface Props {
  items: Array<TabItem>;
}

export function Tabs({ items }: Props) {
  const [activeTabIndex, setActiveTabIndex] = useState(items.findIndex(({ disabled }) => !disabled));

  return (
    <>
      <nav className={styles.tabs}>
        <ul className={styles.tabs__list}>
          {items.map(({ disabled, id, label }, index) => (
            <li
              key={`${id}-tab`}
              className={clsx(styles['tabs__item'], { [styles['tabs__item--is-active']]: index === activeTabIndex })}
            >
              <button
                aria-expanded={index === activeTabIndex}
                className={styles.tabs__button}
                disabled={disabled}
                id={id}
                onClick={() => {
                  setActiveTabIndex(index);
                }}
              >
                {label}
              </button>
            </li>
          ))}
        </ul>
      </nav>

      {items.map(({ id, content }, index) => (
        <div
          key={`${id}-content`}
          className={styles.tabs__content}
          aria-hidden={index !== activeTabIndex}
          aria-labelledby={`${id}-tab`}
          role="region"
        >
          {content}
        </div>
      ))}
    </>
  );
}
