import { useQuery } from '@apollo/client';

import type { GetProfileQuery } from 'types/__generated__';

import { GET_PROFILE } from 'graphql/queries/getProfile';
import { useAuth } from 'providers/AuthProvider';

export const Username = () => {
  const user = useAuth();
  const { error, data } = useQuery<GetProfileQuery>(GET_PROFILE);

  if (user === null || error || !data || !data.me?.name) {
    return null;
  }

  return <>{data.me.name}</>;
};
