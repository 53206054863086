import { gql } from '@apollo/client';

import { RULE_FRAGMENT } from './ruleFragment';

export const REQUIREMENT_FRAGMENT = gql`
  fragment requirement on Requirement {
    id
    name
    topics {
      id
    }
    department {
      id
    }
    courseOfferings {
      id
    }
    rulesInRequirement {
      id
      allocationRound
      priority
      priorityLimit
      includeInRetry
      rule {
        ...rule
      }
    }
  }
  ${RULE_FRAGMENT}
`;
