import classNames from 'clsx';
import { Icon } from '@uva-glass/component-library';

import type { GetStudentRequirementCheckByCourseOfferingIdQuery } from 'types/__generated__';

import styles from './RequirementsCheckGroupRow.module.css';

import { useCurrentLanguage } from 'hooks/useCurrentLanguage';
import { RequirementStatus } from 'types/__generated__';

type RowData = NonNullable<
  GetStudentRequirementCheckByCourseOfferingIdQuery['studentRequirementsCheck']
>['requirementsCheck']['groups'][number]['rows'][number];

interface Props {
  row: RowData;
}

function getIconName(status: RequirementStatus) {
  if (status === RequirementStatus.Pass) return 'CheckCircle';
  if (status === RequirementStatus.Fail) return 'CrossCircle';
  return 'ExclamationCircle';
}

function getIconClassName(status: RequirementStatus) {
  let className = styles['requirements-check-group-row__icon--inconclusive'];

  if (status === RequirementStatus.Pass) {
    className = styles['requirements-check-group-row__icon--pass'];
  }

  if (status === RequirementStatus.Fail) {
    className = styles['requirements-check-group-row__icon--fail'];
  }

  return classNames(styles['requirements-check-group-row__icon'], className);
}

export function RequirementsCheckGroupRow({ row }: Props) {
  const currentLanguage = useCurrentLanguage();

  return (
    <article className={styles['requirements-check-group-row']}>
      <ul className={styles['requirements-check-group-row__details-list']}>
        {row.details.map((detail) => (
          <li key={detail.courseInfo?.catalogNumber}>
            <Icon className={getIconClassName(detail.status)} size={20} name={getIconName(detail.status)} />
            {detail.courseInfo?.title[currentLanguage]} {detail.courseInfo?.ec && <>({detail.courseInfo?.ec} EC)</>}
          </li>
        ))}
      </ul>
    </article>
  );
}
