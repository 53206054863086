import { DateTime } from 'luxon';
import { useQuery } from '@apollo/client';
import { Spinner } from '@uva-glass/component-library';

import type {
  GetCourseOfferingGroupSwapsByIdQuery,
  GetCourseOfferingGroupSwapsByIdQueryVariables,
} from 'types/__generated__';

import { CancelledGroupSwapTable } from 'App/CourseOffering/CourseOfferingGroupSwap/CancelledGroupSwapTable';
import { ExpiredGroupSwapTable } from 'App/CourseOffering/CourseOfferingGroupSwap/ExpiredGroupSwapTable';
import { GET_COURSE_OFFERING_GROUP_SWAPS_BY_ID } from 'graphql/queries/getCourseOfferingGroupSwapsById';
import { GroupSwapStatus, UserAction } from 'types/__generated__';
import { PendingCourseOfferingGroupSwapTable } from 'App/CourseOffering/CourseOfferingGroupSwap/PendingCourseOfferingGroupSwapTable';
import { ProcessedCourseOfferingGroupSwapTable } from 'App/CourseOffering/CourseOfferingGroupSwap/ProcessedCourseOfferingGroupSwapTable';
import { useGetCourseOffering } from 'hooks/useGetCourseOffering';
import { useAuthorisation } from 'providers/AuthorisationProvider';

export function CourseOfferingGroupSwap() {
  const { marblesCourseOffering } = useGetCourseOffering();
  const { id, groupSwapExpirationDate, departmentId } = marblesCourseOffering;

  const userCanProcessGroupSwapRequests = useAuthorisation(UserAction.ProcessGroupSwapRequests, departmentId);
  const courseOfferingGroupSwapHasNotExpired = DateTime.now() < DateTime.fromISO(groupSwapExpirationDate);

  const query = useQuery<GetCourseOfferingGroupSwapsByIdQuery, GetCourseOfferingGroupSwapsByIdQueryVariables>(
    GET_COURSE_OFFERING_GROUP_SWAPS_BY_ID,
    {
      variables: { courseOfferingId: id },
      fetchPolicy: 'cache-and-network',
    }
  );

  if (query.loading) return <Spinner ariaValueText={''} />;

  if (query.error || !query.data?.courseOfferingGroupSwaps) return <>Error</>;

  const { courseOfferingGroupSwaps } = query.data;

  const pendingGroupSwaps = courseOfferingGroupSwaps.filter(
    (groupSwap) => groupSwap.status === GroupSwapStatus.Pending
  );
  const expiredGroupSwaps = courseOfferingGroupSwaps.filter(
    (groupSwap) => groupSwap.status === GroupSwapStatus.Expired
  );
  const processedGroupSwaps = courseOfferingGroupSwaps.filter(
    (groupSwap) => groupSwap.status === GroupSwapStatus.Completed
  );
  const cancelledGroupSwaps = courseOfferingGroupSwaps.filter(
    (groupSwap) => groupSwap.status === GroupSwapStatus.Cancelled
  );

  return (
    <>
      {(pendingGroupSwaps.length || courseOfferingGroupSwapHasNotExpired) && (
        <PendingCourseOfferingGroupSwapTable
          courseOfferingId={marblesCourseOffering.id}
          groupSwaps={pendingGroupSwaps}
          userCanProcessGroupSwapRequests={userCanProcessGroupSwapRequests}
        />
      )}
      {(expiredGroupSwaps.length || !courseOfferingGroupSwapHasNotExpired) && (
        <ExpiredGroupSwapTable groupSwaps={expiredGroupSwaps} />
      )}
      <ProcessedCourseOfferingGroupSwapTable groupSwaps={processedGroupSwaps} />
      <CancelledGroupSwapTable groupSwaps={cancelledGroupSwaps} />
    </>
  );
}
