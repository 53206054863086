import { useTranslation } from 'react-i18next';
import { Icon, Card } from '@uva-glass/component-library';

import styles from './ErrorPage.module.css';

import { Page } from 'components/Page/Page';
import { ActionHeader } from 'App/shared/ActionHeader/ActionHeader';

interface Props {
  message?: string;
}

export function ErrorPage({ message }: Props) {
  const { t } = useTranslation('error');

  return (
    <Page documentTitle={t('title')} header={<ActionHeader />}>
      <div className={styles['error-page__container']}>
        <Card highContrast padding="large" alignCenter>
          <div className={styles['error-page__icon']}>
            <Icon name="BrowserBroken" size={128} className={styles['error-page__icon']} />
          </div>
          <div className={styles['error-page__content']}>
            <div className={styles['error-page__text']}>
              <h1 className={styles['error-page__title']}>{t('title')}</h1>

              <p className={styles['error-page__description']}>{t('description')}</p>
            </div>

            <Card padding="small" alignCenter>
              <h2 className={styles['error-page__error-title']}>{t('error.title')}</h2>
              <p className={styles['error-page__error-message']}>
                {message ? <small>{message}</small> : t('error.placeholder')}
              </p>
            </Card>
          </div>
        </Card>
      </div>
    </Page>
  );
}
