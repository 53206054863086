import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import type { GetRequirementByIdQuery } from 'types/__generated__';

import styles from './RuleNodes.module.css';

import { useCurrentLanguage } from 'hooks/useCurrentLanguage';
import { RequiredCoursesRequirement } from 'types/__generated__';

type Rule = NonNullable<GetRequirementByIdQuery['requirement']>['rulesInRequirement'][number]['rule'];
type Node = Rule['nodes']['groupRequirements'][number];

interface Props {
  rule: Rule;
}

export function RuleNodes({ rule }: Props) {
  const { t } = useTranslation('common', { keyPrefix: 'rule-nodes' });
  const currentLanguage = useCurrentLanguage();

  function getTypeDescription(node: Node) {
    switch (node.__typename) {
      case 'RequiredPlan':
        return [
          t('plan', { plan: node.plan.description, code: node.plan.code }),
          node.subplans && node.subplans.length > 0
            ? t('subplan', {
                count: node.subplans.length,
                plan: node.subplans.map(({ description, code }) => `${description} (${code})`),
              })
            : '',
        ].filter(Boolean);
      case 'RequiredCareer':
        return [
          t('career', {
            count: node.careers.length,
            career: node.careers.map(({ description, code }) => `${description[currentLanguage]} (${code})`).join(', '),
          }),
        ];
      case 'RequiredStudentSet':
        return [`${node.studentSet?.code} - ${node.studentSet?.title[currentLanguage]}`];
      default:
        return [];
    }
  }

  return (
    <>
      {rule.nodes.groupRequirements.length > 0 && (
        <>
          <h3 className={styles['rule-nodes__title']}>{t('group-requirements')}</h3>
          <ul className={styles['rule-nodes__nodes']}>
            {rule.nodes.groupRequirements.map((node) =>
              getTypeDescription(node).map((description) => <li key={description}>{description}</li>)
            )}
          </ul>
        </>
      )}
      {Boolean(rule.nodes.requirements.length) && (
        <h3 className={styles['rule-nodes__title']}>{t('recommended-prior-knowledge')}</h3>
      )}
      {rule.nodes.requirements.map((requirement) => (
        <Fragment key={requirement.id}>
          <h3 className={styles['rule-nodes__title']}>{requirement.description[currentLanguage]}</h3>

          {requirement.__typename === 'RequiredCourses' && (
            <>
              {requirement.requirement === RequiredCoursesRequirement.AllCourses && <>{t('all-courses')}</>}
              {requirement.requirement === RequiredCoursesRequirement.MinimumCourses && requirement.requiredCourses && (
                <div>
                  {t('courses-out-of', { count: requirement.requiredCourses, total: requirement.courses.length })}:
                </div>
              )}
              {requirement.requirement === RequiredCoursesRequirement.MinimumCredits && requirement.requiredCredits && (
                <div>{t('ec-out-of', { ec: requirement.requiredCredits })}:</div>
              )}

              <ul className={styles['rule-nodes__nodes']}>
                {requirement.courses.map((course, courseIndex) => (
                  // setting map index; list can contain duplicate values
                  <li key={course.id + courseIndex}>
                    {course.title[currentLanguage]} ({course.catalogNumber}, {course.ec} EC)
                  </li>
                ))}
              </ul>
            </>
          )}
        </Fragment>
      ))}
    </>
  );
}
