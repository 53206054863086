import { useTranslation } from 'react-i18next';
import { ButtonGroup } from '@uva-glass/component-library';

import type { RequiredCourses } from 'types/__generated__';

import { EditRequiredCoursesNode } from './EditRequiredCoursesNode';
import { RemoveRequiredCourses } from './RemoveRequiredCourses';
import styles from './RequiredCoursesNode.module.css';

import { useCurrentLanguage } from 'hooks/useCurrentLanguage';
import { RequiredCoursesRequirement } from 'types/__generated__';

interface Props {
  requirement: RequiredCourses;
  ruleId: string;
  canEdit: boolean;
}
export function RequiredCoursesNode({ requirement, ruleId, canEdit }: Props) {
  const { t } = useTranslation('requirement-rules', { keyPrefix: 'rule.optional-requirements' });
  const currentLanguage = useCurrentLanguage();

  return (
    <div className={styles['required-courses-node']}>
      <div className={styles['required-courses-node__header']}>
        <div>
          <strong>{requirement.description[currentLanguage]}</strong>

          <div className={styles['required-courses-node__subheader']}>
            {requirement.requirement === RequiredCoursesRequirement.AllCourses &&
              t('all-courses', { count: requirement.courses.length })}
            {requirement.requirement === RequiredCoursesRequirement.MinimumCourses &&
              t('minimum-courses', {
                minimumCount: requirement.requiredCourses,
                coursesCount: requirement.courses.length,
              })}
            {requirement.requirement === RequiredCoursesRequirement.MinimumCredits &&
              t('minimum-credits', {
                minimumCount: requirement.requiredCredits,
                creditsCount: requirement.courses.reduce((acc, { ec }) => acc + ec, 0),
              })}
          </div>
        </div>
        {canEdit && (
          <ButtonGroup>
            <EditRequiredCoursesNode ruleId={ruleId} requirement={requirement} />
            <RemoveRequiredCourses ruleId={ruleId} requirement={requirement} />
          </ButtonGroup>
        )}
      </div>

      <div className={styles['required-courses-node__list-container']}>
        <div className={styles['required-courses-node__list-scroll-container']}>
          <ul className={styles['required-courses-node__list']}>
            {requirement.courses.map((course) => (
              <li key={course.id} className={styles['required-courses-node__list-item']}>
                <div>{course.title[currentLanguage]}</div>
                <div className={styles['required-courses-node__list-item-align']}>{course.catalogNumber}</div>
                <div className={styles['required-courses-node__list-item-align']}>
                  {t('required-courses-table.values.ec', { count: course.ec })}
                </div>
                <div />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
