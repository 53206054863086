import { useMutation } from '@apollo/client';

import type {
  CreateRuleRequiredCareerMutation,
  CreateRuleRequiredCareerMutationVariables,
  GetRulesByDepartmentIdQuery,
  GetRulesByDepartmentIdQueryVariables,
} from 'types/__generated__';

import { CREATE_RULE_REQUIRED_CAREER } from 'graphql/mutations/createRuleRequiredCareer';
import { GET_RULES_BY_DEPARTMENT_ID } from 'graphql/queries/getRulesByDepartmentId';
import { MutationStatus } from 'types/__generated__';

export const useCreateRuleRequiredCareer = () =>
  useMutation<CreateRuleRequiredCareerMutation, CreateRuleRequiredCareerMutationVariables>(
    CREATE_RULE_REQUIRED_CAREER,
    {
      update(cache, { data }) {
        if (data?.createRuleRequiredCareer.mutationStatus !== MutationStatus.Success) return;

        // If creating a new Career Rule was successful the NewCareerRule component will automatically navigate the user
        // to the newly created Career Rule
        // That would usually require the cache to be manually updated here for the `getRuleById` query for the new
        // `rule.id`, however, because (for now!) rules are real time, this isn't necessary.

        const { rule, academicYear } = data.createRuleRequiredCareer;

        if (!rule) {
          throw new Error('No "Rule" in payload.');
        }

        cache.updateQuery<GetRulesByDepartmentIdQuery, GetRulesByDepartmentIdQueryVariables>(
          { query: GET_RULES_BY_DEPARTMENT_ID, variables: { departmentId: rule.department.id, academicYear } },
          (cached) => ({ rules: cached ? [...cached.rules, rule] : [rule] })
        );
      },
    }
  );
