import { useOverlayTriggerState } from '@react-stately/overlays';
import { Trans, useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Button, IconButton, Icon, FeedbackBox, ModalDialog, ButtonGroup } from '@uva-glass/component-library';

import type { FormEvent } from 'react';
import type { GetTopicsByTopicSetIdQuery } from 'types/__generated__';

import { MutationStatus } from 'types/__generated__';
import { useRemoveTopic } from 'App/TopicSets/TopicSet/TopicsTable/hooks/useRemoveTopic';
import { useCurrentLanguage } from 'hooks/useCurrentLanguage';
import { useGetReadableMutationStatus } from 'hooks/useGetReadableMutationStatus';

interface Props {
  topic: NonNullable<GetTopicsByTopicSetIdQuery['topicSet']>['topics'][number];
  isDisabled?: boolean;
}

export function RemoveTopic({ topic, isDisabled }: Props) {
  const { t } = useTranslation('topic-sets', { keyPrefix: 'remove-topic' });
  const currentLanguage = useCurrentLanguage();

  const { open, close, isOpen } = useOverlayTriggerState({});
  const readableMutationStatus = useGetReadableMutationStatus();
  const [mutate, mutation] = useRemoveTopic();

  const [mutationError, setMutationError] = useState<string>();

  function resetAndClose() {
    setMutationError(undefined);
    mutation.reset();

    close();
  }

  function onSubmit(event: FormEvent) {
    event.preventDefault();

    mutate({
      variables: { topicId: topic.id },
      onCompleted(data) {
        const { mutationStatus } = data.removeTopic;

        switch (mutationStatus) {
          case MutationStatus.Success:
          case MutationStatus.TopicNotFound:
            resetAndClose();
            return;

          default:
            setMutationError(readableMutationStatus(data.removeTopic.mutationStatus));
            break;
        }
      },
      onError(error) {
        if (error.graphQLErrors.length) {
          setMutationError(readableMutationStatus(MutationStatus.Corrupt));
        } else {
          setMutationError(readableMutationStatus('unknown'));
        }
      },
    });
  }

  return (
    <>
      <IconButton variant="destructive" aria-label={t('trigger')} onClick={open} disabled={isDisabled}>
        <Icon name={isDisabled ? 'TrashStrikethrough' : 'Trash'} />
      </IconButton>

      <ModalDialog
        isOpen={isOpen}
        title={t('modal.title')}
        onClose={resetAndClose}
        onSubmit={onSubmit}
        buttons={
          <ButtonGroup>
            <Button variant="secondary" onClick={resetAndClose}>
              {t('modal.buttons.cancel')}
            </Button>
            <Button variant="destructive-primary" onClick={onSubmit}>
              {t('modal.buttons.confirm')}
            </Button>
          </ButtonGroup>
        }
      >
        <p>
          <Trans
            t={t}
            i18nKey="modal.description"
            values={{ name: topic.title[currentLanguage] }}
            components={{ strong: <strong /> }}
          />
        </p>
        {mutationError && <FeedbackBox level="error" feedback={mutationError} />}
      </ModalDialog>
    </>
  );
}
