import { useQuery } from '@apollo/client';
import { Trans, useTranslation } from 'react-i18next';
import { Accordion, Spinner } from '@uva-glass/component-library';

import type {
  GetPendingPursuitsByCourseOfferingIdQuery,
  GetStudentRequirementCheckByCourseOfferingIdQuery,
  GetStudentRequirementCheckByCourseOfferingIdQueryVariables,
} from 'types/__generated__';

import styles from './StudentRequirementStatus.module.css';

import { RequirementsCheckGroupRow } from 'App/CourseOffering/RequirementsCheckGroupRow/RequirementsCheckGroupRow';
import { RequirementsCheckGroupRowHeader } from 'App/CourseOffering/RequirementsCheckGroupRow/RequirementsCheckGroupRowHeader/RequirementsCheckGroupRowHeader';
import { GET_STUDENT_REQUIREMENT_CHECK_BY_COURSE_OFFERING_ID } from 'graphql/queries/getStudentRequirementCheckByCourseOfferingId';
import { useGetCourseOffering } from 'hooks/useGetCourseOffering';
import { useCurrentLanguage } from 'hooks/useCurrentLanguage';

interface Props {
  student: GetPendingPursuitsByCourseOfferingIdQuery['courseOfferingPursuits'][number]['student'];
}

export function StudentRequirementStatus({ student }: Props) {
  const { t } = useTranslation('course-offering', { keyPrefix: 'student-requirement-status' });
  const currentLanguage = useCurrentLanguage();
  const {
    marblesCourseOffering: { id: courseOfferingId, info: courseInfo },
  } = useGetCourseOffering();

  const { loading, error, data } = useQuery<
    GetStudentRequirementCheckByCourseOfferingIdQuery,
    GetStudentRequirementCheckByCourseOfferingIdQueryVariables
  >(GET_STUDENT_REQUIREMENT_CHECK_BY_COURSE_OFFERING_ID, {
    variables: {
      courseOfferingId,
      studentId: student.id,
    },
  });

  if (loading) return <Spinner ariaValueText={''} />;
  if (error || !data?.studentRequirementsCheck) return <>Error</>;

  const { studentRequirementsCheck } = data;

  // get the first possible matching group; other matching groups have a lower priority over the first matching one
  const applicableRequirementGroup = studentRequirementsCheck.requirementsCheck.groups.find(({ applies }) => applies);

  if (!applicableRequirementGroup?.rows.length || !applicableRequirementGroup.descriptions) return null;

  return (
    <>
      <p>
        <Trans
          t={t}
          i18nKey="intro"
          values={{
            coursePursuitTitle: courseInfo?.title[currentLanguage],
            coursePursuitCatalogNumber: courseInfo?.catalogNumber,
          }}
          components={{ bold: <strong /> }}
        />
      </p>

      <h2 className={styles['student-requirement-status__group-header']}>
        {applicableRequirementGroup.descriptions.map((description) => description[currentLanguage]).join(', ')}
      </h2>

      {applicableRequirementGroup.rows.map((row) =>
        row.title ? (
          <Accordion
            key={row.title[currentLanguage]}
            title={<RequirementsCheckGroupRowHeader row={row} header="h4" />}
            content={<RequirementsCheckGroupRow row={row} />}
          />
        ) : null
      )}
    </>
  );
}
