import { useMutation } from '@apollo/client';
import { useOverlayTriggerState } from '@react-stately/overlays';
import {
  Button,
  Icon,
  IconButton,
  Label,
  InputField,
  FeedbackBox,
  ModalDialog,
  ButtonGroup,
  FormField,
} from '@uva-glass/component-library';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { ChangeEvent, FormEvent } from 'react';
import type {
  InfoRequirement,
  UpdateInfoRequirementMutation,
  UpdateInfoRequirementMutationVariables,
} from 'types/__generated__';

import { UPDATE_INFO_REQUIREMENT } from 'graphql/mutations/updateInfoRequirement';
import { useGetReadableMutationStatus } from 'hooks/useGetReadableMutationStatus';
import { MutationStatus } from 'types/__generated__';

interface Props extends Pick<UpdateInfoRequirementMutationVariables, 'ruleId'> {
  requirement: InfoRequirement;
}

const emptyBilingualString = { EN: '', NL: '' };
const ID_INPUT_NL = 'ID_INPUT_NL';
const ID_ERROR_NL = 'ID_ERROR_NL';
const ID_INPUT_EN = 'ID_INPUT_EN';
const ID_ERROR_EN = 'ID_ERROR_EN';

export function EditInfoRequirement({ ruleId, requirement }: Props) {
  const { t } = useTranslation('requirement-rules', { keyPrefix: 'info-requirement-form' });
  const { open, close, isOpen } = useOverlayTriggerState({});
  const readableMutationStatus = useGetReadableMutationStatus();

  const [description, setDescription] = useState(requirement.description);
  const [fieldError, setFieldError] = useState(emptyBilingualString);
  const [error, setError] = useState<string>();
  const [mutate, mutation] = useMutation<UpdateInfoRequirementMutation, UpdateInfoRequirementMutationVariables>(
    UPDATE_INFO_REQUIREMENT
  );

  function onChange(event: ChangeEvent<HTMLInputElement>) {
    const { value, name } = event.target;

    setFieldError(emptyBilingualString);
    setError(undefined);
    setDescription((prevState) => ({ ...prevState, [name]: value }));
  }

  function onSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (!description.NL || description.NL.trim() === '' || !description.EN || description.EN.trim() === '') {
      if (!description.NL || description.NL.trim() === '') {
        setFieldError((prevState) => ({ ...prevState, NL: t('modal.field-error') }));
      }

      if (!description.EN || description.EN.trim() === '') {
        setFieldError((prevState) => ({ ...prevState, EN: t('modal.field-error') }));
      }

      return;
    }

    const { __typename, ...updatedDescription } = description;

    const trimmedDescription = {
      NL: updatedDescription.NL.trim(),
      EN: updatedDescription.EN.trim(),
    };

    mutate({
      variables: { ruleId, nodeId: requirement.id, description: trimmedDescription },
      onCompleted(data) {
        const { mutationStatus } = data.updateInfoRequirement;

        if (mutationStatus === MutationStatus.Success) {
          close();
          return;
        }
        setError(readableMutationStatus(mutationStatus));
      },
    });
  }

  return (
    <>
      <IconButton aria-label={t('trigger')} onClick={open}>
        <Icon name="PencilSquare" />
      </IconButton>

      <ModalDialog
        title={t('modal.title')}
        isOpen={isOpen}
        onClose={close}
        buttons={
          <ButtonGroup reversed>
            <Button variant="primary" type="submit" disabled={mutation.loading}>
              {t('modal.buttons.save')}
            </Button>
            <Button variant="secondary" onClick={close} disabled={mutation.loading}>
              {t('modal.buttons.cancel')}
            </Button>
          </ButtonGroup>
        }
        onSubmit={onSubmit}
        wide
      >
        <FormField outerSpace>
          <Label htmlFor={ID_INPUT_NL}>{t('modal.label-nl')}</Label>
          <InputField
            id={ID_INPUT_NL}
            name="NL"
            onChange={onChange}
            value={description.NL}
            aria-describedby={fieldError.NL ? ID_ERROR_NL : undefined}
          />
          {fieldError.NL && <FeedbackBox level="error" feedback={fieldError.NL} id={ID_ERROR_NL} />}
        </FormField>
        <FormField outerSpace>
          <Label htmlFor={ID_INPUT_EN}>{t('modal.label-en')}</Label>
          <InputField
            id={ID_INPUT_EN}
            name="EN"
            onChange={onChange}
            value={description.EN}
            aria-describedby={fieldError.EN ? ID_ERROR_EN : undefined}
          />
          {fieldError.EN && <FeedbackBox level="error" feedback={fieldError.EN} id={ID_ERROR_EN} />}
        </FormField>
        {error && <FeedbackBox level="error" feedback={error} />}
      </ModalDialog>
    </>
  );
}
