import { gql } from '@apollo/client';

import { DEPARTMENT_FRAGMENT } from 'graphql/fragments/departmentFragment';

export const GET_DEPARTMENTS_BY_REQUIREMENT_ID = gql`
  query getDepartmentsByRequirementId($requirementId: String!) {
    requirementCopyDepartments(requirementId: $requirementId) {
      ...department
    }
  }
  ${DEPARTMENT_FRAGMENT}
`;
