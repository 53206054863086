import { gql } from '@apollo/client';

export const GET_CONSTRAINED_CHOICE_CLUSTERS_BY_DEPARTMENT_ID = gql`
  query getConstrainedChoiceClustersByDepartmentId($departmentId: String!, $academicYear: Int!) {
    constrainedChoiceClusters(departmentId: $departmentId, academicYear: $academicYear) {
      id
      registrationLimit
      title {
        EN
        NL
      }
      offerings {
        id
        info {
          title {
            EN
            NL
          }
        }
      }
    }
  }
`;
