import { useMutation } from '@apollo/client';
import { useOverlayTriggerState } from '@react-stately/overlays';
import {
  Button,
  FeedbackBox,
  Label,
  ModalDialog,
  Spinner,
  Checkbox,
  ButtonGroup,
  FormField,
} from '@uva-glass/component-library';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { ChangeEvent, FormEvent } from 'react';
import type {
  GetCourseOfferingsQueryVariables,
  UploadBulkCourseDeregistrationsMutation,
  UploadBulkCourseDeregistrationsMutationVariables,
} from 'types/__generated__';

import { DeregistrationReason } from 'App/shared/DeregistrationReason/DeregistrationReason';
import { UploadResults } from 'App/shared/UploadResults/UploadResults';
import { UploadRowErrors } from 'App/shared/UploadRowErrors/UploadRowErrors';
import { Input } from 'components/Input/Input';
import { UPLOAD_BULK_COURSE_DEREGISTRATIONS } from 'graphql/mutations/uploadBulkCourseDeregistrations';
import { useGetReadableMissingColumn } from 'hooks/useGetReadableMissingColumns';
import { useGetReadableMutationStatus } from 'hooks/useGetReadableMutationStatus';
import { MutationStatus, StaffDeregistrationReason } from 'types/__generated__';
import { ACCEPTED_FILE_TYPES } from 'util/acceptedFileTypes';

const INPUT_FILE_ID = 'file-upload';
const IGNORE_DEREGISTERED_PURSUITS_ID = 'ignore-deregistered-pursuits';
const INPUT_FILE_ERROR_ID = 'file-upload-error';

type UploadMultiCourseDeregistrationsResult =
  UploadBulkCourseDeregistrationsMutation['uploadBulkCourseDeregistrations']['result'];
type UploadMultiCourseDeregistrationsMissingColumns =
  UploadBulkCourseDeregistrationsMutation['uploadBulkCourseDeregistrations']['missingColumns'];

type Props = {
  academicYear: GetCourseOfferingsQueryVariables['academicYear'];
};

export function UploadMultiCourseDeregistrationsModal({ academicYear }: Props) {
  const { t } = useTranslation('course-offerings', { keyPrefix: 'upload-multi-course-deregistrations-modal' });
  const readableMutationStatus = useGetReadableMutationStatus();
  const readableMissingColumn = useGetReadableMissingColumn();
  const { close, isOpen, open } = useOverlayTriggerState({});

  const [deregistrationReason, setDeregistrationReason] = useState<StaffDeregistrationReason>(
    StaffDeregistrationReason.Administrative
  );
  const [deregistrationReasonError, setDeregistrationReasonError] = useState('');
  const [file, setFile] = useState<File>();
  const [fileError, setFileError] = useState('');
  const [results, setResults] = useState<UploadMultiCourseDeregistrationsResult>([]);
  const [currentMissingColumns, setCurrentMissingColumns] =
    useState<UploadMultiCourseDeregistrationsMissingColumns>(null);
  const [ignorePursuitsNotFound, setIgnorePursuitsNotFound] = useState(false);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const [mutate, mutation] = useMutation<
    UploadBulkCourseDeregistrationsMutation,
    UploadBulkCourseDeregistrationsMutationVariables
  >(UPLOAD_BULK_COURSE_DEREGISTRATIONS);
  const errorRows = results.filter(({ rowStatus }) => rowStatus !== MutationStatus.Success);
  const successRowCount = results.length - errorRows.length;

  function reset() {
    setDeregistrationReason(StaffDeregistrationReason.Administrative);
    setDeregistrationReasonError('');
    setFile(undefined);
    setFileError('');
    setResults([]);
    setCurrentMissingColumns(null);
    setIgnorePursuitsNotFound(false);
    mutation.reset();
  }

  function resetAndClose() {
    reset();
    close();
  }

  function onChange(event: ChangeEvent<HTMLInputElement>) {
    setFileError('');
    const { files } = event.target;

    if (!files) return;

    const file = files[0];

    if (!ACCEPTED_FILE_TYPES.includes(file.type)) {
      setFileError(t('modal.errors.unsupported-type'));
      if (fileInputRef.current) {
        // reset the value of the <input type="file">
        fileInputRef.current.value = '';
      }
      return;
    }

    setFile(file);
  }

  function onSelectReason(event: ChangeEvent<HTMLSelectElement>) {
    setDeregistrationReasonError('');
    setDeregistrationReason(event.target.value as StaffDeregistrationReason);
  }

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (!file) {
      setFileError(t('modal.errors.file-missing'));
      return;
    }

    if (!deregistrationReason) {
      setDeregistrationReasonError(t('modal.errors.reason-missing'));
      return;
    }

    const { data } = await mutate({
      variables: { file, academicYear, deregistrationReason, ignorePursuitsNotFound },
    });

    if (!data?.uploadBulkCourseDeregistrations) {
      setFileError(readableMutationStatus('unknown'));
      return;
    }

    const { mutationStatus, result, details, missingColumns } = data.uploadBulkCourseDeregistrations;

    if (mutationStatus === MutationStatus.Success) {
      setResults(result);
      return;
    }

    if (mutationStatus === MutationStatus.UploadMissingColumns) {
      setCurrentMissingColumns(missingColumns);
      setFileError(readableMutationStatus(mutationStatus, { details: missingColumns ?? undefined }));
      return;
    }

    setFileError(readableMutationStatus(mutationStatus, { details: details ?? undefined }));
  }

  return (
    <>
      <button onClick={open}>{t('trigger')}</button>

      <ModalDialog
        isDismissable={!mutation.loading}
        isOpen={isOpen}
        noValidate={true}
        onClose={resetAndClose}
        onSubmit={handleSubmit}
        title={t('modal.title')}
        buttons={
          <>
            {!mutation.called && (
              <ButtonGroup reversed>
                <Button variant="primary" type="submit" disabled={mutation.loading}>
                  {t('modal.buttons.import')}
                </Button>

                <Button onClick={resetAndClose} variant="secondary">
                  {t('modal.buttons.cancel')}
                </Button>
              </ButtonGroup>
            )}

            {mutation.called && !mutation.loading && (
              <ButtonGroup reversed>
                <Button onClick={resetAndClose} variant="primary">
                  {t('modal.buttons.close')}
                </Button>
                <Button onClick={reset} variant="secondary">
                  {t('modal.buttons.reset-and-retry')}
                </Button>
              </ButtonGroup>
            )}
          </>
        }
        wide
      >
        {!mutation.called && (
          <>
            <p>{t('modal.description')}</p>

            <FormField outerSpace>
              <Label htmlFor={INPUT_FILE_ID}>{t('modal.choose-file')}</Label>
              <Input
                id={INPUT_FILE_ID}
                type="file"
                accept={ACCEPTED_FILE_TYPES.join(',')}
                onChange={onChange}
                title={t('modal.file-type')}
                aria-describedby={fileError ? INPUT_FILE_ERROR_ID : undefined}
                ref={fileInputRef}
              />
            </FormField>

            {fileError && <FeedbackBox id={INPUT_FILE_ERROR_ID} level="error" feedback={fileError} />}

            <FormField outerSpace>
              <DeregistrationReason
                onChange={onSelectReason}
                error={deregistrationReasonError}
                defaultReason={deregistrationReason}
              />
            </FormField>

            <FormField outerSpace inline>
              <Checkbox
                checked={ignorePursuitsNotFound}
                id={IGNORE_DEREGISTERED_PURSUITS_ID}
                onChange={() => {
                  setIgnorePursuitsNotFound(!ignorePursuitsNotFound);
                }}
              />
              <Label htmlFor={IGNORE_DEREGISTERED_PURSUITS_ID}>{t('modal.ignore-already-deregistered')}</Label>
            </FormField>
          </>
        )}

        {mutation.called && mutation.loading && <Spinner ariaValueText={t('results-modal.loading')} />}

        {mutation.called &&
          !mutation.loading &&
          (results.length > 0 ? (
            <UploadResults>
              {successRowCount > 0 && (
                <FeedbackBox level="success" feedback={t('results-modal.success', { count: successRowCount })} />
              )}
              {errorRows.length > 0 && (
                <>
                  <FeedbackBox level="error" feedback={t('results-modal.failure', { count: errorRows.length })} />
                  <UploadRowErrors errorRows={errorRows} />
                </>
              )}
            </UploadResults>
          ) : (
            <>
              {fileError && <FeedbackBox level="error" feedback={fileError} />}
              {currentMissingColumns ? (
                <ul>
                  {currentMissingColumns.map((column, index) => (
                    <li key={index}>{readableMissingColumn(column)}</li>
                  ))}
                </ul>
              ) : (
                <p>{t('results-modal.nothing-imported')}</p>
              )}
            </>
          ))}
      </ModalDialog>
    </>
  );
}
