import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { IconButton, Icon } from '@uva-glass/component-library';

import type {
  GetRequirementByIdQuery,
  RuleInRequirementInput,
  UpdateRequirementRulesMutation,
  UpdateRequirementRulesMutationVariables,
} from 'types/__generated__';

import { createRulesInRequirement } from 'util/createRulesInRequirement';
import { getReorderedList } from 'util/getReorderedList';
import { UPDATE_REQUIREMENT_RULES } from 'graphql/mutations/updateRequirementRules';

interface Props {
  rowIndex: number;
  requirement: NonNullable<GetRequirementByIdQuery['requirement']>;
}

const orderIncrement = 1;

export function RuleInRequirementOrder({ rowIndex, requirement }: Props) {
  const { t } = useTranslation('entry-requirements', { keyPrefix: 'entry-requirement' });
  const rulesInRequirement = createRulesInRequirement(requirement);
  const [mutate, mutation] = useMutation<UpdateRequirementRulesMutation, UpdateRequirementRulesMutationVariables>(
    UPDATE_REQUIREMENT_RULES
  );

  function setNewRulesInRequirementOrder(newIndex: number) {
    const rules = getReorderedList<RuleInRequirementInput>(rowIndex, newIndex, ...rulesInRequirement);

    mutate({
      variables: {
        requirementId: requirement.id,
        rules,
      },
    });
  }

  return (
    <>
      <IconButton
        aria-label={t('order-up')}
        disabled={rowIndex === 0 || mutation.loading}
        onClick={() => setNewRulesInRequirementOrder(rowIndex - orderIncrement)}
      >
        <Icon name="CheveronUp" />
      </IconButton>

      <IconButton
        aria-label={t('order-down')}
        disabled={rowIndex === rulesInRequirement.length - orderIncrement || mutation.loading}
        onClick={() => setNewRulesInRequirementOrder(rowIndex + orderIncrement)}
      >
        <Icon name="CheveronDown" />
      </IconButton>
    </>
  );
}
