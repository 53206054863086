export function read<T>(key: string) {
  const item = window.localStorage.getItem(key);

  return item ? (JSON.parse(item) as T) : null;
}

export function write(key: string, value: unknown) {
  if (value !== undefined && value !== null) {
    window.localStorage.setItem(key, JSON.stringify(value));
  }
}

export function remove(key: string) {
  window.localStorage.removeItem(key);
}
