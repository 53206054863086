import { gql } from '@apollo/client';

export const GET_COURSE_OFFERING_PENDING_GROUP_SWAPS_BY_DEPARTMENT_ID = gql`
  query getCourseOfferingPendingGroupSwapsByDepartmentId($departmentId: String!, $academicYear: Int!) {
    courseOfferingPendingGroupSwaps(departmentId: $departmentId, academicYear: $academicYear) {
      info {
        id
        catalogNumber
        title {
          NL
          EN
        }
      }
      courseOfferingId
      requestCount
      requestPossible
      requestExpirationDate
      lastRequestDate
    }
  }
`;
