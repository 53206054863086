import { useLazyQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import type { GetGroupsExportLinkQuery, GetGroupsExportLinkQueryVariables } from 'types/__generated__';

import { downloadFile } from 'util/downloadFile';
import { GET_GROUPS_EXPORT_LINK } from 'graphql/queries/getGroupsExportLink';

export function ExportAllGroups({
  courseOfferingId = null,
  departmentId = null,
  academicYear = null,
}: GetGroupsExportLinkQueryVariables) {
  const { t } = useTranslation('course-offerings', { keyPrefix: 'course-offerings' });

  const [lazyQuery] = useLazyQuery<GetGroupsExportLinkQuery, GetGroupsExportLinkQueryVariables>(
    GET_GROUPS_EXPORT_LINK,
    {
      variables: { courseOfferingId, departmentId, academicYear },
      fetchPolicy: 'network-only',
    }
  );

  async function onClick() {
    const { data } = await lazyQuery();

    if (data) {
      downloadFile(data.studentGroupsExport.url);
    }
  }

  return (
    <button type="button" onClick={onClick}>
      {t('export-action-list.options.all-groups')}
    </button>
  );
}
