import { useMutation } from '@apollo/client';

import type { UpdateTopicSetNameMutation, UpdateTopicSetNameMutationVariables } from 'types/__generated__';

import { MutationStatus } from 'types/__generated__';
import { UPDATE_TOPIC_SET_NAME } from 'graphql/mutations/updateTopicSetName';
import { GET_TOPIC_SETS_BY_DEPARTMENT_ID } from 'graphql/queries/getTopicSetsByDepartmentId';

export const useUpdateTopicSetName = () =>
  useMutation<UpdateTopicSetNameMutation, UpdateTopicSetNameMutationVariables>(UPDATE_TOPIC_SET_NAME, {
    // wait for the refetch to have completed, before returning the result of the mutation; this way
    // the interface is already updated with the correct data before any redirect takes place
    awaitRefetchQueries: true,
    refetchQueries({ data }) {
      if (data?.updateTopicSetName.mutationStatus === MutationStatus.TopicSetNotFound) {
        return [GET_TOPIC_SETS_BY_DEPARTMENT_ID];
      }

      return [];
    },
  });
