import { gql } from '@apollo/client';

export const COURSE_INFO_FRAGMENT = gql`
  fragment courseInfo on MarblesCourseInfo {
    id
    catalogNumber
    title {
      NL
      EN
    }
    ec
  }
`;
