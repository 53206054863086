import { createColumnHelper } from '@tanstack/react-table';
import { Link, generatePath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import type { GetCourseOfferingGroupSwapsByIdQuery } from 'types/__generated__';

import styles from './CourseOfferingGroupSwap.module.css';

import { DataTable } from 'App/shared/DataTable/DataTable';
import { DateTime } from 'App/shared/DateTime/DateTime';
import { DualTextCell } from 'components/DualTextCell/DualTextCell';
import { STUDENTS } from 'routes';
import { TABLE_STORAGE_KEYS } from 'util/storagekeys';
import { useFormattedDate } from 'hooks/useFormattedDate';

type RowData = GetCourseOfferingGroupSwapsByIdQuery['courseOfferingGroupSwaps'][number];

interface Props {
  groupSwaps: GetCourseOfferingGroupSwapsByIdQuery['courseOfferingGroupSwaps'];
}

const columnHelper = createColumnHelper<RowData>();

const initialState = {
  sorting: [{ id: 'expired-creation-date', desc: false }],
};

export function ExpiredGroupSwapTable({ groupSwaps }: Props) {
  const { t } = useTranslation('course-offering', { keyPrefix: 'course-offering-group-swap.sections.expired' });
  const formattedDate = useFormattedDate();

  const columns = [
    columnHelper.accessor('creationDate', {
      id: 'expired-creation-date',
      header: t('headers.date-request'),
      cell: ({ cell }) => (
        <>
          {cell.getValue() ? (
            <DateTime isoDate={cell.getValue()} format={{ dateStyle: 'medium', timeStyle: 'short' }} />
          ) : (
            t('values.not-applicable')
          )}
        </>
      ),
      filterFn: (row, _, filterValue: string) =>
        formattedDate(row.original.creationDate, { dateStyle: 'medium', timeStyle: 'short' })
          .toLocaleLowerCase()
          .includes(filterValue.toLocaleLowerCase()),
    }),

    columnHelper.accessor(({ student }) => student.studentNumber, {
      id: 'expired-student-number',
      header: t('headers.student-number'),
    }),

    columnHelper.accessor(({ student }) => student.name, {
      id: 'expired-student-name',
      header: t('headers.student-name'),
      cell: ({ cell, row }) => (
        <Link to={generatePath(`${STUDENTS}/:studentId`, { studentId: row.original.student.id })}>
          {cell.getValue()}
        </Link>
      ),
      meta: { tableCellProps: { noWrap: true } },
    }),

    columnHelper.accessor(({ studentGroup }) => studentGroup, {
      id: 'expired-current-group',
      header: t('headers.current-group'),
      cell: ({ row }) => {
        return row.original.studentGroup ? (
          <DualTextCell upperText={row.original.studentGroup.name} lowerText={row.original.studentGroup.code} />
        ) : (
          t('values.deleted-group')
        );
      },
      filterFn: (row, _, filterValue: string) => {
        const isDeletedValue = t('values.deleted-group').toLocaleLowerCase().includes(filterValue.toLocaleLowerCase());

        return Boolean(
          (row.original.studentGroup &&
            (row.original.studentGroup.name.toLocaleLowerCase().includes(filterValue.toLocaleLowerCase()) ||
              row.original.studentGroup.code.toLocaleLowerCase().includes(filterValue.toLocaleLowerCase()))) ||
            (isDeletedValue && (row.original.studentGroup === null || undefined))
        );
      },
    }),

    columnHelper.accessor(({ expirationDate }) => expirationDate, {
      id: 'expired-expired-on',
      header: t('headers.expired-on'),
      cell: ({ cell }) => (
        <>
          {cell.getValue() ? (
            <DateTime isoDate={cell.getValue()} format={{ dateStyle: 'medium', timeStyle: 'short' }} />
          ) : (
            t('values.not-applicable')
          )}
        </>
      ),
      filterFn: (row, _, filterValue: string) =>
        formattedDate(row.original.creationDate, { dateStyle: 'medium', timeStyle: 'short' })
          .toLocaleLowerCase()
          .includes(filterValue.toLocaleLowerCase()),
    }),
  ];

  return (
    <section className={styles['course-offering-group-swap__table-section']}>
      <header className={styles['course-offering-group-swap__table-header']}>
        <h2 className={styles['course-offering-group-swap__table-heading']}>{t('heading')}</h2>
      </header>
      {groupSwaps.length ? (
        <DataTable
          columns={columns}
          data={groupSwaps}
          initialState={initialState}
          tableId={TABLE_STORAGE_KEYS.EXPIRED_COURSE_OFFERING_GROUP_SWAP_TABLE}
        />
      ) : (
        <p className={styles['course-offering-group-swap__table-empty']}>{t('empty')}</p>
      )}
    </section>
  );
}
