import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { SectionNotification, Icon, Card, RadioButton, Fieldset } from '@uva-glass/component-library';
import { useEffect, useState, type ChangeEvent } from 'react';

import type { GetStatusByCourseOfferingIdQuery, GetStatusByCourseOfferingIdQueryVariables } from 'types/__generated__';

import { useSetCourseOfferingStatus } from './hooks/useSetCourseOfferingStatus';

import { GET_STATUS_BY_COURSE_OFFERING_ID } from 'graphql/queries/getStatusByCourseOfferingId';
import { useFormattedDate } from 'hooks/useFormattedDate';
import { useGetCourseOffering } from 'hooks/useGetCourseOffering';
import { DirectRegistrationType, MarblesCourseOfferingStatus, MutationStatus, UserAction } from 'types/__generated__';
import { desiredCourseOfferingStatusOrder } from 'util/sortCourseOfferingStatuses';

const COURSE_OFFERING_STATUS_ID_PREFIX = 'course-offering-status';

export function Status() {
  const { t } = useTranslation('course-offering', { keyPrefix: 'course-offering-status-setting' });
  const formattedDate = useFormattedDate();
  const { marblesCourseOffering } = useGetCourseOffering();
  const [isSpacePressed, setIsSpacePressed] = useState<boolean>(false);
  const [isMouseClick, setIsMouseClick] = useState<boolean>(false);

  const { id: courseOfferingId, department, academicYear } = marblesCourseOffering;
  const userCanEditCourseSettings = department.allowedUserActions.includes(UserAction.EditCourseSettings);

  const query = useQuery<GetStatusByCourseOfferingIdQuery, GetStatusByCourseOfferingIdQueryVariables>(
    GET_STATUS_BY_COURSE_OFFERING_ID,
    { variables: { courseOfferingId } }
  );
  const [mutate, mutation] = useSetCourseOfferingStatus();

  const setLastKey = (event: KeyboardEvent) => {
    setIsMouseClick(false);
    setIsSpacePressed(event.key === ' ');
  };

  useEffect(() => {
    document.addEventListener('keydown', setLastKey, false);
    return () => {
      document.removeEventListener('keydown', setLastKey, false);
    };
  }, []);

  if (query.loading || !query.data?.marblesCourseOffering) return null;

  const { status, directRegistrationSettings } = query.data.marblesCourseOffering;
  const { registrationStartDate, registrationEndDate } = directRegistrationSettings;

  const mutateStatus = (status: MarblesCourseOfferingStatus) => {
    mutate({
      variables: { courseOfferingId, status },
      optimisticResponse: {
        setCourseOfferingStatus: {
          mutationStatus: MutationStatus.Success,
          status,
          courseOfferingId,
        },
      },
    });
  };

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const status = event.target.value as MarblesCourseOfferingStatus;

    if (isSpacePressed || isMouseClick) mutateStatus(status);
  };

  return (
    <Card highContrast>
      {!userCanEditCourseSettings && (
        <SectionNotification>
          <Icon name="ExclamationCircle" size={16} /> {t('not-authorised')}
        </SectionNotification>
      )}
      <Fieldset legend={t('legend')}>
        {desiredCourseOfferingStatusOrder.map((courseOfferingStatus) => (
          <RadioButton
            key={courseOfferingStatus}
            id={`${COURSE_OFFERING_STATUS_ID_PREFIX}-${courseOfferingStatus}`}
            label={t(`labels.${courseOfferingStatus}`)}
            description={t(`label-descriptions.${courseOfferingStatus}`, {
              registrationStartDate: formattedDate(registrationStartDate),
              registrationEndDate: formattedDate(registrationEndDate),
            })}
            value={courseOfferingStatus}
            onChange={onChange}
            onClick={() => setIsMouseClick(true)}
            checked={status === courseOfferingStatus}
            disabled={
              !userCanEditCourseSettings ||
              mutation.loading ||
              academicYear.isArchived ||
              // disable the DirectRegistrationOnly option when the current setting is TGOW
              (courseOfferingStatus === MarblesCourseOfferingStatus.DirectRegistrationOnly &&
                directRegistrationSettings.directRegistrationType === DirectRegistrationType.Tgow)
            }
            gap="large"
          />
        ))}
      </Fieldset>
    </Card>
  );
}
