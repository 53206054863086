import { useMutation } from '@apollo/client';

import type {
  AddCourseOfferingToConstrainedChoiceMutation,
  AddCourseOfferingToConstrainedChoiceMutationVariables,
  GetConstrainedChoiceClusterByIdQuery,
  GetConstrainedChoiceClusterByIdQueryVariables,
} from 'types/__generated__';

import { MutationStatus } from 'types/__generated__';
import { ADD_COURSE_OFFERING_TO_CONSTRAINED_CHOICE } from 'graphql/mutations/addCourseOfferingToConstrainedChoice';
import { GET_CONSTRAINED_CHOICE_CLUSTER_BY_ID } from 'graphql/queries/getConstrainedChoiceClusterById';
import { GET_CONSTRAINED_CHOICE_CLUSTERS_BY_DEPARTMENT_ID } from 'graphql/queries/getConstrainedChoiceClustersByDepartmentId';

export const useAddCourseOfferingToConstrainedChoice = () =>
  useMutation<AddCourseOfferingToConstrainedChoiceMutation, AddCourseOfferingToConstrainedChoiceMutationVariables>(
    ADD_COURSE_OFFERING_TO_CONSTRAINED_CHOICE,
    {
      update(cache, { data }) {
        if (data?.setCourseOfferingConstrainedChoiceCluster.mutationStatus !== MutationStatus.Success) return;

        const { clusterId, courseOffering } = data.setCourseOfferingConstrainedChoiceCluster;

        if (!clusterId) {
          throw new Error('No "clusterId" in payload');
        }

        if (!courseOffering) {
          throw new Error('No "courseOffering" in payload');
        }

        cache.updateQuery<GetConstrainedChoiceClusterByIdQuery, GetConstrainedChoiceClusterByIdQueryVariables>(
          {
            query: GET_CONSTRAINED_CHOICE_CLUSTER_BY_ID,
            variables: { constrainedChoiceClusterId: clusterId },
          },
          (cached) =>
            cached?.constrainedChoiceCluster
              ? {
                  constrainedChoiceCluster: {
                    ...cached.constrainedChoiceCluster,
                    offerings: [...cached.constrainedChoiceCluster.offerings, courseOffering],
                  },
                }
              : undefined
        );
      },
      // we want to refetch the whole list of constrained choice clusters for the current department
      // if the constrained choice cluster is not found on mutation trigger
      refetchQueries: ({ data }) => {
        if (
          data &&
          data.setCourseOfferingConstrainedChoiceCluster.mutationStatus ===
            MutationStatus.ConstrainedChoiceClusterNotFound
        ) {
          return [GET_CONSTRAINED_CHOICE_CLUSTERS_BY_DEPARTMENT_ID];
        }
        return [];
      },
    }
  );
