import { useMutation } from '@apollo/client';

import type { SetGroupSwapEnabledMutation, SetGroupSwapEnabledMutationVariables } from 'types/__generated__';
import type { StoreObject } from '@apollo/client';

import { SET_GROUP_SWAP_ENABLED } from 'graphql/mutations/setGroupSwapEnabled';
import { GET_COURSE_OFFERING_BY_ID } from 'graphql/queries/getCourseOfferingById';
import { MutationStatus } from 'types/__generated__';

export const useSetGroupSwapEnabled = () =>
  useMutation<SetGroupSwapEnabledMutation, SetGroupSwapEnabledMutationVariables>(SET_GROUP_SWAP_ENABLED, {
    update(cache, { data }) {
      if (data?.setGroupSwapEnabled.mutationStatus !== MutationStatus.Success) return;

      const { courseOfferingId, groupSwapEnabled } = data.setGroupSwapEnabled;
      const CourseOffering: StoreObject = { __typename: 'MarblesCourseOffering', id: courseOfferingId };

      cache.modify({
        id: cache.identify(CourseOffering),
        fields: {
          groupSwapEnabled() {
            return groupSwapEnabled;
          },
        },
      });
    },
    refetchQueries({ data }) {
      const mutationStatus = data?.setGroupSwapEnabled.mutationStatus;

      if (mutationStatus === MutationStatus.Success) return [];

      return [GET_COURSE_OFFERING_BY_ID];
    },
  });
