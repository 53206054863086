import { gql } from '@apollo/client';

export const GET_STATUS_BY_COURSE_OFFERING_ID = gql`
  query getStatusByCourseOfferingId($courseOfferingId: String!) {
    marblesCourseOffering(courseOfferingId: $courseOfferingId) {
      status
      directRegistrationSettings {
        registrationStartDate
        registrationEndDate
        directRegistrationType
      }
    }
  }
`;
