import { gql } from '@apollo/client';

import { PERIOD_FRAGMENT } from 'graphql/fragments/periodFragment';

export const GET_PURSUITS_BY_STUDENT_ID = gql`
  query getPursuitsByStudentId($studentId: String!) {
    studentPursuits(studentId: $studentId) {
      id
      isGroupSwapPossible
      registrationDate
      coursePursuitStatus
      courseInfo {
        id
        catalogNumber
        title {
          EN
          NL
        }
        ec
      }
      selectedOffering {
        id
        academicYear {
          id
          name
          isArchived
        }
        studentGroups {
          id
          name
        }
        department {
          allowedUserActions
        }
        periods {
          ...period
        }
        constrainedChoiceClusterId
      }
      studentGroup {
        id
        code
        name
      }
      clusterPreference {
        index
        pursuitCount
        registrationLimit
      }
    }
    studentGroupSwaps(studentId: $studentId) {
      id
      coursePursuitId
      status
    }
  }
  ${PERIOD_FRAGMENT}
`;
