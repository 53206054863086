import { useQuery } from '@apollo/client';
import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { EmptyStateDataDisplay } from '@uva-glass/component-library';

import type { VisibilityState } from '@tanstack/react-table';
import type {
  GetCourseOfferingRejectionsByIdQuery,
  GetCourseOfferingRejectionsByIdQueryVariables,
} from 'types/__generated__';

import { DataTable } from 'App/shared/DataTable/DataTable';
import { DateTime } from 'App/shared/DateTime/DateTime';
import { ProgrammeList } from 'App/shared/ProgrammeList/ProgrammeList';
import { GET_COURSE_OFFERING_REJECTIONS_BY_ID } from 'graphql/queries/getCourseOfferingRejectionsById';
import { useCurrentLanguage } from 'hooks/useCurrentLanguage';
import { useFormattedDate } from 'hooks/useFormattedDate';
import { useGetCourseOffering } from 'hooks/useGetCourseOffering';
import { TABLE_STORAGE_KEYS } from 'util/storagekeys';

type RowData = GetCourseOfferingRejectionsByIdQuery['courseOfferingRejections'][number];

const columnHelper = createColumnHelper<RowData>();

export function CourseOfferingRejections() {
  const { t } = useTranslation('course-offering', { keyPrefix: 'course-offering-rejections' });
  const currentLanguage = useCurrentLanguage();
  const formattedDate = useFormattedDate();
  const { marblesCourseOffering } = useGetCourseOffering();
  const { id: courseOfferingId, topicSet } = marblesCourseOffering;
  const academicYear = marblesCourseOffering.academicYear.id;
  const query = useQuery<GetCourseOfferingRejectionsByIdQuery, GetCourseOfferingRejectionsByIdQueryVariables>(
    GET_COURSE_OFFERING_REJECTIONS_BY_ID,
    { variables: { courseOfferingId, academicYear }, fetchPolicy: 'network-only' }
  );

  const initialState = useMemo(() => {
    const columnVisibility: VisibilityState = {};

    if (!topicSet) {
      columnVisibility.topic = false;
    }

    return { columnVisibility };
  }, [topicSet]);

  const columns = [
    columnHelper.accessor(({ student }) => student.studentNumber, {
      id: 'student-number',
      header: t('headers.student-number'),
    }),

    columnHelper.accessor(({ student }) => student.name, {
      id: 'student-name',
      header: t('headers.student-name'),
    }),

    columnHelper.accessor(({ student }) => student.enrolments, {
      id: 'programme',
      header: t('headers.programme'),
      cell: ({ row }) => <ProgrammeList asInlineList includeSubplans enrolments={row.original.student.enrolments} />,
    }),

    columnHelper.accessor(({ topic }) => topicSet?.topics.find(({ id }) => id === topic?.id)?.title[currentLanguage], {
      id: 'topic',
      header: topicSet?.title[currentLanguage],
    }),

    columnHelper.accessor(({ registrationRound }) => registrationRound?.description[currentLanguage], {
      id: 'round',
      header: t('headers.round'),
    }),

    columnHelper.accessor('rejectionDate', {
      id: 'when',
      header: t('headers.when'),
      cell: ({ cell }) => <DateTime isoDate={cell.getValue()} format={{ dateStyle: 'medium', timeStyle: 'short' }} />,
      sortingFn: 'datetime',
      filterFn: (row, _, filterValue: string) =>
        formattedDate(row.original.rejectionDate, { dateStyle: 'medium', timeStyle: 'short' })
          .toLocaleLowerCase()
          .includes(filterValue.toLocaleLowerCase()),
    }),

    columnHelper.accessor('username', {
      header: t('headers.who'),
    }),

    columnHelper.accessor('rejectionReason', {
      id: 'rejection-reason',
      header: t('headers.why'),
      cell: ({ cell }) => t(`values.${cell.getValue()}`),
    }),
  ];

  if (query.loading || query.error || !query.data?.courseOfferingRejections) return null;

  const { courseOfferingRejections } = query.data;

  if (!courseOfferingRejections.length) {
    return <EmptyStateDataDisplay label={t('empty-state.label')} paragraph={t('empty-state.paragraph')} />;
  }

  return (
    <DataTable
      columns={columns}
      data={courseOfferingRejections}
      initialState={initialState}
      tableId={TABLE_STORAGE_KEYS.COURSE_OFFERING_REJECTIONS}
    />
  );
}
