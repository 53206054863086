import { makeVar } from '@apollo/client';

import type { GetFiltersQuery } from 'types/__generated__';

import { read } from 'util/localStorage';
import { STORAGE_KEYS } from 'util/storagekeys';

type CourseOfferingsFilter = GetFiltersQuery['courseOfferingsFilter'];

export const courseOfferingsFilterVar = makeVar<CourseOfferingsFilter>({
  __typename: 'CourseOfferingsFilter',
  departmentId: read(STORAGE_KEYS.DEPARTMENT_ID),
  academicYear: read(STORAGE_KEYS.ACADEMIC_YEAR),
});
