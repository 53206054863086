import type { GetRequirementByIdQuery, RuleInRequirementInput } from 'types/__generated__';

export const createRulesInRequirement = (
  requirement: NonNullable<GetRequirementByIdQuery['requirement']>
): RuleInRequirementInput[] =>
  requirement?.rulesInRequirement.map(({ priority, priorityLimit, includeInRetry, rule }) => ({
    ruleId: rule.id,
    priority,
    priorityLimit,
    includeInRetry,
  }));
