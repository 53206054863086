import { useTranslation } from 'react-i18next';

import type {
  UploadBulkCourseRegistrationsMutation,
  UploadCoursePendingStudentsMutation,
  UploadCourseDeregistrationsMutation,
  UploadCourseRegistrationsMutation,
  UploadCreditLimitExceptionsMutation,
} from 'types/__generated__';

import styles from './UploadRowErrors.module.css';

import { useGetReadableMutationStatus } from 'hooks/useGetReadableMutationStatus';

type ErrorRows =
  | UploadCourseRegistrationsMutation['uploadCourseRegistrations']['result']
  | UploadCoursePendingStudentsMutation['uploadCoursePendingStudents']['result']
  | UploadCourseDeregistrationsMutation['uploadCourseDeregistrations']['result']
  | UploadBulkCourseRegistrationsMutation['uploadBulkCourseRegistrations']['result']
  | UploadCreditLimitExceptionsMutation['uploadCreditLimitExceptions']['result'];

interface Props {
  errorRows: ErrorRows;
}

export function UploadRowErrors({ errorRows }: Props) {
  const { t } = useTranslation('common', { keyPrefix: 'upload-row-errors' });
  const readableMutationStatus = useGetReadableMutationStatus();

  if (errorRows.length === 0) return null;

  return (
    <table className={styles['upload-row-errors']}>
      <thead>
        <tr>
          <th className={styles['upload-row-errors__th']}>{t('row')}</th>
          <th className={styles['upload-row-errors__th']}>{t('error-message')}</th>
        </tr>
      </thead>
      <tbody>
        {errorRows.map((errorRow) => (
          <tr key={errorRow.line}>
            <td className={styles['upload-row-errors__td']}>{errorRow.line}</td>

            <td className={styles['upload-row-errors__td']}>
              {readableMutationStatus(errorRow.rowStatus, {
                student: errorRow.studentNumber,
                group:
                  errorRow.__typename === 'UploadCourseRegistrationsPayloadResultRow' ? errorRow.groupCode : undefined,
              })}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
