import { gql } from '@apollo/client';

export const ADD_TOPIC = gql`
  mutation addTopic($topicSetId: String!, $titleNL: String!, $titleEN: String!, $requirementNodeId: String) {
    addTopic(
      input: { topicSetId: $topicSetId, titleNL: $titleNL, titleEN: $titleEN, requirementNodeId: $requirementNodeId }
    ) {
      mutationStatus
      topic {
        id
        topicSetId
        title {
          NL
          EN
        }
        requirement {
          id
          name
        }
      }
    }
  }
`;
