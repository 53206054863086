import { useMutation } from '@apollo/client';

import type {
  SetRequirementNodeByCourseOfferingIdMutation,
  SetRequirementNodeByCourseOfferingIdMutationVariables,
} from 'types/__generated__';
import type { StoreObject } from '@apollo/client';

import { SET_REQUIREMENT_NODE_BY_COURSE_OFFERING_ID } from 'graphql/mutations/setRequirementNodeByCourseOfferingId';
import { MutationStatus } from 'types/__generated__';
import { GET_COURSE_OFFERING_BY_ID } from 'graphql/queries/getCourseOfferingById';
import { GET_COURSE_OFFERING_REQUIREMENT_CAPACITY } from 'graphql/queries/getCourseOfferingRequirementCapacity';

export const useSetRequirementNodeByCourseOfferingId = () =>
  useMutation<SetRequirementNodeByCourseOfferingIdMutation, SetRequirementNodeByCourseOfferingIdMutationVariables>(
    SET_REQUIREMENT_NODE_BY_COURSE_OFFERING_ID,
    {
      update(cache, { data }) {
        if (data?.setCourseOfferingRequirementNode.mutationStatus !== MutationStatus.Success) return;

        const { courseOfferingId, requirementNodeId, courseOfferingRequirementCapacities } =
          data.setCourseOfferingRequirementNode;
        const courseOffering: StoreObject = { __typename: 'MarblesCourseOffering', id: courseOfferingId };

        if (courseOfferingRequirementCapacities) {
          cache.writeQuery({
            query: GET_COURSE_OFFERING_REQUIREMENT_CAPACITY,
            variables: { courseOfferingId },
            data: {
              courseOfferingRequirementCapacity: [...courseOfferingRequirementCapacities],
            },
          });
        }

        cache.modify({
          id: cache.identify(courseOffering),
          fields: {
            requirementNodeId() {
              return requirementNodeId ?? null;
            },
          },
        });
      },

      refetchQueries({ data }) {
        const mutationStatus = data?.setCourseOfferingRequirementNode.mutationStatus;

        if (mutationStatus === MutationStatus.Success) return [];

        return [GET_COURSE_OFFERING_BY_ID];
      },
    }
  );
