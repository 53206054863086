import { useMemo } from 'react';
import { Icon } from '@uva-glass/component-library';

import type { PropsWithChildren } from 'react';
import type { IconProps } from '@uva-glass/component-library';

import styles from './TableSortHeader.module.css';

interface Props {
  descending?: boolean;
  sorted?: boolean;
  canSort?: boolean;
}

export function TableSortHeader({
  sorted = false,
  descending = false,
  canSort = false,
  children,
}: PropsWithChildren<Props>) {
  const iconName = useMemo((): IconProps['name'] => {
    if (sorted && descending) {
      return 'ArrowSmallDownMini';
    }
    if (sorted) {
      return 'ArrowSmallUpMini';
    }
    return 'CheveronUpDown';
  }, [sorted, descending]);

  return (
    <div className={styles['sort-header']}>
      {children}
      {canSort && <Icon name={iconName} className={styles['sort-header__icon']} />}
    </div>
  );
}
