import { useMutation } from '@apollo/client';

import type {
  UpdateConstrainedChoiceClusterMutation,
  UpdateConstrainedChoiceClusterMutationVariables,
} from 'types/__generated__';

import { UPDATE_CONSTRAINED_CHOICE_CLUSTER } from 'graphql/mutations/updateConstrainedChoiceCluster';
import { MutationStatus } from 'types/__generated__';
import { GET_CONSTRAINED_CHOICE_CLUSTERS_BY_DEPARTMENT_ID } from 'graphql/queries/getConstrainedChoiceClustersByDepartmentId';

export const useUpdateConstrainedChoice = () =>
  useMutation<UpdateConstrainedChoiceClusterMutation, UpdateConstrainedChoiceClusterMutationVariables>(
    UPDATE_CONSTRAINED_CHOICE_CLUSTER,
    {
      refetchQueries({ data }) {
        if (data?.updateConstrainedChoiceCluster.mutationStatus === MutationStatus.ConstrainedChoiceClusterNotFound) {
          return [GET_CONSTRAINED_CHOICE_CLUSTERS_BY_DEPARTMENT_ID];
        }

        return [];
      },
    }
  );
