import { gql } from '@apollo/client';

export const CONSTRAINED_CHOICE_CLUSTER_FRAGMENT = gql`
  fragment constrainedChoiceCluster on ConstrainedChoiceCluster {
    id
    departmentId
    title {
      NL
      EN
    }
    registrationLimit
  }
`;
