import { useMutation } from '@apollo/client';
import { useOverlayTriggerState } from '@react-stately/overlays';
import { useTranslation } from 'react-i18next';
import { IconButton, Icon, ButtonGroup } from '@uva-glass/component-library';

import type {
  AddRequiredCoursesMutationInput,
  RequiredCourses,
  UpdateRequiredCoursesMutation,
  UpdateRequiredCoursesMutationVariables,
} from 'types/__generated__';

import { RequiredCoursesForm } from 'App/RequirementRules/Rule/RequiredCoursesForm/RequiredCoursesForm';
import { UPDATE_REQUIRED_COURSES } from 'graphql/mutations/updateRequiredCourses';

interface Props {
  requirement: RequiredCourses;
  ruleId: string;
}

export function EditRequiredCoursesNode({ requirement, ruleId }: Props) {
  const { t } = useTranslation('requirement-rules', { keyPrefix: 'rule.optional-requirements' });
  const { open, close, isOpen } = useOverlayTriggerState({});

  const [mutate, mutation] = useMutation<UpdateRequiredCoursesMutation, UpdateRequiredCoursesMutationVariables>(
    UPDATE_REQUIRED_COURSES
  );

  const onMutate = (variables: AddRequiredCoursesMutationInput, cleanUp: () => void) => {
    mutate({
      variables: {
        nodeId: requirement.id,
        ...variables,
      },
      onCompleted: cleanUp,
    });
  };

  return (
    <>
      <ButtonGroup>
        <IconButton aria-label={t('buttons.edit')} onClick={open}>
          <Icon name="PencilSquare" />
        </IconButton>
      </ButtonGroup>

      {isOpen && (
        <RequiredCoursesForm
          close={close}
          isLoading={mutation.loading}
          isOpen={isOpen}
          modalTitle={t('edit-required-courses')}
          mutate={onMutate}
          requirementNode={requirement}
          ruleId={ruleId}
        />
      )}
    </>
  );
}
