import { useId, useState } from 'react';
import { InputField } from '@uva-glass/component-library';

import type { MutationTuple } from '@apollo/client';
import type { ChangeEvent } from 'react';
import type {
  GetRequirementByIdQuery,
  RuleInRequirementInput,
  UpdateRequirementRulesMutation,
  UpdateRequirementRulesMutationVariables,
} from 'types/__generated__';

import { MutationStatus } from 'types/__generated__';
import { createRulesInRequirement } from 'util/createRulesInRequirement';

type TValue = number | null | undefined;

interface Props {
  cellValue: TValue;
  ruleId: string;
  requirement: NonNullable<GetRequirementByIdQuery['requirement']>;
  fieldName: 'priority' | 'priorityLimit';
  updateRequirementRulesMutation: MutationTuple<
    UpdateRequirementRulesMutation,
    UpdateRequirementRulesMutationVariables
  >;
}

const InputRE = new RegExp('^[0-9]+$');

export function NumericInput({ cellValue, ruleId, requirement, fieldName, updateRequirementRulesMutation }: Props) {
  const [isPristine, setIsPristine] = useState(true);
  const [value, setValue] = useState<TValue>(cellValue);
  const cellId = useId();
  const [mutate, mutation] = updateRequirementRulesMutation;

  const rules = createRulesInRequirement(requirement);
  const thisRule = rules.find((rule) => rule.ruleId === ruleId) as RuleInRequirementInput;

  function handleMutation() {
    if (isPristine) return;

    const updatedRule = { ...thisRule, [fieldName]: value };

    if (value === null) {
      updatedRule.includeInRetry = false;

      if (fieldName === 'priority') {
        // whenever the priority field has been cleared, the priorityLimit field also has to be cleared
        updatedRule['priorityLimit'] = null;
      }
    }

    const updatedRules = rules.map((rule) => (rule.ruleId === ruleId ? updatedRule : rule));

    mutate({
      variables: {
        requirementId: requirement.id,
        rules: updatedRules,
      },
      optimisticResponse: {
        updateRequirementRules: {
          mutationStatus: MutationStatus.Success,
          requirement: {
            ...requirement,
            rulesInRequirement: requirement.rulesInRequirement.map((ruleInRequirement) =>
              ruleInRequirement.rule.id === updatedRule?.ruleId
                ? {
                    ...ruleInRequirement,
                    priority: updatedRule?.priority ?? null,
                    priorityLimit: updatedRule.priorityLimit ?? null,
                  }
                : ruleInRequirement
            ),
          },
        },
      },
    });
  }

  function onBlur() {
    handleMutation();
  }

  function onChange(event: ChangeEvent<HTMLInputElement>) {
    setValue((prevValue) => {
      let returnValue;

      if (event.target.value.trim() === '') {
        returnValue = null;
      } else if (InputRE.test(event.target.value)) {
        returnValue = parseInt(event.target.value.trim(), 10);
      } else {
        returnValue = prevValue;
      }

      setIsPristine(returnValue === cellValue);

      return returnValue;
    });
  }

  return (
    <InputField
      disabled={mutation.loading}
      id={cellId}
      inputMode="numeric"
      max={9999}
      maxLength={4}
      min={0}
      onBlur={onBlur}
      onChange={onChange}
      pattern="[0-9]*"
      size={4}
      value={value ?? ''} // value can be `null`, use the empty string in that case
    />
  );
}
