import { useLazyQuery } from '@apollo/client';
import { Label, InputField, FeedbackBox } from '@uva-glass/component-library';
import { useId, useState } from 'react';

import type { ChangeEvent, Dispatch } from 'react';
import type {
  GetStudentRequirementCheckByCourseOfferingIdQuery,
  GetStudentRequirementCheckByCourseOfferingIdQueryVariables,
} from 'types/__generated__';

import { FetchedStudentRequirementStatus } from 'App/CourseOffering/FetchedStudentRequirementStatus/FetchedStudentRequirementStatus';
import { environment } from 'env';
import { GET_STUDENT_REQUIREMENT_CHECK_BY_COURSE_OFFERING_ID } from 'graphql/queries/getStudentRequirementCheckByCourseOfferingId';
import { useGetCourseOffering } from 'hooks/useGetCourseOffering';

type StudentRequirementCheck = NonNullable<
  GetStudentRequirementCheckByCourseOfferingIdQuery['studentRequirementsCheck']
>;

interface Props {
  fieldErrorId: string;
  onSetStudentNumber: Dispatch<StudentRequirementCheck['student']['id']>;
  label: string;
}

const isSandbox = environment === 'sandbox';

export function FetchStudent({ fieldErrorId, onSetStudentNumber, label }: Props) {
  const studentNumberId = useId();
  const {
    marblesCourseOffering: { id: courseOfferingId, info: courseInfo, academicYear },
  } = useGetCourseOffering();
  const [inputError, setInputError] = useState('');
  const [studentRequirementCheck, setStudentRequirementCheck] = useState<StudentRequirementCheck | null>(null);

  const [query, lazyQuery] = useLazyQuery<
    GetStudentRequirementCheckByCourseOfferingIdQuery,
    GetStudentRequirementCheckByCourseOfferingIdQueryVariables
  >(GET_STUDENT_REQUIREMENT_CHECK_BY_COURSE_OFFERING_ID);

  function onChange(event: ChangeEvent<HTMLInputElement>) {
    const { value, validity } = event.target;

    setInputError('');

    if (value.trim() === '' || !validity.valid) {
      setStudentRequirementCheck(null);
      onSetStudentNumber('');
      return;
    }

    query({
      variables: { courseOfferingId, studentNumber: value, academicYear: academicYear.id },
      onCompleted(data) {
        if (!data.studentRequirementsCheck) {
          setStudentRequirementCheck(null);
          onSetStudentNumber('');
          return;
        }

        setStudentRequirementCheck(data.studentRequirementsCheck);
        onSetStudentNumber(data.studentRequirementsCheck.student.studentNumber);
      },
      onError() {
        setStudentRequirementCheck(null);
        // in case student data could not be retrieved, the student number should still be set so that the form can be submitted
        onSetStudentNumber(value.trim());
      },
    });
  }

  return (
    <>
      <Label htmlFor={studentNumberId}>{label}</Label>

      <InputField
        aria-describedby={inputError ? fieldErrorId : undefined}
        autoComplete="off"
        defaultValue={studentRequirementCheck?.student.id}
        id={studentNumberId}
        onChange={onChange}
        pattern={isSandbox ? undefined : '\\d+'}
      />
      {inputError && <FeedbackBox id={fieldErrorId} level="error" feedback={inputError} />}
      {studentRequirementCheck && (
        <FetchedStudentRequirementStatus
          studentRequirementCheck={studentRequirementCheck}
          isStudentRequirementCheckLoading={lazyQuery.loading}
          courseInfo={courseInfo}
        />
      )}
    </>
  );
}
