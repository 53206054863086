import { useMutation } from '@apollo/client';

import type { StoreObject } from '@apollo/client';
import type {
  GetPendingPursuitsByCourseOfferingIdQuery,
  GetPendingPursuitsByCourseOfferingIdQueryVariables,
  RejectPendingCoursePursuitMutation,
  RejectPendingCoursePursuitMutationVariables,
} from 'types/__generated__';

import { MutationStatus } from 'types/__generated__';
import { REJECT_PENDING_COURSEPURSUIT } from 'graphql/mutations/rejectPendingCoursePursuit';
import { GET_PENDING_PURSUITS_BY_COURSE_OFFERING_ID } from 'graphql/queries/getPendingPursuitsByCourseOfferingId';

export const useRejectPendingCoursePursuit = (academicYear: number) =>
  useMutation<RejectPendingCoursePursuitMutation, RejectPendingCoursePursuitMutationVariables>(
    REJECT_PENDING_COURSEPURSUIT,
    {
      update(cache, { data }) {
        if (data?.rejectPendingCoursePursuit.mutationStatus !== MutationStatus.Success) return;

        const { coursePursuitId, courseOfferingId } = data.rejectPendingCoursePursuit;

        if (!courseOfferingId) throw new Error('Missing courseOfferingId');

        const coursePursuitToEvict: StoreObject = { __typename: 'CoursePursuit', id: coursePursuitId };
        const courseOffering: StoreObject = { __typename: 'MarblesCourseOffering', id: courseOfferingId };

        // evict the CoursePursuit
        cache.evict({ id: cache.identify(coursePursuitToEvict) });
        cache.gc();

        // update the cache for `GET_PENDING_PURSUITS_BY_COURSE_OFFERING_ID`
        const pending = cache.updateQuery<
          GetPendingPursuitsByCourseOfferingIdQuery,
          GetPendingPursuitsByCourseOfferingIdQueryVariables
        >(
          { query: GET_PENDING_PURSUITS_BY_COURSE_OFFERING_ID, variables: { courseOfferingId, academicYear } },
          (cached) =>
            cached
              ? {
                  courseOfferingPursuits: [
                    ...cached.courseOfferingPursuits.filter(({ id }) => id !== courseOfferingId),
                  ],
                }
              : undefined
        );

        // update the number of pending students for the CourseOffering
        cache.modify({
          id: cache.identify(courseOffering),
          fields: {
            numberOfPendingStudents(currentValue) {
              return pending?.courseOfferingPursuits.length ?? currentValue;
            },
          },
        });
      },
      refetchQueries({ data }) {
        const mutationStatus = data?.rejectPendingCoursePursuit.mutationStatus;

        if (mutationStatus === MutationStatus.Success) return [];

        return [GET_PENDING_PURSUITS_BY_COURSE_OFFERING_ID];
      },
    }
  );
