import { Trans, useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { useOverlayTriggerState } from '@react-stately/overlays';
import { Button, ButtonGroup } from '@uva-glass/component-library';

import { Plan } from './Plan/Plan';
import { RequiredPlanForm } from './RequiredPlanForm/RequiredPlanForm';
import styles from './Rule.module.css';

import {
  UserAction,
  type AddRequiredPlanMutation,
  type AddRequiredPlanMutationVariables,
  type GetRuleByIdQuery,
  type RequiredPlanInput,
} from 'types/__generated__';
import { ADD_REQUIRED_PLAN } from 'graphql/mutations/addRequiredPlan';
import { PageSection } from 'App/shared/PageSection/PageSection';

interface Props {
  rule: NonNullable<GetRuleByIdQuery['rule']>;
}

export function RequiredPlanRule({ rule }: Props) {
  const { t } = useTranslation('requirement-rules', { keyPrefix: 'rule.group-requirements' });
  const { open, close, isOpen } = useOverlayTriggerState({});

  const userCanEditRule = rule.department.allowedUserActions.includes(UserAction.EditRequirements);

  const [mutate, mutation] = useMutation<AddRequiredPlanMutation, AddRequiredPlanMutationVariables>(ADD_REQUIRED_PLAN);

  const groupRequirements = rule.nodes.groupRequirements.filter(
    (groupRequirement) => groupRequirement.__typename === 'RequiredPlan'
  );

  function onMutate(plan: RequiredPlanInput) {
    mutate({
      variables: {
        ruleId: rule.id,
        plan,
      },
      onCompleted: close,
    });
  }

  return (
    <PageSection>
      <div className={styles['rule-requirement-header']}>
        <h2 className={styles['rule-heading']}>{t('title')}</h2>

        {userCanEditRule && !rule.academicYear.isArchived && (
          <ButtonGroup>
            <Button variant="secondary" onClick={open}>
              {t('plan.add')}
            </Button>
          </ButtonGroup>
        )}

        {isOpen && (
          <RequiredPlanForm
            close={close}
            isLoading={mutation.loading}
            isOpen={isOpen}
            modalTitle={t('plan.add')}
            mutate={onMutate}
          />
        )}
      </div>

      {groupRequirements.length > 0 ? (
        <div className={styles['rule-requirement']}>
          <div className={styles['rule-requirement-header']}>
            <div>
              <strong>{t('plan.required-plan')}</strong>
              <p className={styles['rule-requirement-header__subheader']}>
                <Trans t={t} i18nKey="plan.student-must-be-registered" components={{ strong: <strong /> }} />
              </p>
            </div>
          </div>

          <ul className={styles['group-requirement-list']}>
            {groupRequirements.map(
              (groupRequirement) =>
                groupRequirement.__typename === 'RequiredPlan' && (
                  <li key={groupRequirement.id} className={styles['group-requirement-list-item']}>
                    <Plan
                      canBeRemoved={Boolean(rule.nodes.groupRequirements.length)}
                      nodeId={groupRequirement.id}
                      plan={groupRequirement.plan}
                      ruleId={rule.id}
                      subplans={groupRequirement.subplans}
                      canEdit={userCanEditRule && !rule.academicYear.isArchived}
                    />
                  </li>
                )
            )}
          </ul>
        </div>
      ) : (
        t('plan.no-plans')
      )}
    </PageSection>
  );
}
