import { useMutation } from '@apollo/client';

import type {
  CreateTopicSetMutation,
  CreateTopicSetMutationVariables,
  GetTopicSetsByDepartmentIdQuery,
  GetTopicSetsByDepartmentIdQueryVariables,
} from 'types/__generated__';

import { CREATE_TOPIC_SET } from 'graphql/mutations/createTopicSet';
import { GET_TOPIC_SETS_BY_DEPARTMENT_ID } from 'graphql/queries/getTopicSetsByDepartmentId';
import { MutationStatus } from 'types/__generated__';

export const useCreateTopicSet = (departmentId: string) =>
  useMutation<CreateTopicSetMutation, CreateTopicSetMutationVariables>(CREATE_TOPIC_SET, {
    update(cache, { data }) {
      if (data?.createTopicSet.mutationStatus !== MutationStatus.Success) return;

      const { academicYear, topicSet } = data.createTopicSet;

      if (!topicSet) {
        throw new Error('No "topic set" in payload');
      }

      cache.updateQuery<GetTopicSetsByDepartmentIdQuery, GetTopicSetsByDepartmentIdQueryVariables>(
        {
          query: GET_TOPIC_SETS_BY_DEPARTMENT_ID,
          variables: { academicYear, departmentId },
        },
        (cached) => ({
          topicSets: cached?.topicSets ? [...cached.topicSets, topicSet] : [topicSet],
        })
      );
    },
  });
