import { gql } from '@apollo/client';

export const AUTHORISE_USER = gql`
  mutation authoriseUser($username: String!, $role: String!, $departmentId: String!) {
    marblesAuthoriseUser(input: { username: $username, role: $role, departmentId: $departmentId }) {
      username
      roleAssignment {
        id
        createdBy
        creationDate
        department {
          id
          code
          name {
            NL
            EN
          }
        }
        role
        userActions
        userRole {
          name
          description {
            NL
            EN
          }
        }
      }
      mutationStatus
    }
  }
`;
