import { Log, UserManager, WebStorageStateStore } from 'oidc-client-ts';

import type { UserState } from 'oidc-clients-ts';

import { authority, client_id } from 'env';

const isProd = process.env.NODE_ENV === 'production';

// log errors in production
Log.setLevel(isProd ? Log.ERROR : Log.INFO);
Log.setLogger(window.console);

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const THREE_MINUTES = 60 * 3;

const userManager = new UserManager({
  automaticSilentRenew: false,
  authority,
  client_id,
  redirect_uri: `${window.location.origin}/callback`,
  response_type: 'code',
  scope: 'openid profile',
  // Uses `localStorage` for storage instead of the default `sessionStorage`. `sessionStorage` is unique between tabs
  // which means that the user needs to login for every open tab. With Prism requiring MFA, this would be
  // particularly user-unfriendly.
  userStore: new WebStorageStateStore({ store: localStorage }),
  accessTokenExpiringNotificationTimeInSeconds: THREE_MINUTES,
});

export const { events } = userManager;
export const login = async (state: UserState) => userManager.signinRedirect({ state });
export const completeLogin = async () => userManager.signinRedirectCallback();
export const logout = async () => userManager.removeUser();
export const getUser = () => Promise.all([userManager.getUser(), userManager.clearStaleState()]);
