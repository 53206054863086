import { gql } from '@apollo/client';

import { CONSTRAINED_CHOICE_CLUSTER_FRAGMENT } from 'graphql/fragments/constrainedChoiceClusterFragment';
import { COURSE_INFO_FRAGMENT } from 'graphql/fragments/courseInfoFragment';
import { PERIOD_FRAGMENT } from 'graphql/fragments/periodFragment';

export const CREATE_CONSTRAINED_CHOICE_CLUSTER = gql`
  mutation createConstrainedChoiceCluster(
    $academicYear: Int!
    $departmentId: String!
    $titleNL: String!
    $titleEN: String!
  ) {
    createConstrainedChoiceCluster(
      input: { academicYear: $academicYear, departmentId: $departmentId, titleNL: $titleNL, titleEN: $titleEN }
    ) {
      mutationStatus
      academicYear
      constrainedChoiceCluster {
        ...constrainedChoiceCluster
        offerings {
          id
          info {
            ...courseInfo
          }
          periods {
            ...period
          }
        }
      }
    }
  }

  ${CONSTRAINED_CHOICE_CLUSTER_FRAGMENT}
  ${COURSE_INFO_FRAGMENT}
  ${PERIOD_FRAGMENT}
`;
