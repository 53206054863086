import { gql } from '@apollo/client';

import { REQUIREMENT_FRAGMENT } from 'graphql/fragments/requirementFragment';

export const UPDATE_REQUIREMENT_NAME = gql`
  mutation updateRequirementName($requirementId: String!, $name: String!) {
    updateRequirementName(input: { requirementId: $requirementId, name: $name }) {
      mutationStatus
      requirement {
        ...requirement
      }
    }
  }
  ${REQUIREMENT_FRAGMENT}
`;
