import { gql } from '@apollo/client';

export const GET_AUTHORISATION_RIGHTS = gql`
  query getAuthorisationRights {
    marblesAuthorisationRights {
      userRoles {
        name
        description {
          NL
          EN
        }
      }
      departments {
        id
        code
        name {
          NL
          EN
        }
      }
    }
  }
`;
