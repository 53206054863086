import { gql } from '@apollo/client';

export const UPLOAD_COURSE_DEREGISTRATIONS = gql`
  mutation uploadCourseDeregistrations($courseOfferingId: String!, $file: Upload!, $reason: StaffDeregistrationReason) {
    uploadCourseDeregistrations(input: { courseOfferingId: $courseOfferingId, file: $file, reason: $reason }) {
      mutationStatus
      courseOfferingId
      result {
        line
        studentNumber
        rowStatus
      }
      missingColumns
    }
  }
`;
