import { useOverlayTriggerState } from '@react-stately/overlays';
import {
  Button,
  FeedbackBox,
  Label,
  ModalDialog,
  Spinner,
  Checkbox,
  ButtonGroup,
  FormField,
} from '@uva-glass/component-library';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { ChangeEvent, FormEvent } from 'react';
import type { UploadCourseRegistrationsMutation } from 'types/__generated__';

import { useUploadCourseRegistrations } from './hooks/useUploadCourseRegistrations';

import { UploadResults } from 'App/shared/UploadResults/UploadResults';
import { UploadRowErrors } from 'App/shared/UploadRowErrors/UploadRowErrors';
import { Input } from 'components/Input/Input';
import { useCurrentLanguage } from 'hooks/useCurrentLanguage';
import { useGetCourseOffering } from 'hooks/useGetCourseOffering';
import { useGetReadableMissingColumn } from 'hooks/useGetReadableMissingColumns';
import { useGetReadableMutationStatus } from 'hooks/useGetReadableMutationStatus';
import { MutationStatus } from 'types/__generated__';
import { ACCEPTED_FILE_TYPES } from 'util/acceptedFileTypes';

const FILE_UPLOAD_ID = 'file-upload';
const FILE_UPLOAD_ERROR_ID = 'file-upload-error';
const SKIP_REQUIREMENTS_ID = 'skip-requirements';

type UploadCourseRegistrationsMutationResult = UploadCourseRegistrationsMutation['uploadCourseRegistrations']['result'];
type UploadCourseRegistrationsMissingColumns =
  UploadCourseRegistrationsMutation['uploadCourseRegistrations']['missingColumns'];

export function UploadCourseRegistrationsModal() {
  const { t } = useTranslation('course-offering', { keyPrefix: 'upload-course-registrations-modal' });
  const currentLanguage = useCurrentLanguage();
  const { close, isOpen, open } = useOverlayTriggerState({});
  const readableMutationStatus = useGetReadableMutationStatus();
  const readableMissingColumn = useGetReadableMissingColumn();
  const { marblesCourseOffering } = useGetCourseOffering();

  const { id: courseOfferingId, info } = marblesCourseOffering;
  const academicYear = marblesCourseOffering.academicYear.id;

  const [error, setError] = useState('');
  const [results, setResults] = useState<UploadCourseRegistrationsMutationResult>([]);
  const [currentMissingColumns, setCurrentMissingColumns] = useState<UploadCourseRegistrationsMissingColumns>(null);
  const [file, setFile] = useState<File>();
  const [switchGroupIfAlreadyRegistered, setSwitchGroupIfAlreadyRegistered] = useState(false);
  const [skipRequirements, setSkipRequirements] = useState(true);

  const closeButtonRef = useRef<HTMLButtonElement>(null);
  const okButtonRef = useRef<HTMLButtonElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [mutate, mutation] = useUploadCourseRegistrations(academicYear);
  const errorRows = results.filter(({ rowStatus }) => rowStatus !== MutationStatus.Success);
  const successRowCount = results.length - errorRows.length;

  function reset() {
    setError('');
    setResults([]);
    setCurrentMissingColumns(null);
    setFile(undefined);
    setSwitchGroupIfAlreadyRegistered(false);
    setSkipRequirements(true);
    mutation.reset();
  }

  function resetAndClose() {
    reset();
    close();
  }

  function handleFileChange({ target }: ChangeEvent<HTMLInputElement>) {
    setError('');
    const { files } = target;

    if (!files) return;

    const file = files[0];

    if (!ACCEPTED_FILE_TYPES.includes(file.type)) {
      setError(t('modal.errors.unsupported-type'));
      if (fileInputRef.current) {
        // reset the value of the <input type="file">
        fileInputRef.current.value = '';
      }
      return;
    }

    setFile(file);
  }

  function handleCheckBoxChange({ target }: ChangeEvent<HTMLInputElement>) {
    setSwitchGroupIfAlreadyRegistered(target.checked);
  }

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (!file) {
      setError(t('modal.errors.file-missing'));
      return;
    }

    const { data } = await mutate({
      variables: { courseOfferingId, skipRequirements, file, switchGroupIfAlreadyRegistered },
    });

    if (!data?.uploadCourseRegistrations) {
      setError(readableMutationStatus('unknown'));
      return;
    }

    const { mutationStatus, result, missingColumns } = data.uploadCourseRegistrations;

    if (mutationStatus === MutationStatus.Success) {
      setResults(result);
      return;
    }

    if (mutationStatus === MutationStatus.UploadMissingColumns) {
      setCurrentMissingColumns(missingColumns);
      setError(readableMutationStatus(mutationStatus, { details: missingColumns ?? undefined }));
      return;
    }

    setError(readableMutationStatus(mutationStatus));
  }

  return (
    <>
      <button onClick={open}>{t('trigger')}</button>

      <ModalDialog
        title={t('modal.title')}
        onSubmit={handleSubmit}
        buttons={
          <>
            {!mutation.called && (
              <ButtonGroup reversed>
                <Button variant="primary" type="submit" disabled={mutation.loading}>
                  {t('modal.buttons.import')}
                </Button>
                <Button onClick={resetAndClose} variant="secondary" ref={closeButtonRef}>
                  {t('modal.buttons.cancel')}
                </Button>
              </ButtonGroup>
            )}

            {mutation.called && !mutation.loading && (
              <ButtonGroup reversed>
                <Button onClick={resetAndClose} variant="primary" ref={okButtonRef}>
                  {t('modal.buttons.confirm')}
                </Button>
                <Button onClick={reset} variant="secondary">
                  {t('modal.buttons.reset-and-retry')}
                </Button>
              </ButtonGroup>
            )}
          </>
        }
        isOpen={isOpen}
        onClose={resetAndClose}
        wide
      >
        {info && (
          <p>
            {info.title[currentLanguage]} ({info.catalogNumber})
          </p>
        )}

        {!mutation.called && (
          <>
            <p>{t('modal.description')}</p>

            <FormField outerSpace>
              <Label htmlFor={FILE_UPLOAD_ID}>{t('modal.file')}</Label>
              <Input
                id={FILE_UPLOAD_ID}
                type="file"
                accept={ACCEPTED_FILE_TYPES.join(',')}
                onChange={handleFileChange}
                aria-describedby={error ? FILE_UPLOAD_ERROR_ID : undefined}
                ref={fileInputRef}
              />
              {error && <FeedbackBox id={FILE_UPLOAD_ERROR_ID} level="error" feedback={error} />}
            </FormField>

            <FormField outerSpace inline>
              <Checkbox id="overwrite" checked={switchGroupIfAlreadyRegistered} onChange={handleCheckBoxChange} />
              <Label htmlFor="overwrite">{t('modal.overwrite')}</Label>
            </FormField>

            <FormField outerSpace inline>
              <Checkbox
                checked={skipRequirements}
                id={SKIP_REQUIREMENTS_ID}
                onChange={() => {
                  setSkipRequirements(!skipRequirements);
                }}
              />
              <Label htmlFor={SKIP_REQUIREMENTS_ID}>{t('modal.ignore-requirements')}</Label>
            </FormField>
          </>
        )}

        {mutation.called && mutation.loading && <Spinner ariaValueText={t('results-modal.loading')} />}

        {mutation.called &&
          !mutation.loading &&
          (results.length > 0 ? (
            <UploadResults>
              {successRowCount > 0 && (
                <FeedbackBox level="success" feedback={t('results-modal.success', { count: successRowCount })} />
              )}
              {errorRows.length > 0 && (
                <>
                  <FeedbackBox level="error" feedback={t('results-modal.failure', { count: errorRows.length })} />
                  <UploadRowErrors errorRows={errorRows} />
                </>
              )}
            </UploadResults>
          ) : (
            <>
              {error && <FeedbackBox level="error" feedback={error} />}
              {currentMissingColumns ? (
                <ul>
                  {currentMissingColumns.map((column, index) => (
                    <li key={index}>{readableMissingColumn(column)}</li>
                  ))}
                </ul>
              ) : (
                <p>{t('results-modal.nothing-imported')}</p>
              )}
            </>
          ))}
      </ModalDialog>
    </>
  );
}
