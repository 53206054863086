import { gql } from '@apollo/client';

import { ENROLMENT_FRAGMENT } from 'graphql/fragments/enrolmentFragment';
import { REQUIREMENTS_FRAGMENT } from 'graphql/fragments/requirementsFragment';
import { STUDENT_WITH_ENROLMENTS_BY_ACADEMIC_YEAR_FRAGMENT } from 'graphql/fragments/studentWithEnrolmentsByAcademicYearFragment';

export const GET_PENDING_PURSUITS_BY_COURSE_OFFERING_ID = gql`
  query getPendingPursuitsByCourseOfferingId($courseOfferingId: String!, $academicYear: Int) {
    courseOfferingPursuits(courseOfferingId: $courseOfferingId, status: PENDING) {
      id
      topicId
      student {
        ...studentWithEnrolmentsByAcademicYear
      }
      requirementsCheck {
        ...requirements
      }
      clusterPreference {
        index
        pursuitCount
        registrationLimit
      }
    }
  }
  ${STUDENT_WITH_ENROLMENTS_BY_ACADEMIC_YEAR_FRAGMENT}
  ${ENROLMENT_FRAGMENT}
  ${REQUIREMENTS_FRAGMENT}
`;
