import { InMemoryCache } from '@apollo/client';

import { courseOfferingsFilterVar } from 'graphql/courseOfferingsFilterVar';

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        subplans: {
          merge: false,
        },
        requirements: {
          merge: false,
        },
        rules: {
          merge: false,
        },
        courseOfferingPursuits: {
          merge: false,
        },
        courseOfferingsFilter: {
          read() {
            return courseOfferingsFilterVar();
          },
        },
        courseOffering: {
          read(currentValue, { args, toReference }) {
            if (!args) {
              return currentValue;
            }

            const { courseOfferingId: id } = args;

            return toReference({
              __typename: 'MarblesCourseOffering',
              id,
            });
          },
        },
        courseOfferingTopicsCapacityQuery: {
          merge: false,
        },
        topicSet: {
          merge: false,
        },
        topicSets: {
          merge: false,
        },
        constrainedChoiceClusters: {
          merge: false,
        },
        courseOfferingRequirementCapacity: {
          merge: false,
        },
        users: {
          merge: false,
        },
      },
    },
    Requirement: {
      fields: {
        courseOfferings: {
          merge: false,
        },
        rulesInRequirement: {
          merge: false,
        },
      },
    },
    RequiredCareer: {
      fields: {
        careers: {
          merge: false,
        },
      },
    },
    TopicSet: {
      fields: {
        courseOfferings: {
          merge: false,
        },
        topics: {
          merge: false,
        },
      },
    },
    ConstrainedChoiceCluster: {
      fields: {
        offerings: {
          merge: false,
        },
      },
    },
  },
});
