import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Icon, Card } from '@uva-glass/component-library';

import styles from './UserBlocked.module.css';

import { ActionHeader } from 'App/shared/ActionHeader/ActionHeader';
import { Page } from 'components/Page/Page';

export const UserBlocked = () => {
  const { t } = useTranslation('user-blocked');

  return (
    <Page documentTitle={t('title')} header={<ActionHeader />}>
      <div className={styles['userblocked-page__container']}>
        <Card highContrast padding="large" alignCenter>
          <div className={styles['userblocked-page__icon']}>
            <Icon name="BrowserBlocked" size={128} className={styles['userblocked-page__icon']} />
          </div>
          <div className={styles['userblocked-page__content']}>
            <div className={styles['userblocked-page__text']}>
              <h1 className={styles['userblocked-page__title']}>{t('title')}</h1>
              <p>
                <Trans
                  t={t}
                  i18nKey="body"
                  components={{
                    confluenceLink: (
                      <Link to="https://confluence.ic.uva.nl/pages/viewpage.action?pageId=224722989" target="_blank" />
                    ),
                  }}
                />
              </p>
            </div>
          </div>
        </Card>
      </div>
    </Page>
  );
};
