import { gql } from '@apollo/client';

import { RULE_FRAGMENT } from 'graphql/fragments/ruleFragment';

export const UPDATE_REQUIRED_PLAN = gql`
  mutation updateRequiredPlan($ruleId: String!, $nodeId: String!, $plan: RequiredPlanInput!) {
    updateRequiredPlan(input: { ruleId: $ruleId, nodeId: $nodeId, plan: $plan }) {
      nodeId
      rule {
        ...rule
      }
      mutationStatus
    }
  }
  ${RULE_FRAGMENT}
`;
