import { clsx } from 'clsx';
import { Children, useEffect, useId, useRef, useState } from 'react';
import { Icon, MenuButton } from '@uva-glass/component-library';

import type { KeyboardEvent, ReactElement, MouseEvent as ReactMouseEvent } from 'react';
import type { IconProps } from '@uva-glass/component-library';
import type { UIButtonVariant } from 'types/UserInterface';

import styles from './ActionList.module.css';

interface ActionListProps {
  label: string;
  prefixIcon?: IconProps['name'];
  variant?: UIButtonVariant;
  children: ReactElement | ReactElement[];
}

const inactiveIndex = -1;
const indexIncrement = 1;

export function ActionList({ label, prefixIcon, variant = 'secondary', children }: ActionListProps) {
  const listId = useId();
  const triggerRef = useRef<HTMLButtonElement>(null);
  const listRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const numberOfChildren = Children.count(children);

  const [activeIndex, setActiveIndex] = useState(inactiveIndex);
  const [isOpen, setIsOpen] = useState(false);

  function open() {
    setIsOpen(true);

    document.addEventListener('click', onClickOutside, { once: true, capture: true });
  }

  function close(event?: ReactMouseEvent<HTMLDivElement>) {
    // When a click occurs on an element that is not an immediate descendant of the action list container
    // (can happen when a modal window is triggered from a button in the action list), the setIsOpen state
    // handler should not be called, because this causes a re-render of the entire component and can lead
    // to undesired effects, like a select element closing immediately after it has been clicked.
    if (event && containerRef.current && !containerRef.current.contains(event.target as Node)) {
      return;
    }

    setActiveIndex(inactiveIndex);
    setIsOpen(false);

    document.removeEventListener('click', onClickOutside, { capture: true });

    triggerRef.current?.focus();
  }

  function toggle() {
    if (isOpen) close();
    else open();
  }

  function onKeyDown(event: KeyboardEvent) {
    if (!isOpen) return;

    const eventPreventCodes = ['ArrowUp', 'ArrowDown', 'Home', 'End'];

    if (eventPreventCodes.includes(event.code)) {
      // prevent page from scrolling when navigating through items in list
      event.preventDefault();
    }

    if (event.code === 'Tab') {
      close();
    }
  }

  function onKeyUp(event: KeyboardEvent) {
    if (event.code === 'Space' || event.key === 'Enter') {
      if (event.target === triggerRef.current) {
        toggle();
      } else if (event.key === 'Enter' && event.target !== listRef.current) {
        if ('firstChild' in event.target) {
          const firstChild = event.target.firstChild as HTMLButtonElement;

          firstChild.click();
        }
      }
    }

    if (event.code === 'Escape') close();

    if (event.code === 'ArrowUp') {
      setActiveIndex((prevState) => {
        if (prevState === 0) return numberOfChildren - indexIncrement;

        return prevState - indexIncrement;
      });
    }

    if (event.code === 'ArrowDown') {
      setActiveIndex((prevState) => {
        if (prevState === numberOfChildren - indexIncrement) return 0;

        return prevState + indexIncrement;
      });
    }

    if (event.code === 'Home') {
      setActiveIndex(0);
    }

    if (event.code === 'End') {
      setActiveIndex(numberOfChildren - indexIncrement);
    }
  }

  const onClickOutside = (event: MouseEvent) => {
    const { target } = event;

    if (!target || !containerRef.current) return;

    if (!containerRef.current.contains(target as Node)) {
      close();
    }
  };

  useEffect(() => {
    if (activeIndex < 0 || !listRef.current) return;

    const listItem = listRef.current.children.item(activeIndex);

    if (listItem) {
      (listItem as HTMLDivElement).focus();
    }
  }, [activeIndex]);

  useEffect(() => {
    if (!listRef.current || !listRef.current.hasChildNodes()) return;

    Array.from(listRef.current.children)
      .filter((overlayChild) => overlayChild.nodeType === Node.ELEMENT_NODE && overlayChild.hasChildNodes())
      .forEach((overlayChild) => {
        Array.from(overlayChild.children)
          .filter((childOfChild) => childOfChild.nodeType === Node.ELEMENT_NODE)
          .forEach((childOfChild) => {
            // internal children may be buttons and can get focus when tab is pressed; preventing those elements
            // from getting focus so that list can be tabbed over and traversed with arrow keys
            childOfChild.setAttribute('tabIndex', '-1');
          });
      });
  }, [children]);

  return (
    <div className={styles['action-list-container']} ref={containerRef}>
      <MenuButton onClick={toggle} onKeyDown={onKeyDown} onKeyUp={onKeyUp} buttonRef={triggerRef} variant={variant}>
        {prefixIcon && <Icon size={20} name={prefixIcon} />}
        {label}
        <Icon size={16} name={isOpen ? 'CheveronUp' : 'CheveronDown'} />
      </MenuButton>
      <div
        aria-activedescendant={`${listId}-child-${activeIndex}`}
        aria-label={label}
        className={clsx(styles['action-list'], {
          [styles['action-list--open']]: isOpen,
        })}
        onClickCapture={close}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        ref={listRef}
        role="menu"
        tabIndex={-1}
      >
        {Children.map(children, (child, index) => (
          <div key={`${listId}-child-${index}`} role="menuitem" tabIndex={-1}>
            {child}
          </div>
        ))}
      </div>
    </div>
  );
}
