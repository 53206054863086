import { gql } from '@apollo/client';

export const UPDATE_TOPIC = gql`
  mutation updateTopic($topicId: String!, $titleNL: String!, $titleEN: String!, $requirementNodeId: String) {
    updateTopic(
      input: { topicId: $topicId, titleNL: $titleNL, titleEN: $titleEN, requirementNodeId: $requirementNodeId }
    ) {
      topic {
        id
        title {
          NL
          EN
        }
        requirement {
          id
          name
        }
        topicSetId
      }
      mutationStatus
    }
  }
`;
