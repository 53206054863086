import classNames from 'clsx';

import type { InputHTMLAttributes, ReactNode } from 'react';

import styles from './ToggleSwitch.module.css';

interface Props extends Omit<InputHTMLAttributes<HTMLInputElement>, 'style' | 'className'> {
  label: string;
  labelVisible?: boolean;
  labelPosition?: 'before' | 'after';
  loading?: boolean;
  extraLeftGap?: boolean;
}

function ConditionalWrapper({
  condition,
  wrapper,
  children,
}: {
  condition: boolean;
  wrapper: (children: ReactNode) => ReactNode;
  children: ReactNode;
}) {
  return condition ? wrapper(children) : children;
}

export function ToggleSwitch({
  label,
  labelPosition = 'after',
  labelVisible = true,
  loading = false,
  extraLeftGap,
  ...restProps
}: Props) {
  const labelComponent = (
    <span
      className={classNames(
        { 'visually-hidden': !labelVisible },
        { [styles['toggle-switch--disabled-label']]: restProps.disabled }
      )}
    >
      {label}
    </span>
  );

  return (
    <ConditionalWrapper
      condition={loading}
      wrapper={(children) => <div className={styles['toggle-switch--loading-cursor']}>{children}</div>}
    >
      <label
        className={classNames(
          styles['toggle-switch'],
          { [styles['toggle-switch--disabled']]: restProps.disabled },
          { [styles['toggle-switch--loading']]: loading },
          { [styles['toggle-switch--extra-left-gap']]: extraLeftGap }
        )}
      >
        {labelPosition === 'before' && labelComponent}
        <input className="visually-hidden" {...restProps} type="checkbox" />
        <span className={styles['toggle-switch__visible']} title={!labelVisible ? label : ''} />
        {labelPosition === 'after' && labelComponent}
      </label>
    </ConditionalWrapper>
  );
}
