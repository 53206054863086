import { gql } from '@apollo/client';

export const PARTIAL_RULE_FRAGMENT = gql`
  fragment partialRule on Rule {
    id
    ruleKind
    name
    requirements {
      id
    }
    department {
      id
      name {
        EN
        NL
      }
      code
      allowedUserActions
    }
  }
`;
