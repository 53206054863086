import { gql } from '@apollo/client';

import { COURSE_INFO_FRAGMENT } from 'graphql/fragments/courseInfoFragment';
import { PERIOD_FRAGMENT } from 'graphql/fragments/periodFragment';

export const GET_COURSE_OFFERINGS_BY_CATALOG_NUMBER = gql`
  query getCourseOfferingsByCatalogNumber($departmentId: String!, $academicYear: Int!, $catalogNumber: String!) {
    marblesCourseOfferings(departmentId: $departmentId, academicYear: $academicYear, catalogNumber: $catalogNumber) {
      id
      status
      info {
        ...courseInfo
      }
      periods {
        ...period
      }
      numberOfPendingStudents
      hasCreditLimitException
      constrainedChoiceClusterId
      registrationPeriod {
        ...period
      }
    }
  }
  ${COURSE_INFO_FRAGMENT}
  ${PERIOD_FRAGMENT}
`;
