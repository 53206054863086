import { gql } from '@apollo/client';

import { PARTIAL_RULE_FRAGMENT } from 'graphql/fragments/partialRuleFragment';

export const COPY_RULE = gql`
  mutation copyRule($ruleId: String!, $name: String!, $departmentId: String!) {
    copyRule(input: { ruleId: $ruleId, name: $name, departmentId: $departmentId }) {
      mutationStatus
      rule {
        ...partialRule
      }
    }
  }
  ${PARTIAL_RULE_FRAGMENT}
`;
