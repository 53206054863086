import { Children, useLayoutEffect } from 'react';

import type { ReactElement } from 'react';

const HELMET_ID = 'data-helmet';
const VALID_TAGS = ['meta', 'link', 'title', 'base', 'html'];

interface Props {
  children: ReactElement | ReactElement[];
}

export function Helmet({ children }: Props) {
  useLayoutEffect(() => {
    const headElement = document.head;
    const tagNodes = headElement.querySelectorAll(`[${HELMET_ID}]`);
    const fragment = document.createDocumentFragment();

    Children.forEach(children, (child) => {
      if (typeof child.type === 'function' || typeof child.type === 'object') {
        // eslint-disable-next-line no-console
        return console.warn('Do not use React components as children');
      }

      if (!VALID_TAGS.includes(child.type)) {
        // eslint-disable-next-line no-console
        return console.warn(`Element of type '${child.type}' is not allowed`);
      }

      if (child.type === 'html') {
        // handle the <html /> differently; only extract and apply attributes
        Object.entries<string>(child.props).forEach(([prop, value]) => {
          document.documentElement.setAttribute(prop, value);
        });

        return;
      }

      if (child.type === 'title') {
        document.title = child.props.children;
      }

      const element: HTMLElement = document.createElement(child.type);

      element.setAttribute(HELMET_ID, '');

      Object.entries<string | Node>(child.props).forEach(([prop, value]) => {
        if (prop === 'children') {
          // only allow text content
          if (typeof value === 'string') {
            element.textContent = value;
          }

          return;
        }

        const propValue = value as string;

        element.setAttribute(prop, propValue);
      });

      fragment.append(element);
    });

    tagNodes.forEach((tagNode) => {
      tagNode.remove();
    });

    headElement.appendChild(fragment);
  }, [children]);

  return null;
}
