import { useQuery } from '@apollo/client';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import type { ChangeEvent } from 'react';
import type {
  GetTopicsByCourseOfferingIdQuery,
  GetTopicsByCourseOfferingIdQueryVariables,
  SetTopicForStudentGroupMutation,
  SetTopicForStudentGroupMutationVariables,
} from 'types/__generated__';

import { useSetStudentGroupTopic } from './hooks/useSetStudentGroupTopic';

import { Select } from 'components/Select/Select';
import { GET_TOPICS_BY_COURSE_OFFERING_ID } from 'graphql/queries/getTopicsByCourseOfferingId';
import { useCurrentLanguage } from 'hooks/useCurrentLanguage';
import { MutationStatus } from 'types/__generated__';

interface Props {
  topic?: SetTopicForStudentGroupMutation['setStudentGroupTopic']['topic'];
  courseOfferingId: GetTopicsByCourseOfferingIdQueryVariables['courseOfferingId'];
  studentGroupId: SetTopicForStudentGroupMutationVariables['studentGroupId'];
}

function ChangeGroupTopicComponent({ topic, studentGroupId, courseOfferingId }: Props) {
  const { t } = useTranslation('course-offering', { keyPrefix: 'change-topic' });
  const currentLanguage = useCurrentLanguage();
  const [mutate, mutation] = useSetStudentGroupTopic();
  const id = `topic-select-${studentGroupId}${courseOfferingId}`;
  const { loading, data } = useQuery<GetTopicsByCourseOfferingIdQuery, GetTopicsByCourseOfferingIdQueryVariables>(
    GET_TOPICS_BY_COURSE_OFFERING_ID,
    { variables: { courseOfferingId } }
  );

  const topicsList = data?.marblesCourseOffering?.topicSet?.topics.map(({ id, title }) => ({
    id,
    title,
  }));

  function handleChange(event: ChangeEvent<HTMLSelectElement>) {
    const topicId = event.target.value || null;
    const newTopic = data?.marblesCourseOffering?.topicSet?.topics?.find(({ id }) => id === topicId) || null;

    mutate({
      variables: { studentGroupId, topicId },
      optimisticResponse: {
        setStudentGroupTopic: {
          mutationStatus: MutationStatus.Success,
          studentGroupId,
          topic: newTopic,
        },
      },
    });
  }

  if (!topicsList?.length) return null;

  return (
    <>
      <label className="visually-hidden" htmlFor={id}>
        {t('label')}
      </label>

      <Select
        disabled={loading || mutation.loading}
        fullWidth
        id={id}
        onChange={handleChange}
        value={topic?.id}
        variant="small"
      >
        <Select.Option value=""></Select.Option>

        {[...topicsList]
          .sort((topic1, topic2) => topic1.title[currentLanguage].localeCompare(topic2.title[currentLanguage]))
          .map(({ id, title }) => (
            <Select.Option key={id} value={id}>
              {title[currentLanguage]}
            </Select.Option>
          ))}
      </Select>
    </>
  );
}

export const ChangeGroupTopic = memo(ChangeGroupTopicComponent);
