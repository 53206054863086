import { gql } from '@apollo/client';

export const ACADEMIC_YEAR_FRAGMENT = gql`
  fragment academicYear on MarblesAcademicYear {
    id
    name
    startDate
    endDate
    isCurrentAcademicYearForAdmins
    isArchived
  }
`;
