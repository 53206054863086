import { gql } from '@apollo/client';

import { REGISTERED_COURSE_PURSUIT_FRAGMENT } from 'graphql/fragments/registeredCoursePursuitFragment';

export const UPLOAD_COURSE_REGISTRATIONS = gql`
  mutation uploadCourseRegistrations(
    $file: Upload!
    $courseOfferingId: String!
    $skipRequirements: Boolean! = true
    $switchGroupIfAlreadyRegistered: Boolean = false
  ) {
    uploadCourseRegistrations(
      input: {
        file: $file
        courseOfferingId: $courseOfferingId
        skipRequirements: $skipRequirements
        switchGroupIfAlreadyRegistered: $switchGroupIfAlreadyRegistered
      }
    ) {
      mutationStatus
      courseOfferingId
      pursuits {
        ...registeredCoursePursuit
      }
      result {
        groupCode
        line
        rowStatus
        studentNumber
      }
      missingColumns
    }
  }
  ${REGISTERED_COURSE_PURSUIT_FRAGMENT}
`;
