import { gql } from '@apollo/client';

import { TOPIC_SET_FRAGMENT } from 'graphql/fragments/topicSetFragment';

export const CREATE_TOPIC_SET = gql`
  mutation createTopicSet(
    $academicYear: Int!
    $departmentId: String!
    $name: String!
    $titleNL: String!
    $titleEN: String!
  ) {
    createTopicSet(
      input: {
        academicYear: $academicYear
        departmentId: $departmentId
        name: $name
        titleNL: $titleNL
        titleEN: $titleEN
      }
    ) {
      mutationStatus
      academicYear
      topicSet {
        ...topicSet
        courseOfferings {
          id
        }
        department {
          id
          name {
            NL
            EN
          }
        }
      }
    }
  }
  ${TOPIC_SET_FRAGMENT}
`;
