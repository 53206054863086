import { useQuery } from '@apollo/client';
import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { useOverlayTriggerState } from '@react-stately/overlays';
import { useState } from 'react';
import { Button, Drawer, Spinner } from '@uva-glass/component-library';

import type { GetTopicsByTopicSetIdQuery, GetTopicsByTopicSetIdQueryVariables } from 'types/__generated__';

import { RemoveTopic } from './RemoveTopic';
import { EditTopic } from './EditTopic';

import { DataTable } from 'App/shared/DataTable/DataTable';
import { EntryRequirementDrawer } from 'App/EntryRequirements/EntryRequirementsTable/EntryRequirementDrawer/EntryRequirementDrawer';
import { GET_TOPICS_BY_TOPIC_SET_ID } from 'graphql/queries/getTopicsByTopicSetId';
import { RowActions } from 'App/shared/DataTable/RowActions/RowActions';
import { TABLE_STORAGE_KEYS } from 'util/storagekeys';
import { useAuthorisation } from 'providers/AuthorisationProvider';
import { useCurrentLanguage } from 'hooks/useCurrentLanguage';
import { useGetAcademicYearDetails } from 'hooks/useGetAcademicYearDetails';
import { UserAction } from 'types/__generated__';

type RowData = NonNullable<GetTopicsByTopicSetIdQuery['topicSet']>['topics'][number];

interface Props extends GetTopicsByTopicSetIdQueryVariables {
  academicYear: number;
  departmentId: string;
}

const columnHelper = createColumnHelper<RowData>();

const initialState = { sorting: [{ id: 'name', desc: false }] };

export function TopicsTable({ topicSetId, academicYear, departmentId }: Props) {
  const { t } = useTranslation('topic-sets', { keyPrefix: 'topics' });
  const currentLanguage = useCurrentLanguage();
  const userCanEditTopicSet = useAuthorisation(UserAction.EditTopicSets, departmentId);
  const academicYearDetails = useGetAcademicYearDetails(academicYear);
  const { open, close, isOpen } = useOverlayTriggerState({});

  const [currentRequirement, setCurrentRequirement] = useState<RowData['requirement'] | null>(null);

  const topicSetQuery = useQuery<GetTopicsByTopicSetIdQuery, GetTopicsByTopicSetIdQueryVariables>(
    GET_TOPICS_BY_TOPIC_SET_ID,
    {
      variables: { topicSetId },
    }
  );

  if (topicSetQuery.loading) return <Spinner ariaValueText={''} />;

  if (!topicSetQuery.data?.topicSet) return null;

  const { topics } = topicSetQuery.data.topicSet;

  const columns = [
    columnHelper.accessor(({ title }) => title[currentLanguage], {
      id: 'name',
      header: t('table.headers.name'),
    }),

    columnHelper.accessor(({ requirement }) => requirement?.name, {
      id: 'requirement',
      header: t('table.headers.entry-requirement'),
      enableSorting: false,
      cell: ({ cell, row }) => (
        <Button
          variant="blank"
          onClick={() => {
            open();
            setCurrentRequirement(row.original.requirement);
          }}
        >
          {cell.getValue()}
        </Button>
      ),
    }),

    columnHelper.display({
      id: 'actions',
      header: () => <span className="visually-hidden">{t('table.headers.actions')}</span>,
      cell: ({ row }) =>
        userCanEditTopicSet &&
        !academicYearDetails.isArchived && (
          <RowActions>
            <EditTopic academicYear={academicYear} topic={row.original} departmentId={departmentId} />
            <RemoveTopic topic={row.original} isDisabled={!topics.length} />
          </RowActions>
        ),
      meta: { tableCellProps: { hasButton: true } },
    }),
  ];

  return (
    <>
      <DataTable
        columns={columns}
        data={topics}
        initialState={initialState}
        enableColumnFilters={false}
        tableId={TABLE_STORAGE_KEYS.TOPICS_TABLE}
      />

      {currentRequirement && (
        <Drawer
          isOpen={isOpen}
          onClose={() => {
            close();
            setCurrentRequirement(null);
          }}
          title={currentRequirement?.name}
        >
          <EntryRequirementDrawer requirementId={currentRequirement.id} />
        </Drawer>
      )}
    </>
  );
}
