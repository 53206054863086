import { useTranslation } from 'react-i18next';

import { MissingColumn } from 'types/__generated__';

export function useGetReadableMissingColumn(keyPrefix = 'import-column') {
  const { t } = useTranslation('common', { keyPrefix });

  return function (missingColumn: string) {
    switch (missingColumn) {
      case MissingColumn.CatalogNumber:
        return t('catalog-number');

      case MissingColumn.CreditLimitException:
        return t('credit-limit-exception');

      case MissingColumn.GroupCapacity:
        return t('group-capacity');

      case MissingColumn.GroupCode:
        return t('group-code');

      case MissingColumn.StudentId:
        return t('student-id');

      case MissingColumn.Topic:
        return t('topic');

      default:
        return missingColumn;
    }
  };
}
