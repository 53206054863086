import { gql } from '@apollo/client';

import { REQUIREMENTS_FRAGMENT } from 'graphql/fragments/requirementsFragment';
import { STUDENT_FRAGMENT } from 'graphql/fragments/studentFragment';

export const REGISTERED_COURSE_PURSUIT_FRAGMENT = gql`
  fragment registeredCoursePursuit on CoursePursuit {
    id
    registrationDate
    student {
      ...student
    }
    studentGroup {
      id
      code
      name
    }
    requirementsCheck {
      ...requirements
    }
  }
  ${STUDENT_FRAGMENT}
  ${REQUIREMENTS_FRAGMENT}
`;
