import { useLazyQuery } from '@apollo/client';
import { useEffect } from 'react';
import { AppStatusBar } from '@uva-glass/component-library';

import type { ReactNode } from 'react';

import { MaintenanceMessageSeverity, type GetMaintenanceMessagesQuery } from 'types/__generated__';
import { GET_MAINTENANCE_MESSAGES } from 'graphql/queries/getMaintenanceMessages';

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const POLL_INTERVAL = 5 * 1000 * 60; // 5 minutes

interface AppStatusProps {
  children: ReactNode;
}

export function AppStatus({ children }: AppStatusProps) {
  const [query, { data }] = useLazyQuery<GetMaintenanceMessagesQuery>(GET_MAINTENANCE_MESSAGES, {
    pollInterval: POLL_INTERVAL,
  });

  useEffect(() => {
    async function asyncQuery() {
      try {
        await query();
      } catch (exception) {
        const error = exception as DOMException;

        if (error.name !== 'AbortError') {
          throw error;
        }
      }
    }

    asyncQuery();
  }, [query]);

  // severityMap needed for type-checking
  const severityMap = {
    [MaintenanceMessageSeverity.Error]: 'error',
    [MaintenanceMessageSeverity.Info]: 'info',
    [MaintenanceMessageSeverity.Warning]: 'warning',
  } as const;

  return data ? (
    <>
      {data.maintenanceMessages.map(({ message, severity }, key) => (
        <AppStatusBar severity={severityMap[severity]} message={message} key={key} />
      ))}
      {children}
    </>
  ) : null;
}
