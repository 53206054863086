import { createContext, useContext, useEffect, useState } from 'react';

import type { AuthUser } from 'oidc-clients-ts';
import type { ReactNode } from 'react';

import { events, getUser, logout } from 'services/authService';

const AuthContext = createContext<AuthUser | null>(null);

function isUserAuthenticated(user: AuthUser | null) {
  if (user === null) return false;

  return Boolean(user.access_token) && !user.expired;
}

interface Props {
  children: ReactNode;
}

export function AuthProvider({ children }: Props) {
  const [isReady, setIsReady] = useState(false);
  const [user, setUser] = useState<AuthUser | null>(null);

  useEffect(() => {
    async function asyncLoadUser() {
      const [existingUser] = await getUser();

      if (isUserAuthenticated(existingUser)) {
        setUser(existingUser);
      }
      setIsReady(true);
    }

    events.addAccessTokenExpiring(logout);
    events.addAccessTokenExpired(logout);
    events.addUserLoaded((newUser) => {
      if (isUserAuthenticated(newUser)) {
        setUser(newUser);
      }
    });
    events.addUserUnloaded(() => {
      setUser(null);
    });

    asyncLoadUser();
  }, []);

  if (!isReady) return null;

  return <AuthContext.Provider value={user}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  const context = useContext(AuthContext);

  if (context === undefined) throw new Error('useAuth can only be used in an AuthProvider');

  return context;
}
