import { useMutation } from '@apollo/client';

import type {
  GetUsersQuery,
  GetUserAuthorisationsQueryVariables,
  GetUserAuthorisationsQuery,
  LiftSuspensionMutation,
  LiftSuspensionMutationVariables,
} from 'types/__generated__';

import { GET_USERS } from 'graphql/queries/getUsers';
import { GET_USER_AUTHORISATIONS } from 'graphql/queries/getUserAuthorisations';
import { LIFT_SUSPENSION } from 'graphql/mutations/liftSuspension';
import { MutationStatus, UserStatus } from 'types/__generated__';

export const useLiftSuspension = (username: string) =>
  useMutation<LiftSuspensionMutation, LiftSuspensionMutationVariables>(LIFT_SUSPENSION, {
    update(cache, { data }) {
      if (data?.marblesLiftSuspension.mutationStatus !== MutationStatus.Success) return;

      cache.updateQuery<GetUsersQuery>({ query: GET_USERS }, (cached) => {
        if (!cached) return undefined;

        const marblesUsers = cached.marblesUsers.map((user) => {
          if (user.username === username) {
            return {
              ...user,
              status: UserStatus.SuspensionLifted,
            };
          }
          return user;
        });
        return { marblesUsers };
      });

      cache.updateQuery<GetUserAuthorisationsQuery, GetUserAuthorisationsQueryVariables>(
        {
          query: GET_USER_AUTHORISATIONS,
          variables: { username: username },
        },
        (cached) =>
          cached?.marblesUser
            ? {
                marblesUser: {
                  ...cached.marblesUser,
                  status: UserStatus.SuspensionLifted,
                },
              }
            : undefined
      );
    },
  });
