import { useQuery } from '@apollo/client';
import { useOverlayTriggerState } from '@react-stately/overlays';
import {
  Button,
  Icon,
  IconButton,
  Label,
  InputField,
  FeedbackBox,
  ModalDialog,
  ButtonGroup,
  FormField,
} from '@uva-glass/component-library';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { ChangeEvent, FormEvent } from 'react';
import type {
  GetRequirementsByDepartmentIdQuery,
  GetRequirementsByDepartmentIdQueryVariables,
  GetTopicsByTopicSetIdQuery,
} from 'types/__generated__';

import { useUpdateTopic } from 'App/TopicSets/TopicSet/TopicsTable/hooks/useUpdateTopic';
import { Select } from 'components/Select/Select';
import { GET_REQUIREMENTS_BY_DEPARTMENT_ID } from 'graphql/queries/getRequirementsByDepartmentId';
import { useGetReadableMutationStatus } from 'hooks/useGetReadableMutationStatus';
import { MutationStatus } from 'types/__generated__';
import { alphabeticalSortBy } from 'util/alphabeticalSortBy';

type Props = Omit<GetRequirementsByDepartmentIdQueryVariables, 'includeParentDepartments'> & {
  topic: NonNullable<GetTopicsByTopicSetIdQuery['topicSet']>['topics'][number];
};

const title_NL_INPUT = 'title-nl-input';
const title_NL_ERROR = 'title-nl-error';
const title_EN_INPUT = 'title-en-input';
const title_EN_ERROR = 'title-en-error';
const REQUIREMENT_SELECT = 'requirement-select';

export function EditTopic({ academicYear, departmentId, topic }: Props) {
  const { t } = useTranslation('topic-sets', { keyPrefix: 'edit-topic' });

  const { open, close, isOpen } = useOverlayTriggerState({});
  const [error, setError] = useState<string>();
  const [titleNL, setTitleNL] = useState<string>(topic.title.NL);
  const [titleEN, setTitleEN] = useState<string>(topic.title.EN);
  const [titleNLError, setTitleNLError] = useState<string>();
  const [titleENError, setTitleENError] = useState<string>();
  const [requirementId, setRequirementId] = useState<string | undefined>(topic.requirement?.id);

  const query = useQuery<GetRequirementsByDepartmentIdQuery, GetRequirementsByDepartmentIdQueryVariables>(
    GET_REQUIREMENTS_BY_DEPARTMENT_ID,
    { variables: { academicYear, departmentId, includeParentDepartments: true } }
  );
  const [mutate, mutation] = useUpdateTopic();
  const readableMutationStatus = useGetReadableMutationStatus();

  function resetAndClose() {
    setTitleNL(topic.title.NL);
    setTitleEN(topic.title.EN);
    setRequirementId(topic.requirement?.id);
    setError(undefined);
    setTitleNLError(undefined);
    setTitleENError(undefined);
    close();
  }

  function onInputNLChange(event: ChangeEvent<HTMLInputElement>) {
    setTitleNL(event.target.value);
    setTitleNLError(undefined);
  }

  function onInputENChange(event: ChangeEvent<HTMLInputElement>) {
    setTitleEN(event.target.value);
    setTitleENError(undefined);
  }

  function onSelectChange(event: ChangeEvent<HTMLSelectElement>) {
    event.target.value !== '' ? setRequirementId(event.target.value) : setRequirementId(undefined);
  }

  function onSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (!titleNL || titleNL.trim() === '' || !titleEN || titleEN.trim() === '') {
      if (!titleNL || titleNL.trim() === '') {
        setTitleNLError(t('modal.title-error'));
      }

      if (!titleEN || titleEN.trim() === '') {
        setTitleENError(t('modal.title-error'));
      }

      return;
    }

    mutate({
      variables: { topicId: topic.id, titleNL, titleEN, requirementNodeId: requirementId },
      onCompleted(data) {
        const { mutationStatus } = data.updateTopic;

        switch (mutationStatus) {
          case MutationStatus.Success:
          case MutationStatus.TopicNotFound:
            resetAndClose();
            return;

          case MutationStatus.RequirementNotFound:
          case MutationStatus.RequirementNotAvailable:
            setRequirementId(topic.requirement?.id);
            setError(readableMutationStatus(mutationStatus));
            return;

          default:
            setError(readableMutationStatus(mutationStatus));
            break;
        }
      },
      onError() {
        setError(readableMutationStatus('unknown'));
      },
    });
  }

  if (!query.data?.requirements) return null;

  const { requirements } = query.data;

  return (
    <>
      <IconButton aria-label={t('trigger')} onClick={open}>
        <Icon name="PencilSquare" />
      </IconButton>

      <ModalDialog
        isOpen={isOpen}
        title={t('modal.title')}
        onClose={resetAndClose}
        onSubmit={onSubmit}
        buttons={
          <ButtonGroup reversed>
            <Button variant="primary" type="submit" disabled={mutation.loading}>
              {t('modal.buttons.save')}
            </Button>
            <Button variant="secondary" onClick={resetAndClose} disabled={mutation.loading}>
              {t('modal.buttons.cancel')}
            </Button>
          </ButtonGroup>
        }
      >
        <FormField outerSpace>
          <Label htmlFor={title_NL_INPUT}>{t('modal.title-nl-label')}</Label>
          <InputField
            id={title_NL_INPUT}
            value={titleNL}
            onChange={onInputNLChange}
            autoComplete="off"
            aria-describedby={titleNLError ? title_NL_ERROR : undefined}
          />
          {titleNLError && <FeedbackBox level="error" feedback={titleNLError} />}
        </FormField>
        <FormField outerSpace>
          <Label htmlFor={title_EN_INPUT}>{t('modal.title-en-label')}</Label>
          <InputField
            id={title_EN_INPUT}
            value={titleEN}
            onChange={onInputENChange}
            autoComplete="off"
            aria-describedby={titleENError ? title_EN_ERROR : undefined}
          />
          {titleENError && <FeedbackBox level="error" feedback={titleENError} />}
        </FormField>
        <FormField outerSpace>
          <Label htmlFor={REQUIREMENT_SELECT}>{t('modal.link-requirement')}</Label>
          <Select id={REQUIREMENT_SELECT} value={requirementId} onChange={onSelectChange}>
            <Select.Option />
            {[...requirements].sort(alphabeticalSortBy('name')).map((requirement) => (
              <Select.Option key={requirement.id} value={requirement.id}>
                {requirement.name}
              </Select.Option>
            ))}
          </Select>
        </FormField>
        {error && <FeedbackBox level="error" feedback={error} />}
      </ModalDialog>
    </>
  );
}
