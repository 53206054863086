import { gql } from '@apollo/client';

import { STUDENT_SET_FRAGMENT } from 'graphql/fragments/studentSetFragment';

export const GET_STUDENT_SET_BY_CODE = gql`
  query getStudentSetByCode($code: String!) {
    studentSet(code: $code) {
      ...studentSet
    }
  }
  ${STUDENT_SET_FRAGMENT}
`;
