import { useOverlayTriggerState } from '@react-stately/overlays';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonGroup, FeedbackBox, Icon, ModalDialog, FormField } from '@uva-glass/component-library';

import type { ChangeEvent, FormEvent } from 'react';

import { useCreatePendingCoursePursuit } from './hooks/useCreatePendingCoursePursuit';

import { RadioSelectAlternator } from 'components/RadioSelectAlternator/RadioSelectAlternator';
import { useCurrentLanguage } from 'hooks/useCurrentLanguage';
import { useGetCourseOffering } from 'hooks/useGetCourseOffering';
import { useGetReadableMutationStatus } from 'hooks/useGetReadableMutationStatus';
import { MutationStatus } from 'types/__generated__';
import { FetchStudent } from 'App/CourseOffering/FetchStudent/FetchStudent';

const STUDENT_NUMBER_ERROR_ID = 'student-number-error';
const TOPIC_ERROR_ID = 'topic-error';

export function CreatePendingCoursePursuitModal() {
  const { t } = useTranslation('course-offering', { keyPrefix: 'create-pending-course-pursuit-modal' });
  const currentLanguage = useCurrentLanguage();
  const { marblesCourseOffering } = useGetCourseOffering();
  const { id: courseOfferingId, topicSet } = marblesCourseOffering;
  const academicYear = marblesCourseOffering.academicYear.id;

  const [mutate, mutation] = useCreatePendingCoursePursuit(academicYear);
  const { close, isOpen, open } = useOverlayTriggerState({});
  const readableMutationStatus = useGetReadableMutationStatus();

  const [studentNumber, setStudentNumber] = useState('');
  const [mutationError, setMutationError] = useState('');
  const [topicId, setTopicId] = useState<string | undefined>();
  const [topicError, setTopicError] = useState('');

  function resetAndClose() {
    setStudentNumber('');
    setMutationError('');
    setTopicId(undefined);
    setTopicError('');
    close();
  }

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (studentNumber.trim() === '') {
      setMutationError(t('modal.errors.no-value'));
      return;
    }

    if (topicSet && !topicId) {
      setTopicError(t('modal.errors.choose-a-topic', { topic: topicSet.title[currentLanguage] }));
      return;
    }

    const { data } = await mutate({
      variables: { courseOfferingId, studentNumber: studentNumber.trim(), topicId: topicId || undefined, academicYear },
    });

    const mutationStatus = data?.administrativelySelectCourseForRegistration.mutationStatus;

    if (mutationStatus === MutationStatus.Success) {
      resetAndClose();
      return;
    }

    setMutationError(readableMutationStatus(mutationStatus || 'unknown', { student: studentNumber.trim() }));
  }

  function onChangeInput(number: string) {
    setMutationError('');
    setStudentNumber(number);
  }

  function onChangeTopic(event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
    setTopicError('');
    setTopicId(event.target.value);
  }

  return (
    <>
      <Button variant="primary" onClick={open}>
        <Icon name="Plus" size={20} />
        {t('trigger')}
      </Button>
      <ModalDialog
        title={t('modal.title')}
        onSubmit={handleSubmit}
        buttons={
          <ButtonGroup reversed>
            <Button variant="primary" type="submit" disabled={mutation.loading}>
              {t('modal.buttons.confirm')}
            </Button>
            <Button onClick={resetAndClose} variant="secondary">
              {t('modal.buttons.cancel')}
            </Button>
          </ButtonGroup>
        }
        isOpen={isOpen}
        onClose={resetAndClose}
      >
        <p>{t('modal.description')}</p>
        <FormField outerSpace>
          <FetchStudent
            fieldErrorId={STUDENT_NUMBER_ERROR_ID}
            label={t('modal.student-number')}
            onSetStudentNumber={onChangeInput}
          />

          {mutationError && <FeedbackBox id={STUDENT_NUMBER_ERROR_ID} level="error" feedback={mutationError} />}
        </FormField>

        {topicSet && (
          <FormField outerSpace>
            <RadioSelectAlternator
              label={topicSet.title[currentLanguage]}
              onChange={onChangeTopic}
              id={topicSet.id}
              options={topicSet.topics
                .map(({ id, title }) => ({ id, label: title[currentLanguage], value: id }))
                .sort((topic1, topic2) => topic1.label.localeCompare(topic2.label))}
              aria-describedby={topicError ? TOPIC_ERROR_ID : undefined}
              inline={false}
            />
            {topicError && <FeedbackBox id={TOPIC_ERROR_ID} level="error" feedback={topicError} />}
          </FormField>
        )}
      </ModalDialog>
    </>
  );
}
