import type { ReactElement } from 'react';

import styles from './DualTextCell.module.css';

interface Props {
  upperText: string | ReactElement;
  lowerText: string | ReactElement;
}

export function DualTextCell({ upperText, lowerText }: Props) {
  return (
    <article className={styles['dual-text-cell__container']}>
      <span>{upperText}</span>
      <span className={styles['dual-text-cell__lower-text']}>{lowerText}</span>
    </article>
  );
}
