import { useMutation } from '@apollo/client';

import type {
  GetGroupsByCourseOfferingIdQuery,
  GetGroupsByCourseOfferingIdQueryVariables,
  ProcessGroupSwapMutation,
  ProcessGroupSwapMutationVariables,
} from 'types/__generated__';

import { GET_GROUPS_BY_COURSE_OFFERING_ID } from 'graphql/queries/getGroupsByCourseOfferingId';
import { MutationStatus } from 'types/__generated__';
import { PROCESS_GROUP_SWAP } from 'graphql/mutations/processGroupSwap';

export const useProcessGroupSwap = () =>
  useMutation<ProcessGroupSwapMutation, ProcessGroupSwapMutationVariables>(PROCESS_GROUP_SWAP, {
    update(cache, { data }) {
      if (data?.processGroupSwap.mutationStatus !== MutationStatus.Success) return;

      const { groupSwapRequest, studentGroups } = data.processGroupSwap;

      if (!groupSwapRequest) {
        throw new Error('No "groupSwapRequest" in payload');
      }

      if (!studentGroups) {
        throw new Error('No "studentGroups" in payload');
      }

      cache.updateQuery<GetGroupsByCourseOfferingIdQuery, GetGroupsByCourseOfferingIdQueryVariables>(
        {
          query: GET_GROUPS_BY_COURSE_OFFERING_ID,
          variables: { courseOfferingId: groupSwapRequest.courseOfferingId },
        },
        (cached) =>
          cached?.marblesCourseOffering
            ? {
                marblesCourseOffering: {
                  ...cached.marblesCourseOffering,
                  studentGroups: [...studentGroups],
                },
              }
            : undefined
      );
    },
  });
