import { useOverlayTriggerState } from '@react-stately/overlays';
import { useTranslation } from 'react-i18next';
import { IconButton, Icon, ButtonGroup } from '@uva-glass/component-library';

import type { RequiredStudentSet, RuleFragment } from 'types/__generated__';

import { EditRequiredStudentSet } from './EditRequiredStudentSet/EditRequiredStudentSet';
import styles from './StudentSet.module.css';

import { useCurrentLanguage } from 'hooks/useCurrentLanguage';

interface Props {
  ruleId: RuleFragment['id'];
  requiredStudentSet: RequiredStudentSet;
  canEdit: boolean;
}

export function StudentSet({ ruleId, requiredStudentSet, canEdit }: Props) {
  const { t } = useTranslation('requirement-rules', { keyPrefix: 'edit-required-student-set' });
  const currentLanguage = useCurrentLanguage();
  const { open, close, isOpen } = useOverlayTriggerState({});

  if (!requiredStudentSet.studentSet) return null;

  return (
    <div className={styles['student-set']}>
      <div>
        {requiredStudentSet.studentSet.code} - {requiredStudentSet?.studentSet?.title[currentLanguage]}
      </div>

      {canEdit && (
        <ButtonGroup>
          <IconButton aria-label={t('trigger')} onClick={open}>
            <Icon name="PencilSquare" />
          </IconButton>

          {isOpen && (
            <EditRequiredStudentSet
              studentSetInRequirement={requiredStudentSet.studentSet}
              ruleId={ruleId}
              nodeId={requiredStudentSet.id}
              close={close}
            />
          )}
        </ButtonGroup>
      )}
    </div>
  );
}
