import { Trans, useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useOverlayTriggerState } from '@react-stately/overlays';
import { Button, IconButton, Icon, FeedbackBox, ModalDialog, ButtonGroup } from '@uva-glass/component-library';

import type { FormEvent } from 'react';
import type { GetRulesByDepartmentIdQuery } from 'types/__generated__';

import { MutationStatus } from 'types/__generated__';
import { useGetReadableMutationStatus } from 'hooks/useGetReadableMutationStatus';
import { useRemoveRule } from 'hooks/useRemoveRule';

interface Props {
  isDisabled: boolean;
  rule: GetRulesByDepartmentIdQuery['rules'][number] | null;
}

export function RemoveRule({ rule, isDisabled }: Props) {
  const { t } = useTranslation('requirement-rules', { keyPrefix: 'remove-rule' });
  const { open, close, isOpen } = useOverlayTriggerState({});

  const [error, setError] = useState<string>();
  const readableMutationStatus = useGetReadableMutationStatus();
  const [mutate, mutation] = useRemoveRule();

  function onCompleted() {
    setError(undefined);
    close();
  }

  function onSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (!rule) return;

    mutate({
      variables: { ruleId: rule.id },
      onCompleted(data) {
        const { mutationStatus } = data.removeRule;

        if (mutationStatus === MutationStatus.Success) {
          onCompleted();
          return;
        }

        setError(readableMutationStatus(mutationStatus));
      },
      onError() {
        setError(readableMutationStatus('unknown'));
      },
    });
  }

  return (
    <>
      <IconButton aria-label={t('trigger')} variant="destructive" onClick={open} disabled={isDisabled}>
        <Icon name={isDisabled ? 'TrashStrikethrough' : 'Trash'} />
      </IconButton>

      <ModalDialog
        title={t('modal.title')}
        isOpen={isOpen}
        onClose={onCompleted}
        onSubmit={onSubmit}
        buttons={
          <ButtonGroup>
            <Button onClick={onCompleted} variant="secondary">
              {t('modal.buttons.cancel')}
            </Button>
            <Button variant="destructive-primary" disabled={mutation.loading} type="submit">
              {t('modal.buttons.remove')}
            </Button>
          </ButtonGroup>
        }
      >
        <p>
          <Trans t={t} i18nKey="modal.description" values={{ name: rule?.name }} components={{ strong: <strong /> }} />
        </p>
        {error && <FeedbackBox level="error" feedback={error} />}
      </ModalDialog>
    </>
  );
}
