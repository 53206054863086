import { gql } from '@apollo/client';

import { COURSE_INFO_FRAGMENT } from './courseInfoFragment';

export const REQUIRED_COURSES_FRAGMENT = gql`
  fragment requiredCourses on RequiredCourses {
    id
    courses {
      ...courseInfo
    }
    requirement
    requiredCourses
    requiredCredits
  }
  ${COURSE_INFO_FRAGMENT}
`;
