import { useTranslation } from 'react-i18next';

import { UserStatus } from 'types/__generated__';

export function useGetReadableUserStatus(keyPrefix = 'user-status') {
  const { t } = useTranslation('common', { keyPrefix });

  return function (userStatus: string) {
    switch (userStatus) {
      case UserStatus.Active:
        return t('active');

      case UserStatus.Suspended:
        return t('suspended');

      case UserStatus.SuspensionLifted:
        return t('suspension-lifted');

      default:
        return userStatus;
    }
  };
}
