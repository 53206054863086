import { useCallback, useEffect, useId, useRef, useState } from 'react';
import { clsx } from 'clsx';
import { Icon } from '@uva-glass/component-library';

import type { KeyboardEvent } from 'react';

import styles from './Listbox.module.css';

export type ListboxOption = {
  label: string;
  value: string;
};

interface Props {
  options: ListboxOption[];
  onSelect: (option: ListboxOption) => void;
  label: string;
  defaultOptionValue?: string;
  noBorder?: boolean;
  triggerLabelValue?: boolean;
  optionPositionRight?: boolean;
  customLabel?: boolean;
}

export const Listbox = ({
  options,
  onSelect,
  label,
  defaultOptionValue,
  noBorder = false,
  triggerLabelValue = false,
  optionPositionRight = false,
  customLabel = false,
}: Props) => {
  const listboxRef = useRef<HTMLUListElement>(null);
  const listboxTriggerRef = useRef<HTMLButtonElement>(null);
  const listboxOptionRefs = useRef<Array<HTMLLIElement | null>>([]);
  const lastMouseEventRef = useRef<{ clientX: number; clientY: number } | null>(null);
  const listboxId = useId();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<ListboxOption | undefined>(
    options.find((option) => option.value === defaultOptionValue)
  );
  const [activeIndex, setActiveIndex] = useState(
    options.findIndex((option) => option.value === defaultOptionValue) || -1
  );

  const onClickOutside = useCallback(
    (event: MouseEvent) => {
      const { target } = event;

      if (!target || !listboxRef.current) return;

      if (!listboxRef.current.contains(target as Node)) {
        setActiveIndex(options.findIndex((option) => option.value === selectedOption?.value));
        setIsOpen(false);
      }
    },
    [options, selectedOption]
  );

  const onScrollOutside = useCallback(() => {
    if (!listboxRef.current) return;

    const { clientX, clientY } = lastMouseEventRef.current || { clientX: -1, clientY: -1 };
    const isMouseOverListbox = listboxRef.current.contains(document.elementFromPoint(clientX, clientY));
    if (!isMouseOverListbox) {
      setIsOpen(false);
    }
  }, []);

  useEffect(() => {
    if (!isOpen) return;

    const mouseMoveHandler = (event: MouseEvent) => {
      lastMouseEventRef.current = { clientX: event.clientX, clientY: event.clientY };
    };

    document.addEventListener('mousemove', mouseMoveHandler);
    document.addEventListener('click', onClickOutside, true);
    document.addEventListener('scroll', onScrollOutside, true);

    listboxOptionRefs.current[activeIndex]?.focus();

    return () => {
      document.removeEventListener('mousemove', mouseMoveHandler);
      document.removeEventListener('click', onClickOutside, true);
      document.removeEventListener('scroll', onScrollOutside, true);
    };
  }, [onClickOutside, onScrollOutside, isOpen, activeIndex]);

  const toggleListbox = () => {
    setActiveIndex(options.findIndex((option) => option.value === selectedOption?.value));
    setIsOpen(!isOpen);
  };

  const handleSelectOption = (option: ListboxOption) => {
    setSelectedOption(option);
    setIsOpen(false);
    onSelect(option);
    listboxTriggerRef.current?.focus();
  };

  const onTriggerKeyDown = (event: KeyboardEvent<HTMLButtonElement>) => {
    switch (event.key) {
      case 'Enter':
        event.preventDefault();
        if (!isOpen) {
          setIsOpen(true);
          listboxRef.current?.focus();
          return;
        }
        break;
      case 'Escape':
        if (isOpen) {
          event.stopPropagation();
          setActiveIndex(options.findIndex((option) => option.value === selectedOption?.value));
          setIsOpen(false);
        }
        break;
    }
  };

  const onOptionKeyDown = (event: KeyboardEvent<HTMLLIElement>) => {
    switch (event.key) {
      case 'Tab':
        event.preventDefault();
        break;
      case 'ArrowDown':
      case 'ArrowUp':
        event.preventDefault();
        if (!isOpen) {
          return;
        }
        if (event.key === 'ArrowDown' && activeIndex < options.length - 1) {
          setActiveIndex((previousIndex) => previousIndex + 1);
        }
        if (event.key === 'ArrowUp' && activeIndex > 0) {
          setActiveIndex((previousIndex) => previousIndex - 1);
        }
        break;
      case 'Enter':
        event.preventDefault();
        if (options?.length && activeIndex > -1) {
          handleSelectOption(options[activeIndex]);
        }
        break;
      case 'Escape':
        if (isOpen) {
          event.stopPropagation();
          setActiveIndex(options.findIndex((option) => option.value === selectedOption?.value));
          setIsOpen(false);
          listboxTriggerRef.current?.focus();
        }
        break;
    }
  };

  return (
    <div className={styles['listbox-container']}>
      <button
        className={clsx(styles['listbox-trigger'], { [styles['listbox-trigger--noborder']]: noBorder })}
        ref={listboxTriggerRef}
        type="button"
        onClick={toggleListbox}
        onKeyDown={onTriggerKeyDown}
        aria-expanded={isOpen}
        aria-haspopup="listbox"
        aria-label={customLabel ? `${label}` : `${selectedOption && selectedOption.label} ${label}`}
        aria-controls={listboxId}
      >
        {triggerLabelValue ? selectedOption && selectedOption.value : selectedOption && selectedOption.label}
        <Icon className={styles['listbox-trigger-icon']} name="CheveronDown" size={16} />
      </button>
      {isOpen && (
        <ul
          className={clsx(styles['listbox'], { [styles['listbox--align-right']]: optionPositionRight })}
          ref={listboxRef}
          id={listboxId}
          role="listbox"
          tabIndex={-1}
        >
          {options.map((option, index) => (
            <li
              className={clsx(styles['listbox-option'], {
                [styles['listbox-option--active']]: index === activeIndex,
              })}
              ref={(li) => {
                listboxOptionRefs.current[index] = li;
              }}
              key={option.value}
              role="option"
              onClick={() => handleSelectOption(option)}
              onKeyDown={onOptionKeyDown}
              onPointerEnter={() => setActiveIndex(index)}
              onPointerMove={() => setActiveIndex(index)}
              onPointerLeave={() => setActiveIndex(-1)}
              aria-selected={selectedOption?.value === option.value}
              tabIndex={0}
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
