import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { NewTopicSet } from './NewTopicSet';
import { TopicSetsTable } from './TopicSetsTable/TopicSetsTable';

import { ArchivedYearNotification } from 'App/shared/ArchivedYearNotification/ArchivedYearNotification';
import { Page } from 'components/Page/Page';
import { useGetAcademicYearDetails } from 'hooks/useGetAcademicYearDetails';
import { useCurrentLanguage } from 'hooks/useCurrentLanguage';
import { useGetDepartmentDetails } from 'hooks/useGetDepartmentDetails';
import { PageMultiSubTitle } from 'App/shared/PageMultiSubTitle/PageMultiSubTitle';

export function TopicSets() {
  const { t } = useTranslation('topic-sets', { keyPrefix: 'topic-sets' });
  const currentLanguage = useCurrentLanguage();
  const { academicYear } = useParams();
  const selectedAcademicYear = useGetAcademicYearDetails(Number(academicYear));
  const selectedDepartment = useGetDepartmentDetails();

  return (
    <Page
      title={t('title')}
      subTitle={<PageMultiSubTitle values={[selectedAcademicYear.name, selectedDepartment.name[currentLanguage]]} />}
      notification={<ArchivedYearNotification academicYear={selectedAcademicYear} />}
      actions={
        !selectedAcademicYear.isArchived && (
          <NewTopicSet departmentId={selectedDepartment.id} academicYear={selectedAcademicYear.id} />
        )
      }
    >
      <section>
        <TopicSetsTable departmentId={selectedDepartment.id} academicYear={selectedAcademicYear.id} />
      </section>
    </Page>
  );
}
