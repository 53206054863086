import { gql } from '@apollo/client';

export const GET_COURSE_OFFERING_REQUIREMENT_CAPACITY = gql`
  query getCourseOfferingRequirementCapacity($courseOfferingId: String!) {
    courseOfferingRequirementCapacity(courseOfferingId: $courseOfferingId) {
      allocationRound
      description {
        NL
        EN
      }
      pending
      registered
      priority
      priorityLimit
      includeInRetry
      ruleId
    }
  }
`;
