import * as routes from 'routes';

const academicYearParamName = 'academicYear';
const departmentIdParamName = 'departmentId';

const academicYearParamRegExp = new RegExp(`:${academicYearParamName}`);
const departmentIdParamRegExp = new RegExp(`:${departmentIdParamName}`);

const filteredRoutes = Object.entries(routes).reduce((dest, [_, route]) => {
  if (route.startsWith('/')) return dest;

  // If the route doesn't contain an academic year param, no need to do anything
  if (!academicYearParamRegExp.test(route)) return dest;

  const pathSegment = `${routes.COURSE_REGISTRATION}/${route}`;
  dest.push(pathSegment);

  return dest;
}, [] as string[]);

export const getExpectedFilterParams = (leadingPathSegment: string) => {
  for (const route of filteredRoutes) {
    if (!route.startsWith(leadingPathSegment)) continue;

    // Always expect an academic year, because that is defined by the filteredRoutes
    const expectedFilterParams = [academicYearParamName];
    // Some filterable URLs do not require a department id param
    if (departmentIdParamRegExp.test(route)) expectedFilterParams.push(departmentIdParamName);

    return expectedFilterParams;
  }

  return null;
};
