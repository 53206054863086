import { useMutation } from '@apollo/client';

import type { StoreObject } from '@apollo/client';
import type { SwitchStudentGroupMutation, SwitchStudentGroupMutationVariables } from 'types/__generated__';

import { SWITCH_STUDENT_GROUP } from 'graphql/mutations/switchStudentGroup';
import { MutationStatus } from 'types/__generated__';
import { GET_PURSUITS_BY_STUDENT_ID } from 'graphql/queries/getPursuitsByStudentId';

export const useSwitchStudentGroup = (studentId?: string) =>
  useMutation<SwitchStudentGroupMutation, SwitchStudentGroupMutationVariables>(SWITCH_STUDENT_GROUP, {
    update(cache, { data }) {
      if (data?.switchStudentGroup.mutationStatus !== MutationStatus.Success) return;

      const { coursePursuitId, studentGroupId } = data.switchStudentGroup;
      const coursePursuit: StoreObject = { __typename: 'CoursePursuit', id: coursePursuitId };

      cache.modify({
        id: cache.identify(coursePursuit),
        fields: {
          studentGroup(_, { toReference }) {
            return toReference({ __typename: 'StudentGroup', id: studentGroupId }) ?? null;
          },
        },
      });
    },
    refetchQueries: ({ data }) => {
      if (data && studentId) {
        return [{ query: GET_PURSUITS_BY_STUDENT_ID, variables: { studentId } }];
      }
      return [];
    },
  });
