import { gql } from '@apollo/client';

export const SET_TOPIC_BY_COURSE_PURSUIT_ID = gql`
  mutation setTopicByCoursePursuitId($coursePursuitId: String!, $topicId: String) {
    setCoursePursuitTopic(input: { pursuitId: $coursePursuitId, topicId: $topicId }) {
      mutationStatus
      topicId
      coursePursuitId
    }
  }
`;
