import { useLazyQuery } from '@apollo/client';
import { useOverlayTriggerState } from '@react-stately/overlays';
import { Button, ButtonGroup, Icon, Label, ModalDialog, Spinner, FormField } from '@uva-glass/component-library';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { ChangeEvent, FormEvent } from 'react';
import type {
  GetRequirementsByDepartmentIdQuery,
  GetRequirementsByDepartmentIdQueryVariables,
  SetRequirementNodeByCourseOfferingIdMutationVariables,
} from 'types/__generated__';

import { Select } from 'components/Select/Select';
import { GET_REQUIREMENTS_BY_DEPARTMENT_ID } from 'graphql/queries/getRequirementsByDepartmentId';
import { useGetCourseOffering } from 'hooks/useGetCourseOffering';
import { useSetRequirementNodeByCourseOfferingId } from 'hooks/useSetRequirementNodeByCourseOfferingId';
import { alphabeticalSortBy } from 'util/alphabeticalSortBy';

type Props = Pick<SetRequirementNodeByCourseOfferingIdMutationVariables, 'requirementNodeId'> &
  Omit<GetRequirementsByDepartmentIdQueryVariables, 'includeParentDepartments'>;

const SELECT_ID = 'link-requirement-select-id';

export function LinkRequirement({ academicYear, requirementNodeId, departmentId }: Props) {
  const { t } = useTranslation('course-offering', { keyPrefix: 'link-requirement' });
  const { close, isOpen, open } = useOverlayTriggerState({});

  const { marblesCourseOffering } = useGetCourseOffering();
  const { id: courseOfferingId } = marblesCourseOffering;

  const [value, setValue] = useState(requirementNodeId ?? '');

  const [query, lazyQuery] = useLazyQuery<
    GetRequirementsByDepartmentIdQuery,
    GetRequirementsByDepartmentIdQueryVariables
  >(GET_REQUIREMENTS_BY_DEPARTMENT_ID);
  const [mutate, mutation] = useSetRequirementNodeByCourseOfferingId();

  useEffect(() => {
    if (!isOpen) return;

    query({
      variables: { academicYear, departmentId, includeParentDepartments: true },
      fetchPolicy: 'cache-and-network',
    });
  }, [academicYear, departmentId, isOpen, query]);

  const onSubmit = async (event: FormEvent) => {
    event.preventDefault();
    await mutate({ variables: { courseOfferingId, requirementNodeId: value || null } });
    close();
  };

  const onChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;

    setValue(value);
  };

  return (
    <>
      <Button onClick={open} variant="secondary">
        {!requirementNodeId && <Icon name="Link" size={20} />}
        {t(requirementNodeId ? 'trigger.change' : 'trigger.open')}
      </Button>
      <ModalDialog
        title={t('modal.title')}
        onSubmit={onSubmit}
        buttons={
          <ButtonGroup reversed>
            <Button type="submit" variant="primary" disabled={mutation.loading}>
              {t('modal.buttons.link')}
            </Button>
            <Button variant="secondary" onClick={close}>
              {t('modal.buttons.cancel')}
            </Button>
          </ButtonGroup>
        }
        isOpen={isOpen}
        onClose={close}
      >
        {lazyQuery.data?.requirements?.length ? (
          <FormField>
            <Label htmlFor={SELECT_ID}>{t('modal.label')}</Label>
            <Select id={SELECT_ID} onChange={onChange} value={value}>
              {requirementNodeId === undefined && <Select.Option value="" />}
              {/** add blank first item to avoid unintended default selection */}
              {[{ id: '', name: '' }, ...lazyQuery.data.requirements]
                .sort(alphabeticalSortBy('name'))
                .map(({ id, name }) => (
                  <Select.Option key={id} value={id}>
                    {name}
                  </Select.Option>
                ))}
            </Select>
          </FormField>
        ) : (
          <Spinner ariaValueText={t('modal.loading')} />
        )}
      </ModalDialog>
    </>
  );
}
