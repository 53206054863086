import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Card, Fieldset } from '@uva-glass/component-library';

import type { ChangeEvent } from 'react';
import type {
  GetCreditLimitExceptionByStudentIdQuery,
  GetCreditLimitExceptionByStudentIdQueryVariables,
} from 'types/__generated__';

import { useSetCreditLimitExceptionByStudentId } from './hooks/useSetCreditLimitExceptionByStudentId';

import { MutationStatus } from 'types/__generated__';
import { GET_CREDIT_LIMIT_EXCEPTION_BY_STUDENT_ID } from 'graphql/queries/getCreditLimitExceptionByStudentId';
import { ToggleSwitch } from 'components/ToggleSwitch/ToggleSwitch';

const DESCRIPTION_ID = 'credit-limit-exception-setting-description';

export function CreditLimitExceptionSetting() {
  const { studentId } = useParams<keyof GetCreditLimitExceptionByStudentIdQueryVariables>();

  if (!studentId) throw new Error('Missing studentId.');

  const { t } = useTranslation('student', { keyPrefix: 'credit-limit-exception-setting' });
  const query = useQuery<GetCreditLimitExceptionByStudentIdQuery, GetCreditLimitExceptionByStudentIdQueryVariables>(
    GET_CREDIT_LIMIT_EXCEPTION_BY_STUDENT_ID,
    { variables: { studentId } }
  );
  const [mutate, mutation] = useSetCreditLimitExceptionByStudentId();

  if (query.loading || !query.data?.student) return null;

  const checked = query.data.student.hasCreditLimitException;

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const hasCreditLimitException = event.target.checked;

    mutate({
      variables: { studentId, hasCreditLimitException },
      optimisticResponse: {
        setCreditLimitException: {
          mutationStatus: MutationStatus.Success,
          studentId,
          hasCreditLimitException,
        },
      },
    });
  };

  return (
    <Card>
      <Fieldset legend={t('legend')} description={t('description')} descriptionId={DESCRIPTION_ID}>
        <div>
          <ToggleSwitch
            onChange={onChange}
            checked={checked}
            loading={mutation.loading}
            label={t('label')}
            labelPosition="before"
            aria-describedby={DESCRIPTION_ID}
          />
        </div>
      </Fieldset>
    </Card>
  );
}
