import { useOverlayTriggerState } from '@react-stately/overlays';
import {
  Button,
  ButtonGroup,
  FeedbackBox,
  Icon,
  IconButton,
  Label,
  ModalDialog,
  FormField,
} from '@uva-glass/component-library';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { ChangeEvent } from 'react';
import type { RegisterStudentForCourseMutation, StudentFragment } from 'types/__generated__';

import { Select } from 'components/Select/Select';
import { useCurrentLanguage } from 'hooks/useCurrentLanguage';
import { useGetCourseOffering } from 'hooks/useGetCourseOffering';
import { useGetReadableMutationStatus } from 'hooks/useGetReadableMutationStatus';
import { useRegisterStudentForCourse } from 'hooks/useRegisterStudentForCourse';
import { MutationStatus } from 'types/__generated__';
import { getGroupUnavailabilityStatus } from 'util/getGroupUnavailabilityStatus';

const STUDENT_GROUP_ID = 'student-group-id';

export interface StudentAndCourseForRejection extends RegisterStudentForCourseMutation {
  student: StudentFragment;
}
interface Props {
  student: StudentFragment;
  courseInfoTitle?: string;
  topicId?: string | null;
  isDisabled: boolean;
}

export function TransferStudentToRegisteredModal({ student, courseInfoTitle, topicId, isDisabled }: Props) {
  const { t } = useTranslation('course-offering', { keyPrefix: 'transfer-student-to-registered' });
  const { close, isOpen, open } = useOverlayTriggerState({});
  const currentLanguage = useCurrentLanguage();
  const readableMutationStatus = useGetReadableMutationStatus();
  const { marblesCourseOffering } = useGetCourseOffering();
  const { studentGroups, id: courseOfferingId } = marblesCourseOffering;
  const [firstStudentGroup] = studentGroups;
  const academicYear = marblesCourseOffering.academicYear.id;
  const topicSetTitle = marblesCourseOffering.topicSet?.title[currentLanguage];

  const getStudentGroupTopicTitle = (group: typeof firstStudentGroup) =>
    group.topic?.title[currentLanguage]
      ? group.topic?.title[currentLanguage]
      : `${t('modal.no', { topicTitle: topicSetTitle || t('modal.topic') })}`;

  const groupStudentGroupsByTopicTitle = (groups: typeof studentGroups) =>
    groups.reduce<Record<string, typeof studentGroups>>((groupsByTopicTitle, studentGroup) => {
      const studentGroupTopicTitle = getStudentGroupTopicTitle(studentGroup);

      const studentGroupsByTopicTitle = groupsByTopicTitle[studentGroupTopicTitle] || [];

      studentGroupsByTopicTitle.push(studentGroup);
      return { ...groupsByTopicTitle, [studentGroupTopicTitle]: studentGroupsByTopicTitle };
    }, {});

  const studentGroupsByTopic = groupStudentGroupsByTopicTitle(studentGroups);
  const aplhabeticallySortedStudentGroupsByTopic = Object.keys(studentGroupsByTopic)
    .sort()
    .reduce<Record<keyof typeof studentGroupsByTopic, typeof studentGroups>>(
      (sortedGroups, topicTitle) => ({
        ...sortedGroups,
        [topicTitle]: studentGroupsByTopic[topicTitle],
      }),
      {}
    );

  function defaultStudentGroup() {
    const filteredStudentGroups = studentGroups.filter(({ topic }) => topic?.id === topicId);

    return filteredStudentGroups.length > 0 ? filteredStudentGroups[0].id : firstStudentGroup.id;
  }

  const [mutate, mutation] = useRegisterStudentForCourse(academicYear);
  const [mutationError, setMutationError] = useState('');
  const [studentGroupId, setStudentGroupId] = useState<string>(defaultStudentGroup);
  const [groupUnavailabilityStatus, setGroupUnavailabilityStatus] = useState(
    getGroupUnavailabilityStatus(firstStudentGroup?.id, studentGroups)
  );

  function resetAndClose() {
    setStudentGroupId(defaultStudentGroup);
    setGroupUnavailabilityStatus(getGroupUnavailabilityStatus(defaultStudentGroup(), studentGroups));
    setMutationError('');
    close();
  }

  function onChangeStudentGroupId(event: ChangeEvent<HTMLSelectElement>) {
    const selectedGroupId = event.target.value;
    setStudentGroupId(selectedGroupId);
    setGroupUnavailabilityStatus(getGroupUnavailabilityStatus(selectedGroupId, studentGroups));
  }

  function onClick() {
    mutate({
      variables: { studentId: student.studentNumber, courseOfferingId, studentGroupId, academicYear },
      onCompleted(data) {
        const { mutationStatus } = data.registerStudentForCourse;

        if (mutationStatus === MutationStatus.Success) {
          resetAndClose();
          return;
        }

        setMutationError(readableMutationStatus(mutationStatus, { student: student.studentNumber }));
      },
      onError(error) {
        if (error.networkError) {
          setMutationError(readableMutationStatus(MutationStatus.Error));
        } else if (error.graphQLErrors.length) {
          setMutationError(readableMutationStatus(MutationStatus.Corrupt));
        } else {
          setMutationError(readableMutationStatus('unknown'));
        }
      },
    });
  }

  return (
    <>
      <IconButton
        aria-label={t('trigger', { course: courseInfoTitle })}
        title={t('trigger', { course: courseInfoTitle })}
        onClick={open}
        disabled={isDisabled}
      >
        <Icon name="UserCheckFill" />
      </IconButton>
      <ModalDialog
        title={t('modal.title')}
        buttons={
          <ButtonGroup reversed>
            <Button variant="primary" disabled={mutation.loading} onClick={onClick}>
              {t('modal.buttons.confirm')}
            </Button>
            <Button variant="secondary" disabled={mutation.loading} onClick={resetAndClose}>
              {t('modal.buttons.cancel')}
            </Button>
          </ButtonGroup>
        }
        isOpen={isOpen}
        onClose={resetAndClose}
        isDismissable={!mutation.loading}
      >
        <p>{t('modal.description', student)}</p>

        <FormField outerSpace>
          <Label htmlFor={STUDENT_GROUP_ID}>{t('modal.student-group-label')}</Label>

          <Select id={STUDENT_GROUP_ID} onChange={onChangeStudentGroupId} value={studentGroupId}>
            {Object.keys(aplhabeticallySortedStudentGroupsByTopic).map((topicName) => (
              <Select.OptionGroup label={topicName} key={topicName}>
                {aplhabeticallySortedStudentGroupsByTopic[topicName].map(
                  ({ id, name, numberOfRegisteredStudents, capacity }) => (
                    <Select.Option value={id} key={id}>
                      {name} — ({numberOfRegisteredStudents}/{capacity})
                      {getGroupUnavailabilityStatus(id, studentGroups) &&
                        ` — ${t(`modal.values.${getGroupUnavailabilityStatus(id, studentGroups)}`)}`}
                    </Select.Option>
                  )
                )}
              </Select.OptionGroup>
            ))}
          </Select>
          {mutationError && <FeedbackBox level="error" feedback={mutationError} />}
          {groupUnavailabilityStatus && (
            <FeedbackBox
              id={STUDENT_GROUP_ID}
              level="warning"
              feedback={t('modal.errors.group-unavailable', {
                unavailable: t(`modal.values.${groupUnavailabilityStatus}`).toLocaleLowerCase(),
              })}
            />
          )}
        </FormField>
      </ModalDialog>
    </>
  );
}
