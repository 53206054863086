import { gql } from '@apollo/client';

export const REMOVE_CONSTRAINED_CHOICE_CLUSTER = gql`
  mutation removeConstrainedChoiceCluster($constrainedChoiceClusterId: String!) {
    removeConstrainedChoiceCluster(input: { constrainedChoiceClusterId: $constrainedChoiceClusterId }) {
      constrainedChoiceClusterId
      mutationStatus
    }
  }
`;
