import { useId } from 'react';
import { useTranslation } from 'react-i18next';

import type { ChangeEvent } from 'react';
import type { GetCourseOfferingByIdQuery, SetTopicByCoursePursuitIdMutationVariables } from 'types/__generated__';

import { useSetCoursePursuitTopic } from './hooks/useSetCoursePursuitTopic';

import { Select } from 'components/Select/Select';
import { useCurrentLanguage } from 'hooks/useCurrentLanguage';
import { MutationStatus } from 'types/__generated__';

type Topics = NonNullable<NonNullable<GetCourseOfferingByIdQuery['marblesCourseOffering']>['topicSet']>['topics'];

interface Props extends SetTopicByCoursePursuitIdMutationVariables {
  topics: Topics;
}

export function SetCoursePursuitTopic({ topics, topicId, coursePursuitId }: Props) {
  const { t } = useTranslation('course-offering', { keyPrefix: 'set-course-pursuit-topic' });
  const currentLanguage = useCurrentLanguage();
  const topicSelectId = useId();
  const [mutate, mutation] = useSetCoursePursuitTopic();

  async function onChange(event: ChangeEvent<HTMLSelectElement>) {
    const topicId = event.target.value;

    if (globalThis.confirm(t('confirm'))) {
      await mutate({
        variables: { coursePursuitId, topicId: topicId || null },
        optimisticResponse: {
          setCoursePursuitTopic: {
            mutationStatus: MutationStatus.Success,
            topicId,
            coursePursuitId,
          },
        },
      });
    }
  }

  return (
    <>
      <label className="visually-hidden" htmlFor={topicSelectId}>
        {t('label')}
      </label>
      <Select variant="small" value={topicId || ''} id={topicSelectId} onChange={onChange} disabled={mutation.loading}>
        <Select.Option value=""></Select.Option>
        {topics.map((topic) => (
          <Select.Option key={topic.id} value={topic.id}>
            {topic.title[currentLanguage]}
          </Select.Option>
        ))}
      </Select>
    </>
  );
}
