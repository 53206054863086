import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { ChangeEvent } from 'react';
import type { Column } from '@tanstack/react-table';

import { Input } from 'components/Input/Input';
import { useDebounce } from 'hooks/useDebounce';

const DELAY = 500; // ms

export function TableFilterInput<T>({ column }: { column: Column<T, unknown> }) {
  const { t } = useTranslation('common', { keyPrefix: 'table-filter-input' });
  const [value, setValue] = useState('');
  const { setFilterValue, id } = column;

  const debouncedSetFilterInput = useDebounce((value: string) => {
    setFilterValue(value);
  }, DELAY);

  function onChange(event: ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;

    setValue(value);
    debouncedSetFilterInput(value);
  }

  const labels: Record<string, string> = {
    id: t('id'),
    'catalog-number': t('catalog-number'),
    catalogNumber: t('catalog-number'),
    'courseOffering.info.title': t('course-offering-info-title'),
    dayOfWeek: t('day-of-week'),
    description: t('description'),
    group: t('group'),
    'info.catalogNumber': t('info-catalog-number'),
    'info.title': t('info-title'),
    locations: t('locations'),
    name: t('name'),
    period: t('period'),
    periods: t('periods'),
    programme: t('programme'),
    studentGroups: t('student-groups'),
    studentNumber: t('student-number'),
    title: t('title'),
    weeks: t('weeks'),
    timetableSummary: t('timetable-summary'),
  };

  return (
    <>
      <label className="visually-hidden" htmlFor={id}>
        {labels[id]}
      </label>
      <Input id={id} value={value} onChange={onChange} placeholder="🔍" fullWidth />
    </>
  );
}
