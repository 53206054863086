import { useLazyQuery } from '@apollo/client';
import {
  Button,
  Label,
  FieldHint,
  InputField,
  FeedbackBox,
  ModalDialog,
  ButtonGroup,
  Fieldset,
  FormField,
  MultiSelect,
} from '@uva-glass/component-library';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import type { ChangeEvent, FormEvent } from 'react';
import type { GetPlanByCodeQuery, GetPlanByCodeQueryVariables, RequiredPlanInput, Subplan } from 'types/__generated__';

import styles from './RequiredPlanForm.module.css';

import { GET_PLAN_BY_CODE } from 'graphql/queries/getPlanByCode';

const PLAN_CODE_LENGTH = 6;

const ID_INPUT_PLAN_CODE = 'ID_INPUT_PLAN_CODE';
const ID_PLAN_ERROR = 'ID_PLAN_ERROR';

interface Props {
  close: () => void;
  code?: string;
  isLoading?: boolean;
  isOpen: boolean;
  modalTitle: string;
  mutate: (plan: RequiredPlanInput) => void;
  subplans?: Subplan[] | null;
}

export function RequiredPlanForm(props: Props) {
  const { close, code, isLoading, isOpen, modalTitle, mutate, subplans } = props;
  const { t } = useTranslation('requirement-rules', { keyPrefix: 'required-plan-form' });

  const [plan, setPlan] = useState<GetPlanByCodeQuery['plan']>();
  const [planCode, setPlanCode] = useState<string>('');
  const [planError, setPlanError] = useState<string>('');
  const [subplanIds, setSubplanIds] = useState<string[]>(subplans?.map(({ id }) => id) || []);

  const [query, lazyQuery] = useLazyQuery<GetPlanByCodeQuery, GetPlanByCodeQueryVariables>(GET_PLAN_BY_CODE);

  useEffect(() => {
    if (!code) return;

    query({
      variables: { planCode: code },
      onCompleted(data) {
        if (data.plan === null) {
          setPlanError(t('plan-not-found', { planCode: code }));
          return;
        }

        setPlanCode(code);
        setPlan(data.plan);
      },
    });
  }, [code, query, t]);

  function resetAndClose() {
    setPlan(undefined);
    setPlanCode('');
    setPlanError('');

    close();
  }

  function onSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (!plan) {
      setPlanError(t('fill-in-a-valid-plan-code'));
      return;
    }

    mutate({
      planId: plan.id,
      subplanIds,
    });
  }

  function onChangeSubplans(event: ChangeEvent<HTMLInputElement>) {
    const { checked, value } = event.target;

    setSubplanIds((prevState) =>
      checked ? [...prevState, value] : prevState.filter((subplanId) => subplanId !== value)
    );
  }

  function onChangePlanCode(event: ChangeEvent<HTMLInputElement>) {
    const { value, validity } = event.target;

    setPlanError('');

    if (value.length > PLAN_CODE_LENGTH) return;

    const newPlanCode = value.trim().replace(/[^\d]/g, '');

    if (value !== newPlanCode) return;

    setPlanCode(value);

    if (!validity.valid || !value) {
      setPlan(undefined);
      return;
    }

    query({
      variables: { planCode: value },
      onCompleted(data) {
        if (data.plan === null) {
          setPlanError(t('plan-not-found', { planCode: value }));
          return;
        }

        setPlan(data.plan);
        setSubplanIds([]);
      },
    });
  }

  return (
    <ModalDialog
      buttons={
        <ButtonGroup reversed>
          <Button variant="primary" type="submit" disabled={isLoading}>
            {t('buttons.save')}
          </Button>
          <Button variant="secondary" onClick={resetAndClose} disabled={isLoading}>
            {t('buttons.cancel')}
          </Button>
        </ButtonGroup>
      }
      isDismissable={!isLoading}
      isOpen={isOpen}
      noValidate
      onClose={resetAndClose}
      onSubmit={onSubmit}
      title={modalTitle}
      wide
    >
      <FormField outerSpace>
        <Label id={`${ID_INPUT_PLAN_CODE}-label`} htmlFor={ID_INPUT_PLAN_CODE}>
          {t('plan-label')}
        </Label>
        <span id={`${ID_INPUT_PLAN_CODE}-sublabel`} className={styles['required-plan-form__sublabel']}>
          <Trans t={t} i18nKey="student-must-be-registered" components={{ strong: <strong /> }} />
        </span>

        <InputField
          aria-describedby={plan === null ? ID_PLAN_ERROR : undefined}
          aria-labelledby={`${ID_INPUT_PLAN_CODE}-sublabel`}
          disabled={isLoading}
          id={ID_INPUT_PLAN_CODE}
          inputMode="numeric"
          maxLength={PLAN_CODE_LENGTH}
          onChange={onChangePlanCode}
          pattern="[0-9]{6}"
          placeholder={t('plan-code')}
          value={planCode}
        />
        <FieldHint value={plan?.description} loading={lazyQuery.loading} spinnerAriaValueText={t('loading')} />

        {planError && <FeedbackBox id={ID_PLAN_ERROR} feedback={planError} level="error" />}
      </FormField>

      {plan &&
        (plan.subplans.length > 0 ? (
          <Fieldset legend={t('subplans-label')}>
            <MultiSelect>
              {plan.subplans.map(({ id, code, description }) => (
                <MultiSelect.MultiSelectItem
                  key={id}
                  label={`${code} - ${description}`}
                  checked={subplanIds.includes(id)}
                  value={id}
                  onChange={onChangeSubplans}
                />
              ))}
            </MultiSelect>
          </Fieldset>
        ) : (
          t('no-subplans')
        ))}
    </ModalDialog>
  );
}
