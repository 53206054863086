import { Trans, useTranslation } from 'react-i18next';
import { Card, Fieldset } from '@uva-glass/component-library';

import type { ChangeEvent } from 'react';

import { useSetGroupSwapEnabled } from './hooks/useSetGroupSwapEnabled';

import { UserAction, MutationStatus } from 'types/__generated__';
import { ToggleSwitch } from 'components/ToggleSwitch/ToggleSwitch';
import { DateTime } from 'App/shared/DateTime/DateTime';
import { useGetCourseOffering } from 'hooks/useGetCourseOffering';

const DESCRIPTION_ID = 'course-offering-group-swap-setting-description';

export function GroupSwap() {
  const { marblesCourseOffering } = useGetCourseOffering();

  const {
    id: courseOfferingId,
    department,
    groupSwapEnabled: checked,
    groupSwapExpirationDate,
    academicYear,
  } = marblesCourseOffering;
  const userCanEditCourseSettings = department.allowedUserActions.includes(UserAction.EditCourseSettings);

  const { t } = useTranslation('course-offering', { keyPrefix: 'course-offering-group-swap-setting' });
  const [mutate, mutation] = useSetGroupSwapEnabled();

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const groupSwapEnabled = event.target.checked;

    mutate({
      variables: { courseOfferingId, groupSwapEnabled },
      optimisticResponse: {
        setGroupSwapEnabled: {
          mutationStatus: MutationStatus.Success,
          courseOfferingId,
          groupSwapEnabled,
        },
      },
    });
  };

  return (
    <Card highContrast>
      <Fieldset
        legend={t('legend')}
        description={
          <Trans i18nKey="description" t={t}>
            <DateTime isoDate={groupSwapExpirationDate} />
          </Trans>
        }
        descriptionId={DESCRIPTION_ID}
      >
        <div>
          <ToggleSwitch
            onChange={onChange}
            checked={checked}
            disabled={!userCanEditCourseSettings || academicYear.isArchived}
            loading={mutation.loading}
            label={t('label')}
            labelPosition="before"
            aria-describedby={DESCRIPTION_ID}
          />
        </div>
      </Fieldset>
    </Card>
  );
}
