import { gql } from '@apollo/client';

import { ACADEMIC_YEAR_FRAGMENT } from 'graphql/fragments/academicYearFragment';
import { COURSE_INFO_FRAGMENT } from 'graphql/fragments/courseInfoFragment';
import { PERIOD_FRAGMENT } from 'graphql/fragments/periodFragment';
import { STUDENT_GROUP_FRAGMENT } from 'graphql/fragments/studentGroupFragment';
import { TOPIC_SET_FRAGMENT } from 'graphql/fragments/topicSetFragment';

export const GET_COURSE_OFFERING_BY_ID = gql`
  query getCourseOfferingById($courseOfferingId: String!) {
    marblesCourseOffering(courseOfferingId: $courseOfferingId) {
      id
      status
      info {
        ...courseInfo
      }
      constrainedChoiceCluster {
        title {
          EN
          NL
        }
      }
      periods {
        ...period
      }
      academicYear {
        ...academicYear
      }
      studentGroups {
        ...studentGroup
        topic {
          id
          title {
            EN
            NL
          }
        }
      }
      topicSet {
        ...topicSet
      }
      numberOfRegisteredStudents
      numberOfPendingStudents
      department {
        id
        code
        name {
          NL
          EN
        }
        allowedUserActions
      }
      groupSwapEnabled
      groupSwapExpirationDate
      hasCreditLimitException
      departmentId
      requirementNodeId
      directRegistrationSettings {
        registrationStartDate
        registrationEndDate
        directRegistrationType
      }
      registrationPeriod {
        id
      }
    }
    courseOfferingGroupSwaps(courseOfferingId: $courseOfferingId) {
      id
      studentId
      status
    }
  }
  ${PERIOD_FRAGMENT}
  ${STUDENT_GROUP_FRAGMENT}
  ${ACADEMIC_YEAR_FRAGMENT}
  ${TOPIC_SET_FRAGMENT}
  ${COURSE_INFO_FRAGMENT}
`;
