import { gql } from '@apollo/client';

import { RULE_FRAGMENT } from 'graphql/fragments/ruleFragment';

export const ADD_REQUIRED_PLAN = gql`
  mutation addRequiredPlan($ruleId: String!, $plan: RequiredPlanInput!) {
    addRequiredPlan(input: { ruleId: $ruleId, plan: $plan }) {
      mutationStatus
      rule {
        ...rule
      }
    }
  }
  ${RULE_FRAGMENT}
`;
