import { useTranslation } from 'react-i18next';
import { ButtonGroup } from '@uva-glass/component-library';

import styles from './Rule.module.css';
import { AddInfoRequirement } from './AddInfoRequirement';
import { AddRequiredCourse } from './AddRequiredCourses';
import { InfoRequirementNode } from './InfoRequirementNode/InfoRequirementNode';
import { RequiredCoursesNode } from './RequiredCoursesNode/RequiredCoursesNode';

import { UserAction, type GetRuleByIdQuery, type InfoRequirement, type RequiredCourses } from 'types/__generated__';
import { ActionList } from 'App/shared/ActionList/ActionList';
import { PageSection } from 'App/shared/PageSection/PageSection';

interface Props {
  rule: NonNullable<GetRuleByIdQuery['rule']>;
}

export function OptionalRequirements({ rule }: Props) {
  const { t } = useTranslation('requirement-rules', { keyPrefix: 'rule.optional-requirements' });

  const userCanEditRule = rule.department.allowedUserActions.includes(UserAction.EditRequirements);

  return (
    <PageSection>
      <div className={styles['rule-requirement-header']}>
        <h2 className={styles['rule-heading']}>{t('title')}</h2>
        {userCanEditRule && !rule.academicYear.isArchived && (
          <ButtonGroup>
            <ActionList label={t('new-optional-requirement')} prefixIcon="Plus">
              <AddInfoRequirement ruleId={rule.id} />
              <AddRequiredCourse rule={rule} />
            </ActionList>
          </ButtonGroup>
        )}
      </div>

      {rule.nodes.requirements.length > 0 ? (
        rule.nodes.requirements.map((requirement) => (
          <div key={requirement.id} className={styles['rule-optional-requirements']}>
            {requirement.__typename === 'RequiredCourses' && (
              <RequiredCoursesNode
                requirement={requirement as RequiredCourses}
                ruleId={rule.id}
                canEdit={userCanEditRule && !rule.academicYear.isArchived}
              />
            )}

            {requirement.__typename === 'InfoRequirement' && (
              <InfoRequirementNode
                requirement={requirement as InfoRequirement}
                ruleId={rule.id}
                canEdit={userCanEditRule && !rule.academicYear.isArchived}
              />
            )}
          </div>
        ))
      ) : (
        <p>{t('no-optional-requirements')}</p>
      )}
    </PageSection>
  );
}
