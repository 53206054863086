import { gql } from '@apollo/client';

export const LOCK_GROUP = gql`
  mutation lockGroup($groupId: String!) {
    lockStudentGroup(input: { studentGroupId: $groupId }) {
      mutationStatus
      studentGroupId
    }
  }
`;
