import { useTranslation } from 'react-i18next';
import { StatusPill } from '@uva-glass/component-library';

import type { ChangeEvent } from 'react';
import type { GetCourseOfferingGroupSwapsByIdQuery, GetGroupsByCourseOfferingIdQuery } from 'types/__generated__';

import styles from './GroupSelector.module.css';

import { GroupDetails } from 'App/CourseOffering/CourseOfferingGroupSwap/GroupSwapModal/GroupDetails';

type StudentGroup = NonNullable<GetGroupsByCourseOfferingIdQuery['marblesCourseOffering']>['studentGroups'][number];

type Props = {
  requestedGroupIds: GetCourseOfferingGroupSwapsByIdQuery['courseOfferingGroupSwaps'][number]['requestedStudentGroupIds'];
  allGroups: StudentGroup[];
  onGroupSelect: (event: ChangeEvent<HTMLInputElement>) => void;
  showNonSwappable?: boolean;
};

export function GroupSelector({ requestedGroupIds, allGroups, onGroupSelect, showNonSwappable }: Props) {
  const { t } = useTranslation('course-offering', { keyPrefix: 'course-offering-group-swap.group-swap-modal' });

  function isRequestedGroupFull(group: StudentGroup): boolean {
    return group.capacity - group.numberOfRegisteredStudents <= 0;
  }

  function compareFunction(aGroup: StudentGroup, bGroup: StudentGroup) {
    const shouldMoveToEnd = (group: StudentGroup) => group.locked || isRequestedGroupFull(group);

    return (shouldMoveToEnd(aGroup) ? 1 : 0) - (shouldMoveToEnd(bGroup) ? 1 : 0);
  }

  const requestedGroups = allGroups.filter((group) => requestedGroupIds.includes(group.id)).sort(compareFunction);

  return (
    <>
      <h2 className={styles['group-selector-header']}>{t('select-group-label')}</h2>
      <div className={styles['group-selector']}>
        {showNonSwappable && <StatusPill label={t('impossible')} status="impossible" variant="bar" />}
        {requestedGroups.map((requestedGroup) => (
          <div className={styles['group-selector__option']} key={requestedGroup.id}>
            <label className={styles['group-selector__option-content']} htmlFor={requestedGroup.id}>
              <input
                className={styles['group-selector__option-input']}
                id={requestedGroup.id}
                type="radio"
                name="course-offering"
                aria-label={requestedGroup.name}
                value={requestedGroup.id}
                onChange={onGroupSelect}
                disabled={isRequestedGroupFull(requestedGroup) || requestedGroup.locked}
              />
              <div className={styles['group-selector__group-details']}>
                <GroupDetails group={requestedGroup} isLocked={requestedGroup.locked} />
              </div>
            </label>
          </div>
        ))}
      </div>
    </>
  );
}
