import { useQuery } from '@apollo/client';
import { Trans, useTranslation } from 'react-i18next';
import { Navigate, useParams } from 'react-router-dom';

import type {
  GetConstrainedChoiceClusterByIdQuery,
  GetConstrainedChoiceClusterByIdQueryVariables,
} from 'types/__generated__';

import { AddCourseOffering } from './AddCourseOffering/AddCourseOffering';
import styles from './ConstrainedChoice.module.css';
import { CoursesTable } from './CoursesTable/CoursesTable';
import { UpdateConstrainedChoiceHeading } from './UpdateConstrainedChoiceHeading';

import { CONSTRAINED_CHOICES_NO_PARAMS } from 'routes';
import { GET_CONSTRAINED_CHOICE_CLUSTER_BY_ID } from 'graphql/queries/getConstrainedChoiceClusterById';
import { Page } from 'components/Page/Page';
import { PageSection } from 'App/shared/PageSection/PageSection';
import { useCurrentLanguage } from 'hooks/useCurrentLanguage';
import { UserAction } from 'types/__generated__';
import { ArchivedYearNotification } from 'App/shared/ArchivedYearNotification/ArchivedYearNotification';
import { PageMultiSubTitle } from 'App/shared/PageMultiSubTitle/PageMultiSubTitle';

export function ConstrainedChoice() {
  const { t } = useTranslation('constrained-choices', { keyPrefix: 'constrained-choice' });
  const currentLanguage = useCurrentLanguage();

  const { constrainedChoiceClusterId } = useParams();

  if (!constrainedChoiceClusterId) {
    throw new Error('No id found for constrained choice');
  }

  const query = useQuery<GetConstrainedChoiceClusterByIdQuery, GetConstrainedChoiceClusterByIdQueryVariables>(
    GET_CONSTRAINED_CHOICE_CLUSTER_BY_ID,
    {
      variables: { constrainedChoiceClusterId },
    }
  );

  if (query.loading) return null;

  if (!query.data?.constrainedChoiceCluster) {
    return <Navigate to="../" />;
  }

  const { constrainedChoiceCluster } = query.data;

  const { title, department, academicYear, departmentId, offerings } = constrainedChoiceCluster;
  const { allowedUserActions } = department;

  const userCanEditConstrainedChoiceClusters = allowedUserActions.includes(UserAction.EditConstrainedChoiceClusters);
  const firstOfferingRegistrationPeriod = offerings[0]?.registrationPeriod.semesterNumber;

  return (
    <Page
      title={t('name', { name: title[currentLanguage] })}
      subTitle={<PageMultiSubTitle values={[academicYear.name, department.name[currentLanguage]]} />}
      backlink={{
        to: `../${CONSTRAINED_CHOICES_NO_PARAMS}/${academicYear.id}/${departmentId}`,
        text: t('back'),
      }}
      notification={<ArchivedYearNotification academicYear={academicYear} />}
    >
      <PageSection>
        <UpdateConstrainedChoiceHeading constrainedChoiceCluster={constrainedChoiceCluster} />

        <section>
          <header className={styles['constrained-choice__summary-header']}>
            <h2 className={styles['constrained-choice__summary-heading']}>{t('alternative-language-title')}</h2>
          </header>
          <p className={styles['constrained-choice__summary-content']}>
            {constrainedChoiceCluster?.title[currentLanguage === 'NL' ? 'EN' : 'NL']}
          </p>
          <p className={styles['constrained-choice__summary-content']}>
            <Trans t={t} i18nKey="registration-limit" values={{ count: constrainedChoiceCluster?.registrationLimit }} />
          </p>
        </section>

        <section>
          <header className={styles['constrained-choice__courses-header']}>
            <h2 className={styles['constrained-choice__courses-heading']}>{t('courses-title')}</h2>
            {userCanEditConstrainedChoiceClusters && !academicYear.isArchived && (
              <AddCourseOffering
                departmentId={department.id}
                academicYear={academicYear.id}
                clusterId={constrainedChoiceClusterId}
                onlyAllowedRegistrationPeriod={firstOfferingRegistrationPeriod}
              />
            )}
          </header>
          <CoursesTable cluster={constrainedChoiceCluster} academicYear={academicYear.id} departmentId={departmentId} />
        </section>
      </PageSection>
    </Page>
  );
}
