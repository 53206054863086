import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { GroupSwapStatus } from 'types/__generated__';

export function useGroupSwapRequestStatus() {
  const { t } = useTranslation('common', { keyPrefix: 'group-swap-request-status' });

  const groupSwapRequestStatus = useMemo(
    () => ({
      [GroupSwapStatus.Cancelled]: t('CANCELLED'),
      [GroupSwapStatus.Completed]: t('COMPLETED'),
      [GroupSwapStatus.Expired]: t('EXPIRED'),
      [GroupSwapStatus.Pending]: t('PENDING'),
      None: t('NONE'),
    }),
    [t]
  );

  return groupSwapRequestStatus;
}
