import { useMutation } from '@apollo/client';

import type { StoreObject } from '@apollo/client';
import type {
  GetTopicsByTopicSetIdQuery,
  GetTopicsByTopicSetIdQueryVariables,
  RemoveTopicMutation,
  RemoveTopicMutationVariables,
} from 'types/__generated__';

import { MutationStatus } from 'types/__generated__';
import { REMOVE_TOPIC } from 'graphql/mutations/removeTopic';
import { GET_TOPICS_BY_TOPIC_SET_ID } from 'graphql/queries/getTopicsByTopicSetId';

export const useRemoveTopic = () =>
  useMutation<RemoveTopicMutation, RemoveTopicMutationVariables>(REMOVE_TOPIC, {
    update(cache, { data }) {
      if (data?.removeTopic.mutationStatus !== MutationStatus.Success) return;

      const { topicId, topicSetId } = data.removeTopic;

      if (!topicId) {
        throw new Error('No "topicId" in payload');
      }

      if (!topicSetId) {
        throw new Error('No "topicSetId" in payload');
      }

      const topicToEvict: StoreObject = { __typename: 'Topic', id: topicId };

      cache.updateQuery<GetTopicsByTopicSetIdQuery, GetTopicsByTopicSetIdQueryVariables>(
        {
          query: GET_TOPICS_BY_TOPIC_SET_ID,
          variables: { topicSetId },
        },
        (cached) =>
          cached && cached.topicSet
            ? {
                topicSet: {
                  id: topicSetId,
                  topics: cached.topicSet.topics.filter((topic) => topic.id !== topicId),
                },
              }
            : undefined
      );

      cache.evict({ id: cache.identify(topicToEvict) });
      cache.gc();
    },
    refetchQueries({ data }) {
      // Re-fetching the topics query to display the correct data on screen
      if (
        // Getting a TopicNotFound status means that the topic has already been removed in another browser tab or by another user.
        data?.removeTopic.mutationStatus === MutationStatus.TopicNotFound
      ) {
        return [GET_TOPICS_BY_TOPIC_SET_ID];
      }
      return [];
    },
  });
