import { gql } from '@apollo/client';

import { REQUIREMENTS_FRAGMENT } from 'graphql/fragments/requirementsFragment';
import { STUDENT_WITH_ENROLMENTS_BY_ACADEMIC_YEAR_FRAGMENT } from 'graphql/fragments/studentWithEnrolmentsByAcademicYearFragment';

export const GET_REGISTERED_PURSUITS_BY_COURSE_OFFERING_ID = gql`
  query getRegisteredPursuitsByCourseOfferingId($courseOfferingId: String!, $academicYear: Int) {
    courseOfferingPursuits(courseOfferingId: $courseOfferingId, status: REGISTERED) {
      id
      registrationDate
      student {
        ...studentWithEnrolmentsByAcademicYear
      }
      studentGroup {
        id
        code
        name
      }
      requirementsCheck {
        ...requirements
      }
    }
  }
  ${STUDENT_WITH_ENROLMENTS_BY_ACADEMIC_YEAR_FRAGMENT}
  ${REQUIREMENTS_FRAGMENT}
`;
