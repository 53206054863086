import { clsx } from 'clsx';

import styles from './DepartmentOption.module.css';

interface Props {
  label: string;
  type: string | undefined;
}

export const DepartmentOption = ({ label, type }: Props) => {
  return (
    <div
      className={clsx(styles['department-option__wrapper'], styles[`department-option__${type}`])}
      tabIndex={-1}
      aria-hidden="true"
    >
      {label}
    </div>
  );
};
