import { useTranslation } from 'react-i18next';
import { IconButton, Icon } from '@uva-glass/component-library';

import type { LockGroupMutationVariables, StudentGroup, UnlockGroupMutationVariables } from 'types/__generated__';

import { useLockGroup } from './hooks/useLockGroup';
import { useUnlockGroup } from './hooks/useUnlockGroup';

interface Props extends UnlockGroupMutationVariables, LockGroupMutationVariables {
  locked: StudentGroup['locked'];
}

export function ToggleGroupLocked({ groupId, locked }: Props) {
  const { t } = useTranslation('course-offering', { keyPrefix: 'toggle-group-locked' });
  const [lock, lockMutation] = useLockGroup();
  const [unlock, unlockMutation] = useUnlockGroup();

  const loading = lockMutation.loading || unlockMutation.loading;
  const error = lockMutation.error || unlockMutation.error;

  function onClick() {
    const variables = { groupId };

    if (locked) unlock({ variables });
    else lock({ variables });
  }

  if (error) return <>Error</>;

  return (
    <IconButton aria-label={t(locked ? 'options.unlock' : 'options.lock')} disabled={loading} onClick={onClick}>
      <Icon name={locked ? 'LockClosedFill' : 'LockOpenFill'} />
    </IconButton>
  );
}
