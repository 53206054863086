import { useOverlayTriggerState } from '@react-stately/overlays';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button, IconButton, Icon, FeedbackBox, ModalDialog, ButtonGroup } from '@uva-glass/component-library';

import type { FormEvent } from 'react';
import type { GetTopicSetsByDepartmentIdQuery, GetTopicSetsByDepartmentIdQueryVariables } from 'types/__generated__';

import { useRemoveTopicSet } from 'App/TopicSets/TopicSetsTable/hooks/useRemoveTopicSet';
import { useGetReadableMutationStatus } from 'hooks/useGetReadableMutationStatus';
import { useAuthorisation } from 'providers/AuthorisationProvider';
import { useToast } from 'providers/ToastProvider';
import { MutationStatus, UserAction } from 'types/__generated__';

interface Props extends GetTopicSetsByDepartmentIdQueryVariables {
  topicSet: GetTopicSetsByDepartmentIdQuery['topicSets'][number];
}

export function RemoveTopicSet({ topicSet, academicYear, departmentId }: Props) {
  const { t } = useTranslation('topic-sets', { keyPrefix: 'remove-topic-set' });
  const { open, close, isOpen } = useOverlayTriggerState({});
  const [mutationError, setMutationError] = useState<string>();
  const readableMutationStatus = useGetReadableMutationStatus();
  const [mutate, mutation] = useRemoveTopicSet({ academicYear, departmentId });
  const userCanRemoveTopicSet = useAuthorisation(UserAction.View, departmentId);
  const toastContext = useToast();

  function resetAndClose() {
    setMutationError(undefined);
    mutation.reset();

    close();
  }

  function onSubmit(event: FormEvent) {
    event.preventDefault();

    mutate({
      variables: { topicSetId: topicSet.id },
      onCompleted(data) {
        if (data.removeTopicSet.mutationStatus === MutationStatus.Success) {
          resetAndClose();
          return;
        }

        if (data.removeTopicSet.mutationStatus === MutationStatus.TopicSetNotFound) {
          resetAndClose();
          toastContext.addToast({
            variant: 'warning',
            title: t('toast.topic-set-not-found'),
            content: readableMutationStatus(data.removeTopicSet.mutationStatus),
          });
          return;
        }
        setMutationError(readableMutationStatus(data.removeTopicSet.mutationStatus));
      },
      onError(error) {
        if (error.graphQLErrors.length) {
          setMutationError(readableMutationStatus(MutationStatus.Corrupt));
        } else {
          setMutationError(readableMutationStatus(MutationStatus.Error));
        }
      },
    });
  }

  if (!userCanRemoveTopicSet) return null;

  return (
    <>
      <IconButton
        variant="destructive"
        aria-label={t('trigger')}
        onClick={open}
        disabled={topicSet.courseOfferings.length > 0}
      >
        <Icon name={topicSet.courseOfferings.length > 0 ? 'TrashStrikethrough' : 'Trash'} />
      </IconButton>

      <ModalDialog
        isOpen={isOpen}
        title={t('modal.title')}
        onClose={resetAndClose}
        onSubmit={onSubmit}
        buttons={
          <ButtonGroup>
            <Button variant="secondary" onClick={resetAndClose}>
              {t('modal.buttons.cancel')}
            </Button>
            <Button variant="destructive-primary" onClick={onSubmit}>
              {t('modal.buttons.confirm')}
            </Button>
          </ButtonGroup>
        }
      >
        <p>
          <Trans
            t={t}
            i18nKey="modal.description"
            values={{ name: topicSet.name }}
            components={{ strong: <strong /> }}
          />
        </p>
        {mutationError && <FeedbackBox level="error" feedback={mutationError} />}
      </ModalDialog>
    </>
  );
}
