import type { MarblesAcademicYear } from 'types/__generated__';

import { useFilters } from 'providers/FiltersProvider';

export function useGetAcademicYearDetails(academicYear: MarblesAcademicYear['id']) {
  const { academicYears } = useFilters();

  const academicYearDetails = academicYears.find((year) => year.id === academicYear);

  if (!academicYearDetails) {
    throw new Error('The selected AcademicYear could not be found');
  }

  return academicYearDetails;
}
