import { createColumnHelper } from '@tanstack/react-table';
import { Link, generatePath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import type { GetCourseOfferingGroupSwapsByIdQuery } from 'types/__generated__';

import styles from './CourseOfferingGroupSwap.module.css';

import { DataTable } from 'App/shared/DataTable/DataTable';
import { DateTime } from 'App/shared/DateTime/DateTime';
import { DualTextCell } from 'components/DualTextCell/DualTextCell';
import { STUDENTS } from 'routes';
import { TABLE_STORAGE_KEYS } from 'util/storagekeys';
import { useFormattedDate } from 'hooks/useFormattedDate';

type RowData = GetCourseOfferingGroupSwapsByIdQuery['courseOfferingGroupSwaps'][number];

interface Props {
  groupSwaps: GetCourseOfferingGroupSwapsByIdQuery['courseOfferingGroupSwaps'];
}

const columnHelper = createColumnHelper<RowData>();

const initialState = {
  sorting: [{ id: 'cancelled-creation-date', desc: true }],
};

export function CancelledGroupSwapTable({ groupSwaps }: Props) {
  const { t } = useTranslation('course-offering', { keyPrefix: 'course-offering-group-swap.sections.cancelled' });
  const formattedDate = useFormattedDate();

  const columns = [
    columnHelper.accessor('creationDate', {
      id: 'cancelled-creation-date',
      header: t('headers.date-request'),
      cell: ({ cell }) => (
        <>
          {cell.getValue() ? (
            <DateTime isoDate={cell.getValue()} format={{ dateStyle: 'medium', timeStyle: 'short' }} />
          ) : (
            t('values.not-applicable')
          )}
        </>
      ),
      filterFn: (row, _, filterValue: string) =>
        formattedDate(row.original.creationDate, { dateStyle: 'medium', timeStyle: 'short' })
          .toLocaleLowerCase()
          .includes(filterValue.toLocaleLowerCase()),
    }),

    columnHelper.accessor(({ student }) => student.studentNumber, {
      id: 'cancelled-student-number',
      header: t('headers.student-number'),
    }),

    columnHelper.accessor(({ student }) => student.name, {
      id: 'cancelled-student-name',
      header: t('headers.student-name'),
      cell: ({ cell, row }) => (
        <Link to={generatePath(`${STUDENTS}/:studentId`, { studentId: row.original.student.id })}>
          {cell.getValue()}
        </Link>
      ),
      meta: { tableCellProps: { noWrap: true } },
    }),

    columnHelper.accessor(({ updateDate }) => updateDate, {
      id: 'cancelled-date-cancelled',
      header: t('headers.date-cancelled'),
      cell: ({ row }) => {
        return (
          <DualTextCell
            upperText={
              <DateTime isoDate={row.original.updateDate} format={{ dateStyle: 'medium', timeStyle: 'short' }} />
            }
            lowerText={row.original.processedBy || '-'}
          />
        );
      },
      filterFn: (row, _, filterValue: string) => {
        return (
          formattedDate(row.original.updateDate, { dateStyle: 'medium', timeStyle: 'short' })
            .toLocaleLowerCase()
            .includes(filterValue.toLocaleLowerCase()) ||
          (row.original.processedBy?.toLocaleLowerCase() || '').includes(filterValue.toLocaleLowerCase()) ||
          false
        );
      },
    }),
  ];

  return groupSwaps.length ? (
    <section className={styles['course-offering-group-swap__table-section']}>
      <header className={styles['course-offering-group-swap__table-header']}>
        <h2 className={styles['course-offering-group-swap__table-heading']}>{t('heading')}</h2>
      </header>
      <DataTable
        columns={columns}
        data={groupSwaps}
        initialState={initialState}
        tableId={TABLE_STORAGE_KEYS.CANCELLED_COURSE_OFFERING_GROUP_SWAP_TABLE}
      />
    </section>
  ) : null;
}
