import { gql } from '@apollo/client';

import { REQUIREMENTS_FRAGMENT } from 'graphql/fragments/requirementsFragment';
import { STUDENT_FRAGMENT } from 'graphql/fragments/studentFragment';

export const PENDING_COURSE_PURSUIT_FRAGMENT = gql`
  fragment pendingCoursePursuit on CoursePursuit {
    id
    topicId
    student {
      ...student
    }
    requirementsCheck {
      ...requirements
    }
    clusterPreference {
      index
      pursuitCount
      registrationLimit
    }
  }
  ${STUDENT_FRAGMENT}
  ${REQUIREMENTS_FRAGMENT}
`;
