import type { GetRuleByIdQuery } from 'types/__generated__';

import { RequiredPlanRule } from './RequiredPlanRule';
import { RequiredStudentSetRule } from './RequiredStudentSetRule';
import { RequiredCareerRule } from './RequiredCareerRule';

import { RuleKind } from 'types/__generated__';

interface Props {
  rule: NonNullable<GetRuleByIdQuery['rule']>;
}

export function GroupRequirements({ rule }: Props) {
  switch (rule.ruleKind) {
    case RuleKind.RequiredPlan:
      return <RequiredPlanRule rule={rule} />;

    case RuleKind.RequiredCareer:
      return <RequiredCareerRule rule={rule} />;

    case RuleKind.RequiredStudentSet:
      return <RequiredStudentSetRule rule={rule} />;

    default:
      return null;
  }
}
