import { useQuery } from '@apollo/client';
import { createContext, useContext } from 'react';

import type { ReactElement } from 'react';
import type { GetDepartmentsQuery, UserAction } from 'types/__generated__';

import { GET_DEPARTMENTS } from 'graphql/queries/getDepartments';
import { Unauthorized } from 'App/Unauthorized/Unauthorized';
import { UserBlocked } from 'App/UserBlocked/UserBlocked';

type Departments = GetDepartmentsQuery['marblesDepartments'];

interface AuthorisationProviderProps {
  children: ReactElement;
}

const AuthorisationContext = createContext<Departments | null>(null);

export function AuthorisationProvider({ children }: AuthorisationProviderProps) {
  const query = useQuery<GetDepartmentsQuery>(GET_DEPARTMENTS);

  if (query.loading) return null;

  if (query.error) {
    if (query.error.message.includes('403')) return <Unauthorized />;
    if (query.error.message.includes('451')) return <UserBlocked />;
    throw query.error;
  }

  if (!query.data?.marblesDepartments) {
    throw new Error('Error retrieving departments');
  }

  const { marblesDepartments } = query.data;

  return <AuthorisationContext.Provider value={marblesDepartments}>{children}</AuthorisationContext.Provider>;
}

export function useAuthorisation(userAction: UserAction, departmentId?: string) {
  const context = useContext(AuthorisationContext);

  if (context === null) throw new Error('useAuthorisation can only be used in an AuthorisationProvider');

  const department = context.find(({ id, allowedUserActions }) =>
    departmentId
      ? id === departmentId && allowedUserActions.includes(userAction)
      : allowedUserActions.includes(userAction)
  );

  return department !== undefined;
}

interface RequireAuthorisationProps {
  authorisation: UserAction;
  children: ReactElement;
  departmentId?: string;
  fallback?: ReactElement;
}

export function RequireAuthorisation({ authorisation, children, departmentId, fallback }: RequireAuthorisationProps) {
  const isAuthorised = useAuthorisation(authorisation, departmentId);

  if (isAuthorised) return children;

  if (fallback) return fallback;

  return null;
}
