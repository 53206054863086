import { useOverlayTriggerState } from '@react-stately/overlays';
import { useTranslation } from 'react-i18next';
import { Button, ButtonGroup, Icon, ModalDialog } from '@uva-glass/component-library';

import { useGetCourseOffering } from 'hooks/useGetCourseOffering';
import { useSetRequirementNodeByCourseOfferingId } from 'hooks/useSetRequirementNodeByCourseOfferingId';

export function UnlinkRequirement() {
  const { t } = useTranslation('course-offering', { keyPrefix: 'unlink-requirement' });
  const {
    marblesCourseOffering: { id: courseOfferingId },
  } = useGetCourseOffering();
  const { close, open, isOpen } = useOverlayTriggerState({});
  const [mutate, mutation] = useSetRequirementNodeByCourseOfferingId();

  const onClick = async () => {
    await mutate({ variables: { courseOfferingId, requirementNodeId: null } });
    close();
  };

  return (
    <>
      <Button variant="secondary" onClick={open}>
        <Icon name="LinkSlash" size={20} />
        {t('trigger')}
      </Button>
      <ModalDialog
        title={t('modal.title')}
        buttons={
          <ButtonGroup reversed>
            <Button variant="destructive-primary" disabled={mutation.loading} onClick={onClick}>
              {t('modal.buttons.unlink')}
            </Button>
            <Button variant="secondary" disabled={mutation.loading} onClick={close}>
              {t('modal.buttons.cancel')}
            </Button>
          </ButtonGroup>
        }
        isOpen={isOpen}
        onClose={close}
        isDismissable={!mutation.loading}
      >
        <p>{t('modal.description')}</p>
      </ModalDialog>
    </>
  );
}
