import { useMutation } from '@apollo/client';

import type {
  CopyRequirementMutation,
  CopyRequirementMutationInput,
  GetRequirementsByDepartmentIdQuery,
  GetRequirementsByDepartmentIdQueryVariables,
} from 'types/__generated__';

import { MutationStatus } from 'types/__generated__';
import { COPY_REQUIREMENT } from 'graphql/mutations/copyRequirement';
import { GET_REQUIREMENTS_BY_DEPARTMENT_ID } from 'graphql/queries/getRequirementsByDepartmentId';

export const useCopyRequirement = (academicYear: number, childDepartmentIds: string[]) =>
  useMutation<CopyRequirementMutation, CopyRequirementMutationInput>(COPY_REQUIREMENT, {
    update(cache, { data }) {
      if (data?.copyRequirement.mutationStatus !== MutationStatus.Success) return;

      const { requirement } = data.copyRequirement;

      if (!requirement) {
        throw new Error('No "requirement" in payload');
      }

      const departmentsToBeUpdated = [...childDepartmentIds, requirement.department.id];

      // forEach necessary as we need to update the cache for the selected department and every child applicable to it
      departmentsToBeUpdated.forEach((departmentId) => {
        cache.updateQuery<GetRequirementsByDepartmentIdQuery, GetRequirementsByDepartmentIdQueryVariables>(
          {
            query: GET_REQUIREMENTS_BY_DEPARTMENT_ID,
            variables: { academicYear, departmentId, includeParentDepartments: true },
          },
          (cached) => (cached ? { requirements: [...cached.requirements, requirement] } : null)
        );
      });
    },
  });
