import '@uva-glass/component-library/dist/assets/globals.css';
import './css/base.css';
import './css/miscellaneous.css';
import './css/transitions.css';
import './css/typography.css';

import { use } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { createRoot } from 'react-dom/client';
import { initReactI18next } from 'react-i18next';

import type { HttpBackendOptions } from 'i18next-http-backend';

import { App } from 'App/App';

const appRoot = document.getElementById('root') as HTMLElement;
const root = createRoot(appRoot);

const backendOptions: HttpBackendOptions = {
  async request(_options, url, _payload, callback) {
    const matches = url.match(/\/([a-z]{2})\/([\w-]+)\.json$/);

    if (!matches?.length) {
      callback(new Error(`Translation could not be found for ${url}`), { status: 404, data: ' ' });
      return;
    }

    const [, lang, ns] = matches;

    const result = await import(
      /* webpackChunkName: "[request]" */
      `./assets/locales/${lang}/${ns}.json`
    );

    const data = JSON.stringify(result.default);

    callback(undefined, { status: 200, data });
  },
  loadPath: '/locales/{{lng}}/{{ns}}.json',
};

use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // list of namespaces that cannot be resolved via GET requests
    ns: ['common'],
    detection: {
      order: ['localStorage', 'navigator', 'cookie', 'htmlTag'],
    },
    interpolation: { escapeValue: false },
    load: 'languageOnly',
    cleanCode: true,
    supportedLngs: ['nl', 'en'],
    returnNull: false,
    missingKeyHandler(_: readonly string[], __: string, key: string) {
      // eslint-disable-next-line no-console
      console.warn('Missing translation for key: ', key);
    },
    backend: backendOptions,
  });

root.render(<App />);
