import { useId } from 'react';
import { useTranslation } from 'react-i18next';

import type { Column } from '@tanstack/react-table';

import { Select } from 'components/Select/Select';

interface Props<TData> {
  column: Column<TData>;
}

export function TableFilterLocked<T>({ column }: Props<T>) {
  const selectId = useId();
  const { t } = useTranslation('course-offering', { keyPrefix: 'table-filters' });
  const { getFilterValue, setFilterValue } = column;

  return (
    <>
      <label className="visually-hidden" htmlFor={selectId}>
        {t('locked.label')}
      </label>
      <Select
        id={selectId}
        onChange={(event) =>
          setFilterValue(event.target.value === 'locked' ? true : event.target.value === 'unlocked' ? false : undefined)
        }
        value={getFilterValue() === true ? 'locked' : getFilterValue() === false ? 'unlocked' : undefined}
        variant="small"
      >
        <Select.Option value="">{t('locked.options.all')}</Select.Option>
        <Select.Option value="locked">{t('locked.options.closed')}</Select.Option>
        <Select.Option value="unlocked">{t('locked.options.open')}</Select.Option>
      </Select>
    </>
  );
}
