import { useMutation } from '@apollo/client';

import type { StoreObject } from '@apollo/client';
import type {
  GetRulesByDepartmentIdQuery,
  GetRulesByDepartmentIdQueryVariables,
  RemoveRuleMutation,
  RemoveRuleMutationVariables,
} from 'types/__generated__';

import { REMOVE_RULE } from 'graphql/mutations/removeRule';
import { MutationStatus } from 'types/__generated__';
import { GET_RULES_BY_DEPARTMENT_ID } from 'graphql/queries/getRulesByDepartmentId';

export const useRemoveRule = () =>
  useMutation<RemoveRuleMutation, RemoveRuleMutationVariables>(REMOVE_RULE, {
    update: (cache, { data }) => {
      if (data?.removeRule.mutationStatus !== MutationStatus.Success) return;

      const { ruleId, academicYear, departmentId } = data.removeRule;

      if (!academicYear || !departmentId) {
        throw new Error('"academicYear" is not defined');
      }

      const ruleToEvict: StoreObject = { __typename: 'Rule', id: ruleId };

      cache.updateQuery<GetRulesByDepartmentIdQuery, GetRulesByDepartmentIdQueryVariables>(
        {
          query: GET_RULES_BY_DEPARTMENT_ID,
          variables: { academicYear, departmentId },
        },
        (cached) => (cached ? { rules: cached.rules.filter((rule) => rule.id !== ruleId) } : undefined)
      );

      cache.evict({ id: cache.identify(ruleToEvict) });
      cache.gc();
    },
  });
