import { gql } from '@apollo/client';

import { CONSTRAINED_CHOICE_CLUSTER_FRAGMENT } from 'graphql/fragments/constrainedChoiceClusterFragment';
import { COURSE_INFO_FRAGMENT } from 'graphql/fragments/courseInfoFragment';
import { PERIOD_FRAGMENT } from 'graphql/fragments/periodFragment';
import { DEPARTMENT_FRAGMENT } from 'graphql/fragments/departmentFragment';

export const GET_CONSTRAINED_CHOICE_CLUSTER_BY_ID = gql`
  query getConstrainedChoiceClusterById($constrainedChoiceClusterId: String!) {
    constrainedChoiceCluster(constrainedChoiceClusterId: $constrainedChoiceClusterId) {
      ...constrainedChoiceCluster
      offerings {
        id
        info {
          ...courseInfo
        }
        periods {
          ...period
        }
        numberOfPendingStudents
        registrationPeriod {
          ...period
        }
      }
      academicYear {
        id
        name
        isArchived
      }
      department {
        ...department
      }
    }
  }

  ${CONSTRAINED_CHOICE_CLUSTER_FRAGMENT}
  ${COURSE_INFO_FRAGMENT}
  ${PERIOD_FRAGMENT}
  ${DEPARTMENT_FRAGMENT}
`;
