import { gql } from '@apollo/client';

export const PARTIAL_REQUIREMENT_FRAGMENT = gql`
  fragment partialRequirement on Requirement {
    id
    name
    department {
      id
      name {
        NL
        EN
      }
      allowedUserActions
    }
    topics {
      id
    }
    courseOfferings {
      id
    }
    ... on Requirement {
      rulesInRequirement {
        id
        rule {
          id
        }
      }
    }
  }
`;
