import { gql } from '@apollo/client';

export const SWITCH_STUDENT_GROUP = gql`
  mutation switchStudentGroup($coursePursuitId: String!, $studentGroupId: String!) {
    switchStudentGroup(input: { coursePursuitId: $coursePursuitId, studentGroupId: $studentGroupId }) {
      mutationStatus
      coursePursuitId
      studentGroupId
    }
  }
`;
