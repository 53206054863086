export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: { input: any; output: any };
  /** The `Long` scalar type represents non-fractional signed whole 64-bit numeric values. Long can represent values between -(2^63) and 2^63 - 1. */
  Long: { input: any; output: any };
  /** The `Upload` scalar type represents a file upload. */
  Upload: { input: any; output: any };
};

export type AcceptPendingCoursePursuitInput = {
  coursePursuitId: Scalars['String']['input'];
  studentGroupId: Scalars['String']['input'];
};

export type AcceptPendingCoursePursuitPayload = {
  __typename?: 'AcceptPendingCoursePursuitPayload';
  mutationStatus: MutationStatus;
};

export type AcknowledgeRejectedCourseInput = {
  coursePursuitId: Scalars['String']['input'];
};

export type AcknowledgeRejectedCoursePayload = {
  __typename?: 'AcknowledgeRejectedCoursePayload';
  coursePursuitId: Scalars['String']['output'];
  mutationStatus: MutationStatus;
};

export type AddApiKeyInput = {
  override: Scalars['Boolean']['input'];
  username: Scalars['String']['input'];
};

export type AddApiKeyPayload = {
  __typename?: 'AddApiKeyPayload';
  password: Scalars['String']['output'];
};

export type AddInfoRequirementMutationInput = {
  description: BilingualStringInput;
  ruleId: Scalars['String']['input'];
};

export type AddInfoRequirementMutationPayload = {
  __typename?: 'AddInfoRequirementMutationPayload';
  mutationStatus: MutationStatus;
  rule?: Maybe<Rule>;
};

export type AddMarblesCourseInput = {
  catalogNumber: Scalars['String']['input'];
  confirmedRedo?: Scalars['Boolean']['input'];
  semesterId?: Scalars['String']['input'];
};

export type AddMarblesCoursePayload = {
  __typename?: 'AddMarblesCoursePayload';
  course?: Maybe<MarblesCourseInfo>;
  coursePursuit?: Maybe<CoursePursuit>;
  coursePursuitId?: Maybe<Scalars['String']['output']>;
  duplicateInSemester: Scalars['Boolean']['output'];
  mutationStatus: MutationStatus;
};

export type AddRequiredCoursesMutationInput = {
  courses: RequiredCoursesInput;
  description: BilingualStringInput;
  ruleId: Scalars['String']['input'];
};

export type AddRequiredCoursesMutationPayload = {
  __typename?: 'AddRequiredCoursesMutationPayload';
  mutationStatus: MutationStatus;
  rule?: Maybe<Rule>;
};

export type AddRequiredPlanMutationInput = {
  plan: RequiredPlanInput;
  ruleId: Scalars['String']['input'];
};

export type AddRequiredPlanMutationPayload = {
  __typename?: 'AddRequiredPlanMutationPayload';
  mutationStatus: MutationStatus;
  rule?: Maybe<Rule>;
};

export type AddTopicMutationInput = {
  requirementNodeId?: InputMaybe<Scalars['String']['input']>;
  titleEN: Scalars['String']['input'];
  titleNL: Scalars['String']['input'];
  topicSetId: Scalars['String']['input'];
};

export type AddTopicMutationPayload = {
  __typename?: 'AddTopicMutationPayload';
  mutationStatus: MutationStatus;
  topic?: Maybe<Topic>;
};

export type AdministrativelyDeregisterStudentForCourseInput = {
  coursePursuitId: Scalars['String']['input'];
  reason?: InputMaybe<StaffDeregistrationReason>;
};

export type AdministrativelyDeregisterStudentForCoursePayload = {
  __typename?: 'AdministrativelyDeregisterStudentForCoursePayload';
  courseOfferingId?: Maybe<Scalars['String']['output']>;
  coursePursuitId: Scalars['String']['output'];
  mutationStatus: MutationStatus;
  reason?: Maybe<Scalars['String']['output']>;
  removed: Scalars['Boolean']['output'];
};

export type AdministrativelySelectCourseForRegistrationInput = {
  courseOfferingId: Scalars['String']['input'];
  skipRequirements?: Scalars['Boolean']['input'];
  studentNumber: Scalars['String']['input'];
  topicId?: InputMaybe<Scalars['String']['input']>;
};

export type AdministrativelySelectCourseForRegistrationPayload = {
  __typename?: 'AdministrativelySelectCourseForRegistrationPayload';
  clusterPreference?: Maybe<CoursePursuitClusterPreference>;
  courseOfferingId?: Maybe<Scalars['String']['output']>;
  coursePursuitId?: Maybe<Scalars['String']['output']>;
  mutationStatus: MutationStatus;
  requirementsCheck?: Maybe<Requirements>;
  student?: Maybe<Student>;
  topicId?: Maybe<Scalars['String']['output']>;
};

export type AnnualTransitionCopyRequirementsInput = {
  dryRun?: Scalars['Boolean']['input'];
  sourceYear: Scalars['Int']['input'];
  targetYear: Scalars['Int']['input'];
};

export type AnnualTransitionCopyRequirementsPayload = {
  __typename?: 'AnnualTransitionCopyRequirementsPayload';
  status: MutationStatus;
};

export type AnnualTransitionMutationInput = {
  copyConstrainedChoice?: Scalars['Boolean']['input'];
  copyRules?: Scalars['Boolean']['input'];
  copyTopics?: Scalars['Boolean']['input'];
  departmentIds?: InputMaybe<Array<Scalars['String']['input']>>;
  dryRun?: Scalars['Boolean']['input'];
  facultyId?: InputMaybe<Scalars['String']['input']>;
  offeringIds?: InputMaybe<Array<Scalars['String']['input']>>;
  sourceYear: Scalars['Int']['input'];
  targetYear: Scalars['Int']['input'];
};

export type AnnualTransitionMutationPayload = {
  __typename?: 'AnnualTransitionMutationPayload';
  status: MutationStatus;
};

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER',
  Validation = 'VALIDATION',
}

export enum BeforeOrAfter {
  After = 'AFTER',
  Before = 'BEFORE',
}

export type BenchmarkResults = {
  __typename?: 'BenchmarkResults';
  offeringId?: Maybe<Scalars['String']['output']>;
  students: Scalars['Int']['output'];
  time: Scalars['Long']['output'];
};

export type BilingualString = {
  __typename?: 'BilingualString';
  EN: Scalars['String']['output'];
  NL: Scalars['String']['output'];
};

export type BilingualStringInput = {
  EN: Scalars['String']['input'];
  NL: Scalars['String']['input'];
};

export type BulkAcceptRejectCoursesInput = {
  accept: Array<BulkAcceptRejectCoursesInputAcceptRowInput>;
  reject: Array<BulkAcceptRejectCoursesInputRejectRowInput>;
};

export type BulkAcceptRejectCoursesInputAcceptRowInput = {
  courseOfferingId: Scalars['String']['input'];
  studentGroupId: Scalars['String']['input'];
  studentId: Scalars['String']['input'];
};

export type BulkAcceptRejectCoursesInputRejectRowInput = {
  courseOfferingId: Scalars['String']['input'];
  reason: RejectionReason;
  studentId: Scalars['String']['input'];
};

export type BulkAcceptRejectCoursesPayload = {
  __typename?: 'BulkAcceptRejectCoursesPayload';
  failedRows: Array<BulkAcceptRejectCoursesPayloadRow>;
  numFailed: Scalars['Int']['output'];
  numProcessed: Scalars['Int']['output'];
  numSucceeded: Scalars['Int']['output'];
};

export type BulkAcceptRejectCoursesPayloadRow = {
  __typename?: 'BulkAcceptRejectCoursesPayloadRow';
  courseOfferingId: Scalars['String']['output'];
  reason: Scalars['String']['output'];
  status: MutationStatus;
  studentId: Scalars['String']['output'];
};

export type BulkDeregisterMarkedPursuitsPayload = {
  __typename?: 'BulkDeregisterMarkedPursuitsPayload';
  cancelledDeregistrationRows: Array<BulkDeregisterMarkedPursuitsPayloadRow>;
  deregisteredRows: Array<BulkDeregisterMarkedPursuitsPayloadRow>;
};

export type BulkDeregisterMarkedPursuitsPayloadRow = {
  __typename?: 'BulkDeregisterMarkedPursuitsPayloadRow';
  creationDate: Scalars['DateTime']['output'];
  pursuitId: Scalars['String']['output'];
  studentId: Scalars['String']['output'];
};

export type BulkRejectInactiveStudentsPayload = {
  __typename?: 'BulkRejectInactiveStudentsPayload';
  rejectedPursuits: Array<BulkRejectInactiveStudentsPayloadRow>;
};

export type BulkRejectInactiveStudentsPayloadRow = {
  __typename?: 'BulkRejectInactiveStudentsPayloadRow';
  pursuitId: Scalars['String']['output'];
  status: MutationStatus;
  studentId: Scalars['String']['output'];
};

export type CancelGroupSwapRequestInput = {
  coursePursuitId: Scalars['String']['input'];
  groupSwapRequestId: Scalars['String']['input'];
};

export type CancelGroupSwapRequestPayload = {
  __typename?: 'CancelGroupSwapRequestPayload';
  coursePursuitId: Scalars['String']['output'];
  groupSwapRequestId: Scalars['String']['output'];
  mutationStatus: MutationStatus;
};

export type Career = {
  __typename?: 'Career';
  code: Scalars['String']['output'];
  description: BilingualString;
  id: Scalars['String']['output'];
};

export type ClearTimetablePreferencesInput = {
  dryRun: Scalars['Boolean']['input'];
};

export type ClearTimetablePreferencesPayload = {
  __typename?: 'ClearTimetablePreferencesPayload';
  rowCount: Scalars['Int']['output'];
  rows: Array<ClearTimetablePreferencesPayloadRow>;
};

export type ClearTimetablePreferencesPayloadRow = {
  __typename?: 'ClearTimetablePreferencesPayloadRow';
  status: MutationStatus;
  studentId: Scalars['String']['output'];
};

export type ConstrainedChoiceCluster = {
  __typename?: 'ConstrainedChoiceCluster';
  academicYear: MarblesAcademicYear;
  department: MarblesDepartment;
  departmentId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  offerings: Array<MarblesCourseOffering>;
  registrationLimit: Scalars['Int']['output'];
  title: BilingualString;
};

export type ConstrainedChoiceClusterSetting = {
  __typename?: 'ConstrainedChoiceClusterSetting';
  academicYear: MarblesAcademicYear;
  constrainedChoiceCluster?: Maybe<ConstrainedChoiceCluster>;
  coursePursuitIds: Array<Scalars['String']['output']>;
  credits: Scalars['Float']['output'];
  id: Scalars['String']['output'];
  registrationLimit: Scalars['Int']['output'];
};

export type CopyRequirementMutationInput = {
  departmentId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  requirementId: Scalars['String']['input'];
};

export type CopyRequirementMutationPayload = {
  __typename?: 'CopyRequirementMutationPayload';
  mutationStatus: MutationStatus;
  requirement?: Maybe<Requirement>;
};

export type CopyRuleMutationInput = {
  departmentId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  ruleId: Scalars['String']['input'];
};

export type CopyRuleMutationPayload = {
  __typename?: 'CopyRuleMutationPayload';
  mutationStatus: MutationStatus;
  rule?: Maybe<Rule>;
};

export type CourseOfferingCapacity = {
  __typename?: 'CourseOfferingCapacity';
  available: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type CourseOfferingPendingGroupSwap = {
  __typename?: 'CourseOfferingPendingGroupSwap';
  courseId: Scalars['String']['output'];
  courseOfferingId: Scalars['String']['output'];
  info?: Maybe<MarblesCourseInfo>;
  lastRequestDate: Scalars['DateTime']['output'];
  requestCount: Scalars['Int']['output'];
  requestExpirationDate: Scalars['DateTime']['output'];
  requestPossible: Scalars['Boolean']['output'];
};

export type CourseOfferingRequirementCapacity = {
  __typename?: 'CourseOfferingRequirementCapacity';
  allocationRound: Scalars['Int']['output'];
  description?: Maybe<BilingualString>;
  includeInRetry: Scalars['Boolean']['output'];
  pending: Scalars['Int']['output'];
  priority?: Maybe<Scalars['Int']['output']>;
  priorityLimit?: Maybe<Scalars['Int']['output']>;
  registered: Scalars['Int']['output'];
  ruleId?: Maybe<Scalars['String']['output']>;
};

export type CourseOfferingTopicCapacity = {
  __typename?: 'CourseOfferingTopicCapacity';
  availableCapacity: Scalars['Int']['output'];
  groupCount: Scalars['Int']['output'];
  pending: Scalars['Int']['output'];
  registered: Scalars['Int']['output'];
  topic: Topic;
};

export type CourseOfferingsFilter = {
  __typename?: 'CourseOfferingsFilter';
  academicYear?: Maybe<Scalars['Int']['output']>;
  departmentId?: Maybe<Scalars['String']['output']>;
};

export type CoursePursuit = {
  __typename?: 'CoursePursuit';
  clusterPreference?: Maybe<CoursePursuitClusterPreference>;
  course: StudentCourse;
  courseInfo: MarblesCourseInfo;
  coursePursuitStatus: CoursePursuitStatus;
  id: Scalars['String']['output'];
  isGroupSwapPossible: Scalars['Boolean']['output'];
  pendingGroupSwapRequestId?: Maybe<Scalars['String']['output']>;
  registrationDate?: Maybe<Scalars['DateTime']['output']>;
  rejectionReason?: Maybe<RejectionReason>;
  requirementsCheck?: Maybe<Requirements>;
  selectedOffering?: Maybe<MarblesCourseOffering>;
  student: Student;
  studentGroup?: Maybe<StudentGroup>;
  topicId?: Maybe<Scalars['String']['output']>;
};

export enum CoursePursuitAction {
  Accepted = 'ACCEPTED',
  AcceptedAdministratively = 'ACCEPTED_ADMINISTRATIVELY',
  Added = 'ADDED',
  Deregistered = 'DEREGISTERED',
  Deselected = 'DESELECTED',
  RegisteredAdministratively = 'REGISTERED_ADMINISTRATIVELY',
  RegisteredDirectly = 'REGISTERED_DIRECTLY',
  Rejected = 'REJECTED',
  RejectedCourseAcknowledged = 'REJECTED_COURSE_ACKNOWLEDGED',
  Removed = 'REMOVED',
  Selected = 'SELECTED',
  SwitchedGroup = 'SWITCHED_GROUP',
  UpdatedTopic = 'UPDATED_TOPIC',
}

export type CoursePursuitAudit = {
  __typename?: 'CoursePursuitAudit';
  academicYear?: Maybe<MarblesAcademicYear>;
  action: CoursePursuitAction;
  catalogNumber?: Maybe<Scalars['String']['output']>;
  course?: Maybe<BilingualString>;
  courseOfferingId?: Maybe<Scalars['String']['output']>;
  deregistrationReason?: Maybe<DeregistrationReason>;
  new?: Maybe<BilingualString>;
  old?: Maybe<BilingualString>;
  pursuitId: Scalars['String']['output'];
  registrationRound?: Maybe<RegistrationRound>;
  studentId: Scalars['String']['output'];
  timeStamp: Scalars['DateTime']['output'];
  username: Scalars['String']['output'];
};

export type CoursePursuitClusterPreference = {
  __typename?: 'CoursePursuitClusterPreference';
  index: Scalars['Int']['output'];
  pursuitCount: Scalars['Int']['output'];
  registrationLimit: Scalars['Int']['output'];
};

export enum CoursePursuitStatus {
  Added = 'ADDED',
  Deregistered = 'DEREGISTERED',
  Pending = 'PENDING',
  Registered = 'REGISTERED',
  Rejected = 'REJECTED',
}

export type CreateConstrainedChoiceClusterMutationInput = {
  academicYear: Scalars['Int']['input'];
  departmentId: Scalars['String']['input'];
  titleEN: Scalars['String']['input'];
  titleNL: Scalars['String']['input'];
};

export type CreateConstrainedChoiceClusterMutationPayload = {
  __typename?: 'CreateConstrainedChoiceClusterMutationPayload';
  academicYear: Scalars['Int']['output'];
  constrainedChoiceCluster?: Maybe<ConstrainedChoiceCluster>;
  mutationStatus: MutationStatus;
};

export type CreateGroupSwapRequestErrorPayload = CreateGroupSwapRequestPayload & {
  __typename?: 'CreateGroupSwapRequestErrorPayload';
  coursePursuitId: Scalars['String']['output'];
  mutationStatus: MutationStatus;
  reason: BilingualString;
};

export type CreateGroupSwapRequestInput = {
  coursePursuitId: Scalars['String']['input'];
  requestedStudentGroupIds: Array<Scalars['String']['input']>;
};

export type CreateGroupSwapRequestPayload = {
  coursePursuitId: Scalars['String']['output'];
  mutationStatus: MutationStatus;
};

export type CreateGroupSwapRequestSuccessPayload = CreateGroupSwapRequestPayload & {
  __typename?: 'CreateGroupSwapRequestSuccessPayload';
  coursePursuitId: Scalars['String']['output'];
  expirationDate: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  mutationStatus: MutationStatus;
  requestedStudentGroupIds: Array<Scalars['String']['output']>;
};

export type CreateRequirementMutationInput = {
  academicYear: Scalars['Int']['input'];
  departmentId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  ruleId: Scalars['String']['input'];
};

export type CreateRequirementMutationPayload = {
  __typename?: 'CreateRequirementMutationPayload';
  academicYear: Scalars['Int']['output'];
  mutationStatus: MutationStatus;
  requirement?: Maybe<Requirement>;
};

export type CreateRuleRequiredCareerMutationInput = {
  academicYear: Scalars['Int']['input'];
  careerIds: Array<Scalars['String']['input']>;
  departmentId: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CreateRuleRequiredCareerMutationPayload = {
  __typename?: 'CreateRuleRequiredCareerMutationPayload';
  academicYear: Scalars['Int']['output'];
  mutationStatus: MutationStatus;
  rule?: Maybe<Rule>;
};

export type CreateRuleRequiredPlanMutationInput = {
  academicYear: Scalars['Int']['input'];
  departmentId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  plan: RequiredPlanInput;
};

export type CreateRuleRequiredPlanMutationPayload = {
  __typename?: 'CreateRuleRequiredPlanMutationPayload';
  academicYear: Scalars['Int']['output'];
  mutationStatus: MutationStatus;
  rule?: Maybe<Rule>;
};

export type CreateRuleRequiredStudentSetMutationInput = {
  academicYear: Scalars['Int']['input'];
  departmentId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  studentSetId: Scalars['String']['input'];
};

export type CreateRuleRequiredStudentSetMutationPayload = {
  __typename?: 'CreateRuleRequiredStudentSetMutationPayload';
  academicYear: Scalars['Int']['output'];
  mutationStatus: MutationStatus;
  rule?: Maybe<Rule>;
};

export type CreateTopicSetMutationInput = {
  academicYear: Scalars['Int']['input'];
  departmentId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  titleEN: Scalars['String']['input'];
  titleNL: Scalars['String']['input'];
};

export type CreateTopicSetMutationPayload = {
  __typename?: 'CreateTopicSetMutationPayload';
  academicYear: Scalars['Int']['output'];
  mutationStatus: MutationStatus;
  topicSet?: Maybe<TopicSet>;
};

export enum DayOfWeek {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
}

export type DeregisterCourseInput = {
  coursePursuitId: Scalars['String']['input'];
};

export type DeregisterCoursePayload = {
  __typename?: 'DeregisterCoursePayload';
  coursePursuitId: Scalars['String']['output'];
  mutationStatus: MutationStatus;
  removed: Scalars['Boolean']['output'];
};

export enum DeregistrationReason {
  Administrative = 'ADMINISTRATIVE',
  CourseCancelled = 'COURSE_CANCELLED',
  EngineDetectedInactiveEnrolment = 'ENGINE_DETECTED_INACTIVE_ENROLMENT',
  StudentExceedsCreditLimit = 'STUDENT_EXCEEDS_CREDIT_LIMIT',
  StudentFailedRequirements = 'STUDENT_FAILED_REQUIREMENTS',
  StudentQuitsProgramme = 'STUDENT_QUITS_PROGRAMME',
  StudentRequestedDeregistration = 'STUDENT_REQUESTED_DEREGISTRATION',
  StudentSelfDeregistration = 'STUDENT_SELF_DEREGISTRATION',
}

export type DeselectCourseForRegistrationInput = {
  coursePursuitId: Scalars['String']['input'];
};

export type DeselectCourseForRegistrationPayload = {
  __typename?: 'DeselectCourseForRegistrationPayload';
  constrainedChoiceClusterSetting?: Maybe<ConstrainedChoiceClusterSetting>;
  coursePursuitId: Scalars['String']['output'];
  mutationStatus: MutationStatus;
};

export type Diagnostic = {
  __typename?: 'Diagnostic';
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type DirectRegisterForCourseInput = {
  courseOfferingId: Scalars['String']['input'];
  coursePursuitId: Scalars['String']['input'];
};

export type DirectRegisterForCoursePayload = {
  __typename?: 'DirectRegisterForCoursePayload';
  blockReason?: Maybe<RequirementBlockedReason>;
  courseOfferingId?: Maybe<Scalars['String']['output']>;
  coursePursuitId?: Maybe<Scalars['String']['output']>;
  mutationStatus: MutationStatus;
  studentGroup?: Maybe<StudentGroup>;
};

export type DirectRegistrationSettings = {
  __typename?: 'DirectRegistrationSettings';
  directRegistrationType: DirectRegistrationType;
  registrationEndDate: Scalars['DateTime']['output'];
  registrationStartDate: Scalars['DateTime']['output'];
};

export enum DirectRegistrationType {
  DirectRegistrationOnly = 'DIRECT_REGISTRATION_ONLY',
  Disabled = 'DISABLED',
  Tgow = 'TGOW',
}

export type DownloadLink = {
  __typename?: 'DownloadLink';
  url: Scalars['String']['output'];
};

export type DummyAlgorithmInput = {
  rejectCatalogNumbers: Array<Scalars['String']['input']>;
  studentId: Scalars['String']['input'];
};

export type DummyAlgorithmPayload = {
  __typename?: 'DummyAlgorithmPayload';
  mutationStatus: MutationStatus;
};

export type EnableDirectRegistrationInput = {
  studentGroupId: Scalars['String']['input'];
};

export type EnableDirectRegistrationPayload = {
  __typename?: 'EnableDirectRegistrationPayload';
  mutationStatus: MutationStatus;
};

export type Enrolment = {
  __typename?: 'Enrolment';
  career: Career;
  endDate: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  plan: Plan;
  startDate: Scalars['DateTime']['output'];
  subplans: Array<Subplan>;
};

export type ExecuteGroupSwapInput = {
  courseOfferingId?: InputMaybe<Scalars['String']['input']>;
  dryRunExecute: Scalars['Boolean']['input'];
  dryRunExpire: Scalars['Boolean']['input'];
  forceExecuteAllRequests: Scalars['Boolean']['input'];
  notifyStudents: Scalars['Boolean']['input'];
};

export type ExecuteGroupSwapOutput = {
  __typename?: 'ExecuteGroupSwapOutput';
  completedRequests: Array<GroupSwapRequest>;
  expiredRequests: Array<GroupSwapRequest>;
  mutationStatus: MutationStatus;
};

export type ForceRemoveCourseInput = {
  coursePursuitId: Scalars['String']['input'];
  studentId: Scalars['String']['input'];
};

export type ForceRemoveCoursePayload = {
  __typename?: 'ForceRemoveCoursePayload';
  mutationStatus: MutationStatus;
};

export type ForceUpdateConstrainedChoiceSettingInput = {
  credits: Scalars['Float']['input'];
  pursuitIds: Array<Scalars['String']['input']>;
  registrationLimit: Scalars['Int']['input'];
  settingsId: Scalars['String']['input'];
  studentId: Scalars['String']['input'];
};

export type ForceUpdateConstrainedChoiceSettingPayload = {
  __typename?: 'ForceUpdateConstrainedChoiceSettingPayload';
  mutationStatus: MutationStatus;
};

export type GroupRequirementNode = {
  description: BilingualString;
  id: Scalars['String']['output'];
  type: RequirementNodeType;
};

export type GroupSwapRequest = {
  __typename?: 'GroupSwapRequest';
  courseOfferingId: Scalars['String']['output'];
  coursePursuitId: Scalars['String']['output'];
  creationDate: Scalars['DateTime']['output'];
  expirationDate: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  /** admin */
  newStudentGroup?: Maybe<StudentGroup>;
  newStudentGroupId?: Maybe<Scalars['String']['output']>;
  processedBy?: Maybe<Scalars['String']['output']>;
  requestedStudentGroupIds: Array<Scalars['String']['output']>;
  status: GroupSwapStatus;
  /** admin */
  student: Student;
  /** admin */
  studentGroup?: Maybe<StudentGroup>;
  studentGroupId: Scalars['String']['output'];
  studentId: Scalars['String']['output'];
  updateDate: Scalars['DateTime']['output'];
};

export enum GroupSwapStatus {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Expired = 'EXPIRED',
  Pending = 'PENDING',
}

export type InfoRequirement = RequirementNode & {
  __typename?: 'InfoRequirement';
  description: BilingualString;
  id: Scalars['String']['output'];
  type: RequirementNodeType;
};

export enum Language {
  Eng = 'ENG',
  Nld = 'NLD',
}

export type LockStudentGroupInput = {
  studentGroupId: Scalars['String']['input'];
};

export type LockStudentGroupPayload = {
  __typename?: 'LockStudentGroupPayload';
  mutationStatus: MutationStatus;
  studentGroupId: Scalars['String']['output'];
};

export type MaintenanceMessage = {
  __typename?: 'MaintenanceMessage';
  message: Scalars['String']['output'];
  severity: MaintenanceMessageSeverity;
};

export enum MaintenanceMessageSeverity {
  Error = 'ERROR',
  Info = 'INFO',
  Warning = 'WARNING',
}

export type MarblesAcademicYear = {
  __typename?: 'MarblesAcademicYear';
  endDate: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  isArchived: Scalars['Boolean']['output'];
  isCurrentAcademicYearForAdmins: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  startDate: Scalars['DateTime']['output'];
};

export type MarblesAuthorisationRights = {
  __typename?: 'MarblesAuthorisationRights';
  departments: Array<MarblesDepartment>;
  userRoles: Array<UserRole>;
};

export type MarblesAuthoriseUserMutationInput = {
  departmentId: Scalars['String']['input'];
  role: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type MarblesAuthoriseUserMutationPayload = {
  __typename?: 'MarblesAuthoriseUserMutationPayload';
  mutationStatus: MutationStatus;
  roleAssignment?: Maybe<RoleAssignment>;
  username: Scalars['String']['output'];
};

export type MarblesCourseInfo = {
  __typename?: 'MarblesCourseInfo';
  catalogNumber: Scalars['String']['output'];
  ec: Scalars['Float']['output'];
  id: Scalars['String']['output'];
  relevantOfferings: Array<MarblesCourseOffering>;
  title: BilingualString;
};

export type MarblesCourseInfoRelevantOfferingsArgs = {
  semesterId: Scalars['String']['input'];
};

export type MarblesCourseOffering = {
  __typename?: 'MarblesCourseOffering';
  academicYear: MarblesAcademicYear;
  availableForRegistration: Scalars['Boolean']['output'];
  capacity?: Maybe<CourseOfferingCapacity>;
  constrainedChoiceCluster?: Maybe<ConstrainedChoiceCluster>;
  constrainedChoiceClusterId?: Maybe<Scalars['String']['output']>;
  credits: Scalars['Float']['output'];
  /** admin */
  department: MarblesDepartment;
  /** admin */
  departmentId: Scalars['String']['output'];
  directRegistrationEnabled: Scalars['Boolean']['output'];
  directRegistrationSettings: DirectRegistrationSettings;
  finalDeregistrationDate?: Maybe<Scalars['DateTime']['output']>;
  /** admin */
  groupSwapEnabled: Scalars['Boolean']['output'];
  groupSwapExpirationDate: Scalars['DateTime']['output'];
  hasCreditLimitException: Scalars['Boolean']['output'];
  hasRequirements: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  info?: Maybe<MarblesCourseInfo>;
  /** admin */
  numberOfPendingStudents: Scalars['Int']['output'];
  /** admin */
  numberOfRegisteredStudents: Scalars['Int']['output'];
  periods: Array<Period>;
  registrationPeriod: Period;
  relevantTopicIds: Array<Scalars['String']['output']>;
  /** admin */
  requirementNodeId?: Maybe<Scalars['String']['output']>;
  semesters: Array<Semester>;
  status: MarblesCourseOfferingStatus;
  /** admin */
  studentGroups: Array<StudentGroup>;
  timeTableUrl?: Maybe<Scalars['String']['output']>;
  topicCapacities: Array<TopicCapacity>;
  topicSet?: Maybe<TopicSet>;
};

export type MarblesCourseOfferingAvailableForRegistrationArgs = {
  semesterId: Scalars['String']['input'];
};

export type MarblesCourseOfferingCreditsArgs = {
  semesterId: Scalars['String']['input'];
};

export enum MarblesCourseOfferingStatus {
  ActiveClosed = 'ACTIVE_CLOSED',
  ActiveOpen = 'ACTIVE_OPEN',
  DirectRegistrationOnly = 'DIRECT_REGISTRATION_ONLY',
  Inactive = 'INACTIVE',
}

export type MarblesCourseSearchResult = {
  __typename?: 'MarblesCourseSearchResult';
  courses: Array<MarblesCourseInfo>;
  totalCount: Scalars['Int']['output'];
};

export type MarblesDepartment = {
  __typename?: 'MarblesDepartment';
  allowedUserActions: Array<UserAction>;
  code: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: BilingualString;
  parentId?: Maybe<Scalars['String']['output']>;
};

export type MarblesLiftSuspensionMutationPayload = {
  __typename?: 'MarblesLiftSuspensionMutationPayload';
  mutationStatus: MutationStatus;
};

export type MarblesRevokeAuthorisationMutationInput = {
  id: Scalars['Int']['input'];
};

export type MarblesRevokeAuthorisationMutationPayload = {
  __typename?: 'MarblesRevokeAuthorisationMutationPayload';
  mutationStatus: MutationStatus;
};

export type MarblesUser = {
  __typename?: 'MarblesUser';
  familyName: Scalars['String']['output'];
  givenName: Scalars['String']['output'];
  isAdminPanelUser: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  roleAssignments: Array<RoleAssignment>;
  status: UserStatus;
  student?: Maybe<Student>;
  studentId?: Maybe<Scalars['String']['output']>;
  username: Scalars['String']['output'];
};

export type MarkPursuitsForDeregistrationPayload = {
  __typename?: 'MarkPursuitsForDeregistrationPayload';
  rows: Array<MarkPursuitsForDeregistrationPayloadRow>;
};

export type MarkPursuitsForDeregistrationPayloadRow = {
  __typename?: 'MarkPursuitsForDeregistrationPayloadRow';
  pursuitId: Scalars['String']['output'];
  studentId: Scalars['String']['output'];
};

export type MigrationIssue = {
  __typename?: 'MigrationIssue';
  missingClusterId?: Maybe<Scalars['String']['output']>;
  missingRequirementId?: Maybe<Scalars['String']['output']>;
  missingTopicSetId?: Maybe<Scalars['String']['output']>;
  offeringId: Scalars['String']['output'];
};

export enum MissingColumn {
  CatalogNumber = 'CATALOG_NUMBER',
  ClassNumber = 'CLASS_NUMBER',
  CreditLimitException = 'CREDIT_LIMIT_EXCEPTION',
  GroupCapacity = 'GROUP_CAPACITY',
  GroupCode = 'GROUP_CODE',
  Period = 'PERIOD',
  StudentId = 'STUDENT_ID',
  Topic = 'TOPIC',
}

export type Mutation = {
  __typename?: 'Mutation';
  /** algorithm/admin */
  acceptPendingCoursePursuit: AcceptPendingCoursePursuitPayload;
  /** student */
  acknowledgeRejectedCourse: AcknowledgeRejectedCoursePayload;
  /** admin */
  addApiKey: AddApiKeyPayload;
  /** admin */
  addInfoRequirement: AddInfoRequirementMutationPayload;
  /** student */
  addMarblesCourse: AddMarblesCoursePayload;
  /** admin */
  addRequiredCourses: AddRequiredCoursesMutationPayload;
  /** admin */
  addRequiredPlan: AddRequiredPlanMutationPayload;
  /** admin */
  addTopic: AddTopicMutationPayload;
  /** admin */
  administrativelySelectCourseForRegistration: AdministrativelySelectCourseForRegistrationPayload;
  /** admin */
  annualTransitionCopyRequirementMutation: AnnualTransitionCopyRequirementsPayload;
  /** admin */
  annualTransitionMutation: AnnualTransitionMutationPayload;
  /** admin */
  bulkAcceptRejectCourses: BulkAcceptRejectCoursesPayload;
  /** admin */
  bulkDeregisterMarkedPursuits: BulkDeregisterMarkedPursuitsPayload;
  /** admin */
  bulkRejectInactiveStudents: BulkRejectInactiveStudentsPayload;
  /** student */
  cancelGroupSwapRequest: CancelGroupSwapRequestPayload;
  /** admin */
  clearTimetablePreferences: ClearTimetablePreferencesPayload;
  /** admin */
  copyRequirement: CopyRequirementMutationPayload;
  /** admin */
  copyRule: CopyRuleMutationPayload;
  /** admin */
  createConstrainedChoiceCluster: CreateConstrainedChoiceClusterMutationPayload;
  /** student */
  createGroupSwapRequest: CreateGroupSwapRequestPayload;
  /** admin */
  createRequirement: CreateRequirementMutationPayload;
  /** admin */
  createRuleRequiredCareer: CreateRuleRequiredCareerMutationPayload;
  /** admin */
  createRuleRequiredPlan: CreateRuleRequiredPlanMutationPayload;
  /** admin */
  createRuleRequiredStudentSet: CreateRuleRequiredStudentSetMutationPayload;
  /** admin */
  createTopicSet: CreateTopicSetMutationPayload;
  /** student */
  deregisterCourse: DeregisterCoursePayload;
  /** admin */
  deregisterStudentForCourse: AdministrativelyDeregisterStudentForCoursePayload;
  /** student */
  deselectCourseForRegistration: DeselectCourseForRegistrationPayload;
  /** student */
  directRegisterForCourse: DirectRegisterForCoursePayload;
  /** algorithm/admin */
  dummyAlgorithm: DummyAlgorithmPayload;
  /** admin */
  enableDirectRegistration: EnableDirectRegistrationPayload;
  /** admin */
  executeGroupSwap: ExecuteGroupSwapOutput;
  /** admin */
  fixCounters: Scalars['Boolean']['output'];
  /** admin */
  forceRemoveCourse: ForceRemoveCoursePayload;
  /** admin */
  forceUpdateConstrainedChoiceSetting: ForceUpdateConstrainedChoiceSettingPayload;
  /** admin */
  lockStudentGroup: LockStudentGroupPayload;
  /** admin */
  marblesAuthoriseUser: MarblesAuthoriseUserMutationPayload;
  /** admin */
  marblesClearTokenCache: Scalars['Boolean']['output'];
  /** admin */
  marblesLiftSuspension: MarblesLiftSuspensionMutationPayload;
  /** admin */
  marblesRevokeAuthorisation: MarblesRevokeAuthorisationMutationPayload;
  /** admin */
  marblesSetNonAdminsToReadOnly: MutationStatus;
  /** admin */
  markPursuitsForDeregistration: MarkPursuitsForDeregistrationPayload;
  /** admin */
  processGroupSwap: ProcessGroupSwapPayload;
  /** admin */
  queueNotifications: QueueNotificationsPayload;
  /** admin */
  refreshCache: Scalars['Boolean']['output'];
  /** admin */
  registerStudentForCourse: RegisterStudentForCoursePayload;
  /** admin */
  rejectPendingCoursePursuit: RejectPendingCoursePursuitPayload;
  /** admin */
  removeApiKey: Scalars['Boolean']['output'];
  /** admin */
  removeConstrainedChoiceCluster: RemoveConstrainedChoiceClusterPayload;
  /** admin */
  removeInfoRequirement: RemoveInfoRequirementMutationPayload;
  /** student */
  removeMarblesCourse: RemoveMarblesCoursePayload;
  /** admin */
  removeRequiredCourses: RemoveRequiredCoursesMutationPayload;
  /** admin */
  removeRequiredPlan: RemoveRequiredPlanMutationPayload;
  /** admin */
  removeRequirement: RemoveRequirementMutationPayload;
  /** admin */
  removeRule: RemoveRuleMutationPayload;
  /** admin */
  removeTopic: RemoveTopicPayload;
  /** admin */
  removeTopicSet: RemoveTopicSetPayload;
  /** admin */
  replaceCourse: ReplaceCoursePayload;
  /** student */
  requestCourseRegistrationConfirmationEmail: RequestCourseRegistrationConfirmationEmailPayload;
  /** admin */
  resetCreditLimitExceptions: ResetCreditLimitExceptionsPayload;
  /** student */
  selectCourseForRegistration: SelectCourseForRegistrationPayload;
  /** admin */
  semesterTransitionMutation: SemesterTransitionMutationPayload;
  /** admin */
  sendMail: SendMailOutput;
  /** admin */
  setCourseOfferingConstrainedChoiceCluster: SetCourseOfferingConstrainedChoiceClusterPayload;
  /** admin */
  setCourseOfferingCreditLimitException: SetCourseOfferingCreditLimitExceptionPayload;
  /** admin */
  setCourseOfferingRequirementNode: SetCourseOfferingRequirementNodePayload;
  /** admin */
  setCourseOfferingStatus: SetCourseOfferingStatusPayload;
  /** admin */
  setCourseOfferingTopicSet: SetCourseOfferingTopicSetPayload;
  /** admin */
  setCoursePursuitTopic: SetCoursePursuitTopicPayload;
  /** admin */
  setCreditLimitException: SetCreditLimitExceptionPayload;
  /** admin */
  setGroupSwapEnabled: SetGroupSwapEnabledPayload;
  /** admin */
  setStudentGroupCapacity: SetStudentGroupCapacityPayload;
  /** admin */
  setStudentGroupTopic: SetStudentGroupTopicPayload;
  /** admin */
  setStudentTimetablePreferences: SetStudentTimetablePreferencesPayload;
  /** student */
  setTimetablePreferences: SetTimetablePreferencesPayload;
  /** admin */
  switchStudentGroup: SwitchStudentGroupPayload;
  /** admin */
  timeTravelTo: TimeTravelToPayload;
  /** admin */
  timeTravelToOffset: TimeTravelToPayload;
  /** admin */
  unlockStudentGroup: UnlockStudentGroupPayload;
  /** admin */
  updateConstrainedChoiceCluster: UpdateConstrainedChoiceClusterMutationPayload;
  /** student */
  updateConstrainedChoiceSetting: UpdateConstrainedChoiceSettingPayload;
  /** admin */
  updateInfoRequirement: UpdateInfoRequirementMutationPayload;
  /** admin */
  updateRequiredCareer: UpdateRequiredCareerMutationPayload;
  /** admin */
  updateRequiredCourses: UpdateRequiredCoursesMutationPayload;
  /** admin */
  updateRequiredPlan: UpdateRequiredPlanMutationPayload;
  /** admin */
  updateRequiredStudentSet: UpdateRequiredStudentSetMutationPayload;
  /** admin */
  updateRequirementName: UpdateRequirementNameMutationPayload;
  /** admin */
  updateRequirementRules: UpdateRequirementRulesMutationPayload;
  /** admin */
  updateRuleName: UpdateRuleNameMutationPayload;
  /** admin */
  updateTopic: UpdateTopicMutationPayload;
  /** admin */
  updateTopicSetName: UpdateTopicSetNameMutationPayload;
  /** admin */
  uploadBulkCourseDeregistrations: UploadBulkCourseDeregistrationsPayload;
  /** admin */
  uploadBulkCourseRegistrations: UploadBulkCourseRegistrationsPayload;
  /** admin */
  uploadCourseDeregistrations: UploadCourseDeregistrationsPayload;
  /** admin */
  uploadCoursePendingStudents: UploadCoursePendingStudentsPayload;
  /** admin */
  uploadCourseRegistrations: UploadCourseRegistrationsPayload;
  /** admin */
  uploadCreditLimitExceptions: UploadCreditLimitExceptionsPayload;
  /** admin */
  uploadGroupSettings: UploadGroupSettingsPayload;
};

export type MutationAcceptPendingCoursePursuitArgs = {
  input: AcceptPendingCoursePursuitInput;
};

export type MutationAcknowledgeRejectedCourseArgs = {
  input: AcknowledgeRejectedCourseInput;
};

export type MutationAddApiKeyArgs = {
  input: AddApiKeyInput;
};

export type MutationAddInfoRequirementArgs = {
  input: AddInfoRequirementMutationInput;
};

export type MutationAddMarblesCourseArgs = {
  input: AddMarblesCourseInput;
};

export type MutationAddRequiredCoursesArgs = {
  input: AddRequiredCoursesMutationInput;
};

export type MutationAddRequiredPlanArgs = {
  input: AddRequiredPlanMutationInput;
};

export type MutationAddTopicArgs = {
  input: AddTopicMutationInput;
};

export type MutationAdministrativelySelectCourseForRegistrationArgs = {
  input: AdministrativelySelectCourseForRegistrationInput;
};

export type MutationAnnualTransitionCopyRequirementMutationArgs = {
  input: AnnualTransitionCopyRequirementsInput;
};

export type MutationAnnualTransitionMutationArgs = {
  input: AnnualTransitionMutationInput;
};

export type MutationBulkAcceptRejectCoursesArgs = {
  input: BulkAcceptRejectCoursesInput;
};

export type MutationBulkDeregisterMarkedPursuitsArgs = {
  deregisterNow: Scalars['Boolean']['input'];
  dryRun: Scalars['Boolean']['input'];
};

export type MutationBulkRejectInactiveStudentsArgs = {
  academicYear: Scalars['Int']['input'];
  dryRun: Scalars['Boolean']['input'];
};

export type MutationCancelGroupSwapRequestArgs = {
  input: CancelGroupSwapRequestInput;
};

export type MutationClearTimetablePreferencesArgs = {
  input: ClearTimetablePreferencesInput;
};

export type MutationCopyRequirementArgs = {
  input: CopyRequirementMutationInput;
};

export type MutationCopyRuleArgs = {
  input: CopyRuleMutationInput;
};

export type MutationCreateConstrainedChoiceClusterArgs = {
  input: CreateConstrainedChoiceClusterMutationInput;
};

export type MutationCreateGroupSwapRequestArgs = {
  input: CreateGroupSwapRequestInput;
};

export type MutationCreateRequirementArgs = {
  input: CreateRequirementMutationInput;
};

export type MutationCreateRuleRequiredCareerArgs = {
  input: CreateRuleRequiredCareerMutationInput;
};

export type MutationCreateRuleRequiredPlanArgs = {
  input: CreateRuleRequiredPlanMutationInput;
};

export type MutationCreateRuleRequiredStudentSetArgs = {
  input: CreateRuleRequiredStudentSetMutationInput;
};

export type MutationCreateTopicSetArgs = {
  input: CreateTopicSetMutationInput;
};

export type MutationDeregisterCourseArgs = {
  input: DeregisterCourseInput;
};

export type MutationDeregisterStudentForCourseArgs = {
  input: AdministrativelyDeregisterStudentForCourseInput;
};

export type MutationDeselectCourseForRegistrationArgs = {
  input: DeselectCourseForRegistrationInput;
};

export type MutationDirectRegisterForCourseArgs = {
  input: DirectRegisterForCourseInput;
};

export type MutationDummyAlgorithmArgs = {
  input: DummyAlgorithmInput;
};

export type MutationEnableDirectRegistrationArgs = {
  input: EnableDirectRegistrationInput;
};

export type MutationExecuteGroupSwapArgs = {
  input: ExecuteGroupSwapInput;
};

export type MutationFixCountersArgs = {
  courseOfferingId: Scalars['String']['input'];
};

export type MutationForceRemoveCourseArgs = {
  input: ForceRemoveCourseInput;
};

export type MutationForceUpdateConstrainedChoiceSettingArgs = {
  input: ForceUpdateConstrainedChoiceSettingInput;
};

export type MutationLockStudentGroupArgs = {
  input: LockStudentGroupInput;
};

export type MutationMarblesAuthoriseUserArgs = {
  input: MarblesAuthoriseUserMutationInput;
};

export type MutationMarblesClearTokenCacheArgs = {
  username?: InputMaybe<Scalars['String']['input']>;
};

export type MutationMarblesLiftSuspensionArgs = {
  username: Scalars['String']['input'];
};

export type MutationMarblesRevokeAuthorisationArgs = {
  input: MarblesRevokeAuthorisationMutationInput;
};

export type MutationMarblesSetNonAdminsToReadOnlyArgs = {
  readOnly: Scalars['Boolean']['input'];
};

export type MutationMarkPursuitsForDeregistrationArgs = {
  dryRun: Scalars['Boolean']['input'];
};

export type MutationProcessGroupSwapArgs = {
  input: ProcessGroupSwapInput;
};

export type MutationQueueNotificationsArgs = {
  input: QueueNotificationsInput;
};

export type MutationRegisterStudentForCourseArgs = {
  input: RegisterStudentForCourseInput;
};

export type MutationRejectPendingCoursePursuitArgs = {
  input: RejectPendingCoursePursuitInput;
};

export type MutationRemoveApiKeyArgs = {
  username: Scalars['String']['input'];
};

export type MutationRemoveConstrainedChoiceClusterArgs = {
  input: RemoveConstrainedChoiceClusterInput;
};

export type MutationRemoveInfoRequirementArgs = {
  input: RemoveInfoRequirementMutationInput;
};

export type MutationRemoveMarblesCourseArgs = {
  input: RemoveMarblesCourseInput;
};

export type MutationRemoveRequiredCoursesArgs = {
  input: RemoveRequiredCoursesMutationInput;
};

export type MutationRemoveRequiredPlanArgs = {
  input: RemoveRequiredPlanMutationInput;
};

export type MutationRemoveRequirementArgs = {
  input: RemoveRequirementMutationInput;
};

export type MutationRemoveRuleArgs = {
  input: RemoveRuleMutationInput;
};

export type MutationRemoveTopicArgs = {
  input: RemoveTopicInput;
};

export type MutationRemoveTopicSetArgs = {
  input: RemoveTopicSetInput;
};

export type MutationReplaceCourseArgs = {
  input: ReplaceCourseInput;
};

export type MutationResetCreditLimitExceptionsArgs = {
  dryRun: Scalars['Boolean']['input'];
};

export type MutationSelectCourseForRegistrationArgs = {
  input: SelectCourseForRegistrationInput;
};

export type MutationSemesterTransitionMutationArgs = {
  input: SemesterTransitionMutationInput;
};

export type MutationSendMailArgs = {
  input: SendMailInput;
};

export type MutationSetCourseOfferingConstrainedChoiceClusterArgs = {
  input: SetCourseOfferingConstrainedChoiceClusterInput;
};

export type MutationSetCourseOfferingCreditLimitExceptionArgs = {
  input: SetCourseOfferingCreditLimitExceptionInput;
};

export type MutationSetCourseOfferingRequirementNodeArgs = {
  input: SetCourseOfferingRequirementNodeInput;
};

export type MutationSetCourseOfferingStatusArgs = {
  input: SetCourseOfferingStatusInput;
};

export type MutationSetCourseOfferingTopicSetArgs = {
  input: SetCourseOfferingTopicSetInput;
};

export type MutationSetCoursePursuitTopicArgs = {
  input: SetCoursePursuitTopicInput;
};

export type MutationSetCreditLimitExceptionArgs = {
  input: SetCreditLimitExceptionInput;
};

export type MutationSetGroupSwapEnabledArgs = {
  input: SetGroupSwapEnabledInput;
};

export type MutationSetStudentGroupCapacityArgs = {
  input: SetStudentGroupCapacityInput;
};

export type MutationSetStudentGroupTopicArgs = {
  input: SetStudentGroupTopicInput;
};

export type MutationSetStudentTimetablePreferencesArgs = {
  input: SetStudentTimetablePreferencesInput;
};

export type MutationSetTimetablePreferencesArgs = {
  input: SetTimetablePreferencesInput;
};

export type MutationSwitchStudentGroupArgs = {
  input: SwitchStudentGroupInput;
};

export type MutationTimeTravelToArgs = {
  beforeOrAfter: BeforeOrAfter;
  minutes: Scalars['Int']['input'];
  moment: RegistrationRoundMoment;
  registrationRoundId: Scalars['String']['input'];
};

export type MutationTimeTravelToOffsetArgs = {
  offsetTicks: Scalars['Long']['input'];
};

export type MutationUnlockStudentGroupArgs = {
  input: UnlockStudentGroupInput;
};

export type MutationUpdateConstrainedChoiceClusterArgs = {
  input: UpdateConstrainedChoiceClusterMutationInput;
};

export type MutationUpdateConstrainedChoiceSettingArgs = {
  input: UpdateConstrainedChoiceSettingInput;
};

export type MutationUpdateInfoRequirementArgs = {
  input: UpdateInfoRequirementMutationInput;
};

export type MutationUpdateRequiredCareerArgs = {
  input: UpdateRequiredCareerMutationInput;
};

export type MutationUpdateRequiredCoursesArgs = {
  input: UpdateRequiredCoursesMutationInput;
};

export type MutationUpdateRequiredPlanArgs = {
  input: UpdateRequiredPlanMutationInput;
};

export type MutationUpdateRequiredStudentSetArgs = {
  input: UpdateRequiredStudentSetMutationInput;
};

export type MutationUpdateRequirementNameArgs = {
  input: UpdateRequirementNameMutationInput;
};

export type MutationUpdateRequirementRulesArgs = {
  input: UpdateRequirementRulesMutationInput;
};

export type MutationUpdateRuleNameArgs = {
  input: UpdateRuleNameMutationInput;
};

export type MutationUpdateTopicArgs = {
  input: UpdateTopicMutationInput;
};

export type MutationUpdateTopicSetNameArgs = {
  input: UpdateTopicSetNameMutationInput;
};

export type MutationUploadBulkCourseDeregistrationsArgs = {
  input: UploadBulkCourseDeregistrationsInput;
};

export type MutationUploadBulkCourseRegistrationsArgs = {
  input: UploadBulkCourseRegistrationsInput;
};

export type MutationUploadCourseDeregistrationsArgs = {
  input: UploadCourseDeregistrationsInput;
};

export type MutationUploadCoursePendingStudentsArgs = {
  input: UploadCoursePendingStudentsInput;
};

export type MutationUploadCourseRegistrationsArgs = {
  input: UploadCourseRegistrationsInput;
};

export type MutationUploadCreditLimitExceptionsArgs = {
  input: UploadCreditLimitExceptionsInput;
};

export type MutationUploadGroupSettingsArgs = {
  input: UploadGroupSettingsInput;
};

export enum MutationStatus {
  CareerNotFound = 'CAREER_NOT_FOUND',
  ClusterFull = 'CLUSTER_FULL',
  ClusterLimitExceedsPursuits = 'CLUSTER_LIMIT_EXCEEDS_PURSUITS',
  ClusterMaxLimitExceeded = 'CLUSTER_MAX_LIMIT_EXCEEDED',
  ConstrainedChoiceClusterNotFound = 'CONSTRAINED_CHOICE_CLUSTER_NOT_FOUND',
  Corrupt = 'CORRUPT',
  CourseNotFound = 'COURSE_NOT_FOUND',
  CourseOfferingAmbivalent = 'COURSE_OFFERING_AMBIVALENT',
  CourseOfferingCreditLimitException = 'COURSE_OFFERING_CREDIT_LIMIT_EXCEPTION',
  CourseOfferingInactive = 'COURSE_OFFERING_INACTIVE',
  CourseOfferingNotFound = 'COURSE_OFFERING_NOT_FOUND',
  CourseOfferingPendingPursuits = 'COURSE_OFFERING_PENDING_PURSUITS',
  CreditLimitExceeded = 'CREDIT_LIMIT_EXCEEDED',
  Duplicate = 'DUPLICATE',
  Error = 'ERROR',
  GroupFull = 'GROUP_FULL',
  GroupNotFound = 'GROUP_NOT_FOUND',
  Inactive = 'INACTIVE',
  Locked = 'LOCKED',
  MaximumReached = 'MAXIMUM_REACHED',
  NotFound = 'NOT_FOUND',
  NoActiveEnrolment = 'NO_ACTIVE_ENROLMENT',
  PlanNotFound = 'PLAN_NOT_FOUND',
  PursuitDuplicatePending = 'PURSUIT_DUPLICATE_PENDING',
  PursuitDuplicateRegistered = 'PURSUIT_DUPLICATE_REGISTERED',
  PursuitNotFound = 'PURSUIT_NOT_FOUND',
  RegistrationClosed = 'REGISTRATION_CLOSED',
  RequirementsNotMet = 'REQUIREMENTS_NOT_MET',
  RequirementNotAvailable = 'REQUIREMENT_NOT_AVAILABLE',
  RequirementNotFound = 'REQUIREMENT_NOT_FOUND',
  StudentNotFound = 'STUDENT_NOT_FOUND',
  StudentSetNotFound = 'STUDENT_SET_NOT_FOUND',
  SubplanNotFound = 'SUBPLAN_NOT_FOUND',
  Success = 'SUCCESS',
  TopicHasPendingPursuit = 'TOPIC_HAS_PENDING_PURSUIT',
  TopicMissing = 'TOPIC_MISSING',
  TopicNotFound = 'TOPIC_NOT_FOUND',
  TopicSetNotConfigured = 'TOPIC_SET_NOT_CONFIGURED',
  TopicSetNotFound = 'TOPIC_SET_NOT_FOUND',
  Unauthorized = 'UNAUTHORIZED',
  UploadMissingColumns = 'UPLOAD_MISSING_COLUMNS',
  UserSuspended = 'USER_SUSPENDED',
}

export enum NotificationType {
  AllocationResult = 'ALLOCATION_RESULT',
  CourseRegistrationConfirmation = 'COURSE_REGISTRATION_CONFIRMATION',
  GroupSwapExpired = 'GROUP_SWAP_EXPIRED',
  GroupSwapSuccess = 'GROUP_SWAP_SUCCESS',
  SurveyInvite = 'SURVEY_INVITE',
}

export enum PendingOrRegistered {
  Pending = 'PENDING',
  PendingRegistered = 'PENDING_REGISTERED',
  Registered = 'REGISTERED',
  Rejected = 'REJECTED',
}

export type Period = {
  __typename?: 'Period';
  academicYear: MarblesAcademicYear;
  endDate: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  number: Scalars['Int']['output'];
  periodInSemester: Scalars['Int']['output'];
  semester: Semester;
  semesterNumber: Scalars['Int']['output'];
  startDate: Scalars['DateTime']['output'];
};

export type Plan = {
  __typename?: 'Plan';
  code: Scalars['String']['output'];
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  subplans: Array<Subplan>;
};

export type ProcessGroupSwapInput = {
  groupSwapRequestId: Scalars['String']['input'];
  studentGroupId: Scalars['String']['input'];
};

export type ProcessGroupSwapPayload = {
  __typename?: 'ProcessGroupSwapPayload';
  groupSwapRequest?: Maybe<GroupSwapRequest>;
  mutationStatus: MutationStatus;
  studentGroups: Array<StudentGroup>;
};

export type PursuitDiagnostics = {
  __typename?: 'PursuitDiagnostics';
  allClusterSettings: Array<ConstrainedChoiceClusterSetting>;
  clusterSettingIds: Array<Scalars['String']['output']>;
  pursuitId: Scalars['String']['output'];
  status?: Maybe<CoursePursuitStatus>;
  studentId: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'Query';
  benchmarkCourseOfferingRequirements?: Maybe<BenchmarkResults>;
  careers: Array<Career>;
  constrainedChoiceCluster?: Maybe<ConstrainedChoiceCluster>;
  constrainedChoiceClusterSettings: Array<ConstrainedChoiceClusterSetting>;
  constrainedChoiceClusters: Array<ConstrainedChoiceCluster>;
  courseOfferingGroupSwaps: Array<GroupSwapRequest>;
  courseOfferingPendingGroupSwaps: Array<CourseOfferingPendingGroupSwap>;
  courseOfferingPursuits: Array<CoursePursuit>;
  courseOfferingRejections: Array<Rejection>;
  courseOfferingRequirementCapacity: Array<CourseOfferingRequirementCapacity>;
  courseOfferingRequirementCheck?: Maybe<Requirements>;
  courseOfferingRequirementsExport: DownloadLink;
  courseOfferingSwappableGroups: Array<SwappableStudentGroup>;
  courseOfferingTimetableActivities: Array<TimetableActivity>;
  courseOfferingTopicsCapacityQuery: Array<CourseOfferingTopicCapacity>;
  courseOfferingsExport: DownloadLink;
  courseOfferingsFilter: CourseOfferingsFilter;
  coursePursuit?: Maybe<CoursePursuit>;
  coursePursuitRequirements?: Maybe<Requirements>;
  coursePursuits: Array<CoursePursuit>;
  courseRegistrationsExport: DownloadLink;
  diagnostics: Array<Diagnostic>;
  groupSwapRequest?: Maybe<GroupSwapRequest>;
  maintenanceMessages: Array<MaintenanceMessage>;
  marblesAcademicYears: Array<MarblesAcademicYear>;
  marblesAuthorisationRights: MarblesAuthorisationRights;
  marblesCourseInfo?: Maybe<MarblesCourseInfo>;
  marblesCourseOffering?: Maybe<MarblesCourseOffering>;
  marblesCourseOfferings: Array<MarblesCourseOffering>;
  marblesCourses: MarblesCourseSearchResult;
  marblesDepartments: Array<MarblesDepartment>;
  /** me */
  marblesMe: MarblesUser;
  marblesUser: MarblesUser;
  marblesUsers: Array<MarblesUser>;
  periods: Array<Period>;
  plan?: Maybe<Plan>;
  /** admin */
  pursuitDiagnostics: PursuitDiagnostics;
  registrationRoundStatistics: RegistrationRoundStatistics;
  registrationRounds: Array<RegistrationRound>;
  requirement?: Maybe<Requirement>;
  requirementCopyDepartments: Array<MarblesDepartment>;
  requirements: Array<Requirement>;
  rule?: Maybe<Rule>;
  rules: Array<Rule>;
  semesters: Array<Semester>;
  student?: Maybe<Student>;
  studentGroup?: Maybe<StudentGroup>;
  studentGroupSwaps: Array<GroupSwapRequest>;
  studentGroups: Array<StudentGroup>;
  studentGroupsExport: DownloadLink;
  studentPursuitAudit: Array<CoursePursuitAudit>;
  studentPursuits: Array<CoursePursuit>;
  studentRequirementsCheck?: Maybe<StudentRequirementsCheckPayload>;
  studentSet?: Maybe<StudentSet>;
  studentSets: Array<StudentSet>;
  studentTimetableActivities: Array<TimetableActivity>;
  students: StudentSearchResult;
  timetablePreferences: TimetablePreferences;
  topicSet?: Maybe<TopicSet>;
  topicSetCreateDepartments: Array<MarblesDepartment>;
  topicSets: Array<TopicSet>;
};

export type QueryBenchmarkCourseOfferingRequirementsArgs = {
  courseOfferingId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryConstrainedChoiceClusterArgs = {
  constrainedChoiceClusterId: Scalars['String']['input'];
};

export type QueryConstrainedChoiceClustersArgs = {
  academicYear?: InputMaybe<Scalars['Int']['input']>;
  departmentId: Scalars['String']['input'];
};

export type QueryCourseOfferingGroupSwapsArgs = {
  courseOfferingId: Scalars['String']['input'];
};

export type QueryCourseOfferingPendingGroupSwapsArgs = {
  academicYear: Scalars['Int']['input'];
  departmentId: Scalars['String']['input'];
};

export type QueryCourseOfferingPursuitsArgs = {
  courseOfferingId: Scalars['String']['input'];
  status?: InputMaybe<CoursePursuitStatus>;
};

export type QueryCourseOfferingRejectionsArgs = {
  courseOfferingId: Scalars['String']['input'];
};

export type QueryCourseOfferingRequirementCapacityArgs = {
  courseOfferingId: Scalars['String']['input'];
};

export type QueryCourseOfferingRequirementCheckArgs = {
  courseOfferingId: Scalars['String']['input'];
};

export type QueryCourseOfferingRequirementsExportArgs = {
  academicYear?: Scalars['Int']['input'];
  departmentId: Scalars['String']['input'];
  language?: Language;
};

export type QueryCourseOfferingSwappableGroupsArgs = {
  courseOfferingId: Scalars['String']['input'];
};

export type QueryCourseOfferingTimetableActivitiesArgs = {
  courseOfferingId: Scalars['String']['input'];
};

export type QueryCourseOfferingTopicsCapacityQueryArgs = {
  courseOfferingId: Scalars['String']['input'];
};

export type QueryCourseOfferingsExportArgs = {
  academicYear?: Scalars['Int']['input'];
  departmentId: Scalars['String']['input'];
  language?: Language;
};

export type QueryCoursePursuitArgs = {
  coursePursuitId: Scalars['String']['input'];
};

export type QueryCoursePursuitRequirementsArgs = {
  coursePursuitId: Scalars['String']['input'];
};

export type QueryCoursePursuitsArgs = {
  semesterId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryCourseRegistrationsExportArgs = {
  academicYear?: InputMaybe<Scalars['Int']['input']>;
  courseOfferingId?: InputMaybe<Scalars['String']['input']>;
  departmentId?: InputMaybe<Scalars['String']['input']>;
  language?: Language;
  status: PendingOrRegistered;
};

export type QueryGroupSwapRequestArgs = {
  id: Scalars['String']['input'];
};

export type QueryMarblesCourseInfoArgs = {
  catalogNumber?: InputMaybe<Scalars['String']['input']>;
  courseId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryMarblesCourseOfferingArgs = {
  courseOfferingId: Scalars['String']['input'];
};

export type QueryMarblesCourseOfferingsArgs = {
  academicYear?: Scalars['Int']['input'];
  catalogNumber?: InputMaybe<Scalars['String']['input']>;
  departmentId: Scalars['String']['input'];
};

export type QueryMarblesCoursesArgs = {
  academicYear?: Scalars['Int']['input'];
  language?: Language;
  query: Scalars['String']['input'];
};

export type QueryMarblesUserArgs = {
  username: Scalars['String']['input'];
};

export type QueryPlanArgs = {
  planCode?: InputMaybe<Scalars['String']['input']>;
  planId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryPursuitDiagnosticsArgs = {
  pursuitId: Scalars['String']['input'];
  studentId: Scalars['String']['input'];
};

export type QueryRegistrationRoundStatisticsArgs = {
  permits: Scalars['Long']['input'];
  roundId: Scalars['String']['input'];
  startFrom: Scalars['Long']['input'];
};

export type QueryRegistrationRoundsArgs = {
  academicYear?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryRequirementArgs = {
  requirementId: Scalars['String']['input'];
};

export type QueryRequirementCopyDepartmentsArgs = {
  requirementId: Scalars['String']['input'];
};

export type QueryRequirementsArgs = {
  academicYear?: InputMaybe<Scalars['Int']['input']>;
  departmentId: Scalars['String']['input'];
  includeParentDepartments: Scalars['Boolean']['input'];
};

export type QueryRuleArgs = {
  ruleId: Scalars['String']['input'];
};

export type QueryRulesArgs = {
  academicYear: Scalars['Int']['input'];
  departmentId: Scalars['String']['input'];
  includeParentDepartments?: Scalars['Boolean']['input'];
};

export type QueryStudentArgs = {
  studentId?: InputMaybe<Scalars['String']['input']>;
  studentNumber?: InputMaybe<Scalars['String']['input']>;
};

export type QueryStudentGroupArgs = {
  studentGroupId: Scalars['String']['input'];
};

export type QueryStudentGroupSwapsArgs = {
  studentId: Scalars['String']['input'];
};

export type QueryStudentGroupsArgs = {
  academicYear?: Scalars['Int']['input'];
  departmentId: Scalars['String']['input'];
};

export type QueryStudentGroupsExportArgs = {
  academicYear?: InputMaybe<Scalars['Int']['input']>;
  courseOfferingId?: InputMaybe<Scalars['String']['input']>;
  departmentId?: InputMaybe<Scalars['String']['input']>;
  language?: Language;
};

export type QueryStudentPursuitAuditArgs = {
  studentId: Scalars['String']['input'];
};

export type QueryStudentPursuitsArgs = {
  studentId: Scalars['String']['input'];
};

export type QueryStudentRequirementsCheckArgs = {
  courseOfferingId: Scalars['String']['input'];
  studentId?: InputMaybe<Scalars['String']['input']>;
  studentNumber?: InputMaybe<Scalars['String']['input']>;
};

export type QueryStudentSetArgs = {
  code: Scalars['String']['input'];
};

export type QueryStudentTimetableActivitiesArgs = {
  studentId: Scalars['String']['input'];
};

export type QueryStudentsArgs = {
  query: Scalars['String']['input'];
};

export type QueryTopicSetArgs = {
  topicSetId: Scalars['String']['input'];
};

export type QueryTopicSetCreateDepartmentsArgs = {
  departmentId: Scalars['String']['input'];
};

export type QueryTopicSetsArgs = {
  academicYear?: Scalars['Int']['input'];
  departmentId: Scalars['String']['input'];
};

export type QueueNotificationsInput = {
  maxMailsPerMinute: Scalars['Int']['input'];
  notificationType: NotificationType;
  plannedNotificationDate: Scalars['DateTime']['input'];
  studentIds: Array<Scalars['String']['input']>;
};

export type QueueNotificationsPayload = {
  __typename?: 'QueueNotificationsPayload';
  issues: Array<QueueNotificationsPayloadIssue>;
  lastPlannedNotificationDate: Scalars['DateTime']['output'];
  mutationStatus: MutationStatus;
  notificationCount: Scalars['Int']['output'];
};

export type QueueNotificationsPayloadIssue = {
  __typename?: 'QueueNotificationsPayloadIssue';
  message: Scalars['String']['output'];
  studentId: Scalars['String']['output'];
};

export type RegisterStudentForCourseInput = {
  courseOfferingId: Scalars['String']['input'];
  skipRequirements?: Scalars['Boolean']['input'];
  studentGroupId: Scalars['String']['input'];
  studentId: Scalars['String']['input'];
};

export type RegisterStudentForCoursePayload = {
  __typename?: 'RegisterStudentForCoursePayload';
  courseOfferingId?: Maybe<Scalars['String']['output']>;
  coursePursuitId?: Maybe<Scalars['String']['output']>;
  mutationStatus: MutationStatus;
  reason?: Maybe<Scalars['String']['output']>;
  registrationDate?: Maybe<Scalars['DateTime']['output']>;
  requirementsCheck?: Maybe<Requirements>;
  student?: Maybe<Student>;
  studentGroup?: Maybe<StudentGroup>;
  studentGroupId?: Maybe<Scalars['String']['output']>;
};

export type RegistrationRound = {
  __typename?: 'RegistrationRound';
  academicYear: MarblesAcademicYear;
  description: BilingualString;
  id: Scalars['String']['output'];
  label?: Maybe<BilingualString>;
  periods: Array<Period>;
  registrationEndDate: Scalars['DateTime']['output'];
  registrationResultDate: Scalars['DateTime']['output'];
  registrationRoundType: RegistrationRoundType;
  registrationStartDate: Scalars['DateTime']['output'];
  semesters: Array<Semester>;
  showCapacity: Scalars['Boolean']['output'];
};

export type RegistrationRoundCreditStatistics = {
  __typename?: 'RegistrationRoundCreditStatistics';
  creditsAllocated: Scalars['Float']['output'];
  creditsRequested: Scalars['Float']['output'];
  studentId: Scalars['String']['output'];
};

export enum RegistrationRoundMoment {
  RegistrationEndDate = 'REGISTRATION_END_DATE',
  RegistrationResultDate = 'REGISTRATION_RESULT_DATE',
  RegistrationStartDate = 'REGISTRATION_START_DATE',
}

export type RegistrationRoundPursuitStatistics = {
  __typename?: 'RegistrationRoundPursuitStatistics';
  courseId: Scalars['String']['output'];
  courseOfferingOfferingId: Scalars['String']['output'];
  credits: Scalars['Float']['output'];
  department: Scalars['String']['output'];
  faculty: Scalars['String']['output'];
  pursuitId: Scalars['String']['output'];
  registeredBy?: Maybe<Scalars['String']['output']>;
  rejectedBy?: Maybe<Scalars['String']['output']>;
  startedBy?: Maybe<Scalars['String']['output']>;
  studentId: Scalars['String']['output'];
};

export type RegistrationRoundStatistics = {
  __typename?: 'RegistrationRoundStatistics';
  credits: Array<RegistrationRoundCreditStatistics>;
  firstEventId?: Maybe<Scalars['Long']['output']>;
  lastEventId?: Maybe<Scalars['Long']['output']>;
  pursuits: Array<RegistrationRoundPursuitStatistics>;
};

export enum RegistrationRoundType {
  LateRegistration = 'LATE_REGISTRATION',
  Regular = 'REGULAR',
}

export type RejectPendingCoursePursuitInput = {
  coursePursuitId: Scalars['String']['input'];
};

export type RejectPendingCoursePursuitPayload = {
  __typename?: 'RejectPendingCoursePursuitPayload';
  courseOfferingId?: Maybe<Scalars['String']['output']>;
  coursePursuitId: Scalars['String']['output'];
  mutationStatus: MutationStatus;
};

export type Rejection = {
  __typename?: 'Rejection';
  id: Scalars['String']['output'];
  registrationRound?: Maybe<RegistrationRound>;
  rejectionDate: Scalars['DateTime']['output'];
  rejectionReason?: Maybe<RejectionReason>;
  student: Student;
  topic?: Maybe<Topic>;
  username: Scalars['String']['output'];
};

export enum RejectionReason {
  AtCapacity = 'AT_CAPACITY',
  Other = 'OTHER',
  PreferredCourseAllocated = 'PREFERRED_COURSE_ALLOCATED',
}

export type RemoveConstrainedChoiceClusterInput = {
  constrainedChoiceClusterId: Scalars['String']['input'];
};

export type RemoveConstrainedChoiceClusterPayload = {
  __typename?: 'RemoveConstrainedChoiceClusterPayload';
  constrainedChoiceClusterId: Scalars['String']['output'];
  mutationStatus: MutationStatus;
};

export type RemoveInfoRequirementMutationInput = {
  nodeId: Scalars['String']['input'];
  ruleId: Scalars['String']['input'];
};

export type RemoveInfoRequirementMutationPayload = {
  __typename?: 'RemoveInfoRequirementMutationPayload';
  mutationStatus: MutationStatus;
  nodeId: Scalars['String']['output'];
  rule?: Maybe<Rule>;
};

export type RemoveMarblesCourseInput = {
  coursePursuitId: Scalars['String']['input'];
};

export type RemoveMarblesCoursePayload = {
  __typename?: 'RemoveMarblesCoursePayload';
  coursePursuitId: Scalars['String']['output'];
  mutationStatus: MutationStatus;
};

export type RemoveRequiredCoursesMutationInput = {
  nodeId: Scalars['String']['input'];
  ruleId: Scalars['String']['input'];
};

export type RemoveRequiredCoursesMutationPayload = {
  __typename?: 'RemoveRequiredCoursesMutationPayload';
  mutationStatus: MutationStatus;
  nodeId: Scalars['String']['output'];
  rule?: Maybe<Rule>;
};

export type RemoveRequiredPlanMutationInput = {
  nodeId: Scalars['String']['input'];
  ruleId: Scalars['String']['input'];
};

export type RemoveRequiredPlanMutationPayload = {
  __typename?: 'RemoveRequiredPlanMutationPayload';
  mutationStatus: MutationStatus;
  nodeId: Scalars['String']['output'];
  rule?: Maybe<Rule>;
};

export type RemoveRequirementMutationInput = {
  requirementId: Scalars['String']['input'];
};

export type RemoveRequirementMutationPayload = {
  __typename?: 'RemoveRequirementMutationPayload';
  academicYear?: Maybe<Scalars['Int']['output']>;
  departmentId?: Maybe<Scalars['String']['output']>;
  mutationStatus: MutationStatus;
  requirementId: Scalars['String']['output'];
};

export type RemoveRuleMutationInput = {
  ruleId: Scalars['String']['input'];
};

export type RemoveRuleMutationPayload = {
  __typename?: 'RemoveRuleMutationPayload';
  academicYear?: Maybe<Scalars['Int']['output']>;
  departmentId?: Maybe<Scalars['String']['output']>;
  mutationStatus: MutationStatus;
  ruleId: Scalars['String']['output'];
};

export type RemoveTopicInput = {
  topicId: Scalars['String']['input'];
};

export type RemoveTopicPayload = {
  __typename?: 'RemoveTopicPayload';
  mutationStatus: MutationStatus;
  topicId: Scalars['String']['output'];
  topicSetId?: Maybe<Scalars['String']['output']>;
};

export type RemoveTopicSetInput = {
  topicSetId: Scalars['String']['input'];
};

export type RemoveTopicSetPayload = {
  __typename?: 'RemoveTopicSetPayload';
  mutationStatus: MutationStatus;
  topicSetId: Scalars['String']['output'];
};

export type ReplaceCourseInput = {
  newCourseId: Scalars['String']['input'];
  oldCourseId: Scalars['String']['input'];
};

export type ReplaceCoursePayload = {
  __typename?: 'ReplaceCoursePayload';
  count: Scalars['Int']['output'];
};

export type RequestCourseRegistrationConfirmationEmailPayload = {
  __typename?: 'RequestCourseRegistrationConfirmationEmailPayload';
  mutationStatus: MutationStatus;
};

export type RequiredCareer = GroupRequirementNode & {
  __typename?: 'RequiredCareer';
  careers: Array<Career>;
  description: BilingualString;
  id: Scalars['String']['output'];
  type: RequirementNodeType;
};

export type RequiredCourses = RequirementNode & {
  __typename?: 'RequiredCourses';
  courses: Array<MarblesCourseInfo>;
  description: BilingualString;
  id: Scalars['String']['output'];
  requiredCourses?: Maybe<Scalars['Int']['output']>;
  requiredCredits?: Maybe<Scalars['Float']['output']>;
  requirement: RequiredCoursesRequirement;
  type: RequirementNodeType;
};

export type RequiredCoursesInput = {
  courseIds: Array<Scalars['String']['input']>;
  requiredCourses?: InputMaybe<Scalars['Int']['input']>;
  requiredCredits?: InputMaybe<Scalars['Float']['input']>;
  requirement?: RequiredCoursesRequirement;
};

export enum RequiredCoursesRequirement {
  AllCourses = 'ALL_COURSES',
  MinimumCourses = 'MINIMUM_COURSES',
  MinimumCredits = 'MINIMUM_CREDITS',
}

export type RequiredPlan = GroupRequirementNode & {
  __typename?: 'RequiredPlan';
  description: BilingualString;
  id: Scalars['String']['output'];
  plan: Plan;
  subplans?: Maybe<Array<Subplan>>;
  type: RequirementNodeType;
};

export type RequiredPlanInput = {
  planId: Scalars['String']['input'];
  subplanIds: Array<Scalars['String']['input']>;
};

export type RequiredStudentSet = GroupRequirementNode & {
  __typename?: 'RequiredStudentSet';
  description: BilingualString;
  id: Scalars['String']['output'];
  studentSet?: Maybe<StudentSet>;
  type: RequirementNodeType;
};

export type Requirement = {
  __typename?: 'Requirement';
  academicYear: MarblesAcademicYear;
  courseOfferings: Array<MarblesCourseOffering>;
  department: MarblesDepartment;
  departmentId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  rulesInRequirement: Array<RuleInRequirement>;
  topics: Array<Topic>;
};

export enum RequirementBlockedReason {
  NoActiveEnrolment = 'NO_ACTIVE_ENROLMENT',
  NoSelfRegistration = 'NO_SELF_REGISTRATION',
  Other = 'OTHER',
}

export type RequirementGroup = {
  __typename?: 'RequirementGroup';
  applies: Scalars['Boolean']['output'];
  descriptions: Array<BilingualString>;
  name?: Maybe<Scalars['String']['output']>;
  rows: Array<RequirementRow>;
};

export type RequirementNode = {
  description: BilingualString;
  id: Scalars['String']['output'];
  type: RequirementNodeType;
};

export enum RequirementNodeType {
  Info = 'INFO',
  Logical = 'LOGICAL',
  RequiredCareer = 'REQUIRED_CAREER',
  RequiredCourses = 'REQUIRED_COURSES',
  RequiredPlan = 'REQUIRED_PLAN',
  RequiredStudentSet = 'REQUIRED_STUDENT_SET',
  Requirement = 'REQUIREMENT',
  Rule = 'RULE',
}

export type RequirementRow = {
  __typename?: 'RequirementRow';
  details: Array<RequirementRowDetail>;
  progress?: Maybe<BilingualString>;
  status: RequirementStatus;
  title?: Maybe<BilingualString>;
};

export type RequirementRowDetail = {
  __typename?: 'RequirementRowDetail';
  courseId?: Maybe<Scalars['String']['output']>;
  courseInfo?: Maybe<MarblesCourseInfo>;
  status: RequirementStatus;
};

export enum RequirementStatus {
  Fail = 'FAIL',
  Info = 'INFO',
  Pass = 'PASS',
}

export type Requirements = {
  __typename?: 'Requirements';
  block: Scalars['Boolean']['output'];
  groups: Array<RequirementGroup>;
};

export type ResetCreditLimitExceptionsPayload = {
  __typename?: 'ResetCreditLimitExceptionsPayload';
  mutationStatus: MutationStatus;
  studentIds: Array<Scalars['String']['output']>;
};

export type RoleAssignment = {
  __typename?: 'RoleAssignment';
  createdBy: Scalars['String']['output'];
  creationDate: Scalars['DateTime']['output'];
  department: MarblesDepartment;
  id: Scalars['Int']['output'];
  role: Scalars['String']['output'];
  userActions: Array<UserAction>;
  userRole: UserRole;
};

export type Row = {
  __typename?: 'Row';
  coursePursuitId?: Maybe<Scalars['String']['output']>;
  line: Scalars['Int']['output'];
  rowStatus: MutationStatus;
  studentNumber: Scalars['String']['output'];
  topicName?: Maybe<Scalars['String']['output']>;
};

export type Rule = {
  __typename?: 'Rule';
  academicYear: MarblesAcademicYear;
  department: MarblesDepartment;
  departmentId: Scalars['String']['output'];
  descriptions: Array<BilingualString>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  nodes: RuleNodes;
  requirements: Array<RuleRequirementSummary>;
  ruleKind: RuleKind;
};

export type RuleInRequirement = {
  __typename?: 'RuleInRequirement';
  allocationRound: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  includeInRetry: Scalars['Boolean']['output'];
  priority?: Maybe<Scalars['Int']['output']>;
  priorityLimit?: Maybe<Scalars['Int']['output']>;
  rule: Rule;
  ruleId: Scalars['String']['output'];
};

export type RuleInRequirementInput = {
  includeInRetry?: Scalars['Boolean']['input'];
  priority?: InputMaybe<Scalars['Int']['input']>;
  priorityLimit?: InputMaybe<Scalars['Int']['input']>;
  ruleId: Scalars['String']['input'];
};

export enum RuleKind {
  Legacy = 'LEGACY',
  RequiredCareer = 'REQUIRED_CAREER',
  RequiredPlan = 'REQUIRED_PLAN',
  RequiredStudentSet = 'REQUIRED_STUDENT_SET',
}

export type RuleNodes = {
  __typename?: 'RuleNodes';
  groupRequirements: Array<GroupRequirementNode>;
  requirements: Array<RequirementNode>;
};

export type RuleRequirementSummary = {
  __typename?: 'RuleRequirementSummary';
  department: MarblesDepartment;
  departmentId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type SelectCourseForRegistrationInput = {
  courseOfferingId: Scalars['String']['input'];
  coursePursuitId: Scalars['String']['input'];
  topicId?: InputMaybe<Scalars['String']['input']>;
};

export type SelectCourseForRegistrationPayload = {
  __typename?: 'SelectCourseForRegistrationPayload';
  blockReason?: Maybe<RequirementBlockedReason>;
  constrainedChoiceClusterSetting?: Maybe<ConstrainedChoiceClusterSetting>;
  courseOfferingId: Scalars['String']['output'];
  coursePursuitId: Scalars['String']['output'];
  mutationStatus: MutationStatus;
  reason?: Maybe<BilingualString>;
  topicId?: Maybe<Scalars['String']['output']>;
};

export type Semester = {
  __typename?: 'Semester';
  academicYear: MarblesAcademicYear;
  id: Scalars['String']['output'];
  isCurrent: Scalars['Boolean']['output'];
  number: Scalars['Int']['output'];
  periods: Array<Period>;
};

export type SemesterTransitionMutationInput = {
  departmentIds?: InputMaybe<Array<Scalars['String']['input']>>;
  dryRun?: Scalars['Boolean']['input'];
  facultyId?: InputMaybe<Scalars['String']['input']>;
  migrateConstrainedChoiceConfig?: Scalars['Boolean']['input'];
  migrateOfferingCreditLimitException?: Scalars['Boolean']['input'];
  migrateOfferingStatus?: Scalars['Boolean']['input'];
  migrateOfferingSwapEnabled?: Scalars['Boolean']['input'];
  migrateRequirementConfig?: Scalars['Boolean']['input'];
  migrateTopicConfig?: Scalars['Boolean']['input'];
  offeringIds?: InputMaybe<Array<Scalars['String']['input']>>;
  sourceAcademicYear: Scalars['Int']['input'];
  targetSemesterId: Scalars['String']['input'];
};

export type SemesterTransitionMutationPayload = {
  __typename?: 'SemesterTransitionMutationPayload';
  issues: Array<MigrationIssue>;
  status: MutationStatus;
};

export type SendMailInput = {
  content: Scalars['String']['input'];
  recipient: Scalars['String']['input'];
  recipientEmail: Scalars['String']['input'];
  subject: Scalars['String']['input'];
};

export type SendMailOutput = {
  __typename?: 'SendMailOutput';
  mutationStatus: MutationStatus;
};

export type SetCourseOfferingConstrainedChoiceClusterInput = {
  clusterId?: InputMaybe<Scalars['String']['input']>;
  courseOfferingId: Scalars['String']['input'];
};

export type SetCourseOfferingConstrainedChoiceClusterPayload = {
  __typename?: 'SetCourseOfferingConstrainedChoiceClusterPayload';
  clusterId?: Maybe<Scalars['String']['output']>;
  courseOffering?: Maybe<MarblesCourseOffering>;
  mutationStatus: MutationStatus;
};

export type SetCourseOfferingCreditLimitExceptionInput = {
  courseOfferingId: Scalars['String']['input'];
  hasCreditLimitException: Scalars['Boolean']['input'];
};

export type SetCourseOfferingCreditLimitExceptionPayload = {
  __typename?: 'SetCourseOfferingCreditLimitExceptionPayload';
  courseOfferingId: Scalars['String']['output'];
  hasCreditLimitException: Scalars['Boolean']['output'];
  mutationStatus: MutationStatus;
};

export type SetCourseOfferingRequirementNodeInput = {
  courseOfferingId: Scalars['String']['input'];
  requirementNodeId?: InputMaybe<Scalars['String']['input']>;
};

export type SetCourseOfferingRequirementNodePayload = {
  __typename?: 'SetCourseOfferingRequirementNodePayload';
  courseOfferingId: Scalars['String']['output'];
  courseOfferingRequirementCapacities?: Maybe<Array<CourseOfferingRequirementCapacity>>;
  mutationStatus: MutationStatus;
  requirementNodeId?: Maybe<Scalars['String']['output']>;
};

export type SetCourseOfferingStatusInput = {
  courseOfferingId: Scalars['String']['input'];
  status: MarblesCourseOfferingStatus;
};

export type SetCourseOfferingStatusPayload = {
  __typename?: 'SetCourseOfferingStatusPayload';
  courseOfferingId: Scalars['String']['output'];
  mutationStatus: MutationStatus;
  status: MarblesCourseOfferingStatus;
};

export type SetCourseOfferingTopicSetInput = {
  courseOfferingId: Scalars['String']['input'];
  topicSetId?: InputMaybe<Scalars['String']['input']>;
};

export type SetCourseOfferingTopicSetPayload = {
  __typename?: 'SetCourseOfferingTopicSetPayload';
  courseOfferingId: Scalars['String']['output'];
  mutationStatus: MutationStatus;
  topicSet?: Maybe<TopicSet>;
};

export type SetCoursePursuitTopicInput = {
  pursuitId: Scalars['String']['input'];
  topicId?: InputMaybe<Scalars['String']['input']>;
};

export type SetCoursePursuitTopicPayload = {
  __typename?: 'SetCoursePursuitTopicPayload';
  coursePursuitId: Scalars['String']['output'];
  mutationStatus: MutationStatus;
  topicId?: Maybe<Scalars['String']['output']>;
};

export type SetCreditLimitExceptionInput = {
  hasCreditLimitException: Scalars['Boolean']['input'];
  studentId: Scalars['String']['input'];
};

export type SetCreditLimitExceptionPayload = {
  __typename?: 'SetCreditLimitExceptionPayload';
  hasCreditLimitException: Scalars['Boolean']['output'];
  mutationStatus: MutationStatus;
  studentId: Scalars['String']['output'];
};

export type SetGroupSwapEnabledInput = {
  courseOfferingId: Scalars['String']['input'];
  groupSwapEnabled: Scalars['Boolean']['input'];
};

export type SetGroupSwapEnabledPayload = {
  __typename?: 'SetGroupSwapEnabledPayload';
  courseOfferingId: Scalars['String']['output'];
  groupSwapEnabled: Scalars['Boolean']['output'];
  mutationStatus: MutationStatus;
};

export type SetStudentGroupCapacityInput = {
  capacity?: InputMaybe<Scalars['Int']['input']>;
  studentGroupId: Scalars['String']['input'];
};

export type SetStudentGroupCapacityPayload = {
  __typename?: 'SetStudentGroupCapacityPayload';
  configuredCapacity?: Maybe<Scalars['Int']['output']>;
  mutationStatus: MutationStatus;
  studentGroupId: Scalars['String']['output'];
};

export type SetStudentGroupTopicInput = {
  studentGroupId: Scalars['String']['input'];
  topicId?: InputMaybe<Scalars['String']['input']>;
};

export type SetStudentGroupTopicPayload = {
  __typename?: 'SetStudentGroupTopicPayload';
  mutationStatus: MutationStatus;
  studentGroupId?: Maybe<Scalars['String']['output']>;
  topic?: Maybe<Topic>;
  topicId?: Maybe<Scalars['String']['output']>;
};

export type SetStudentTimetablePreferencesInput = {
  avoidTimeSlots: Array<TimeSlot>;
  distribution: TimetableDistributionPreference;
  studentNumber: Scalars['String']['input'];
};

export type SetStudentTimetablePreferencesPayload = {
  __typename?: 'SetStudentTimetablePreferencesPayload';
  avoidTimeSlots: Array<TimeSlot>;
  distribution: TimetableDistributionPreference;
  mutationStatus: MutationStatus;
};

export type SetTimetablePreferencesInput = {
  avoidTimeSlots: Array<TimeSlot>;
  distribution: TimetableDistributionPreference;
};

export type SetTimetablePreferencesPayload = {
  __typename?: 'SetTimetablePreferencesPayload';
  avoidTimeSlots: Array<TimeSlot>;
  distribution: TimetableDistributionPreference;
  mutationStatus: MutationStatus;
};

export enum StaffDeregistrationReason {
  Administrative = 'ADMINISTRATIVE',
  CourseCancelled = 'COURSE_CANCELLED',
  StudentExceedsCreditLimit = 'STUDENT_EXCEEDS_CREDIT_LIMIT',
  StudentFailedRequirements = 'STUDENT_FAILED_REQUIREMENTS',
  StudentQuitsProgramme = 'STUDENT_QUITS_PROGRAMME',
  StudentRequestedDeregistration = 'STUDENT_REQUESTED_DEREGISTRATION',
}

export type Student = {
  __typename?: 'Student';
  email: Scalars['String']['output'];
  enrolments: Array<Enrolment>;
  givenName: Scalars['String']['output'];
  hasCreditLimitException: Scalars['Boolean']['output'];
  hasTimetablePreferences: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  studentNumber: Scalars['String']['output'];
  surname: Scalars['String']['output'];
  surnamePrefix: Scalars['String']['output'];
};

export type StudentEnrolmentsArgs = {
  academicYear?: InputMaybe<Scalars['Int']['input']>;
};

export type StudentCourse = {
  __typename?: 'StudentCourse';
  courseId: Scalars['String']['output'];
  info: MarblesCourseInfo;
};

export type StudentGroup = {
  __typename?: 'StudentGroup';
  /** admin */
  capacity: Scalars['Int']['output'];
  code: Scalars['String']['output'];
  /** admin */
  configuredCapacity?: Maybe<Scalars['Int']['output']>;
  /** admin */
  courseOffering: MarblesCourseOffering;
  /** admin */
  directRegistrationEnabled: Scalars['Boolean']['output'];
  /** admin */
  groupSwapDemand: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  /** admin */
  initialCapacity: Scalars['Int']['output'];
  /** admin */
  locked: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  /** admin */
  numberOfRegisteredStudents: Scalars['Int']['output'];
  /** admin */
  timetableSummary: BilingualString;
  topic?: Maybe<Topic>;
};

export type StudentRequirementsCheckPayload = {
  __typename?: 'StudentRequirementsCheckPayload';
  requirementsCheck: Requirements;
  student: Student;
};

export type StudentSearchResult = {
  __typename?: 'StudentSearchResult';
  searchResults: Array<Student>;
  totalCount: Scalars['Int']['output'];
};

export type StudentSet = {
  __typename?: 'StudentSet';
  code: Scalars['String']['output'];
  id: Scalars['String']['output'];
  title: BilingualString;
};

export type Subplan = {
  __typename?: 'Subplan';
  code: Scalars['String']['output'];
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
};

export type SwappableStudentGroup = {
  __typename?: 'SwappableStudentGroup';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  timetableSummary: BilingualString;
};

export type SwitchStudentGroupInput = {
  coursePursuitId: Scalars['String']['input'];
  studentGroupId: Scalars['String']['input'];
};

export type SwitchStudentGroupPayload = {
  __typename?: 'SwitchStudentGroupPayload';
  coursePursuitId: Scalars['String']['output'];
  mutationStatus: MutationStatus;
  reason?: Maybe<Scalars['String']['output']>;
  studentGroupId: Scalars['String']['output'];
};

export enum TimeSlot {
  FridayAfternoon = 'FRIDAY_AFTERNOON',
  FridayEvening = 'FRIDAY_EVENING',
  FridayMorning = 'FRIDAY_MORNING',
  MondayAfternoon = 'MONDAY_AFTERNOON',
  MondayEvening = 'MONDAY_EVENING',
  MondayMorning = 'MONDAY_MORNING',
  ThursdayAfternoon = 'THURSDAY_AFTERNOON',
  ThursdayEvening = 'THURSDAY_EVENING',
  ThursdayMorning = 'THURSDAY_MORNING',
  TuesdayAfternoon = 'TUESDAY_AFTERNOON',
  TuesdayEvening = 'TUESDAY_EVENING',
  TuesdayMorning = 'TUESDAY_MORNING',
  WednesdayAfternoon = 'WEDNESDAY_AFTERNOON',
  WednesdayEvening = 'WEDNESDAY_EVENING',
  WednesdayMorning = 'WEDNESDAY_MORNING',
}

export type TimeTravelToPayload = {
  __typename?: 'TimeTravelToPayload';
  offset: Scalars['Long']['output'];
  registrationRounds: Array<RegistrationRound>;
};

export type TimetableActivity = {
  __typename?: 'TimetableActivity';
  dayOfWeek: DayOfWeek;
  description: Scalars['String']['output'];
  endTime: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isPublishedForStudents: Scalars['Boolean']['output'];
  locations: Array<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  startTime: Scalars['String']['output'];
  studentGroups: Array<StudentGroup>;
  type: Scalars['String']['output'];
  weeks: Array<Scalars['Int']['output']>;
};

export enum TimetableDistributionPreference {
  Cluster = 'CLUSTER',
  None = 'NONE',
  Spread = 'SPREAD',
}

export type TimetablePreferences = {
  __typename?: 'TimetablePreferences';
  avoidTimeSlots: Array<TimeSlot>;
  distribution: TimetableDistributionPreference;
};

export type Topic = {
  __typename?: 'Topic';
  id: Scalars['String']['output'];
  requirement?: Maybe<Requirement>;
  title: BilingualString;
  topicSet: TopicSet;
  topicSetId: Scalars['String']['output'];
};

export type TopicCapacity = {
  __typename?: 'TopicCapacity';
  available: Scalars['Int']['output'];
  topicId: Scalars['String']['output'];
  total: Scalars['Int']['output'];
};

export type TopicSet = {
  __typename?: 'TopicSet';
  academicYear: MarblesAcademicYear;
  courseOfferings: Array<MarblesCourseOffering>;
  department: MarblesDepartment;
  departmentId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  /** admin */
  name: Scalars['String']['output'];
  title: BilingualString;
  topics: Array<Topic>;
};

export type UnlockStudentGroupInput = {
  studentGroupId: Scalars['String']['input'];
};

export type UnlockStudentGroupPayload = {
  __typename?: 'UnlockStudentGroupPayload';
  mutationStatus: MutationStatus;
  studentGroupId: Scalars['String']['output'];
};

export type UpdateConstrainedChoiceClusterMutationInput = {
  constrainedChoiceClusterId: Scalars['String']['input'];
  registrationLimit: Scalars['Int']['input'];
  titleEN: Scalars['String']['input'];
  titleNL: Scalars['String']['input'];
};

export type UpdateConstrainedChoiceClusterMutationPayload = {
  __typename?: 'UpdateConstrainedChoiceClusterMutationPayload';
  constrainedChoiceCluster?: Maybe<ConstrainedChoiceCluster>;
  mutationStatus: MutationStatus;
};

export type UpdateConstrainedChoiceSettingInput = {
  pursuitIds: Array<Scalars['String']['input']>;
  registrationLimit: Scalars['Int']['input'];
  settingsId: Scalars['String']['input'];
};

export type UpdateConstrainedChoiceSettingPayload = {
  __typename?: 'UpdateConstrainedChoiceSettingPayload';
  mutationStatus: MutationStatus;
  setting?: Maybe<ConstrainedChoiceClusterSetting>;
  settingsId: Scalars['String']['output'];
};

export type UpdateInfoRequirementMutationInput = {
  description: BilingualStringInput;
  nodeId: Scalars['String']['input'];
  ruleId: Scalars['String']['input'];
};

export type UpdateInfoRequirementMutationPayload = {
  __typename?: 'UpdateInfoRequirementMutationPayload';
  mutationStatus: MutationStatus;
  nodeId: Scalars['String']['output'];
  rule?: Maybe<Rule>;
  ruleId: Scalars['String']['output'];
};

export type UpdateRequiredCareerMutationInput = {
  careerIds: Array<Scalars['String']['input']>;
  nodeId: Scalars['String']['input'];
  ruleId: Scalars['String']['input'];
};

export type UpdateRequiredCareerMutationPayload = {
  __typename?: 'UpdateRequiredCareerMutationPayload';
  mutationStatus: MutationStatus;
  nodeId: Scalars['String']['output'];
  rule?: Maybe<Rule>;
  ruleId: Scalars['String']['output'];
};

export type UpdateRequiredCoursesMutationInput = {
  courses: RequiredCoursesInput;
  description: BilingualStringInput;
  nodeId: Scalars['String']['input'];
  ruleId: Scalars['String']['input'];
};

export type UpdateRequiredCoursesMutationPayload = {
  __typename?: 'UpdateRequiredCoursesMutationPayload';
  mutationStatus: MutationStatus;
  nodeId: Scalars['String']['output'];
  rule?: Maybe<Rule>;
  ruleId: Scalars['String']['output'];
};

export type UpdateRequiredPlanMutationInput = {
  nodeId: Scalars['String']['input'];
  plan: RequiredPlanInput;
  ruleId: Scalars['String']['input'];
};

export type UpdateRequiredPlanMutationPayload = {
  __typename?: 'UpdateRequiredPlanMutationPayload';
  mutationStatus: MutationStatus;
  nodeId: Scalars['String']['output'];
  rule?: Maybe<Rule>;
  ruleId: Scalars['String']['output'];
};

export type UpdateRequiredStudentSetMutationInput = {
  nodeId: Scalars['String']['input'];
  ruleId: Scalars['String']['input'];
  studentSetId: Scalars['String']['input'];
};

export type UpdateRequiredStudentSetMutationPayload = {
  __typename?: 'UpdateRequiredStudentSetMutationPayload';
  mutationStatus: MutationStatus;
  nodeId: Scalars['String']['output'];
  rule?: Maybe<Rule>;
  ruleId: Scalars['String']['output'];
};

export type UpdateRequirementNameMutationInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  requirementId: Scalars['String']['input'];
};

export type UpdateRequirementNameMutationPayload = {
  __typename?: 'UpdateRequirementNameMutationPayload';
  mutationStatus: MutationStatus;
  requirement?: Maybe<Requirement>;
};

export type UpdateRequirementRulesMutationInput = {
  requirementId: Scalars['String']['input'];
  rules: Array<RuleInRequirementInput>;
};

export type UpdateRequirementRulesMutationPayload = {
  __typename?: 'UpdateRequirementRulesMutationPayload';
  mutationStatus: MutationStatus;
  requirement?: Maybe<Requirement>;
};

export type UpdateRuleNameMutationInput = {
  name: Scalars['String']['input'];
  ruleId: Scalars['String']['input'];
};

export type UpdateRuleNameMutationPayload = {
  __typename?: 'UpdateRuleNameMutationPayload';
  mutationStatus: MutationStatus;
  rule?: Maybe<Rule>;
};

export type UpdateTopicMutationInput = {
  requirementNodeId?: InputMaybe<Scalars['String']['input']>;
  titleEN: Scalars['String']['input'];
  titleNL: Scalars['String']['input'];
  topicId: Scalars['String']['input'];
};

export type UpdateTopicMutationPayload = {
  __typename?: 'UpdateTopicMutationPayload';
  mutationStatus: MutationStatus;
  topic?: Maybe<Topic>;
};

export type UpdateTopicSetNameMutationInput = {
  name: Scalars['String']['input'];
  titleEN: Scalars['String']['input'];
  titleNL: Scalars['String']['input'];
  topicSetId: Scalars['String']['input'];
};

export type UpdateTopicSetNameMutationPayload = {
  __typename?: 'UpdateTopicSetNameMutationPayload';
  mutationStatus: MutationStatus;
  topicSet?: Maybe<TopicSet>;
};

export type UploadBulkCourseDeregistrationsInput = {
  academicYear: Scalars['Int']['input'];
  deregistrationReason: StaffDeregistrationReason;
  file: Scalars['Upload']['input'];
  ignorePursuitsNotFound?: Scalars['Boolean']['input'];
};

export type UploadBulkCourseDeregistrationsPayload = {
  __typename?: 'UploadBulkCourseDeregistrationsPayload';
  details?: Maybe<Array<Scalars['String']['output']>>;
  missingColumns?: Maybe<Array<MissingColumn>>;
  mutationStatus: MutationStatus;
  pursuits: Array<CoursePursuit>;
  result: Array<UploadCourseDeregistrationsPayloadResultRow>;
};

export type UploadBulkCourseRegistrationsInput = {
  academicYear: Scalars['Int']['input'];
  file: Scalars['Upload']['input'];
  skipRequirements?: Scalars['Boolean']['input'];
  switchGroupIfAlreadyRegistered: Scalars['Boolean']['input'];
};

export type UploadBulkCourseRegistrationsPayload = {
  __typename?: 'UploadBulkCourseRegistrationsPayload';
  details?: Maybe<Array<Scalars['String']['output']>>;
  missingColumns?: Maybe<Array<MissingColumn>>;
  mutationStatus: MutationStatus;
  pursuits: Array<CoursePursuit>;
  result: Array<UploadCourseRegistrationsPayloadResultRow>;
};

export type UploadCourseDeregistrationsInput = {
  courseOfferingId: Scalars['String']['input'];
  file: Scalars['Upload']['input'];
  reason?: InputMaybe<StaffDeregistrationReason>;
};

export type UploadCourseDeregistrationsPayload = {
  __typename?: 'UploadCourseDeregistrationsPayload';
  courseOfferingId: Scalars['String']['output'];
  missingColumns?: Maybe<Array<MissingColumn>>;
  mutationStatus: MutationStatus;
  result: Array<UploadCourseDeregistrationsPayloadResultRow>;
};

export type UploadCourseDeregistrationsPayloadResultRow = {
  __typename?: 'UploadCourseDeregistrationsPayloadResultRow';
  line: Scalars['Int']['output'];
  rowStatus: MutationStatus;
  studentNumber: Scalars['String']['output'];
};

export type UploadCoursePendingStudentsInput = {
  courseOfferingId: Scalars['String']['input'];
  file: Scalars['Upload']['input'];
  skipRequirements?: Scalars['Boolean']['input'];
  switchTopicIfAlreadyPending: Scalars['Boolean']['input'];
};

export type UploadCoursePendingStudentsPayload = {
  __typename?: 'UploadCoursePendingStudentsPayload';
  courseOfferingId: Scalars['String']['output'];
  missingColumns?: Maybe<Array<MissingColumn>>;
  mutationStatus: MutationStatus;
  pursuits: Array<CoursePursuit>;
  result: Array<Row>;
};

export type UploadCourseRegistrationsInput = {
  courseOfferingId: Scalars['String']['input'];
  file: Scalars['Upload']['input'];
  skipRequirements?: Scalars['Boolean']['input'];
  switchGroupIfAlreadyRegistered: Scalars['Boolean']['input'];
};

export type UploadCourseRegistrationsPayload = {
  __typename?: 'UploadCourseRegistrationsPayload';
  courseOfferingId: Scalars['String']['output'];
  missingColumns?: Maybe<Array<MissingColumn>>;
  mutationStatus: MutationStatus;
  pursuits: Array<CoursePursuit>;
  result: Array<UploadCourseRegistrationsPayloadResultRow>;
};

export type UploadCourseRegistrationsPayloadResultRow = {
  __typename?: 'UploadCourseRegistrationsPayloadResultRow';
  courseOfferingId: Scalars['String']['output'];
  groupCode: Scalars['String']['output'];
  line: Scalars['Int']['output'];
  rowStatus: MutationStatus;
  studentNumber: Scalars['String']['output'];
};

export type UploadCreditLimitExceptionsInput = {
  file: Scalars['Upload']['input'];
};

export type UploadCreditLimitExceptionsPayload = {
  __typename?: 'UploadCreditLimitExceptionsPayload';
  missingColumns?: Maybe<Array<MissingColumn>>;
  mutationStatus: MutationStatus;
  result: Array<UploadCreditLimitExceptionsPayloadResultRow>;
};

export type UploadCreditLimitExceptionsPayloadResultRow = {
  __typename?: 'UploadCreditLimitExceptionsPayloadResultRow';
  hasCreditLimitException: Scalars['Boolean']['output'];
  line: Scalars['Int']['output'];
  rowStatus: MutationStatus;
  studentNumber: Scalars['String']['output'];
};

export type UploadGroupSettingsInput = {
  courseOfferingId: Scalars['String']['input'];
  file: Scalars['Upload']['input'];
};

export type UploadGroupSettingsPayload = {
  __typename?: 'UploadGroupSettingsPayload';
  missingColumns?: Maybe<Array<MissingColumn>>;
  mutationStatus: MutationStatus;
  result: Array<UploadGroupSettingsPayloadResultRow>;
};

export type UploadGroupSettingsPayloadResultRow = {
  __typename?: 'UploadGroupSettingsPayloadResultRow';
  groupCode: Scalars['String']['output'];
  line: Scalars['Int']['output'];
  rowStatus: MutationStatus;
};

export enum UserAction {
  AdminAction = 'ADMIN_ACTION',
  EditAuthorisations = 'EDIT_AUTHORISATIONS',
  EditConstrainedChoiceClusters = 'EDIT_CONSTRAINED_CHOICE_CLUSTERS',
  EditCourseSettings = 'EDIT_COURSE_SETTINGS',
  EditRegistrations = 'EDIT_REGISTRATIONS',
  EditRequirements = 'EDIT_REQUIREMENTS',
  EditStudents = 'EDIT_STUDENTS',
  EditTopicSets = 'EDIT_TOPIC_SETS',
  ProcessGroupSwapRequests = 'PROCESS_GROUP_SWAP_REQUESTS',
  UploadCreditLimitExceptions = 'UPLOAD_CREDIT_LIMIT_EXCEPTIONS',
  View = 'VIEW',
}

export type UserRole = {
  __typename?: 'UserRole';
  description: BilingualString;
  name: Scalars['String']['output'];
};

export enum UserStatus {
  Active = 'ACTIVE',
  Suspended = 'SUSPENDED',
  SuspensionLifted = 'SUSPENSION_LIFTED',
}

export type AcademicYearFragment = {
  __typename?: 'MarblesAcademicYear';
  id: number;
  name: string;
  startDate: any;
  endDate: any;
  isCurrentAcademicYearForAdmins: boolean;
  isArchived: boolean;
};

export type CareerFragment = {
  __typename?: 'Career';
  id: string;
  code: string;
  description: { __typename?: 'BilingualString'; NL: string; EN: string };
};

export type ConstrainedChoiceClusterFragment = {
  __typename?: 'ConstrainedChoiceCluster';
  id: string;
  departmentId: string;
  registrationLimit: number;
  title: { __typename?: 'BilingualString'; NL: string; EN: string };
};

export type CourseInfoFragment = {
  __typename?: 'MarblesCourseInfo';
  id: string;
  catalogNumber: string;
  ec: number;
  title: { __typename?: 'BilingualString'; NL: string; EN: string };
};

export type CoursePursuitFragment = {
  __typename?: 'CoursePursuit';
  id: string;
  topicId?: string | null;
  registrationDate?: any | null;
  student: {
    __typename?: 'Student';
    id: string;
    studentNumber: string;
    name: string;
    surname: string;
    givenName: string;
    enrolments: Array<{
      __typename?: 'Enrolment';
      id: string;
      endDate: any;
      startDate: any;
      career: {
        __typename?: 'Career';
        id: string;
        code: string;
        description: { __typename?: 'BilingualString'; NL: string; EN: string };
      };
      plan: { __typename?: 'Plan'; id: string; code: string; description: string };
      subplans: Array<{ __typename?: 'Subplan'; id: string; code: string; description: string }>;
    }>;
  };
  studentGroup?: {
    __typename?: 'StudentGroup';
    id: string;
    code: string;
    name: string;
    initialCapacity: number;
    configuredCapacity?: number | null;
    capacity: number;
    locked: boolean;
    numberOfRegisteredStudents: number;
    directRegistrationEnabled: boolean;
    timetableSummary: { __typename?: 'BilingualString'; NL: string; EN: string };
  } | null;
  requirementsCheck?: {
    __typename?: 'Requirements';
    groups: Array<{
      __typename?: 'RequirementGroup';
      name?: string | null;
      applies: boolean;
      descriptions: Array<{ __typename?: 'BilingualString'; NL: string; EN: string }>;
      rows: Array<{
        __typename?: 'RequirementRow';
        status: RequirementStatus;
        title?: { __typename?: 'BilingualString'; NL: string; EN: string } | null;
        progress?: { __typename?: 'BilingualString'; NL: string; EN: string } | null;
        details: Array<{
          __typename?: 'RequirementRowDetail';
          status: RequirementStatus;
          courseInfo?: {
            __typename?: 'MarblesCourseInfo';
            id: string;
            catalogNumber: string;
            ec: number;
            title: { __typename?: 'BilingualString'; EN: string; NL: string };
          } | null;
        }>;
      }>;
    }>;
  } | null;
  clusterPreference?: {
    __typename?: 'CoursePursuitClusterPreference';
    index: number;
    pursuitCount: number;
    registrationLimit: number;
  } | null;
  selectedOffering?: { __typename?: 'MarblesCourseOffering'; id: string } | null;
};

export type DepartmentFragment = {
  __typename?: 'MarblesDepartment';
  id: string;
  code: string;
  allowedUserActions: Array<UserAction>;
  parentId?: string | null;
  name: { __typename?: 'BilingualString'; EN: string; NL: string };
};

export type EnrolmentFragment = {
  __typename?: 'Enrolment';
  id: string;
  endDate: any;
  startDate: any;
  career: {
    __typename?: 'Career';
    id: string;
    code: string;
    description: { __typename?: 'BilingualString'; NL: string; EN: string };
  };
  plan: { __typename?: 'Plan'; id: string; code: string; description: string };
  subplans: Array<{ __typename?: 'Subplan'; id: string; code: string; description: string }>;
};

export type InfoRequirementFragment = {
  __typename?: 'InfoRequirement';
  id: string;
  description: { __typename?: 'BilingualString'; NL: string; EN: string };
};

export type PartialRequirementFragment = {
  __typename?: 'Requirement';
  id: string;
  name: string;
  rulesInRequirement: Array<{
    __typename?: 'RuleInRequirement';
    id: string;
    rule: { __typename?: 'Rule'; id: string };
  }>;
  department: {
    __typename?: 'MarblesDepartment';
    id: string;
    allowedUserActions: Array<UserAction>;
    name: { __typename?: 'BilingualString'; NL: string; EN: string };
  };
  topics: Array<{ __typename?: 'Topic'; id: string }>;
  courseOfferings: Array<{ __typename?: 'MarblesCourseOffering'; id: string }>;
};

export type PartialRuleFragment = {
  __typename?: 'Rule';
  id: string;
  ruleKind: RuleKind;
  name: string;
  requirements: Array<{ __typename?: 'RuleRequirementSummary'; id: string }>;
  department: {
    __typename?: 'MarblesDepartment';
    id: string;
    code: string;
    allowedUserActions: Array<UserAction>;
    name: { __typename?: 'BilingualString'; EN: string; NL: string };
  };
};

export type PendingCoursePursuitFragment = {
  __typename?: 'CoursePursuit';
  id: string;
  topicId?: string | null;
  student: {
    __typename?: 'Student';
    id: string;
    studentNumber: string;
    name: string;
    surname: string;
    givenName: string;
    enrolments: Array<{
      __typename?: 'Enrolment';
      id: string;
      endDate: any;
      startDate: any;
      career: {
        __typename?: 'Career';
        id: string;
        code: string;
        description: { __typename?: 'BilingualString'; NL: string; EN: string };
      };
      plan: { __typename?: 'Plan'; id: string; code: string; description: string };
      subplans: Array<{ __typename?: 'Subplan'; id: string; code: string; description: string }>;
    }>;
  };
  requirementsCheck?: {
    __typename?: 'Requirements';
    groups: Array<{
      __typename?: 'RequirementGroup';
      name?: string | null;
      applies: boolean;
      descriptions: Array<{ __typename?: 'BilingualString'; NL: string; EN: string }>;
      rows: Array<{
        __typename?: 'RequirementRow';
        status: RequirementStatus;
        title?: { __typename?: 'BilingualString'; NL: string; EN: string } | null;
        progress?: { __typename?: 'BilingualString'; NL: string; EN: string } | null;
        details: Array<{
          __typename?: 'RequirementRowDetail';
          status: RequirementStatus;
          courseInfo?: {
            __typename?: 'MarblesCourseInfo';
            id: string;
            catalogNumber: string;
            ec: number;
            title: { __typename?: 'BilingualString'; EN: string; NL: string };
          } | null;
        }>;
      }>;
    }>;
  } | null;
  clusterPreference?: {
    __typename?: 'CoursePursuitClusterPreference';
    index: number;
    pursuitCount: number;
    registrationLimit: number;
  } | null;
};

export type PeriodFragment = {
  __typename?: 'Period';
  id: string;
  number: number;
  semesterNumber: number;
  periodInSemester: number;
  semester: { __typename?: 'Semester'; id: string };
};

export type PlanFragment = {
  __typename?: 'Plan';
  id: string;
  code: string;
  description: string;
  subplans: Array<{ __typename?: 'Subplan'; id: string; code: string; description: string }>;
};

export type RegisteredCoursePursuitFragment = {
  __typename?: 'CoursePursuit';
  id: string;
  registrationDate?: any | null;
  student: {
    __typename?: 'Student';
    id: string;
    studentNumber: string;
    name: string;
    surname: string;
    givenName: string;
    enrolments: Array<{
      __typename?: 'Enrolment';
      id: string;
      endDate: any;
      startDate: any;
      career: {
        __typename?: 'Career';
        id: string;
        code: string;
        description: { __typename?: 'BilingualString'; NL: string; EN: string };
      };
      plan: { __typename?: 'Plan'; id: string; code: string; description: string };
      subplans: Array<{ __typename?: 'Subplan'; id: string; code: string; description: string }>;
    }>;
  };
  studentGroup?: { __typename?: 'StudentGroup'; id: string; code: string; name: string } | null;
  requirementsCheck?: {
    __typename?: 'Requirements';
    groups: Array<{
      __typename?: 'RequirementGroup';
      name?: string | null;
      applies: boolean;
      descriptions: Array<{ __typename?: 'BilingualString'; NL: string; EN: string }>;
      rows: Array<{
        __typename?: 'RequirementRow';
        status: RequirementStatus;
        title?: { __typename?: 'BilingualString'; NL: string; EN: string } | null;
        progress?: { __typename?: 'BilingualString'; NL: string; EN: string } | null;
        details: Array<{
          __typename?: 'RequirementRowDetail';
          status: RequirementStatus;
          courseInfo?: {
            __typename?: 'MarblesCourseInfo';
            id: string;
            catalogNumber: string;
            ec: number;
            title: { __typename?: 'BilingualString'; EN: string; NL: string };
          } | null;
        }>;
      }>;
    }>;
  } | null;
};

export type RequiredCoursesFragment = {
  __typename?: 'RequiredCourses';
  id: string;
  requirement: RequiredCoursesRequirement;
  requiredCourses?: number | null;
  requiredCredits?: number | null;
  courses: Array<{
    __typename?: 'MarblesCourseInfo';
    id: string;
    catalogNumber: string;
    ec: number;
    title: { __typename?: 'BilingualString'; NL: string; EN: string };
  }>;
};

export type RequirementFragment = {
  __typename?: 'Requirement';
  id: string;
  name: string;
  topics: Array<{ __typename?: 'Topic'; id: string }>;
  department: { __typename?: 'MarblesDepartment'; id: string };
  courseOfferings: Array<{ __typename?: 'MarblesCourseOffering'; id: string }>;
  rulesInRequirement: Array<{
    __typename?: 'RuleInRequirement';
    id: string;
    allocationRound: number;
    priority?: number | null;
    priorityLimit?: number | null;
    includeInRetry: boolean;
    rule: {
      __typename?: 'Rule';
      id: string;
      name: string;
      ruleKind: RuleKind;
      requirements: Array<{
        __typename?: 'RuleRequirementSummary';
        id: string;
        name: string;
        department: {
          __typename?: 'MarblesDepartment';
          id: string;
          parentId?: string | null;
          name: { __typename?: 'BilingualString'; EN: string; NL: string };
        };
      }>;
      department: {
        __typename?: 'MarblesDepartment';
        id: string;
        allowedUserActions: Array<UserAction>;
        name: { __typename?: 'BilingualString'; EN: string; NL: string };
      };
      nodes: {
        __typename?: 'RuleNodes';
        requirements: Array<
          | {
              __typename?: 'InfoRequirement';
              id: string;
              description: { __typename?: 'BilingualString'; NL: string; EN: string };
            }
          | {
              __typename?: 'RequiredCourses';
              id: string;
              requirement: RequiredCoursesRequirement;
              requiredCourses?: number | null;
              requiredCredits?: number | null;
              description: { __typename?: 'BilingualString'; NL: string; EN: string };
              courses: Array<{
                __typename?: 'MarblesCourseInfo';
                id: string;
                catalogNumber: string;
                ec: number;
                title: { __typename?: 'BilingualString'; NL: string; EN: string };
              }>;
            }
        >;
        groupRequirements: Array<
          | {
              __typename?: 'RequiredCareer';
              id: string;
              careers: Array<{
                __typename?: 'Career';
                id: string;
                code: string;
                description: { __typename?: 'BilingualString'; NL: string; EN: string };
              }>;
              description: { __typename?: 'BilingualString'; NL: string; EN: string };
            }
          | {
              __typename?: 'RequiredPlan';
              id: string;
              plan: {
                __typename?: 'Plan';
                id: string;
                code: string;
                description: string;
                subplans: Array<{ __typename?: 'Subplan'; id: string; code: string; description: string }>;
              };
              subplans?: Array<{ __typename?: 'Subplan'; id: string; code: string; description: string }> | null;
              description: { __typename?: 'BilingualString'; NL: string; EN: string };
            }
          | {
              __typename?: 'RequiredStudentSet';
              id: string;
              studentSet?: {
                __typename?: 'StudentSet';
                id: string;
                code: string;
                title: { __typename?: 'BilingualString'; EN: string; NL: string };
              } | null;
              description: { __typename?: 'BilingualString'; NL: string; EN: string };
            }
        >;
      };
    };
  }>;
};

export type RequirementsFragment = {
  __typename?: 'Requirements';
  groups: Array<{
    __typename?: 'RequirementGroup';
    name?: string | null;
    applies: boolean;
    descriptions: Array<{ __typename?: 'BilingualString'; NL: string; EN: string }>;
    rows: Array<{
      __typename?: 'RequirementRow';
      status: RequirementStatus;
      title?: { __typename?: 'BilingualString'; NL: string; EN: string } | null;
      progress?: { __typename?: 'BilingualString'; NL: string; EN: string } | null;
      details: Array<{
        __typename?: 'RequirementRowDetail';
        status: RequirementStatus;
        courseInfo?: {
          __typename?: 'MarblesCourseInfo';
          id: string;
          catalogNumber: string;
          ec: number;
          title: { __typename?: 'BilingualString'; EN: string; NL: string };
        } | null;
      }>;
    }>;
  }>;
};

export type RuleFragment = {
  __typename?: 'Rule';
  id: string;
  name: string;
  ruleKind: RuleKind;
  requirements: Array<{
    __typename?: 'RuleRequirementSummary';
    id: string;
    name: string;
    department: {
      __typename?: 'MarblesDepartment';
      id: string;
      parentId?: string | null;
      name: { __typename?: 'BilingualString'; EN: string; NL: string };
    };
  }>;
  department: {
    __typename?: 'MarblesDepartment';
    id: string;
    allowedUserActions: Array<UserAction>;
    name: { __typename?: 'BilingualString'; EN: string; NL: string };
  };
  nodes: {
    __typename?: 'RuleNodes';
    requirements: Array<
      | {
          __typename?: 'InfoRequirement';
          id: string;
          description: { __typename?: 'BilingualString'; NL: string; EN: string };
        }
      | {
          __typename?: 'RequiredCourses';
          id: string;
          requirement: RequiredCoursesRequirement;
          requiredCourses?: number | null;
          requiredCredits?: number | null;
          description: { __typename?: 'BilingualString'; NL: string; EN: string };
          courses: Array<{
            __typename?: 'MarblesCourseInfo';
            id: string;
            catalogNumber: string;
            ec: number;
            title: { __typename?: 'BilingualString'; NL: string; EN: string };
          }>;
        }
    >;
    groupRequirements: Array<
      | {
          __typename?: 'RequiredCareer';
          id: string;
          careers: Array<{
            __typename?: 'Career';
            id: string;
            code: string;
            description: { __typename?: 'BilingualString'; NL: string; EN: string };
          }>;
          description: { __typename?: 'BilingualString'; NL: string; EN: string };
        }
      | {
          __typename?: 'RequiredPlan';
          id: string;
          plan: {
            __typename?: 'Plan';
            id: string;
            code: string;
            description: string;
            subplans: Array<{ __typename?: 'Subplan'; id: string; code: string; description: string }>;
          };
          subplans?: Array<{ __typename?: 'Subplan'; id: string; code: string; description: string }> | null;
          description: { __typename?: 'BilingualString'; NL: string; EN: string };
        }
      | {
          __typename?: 'RequiredStudentSet';
          id: string;
          studentSet?: {
            __typename?: 'StudentSet';
            id: string;
            code: string;
            title: { __typename?: 'BilingualString'; EN: string; NL: string };
          } | null;
          description: { __typename?: 'BilingualString'; NL: string; EN: string };
        }
    >;
  };
};

export type RuleNodesFragment = {
  __typename?: 'RuleNodes';
  requirements: Array<
    | {
        __typename?: 'InfoRequirement';
        id: string;
        description: { __typename?: 'BilingualString'; NL: string; EN: string };
      }
    | {
        __typename?: 'RequiredCourses';
        id: string;
        requirement: RequiredCoursesRequirement;
        requiredCourses?: number | null;
        requiredCredits?: number | null;
        description: { __typename?: 'BilingualString'; NL: string; EN: string };
        courses: Array<{
          __typename?: 'MarblesCourseInfo';
          id: string;
          catalogNumber: string;
          ec: number;
          title: { __typename?: 'BilingualString'; NL: string; EN: string };
        }>;
      }
  >;
  groupRequirements: Array<
    | {
        __typename?: 'RequiredCareer';
        id: string;
        careers: Array<{
          __typename?: 'Career';
          id: string;
          code: string;
          description: { __typename?: 'BilingualString'; NL: string; EN: string };
        }>;
        description: { __typename?: 'BilingualString'; NL: string; EN: string };
      }
    | {
        __typename?: 'RequiredPlan';
        id: string;
        plan: {
          __typename?: 'Plan';
          id: string;
          code: string;
          description: string;
          subplans: Array<{ __typename?: 'Subplan'; id: string; code: string; description: string }>;
        };
        subplans?: Array<{ __typename?: 'Subplan'; id: string; code: string; description: string }> | null;
        description: { __typename?: 'BilingualString'; NL: string; EN: string };
      }
    | {
        __typename?: 'RequiredStudentSet';
        id: string;
        studentSet?: {
          __typename?: 'StudentSet';
          id: string;
          code: string;
          title: { __typename?: 'BilingualString'; EN: string; NL: string };
        } | null;
        description: { __typename?: 'BilingualString'; NL: string; EN: string };
      }
  >;
};

export type StudentFragment = {
  __typename?: 'Student';
  id: string;
  studentNumber: string;
  name: string;
  surname: string;
  givenName: string;
  enrolments: Array<{
    __typename?: 'Enrolment';
    id: string;
    endDate: any;
    startDate: any;
    career: {
      __typename?: 'Career';
      id: string;
      code: string;
      description: { __typename?: 'BilingualString'; NL: string; EN: string };
    };
    plan: { __typename?: 'Plan'; id: string; code: string; description: string };
    subplans: Array<{ __typename?: 'Subplan'; id: string; code: string; description: string }>;
  }>;
};

export type StudentGroupFragment = {
  __typename?: 'StudentGroup';
  id: string;
  code: string;
  name: string;
  initialCapacity: number;
  configuredCapacity?: number | null;
  capacity: number;
  locked: boolean;
  numberOfRegisteredStudents: number;
  directRegistrationEnabled: boolean;
  timetableSummary: { __typename?: 'BilingualString'; NL: string; EN: string };
};

export type StudentSetFragment = {
  __typename?: 'StudentSet';
  id: string;
  code: string;
  title: { __typename?: 'BilingualString'; EN: string; NL: string };
};

export type StudentWithEnrolmentsByAcademicYearFragment = {
  __typename?: 'Student';
  id: string;
  studentNumber: string;
  name: string;
  surname: string;
  givenName: string;
  enrolments: Array<{
    __typename?: 'Enrolment';
    id: string;
    endDate: any;
    startDate: any;
    career: {
      __typename?: 'Career';
      id: string;
      code: string;
      description: { __typename?: 'BilingualString'; NL: string; EN: string };
    };
    plan: { __typename?: 'Plan'; id: string; code: string; description: string };
    subplans: Array<{ __typename?: 'Subplan'; id: string; code: string; description: string }>;
  }>;
};

export type SubplanFragment = { __typename?: 'Subplan'; id: string; code: string; description: string };

export type TopicSetFragment = {
  __typename?: 'TopicSet';
  id: string;
  name: string;
  title: { __typename?: 'BilingualString'; NL: string; EN: string };
  topics: Array<{
    __typename?: 'Topic';
    id: string;
    topicSetId: string;
    title: { __typename?: 'BilingualString'; NL: string; EN: string };
  }>;
};

export type AddCourseOfferingToConstrainedChoiceMutationVariables = Exact<{
  courseOfferingId: Scalars['String']['input'];
  clusterId?: InputMaybe<Scalars['String']['input']>;
}>;

export type AddCourseOfferingToConstrainedChoiceMutation = {
  __typename?: 'Mutation';
  setCourseOfferingConstrainedChoiceCluster: {
    __typename?: 'SetCourseOfferingConstrainedChoiceClusterPayload';
    clusterId?: string | null;
    mutationStatus: MutationStatus;
    courseOffering?: {
      __typename?: 'MarblesCourseOffering';
      id: string;
      numberOfPendingStudents: number;
      info?: {
        __typename?: 'MarblesCourseInfo';
        id: string;
        catalogNumber: string;
        ec: number;
        title: { __typename?: 'BilingualString'; NL: string; EN: string };
      } | null;
      periods: Array<{
        __typename?: 'Period';
        id: string;
        number: number;
        semesterNumber: number;
        periodInSemester: number;
        semester: { __typename?: 'Semester'; id: string };
      }>;
      registrationPeriod: {
        __typename?: 'Period';
        id: string;
        number: number;
        semesterNumber: number;
        periodInSemester: number;
        semester: { __typename?: 'Semester'; id: string };
      };
    } | null;
  };
};

export type AddInfoRequirementMutationVariables = Exact<{
  ruleId: Scalars['String']['input'];
  description: BilingualStringInput;
}>;

export type AddInfoRequirementMutation = {
  __typename?: 'Mutation';
  addInfoRequirement: {
    __typename?: 'AddInfoRequirementMutationPayload';
    mutationStatus: MutationStatus;
    rule?: {
      __typename?: 'Rule';
      id: string;
      name: string;
      ruleKind: RuleKind;
      requirements: Array<{
        __typename?: 'RuleRequirementSummary';
        id: string;
        name: string;
        department: {
          __typename?: 'MarblesDepartment';
          id: string;
          parentId?: string | null;
          name: { __typename?: 'BilingualString'; EN: string; NL: string };
        };
      }>;
      department: {
        __typename?: 'MarblesDepartment';
        id: string;
        allowedUserActions: Array<UserAction>;
        name: { __typename?: 'BilingualString'; EN: string; NL: string };
      };
      nodes: {
        __typename?: 'RuleNodes';
        requirements: Array<
          | {
              __typename?: 'InfoRequirement';
              id: string;
              description: { __typename?: 'BilingualString'; NL: string; EN: string };
            }
          | {
              __typename?: 'RequiredCourses';
              id: string;
              requirement: RequiredCoursesRequirement;
              requiredCourses?: number | null;
              requiredCredits?: number | null;
              description: { __typename?: 'BilingualString'; NL: string; EN: string };
              courses: Array<{
                __typename?: 'MarblesCourseInfo';
                id: string;
                catalogNumber: string;
                ec: number;
                title: { __typename?: 'BilingualString'; NL: string; EN: string };
              }>;
            }
        >;
        groupRequirements: Array<
          | {
              __typename?: 'RequiredCareer';
              id: string;
              careers: Array<{
                __typename?: 'Career';
                id: string;
                code: string;
                description: { __typename?: 'BilingualString'; NL: string; EN: string };
              }>;
              description: { __typename?: 'BilingualString'; NL: string; EN: string };
            }
          | {
              __typename?: 'RequiredPlan';
              id: string;
              plan: {
                __typename?: 'Plan';
                id: string;
                code: string;
                description: string;
                subplans: Array<{ __typename?: 'Subplan'; id: string; code: string; description: string }>;
              };
              subplans?: Array<{ __typename?: 'Subplan'; id: string; code: string; description: string }> | null;
              description: { __typename?: 'BilingualString'; NL: string; EN: string };
            }
          | {
              __typename?: 'RequiredStudentSet';
              id: string;
              studentSet?: {
                __typename?: 'StudentSet';
                id: string;
                code: string;
                title: { __typename?: 'BilingualString'; EN: string; NL: string };
              } | null;
              description: { __typename?: 'BilingualString'; NL: string; EN: string };
            }
        >;
      };
    } | null;
  };
};

export type AddRequiredCoursesMutationVariables = Exact<{
  ruleId: Scalars['String']['input'];
  description: BilingualStringInput;
  courses: RequiredCoursesInput;
}>;

export type AddRequiredCoursesMutation = {
  __typename?: 'Mutation';
  addRequiredCourses: {
    __typename?: 'AddRequiredCoursesMutationPayload';
    mutationStatus: MutationStatus;
    rule?: {
      __typename?: 'Rule';
      id: string;
      name: string;
      ruleKind: RuleKind;
      requirements: Array<{
        __typename?: 'RuleRequirementSummary';
        id: string;
        name: string;
        department: {
          __typename?: 'MarblesDepartment';
          id: string;
          parentId?: string | null;
          name: { __typename?: 'BilingualString'; EN: string; NL: string };
        };
      }>;
      department: {
        __typename?: 'MarblesDepartment';
        id: string;
        allowedUserActions: Array<UserAction>;
        name: { __typename?: 'BilingualString'; EN: string; NL: string };
      };
      nodes: {
        __typename?: 'RuleNodes';
        requirements: Array<
          | {
              __typename?: 'InfoRequirement';
              id: string;
              description: { __typename?: 'BilingualString'; NL: string; EN: string };
            }
          | {
              __typename?: 'RequiredCourses';
              id: string;
              requirement: RequiredCoursesRequirement;
              requiredCourses?: number | null;
              requiredCredits?: number | null;
              description: { __typename?: 'BilingualString'; NL: string; EN: string };
              courses: Array<{
                __typename?: 'MarblesCourseInfo';
                id: string;
                catalogNumber: string;
                ec: number;
                title: { __typename?: 'BilingualString'; NL: string; EN: string };
              }>;
            }
        >;
        groupRequirements: Array<
          | {
              __typename?: 'RequiredCareer';
              id: string;
              careers: Array<{
                __typename?: 'Career';
                id: string;
                code: string;
                description: { __typename?: 'BilingualString'; NL: string; EN: string };
              }>;
              description: { __typename?: 'BilingualString'; NL: string; EN: string };
            }
          | {
              __typename?: 'RequiredPlan';
              id: string;
              plan: {
                __typename?: 'Plan';
                id: string;
                code: string;
                description: string;
                subplans: Array<{ __typename?: 'Subplan'; id: string; code: string; description: string }>;
              };
              subplans?: Array<{ __typename?: 'Subplan'; id: string; code: string; description: string }> | null;
              description: { __typename?: 'BilingualString'; NL: string; EN: string };
            }
          | {
              __typename?: 'RequiredStudentSet';
              id: string;
              studentSet?: {
                __typename?: 'StudentSet';
                id: string;
                code: string;
                title: { __typename?: 'BilingualString'; EN: string; NL: string };
              } | null;
              description: { __typename?: 'BilingualString'; NL: string; EN: string };
            }
        >;
      };
    } | null;
  };
};

export type AddRequiredPlanMutationVariables = Exact<{
  ruleId: Scalars['String']['input'];
  plan: RequiredPlanInput;
}>;

export type AddRequiredPlanMutation = {
  __typename?: 'Mutation';
  addRequiredPlan: {
    __typename?: 'AddRequiredPlanMutationPayload';
    mutationStatus: MutationStatus;
    rule?: {
      __typename?: 'Rule';
      id: string;
      name: string;
      ruleKind: RuleKind;
      requirements: Array<{
        __typename?: 'RuleRequirementSummary';
        id: string;
        name: string;
        department: {
          __typename?: 'MarblesDepartment';
          id: string;
          parentId?: string | null;
          name: { __typename?: 'BilingualString'; EN: string; NL: string };
        };
      }>;
      department: {
        __typename?: 'MarblesDepartment';
        id: string;
        allowedUserActions: Array<UserAction>;
        name: { __typename?: 'BilingualString'; EN: string; NL: string };
      };
      nodes: {
        __typename?: 'RuleNodes';
        requirements: Array<
          | {
              __typename?: 'InfoRequirement';
              id: string;
              description: { __typename?: 'BilingualString'; NL: string; EN: string };
            }
          | {
              __typename?: 'RequiredCourses';
              id: string;
              requirement: RequiredCoursesRequirement;
              requiredCourses?: number | null;
              requiredCredits?: number | null;
              description: { __typename?: 'BilingualString'; NL: string; EN: string };
              courses: Array<{
                __typename?: 'MarblesCourseInfo';
                id: string;
                catalogNumber: string;
                ec: number;
                title: { __typename?: 'BilingualString'; NL: string; EN: string };
              }>;
            }
        >;
        groupRequirements: Array<
          | {
              __typename?: 'RequiredCareer';
              id: string;
              careers: Array<{
                __typename?: 'Career';
                id: string;
                code: string;
                description: { __typename?: 'BilingualString'; NL: string; EN: string };
              }>;
              description: { __typename?: 'BilingualString'; NL: string; EN: string };
            }
          | {
              __typename?: 'RequiredPlan';
              id: string;
              plan: {
                __typename?: 'Plan';
                id: string;
                code: string;
                description: string;
                subplans: Array<{ __typename?: 'Subplan'; id: string; code: string; description: string }>;
              };
              subplans?: Array<{ __typename?: 'Subplan'; id: string; code: string; description: string }> | null;
              description: { __typename?: 'BilingualString'; NL: string; EN: string };
            }
          | {
              __typename?: 'RequiredStudentSet';
              id: string;
              studentSet?: {
                __typename?: 'StudentSet';
                id: string;
                code: string;
                title: { __typename?: 'BilingualString'; EN: string; NL: string };
              } | null;
              description: { __typename?: 'BilingualString'; NL: string; EN: string };
            }
        >;
      };
    } | null;
  };
};

export type AddTopicMutationVariables = Exact<{
  topicSetId: Scalars['String']['input'];
  titleNL: Scalars['String']['input'];
  titleEN: Scalars['String']['input'];
  requirementNodeId?: InputMaybe<Scalars['String']['input']>;
}>;

export type AddTopicMutation = {
  __typename?: 'Mutation';
  addTopic: {
    __typename?: 'AddTopicMutationPayload';
    mutationStatus: MutationStatus;
    topic?: {
      __typename?: 'Topic';
      id: string;
      topicSetId: string;
      title: { __typename?: 'BilingualString'; NL: string; EN: string };
      requirement?: { __typename?: 'Requirement'; id: string; name: string } | null;
    } | null;
  };
};

export type AuthoriseUserMutationVariables = Exact<{
  username: Scalars['String']['input'];
  role: Scalars['String']['input'];
  departmentId: Scalars['String']['input'];
}>;

export type AuthoriseUserMutation = {
  __typename?: 'Mutation';
  marblesAuthoriseUser: {
    __typename?: 'MarblesAuthoriseUserMutationPayload';
    username: string;
    mutationStatus: MutationStatus;
    roleAssignment?: {
      __typename?: 'RoleAssignment';
      id: number;
      createdBy: string;
      creationDate: any;
      role: string;
      userActions: Array<UserAction>;
      department: {
        __typename?: 'MarblesDepartment';
        id: string;
        code: string;
        name: { __typename?: 'BilingualString'; NL: string; EN: string };
      };
      userRole: {
        __typename?: 'UserRole';
        name: string;
        description: { __typename?: 'BilingualString'; NL: string; EN: string };
      };
    } | null;
  };
};

export type CopyRequirementMutationVariables = Exact<{
  requirementId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  departmentId: Scalars['String']['input'];
}>;

export type CopyRequirementMutation = {
  __typename?: 'Mutation';
  copyRequirement: {
    __typename?: 'CopyRequirementMutationPayload';
    mutationStatus: MutationStatus;
    requirement?: {
      __typename?: 'Requirement';
      id: string;
      name: string;
      rulesInRequirement: Array<{
        __typename?: 'RuleInRequirement';
        id: string;
        rule: { __typename?: 'Rule'; id: string };
      }>;
      department: {
        __typename?: 'MarblesDepartment';
        id: string;
        allowedUserActions: Array<UserAction>;
        name: { __typename?: 'BilingualString'; NL: string; EN: string };
      };
      topics: Array<{ __typename?: 'Topic'; id: string }>;
      courseOfferings: Array<{ __typename?: 'MarblesCourseOffering'; id: string }>;
    } | null;
  };
};

export type CopyRuleMutationVariables = Exact<{
  ruleId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  departmentId: Scalars['String']['input'];
}>;

export type CopyRuleMutation = {
  __typename?: 'Mutation';
  copyRule: {
    __typename?: 'CopyRuleMutationPayload';
    mutationStatus: MutationStatus;
    rule?: {
      __typename?: 'Rule';
      id: string;
      ruleKind: RuleKind;
      name: string;
      requirements: Array<{ __typename?: 'RuleRequirementSummary'; id: string }>;
      department: {
        __typename?: 'MarblesDepartment';
        id: string;
        code: string;
        allowedUserActions: Array<UserAction>;
        name: { __typename?: 'BilingualString'; EN: string; NL: string };
      };
    } | null;
  };
};

export type CreateConstrainedChoiceClusterMutationVariables = Exact<{
  academicYear: Scalars['Int']['input'];
  departmentId: Scalars['String']['input'];
  titleNL: Scalars['String']['input'];
  titleEN: Scalars['String']['input'];
}>;

export type CreateConstrainedChoiceClusterMutation = {
  __typename?: 'Mutation';
  createConstrainedChoiceCluster: {
    __typename?: 'CreateConstrainedChoiceClusterMutationPayload';
    mutationStatus: MutationStatus;
    academicYear: number;
    constrainedChoiceCluster?: {
      __typename?: 'ConstrainedChoiceCluster';
      id: string;
      departmentId: string;
      registrationLimit: number;
      offerings: Array<{
        __typename?: 'MarblesCourseOffering';
        id: string;
        info?: {
          __typename?: 'MarblesCourseInfo';
          id: string;
          catalogNumber: string;
          ec: number;
          title: { __typename?: 'BilingualString'; NL: string; EN: string };
        } | null;
        periods: Array<{
          __typename?: 'Period';
          id: string;
          number: number;
          semesterNumber: number;
          periodInSemester: number;
          semester: { __typename?: 'Semester'; id: string };
        }>;
      }>;
      title: { __typename?: 'BilingualString'; NL: string; EN: string };
    } | null;
  };
};

export type CreatePendingCoursePursuitMutationVariables = Exact<{
  courseOfferingId: Scalars['String']['input'];
  studentNumber: Scalars['String']['input'];
  topicId?: InputMaybe<Scalars['String']['input']>;
  academicYear?: InputMaybe<Scalars['Int']['input']>;
}>;

export type CreatePendingCoursePursuitMutation = {
  __typename?: 'Mutation';
  administrativelySelectCourseForRegistration: {
    __typename?: 'AdministrativelySelectCourseForRegistrationPayload';
    mutationStatus: MutationStatus;
    courseOfferingId?: string | null;
    coursePursuitId?: string | null;
    topicId?: string | null;
    student?: {
      __typename?: 'Student';
      id: string;
      studentNumber: string;
      name: string;
      surname: string;
      givenName: string;
      enrolments: Array<{
        __typename?: 'Enrolment';
        id: string;
        endDate: any;
        startDate: any;
        career: {
          __typename?: 'Career';
          id: string;
          code: string;
          description: { __typename?: 'BilingualString'; NL: string; EN: string };
        };
        plan: { __typename?: 'Plan'; id: string; code: string; description: string };
        subplans: Array<{ __typename?: 'Subplan'; id: string; code: string; description: string }>;
      }>;
    } | null;
    clusterPreference?: {
      __typename?: 'CoursePursuitClusterPreference';
      index: number;
      pursuitCount: number;
      registrationLimit: number;
    } | null;
    requirementsCheck?: {
      __typename?: 'Requirements';
      groups: Array<{
        __typename?: 'RequirementGroup';
        name?: string | null;
        applies: boolean;
        descriptions: Array<{ __typename?: 'BilingualString'; NL: string; EN: string }>;
        rows: Array<{
          __typename?: 'RequirementRow';
          status: RequirementStatus;
          title?: { __typename?: 'BilingualString'; NL: string; EN: string } | null;
          progress?: { __typename?: 'BilingualString'; NL: string; EN: string } | null;
          details: Array<{
            __typename?: 'RequirementRowDetail';
            status: RequirementStatus;
            courseInfo?: {
              __typename?: 'MarblesCourseInfo';
              id: string;
              catalogNumber: string;
              ec: number;
              title: { __typename?: 'BilingualString'; EN: string; NL: string };
            } | null;
          }>;
        }>;
      }>;
    } | null;
  };
};

export type CreateRequirementMutationVariables = Exact<{
  academicYear: Scalars['Int']['input'];
  departmentId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  ruleId: Scalars['String']['input'];
}>;

export type CreateRequirementMutation = {
  __typename?: 'Mutation';
  createRequirement: {
    __typename?: 'CreateRequirementMutationPayload';
    mutationStatus: MutationStatus;
    academicYear: number;
    requirement?: {
      __typename?: 'Requirement';
      id: string;
      name: string;
      rulesInRequirement: Array<{
        __typename?: 'RuleInRequirement';
        id: string;
        rule: { __typename?: 'Rule'; id: string };
      }>;
      department: {
        __typename?: 'MarblesDepartment';
        id: string;
        allowedUserActions: Array<UserAction>;
        name: { __typename?: 'BilingualString'; NL: string; EN: string };
      };
      topics: Array<{ __typename?: 'Topic'; id: string }>;
      courseOfferings: Array<{ __typename?: 'MarblesCourseOffering'; id: string }>;
    } | null;
  };
};

export type CreateRuleRequiredCareerMutationVariables = Exact<{
  departmentId: Scalars['String']['input'];
  academicYear: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  careerIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type CreateRuleRequiredCareerMutation = {
  __typename?: 'Mutation';
  createRuleRequiredCareer: {
    __typename?: 'CreateRuleRequiredCareerMutationPayload';
    mutationStatus: MutationStatus;
    academicYear: number;
    rule?: {
      __typename?: 'Rule';
      id: string;
      ruleKind: RuleKind;
      name: string;
      requirements: Array<{ __typename?: 'RuleRequirementSummary'; id: string }>;
      department: {
        __typename?: 'MarblesDepartment';
        id: string;
        code: string;
        allowedUserActions: Array<UserAction>;
        name: { __typename?: 'BilingualString'; EN: string; NL: string };
      };
    } | null;
  };
};

export type CreateRuleRequiredPlanMutationVariables = Exact<{
  academicYear: Scalars['Int']['input'];
  departmentId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  plan: RequiredPlanInput;
}>;

export type CreateRuleRequiredPlanMutation = {
  __typename?: 'Mutation';
  createRuleRequiredPlan: {
    __typename?: 'CreateRuleRequiredPlanMutationPayload';
    academicYear: number;
    mutationStatus: MutationStatus;
    rule?: {
      __typename?: 'Rule';
      id: string;
      ruleKind: RuleKind;
      name: string;
      requirements: Array<{ __typename?: 'RuleRequirementSummary'; id: string }>;
      department: {
        __typename?: 'MarblesDepartment';
        id: string;
        code: string;
        allowedUserActions: Array<UserAction>;
        name: { __typename?: 'BilingualString'; EN: string; NL: string };
      };
    } | null;
  };
};

export type CreateRuleRequiredStudentSetMutationVariables = Exact<{
  academicYear: Scalars['Int']['input'];
  departmentId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  studentSetId: Scalars['String']['input'];
}>;

export type CreateRuleRequiredStudentSetMutation = {
  __typename?: 'Mutation';
  createRuleRequiredStudentSet: {
    __typename?: 'CreateRuleRequiredStudentSetMutationPayload';
    academicYear: number;
    mutationStatus: MutationStatus;
    rule?: {
      __typename?: 'Rule';
      id: string;
      ruleKind: RuleKind;
      name: string;
      requirements: Array<{ __typename?: 'RuleRequirementSummary'; id: string }>;
      department: {
        __typename?: 'MarblesDepartment';
        id: string;
        code: string;
        allowedUserActions: Array<UserAction>;
        name: { __typename?: 'BilingualString'; EN: string; NL: string };
      };
    } | null;
  };
};

export type CreateTopicSetMutationVariables = Exact<{
  academicYear: Scalars['Int']['input'];
  departmentId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  titleNL: Scalars['String']['input'];
  titleEN: Scalars['String']['input'];
}>;

export type CreateTopicSetMutation = {
  __typename?: 'Mutation';
  createTopicSet: {
    __typename?: 'CreateTopicSetMutationPayload';
    mutationStatus: MutationStatus;
    academicYear: number;
    topicSet?: {
      __typename?: 'TopicSet';
      id: string;
      name: string;
      courseOfferings: Array<{ __typename?: 'MarblesCourseOffering'; id: string }>;
      department: {
        __typename?: 'MarblesDepartment';
        id: string;
        name: { __typename?: 'BilingualString'; NL: string; EN: string };
      };
      title: { __typename?: 'BilingualString'; NL: string; EN: string };
      topics: Array<{
        __typename?: 'Topic';
        id: string;
        topicSetId: string;
        title: { __typename?: 'BilingualString'; NL: string; EN: string };
      }>;
    } | null;
  };
};

export type DeregisterStudentForCourseMutationVariables = Exact<{
  coursePursuitId: Scalars['String']['input'];
  reason?: InputMaybe<StaffDeregistrationReason>;
}>;

export type DeregisterStudentForCourseMutation = {
  __typename?: 'Mutation';
  deregisterStudentForCourse: {
    __typename?: 'AdministrativelyDeregisterStudentForCoursePayload';
    mutationStatus: MutationStatus;
    coursePursuitId: string;
    courseOfferingId?: string | null;
  };
};

export type LiftSuspensionMutationVariables = Exact<{
  username: Scalars['String']['input'];
}>;

export type LiftSuspensionMutation = {
  __typename?: 'Mutation';
  marblesLiftSuspension: { __typename?: 'MarblesLiftSuspensionMutationPayload'; mutationStatus: MutationStatus };
};

export type LockGroupMutationVariables = Exact<{
  groupId: Scalars['String']['input'];
}>;

export type LockGroupMutation = {
  __typename?: 'Mutation';
  lockStudentGroup: { __typename?: 'LockStudentGroupPayload'; mutationStatus: MutationStatus; studentGroupId: string };
};

export type ProcessGroupSwapMutationVariables = Exact<{
  groupSwapRequestId: Scalars['String']['input'];
  studentGroupId: Scalars['String']['input'];
}>;

export type ProcessGroupSwapMutation = {
  __typename?: 'Mutation';
  processGroupSwap: {
    __typename?: 'ProcessGroupSwapPayload';
    mutationStatus: MutationStatus;
    groupSwapRequest?: {
      __typename?: 'GroupSwapRequest';
      id: string;
      processedBy?: string | null;
      updateDate: any;
      status: GroupSwapStatus;
      courseOfferingId: string;
      newStudentGroup?: { __typename?: 'StudentGroup'; id: string; name: string; code: string } | null;
    } | null;
    studentGroups: Array<{
      __typename?: 'StudentGroup';
      groupSwapDemand: number;
      id: string;
      code: string;
      name: string;
      initialCapacity: number;
      configuredCapacity?: number | null;
      capacity: number;
      locked: boolean;
      numberOfRegisteredStudents: number;
      directRegistrationEnabled: boolean;
      topic?: {
        __typename?: 'Topic';
        id: string;
        title: { __typename?: 'BilingualString'; NL: string; EN: string };
      } | null;
      timetableSummary: { __typename?: 'BilingualString'; NL: string; EN: string };
    }>;
  };
};

export type RegisterStudentForCourseMutationVariables = Exact<{
  studentId: Scalars['String']['input'];
  courseOfferingId: Scalars['String']['input'];
  studentGroupId: Scalars['String']['input'];
  academicYear?: InputMaybe<Scalars['Int']['input']>;
}>;

export type RegisterStudentForCourseMutation = {
  __typename?: 'Mutation';
  registerStudentForCourse: {
    __typename?: 'RegisterStudentForCoursePayload';
    mutationStatus: MutationStatus;
    coursePursuitId?: string | null;
    courseOfferingId?: string | null;
    registrationDate?: any | null;
    reason?: string | null;
    student?: {
      __typename?: 'Student';
      id: string;
      studentNumber: string;
      name: string;
      surname: string;
      givenName: string;
      enrolments: Array<{
        __typename?: 'Enrolment';
        id: string;
        endDate: any;
        startDate: any;
        career: {
          __typename?: 'Career';
          id: string;
          code: string;
          description: { __typename?: 'BilingualString'; NL: string; EN: string };
        };
        plan: { __typename?: 'Plan'; id: string; code: string; description: string };
        subplans: Array<{ __typename?: 'Subplan'; id: string; code: string; description: string }>;
      }>;
    } | null;
    studentGroup?: { __typename?: 'StudentGroup'; id: string; code: string; name: string } | null;
    requirementsCheck?: {
      __typename?: 'Requirements';
      groups: Array<{
        __typename?: 'RequirementGroup';
        name?: string | null;
        applies: boolean;
        descriptions: Array<{ __typename?: 'BilingualString'; NL: string; EN: string }>;
        rows: Array<{
          __typename?: 'RequirementRow';
          status: RequirementStatus;
          title?: { __typename?: 'BilingualString'; NL: string; EN: string } | null;
          progress?: { __typename?: 'BilingualString'; NL: string; EN: string } | null;
          details: Array<{
            __typename?: 'RequirementRowDetail';
            status: RequirementStatus;
            courseInfo?: {
              __typename?: 'MarblesCourseInfo';
              id: string;
              catalogNumber: string;
              ec: number;
              title: { __typename?: 'BilingualString'; EN: string; NL: string };
            } | null;
          }>;
        }>;
      }>;
    } | null;
  };
};

export type RejectPendingCoursePursuitMutationVariables = Exact<{
  coursePursuitId: Scalars['String']['input'];
}>;

export type RejectPendingCoursePursuitMutation = {
  __typename?: 'Mutation';
  rejectPendingCoursePursuit: {
    __typename?: 'RejectPendingCoursePursuitPayload';
    mutationStatus: MutationStatus;
    coursePursuitId: string;
    courseOfferingId?: string | null;
  };
};

export type RemoveConstrainedChoiceClusterMutationVariables = Exact<{
  constrainedChoiceClusterId: Scalars['String']['input'];
}>;

export type RemoveConstrainedChoiceClusterMutation = {
  __typename?: 'Mutation';
  removeConstrainedChoiceCluster: {
    __typename?: 'RemoveConstrainedChoiceClusterPayload';
    constrainedChoiceClusterId: string;
    mutationStatus: MutationStatus;
  };
};

export type RemoveCourseOfferingFromClusterMutationVariables = Exact<{
  courseOfferingId: Scalars['String']['input'];
}>;

export type RemoveCourseOfferingFromClusterMutation = {
  __typename?: 'Mutation';
  setCourseOfferingConstrainedChoiceCluster: {
    __typename?: 'SetCourseOfferingConstrainedChoiceClusterPayload';
    clusterId?: string | null;
    mutationStatus: MutationStatus;
    courseOffering?: { __typename?: 'MarblesCourseOffering'; id: string } | null;
  };
};

export type RemoveInfoRequirementMutationVariables = Exact<{
  ruleId: Scalars['String']['input'];
  nodeId: Scalars['String']['input'];
}>;

export type RemoveInfoRequirementMutation = {
  __typename?: 'Mutation';
  removeInfoRequirement: {
    __typename?: 'RemoveInfoRequirementMutationPayload';
    nodeId: string;
    mutationStatus: MutationStatus;
    rule?: {
      __typename?: 'Rule';
      id: string;
      name: string;
      ruleKind: RuleKind;
      requirements: Array<{
        __typename?: 'RuleRequirementSummary';
        id: string;
        name: string;
        department: {
          __typename?: 'MarblesDepartment';
          id: string;
          parentId?: string | null;
          name: { __typename?: 'BilingualString'; EN: string; NL: string };
        };
      }>;
      department: {
        __typename?: 'MarblesDepartment';
        id: string;
        allowedUserActions: Array<UserAction>;
        name: { __typename?: 'BilingualString'; EN: string; NL: string };
      };
      nodes: {
        __typename?: 'RuleNodes';
        requirements: Array<
          | {
              __typename?: 'InfoRequirement';
              id: string;
              description: { __typename?: 'BilingualString'; NL: string; EN: string };
            }
          | {
              __typename?: 'RequiredCourses';
              id: string;
              requirement: RequiredCoursesRequirement;
              requiredCourses?: number | null;
              requiredCredits?: number | null;
              description: { __typename?: 'BilingualString'; NL: string; EN: string };
              courses: Array<{
                __typename?: 'MarblesCourseInfo';
                id: string;
                catalogNumber: string;
                ec: number;
                title: { __typename?: 'BilingualString'; NL: string; EN: string };
              }>;
            }
        >;
        groupRequirements: Array<
          | {
              __typename?: 'RequiredCareer';
              id: string;
              careers: Array<{
                __typename?: 'Career';
                id: string;
                code: string;
                description: { __typename?: 'BilingualString'; NL: string; EN: string };
              }>;
              description: { __typename?: 'BilingualString'; NL: string; EN: string };
            }
          | {
              __typename?: 'RequiredPlan';
              id: string;
              plan: {
                __typename?: 'Plan';
                id: string;
                code: string;
                description: string;
                subplans: Array<{ __typename?: 'Subplan'; id: string; code: string; description: string }>;
              };
              subplans?: Array<{ __typename?: 'Subplan'; id: string; code: string; description: string }> | null;
              description: { __typename?: 'BilingualString'; NL: string; EN: string };
            }
          | {
              __typename?: 'RequiredStudentSet';
              id: string;
              studentSet?: {
                __typename?: 'StudentSet';
                id: string;
                code: string;
                title: { __typename?: 'BilingualString'; EN: string; NL: string };
              } | null;
              description: { __typename?: 'BilingualString'; NL: string; EN: string };
            }
        >;
      };
    } | null;
  };
};

export type RemoveRequiredCoursesMutationVariables = Exact<{
  ruleId: Scalars['String']['input'];
  nodeId: Scalars['String']['input'];
}>;

export type RemoveRequiredCoursesMutation = {
  __typename?: 'Mutation';
  removeRequiredCourses: {
    __typename?: 'RemoveRequiredCoursesMutationPayload';
    nodeId: string;
    mutationStatus: MutationStatus;
    rule?: {
      __typename?: 'Rule';
      id: string;
      name: string;
      ruleKind: RuleKind;
      requirements: Array<{
        __typename?: 'RuleRequirementSummary';
        id: string;
        name: string;
        department: {
          __typename?: 'MarblesDepartment';
          id: string;
          parentId?: string | null;
          name: { __typename?: 'BilingualString'; EN: string; NL: string };
        };
      }>;
      department: {
        __typename?: 'MarblesDepartment';
        id: string;
        allowedUserActions: Array<UserAction>;
        name: { __typename?: 'BilingualString'; EN: string; NL: string };
      };
      nodes: {
        __typename?: 'RuleNodes';
        requirements: Array<
          | {
              __typename?: 'InfoRequirement';
              id: string;
              description: { __typename?: 'BilingualString'; NL: string; EN: string };
            }
          | {
              __typename?: 'RequiredCourses';
              id: string;
              requirement: RequiredCoursesRequirement;
              requiredCourses?: number | null;
              requiredCredits?: number | null;
              description: { __typename?: 'BilingualString'; NL: string; EN: string };
              courses: Array<{
                __typename?: 'MarblesCourseInfo';
                id: string;
                catalogNumber: string;
                ec: number;
                title: { __typename?: 'BilingualString'; NL: string; EN: string };
              }>;
            }
        >;
        groupRequirements: Array<
          | {
              __typename?: 'RequiredCareer';
              id: string;
              careers: Array<{
                __typename?: 'Career';
                id: string;
                code: string;
                description: { __typename?: 'BilingualString'; NL: string; EN: string };
              }>;
              description: { __typename?: 'BilingualString'; NL: string; EN: string };
            }
          | {
              __typename?: 'RequiredPlan';
              id: string;
              plan: {
                __typename?: 'Plan';
                id: string;
                code: string;
                description: string;
                subplans: Array<{ __typename?: 'Subplan'; id: string; code: string; description: string }>;
              };
              subplans?: Array<{ __typename?: 'Subplan'; id: string; code: string; description: string }> | null;
              description: { __typename?: 'BilingualString'; NL: string; EN: string };
            }
          | {
              __typename?: 'RequiredStudentSet';
              id: string;
              studentSet?: {
                __typename?: 'StudentSet';
                id: string;
                code: string;
                title: { __typename?: 'BilingualString'; EN: string; NL: string };
              } | null;
              description: { __typename?: 'BilingualString'; NL: string; EN: string };
            }
        >;
      };
    } | null;
  };
};

export type RemoveRequiredPlanMutationVariables = Exact<{
  ruleId: Scalars['String']['input'];
  nodeId: Scalars['String']['input'];
}>;

export type RemoveRequiredPlanMutation = {
  __typename?: 'Mutation';
  removeRequiredPlan: {
    __typename?: 'RemoveRequiredPlanMutationPayload';
    nodeId: string;
    mutationStatus: MutationStatus;
    rule?: {
      __typename?: 'Rule';
      id: string;
      ruleKind: RuleKind;
      name: string;
      requirements: Array<{ __typename?: 'RuleRequirementSummary'; id: string }>;
      department: {
        __typename?: 'MarblesDepartment';
        id: string;
        code: string;
        allowedUserActions: Array<UserAction>;
        name: { __typename?: 'BilingualString'; EN: string; NL: string };
      };
    } | null;
  };
};

export type RemoveRequirementMutationVariables = Exact<{
  requirementId: Scalars['String']['input'];
}>;

export type RemoveRequirementMutation = {
  __typename?: 'Mutation';
  removeRequirement: {
    __typename?: 'RemoveRequirementMutationPayload';
    mutationStatus: MutationStatus;
    academicYear?: number | null;
    departmentId?: string | null;
    requirementId: string;
  };
};

export type RemoveRuleMutationVariables = Exact<{
  ruleId: Scalars['String']['input'];
}>;

export type RemoveRuleMutation = {
  __typename?: 'Mutation';
  removeRule: {
    __typename?: 'RemoveRuleMutationPayload';
    mutationStatus: MutationStatus;
    academicYear?: number | null;
    departmentId?: string | null;
    ruleId: string;
  };
};

export type RemoveTopicMutationVariables = Exact<{
  topicId: Scalars['String']['input'];
}>;

export type RemoveTopicMutation = {
  __typename?: 'Mutation';
  removeTopic: {
    __typename?: 'RemoveTopicPayload';
    topicId: string;
    mutationStatus: MutationStatus;
    topicSetId?: string | null;
  };
};

export type RemoveTopicSetMutationVariables = Exact<{
  topicSetId: Scalars['String']['input'];
}>;

export type RemoveTopicSetMutation = {
  __typename?: 'Mutation';
  removeTopicSet: { __typename?: 'RemoveTopicSetPayload'; topicSetId: string; mutationStatus: MutationStatus };
};

export type RevokeAuthorisationMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type RevokeAuthorisationMutation = {
  __typename?: 'Mutation';
  marblesRevokeAuthorisation: {
    __typename?: 'MarblesRevokeAuthorisationMutationPayload';
    mutationStatus: MutationStatus;
  };
};

export type SetCourseOfferingCreditLimitMutationVariables = Exact<{
  courseOfferingId: Scalars['String']['input'];
  hasCreditLimitException: Scalars['Boolean']['input'];
}>;

export type SetCourseOfferingCreditLimitMutation = {
  __typename?: 'Mutation';
  setCourseOfferingCreditLimitException: {
    __typename?: 'SetCourseOfferingCreditLimitExceptionPayload';
    mutationStatus: MutationStatus;
    courseOfferingId: string;
    hasCreditLimitException: boolean;
  };
};

export type SetCourseOfferingStatusMutationVariables = Exact<{
  courseOfferingId: Scalars['String']['input'];
  status: MarblesCourseOfferingStatus;
}>;

export type SetCourseOfferingStatusMutation = {
  __typename?: 'Mutation';
  setCourseOfferingStatus: {
    __typename?: 'SetCourseOfferingStatusPayload';
    mutationStatus: MutationStatus;
    courseOfferingId: string;
    status: MarblesCourseOfferingStatus;
  };
};

export type SetCreditLimitExceptionByStudentIdMutationVariables = Exact<{
  studentId: Scalars['String']['input'];
  hasCreditLimitException: Scalars['Boolean']['input'];
}>;

export type SetCreditLimitExceptionByStudentIdMutation = {
  __typename?: 'Mutation';
  setCreditLimitException: {
    __typename?: 'SetCreditLimitExceptionPayload';
    mutationStatus: MutationStatus;
    studentId: string;
    hasCreditLimitException: boolean;
  };
};

export type SetGroupSwapEnabledMutationVariables = Exact<{
  courseOfferingId: Scalars['String']['input'];
  groupSwapEnabled: Scalars['Boolean']['input'];
}>;

export type SetGroupSwapEnabledMutation = {
  __typename?: 'Mutation';
  setGroupSwapEnabled: {
    __typename?: 'SetGroupSwapEnabledPayload';
    mutationStatus: MutationStatus;
    courseOfferingId: string;
    groupSwapEnabled: boolean;
  };
};

export type SetRequirementNodeByCourseOfferingIdMutationVariables = Exact<{
  courseOfferingId: Scalars['String']['input'];
  requirementNodeId?: InputMaybe<Scalars['String']['input']>;
}>;

export type SetRequirementNodeByCourseOfferingIdMutation = {
  __typename?: 'Mutation';
  setCourseOfferingRequirementNode: {
    __typename?: 'SetCourseOfferingRequirementNodePayload';
    mutationStatus: MutationStatus;
    courseOfferingId: string;
    requirementNodeId?: string | null;
    courseOfferingRequirementCapacities?: Array<{
      __typename?: 'CourseOfferingRequirementCapacity';
      allocationRound: number;
      pending: number;
      registered: number;
      priority?: number | null;
      priorityLimit?: number | null;
      includeInRetry: boolean;
      ruleId?: string | null;
      description?: { __typename?: 'BilingualString'; NL: string; EN: string } | null;
    }> | null;
  };
};

export type SetStudentGroupCapacityMutationVariables = Exact<{
  studentGroupId: Scalars['String']['input'];
  capacity?: InputMaybe<Scalars['Int']['input']>;
}>;

export type SetStudentGroupCapacityMutation = {
  __typename?: 'Mutation';
  setStudentGroupCapacity: {
    __typename?: 'SetStudentGroupCapacityPayload';
    mutationStatus: MutationStatus;
    configuredCapacity?: number | null;
    studentGroupId: string;
  };
};

export type SetTopicForStudentGroupMutationVariables = Exact<{
  studentGroupId: Scalars['String']['input'];
  topicId?: InputMaybe<Scalars['String']['input']>;
}>;

export type SetTopicForStudentGroupMutation = {
  __typename?: 'Mutation';
  setStudentGroupTopic: {
    __typename?: 'SetStudentGroupTopicPayload';
    studentGroupId?: string | null;
    mutationStatus: MutationStatus;
    topic?: {
      __typename?: 'Topic';
      id: string;
      title: { __typename?: 'BilingualString'; NL: string; EN: string };
    } | null;
  };
};

export type SetTopicByCoursePursuitIdMutationVariables = Exact<{
  coursePursuitId: Scalars['String']['input'];
  topicId?: InputMaybe<Scalars['String']['input']>;
}>;

export type SetTopicByCoursePursuitIdMutation = {
  __typename?: 'Mutation';
  setCoursePursuitTopic: {
    __typename?: 'SetCoursePursuitTopicPayload';
    mutationStatus: MutationStatus;
    topicId?: string | null;
    coursePursuitId: string;
  };
};

export type SetTopicSetByCourseOfferingIdMutationVariables = Exact<{
  courseOfferingId: Scalars['String']['input'];
  topicSetId?: InputMaybe<Scalars['String']['input']>;
}>;

export type SetTopicSetByCourseOfferingIdMutation = {
  __typename?: 'Mutation';
  setCourseOfferingTopicSet: {
    __typename?: 'SetCourseOfferingTopicSetPayload';
    mutationStatus: MutationStatus;
    courseOfferingId: string;
    topicSet?: {
      __typename?: 'TopicSet';
      id: string;
      name: string;
      title: { __typename?: 'BilingualString'; NL: string; EN: string };
      topics: Array<{
        __typename?: 'Topic';
        id: string;
        topicSetId: string;
        title: { __typename?: 'BilingualString'; NL: string; EN: string };
      }>;
    } | null;
  };
};

export type SwitchStudentGroupMutationVariables = Exact<{
  coursePursuitId: Scalars['String']['input'];
  studentGroupId: Scalars['String']['input'];
}>;

export type SwitchStudentGroupMutation = {
  __typename?: 'Mutation';
  switchStudentGroup: {
    __typename?: 'SwitchStudentGroupPayload';
    mutationStatus: MutationStatus;
    coursePursuitId: string;
    studentGroupId: string;
  };
};

export type UnlockGroupMutationVariables = Exact<{
  groupId: Scalars['String']['input'];
}>;

export type UnlockGroupMutation = {
  __typename?: 'Mutation';
  unlockStudentGroup: {
    __typename?: 'UnlockStudentGroupPayload';
    mutationStatus: MutationStatus;
    studentGroupId: string;
  };
};

export type UpdateConstrainedChoiceClusterMutationVariables = Exact<{
  constrainedChoiceClusterId: Scalars['String']['input'];
  titleEN: Scalars['String']['input'];
  titleNL: Scalars['String']['input'];
  registrationLimit: Scalars['Int']['input'];
}>;

export type UpdateConstrainedChoiceClusterMutation = {
  __typename?: 'Mutation';
  updateConstrainedChoiceCluster: {
    __typename?: 'UpdateConstrainedChoiceClusterMutationPayload';
    mutationStatus: MutationStatus;
    constrainedChoiceCluster?: {
      __typename?: 'ConstrainedChoiceCluster';
      id: string;
      departmentId: string;
      registrationLimit: number;
      title: { __typename?: 'BilingualString'; NL: string; EN: string };
    } | null;
  };
};

export type UpdateInfoRequirementMutationVariables = Exact<{
  ruleId: Scalars['String']['input'];
  nodeId: Scalars['String']['input'];
  description: BilingualStringInput;
}>;

export type UpdateInfoRequirementMutation = {
  __typename?: 'Mutation';
  updateInfoRequirement: {
    __typename?: 'UpdateInfoRequirementMutationPayload';
    mutationStatus: MutationStatus;
    rule?: {
      __typename?: 'Rule';
      id: string;
      name: string;
      ruleKind: RuleKind;
      requirements: Array<{
        __typename?: 'RuleRequirementSummary';
        id: string;
        name: string;
        department: {
          __typename?: 'MarblesDepartment';
          id: string;
          parentId?: string | null;
          name: { __typename?: 'BilingualString'; EN: string; NL: string };
        };
      }>;
      department: {
        __typename?: 'MarblesDepartment';
        id: string;
        allowedUserActions: Array<UserAction>;
        name: { __typename?: 'BilingualString'; EN: string; NL: string };
      };
      nodes: {
        __typename?: 'RuleNodes';
        requirements: Array<
          | {
              __typename?: 'InfoRequirement';
              id: string;
              description: { __typename?: 'BilingualString'; NL: string; EN: string };
            }
          | {
              __typename?: 'RequiredCourses';
              id: string;
              requirement: RequiredCoursesRequirement;
              requiredCourses?: number | null;
              requiredCredits?: number | null;
              description: { __typename?: 'BilingualString'; NL: string; EN: string };
              courses: Array<{
                __typename?: 'MarblesCourseInfo';
                id: string;
                catalogNumber: string;
                ec: number;
                title: { __typename?: 'BilingualString'; NL: string; EN: string };
              }>;
            }
        >;
        groupRequirements: Array<
          | {
              __typename?: 'RequiredCareer';
              id: string;
              careers: Array<{
                __typename?: 'Career';
                id: string;
                code: string;
                description: { __typename?: 'BilingualString'; NL: string; EN: string };
              }>;
              description: { __typename?: 'BilingualString'; NL: string; EN: string };
            }
          | {
              __typename?: 'RequiredPlan';
              id: string;
              plan: {
                __typename?: 'Plan';
                id: string;
                code: string;
                description: string;
                subplans: Array<{ __typename?: 'Subplan'; id: string; code: string; description: string }>;
              };
              subplans?: Array<{ __typename?: 'Subplan'; id: string; code: string; description: string }> | null;
              description: { __typename?: 'BilingualString'; NL: string; EN: string };
            }
          | {
              __typename?: 'RequiredStudentSet';
              id: string;
              studentSet?: {
                __typename?: 'StudentSet';
                id: string;
                code: string;
                title: { __typename?: 'BilingualString'; EN: string; NL: string };
              } | null;
              description: { __typename?: 'BilingualString'; NL: string; EN: string };
            }
        >;
      };
    } | null;
  };
};

export type UpdateRequiredCareerMutationVariables = Exact<{
  ruleId: Scalars['String']['input'];
  nodeId: Scalars['String']['input'];
  careerIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type UpdateRequiredCareerMutation = {
  __typename?: 'Mutation';
  updateRequiredCareer: {
    __typename?: 'UpdateRequiredCareerMutationPayload';
    nodeId: string;
    mutationStatus: MutationStatus;
    rule?: {
      __typename?: 'Rule';
      id: string;
      name: string;
      ruleKind: RuleKind;
      requirements: Array<{
        __typename?: 'RuleRequirementSummary';
        id: string;
        name: string;
        department: {
          __typename?: 'MarblesDepartment';
          id: string;
          parentId?: string | null;
          name: { __typename?: 'BilingualString'; EN: string; NL: string };
        };
      }>;
      department: {
        __typename?: 'MarblesDepartment';
        id: string;
        allowedUserActions: Array<UserAction>;
        name: { __typename?: 'BilingualString'; EN: string; NL: string };
      };
      nodes: {
        __typename?: 'RuleNodes';
        requirements: Array<
          | {
              __typename?: 'InfoRequirement';
              id: string;
              description: { __typename?: 'BilingualString'; NL: string; EN: string };
            }
          | {
              __typename?: 'RequiredCourses';
              id: string;
              requirement: RequiredCoursesRequirement;
              requiredCourses?: number | null;
              requiredCredits?: number | null;
              description: { __typename?: 'BilingualString'; NL: string; EN: string };
              courses: Array<{
                __typename?: 'MarblesCourseInfo';
                id: string;
                catalogNumber: string;
                ec: number;
                title: { __typename?: 'BilingualString'; NL: string; EN: string };
              }>;
            }
        >;
        groupRequirements: Array<
          | {
              __typename?: 'RequiredCareer';
              id: string;
              careers: Array<{
                __typename?: 'Career';
                id: string;
                code: string;
                description: { __typename?: 'BilingualString'; NL: string; EN: string };
              }>;
              description: { __typename?: 'BilingualString'; NL: string; EN: string };
            }
          | {
              __typename?: 'RequiredPlan';
              id: string;
              plan: {
                __typename?: 'Plan';
                id: string;
                code: string;
                description: string;
                subplans: Array<{ __typename?: 'Subplan'; id: string; code: string; description: string }>;
              };
              subplans?: Array<{ __typename?: 'Subplan'; id: string; code: string; description: string }> | null;
              description: { __typename?: 'BilingualString'; NL: string; EN: string };
            }
          | {
              __typename?: 'RequiredStudentSet';
              id: string;
              studentSet?: {
                __typename?: 'StudentSet';
                id: string;
                code: string;
                title: { __typename?: 'BilingualString'; EN: string; NL: string };
              } | null;
              description: { __typename?: 'BilingualString'; NL: string; EN: string };
            }
        >;
      };
    } | null;
  };
};

export type UpdateRequiredCoursesMutationVariables = Exact<{
  ruleId: Scalars['String']['input'];
  nodeId: Scalars['String']['input'];
  description: BilingualStringInput;
  courses: RequiredCoursesInput;
}>;

export type UpdateRequiredCoursesMutation = {
  __typename?: 'Mutation';
  updateRequiredCourses: {
    __typename?: 'UpdateRequiredCoursesMutationPayload';
    nodeId: string;
    mutationStatus: MutationStatus;
    rule?: {
      __typename?: 'Rule';
      id: string;
      name: string;
      ruleKind: RuleKind;
      requirements: Array<{
        __typename?: 'RuleRequirementSummary';
        id: string;
        name: string;
        department: {
          __typename?: 'MarblesDepartment';
          id: string;
          parentId?: string | null;
          name: { __typename?: 'BilingualString'; EN: string; NL: string };
        };
      }>;
      department: {
        __typename?: 'MarblesDepartment';
        id: string;
        allowedUserActions: Array<UserAction>;
        name: { __typename?: 'BilingualString'; EN: string; NL: string };
      };
      nodes: {
        __typename?: 'RuleNodes';
        requirements: Array<
          | {
              __typename?: 'InfoRequirement';
              id: string;
              description: { __typename?: 'BilingualString'; NL: string; EN: string };
            }
          | {
              __typename?: 'RequiredCourses';
              id: string;
              requirement: RequiredCoursesRequirement;
              requiredCourses?: number | null;
              requiredCredits?: number | null;
              description: { __typename?: 'BilingualString'; NL: string; EN: string };
              courses: Array<{
                __typename?: 'MarblesCourseInfo';
                id: string;
                catalogNumber: string;
                ec: number;
                title: { __typename?: 'BilingualString'; NL: string; EN: string };
              }>;
            }
        >;
        groupRequirements: Array<
          | {
              __typename?: 'RequiredCareer';
              id: string;
              careers: Array<{
                __typename?: 'Career';
                id: string;
                code: string;
                description: { __typename?: 'BilingualString'; NL: string; EN: string };
              }>;
              description: { __typename?: 'BilingualString'; NL: string; EN: string };
            }
          | {
              __typename?: 'RequiredPlan';
              id: string;
              plan: {
                __typename?: 'Plan';
                id: string;
                code: string;
                description: string;
                subplans: Array<{ __typename?: 'Subplan'; id: string; code: string; description: string }>;
              };
              subplans?: Array<{ __typename?: 'Subplan'; id: string; code: string; description: string }> | null;
              description: { __typename?: 'BilingualString'; NL: string; EN: string };
            }
          | {
              __typename?: 'RequiredStudentSet';
              id: string;
              studentSet?: {
                __typename?: 'StudentSet';
                id: string;
                code: string;
                title: { __typename?: 'BilingualString'; EN: string; NL: string };
              } | null;
              description: { __typename?: 'BilingualString'; NL: string; EN: string };
            }
        >;
      };
    } | null;
  };
};

export type UpdateRequiredPlanMutationVariables = Exact<{
  ruleId: Scalars['String']['input'];
  nodeId: Scalars['String']['input'];
  plan: RequiredPlanInput;
}>;

export type UpdateRequiredPlanMutation = {
  __typename?: 'Mutation';
  updateRequiredPlan: {
    __typename?: 'UpdateRequiredPlanMutationPayload';
    nodeId: string;
    mutationStatus: MutationStatus;
    rule?: {
      __typename?: 'Rule';
      id: string;
      name: string;
      ruleKind: RuleKind;
      requirements: Array<{
        __typename?: 'RuleRequirementSummary';
        id: string;
        name: string;
        department: {
          __typename?: 'MarblesDepartment';
          id: string;
          parentId?: string | null;
          name: { __typename?: 'BilingualString'; EN: string; NL: string };
        };
      }>;
      department: {
        __typename?: 'MarblesDepartment';
        id: string;
        allowedUserActions: Array<UserAction>;
        name: { __typename?: 'BilingualString'; EN: string; NL: string };
      };
      nodes: {
        __typename?: 'RuleNodes';
        requirements: Array<
          | {
              __typename?: 'InfoRequirement';
              id: string;
              description: { __typename?: 'BilingualString'; NL: string; EN: string };
            }
          | {
              __typename?: 'RequiredCourses';
              id: string;
              requirement: RequiredCoursesRequirement;
              requiredCourses?: number | null;
              requiredCredits?: number | null;
              description: { __typename?: 'BilingualString'; NL: string; EN: string };
              courses: Array<{
                __typename?: 'MarblesCourseInfo';
                id: string;
                catalogNumber: string;
                ec: number;
                title: { __typename?: 'BilingualString'; NL: string; EN: string };
              }>;
            }
        >;
        groupRequirements: Array<
          | {
              __typename?: 'RequiredCareer';
              id: string;
              careers: Array<{
                __typename?: 'Career';
                id: string;
                code: string;
                description: { __typename?: 'BilingualString'; NL: string; EN: string };
              }>;
              description: { __typename?: 'BilingualString'; NL: string; EN: string };
            }
          | {
              __typename?: 'RequiredPlan';
              id: string;
              plan: {
                __typename?: 'Plan';
                id: string;
                code: string;
                description: string;
                subplans: Array<{ __typename?: 'Subplan'; id: string; code: string; description: string }>;
              };
              subplans?: Array<{ __typename?: 'Subplan'; id: string; code: string; description: string }> | null;
              description: { __typename?: 'BilingualString'; NL: string; EN: string };
            }
          | {
              __typename?: 'RequiredStudentSet';
              id: string;
              studentSet?: {
                __typename?: 'StudentSet';
                id: string;
                code: string;
                title: { __typename?: 'BilingualString'; EN: string; NL: string };
              } | null;
              description: { __typename?: 'BilingualString'; NL: string; EN: string };
            }
        >;
      };
    } | null;
  };
};

export type UpdateRequiredStudentSetMutationVariables = Exact<{
  ruleId: Scalars['String']['input'];
  nodeId: Scalars['String']['input'];
  studentSetId: Scalars['String']['input'];
}>;

export type UpdateRequiredStudentSetMutation = {
  __typename?: 'Mutation';
  updateRequiredStudentSet: {
    __typename?: 'UpdateRequiredStudentSetMutationPayload';
    mutationStatus: MutationStatus;
    rule?: {
      __typename?: 'Rule';
      id: string;
      name: string;
      ruleKind: RuleKind;
      requirements: Array<{
        __typename?: 'RuleRequirementSummary';
        id: string;
        name: string;
        department: {
          __typename?: 'MarblesDepartment';
          id: string;
          parentId?: string | null;
          name: { __typename?: 'BilingualString'; EN: string; NL: string };
        };
      }>;
      department: {
        __typename?: 'MarblesDepartment';
        id: string;
        allowedUserActions: Array<UserAction>;
        name: { __typename?: 'BilingualString'; EN: string; NL: string };
      };
      nodes: {
        __typename?: 'RuleNodes';
        requirements: Array<
          | {
              __typename?: 'InfoRequirement';
              id: string;
              description: { __typename?: 'BilingualString'; NL: string; EN: string };
            }
          | {
              __typename?: 'RequiredCourses';
              id: string;
              requirement: RequiredCoursesRequirement;
              requiredCourses?: number | null;
              requiredCredits?: number | null;
              description: { __typename?: 'BilingualString'; NL: string; EN: string };
              courses: Array<{
                __typename?: 'MarblesCourseInfo';
                id: string;
                catalogNumber: string;
                ec: number;
                title: { __typename?: 'BilingualString'; NL: string; EN: string };
              }>;
            }
        >;
        groupRequirements: Array<
          | {
              __typename?: 'RequiredCareer';
              id: string;
              careers: Array<{
                __typename?: 'Career';
                id: string;
                code: string;
                description: { __typename?: 'BilingualString'; NL: string; EN: string };
              }>;
              description: { __typename?: 'BilingualString'; NL: string; EN: string };
            }
          | {
              __typename?: 'RequiredPlan';
              id: string;
              plan: {
                __typename?: 'Plan';
                id: string;
                code: string;
                description: string;
                subplans: Array<{ __typename?: 'Subplan'; id: string; code: string; description: string }>;
              };
              subplans?: Array<{ __typename?: 'Subplan'; id: string; code: string; description: string }> | null;
              description: { __typename?: 'BilingualString'; NL: string; EN: string };
            }
          | {
              __typename?: 'RequiredStudentSet';
              id: string;
              studentSet?: {
                __typename?: 'StudentSet';
                id: string;
                code: string;
                title: { __typename?: 'BilingualString'; EN: string; NL: string };
              } | null;
              description: { __typename?: 'BilingualString'; NL: string; EN: string };
            }
        >;
      };
    } | null;
  };
};

export type UpdateRequirementNameMutationVariables = Exact<{
  requirementId: Scalars['String']['input'];
  name: Scalars['String']['input'];
}>;

export type UpdateRequirementNameMutation = {
  __typename?: 'Mutation';
  updateRequirementName: {
    __typename?: 'UpdateRequirementNameMutationPayload';
    mutationStatus: MutationStatus;
    requirement?: {
      __typename?: 'Requirement';
      id: string;
      name: string;
      topics: Array<{ __typename?: 'Topic'; id: string }>;
      department: { __typename?: 'MarblesDepartment'; id: string };
      courseOfferings: Array<{ __typename?: 'MarblesCourseOffering'; id: string }>;
      rulesInRequirement: Array<{
        __typename?: 'RuleInRequirement';
        id: string;
        allocationRound: number;
        priority?: number | null;
        priorityLimit?: number | null;
        includeInRetry: boolean;
        rule: {
          __typename?: 'Rule';
          id: string;
          name: string;
          ruleKind: RuleKind;
          requirements: Array<{
            __typename?: 'RuleRequirementSummary';
            id: string;
            name: string;
            department: {
              __typename?: 'MarblesDepartment';
              id: string;
              parentId?: string | null;
              name: { __typename?: 'BilingualString'; EN: string; NL: string };
            };
          }>;
          department: {
            __typename?: 'MarblesDepartment';
            id: string;
            allowedUserActions: Array<UserAction>;
            name: { __typename?: 'BilingualString'; EN: string; NL: string };
          };
          nodes: {
            __typename?: 'RuleNodes';
            requirements: Array<
              | {
                  __typename?: 'InfoRequirement';
                  id: string;
                  description: { __typename?: 'BilingualString'; NL: string; EN: string };
                }
              | {
                  __typename?: 'RequiredCourses';
                  id: string;
                  requirement: RequiredCoursesRequirement;
                  requiredCourses?: number | null;
                  requiredCredits?: number | null;
                  description: { __typename?: 'BilingualString'; NL: string; EN: string };
                  courses: Array<{
                    __typename?: 'MarblesCourseInfo';
                    id: string;
                    catalogNumber: string;
                    ec: number;
                    title: { __typename?: 'BilingualString'; NL: string; EN: string };
                  }>;
                }
            >;
            groupRequirements: Array<
              | {
                  __typename?: 'RequiredCareer';
                  id: string;
                  careers: Array<{
                    __typename?: 'Career';
                    id: string;
                    code: string;
                    description: { __typename?: 'BilingualString'; NL: string; EN: string };
                  }>;
                  description: { __typename?: 'BilingualString'; NL: string; EN: string };
                }
              | {
                  __typename?: 'RequiredPlan';
                  id: string;
                  plan: {
                    __typename?: 'Plan';
                    id: string;
                    code: string;
                    description: string;
                    subplans: Array<{ __typename?: 'Subplan'; id: string; code: string; description: string }>;
                  };
                  subplans?: Array<{ __typename?: 'Subplan'; id: string; code: string; description: string }> | null;
                  description: { __typename?: 'BilingualString'; NL: string; EN: string };
                }
              | {
                  __typename?: 'RequiredStudentSet';
                  id: string;
                  studentSet?: {
                    __typename?: 'StudentSet';
                    id: string;
                    code: string;
                    title: { __typename?: 'BilingualString'; EN: string; NL: string };
                  } | null;
                  description: { __typename?: 'BilingualString'; NL: string; EN: string };
                }
            >;
          };
        };
      }>;
    } | null;
  };
};

export type UpdateRequirementRulesMutationVariables = Exact<{
  requirementId: Scalars['String']['input'];
  rules: Array<RuleInRequirementInput> | RuleInRequirementInput;
}>;

export type UpdateRequirementRulesMutation = {
  __typename?: 'Mutation';
  updateRequirementRules: {
    __typename?: 'UpdateRequirementRulesMutationPayload';
    mutationStatus: MutationStatus;
    requirement?: {
      __typename?: 'Requirement';
      id: string;
      name: string;
      topics: Array<{ __typename?: 'Topic'; id: string }>;
      department: { __typename?: 'MarblesDepartment'; id: string };
      courseOfferings: Array<{ __typename?: 'MarblesCourseOffering'; id: string }>;
      rulesInRequirement: Array<{
        __typename?: 'RuleInRequirement';
        id: string;
        allocationRound: number;
        priority?: number | null;
        priorityLimit?: number | null;
        includeInRetry: boolean;
        rule: {
          __typename?: 'Rule';
          id: string;
          name: string;
          ruleKind: RuleKind;
          requirements: Array<{
            __typename?: 'RuleRequirementSummary';
            id: string;
            name: string;
            department: {
              __typename?: 'MarblesDepartment';
              id: string;
              parentId?: string | null;
              name: { __typename?: 'BilingualString'; EN: string; NL: string };
            };
          }>;
          department: {
            __typename?: 'MarblesDepartment';
            id: string;
            allowedUserActions: Array<UserAction>;
            name: { __typename?: 'BilingualString'; EN: string; NL: string };
          };
          nodes: {
            __typename?: 'RuleNodes';
            requirements: Array<
              | {
                  __typename?: 'InfoRequirement';
                  id: string;
                  description: { __typename?: 'BilingualString'; NL: string; EN: string };
                }
              | {
                  __typename?: 'RequiredCourses';
                  id: string;
                  requirement: RequiredCoursesRequirement;
                  requiredCourses?: number | null;
                  requiredCredits?: number | null;
                  description: { __typename?: 'BilingualString'; NL: string; EN: string };
                  courses: Array<{
                    __typename?: 'MarblesCourseInfo';
                    id: string;
                    catalogNumber: string;
                    ec: number;
                    title: { __typename?: 'BilingualString'; NL: string; EN: string };
                  }>;
                }
            >;
            groupRequirements: Array<
              | {
                  __typename?: 'RequiredCareer';
                  id: string;
                  careers: Array<{
                    __typename?: 'Career';
                    id: string;
                    code: string;
                    description: { __typename?: 'BilingualString'; NL: string; EN: string };
                  }>;
                  description: { __typename?: 'BilingualString'; NL: string; EN: string };
                }
              | {
                  __typename?: 'RequiredPlan';
                  id: string;
                  plan: {
                    __typename?: 'Plan';
                    id: string;
                    code: string;
                    description: string;
                    subplans: Array<{ __typename?: 'Subplan'; id: string; code: string; description: string }>;
                  };
                  subplans?: Array<{ __typename?: 'Subplan'; id: string; code: string; description: string }> | null;
                  description: { __typename?: 'BilingualString'; NL: string; EN: string };
                }
              | {
                  __typename?: 'RequiredStudentSet';
                  id: string;
                  studentSet?: {
                    __typename?: 'StudentSet';
                    id: string;
                    code: string;
                    title: { __typename?: 'BilingualString'; EN: string; NL: string };
                  } | null;
                  description: { __typename?: 'BilingualString'; NL: string; EN: string };
                }
            >;
          };
        };
      }>;
    } | null;
  };
};

export type UpdateRuleNameMutationVariables = Exact<{
  ruleId: Scalars['String']['input'];
  name: Scalars['String']['input'];
}>;

export type UpdateRuleNameMutation = {
  __typename?: 'Mutation';
  updateRuleName: {
    __typename?: 'UpdateRuleNameMutationPayload';
    mutationStatus: MutationStatus;
    rule?: {
      __typename?: 'Rule';
      id: string;
      name: string;
      ruleKind: RuleKind;
      requirements: Array<{
        __typename?: 'RuleRequirementSummary';
        id: string;
        name: string;
        department: {
          __typename?: 'MarblesDepartment';
          id: string;
          parentId?: string | null;
          name: { __typename?: 'BilingualString'; EN: string; NL: string };
        };
      }>;
      department: {
        __typename?: 'MarblesDepartment';
        id: string;
        allowedUserActions: Array<UserAction>;
        name: { __typename?: 'BilingualString'; EN: string; NL: string };
      };
      nodes: {
        __typename?: 'RuleNodes';
        requirements: Array<
          | {
              __typename?: 'InfoRequirement';
              id: string;
              description: { __typename?: 'BilingualString'; NL: string; EN: string };
            }
          | {
              __typename?: 'RequiredCourses';
              id: string;
              requirement: RequiredCoursesRequirement;
              requiredCourses?: number | null;
              requiredCredits?: number | null;
              description: { __typename?: 'BilingualString'; NL: string; EN: string };
              courses: Array<{
                __typename?: 'MarblesCourseInfo';
                id: string;
                catalogNumber: string;
                ec: number;
                title: { __typename?: 'BilingualString'; NL: string; EN: string };
              }>;
            }
        >;
        groupRequirements: Array<
          | {
              __typename?: 'RequiredCareer';
              id: string;
              careers: Array<{
                __typename?: 'Career';
                id: string;
                code: string;
                description: { __typename?: 'BilingualString'; NL: string; EN: string };
              }>;
              description: { __typename?: 'BilingualString'; NL: string; EN: string };
            }
          | {
              __typename?: 'RequiredPlan';
              id: string;
              plan: {
                __typename?: 'Plan';
                id: string;
                code: string;
                description: string;
                subplans: Array<{ __typename?: 'Subplan'; id: string; code: string; description: string }>;
              };
              subplans?: Array<{ __typename?: 'Subplan'; id: string; code: string; description: string }> | null;
              description: { __typename?: 'BilingualString'; NL: string; EN: string };
            }
          | {
              __typename?: 'RequiredStudentSet';
              id: string;
              studentSet?: {
                __typename?: 'StudentSet';
                id: string;
                code: string;
                title: { __typename?: 'BilingualString'; EN: string; NL: string };
              } | null;
              description: { __typename?: 'BilingualString'; NL: string; EN: string };
            }
        >;
      };
    } | null;
  };
};

export type UpdateTopicMutationVariables = Exact<{
  topicId: Scalars['String']['input'];
  titleNL: Scalars['String']['input'];
  titleEN: Scalars['String']['input'];
  requirementNodeId?: InputMaybe<Scalars['String']['input']>;
}>;

export type UpdateTopicMutation = {
  __typename?: 'Mutation';
  updateTopic: {
    __typename?: 'UpdateTopicMutationPayload';
    mutationStatus: MutationStatus;
    topic?: {
      __typename?: 'Topic';
      id: string;
      topicSetId: string;
      title: { __typename?: 'BilingualString'; NL: string; EN: string };
      requirement?: { __typename?: 'Requirement'; id: string; name: string } | null;
    } | null;
  };
};

export type UpdateTopicSetNameMutationVariables = Exact<{
  topicSetId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  titleNL: Scalars['String']['input'];
  titleEN: Scalars['String']['input'];
}>;

export type UpdateTopicSetNameMutation = {
  __typename?: 'Mutation';
  updateTopicSetName: {
    __typename?: 'UpdateTopicSetNameMutationPayload';
    mutationStatus: MutationStatus;
    topicSet?: {
      __typename?: 'TopicSet';
      id: string;
      name: string;
      title: { __typename?: 'BilingualString'; NL: string; EN: string };
      topics: Array<{
        __typename?: 'Topic';
        id: string;
        topicSetId: string;
        title: { __typename?: 'BilingualString'; NL: string; EN: string };
      }>;
    } | null;
  };
};

export type UploadBulkCourseDeregistrationsMutationVariables = Exact<{
  academicYear: Scalars['Int']['input'];
  file: Scalars['Upload']['input'];
  deregistrationReason: StaffDeregistrationReason;
  ignorePursuitsNotFound?: Scalars['Boolean']['input'];
}>;

export type UploadBulkCourseDeregistrationsMutation = {
  __typename?: 'Mutation';
  uploadBulkCourseDeregistrations: {
    __typename?: 'UploadBulkCourseDeregistrationsPayload';
    mutationStatus: MutationStatus;
    details?: Array<string> | null;
    missingColumns?: Array<MissingColumn> | null;
    result: Array<{
      __typename?: 'UploadCourseDeregistrationsPayloadResultRow';
      line: number;
      rowStatus: MutationStatus;
      studentNumber: string;
    }>;
    pursuits: Array<{ __typename?: 'CoursePursuit'; id: string }>;
  };
};

export type UploadBulkCourseRegistrationsMutationVariables = Exact<{
  file: Scalars['Upload']['input'];
  academicYear: Scalars['Int']['input'];
  skipRequirements?: Scalars['Boolean']['input'];
  switchGroupIfAlreadyRegistered: Scalars['Boolean']['input'];
}>;

export type UploadBulkCourseRegistrationsMutation = {
  __typename?: 'Mutation';
  uploadBulkCourseRegistrations: {
    __typename?: 'UploadBulkCourseRegistrationsPayload';
    mutationStatus: MutationStatus;
    details?: Array<string> | null;
    missingColumns?: Array<MissingColumn> | null;
    result: Array<{
      __typename?: 'UploadCourseRegistrationsPayloadResultRow';
      groupCode: string;
      line: number;
      rowStatus: MutationStatus;
      studentNumber: string;
    }>;
  };
};

export type UploadCourseDeregistrationsMutationVariables = Exact<{
  courseOfferingId: Scalars['String']['input'];
  file: Scalars['Upload']['input'];
  reason?: InputMaybe<StaffDeregistrationReason>;
}>;

export type UploadCourseDeregistrationsMutation = {
  __typename?: 'Mutation';
  uploadCourseDeregistrations: {
    __typename?: 'UploadCourseDeregistrationsPayload';
    mutationStatus: MutationStatus;
    courseOfferingId: string;
    missingColumns?: Array<MissingColumn> | null;
    result: Array<{
      __typename?: 'UploadCourseDeregistrationsPayloadResultRow';
      line: number;
      studentNumber: string;
      rowStatus: MutationStatus;
    }>;
  };
};

export type UploadCoursePendingStudentsMutationVariables = Exact<{
  courseOfferingId: Scalars['String']['input'];
  switchTopicIfAlreadyPending: Scalars['Boolean']['input'];
  file: Scalars['Upload']['input'];
  skipRequirements?: Scalars['Boolean']['input'];
}>;

export type UploadCoursePendingStudentsMutation = {
  __typename?: 'Mutation';
  uploadCoursePendingStudents: {
    __typename?: 'UploadCoursePendingStudentsPayload';
    courseOfferingId: string;
    mutationStatus: MutationStatus;
    missingColumns?: Array<MissingColumn> | null;
    result: Array<{ __typename?: 'Row'; line: number; rowStatus: MutationStatus; studentNumber: string }>;
    pursuits: Array<{
      __typename?: 'CoursePursuit';
      id: string;
      topicId?: string | null;
      student: {
        __typename?: 'Student';
        id: string;
        studentNumber: string;
        name: string;
        surname: string;
        givenName: string;
        enrolments: Array<{
          __typename?: 'Enrolment';
          id: string;
          endDate: any;
          startDate: any;
          career: {
            __typename?: 'Career';
            id: string;
            code: string;
            description: { __typename?: 'BilingualString'; NL: string; EN: string };
          };
          plan: { __typename?: 'Plan'; id: string; code: string; description: string };
          subplans: Array<{ __typename?: 'Subplan'; id: string; code: string; description: string }>;
        }>;
      };
      requirementsCheck?: {
        __typename?: 'Requirements';
        groups: Array<{
          __typename?: 'RequirementGroup';
          name?: string | null;
          applies: boolean;
          descriptions: Array<{ __typename?: 'BilingualString'; NL: string; EN: string }>;
          rows: Array<{
            __typename?: 'RequirementRow';
            status: RequirementStatus;
            title?: { __typename?: 'BilingualString'; NL: string; EN: string } | null;
            progress?: { __typename?: 'BilingualString'; NL: string; EN: string } | null;
            details: Array<{
              __typename?: 'RequirementRowDetail';
              status: RequirementStatus;
              courseInfo?: {
                __typename?: 'MarblesCourseInfo';
                id: string;
                catalogNumber: string;
                ec: number;
                title: { __typename?: 'BilingualString'; EN: string; NL: string };
              } | null;
            }>;
          }>;
        }>;
      } | null;
      clusterPreference?: {
        __typename?: 'CoursePursuitClusterPreference';
        index: number;
        pursuitCount: number;
        registrationLimit: number;
      } | null;
    }>;
  };
};

export type UploadCourseRegistrationsMutationVariables = Exact<{
  file: Scalars['Upload']['input'];
  courseOfferingId: Scalars['String']['input'];
  skipRequirements?: Scalars['Boolean']['input'];
  switchGroupIfAlreadyRegistered?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type UploadCourseRegistrationsMutation = {
  __typename?: 'Mutation';
  uploadCourseRegistrations: {
    __typename?: 'UploadCourseRegistrationsPayload';
    mutationStatus: MutationStatus;
    courseOfferingId: string;
    missingColumns?: Array<MissingColumn> | null;
    pursuits: Array<{
      __typename?: 'CoursePursuit';
      id: string;
      registrationDate?: any | null;
      student: {
        __typename?: 'Student';
        id: string;
        studentNumber: string;
        name: string;
        surname: string;
        givenName: string;
        enrolments: Array<{
          __typename?: 'Enrolment';
          id: string;
          endDate: any;
          startDate: any;
          career: {
            __typename?: 'Career';
            id: string;
            code: string;
            description: { __typename?: 'BilingualString'; NL: string; EN: string };
          };
          plan: { __typename?: 'Plan'; id: string; code: string; description: string };
          subplans: Array<{ __typename?: 'Subplan'; id: string; code: string; description: string }>;
        }>;
      };
      studentGroup?: { __typename?: 'StudentGroup'; id: string; code: string; name: string } | null;
      requirementsCheck?: {
        __typename?: 'Requirements';
        groups: Array<{
          __typename?: 'RequirementGroup';
          name?: string | null;
          applies: boolean;
          descriptions: Array<{ __typename?: 'BilingualString'; NL: string; EN: string }>;
          rows: Array<{
            __typename?: 'RequirementRow';
            status: RequirementStatus;
            title?: { __typename?: 'BilingualString'; NL: string; EN: string } | null;
            progress?: { __typename?: 'BilingualString'; NL: string; EN: string } | null;
            details: Array<{
              __typename?: 'RequirementRowDetail';
              status: RequirementStatus;
              courseInfo?: {
                __typename?: 'MarblesCourseInfo';
                id: string;
                catalogNumber: string;
                ec: number;
                title: { __typename?: 'BilingualString'; EN: string; NL: string };
              } | null;
            }>;
          }>;
        }>;
      } | null;
    }>;
    result: Array<{
      __typename?: 'UploadCourseRegistrationsPayloadResultRow';
      groupCode: string;
      line: number;
      rowStatus: MutationStatus;
      studentNumber: string;
    }>;
  };
};

export type UploadCreditLimitExceptionsMutationVariables = Exact<{
  file: Scalars['Upload']['input'];
}>;

export type UploadCreditLimitExceptionsMutation = {
  __typename?: 'Mutation';
  uploadCreditLimitExceptions: {
    __typename?: 'UploadCreditLimitExceptionsPayload';
    mutationStatus: MutationStatus;
    missingColumns?: Array<MissingColumn> | null;
    result: Array<{
      __typename?: 'UploadCreditLimitExceptionsPayloadResultRow';
      line: number;
      rowStatus: MutationStatus;
      studentNumber: string;
    }>;
  };
};

export type FindStudentsQueryVariables = Exact<{
  query: Scalars['String']['input'];
}>;

export type FindStudentsQuery = {
  __typename?: 'Query';
  students: {
    __typename?: 'StudentSearchResult';
    searchResults: Array<{
      __typename?: 'Student';
      id: string;
      studentNumber: string;
      name: string;
      surname: string;
      givenName: string;
      enrolments: Array<{
        __typename?: 'Enrolment';
        id: string;
        endDate: any;
        startDate: any;
        career: {
          __typename?: 'Career';
          id: string;
          code: string;
          description: { __typename?: 'BilingualString'; NL: string; EN: string };
        };
        plan: { __typename?: 'Plan'; id: string; code: string; description: string };
        subplans: Array<{ __typename?: 'Subplan'; id: string; code: string; description: string }>;
      }>;
    }>;
  };
};

export type GetAcademicYearsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAcademicYearsQuery = {
  __typename?: 'Query';
  marblesAcademicYears: Array<{
    __typename?: 'MarblesAcademicYear';
    id: number;
    name: string;
    startDate: any;
    endDate: any;
    isCurrentAcademicYearForAdmins: boolean;
    isArchived: boolean;
  }>;
};

export type GetAuthorisationRightsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAuthorisationRightsQuery = {
  __typename?: 'Query';
  marblesAuthorisationRights: {
    __typename?: 'MarblesAuthorisationRights';
    userRoles: Array<{
      __typename?: 'UserRole';
      name: string;
      description: { __typename?: 'BilingualString'; NL: string; EN: string };
    }>;
    departments: Array<{
      __typename?: 'MarblesDepartment';
      id: string;
      code: string;
      name: { __typename?: 'BilingualString'; NL: string; EN: string };
    }>;
  };
};

export type GetCareersQueryVariables = Exact<{ [key: string]: never }>;

export type GetCareersQuery = {
  __typename?: 'Query';
  careers: Array<{
    __typename?: 'Career';
    id: string;
    code: string;
    description: { __typename?: 'BilingualString'; NL: string; EN: string };
  }>;
};

export type GetConstrainedChoiceClusterByIdQueryVariables = Exact<{
  constrainedChoiceClusterId: Scalars['String']['input'];
}>;

export type GetConstrainedChoiceClusterByIdQuery = {
  __typename?: 'Query';
  constrainedChoiceCluster?: {
    __typename?: 'ConstrainedChoiceCluster';
    id: string;
    departmentId: string;
    registrationLimit: number;
    offerings: Array<{
      __typename?: 'MarblesCourseOffering';
      id: string;
      numberOfPendingStudents: number;
      info?: {
        __typename?: 'MarblesCourseInfo';
        id: string;
        catalogNumber: string;
        ec: number;
        title: { __typename?: 'BilingualString'; NL: string; EN: string };
      } | null;
      periods: Array<{
        __typename?: 'Period';
        id: string;
        number: number;
        semesterNumber: number;
        periodInSemester: number;
        semester: { __typename?: 'Semester'; id: string };
      }>;
      registrationPeriod: {
        __typename?: 'Period';
        id: string;
        number: number;
        semesterNumber: number;
        periodInSemester: number;
        semester: { __typename?: 'Semester'; id: string };
      };
    }>;
    academicYear: { __typename?: 'MarblesAcademicYear'; id: number; name: string; isArchived: boolean };
    department: {
      __typename?: 'MarblesDepartment';
      id: string;
      code: string;
      allowedUserActions: Array<UserAction>;
      parentId?: string | null;
      name: { __typename?: 'BilingualString'; EN: string; NL: string };
    };
    title: { __typename?: 'BilingualString'; NL: string; EN: string };
  } | null;
};

export type GetConstrainedChoiceClustersByDepartmentIdQueryVariables = Exact<{
  departmentId: Scalars['String']['input'];
  academicYear: Scalars['Int']['input'];
}>;

export type GetConstrainedChoiceClustersByDepartmentIdQuery = {
  __typename?: 'Query';
  constrainedChoiceClusters: Array<{
    __typename?: 'ConstrainedChoiceCluster';
    id: string;
    registrationLimit: number;
    title: { __typename?: 'BilingualString'; EN: string; NL: string };
    offerings: Array<{
      __typename?: 'MarblesCourseOffering';
      id: string;
      info?: {
        __typename?: 'MarblesCourseInfo';
        title: { __typename?: 'BilingualString'; EN: string; NL: string };
      } | null;
    }>;
  }>;
};

export type GetCourseInfoByCatalogNumberQueryVariables = Exact<{
  catalogNumber?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetCourseInfoByCatalogNumberQuery = {
  __typename?: 'Query';
  marblesCourseInfo?: {
    __typename?: 'MarblesCourseInfo';
    id: string;
    catalogNumber: string;
    ec: number;
    title: { __typename?: 'BilingualString'; NL: string; EN: string };
  } | null;
};

export type GetCourseOfferingByIdQueryVariables = Exact<{
  courseOfferingId: Scalars['String']['input'];
}>;

export type GetCourseOfferingByIdQuery = {
  __typename?: 'Query';
  marblesCourseOffering?: {
    __typename?: 'MarblesCourseOffering';
    id: string;
    status: MarblesCourseOfferingStatus;
    numberOfRegisteredStudents: number;
    numberOfPendingStudents: number;
    groupSwapEnabled: boolean;
    groupSwapExpirationDate: any;
    hasCreditLimitException: boolean;
    departmentId: string;
    requirementNodeId?: string | null;
    info?: {
      __typename?: 'MarblesCourseInfo';
      id: string;
      catalogNumber: string;
      ec: number;
      title: { __typename?: 'BilingualString'; NL: string; EN: string };
    } | null;
    constrainedChoiceCluster?: {
      __typename?: 'ConstrainedChoiceCluster';
      title: { __typename?: 'BilingualString'; EN: string; NL: string };
    } | null;
    periods: Array<{
      __typename?: 'Period';
      id: string;
      number: number;
      semesterNumber: number;
      periodInSemester: number;
      semester: { __typename?: 'Semester'; id: string };
    }>;
    academicYear: {
      __typename?: 'MarblesAcademicYear';
      id: number;
      name: string;
      startDate: any;
      endDate: any;
      isCurrentAcademicYearForAdmins: boolean;
      isArchived: boolean;
    };
    studentGroups: Array<{
      __typename?: 'StudentGroup';
      id: string;
      code: string;
      name: string;
      initialCapacity: number;
      configuredCapacity?: number | null;
      capacity: number;
      locked: boolean;
      numberOfRegisteredStudents: number;
      directRegistrationEnabled: boolean;
      topic?: {
        __typename?: 'Topic';
        id: string;
        title: { __typename?: 'BilingualString'; EN: string; NL: string };
      } | null;
      timetableSummary: { __typename?: 'BilingualString'; NL: string; EN: string };
    }>;
    topicSet?: {
      __typename?: 'TopicSet';
      id: string;
      name: string;
      title: { __typename?: 'BilingualString'; NL: string; EN: string };
      topics: Array<{
        __typename?: 'Topic';
        id: string;
        topicSetId: string;
        title: { __typename?: 'BilingualString'; NL: string; EN: string };
      }>;
    } | null;
    department: {
      __typename?: 'MarblesDepartment';
      id: string;
      code: string;
      allowedUserActions: Array<UserAction>;
      name: { __typename?: 'BilingualString'; NL: string; EN: string };
    };
    directRegistrationSettings: {
      __typename?: 'DirectRegistrationSettings';
      registrationStartDate: any;
      registrationEndDate: any;
      directRegistrationType: DirectRegistrationType;
    };
    registrationPeriod: { __typename?: 'Period'; id: string };
  } | null;
  courseOfferingGroupSwaps: Array<{
    __typename?: 'GroupSwapRequest';
    id: string;
    studentId: string;
    status: GroupSwapStatus;
  }>;
};

export type GetCourseOfferingGroupSwapsByIdQueryVariables = Exact<{
  courseOfferingId: Scalars['String']['input'];
}>;

export type GetCourseOfferingGroupSwapsByIdQuery = {
  __typename?: 'Query';
  courseOfferingGroupSwaps: Array<{
    __typename?: 'GroupSwapRequest';
    id: string;
    studentId: string;
    requestedStudentGroupIds: Array<string>;
    processedBy?: string | null;
    updateDate: any;
    creationDate: any;
    expirationDate: any;
    status: GroupSwapStatus;
    student: { __typename?: 'Student'; id: string; name: string; studentNumber: string };
    studentGroup?: {
      __typename?: 'StudentGroup';
      id: string;
      name: string;
      code: string;
      capacity: number;
      numberOfRegisteredStudents: number;
      locked: boolean;
      groupSwapDemand: number;
    } | null;
    newStudentGroup?: { __typename?: 'StudentGroup'; id: string; name: string; code: string } | null;
  }>;
};

export type GetCourseOfferingPendingGroupSwapsByDepartmentIdQueryVariables = Exact<{
  departmentId: Scalars['String']['input'];
  academicYear: Scalars['Int']['input'];
}>;

export type GetCourseOfferingPendingGroupSwapsByDepartmentIdQuery = {
  __typename?: 'Query';
  courseOfferingPendingGroupSwaps: Array<{
    __typename?: 'CourseOfferingPendingGroupSwap';
    courseOfferingId: string;
    requestCount: number;
    requestPossible: boolean;
    requestExpirationDate: any;
    lastRequestDate: any;
    info?: {
      __typename?: 'MarblesCourseInfo';
      id: string;
      catalogNumber: string;
      title: { __typename?: 'BilingualString'; NL: string; EN: string };
    } | null;
  }>;
};

export type GetCourseOfferingRejectionsByIdQueryVariables = Exact<{
  courseOfferingId: Scalars['String']['input'];
  academicYear?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetCourseOfferingRejectionsByIdQuery = {
  __typename?: 'Query';
  courseOfferingRejections: Array<{
    __typename?: 'Rejection';
    id: string;
    username: string;
    rejectionDate: any;
    rejectionReason?: RejectionReason | null;
    student: {
      __typename?: 'Student';
      id: string;
      studentNumber: string;
      name: string;
      surname: string;
      givenName: string;
      enrolments: Array<{
        __typename?: 'Enrolment';
        id: string;
        endDate: any;
        startDate: any;
        career: {
          __typename?: 'Career';
          id: string;
          code: string;
          description: { __typename?: 'BilingualString'; NL: string; EN: string };
        };
        plan: { __typename?: 'Plan'; id: string; code: string; description: string };
        subplans: Array<{ __typename?: 'Subplan'; id: string; code: string; description: string }>;
      }>;
    };
    registrationRound?: {
      __typename?: 'RegistrationRound';
      description: { __typename?: 'BilingualString'; NL: string; EN: string };
    } | null;
    topic?: {
      __typename?: 'Topic';
      id: string;
      title: { __typename?: 'BilingualString'; NL: string; EN: string };
    } | null;
  }>;
};

export type GetCourseOfferingRequirementCapacityQueryVariables = Exact<{
  courseOfferingId: Scalars['String']['input'];
}>;

export type GetCourseOfferingRequirementCapacityQuery = {
  __typename?: 'Query';
  courseOfferingRequirementCapacity: Array<{
    __typename?: 'CourseOfferingRequirementCapacity';
    allocationRound: number;
    pending: number;
    registered: number;
    priority?: number | null;
    priorityLimit?: number | null;
    includeInRetry: boolean;
    ruleId?: string | null;
    description?: { __typename?: 'BilingualString'; NL: string; EN: string } | null;
  }>;
};

export type GetCourseOfferingTimetableActivitiesQueryVariables = Exact<{
  courseOfferingId: Scalars['String']['input'];
}>;

export type GetCourseOfferingTimetableActivitiesQuery = {
  __typename?: 'Query';
  courseOfferingTimetableActivities: Array<{
    __typename?: 'TimetableActivity';
    id: string;
    name: string;
    description: string;
    isPublishedForStudents: boolean;
    type: string;
    weeks: Array<number>;
    dayOfWeek: DayOfWeek;
    startTime: string;
    endTime: string;
    locations: Array<string>;
    studentGroups: Array<{ __typename?: 'StudentGroup'; name: string }>;
  }>;
};

export type GetCourseOfferingsQueryVariables = Exact<{
  departmentId: Scalars['String']['input'];
  academicYear: Scalars['Int']['input'];
}>;

export type GetCourseOfferingsQuery = {
  __typename?: 'Query';
  marblesCourseOfferings: Array<{
    __typename?: 'MarblesCourseOffering';
    id: string;
    status: MarblesCourseOfferingStatus;
    numberOfRegisteredStudents: number;
    numberOfPendingStudents: number;
    info?: {
      __typename?: 'MarblesCourseInfo';
      id: string;
      catalogNumber: string;
      ec: number;
      title: { __typename?: 'BilingualString'; NL: string; EN: string };
    } | null;
    topicSet?: {
      __typename?: 'TopicSet';
      id: string;
      title: { __typename?: 'BilingualString'; NL: string; EN: string };
    } | null;
    periods: Array<{
      __typename?: 'Period';
      id: string;
      number: number;
      semesterNumber: number;
      periodInSemester: number;
      semester: { __typename?: 'Semester'; id: string };
    }>;
    studentGroups: Array<{
      __typename?: 'StudentGroup';
      id: string;
      code: string;
      name: string;
      initialCapacity: number;
      configuredCapacity?: number | null;
      capacity: number;
      locked: boolean;
      numberOfRegisteredStudents: number;
      directRegistrationEnabled: boolean;
      timetableSummary: { __typename?: 'BilingualString'; NL: string; EN: string };
    }>;
  }>;
};

export type GetCourseOfferingsByCatalogNumberQueryVariables = Exact<{
  departmentId: Scalars['String']['input'];
  academicYear: Scalars['Int']['input'];
  catalogNumber: Scalars['String']['input'];
}>;

export type GetCourseOfferingsByCatalogNumberQuery = {
  __typename?: 'Query';
  marblesCourseOfferings: Array<{
    __typename?: 'MarblesCourseOffering';
    id: string;
    status: MarblesCourseOfferingStatus;
    numberOfPendingStudents: number;
    hasCreditLimitException: boolean;
    constrainedChoiceClusterId?: string | null;
    info?: {
      __typename?: 'MarblesCourseInfo';
      id: string;
      catalogNumber: string;
      ec: number;
      title: { __typename?: 'BilingualString'; NL: string; EN: string };
    } | null;
    periods: Array<{
      __typename?: 'Period';
      id: string;
      number: number;
      semesterNumber: number;
      periodInSemester: number;
      semester: { __typename?: 'Semester'; id: string };
    }>;
    registrationPeriod: {
      __typename?: 'Period';
      id: string;
      number: number;
      semesterNumber: number;
      periodInSemester: number;
      semester: { __typename?: 'Semester'; id: string };
    };
  }>;
};

export type GetCourseOfferingsExportLinkQueryVariables = Exact<{
  departmentId: Scalars['String']['input'];
  academicYear: Scalars['Int']['input'];
}>;

export type GetCourseOfferingsExportLinkQuery = {
  __typename?: 'Query';
  courseOfferingsExport: { __typename?: 'DownloadLink'; url: string };
};

export type GetCreditLimitExceptionByStudentIdQueryVariables = Exact<{
  studentId: Scalars['String']['input'];
}>;

export type GetCreditLimitExceptionByStudentIdQuery = {
  __typename?: 'Query';
  student?: { __typename?: 'Student'; hasCreditLimitException: boolean } | null;
};

export type GetDepartmentsQueryVariables = Exact<{ [key: string]: never }>;

export type GetDepartmentsQuery = {
  __typename?: 'Query';
  marblesDepartments: Array<{
    __typename?: 'MarblesDepartment';
    id: string;
    code: string;
    allowedUserActions: Array<UserAction>;
    parentId?: string | null;
    name: { __typename?: 'BilingualString'; EN: string; NL: string };
  }>;
};

export type GetDepartmentsByRequirementIdQueryVariables = Exact<{
  requirementId: Scalars['String']['input'];
}>;

export type GetDepartmentsByRequirementIdQuery = {
  __typename?: 'Query';
  requirementCopyDepartments: Array<{
    __typename?: 'MarblesDepartment';
    id: string;
    code: string;
    allowedUserActions: Array<UserAction>;
    parentId?: string | null;
    name: { __typename?: 'BilingualString'; EN: string; NL: string };
  }>;
};

export type GetFiltersQueryVariables = Exact<{ [key: string]: never }>;

export type GetFiltersQuery = {
  __typename?: 'Query';
  marblesDepartments: Array<{
    __typename?: 'MarblesDepartment';
    id: string;
    code: string;
    allowedUserActions: Array<UserAction>;
    parentId?: string | null;
    name: { __typename?: 'BilingualString'; EN: string; NL: string };
  }>;
  marblesAcademicYears: Array<{
    __typename?: 'MarblesAcademicYear';
    id: number;
    name: string;
    startDate: any;
    endDate: any;
    isCurrentAcademicYearForAdmins: boolean;
    isArchived: boolean;
  }>;
  courseOfferingsFilter: {
    __typename?: 'CourseOfferingsFilter';
    departmentId?: string | null;
    academicYear?: number | null;
  };
};

export type GetGroupsByCourseOfferingIdQueryVariables = Exact<{
  courseOfferingId: Scalars['String']['input'];
}>;

export type GetGroupsByCourseOfferingIdQuery = {
  __typename?: 'Query';
  marblesCourseOffering?: {
    __typename?: 'MarblesCourseOffering';
    id: string;
    topicSet?: { __typename?: 'TopicSet'; id: string } | null;
    studentGroups: Array<{
      __typename?: 'StudentGroup';
      id: string;
      code: string;
      name: string;
      capacity: number;
      initialCapacity: number;
      configuredCapacity?: number | null;
      numberOfRegisteredStudents: number;
      locked: boolean;
      directRegistrationEnabled: boolean;
      groupSwapDemand: number;
      topic?: {
        __typename?: 'Topic';
        id: string;
        title: { __typename?: 'BilingualString'; NL: string; EN: string };
      } | null;
      timetableSummary: { __typename?: 'BilingualString'; NL: string; EN: string };
    }>;
  } | null;
};

export type GetGroupsExportLinkQueryVariables = Exact<{
  courseOfferingId?: InputMaybe<Scalars['String']['input']>;
  departmentId?: InputMaybe<Scalars['String']['input']>;
  academicYear?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetGroupsExportLinkQuery = {
  __typename?: 'Query';
  studentGroupsExport: { __typename?: 'DownloadLink'; url: string };
};

export type GetMaintenanceMessagesQueryVariables = Exact<{ [key: string]: never }>;

export type GetMaintenanceMessagesQuery = {
  __typename?: 'Query';
  maintenanceMessages: Array<{
    __typename?: 'MaintenanceMessage';
    message: string;
    severity: MaintenanceMessageSeverity;
  }>;
};

export type GetOfferingRequirementsExportLinkQueryVariables = Exact<{
  departmentId: Scalars['String']['input'];
  academicYear: Scalars['Int']['input'];
}>;

export type GetOfferingRequirementsExportLinkQuery = {
  __typename?: 'Query';
  courseOfferingRequirementsExport: { __typename?: 'DownloadLink'; url: string };
};

export type GetPendingPursuitsByCourseOfferingIdQueryVariables = Exact<{
  courseOfferingId: Scalars['String']['input'];
  academicYear?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetPendingPursuitsByCourseOfferingIdQuery = {
  __typename?: 'Query';
  courseOfferingPursuits: Array<{
    __typename?: 'CoursePursuit';
    id: string;
    topicId?: string | null;
    student: {
      __typename?: 'Student';
      id: string;
      studentNumber: string;
      name: string;
      surname: string;
      givenName: string;
      enrolments: Array<{
        __typename?: 'Enrolment';
        id: string;
        endDate: any;
        startDate: any;
        career: {
          __typename?: 'Career';
          id: string;
          code: string;
          description: { __typename?: 'BilingualString'; NL: string; EN: string };
        };
        plan: { __typename?: 'Plan'; id: string; code: string; description: string };
        subplans: Array<{ __typename?: 'Subplan'; id: string; code: string; description: string }>;
      }>;
    };
    requirementsCheck?: {
      __typename?: 'Requirements';
      groups: Array<{
        __typename?: 'RequirementGroup';
        name?: string | null;
        applies: boolean;
        descriptions: Array<{ __typename?: 'BilingualString'; NL: string; EN: string }>;
        rows: Array<{
          __typename?: 'RequirementRow';
          status: RequirementStatus;
          title?: { __typename?: 'BilingualString'; NL: string; EN: string } | null;
          progress?: { __typename?: 'BilingualString'; NL: string; EN: string } | null;
          details: Array<{
            __typename?: 'RequirementRowDetail';
            status: RequirementStatus;
            courseInfo?: {
              __typename?: 'MarblesCourseInfo';
              id: string;
              catalogNumber: string;
              ec: number;
              title: { __typename?: 'BilingualString'; EN: string; NL: string };
            } | null;
          }>;
        }>;
      }>;
    } | null;
    clusterPreference?: {
      __typename?: 'CoursePursuitClusterPreference';
      index: number;
      pursuitCount: number;
      registrationLimit: number;
    } | null;
  }>;
};

export type GetPlanByCodeQueryVariables = Exact<{
  planCode: Scalars['String']['input'];
}>;

export type GetPlanByCodeQuery = {
  __typename?: 'Query';
  plan?: {
    __typename?: 'Plan';
    id: string;
    code: string;
    description: string;
    subplans: Array<{ __typename?: 'Subplan'; id: string; code: string; description: string }>;
  } | null;
};

export type GetProfileQueryVariables = Exact<{ [key: string]: never }>;

export type GetProfileQuery = {
  __typename?: 'Query';
  me: { __typename?: 'MarblesUser'; username: string; name: string; status: UserStatus };
};

export type GetPursuitsByStudentIdQueryVariables = Exact<{
  studentId: Scalars['String']['input'];
}>;

export type GetPursuitsByStudentIdQuery = {
  __typename?: 'Query';
  studentPursuits: Array<{
    __typename?: 'CoursePursuit';
    id: string;
    isGroupSwapPossible: boolean;
    registrationDate?: any | null;
    coursePursuitStatus: CoursePursuitStatus;
    courseInfo: {
      __typename?: 'MarblesCourseInfo';
      id: string;
      catalogNumber: string;
      ec: number;
      title: { __typename?: 'BilingualString'; EN: string; NL: string };
    };
    selectedOffering?: {
      __typename?: 'MarblesCourseOffering';
      id: string;
      constrainedChoiceClusterId?: string | null;
      academicYear: { __typename?: 'MarblesAcademicYear'; id: number; name: string; isArchived: boolean };
      studentGroups: Array<{ __typename?: 'StudentGroup'; id: string; name: string }>;
      department: { __typename?: 'MarblesDepartment'; allowedUserActions: Array<UserAction> };
      periods: Array<{
        __typename?: 'Period';
        id: string;
        number: number;
        semesterNumber: number;
        periodInSemester: number;
        semester: { __typename?: 'Semester'; id: string };
      }>;
    } | null;
    studentGroup?: { __typename?: 'StudentGroup'; id: string; code: string; name: string } | null;
    clusterPreference?: {
      __typename?: 'CoursePursuitClusterPreference';
      index: number;
      pursuitCount: number;
      registrationLimit: number;
    } | null;
  }>;
  studentGroupSwaps: Array<{
    __typename?: 'GroupSwapRequest';
    id: string;
    coursePursuitId: string;
    status: GroupSwapStatus;
  }>;
};

export type GetRegisteredPursuitsByCourseOfferingIdQueryVariables = Exact<{
  courseOfferingId: Scalars['String']['input'];
  academicYear?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetRegisteredPursuitsByCourseOfferingIdQuery = {
  __typename?: 'Query';
  courseOfferingPursuits: Array<{
    __typename?: 'CoursePursuit';
    id: string;
    registrationDate?: any | null;
    student: {
      __typename?: 'Student';
      id: string;
      studentNumber: string;
      name: string;
      surname: string;
      givenName: string;
      enrolments: Array<{
        __typename?: 'Enrolment';
        id: string;
        endDate: any;
        startDate: any;
        career: {
          __typename?: 'Career';
          id: string;
          code: string;
          description: { __typename?: 'BilingualString'; NL: string; EN: string };
        };
        plan: { __typename?: 'Plan'; id: string; code: string; description: string };
        subplans: Array<{ __typename?: 'Subplan'; id: string; code: string; description: string }>;
      }>;
    };
    studentGroup?: { __typename?: 'StudentGroup'; id: string; code: string; name: string } | null;
    requirementsCheck?: {
      __typename?: 'Requirements';
      groups: Array<{
        __typename?: 'RequirementGroup';
        name?: string | null;
        applies: boolean;
        descriptions: Array<{ __typename?: 'BilingualString'; NL: string; EN: string }>;
        rows: Array<{
          __typename?: 'RequirementRow';
          status: RequirementStatus;
          title?: { __typename?: 'BilingualString'; NL: string; EN: string } | null;
          progress?: { __typename?: 'BilingualString'; NL: string; EN: string } | null;
          details: Array<{
            __typename?: 'RequirementRowDetail';
            status: RequirementStatus;
            courseInfo?: {
              __typename?: 'MarblesCourseInfo';
              id: string;
              catalogNumber: string;
              ec: number;
              title: { __typename?: 'BilingualString'; EN: string; NL: string };
            } | null;
          }>;
        }>;
      }>;
    } | null;
  }>;
};

export type GetRegistrationRoundsQueryVariables = Exact<{
  academicYear: Scalars['Int']['input'];
}>;

export type GetRegistrationRoundsQuery = {
  __typename?: 'Query';
  registrationRounds: Array<{
    __typename?: 'RegistrationRound';
    id: string;
    registrationStartDate: any;
    registrationEndDate: any;
    registrationResultDate: any;
    description: { __typename?: 'BilingualString'; NL: string; EN: string };
    academicYear: {
      __typename?: 'MarblesAcademicYear';
      id: number;
      name: string;
      startDate: any;
      endDate: any;
      isCurrentAcademicYearForAdmins: boolean;
      isArchived: boolean;
    };
    periods: Array<{
      __typename?: 'Period';
      id: string;
      number: number;
      semesterNumber: number;
      periodInSemester: number;
      semester: { __typename?: 'Semester'; id: string };
    }>;
  }>;
};

export type GetRegistrationsExportLinkQueryVariables = Exact<{
  courseOfferingId?: InputMaybe<Scalars['String']['input']>;
  departmentId?: InputMaybe<Scalars['String']['input']>;
  status: PendingOrRegistered;
  academicYear?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetRegistrationsExportLinkQuery = {
  __typename?: 'Query';
  courseRegistrationsExport: { __typename?: 'DownloadLink'; url: string };
};

export type GetRequirementByIdQueryVariables = Exact<{
  requirementId: Scalars['String']['input'];
}>;

export type GetRequirementByIdQuery = {
  __typename?: 'Query';
  requirement?: {
    __typename?: 'Requirement';
    id: string;
    name: string;
    courseOfferings: Array<{
      __typename?: 'MarblesCourseOffering';
      id: string;
      info?: {
        __typename?: 'MarblesCourseInfo';
        id: string;
        catalogNumber: string;
        ec: number;
        title: { __typename?: 'BilingualString'; NL: string; EN: string };
      } | null;
      periods: Array<{
        __typename?: 'Period';
        id: string;
        number: number;
        semesterNumber: number;
        periodInSemester: number;
        semester: { __typename?: 'Semester'; id: string };
      }>;
    }>;
    topics: Array<{
      __typename?: 'Topic';
      id: string;
      topicSetId: string;
      title: { __typename?: 'BilingualString'; NL: string; EN: string };
      topicSet: { __typename?: 'TopicSet'; name: string };
    }>;
    department: {
      __typename?: 'MarblesDepartment';
      id: string;
      allowedUserActions: Array<UserAction>;
      name: { __typename?: 'BilingualString'; NL: string; EN: string };
    };
    academicYear: { __typename?: 'MarblesAcademicYear'; id: number; name: string; isArchived: boolean };
    rulesInRequirement: Array<{
      __typename?: 'RuleInRequirement';
      id: string;
      allocationRound: number;
      priority?: number | null;
      priorityLimit?: number | null;
      includeInRetry: boolean;
      rule: {
        __typename?: 'Rule';
        id: string;
        name: string;
        ruleKind: RuleKind;
        requirements: Array<{
          __typename?: 'RuleRequirementSummary';
          id: string;
          name: string;
          department: {
            __typename?: 'MarblesDepartment';
            id: string;
            parentId?: string | null;
            name: { __typename?: 'BilingualString'; EN: string; NL: string };
          };
        }>;
        department: {
          __typename?: 'MarblesDepartment';
          id: string;
          allowedUserActions: Array<UserAction>;
          name: { __typename?: 'BilingualString'; EN: string; NL: string };
        };
        nodes: {
          __typename?: 'RuleNodes';
          requirements: Array<
            | {
                __typename?: 'InfoRequirement';
                id: string;
                description: { __typename?: 'BilingualString'; NL: string; EN: string };
              }
            | {
                __typename?: 'RequiredCourses';
                id: string;
                requirement: RequiredCoursesRequirement;
                requiredCourses?: number | null;
                requiredCredits?: number | null;
                description: { __typename?: 'BilingualString'; NL: string; EN: string };
                courses: Array<{
                  __typename?: 'MarblesCourseInfo';
                  id: string;
                  catalogNumber: string;
                  ec: number;
                  title: { __typename?: 'BilingualString'; NL: string; EN: string };
                }>;
              }
          >;
          groupRequirements: Array<
            | {
                __typename?: 'RequiredCareer';
                id: string;
                careers: Array<{
                  __typename?: 'Career';
                  id: string;
                  code: string;
                  description: { __typename?: 'BilingualString'; NL: string; EN: string };
                }>;
                description: { __typename?: 'BilingualString'; NL: string; EN: string };
              }
            | {
                __typename?: 'RequiredPlan';
                id: string;
                plan: {
                  __typename?: 'Plan';
                  id: string;
                  code: string;
                  description: string;
                  subplans: Array<{ __typename?: 'Subplan'; id: string; code: string; description: string }>;
                };
                subplans?: Array<{ __typename?: 'Subplan'; id: string; code: string; description: string }> | null;
                description: { __typename?: 'BilingualString'; NL: string; EN: string };
              }
            | {
                __typename?: 'RequiredStudentSet';
                id: string;
                studentSet?: {
                  __typename?: 'StudentSet';
                  id: string;
                  code: string;
                  title: { __typename?: 'BilingualString'; EN: string; NL: string };
                } | null;
                description: { __typename?: 'BilingualString'; NL: string; EN: string };
              }
          >;
        };
      };
    }>;
  } | null;
};

export type GetRequirementsByDepartmentIdQueryVariables = Exact<{
  academicYear: Scalars['Int']['input'];
  departmentId: Scalars['String']['input'];
  includeParentDepartments: Scalars['Boolean']['input'];
}>;

export type GetRequirementsByDepartmentIdQuery = {
  __typename?: 'Query';
  requirements: Array<{
    __typename?: 'Requirement';
    id: string;
    name: string;
    rulesInRequirement: Array<{
      __typename?: 'RuleInRequirement';
      id: string;
      rule: { __typename?: 'Rule'; id: string };
    }>;
    department: {
      __typename?: 'MarblesDepartment';
      id: string;
      allowedUserActions: Array<UserAction>;
      name: { __typename?: 'BilingualString'; NL: string; EN: string };
    };
    topics: Array<{ __typename?: 'Topic'; id: string }>;
    courseOfferings: Array<{ __typename?: 'MarblesCourseOffering'; id: string }>;
  }>;
};

export type GetRuleByIdQueryVariables = Exact<{
  ruleId: Scalars['String']['input'];
}>;

export type GetRuleByIdQuery = {
  __typename?: 'Query';
  rule?: {
    __typename?: 'Rule';
    id: string;
    name: string;
    ruleKind: RuleKind;
    academicYear: { __typename?: 'MarblesAcademicYear'; id: number; name: string; isArchived: boolean };
    requirements: Array<{
      __typename?: 'RuleRequirementSummary';
      id: string;
      name: string;
      department: {
        __typename?: 'MarblesDepartment';
        id: string;
        parentId?: string | null;
        name: { __typename?: 'BilingualString'; EN: string; NL: string };
      };
    }>;
    department: {
      __typename?: 'MarblesDepartment';
      id: string;
      allowedUserActions: Array<UserAction>;
      name: { __typename?: 'BilingualString'; EN: string; NL: string };
    };
    nodes: {
      __typename?: 'RuleNodes';
      requirements: Array<
        | {
            __typename?: 'InfoRequirement';
            id: string;
            description: { __typename?: 'BilingualString'; NL: string; EN: string };
          }
        | {
            __typename?: 'RequiredCourses';
            id: string;
            requirement: RequiredCoursesRequirement;
            requiredCourses?: number | null;
            requiredCredits?: number | null;
            description: { __typename?: 'BilingualString'; NL: string; EN: string };
            courses: Array<{
              __typename?: 'MarblesCourseInfo';
              id: string;
              catalogNumber: string;
              ec: number;
              title: { __typename?: 'BilingualString'; NL: string; EN: string };
            }>;
          }
      >;
      groupRequirements: Array<
        | {
            __typename?: 'RequiredCareer';
            id: string;
            careers: Array<{
              __typename?: 'Career';
              id: string;
              code: string;
              description: { __typename?: 'BilingualString'; NL: string; EN: string };
            }>;
            description: { __typename?: 'BilingualString'; NL: string; EN: string };
          }
        | {
            __typename?: 'RequiredPlan';
            id: string;
            plan: {
              __typename?: 'Plan';
              id: string;
              code: string;
              description: string;
              subplans: Array<{ __typename?: 'Subplan'; id: string; code: string; description: string }>;
            };
            subplans?: Array<{ __typename?: 'Subplan'; id: string; code: string; description: string }> | null;
            description: { __typename?: 'BilingualString'; NL: string; EN: string };
          }
        | {
            __typename?: 'RequiredStudentSet';
            id: string;
            studentSet?: {
              __typename?: 'StudentSet';
              id: string;
              code: string;
              title: { __typename?: 'BilingualString'; EN: string; NL: string };
            } | null;
            description: { __typename?: 'BilingualString'; NL: string; EN: string };
          }
      >;
    };
  } | null;
};

export type GetRulesByDepartmentIdQueryVariables = Exact<{
  academicYear: Scalars['Int']['input'];
  departmentId: Scalars['String']['input'];
  includeParentDepartments?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type GetRulesByDepartmentIdQuery = {
  __typename?: 'Query';
  rules: Array<{
    __typename?: 'Rule';
    id: string;
    ruleKind: RuleKind;
    name: string;
    requirements: Array<{ __typename?: 'RuleRequirementSummary'; id: string }>;
    department: {
      __typename?: 'MarblesDepartment';
      id: string;
      code: string;
      allowedUserActions: Array<UserAction>;
      name: { __typename?: 'BilingualString'; EN: string; NL: string };
    };
  }>;
};

export type GetStatusByCourseOfferingIdQueryVariables = Exact<{
  courseOfferingId: Scalars['String']['input'];
}>;

export type GetStatusByCourseOfferingIdQuery = {
  __typename?: 'Query';
  marblesCourseOffering?: {
    __typename?: 'MarblesCourseOffering';
    status: MarblesCourseOfferingStatus;
    directRegistrationSettings: {
      __typename?: 'DirectRegistrationSettings';
      registrationStartDate: any;
      registrationEndDate: any;
      directRegistrationType: DirectRegistrationType;
    };
  } | null;
};

export type GetStudentByStudentIdQueryVariables = Exact<{
  studentId: Scalars['String']['input'];
}>;

export type GetStudentByStudentIdQuery = {
  __typename?: 'Query';
  student?: {
    __typename?: 'Student';
    hasCreditLimitException: boolean;
    id: string;
    studentNumber: string;
    name: string;
    surname: string;
    givenName: string;
    enrolments: Array<{
      __typename?: 'Enrolment';
      id: string;
      endDate: any;
      startDate: any;
      career: {
        __typename?: 'Career';
        id: string;
        code: string;
        description: { __typename?: 'BilingualString'; NL: string; EN: string };
      };
      plan: { __typename?: 'Plan'; id: string; code: string; description: string };
      subplans: Array<{ __typename?: 'Subplan'; id: string; code: string; description: string }>;
    }>;
  } | null;
};

export type GetStudentByStudentNumberQueryVariables = Exact<{
  studentNumber: Scalars['String']['input'];
}>;

export type GetStudentByStudentNumberQuery = {
  __typename?: 'Query';
  student?: {
    __typename?: 'Student';
    hasCreditLimitException: boolean;
    id: string;
    studentNumber: string;
    name: string;
    surname: string;
    givenName: string;
    enrolments: Array<{
      __typename?: 'Enrolment';
      id: string;
      endDate: any;
      startDate: any;
      career: {
        __typename?: 'Career';
        id: string;
        code: string;
        description: { __typename?: 'BilingualString'; NL: string; EN: string };
      };
      plan: { __typename?: 'Plan'; id: string; code: string; description: string };
      subplans: Array<{ __typename?: 'Subplan'; id: string; code: string; description: string }>;
    }>;
  } | null;
};

export type GetStudentPursuitAuditByStudentIdQueryVariables = Exact<{
  studentId: Scalars['String']['input'];
}>;

export type GetStudentPursuitAuditByStudentIdQuery = {
  __typename?: 'Query';
  studentPursuitAudit: Array<{
    __typename?: 'CoursePursuitAudit';
    timeStamp: any;
    action: CoursePursuitAction;
    username: string;
    courseOfferingId?: string | null;
    catalogNumber?: string | null;
    deregistrationReason?: DeregistrationReason | null;
    academicYear?: { __typename?: 'MarblesAcademicYear'; id: number; name: string } | null;
    course?: { __typename?: 'BilingualString'; NL: string; EN: string } | null;
    new?: { __typename?: 'BilingualString'; NL: string; EN: string } | null;
    old?: { __typename?: 'BilingualString'; NL: string; EN: string } | null;
    registrationRound?: {
      __typename?: 'RegistrationRound';
      id: string;
      description: { __typename?: 'BilingualString'; NL: string; EN: string };
    } | null;
  }>;
};

export type GetStudentRequirementCheckByCourseOfferingIdQueryVariables = Exact<{
  courseOfferingId: Scalars['String']['input'];
  studentId?: InputMaybe<Scalars['String']['input']>;
  studentNumber?: InputMaybe<Scalars['String']['input']>;
  academicYear?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetStudentRequirementCheckByCourseOfferingIdQuery = {
  __typename?: 'Query';
  studentRequirementsCheck?: {
    __typename?: 'StudentRequirementsCheckPayload';
    requirementsCheck: {
      __typename?: 'Requirements';
      groups: Array<{
        __typename?: 'RequirementGroup';
        name?: string | null;
        applies: boolean;
        descriptions: Array<{ __typename?: 'BilingualString'; NL: string; EN: string }>;
        rows: Array<{
          __typename?: 'RequirementRow';
          status: RequirementStatus;
          title?: { __typename?: 'BilingualString'; NL: string; EN: string } | null;
          progress?: { __typename?: 'BilingualString'; NL: string; EN: string } | null;
          details: Array<{
            __typename?: 'RequirementRowDetail';
            status: RequirementStatus;
            courseInfo?: {
              __typename?: 'MarblesCourseInfo';
              id: string;
              catalogNumber: string;
              ec: number;
              title: { __typename?: 'BilingualString'; EN: string; NL: string };
            } | null;
          }>;
        }>;
      }>;
    };
    student: {
      __typename?: 'Student';
      id: string;
      studentNumber: string;
      name: string;
      surname: string;
      givenName: string;
      enrolments: Array<{
        __typename?: 'Enrolment';
        id: string;
        endDate: any;
        startDate: any;
        career: {
          __typename?: 'Career';
          id: string;
          code: string;
          description: { __typename?: 'BilingualString'; NL: string; EN: string };
        };
        plan: { __typename?: 'Plan'; id: string; code: string; description: string };
        subplans: Array<{ __typename?: 'Subplan'; id: string; code: string; description: string }>;
      }>;
    };
  } | null;
};

export type GetStudentSetByCodeQueryVariables = Exact<{
  code: Scalars['String']['input'];
}>;

export type GetStudentSetByCodeQuery = {
  __typename?: 'Query';
  studentSet?: {
    __typename?: 'StudentSet';
    id: string;
    code: string;
    title: { __typename?: 'BilingualString'; EN: string; NL: string };
  } | null;
};

export type GetTopicCapacityByCourseOfferingIdQueryVariables = Exact<{
  courseOfferingId: Scalars['String']['input'];
}>;

export type GetTopicCapacityByCourseOfferingIdQuery = {
  __typename?: 'Query';
  courseOfferingTopicsCapacity: Array<{
    __typename?: 'CourseOfferingTopicCapacity';
    groupCount: number;
    availableCapacity: number;
    pending: number;
    registered: number;
    topic: { __typename?: 'Topic'; id: string; title: { __typename?: 'BilingualString'; EN: string; NL: string } };
  }>;
};

export type GetTopicSetByIdQueryVariables = Exact<{
  topicSetId: Scalars['String']['input'];
}>;

export type GetTopicSetByIdQuery = {
  __typename?: 'Query';
  topicSet?: {
    __typename?: 'TopicSet';
    departmentId: string;
    id: string;
    name: string;
    department: {
      __typename?: 'MarblesDepartment';
      id: string;
      allowedUserActions: Array<UserAction>;
      name: { __typename?: 'BilingualString'; EN: string; NL: string };
    };
    academicYear: { __typename?: 'MarblesAcademicYear'; id: number; name: string; isArchived: boolean };
    courseOfferings: Array<{
      __typename?: 'MarblesCourseOffering';
      id: string;
      info?: {
        __typename?: 'MarblesCourseInfo';
        id: string;
        catalogNumber: string;
        ec: number;
        title: { __typename?: 'BilingualString'; NL: string; EN: string };
      } | null;
      periods: Array<{
        __typename?: 'Period';
        id: string;
        number: number;
        semesterNumber: number;
        periodInSemester: number;
        semester: { __typename?: 'Semester'; id: string };
      }>;
    }>;
    title: { __typename?: 'BilingualString'; NL: string; EN: string };
    topics: Array<{
      __typename?: 'Topic';
      id: string;
      topicSetId: string;
      title: { __typename?: 'BilingualString'; NL: string; EN: string };
    }>;
  } | null;
};

export type GetTopicSetsByDepartmentIdQueryVariables = Exact<{
  departmentId: Scalars['String']['input'];
  academicYear: Scalars['Int']['input'];
}>;

export type GetTopicSetsByDepartmentIdQuery = {
  __typename?: 'Query';
  topicSets: Array<{
    __typename?: 'TopicSet';
    id: string;
    name: string;
    courseOfferings: Array<{ __typename?: 'MarblesCourseOffering'; id: string }>;
    department: {
      __typename?: 'MarblesDepartment';
      id: string;
      name: { __typename?: 'BilingualString'; NL: string; EN: string };
    };
    title: { __typename?: 'BilingualString'; NL: string; EN: string };
    topics: Array<{
      __typename?: 'Topic';
      id: string;
      topicSetId: string;
      title: { __typename?: 'BilingualString'; NL: string; EN: string };
    }>;
  }>;
};

export type GetTopicsByCourseOfferingIdQueryVariables = Exact<{
  courseOfferingId: Scalars['String']['input'];
}>;

export type GetTopicsByCourseOfferingIdQuery = {
  __typename?: 'Query';
  marblesCourseOffering?: {
    __typename?: 'MarblesCourseOffering';
    id: string;
    topicSet?: {
      __typename?: 'TopicSet';
      id: string;
      name: string;
      title: { __typename?: 'BilingualString'; NL: string; EN: string };
      topics: Array<{
        __typename?: 'Topic';
        id: string;
        topicSetId: string;
        title: { __typename?: 'BilingualString'; NL: string; EN: string };
      }>;
    } | null;
  } | null;
};

export type GetTopicsByTopicSetIdQueryVariables = Exact<{
  topicSetId: Scalars['String']['input'];
}>;

export type GetTopicsByTopicSetIdQuery = {
  __typename?: 'Query';
  topicSet?: {
    __typename?: 'TopicSet';
    id: string;
    topics: Array<{
      __typename?: 'Topic';
      id: string;
      title: { __typename?: 'BilingualString'; NL: string; EN: string };
      requirement?: { __typename?: 'Requirement'; id: string; name: string } | null;
    }>;
  } | null;
};

export type GetUserAuthorisationsQueryVariables = Exact<{
  username: Scalars['String']['input'];
}>;

export type GetUserAuthorisationsQuery = {
  __typename?: 'Query';
  marblesUser: {
    __typename?: 'MarblesUser';
    username: string;
    familyName: string;
    givenName: string;
    status: UserStatus;
    roleAssignments: Array<{
      __typename?: 'RoleAssignment';
      id: number;
      createdBy: string;
      creationDate: any;
      role: string;
      userActions: Array<UserAction>;
      department: {
        __typename?: 'MarblesDepartment';
        id: string;
        name: { __typename?: 'BilingualString'; NL: string; EN: string };
      };
      userRole: {
        __typename?: 'UserRole';
        name: string;
        description: { __typename?: 'BilingualString'; NL: string; EN: string };
      };
    }>;
  };
};

export type GetUsersQueryVariables = Exact<{ [key: string]: never }>;

export type GetUsersQuery = {
  __typename?: 'Query';
  marblesUsers: Array<{
    __typename?: 'MarblesUser';
    name: string;
    familyName: string;
    givenName: string;
    username: string;
    status: UserStatus;
    roleAssignments: Array<{
      __typename?: 'RoleAssignment';
      id: number;
      role: string;
      department: { __typename?: 'MarblesDepartment'; id: string; code: string };
    }>;
  }>;
};
