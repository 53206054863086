import { useMutation } from '@apollo/client';

import type { StoreObject } from '@apollo/client';
import type {
  GetPendingPursuitsByCourseOfferingIdQuery,
  GetPendingPursuitsByCourseOfferingIdQueryVariables,
  UploadCoursePendingStudentsMutation,
  UploadCoursePendingStudentsMutationVariables,
} from 'types/__generated__';

import { MutationStatus } from 'types/__generated__';
import { UPLOAD_COURSE_PENDING_STUDENTS } from 'graphql/mutations/uploadCoursePendingStudents';
import { GET_PENDING_PURSUITS_BY_COURSE_OFFERING_ID } from 'graphql/queries/getPendingPursuitsByCourseOfferingId';

export const useUploadPendingCourseOfferings = (academicYear: number) =>
  useMutation<UploadCoursePendingStudentsMutation, UploadCoursePendingStudentsMutationVariables>(
    UPLOAD_COURSE_PENDING_STUDENTS,
    {
      update(cache, { data }) {
        if (data?.uploadCoursePendingStudents.mutationStatus !== MutationStatus.Success) return;

        const { courseOfferingId, pursuits } = data.uploadCoursePendingStudents;
        const pursuitIds = pursuits.map(({ id }) => id);

        const updatedPending = cache.updateQuery<
          GetPendingPursuitsByCourseOfferingIdQuery,
          GetPendingPursuitsByCourseOfferingIdQueryVariables
        >(
          { query: GET_PENDING_PURSUITS_BY_COURSE_OFFERING_ID, variables: { courseOfferingId, academicYear } },
          (cached) => ({
            courseOfferingPursuits: cached
              ? [...cached.courseOfferingPursuits.filter(({ id }) => !pursuitIds.includes(id)), ...pursuits]
              : pursuits,
          })
        );

        const courseOffering: StoreObject = { __typename: 'MarblesCourseOffering', id: courseOfferingId };

        cache.modify({
          id: cache.identify(courseOffering),
          fields: {
            numberOfPendingStudents(currentValue) {
              return updatedPending?.courseOfferingPursuits.length ?? currentValue;
            },
          },
        });
      },
    }
  );
