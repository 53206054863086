import { gql } from '@apollo/client';

import { RULE_FRAGMENT } from 'graphql/fragments/ruleFragment';

export const UPDATE_REQUIRED_STUDENT_SET = gql`
  mutation updateRequiredStudentSet($ruleId: String!, $nodeId: String!, $studentSetId: String!) {
    updateRequiredStudentSet(input: { ruleId: $ruleId, nodeId: $nodeId, studentSetId: $studentSetId }) {
      rule {
        ...rule
      }
      mutationStatus
    }
  }
  ${RULE_FRAGMENT}
`;
