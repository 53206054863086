import { gql } from '@apollo/client';

export const INFO_REQUIREMENT_FRAGMENT = gql`
  fragment infoRequirement on InfoRequirement {
    id
    description {
      NL
      EN
    }
  }
`;
