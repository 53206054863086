import { useTranslation } from 'react-i18next';

import type { BilingualStringIndex } from 'types/BilingualStringIndex';

import { useCurrentLanguage } from 'hooks/useCurrentLanguage';
import { Listbox } from 'components/Listbox/Listbox';

const options = [
  { label: 'Nederlands', value: 'NL' },
  { label: 'English', value: 'EN' },
];

export const LanguageToggle = () => {
  const { t, i18n } = useTranslation('common', { keyPrefix: 'language-toggle' });
  const currentLanguage = useCurrentLanguage();

  function onClick(event: { value: BilingualStringIndex }) {
    i18n.changeLanguage(event.value);
  }

  return (
    <Listbox
      label={t('set-language-to')}
      defaultOptionValue={currentLanguage}
      options={options}
      onSelect={(event) => onClick(event as { value: BilingualStringIndex })}
      triggerLabelValue
      noBorder
      optionPositionRight
      customLabel={true}
    />
  );
};
