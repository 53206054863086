import { gql } from '@apollo/client';

import { ACADEMIC_YEAR_FRAGMENT } from 'graphql/fragments/academicYearFragment';
import { PERIOD_FRAGMENT } from 'graphql/fragments/periodFragment';

export const GET_REGISTRATION_ROUNDS = gql`
  query getRegistrationRounds($academicYear: Int!) {
    registrationRounds(academicYear: $academicYear) {
      id
      description {
        NL
        EN
      }
      academicYear {
        ...academicYear
      }
      registrationStartDate
      registrationEndDate
      registrationResultDate
      periods {
        ...period
      }
    }
  }
  ${ACADEMIC_YEAR_FRAGMENT}
  ${PERIOD_FRAGMENT}
`;
