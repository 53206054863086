import { createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Spinner } from '@uva-glass/component-library';

import type { GetRegistrationRoundsQuery } from 'types/__generated__';

import { DataTable, periodsFilterFn } from 'App/shared/DataTable/DataTable';
import { DateTime } from 'App/shared/DateTime/DateTime';
import { PageMultiSubTitle } from 'App/shared/PageMultiSubTitle/PageMultiSubTitle';
import { Page } from 'components/Page/Page';
import { useCurrentLanguage } from 'hooks/useCurrentLanguage';
import { useGetAcademicYearDetails } from 'hooks/useGetAcademicYearDetails';
import { useGetRegistrationRounds } from 'hooks/useGetRegistrationRounds';
import { TABLE_STORAGE_KEYS } from 'util/storagekeys';

type RowData = GetRegistrationRoundsQuery['registrationRounds'][number];

const columnHelper = createColumnHelper<RowData>();

const initialState = { sorting: [{ id: 'id', desc: false }] };

export function RegistrationRounds() {
  const { t } = useTranslation('registration-rounds', { keyPrefix: 'registration-rounds-page' });
  const currentLanguage = useCurrentLanguage();
  const { academicYear } = useParams();
  const selectedAcademicYear = useGetAcademicYearDetails(Number(academicYear));
  const query = useGetRegistrationRounds(Number(academicYear));

  const columns = [
    columnHelper.accessor('id', {
      header: t('headers.id'),
    }),

    columnHelper.accessor(({ description }) => description[currentLanguage], {
      id: 'description',
      header: t('headers.description'),
    }),

    columnHelper.accessor(
      ({ periods }) =>
        periods.map(({ semesterNumber, periodInSemester }) => `${semesterNumber}.${periodInSemester}`).join(', '),
      {
        id: 'periods',
        header: t('headers.periods'),
        filterFn: (row, _, filterValue) => periodsFilterFn(row.original.periods, filterValue),
      }
    ),

    columnHelper.accessor('registrationStartDate', {
      header: t('headers.registration-start-date'),
      cell: ({ cell }) => <DateTime isoDate={cell.getValue()} />,
      enableColumnFilter: false,
      sortingFn: 'datetime',
    }),

    columnHelper.accessor('registrationEndDate', {
      header: t('headers.registration-end-date'),
      cell: ({ cell }) => <DateTime isoDate={cell.getValue()} />,
      enableColumnFilter: false,
      sortingFn: 'datetime',
    }),

    columnHelper.accessor('registrationResultDate', {
      header: t('headers.registration-result-date'),
      cell: ({ cell }) => <DateTime isoDate={cell.getValue()} />,
      enableColumnFilter: false,
      sortingFn: 'datetime',
    }),
  ];

  return (
    <Page title={t('title')} subTitle={<PageMultiSubTitle values={[selectedAcademicYear.name]} />}>
      {query.loading && <Spinner ariaValueText={''} />}
      {query.error && <>Error...</>}
      {query.data && (
        <DataTable
          data={query.data.registrationRounds}
          columns={columns}
          initialState={initialState}
          tableId={TABLE_STORAGE_KEYS.REGISTRATION_ROUNDS}
        />
      )}
    </Page>
  );
}
