import { Card, Spinner } from '@uva-glass/component-library';
import { useTranslation } from 'react-i18next';

import type {
  GetCourseOfferingByIdQuery,
  GetStudentRequirementCheckByCourseOfferingIdQuery,
} from 'types/__generated__';

import styles from './FetchedStudentRequirementStatus.module.css';

import { Requirements } from 'App/CourseOffering/FetchedStudentRequirementStatus/Requirements/Requirements';
import { ProgrammeList } from 'App/shared/ProgrammeList/ProgrammeList';

type StudentRequirementCheck = NonNullable<
  GetStudentRequirementCheckByCourseOfferingIdQuery['studentRequirementsCheck']
>;

interface Props {
  studentRequirementCheck: StudentRequirementCheck;
  isStudentRequirementCheckLoading?: boolean;
  courseInfo?: NonNullable<GetCourseOfferingByIdQuery['marblesCourseOffering']>['info'];
}

export function FetchedStudentRequirementStatus({
  studentRequirementCheck,
  isStudentRequirementCheckLoading,
  courseInfo,
}: Props) {
  const { t } = useTranslation('course-offering', { keyPrefix: 'fetched-student-requirement-status' });
  const { requirementsCheck, student } = studentRequirementCheck;

  return (
    <>
      {isStudentRequirementCheckLoading && <Spinner ariaValueText={t('loading')} />}
      {student && (
        <div className={styles['fetched-student-requirement-status__student-card']}>
          <Card
            padding="small"
            header={
              <div className={styles['fetched-student-requirement-status__student-card-header']}>
                <strong>{student.name}</strong>
                <ProgrammeList asInlineList asSpacedList includeSubplans includeDates enrolments={student.enrolments} />
              </div>
            }
          >
            <Requirements requirementsCheck={requirementsCheck} courseInfo={courseInfo}></Requirements>
          </Card>
        </div>
      )}
    </>
  );
}
