import { useTranslation } from 'react-i18next';

import { Page } from 'components/Page/Page';
import { ActionHeader } from 'App/shared/ActionHeader/ActionHeader';

export function Unauthorized() {
  const { t } = useTranslation('unauthorized', { keyPrefix: 'unauthorized-page' });

  return (
    <Page title={t('title')} header={<ActionHeader />}>
      <p>{t('body')}</p>
    </Page>
  );
}
