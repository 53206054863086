export const STORAGE_KEYS = {
  ACADEMIC_YEAR: 'ACADEMIC_YEAR',
  DEPARTMENT_ID: 'DEPARTMENT_ID',
};

export const TABLE_STORAGE_KEYS = {
  ARCHIVED_STUDENT_PURSUITS_TABLE: 'ARCHIVED_STUDENT_PURSUITS_TABLE',
  CANCELLED_COURSE_OFFERING_GROUP_SWAP_TABLE: 'CANCELLED_COURSE_OFFERING_GROUP_SWAP_TABLE',
  CONSTRAINED_CHOICES_TABLE: 'CONSTRAINED_CHOICES_TABLE',
  COURSE_OFFERING_GROUPS: 'COURSE_OFFERING_GROUPS',
  COURSE_OFFERING_PENDING_STUDENTS: 'COURSE_OFFERING_PENDING_STUDENTS',
  COURSE_OFFERING_REGISTERED_STUDENTS: 'COURSE_OFFERING_REGISTERED_STUDENTS',
  COURSE_OFFERING_REJECTIONS: 'COURSE_OFFERING_REJECTIONS',
  COURSE_OFFERING_TIMETABLE: 'COURSE_OFFERING_TIMETABLE',
  COURSE_OFFERING_TOPICS: 'COURSE_OFFERING_TOPICS',
  COURSES_TABLE: 'COURSES_TABLE',
  DEPARTMENT_COURSE_OFFERINGS: 'DEPARTMENT_COURSE_OFFERINGS',
  ENTRY_REQUIREMENT_RULES: 'ENTRY_REQUIREMENT_RULES',
  ENTRY_REQUIREMENT: 'ENTRY_REQUIREMENT',
  ENTRY_REQUIREMENTS_TABLE: 'ENTRY_REQUIREMENTS_TABLE',
  EXPIRED_COURSE_OFFERING_GROUP_SWAP_TABLE: 'EXPIRED_COURSE_OFFERING_GROUP_SWAP_TABLE',
  GROUP_SWAP_TABLE: 'GROUP_SWAP_TABLE',
  PENDING_COURSE_OFFERING_GROUP_SWAP_TABLE: 'PENDING_COURSE_OFFERING_GROUP_SWAP_TABLE',
  PENDING_STUDENT_PURSUITS_TABLE: 'PENDING_STUDENT_PURSUITS_TABLE',
  PROCESSED_COURSE_OFFERING_GROUP_SWAP_TABLE: 'PROCESSED_COURSE_OFFERING_GROUP_SWAP_TABLE',
  REGISTERED_STUDENT_PURSUITS_TABLE: 'REGISTERED_STUDENT_PURSUITS_TABLE',
  REGISTRATION_ROUNDS: 'REGISTRATION_ROUNDS',
  RULES_TABLE: 'RULES_TABLE',
  STUDENT_HISTORY_LOG: 'STUDENT_HISTORY_LOG',
  STUDENT_PROGRAMMES: 'STUDENT_PROGRAMMES',
  STUDENTS: 'STUDENTS',
  TOPIC_SETS_TABLE: 'TOPIC_SETS_TABLE',
  TOPICS_TABLE: 'TOPICS_TABLE',
  USER_AUTHORISATION_TABLE: 'USER_AUTHORISATION_TABLE',
  USERS_TABLE: 'USERS_TABLE',
};
