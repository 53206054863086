import { clsx } from 'clsx';
import { Icon } from '@uva-glass/component-library';

import type { IconProps } from '@uva-glass/component-library';

import styles from './StatusIndicator.module.css';

export interface StatusIndicatorProps {
  status: 'fail' | 'pending' | 'active' | 'ended' | 'completed';
  title: string;
  label: string;
  name?: IconProps['name'];
}

export const StatusIndicator = ({ status, title, label, name = 'Circle' }: StatusIndicatorProps) => (
  <span className={styles['status-indicator']}>
    <Icon
      className={clsx(styles['status-indicator'], styles[`status-indicator--${status}`])}
      name={name}
      title={title}
      size={16}
    />
    {label}
  </span>
);
