import { useLazyQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import type {
  GetCourseOfferingsExportLinkQuery,
  GetCourseOfferingsExportLinkQueryVariables,
  GetCourseOfferingsQueryVariables,
} from 'types/__generated__';

import { GET_COURSE_OFFERINGS_EXPORT_LINK } from 'graphql/queries/getCourseOfferingsExportLink';
import { downloadFile } from 'util/downloadFile';

export function ExportAllRegistrations({ departmentId, academicYear }: GetCourseOfferingsQueryVariables) {
  const { t } = useTranslation('course-offerings', { keyPrefix: 'course-offerings' });

  const [lazyQuery] = useLazyQuery<GetCourseOfferingsExportLinkQuery, GetCourseOfferingsExportLinkQueryVariables>(
    GET_COURSE_OFFERINGS_EXPORT_LINK,
    { variables: { departmentId, academicYear }, fetchPolicy: 'network-only' }
  );

  async function onClick() {
    const { data } = await lazyQuery();

    if (data) {
      downloadFile(data.courseOfferingsExport.url);
    }
  }

  return (
    <button type="button" onClick={onClick}>
      {t('export-action-list.options.all-courses')}
    </button>
  );
}
