import { gql } from '@apollo/client';

import { TOPIC_SET_FRAGMENT } from 'graphql/fragments/topicSetFragment';

export const SET_TOPIC_SET_BY_COURSE_OFFERING_ID = gql`
  mutation setTopicSetByCourseOfferingId($courseOfferingId: String!, $topicSetId: String) {
    setCourseOfferingTopicSet(input: { courseOfferingId: $courseOfferingId, topicSetId: $topicSetId }) {
      mutationStatus
      courseOfferingId
      topicSet {
        ...topicSet
      }
    }
  }
  ${TOPIC_SET_FRAGMENT}
`;
