import { gql } from '@apollo/client';

import { CONSTRAINED_CHOICE_CLUSTER_FRAGMENT } from 'graphql/fragments/constrainedChoiceClusterFragment';

export const UPDATE_CONSTRAINED_CHOICE_CLUSTER = gql`
  mutation updateConstrainedChoiceCluster(
    $constrainedChoiceClusterId: String!
    $titleEN: String!
    $titleNL: String!
    $registrationLimit: Int!
  ) {
    updateConstrainedChoiceCluster(
      input: {
        constrainedChoiceClusterId: $constrainedChoiceClusterId
        titleEN: $titleEN
        titleNL: $titleNL
        registrationLimit: $registrationLimit
      }
    ) {
      mutationStatus
      constrainedChoiceCluster {
        ...constrainedChoiceCluster
      }
    }
  }
  ${CONSTRAINED_CHOICE_CLUSTER_FRAGMENT}
`;
