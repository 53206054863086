import { useApolloClient } from '@apollo/client';

import type { GetDepartmentsQuery } from 'types/__generated__';

import { GET_DEPARTMENTS } from 'graphql/queries/getDepartments';

export function useGetDepartments() {
  const client = useApolloClient();

  const cachedDepartments = client.readQuery<GetDepartmentsQuery>({
    query: GET_DEPARTMENTS,
  });

  if (!cachedDepartments?.marblesDepartments) {
    throw new Error(
      'The useGetDepartments hook can only be used in a child of a component that performed the GET_DEPARTMENTS query'
    );
  }

  return cachedDepartments.marblesDepartments;
}
