import { gql } from '@apollo/client';

export const REMOVE_TOPIC_SET = gql`
  mutation removeTopicSet($topicSetId: String!) {
    removeTopicSet(input: { topicSetId: $topicSetId }) {
      topicSetId
      mutationStatus
    }
  }
`;
