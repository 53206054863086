import { useLazyQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Button, Icon } from '@uva-glass/component-library';

import type { GetRegistrationsExportLinkQuery, GetRegistrationsExportLinkQueryVariables } from 'types/__generated__';

import { GET_REGISTRATIONS_EXPORT_LINK } from 'graphql/queries/getRegistrationsExportLink';
import { downloadFile } from 'util/downloadFile';

type Props = GetRegistrationsExportLinkQueryVariables;

export function StudentsExportLink({ courseOfferingId, status }: Props) {
  const { t } = useTranslation('course-offering', { keyPrefix: 'students-export-link' });
  const [query, lazyQuery] = useLazyQuery<GetRegistrationsExportLinkQuery, GetRegistrationsExportLinkQueryVariables>(
    GET_REGISTRATIONS_EXPORT_LINK,
    {
      variables: { courseOfferingId, status },
      fetchPolicy: 'network-only',
    }
  );

  async function onClick() {
    const { data } = await query();

    if (data) {
      downloadFile(data.courseRegistrationsExport.url);
    }
  }

  if (lazyQuery.error) {
    return <>Error…</>;
  }

  return (
    <Button variant="secondary" onClick={onClick} disabled={lazyQuery.loading}>
      <Icon name="ArrowUpOnSquare" size={20} />
      {t('button')}
    </Button>
  );
}
