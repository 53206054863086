import { useQuery } from '@apollo/client';
import { Spinner } from '@uva-glass/component-library';
import { createContext, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import type { PropsWithChildren } from 'react';
import type { GetFiltersQuery } from 'types/__generated__';

import { GET_FILTERS } from 'graphql/queries/getFilters';

export type FiltersContextType = {
  academicYears: GetFiltersQuery['marblesAcademicYears'];
  departments: GetFiltersQuery['marblesDepartments'];
  courseOfferingsFilter: GetFiltersQuery['courseOfferingsFilter'];
};

const FiltersContext = createContext<FiltersContextType | null>(null);

export function FiltersProvider({ children }: PropsWithChildren) {
  const { t } = useTranslation('common', { keyPrefix: 'provider' });
  const { loading, error, data } = useQuery<GetFiltersQuery>(GET_FILTERS);

  if (loading) return <Spinner ariaValueText={t('loading')} />;

  if (error || !data) {
    throw new Error('FiltersProvider: error loading filters');
  }

  const { marblesAcademicYears, marblesDepartments, courseOfferingsFilter } = data;

  const value = { academicYears: marblesAcademicYears, departments: marblesDepartments, courseOfferingsFilter };

  return <FiltersContext.Provider value={value}>{children}</FiltersContext.Provider>;
}

export function useFilters() {
  const context = useContext(FiltersContext);

  if (!context) throw new Error('useFilters can only be used in a FiltersProvider');

  return context;
}
