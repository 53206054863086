import { useMutation } from '@apollo/client';
import { useOverlayTriggerState } from '@react-stately/overlays';
import { useTranslation } from 'react-i18next';

import type {
  AddRequiredCoursesMutation,
  AddRequiredCoursesMutationInput,
  AddRequiredCoursesMutationVariables,
  GetRuleByIdQuery,
} from 'types/__generated__';

import { RequiredCoursesForm } from './RequiredCoursesForm/RequiredCoursesForm';

import { ADD_REQUIRED_COURSES } from 'graphql/mutations/addRequiredCourses';

interface Props {
  rule: NonNullable<GetRuleByIdQuery['rule']>;
}

export function AddRequiredCourse({ rule }: Props) {
  const { t } = useTranslation('requirement-rules', { keyPrefix: 'rule.optional-requirements' });
  const { open, close, isOpen } = useOverlayTriggerState({});

  const [mutate, mutation] = useMutation<AddRequiredCoursesMutation, AddRequiredCoursesMutationVariables>(
    ADD_REQUIRED_COURSES
  );

  function onMutate(variables: AddRequiredCoursesMutationInput, onCleanUp: () => void) {
    mutate({
      variables,
      onCompleted: onCleanUp,
    });
  }

  return (
    <>
      <button type="button" onClick={open}>
        {t('new-requirement')}
      </button>

      {isOpen && (
        <RequiredCoursesForm
          close={close}
          isLoading={mutation.loading}
          isOpen={isOpen}
          modalTitle={t('add-required-courses')}
          mutate={onMutate}
          ruleId={rule.id}
        />
      )}
    </>
  );
}
