import { Icon, OverlayCloseButton } from '@uva-glass/component-library';
import classNames from 'clsx';
import { useTranslation } from 'react-i18next';

import type { PropsWithChildren } from 'react';
import type { UIStatus } from 'types/UserInterface';

import styles from './Toast.module.css';

import { useToast } from 'providers/ToastProvider';

type UIToastVariant = Extract<UIStatus, 'information' | 'warning' | 'success'>;
export interface ToastProps {
  id: string;
  variant: UIToastVariant;
  title?: string;
  content: string;
}

function setIconName(toastType: UIToastVariant) {
  if (toastType === 'success') return 'CheckCircleFillMini';
  if (toastType === 'information') return 'InformationCircleFillMini';
  return 'ExclamationCircleFillMini';
}

export function Toast({ id, variant, title, content }: PropsWithChildren<ToastProps>) {
  const { t } = useTranslation('common', { keyPrefix: 'buttons' });
  const { removeToast } = useToast();

  function closeToast() {
    removeToast(id);
  }

  return (
    <div className={classNames(styles['toast'], styles['toast-visible'])}>
      <div className={classNames(styles['toast-icon'], styles[`toast-icon--${variant}`])}>
        <Icon name={setIconName(variant)} size={24} />
      </div>
      <div className={styles['toast-content']}>
        {title && <strong>{title}</strong>}
        <p className={styles['toast-message']}>{content}</p>
      </div>
      <span className={styles['toast-close-button']}>
        <OverlayCloseButton onClick={closeToast} aria-label={t('close')} />
      </span>
    </div>
  );
}
