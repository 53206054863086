import { FeedbackBox, Label } from '@uva-glass/component-library';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { ChangeEvent } from 'react';

import { Select } from 'components/Select/Select';
import { StaffDeregistrationReason } from 'types/__generated__';

const DEREGISTRATION_REASON_SELECT = 'deregistration-reason-select';
const DEREGISTRATION_REASON_ERROR = 'deregistration-reason-error';

interface Props {
  error?: string;
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void;
  defaultReason?: StaffDeregistrationReason;
}

export function DeregistrationReason({ error, onChange, defaultReason }: Props) {
  const { t } = useTranslation('common', { keyPrefix: 'deregistration-reason' });
  const [selectedDeregistrationReason, setSelectedDeregistrationReason] = useState<
    StaffDeregistrationReason | undefined
  >(defaultReason);

  const deregistrationReasons = Object.values(StaffDeregistrationReason).map((reason) => ({
    label: t(reason),
    value: reason,
  }));

  function onSelectChange(event: ChangeEvent<HTMLSelectElement>) {
    setSelectedDeregistrationReason(event.target.value as StaffDeregistrationReason);

    onChange(event);
  }

  return (
    <>
      <Label htmlFor={DEREGISTRATION_REASON_SELECT}>{t('label')}</Label>

      <Select
        id={DEREGISTRATION_REASON_SELECT}
        value={selectedDeregistrationReason}
        onChange={onSelectChange}
        aria-describedby={error ? DEREGISTRATION_REASON_ERROR : undefined}
      >
        <Select.Option />
        {deregistrationReasons.map((reason) => (
          <Select.Option key={reason.label} value={reason.value}>
            {reason.label}
          </Select.Option>
        ))}
      </Select>

      {error && <FeedbackBox id={DEREGISTRATION_REASON_ERROR} level="error" feedback={error} />}
    </>
  );
}
