import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { MenuLink } from '@uva-glass/component-library';

import styles from './Header.module.css';

import { HelpLink } from 'App/shared/HelpLink/HelpLink';
import { LanguageToggle } from 'App/shared/LanguageToggle/LanguageToggle';
import { SigninOrSignout } from 'App/shared/SigninOrSignout/SigninOrSignout';
import UvALogo from 'assets/images/UvALogo.svg';
import { AcademicYearAndDepartmentSelector } from 'components/AcademicYearAndDepartmentSelector/AcademicYearAndDepartmentSelector';
import { useAuth } from 'providers/AuthProvider';
import { RequireAuthorisation } from 'providers/AuthorisationProvider';
import {
  APP_ROOT,
  AUTHORISATIONS,
  CONSTRAINED_CHOICES_NO_PARAMS,
  COURSE_OFFERINGS_NO_PARAMS,
  COURSE_REGISTRATION,
  ENTRY_REQUIREMENTS_NO_PARAMS,
  GROUP_SWAP_NO_PARAMS,
  REGISTRATION_ROUNDS_NO_PARAMS,
  REQUIREMENT_RULES_NO_PARAMS,
  STUDENTS,
  TOPIC_SETS_NO_PARAMS,
} from 'routes';
import { UserAction } from 'types/__generated__';

export function Header() {
  const { t } = useTranslation('common', { keyPrefix: 'header' });
  const user = useAuth();
  const location = useLocation();
  const [isCourseRegistration, setIsCourseRegistration] = useState(location.pathname.includes('/course-registration'));

  const locationParts = location.pathname.split('/');
  const hasYear = /^\d{4}$/.test(locationParts[3]);
  const hasDepartmentId = /^oh:department:\w+$/.test(locationParts[4]);

  useEffect(() => {
    setIsCourseRegistration(location.pathname.includes('/course-registration'));
  }, [location.pathname]);

  return (
    <>
      <div className={styles['app-header']}>
        <div className={styles['app-header__menu']}>
          <Link to={APP_ROOT}>
            <img src={UvALogo} alt="" className={styles['app-header-logo']} />
          </Link>
          {user !== null && (
            <nav className={styles['app-header__navigation']}>
              <MenuLink to={COURSE_REGISTRATION}>{t('navigation.course-registration')}</MenuLink>
              <MenuLink to={STUDENTS}>{t('navigation.students')}</MenuLink>
              <RequireAuthorisation authorisation={UserAction.EditAuthorisations}>
                <MenuLink to={AUTHORISATIONS}>{t('navigation.authorisations')}</MenuLink>
              </RequireAuthorisation>
            </nav>
          )}
        </div>
        <div className={styles['app-header__actions']} id="app-header-actions" tabIndex={-1}>
          <LanguageToggle />
          <HelpLink />
          <SigninOrSignout />
        </div>
      </div>
      {isCourseRegistration && user !== null && (
        <div className={styles['app-header__sub-header']}>
          <div className={styles['app-header__menu']}>
            <nav className={styles['app-header__sub-navigation']}>
              <MenuLink to={`${COURSE_REGISTRATION}/${COURSE_OFFERINGS_NO_PARAMS}`}>
                {t('navigation.course-offerings')}
              </MenuLink>
              <MenuLink to={`${COURSE_REGISTRATION}/${ENTRY_REQUIREMENTS_NO_PARAMS}`}>
                {t('navigation.entry-requirements')}
              </MenuLink>
              <MenuLink to={`${COURSE_REGISTRATION}/${REQUIREMENT_RULES_NO_PARAMS}`}>
                {t('navigation.requirement-rules')}
              </MenuLink>
              <MenuLink to={`${COURSE_REGISTRATION}/${TOPIC_SETS_NO_PARAMS}`}>{t('navigation.topic-sets')}</MenuLink>
              <MenuLink to={`${COURSE_REGISTRATION}/${CONSTRAINED_CHOICES_NO_PARAMS}`}>
                {t('navigation.constrained-choice')}
              </MenuLink>
              <RequireAuthorisation authorisation={UserAction.ProcessGroupSwapRequests}>
                <MenuLink to={`${COURSE_REGISTRATION}/${GROUP_SWAP_NO_PARAMS}`}>{t('navigation.group-swap')}</MenuLink>
              </RequireAuthorisation>
              <MenuLink to={`${COURSE_REGISTRATION}/${REGISTRATION_ROUNDS_NO_PARAMS}`}>
                {t('navigation.registration-rounds')}
              </MenuLink>
            </nav>
          </div>
          {/* Only render AcademicYearAndDepartmentSelector if the current path is making use of it */}
          {hasYear && (
            <div className={styles['app-header__department-and-period-filter']}>
              <AcademicYearAndDepartmentSelector showDepartmentFilter={hasDepartmentId} />
            </div>
          )}
        </div>
      )}
    </>
  );
}
