import { gql } from '@apollo/client';

import { PLAN_FRAGMENT } from './planFragment';
import { REQUIRED_COURSES_FRAGMENT } from './requiredCoursesFragment';
import { SUBPLAN_FRAGMENT } from './subplanFragment';
import { STUDENT_SET_FRAGMENT } from './studentSetFragment';

import { CAREER_FRAGMENT } from 'graphql/fragments/careerFragment';
import { INFO_REQUIREMENT_FRAGMENT } from 'graphql/fragments/infoRequirementFragment';

export const RULE_NODES_FRAGMENT = gql`
  fragment ruleNodes on RuleNodes {
    requirements {
      id
      description {
        NL
        EN
      }
      ... on InfoRequirement {
        ...infoRequirement
      }
      ... on RequiredCourses {
        ...requiredCourses
      }
    }
    groupRequirements {
      id
      description {
        NL
        EN
      }
      ... on RequiredPlan {
        plan {
          ...plan
        }
        subplans {
          ...subplan
        }
      }
      ... on RequiredCareer {
        careers {
          ...career
        }
      }
      ... on RequiredStudentSet {
        studentSet {
          ...studentSet
        }
      }
    }
  }
  ${PLAN_FRAGMENT}
  ${SUBPLAN_FRAGMENT}
  ${REQUIRED_COURSES_FRAGMENT}
  ${INFO_REQUIREMENT_FRAGMENT}
  ${CAREER_FRAGMENT}
  ${STUDENT_SET_FRAGMENT}
`;
