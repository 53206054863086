import { useMutation } from '@apollo/client';

import type {
  GetConstrainedChoiceClusterByIdQuery,
  GetConstrainedChoiceClusterByIdQueryVariables,
  RemoveCourseOfferingFromClusterMutation,
  RemoveCourseOfferingFromClusterMutationVariables,
} from 'types/__generated__';

import { MutationStatus } from 'types/__generated__';
import { REMOVE_COURSE_OFFERING_FROM_CLUSTER } from 'graphql/mutations/removeCourseOfferingFromCluster';
import { GET_CONSTRAINED_CHOICE_CLUSTER_BY_ID } from 'graphql/queries/getConstrainedChoiceClusterById';

export const useRemoveCourseOfferingFromCluster = (constrainedChoiceClusterId: string) =>
  useMutation<RemoveCourseOfferingFromClusterMutation, RemoveCourseOfferingFromClusterMutationVariables>(
    REMOVE_COURSE_OFFERING_FROM_CLUSTER,
    {
      update(cache, { data }) {
        if (data?.setCourseOfferingConstrainedChoiceCluster?.mutationStatus !== MutationStatus.Success) return;

        const { courseOffering } = data.setCourseOfferingConstrainedChoiceCluster;

        if (!courseOffering) {
          throw new Error(' "courseOffering" not in payload');
        }

        cache.updateQuery<GetConstrainedChoiceClusterByIdQuery, GetConstrainedChoiceClusterByIdQueryVariables>(
          {
            query: GET_CONSTRAINED_CHOICE_CLUSTER_BY_ID,
            variables: { constrainedChoiceClusterId },
          },
          (cached) =>
            cached?.constrainedChoiceCluster
              ? {
                  constrainedChoiceCluster: {
                    ...cached.constrainedChoiceCluster,
                    offerings: cached.constrainedChoiceCluster.offerings.filter(({ id }) => id !== courseOffering.id),
                  },
                }
              : undefined
        );
      },
      refetchQueries({ data }) {
        const mutationStatus = data?.setCourseOfferingConstrainedChoiceCluster.mutationStatus;

        if (mutationStatus === MutationStatus.CourseOfferingNotFound || mutationStatus === MutationStatus.Duplicate) {
          return [GET_CONSTRAINED_CHOICE_CLUSTER_BY_ID];
        }

        return [];
      },
    }
  );
