import { useMutation } from '@apollo/client';

import type { UpdateRuleNameMutation, UpdateRuleNameMutationVariables } from 'types/__generated__';

import { MutationStatus } from 'types/__generated__';
import { UPDATE_RULE_NAME } from 'graphql/mutations/updateRuleName';
import { GET_REQUIREMENTS_BY_DEPARTMENT_ID } from 'graphql/queries/getRequirementsByDepartmentId';
import { GET_RULES_BY_DEPARTMENT_ID } from 'graphql/queries/getRulesByDepartmentId';

export const useUpdateRuleName = () =>
  useMutation<UpdateRuleNameMutation, UpdateRuleNameMutationVariables>(UPDATE_RULE_NAME, {
    // wait for the refetch to have completed, before returning the result of the mutation; this way
    // the interface is already updated with the correct data before any redirect takes place
    awaitRefetchQueries: true,
    refetchQueries({ data }) {
      if (data?.updateRuleName.mutationStatus === MutationStatus.NotFound) {
        return [GET_REQUIREMENTS_BY_DEPARTMENT_ID, GET_RULES_BY_DEPARTMENT_ID];
      }

      return [];
    },
  });
