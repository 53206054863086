import { Children } from 'react';

import type { ReactNode } from 'react';

import styles from './Toolbar.module.css';

interface Props {
  children?: ReactNode;
}

export const Toolbar = ({ children }: Props) =>
  Children.count(children) > 0 ? <div className={styles['toolbar']}>{children}</div> : null;
