import { gql } from '@apollo/client';

export const CAREER_FRAGMENT = gql`
  fragment career on Career {
    id
    code
    description {
      NL
      EN
    }
  }
`;
