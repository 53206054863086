import { useQuery } from '@apollo/client';
import { createColumnHelper } from '@tanstack/react-table';
import { EmptyStateDataDisplay, Icon, Spinner } from '@uva-glass/component-library';
import { useTranslation } from 'react-i18next';
import { generatePath, Link } from 'react-router-dom';

import type { HeaderContext } from '@tanstack/react-table';
import type { GetCourseOfferingsQuery, GetCourseOfferingsQueryVariables } from 'types/__generated__';

import { DataTable, periodsFilterFn } from 'App/shared/DataTable/DataTable';
import { TableFilterSelect } from 'App/shared/TableFilterSelect/TableFilterSelect';
import { GET_COURSE_OFFERINGS } from 'graphql/queries/getCourseOfferings';
import { useCurrentLanguage } from 'hooks/useCurrentLanguage';
import { COURSE_OFFERING, COURSE_REGISTRATION } from 'routes';
import { sortCourseOfferingStatuses } from 'util/sortCourseOfferingStatuses';
import { TABLE_STORAGE_KEYS } from 'util/storagekeys';

type RowData = GetCourseOfferingsQuery['marblesCourseOfferings'][number];

const columnHelper = createColumnHelper<RowData>();

const initialState = { sorting: [{ id: 'title', desc: false }] };

export function DepartmentCourseOfferings({ departmentId, academicYear }: GetCourseOfferingsQueryVariables) {
  const { t } = useTranslation('course-offerings', { keyPrefix: 'department-course-offerings' });
  const currentLanguage = useCurrentLanguage();

  const query = useQuery<GetCourseOfferingsQuery, GetCourseOfferingsQueryVariables>(GET_COURSE_OFFERINGS, {
    variables: { departmentId, academicYear },
  });

  const columns = [
    columnHelper.accessor(({ info }) => info?.catalogNumber, {
      id: 'catalog-number',
      header: t('headers.catalog-number'),
    }),

    columnHelper.accessor(({ info }) => info?.title[currentLanguage], {
      id: 'title',
      header: t('headers.title'),
      cell: ({ row, cell }) => (
        <Link
          to={generatePath(`${COURSE_REGISTRATION}/${COURSE_OFFERING}`, {
            courseOfferingId: row.original.id,
          })}
        >
          {cell.getValue()}
        </Link>
      ),
    }),

    columnHelper.accessor(({ info }) => info?.ec, {
      id: 'credits',
      header: t('headers.credits'),
      enableColumnFilter: false,
      meta: { tableCellProps: { isNumeric: true } },
    }),

    columnHelper.accessor(
      ({ periods }) =>
        periods.map(({ semesterNumber, periodInSemester }) => `${semesterNumber}.${periodInSemester}`).join(', '),
      {
        id: 'periods',
        header: t('headers.periods'),
        filterFn: (row, _, filterValue) => periodsFilterFn(row.original.periods, filterValue),
      }
    ),

    columnHelper.accessor('status', {
      header: t('headers.status'),
      cell: (cell) => t(`status.${cell.getValue()}`),
      meta: {
        Filter: ({ table, column }: HeaderContext<RowData, unknown>) => {
          const options: Array<{ value: string; content: string }> = Array.from(
            new Set(table.getPreFilteredRowModel().flatRows.map((row) => row.getValue<string>(column.id)))
          ).map((value) => ({ value, content: t(`status.${value}`) }));

          return (
            <TableFilterSelect
              column={column}
              label={t('headers.status')}
              options={sortCourseOfferingStatuses(options)}
            />
          );
        },
      },
    }),

    columnHelper.accessor(
      ({ studentGroups }) => ({
        notLocked: studentGroups.filter(({ locked }) => !locked).length,
        locked: studentGroups.filter(({ locked }) => locked).length,
      }),
      {
        id: 'groups',
        header: t('headers.groups'),
        cell: ({ cell }) => (
          <>
            {cell.getValue().notLocked} / <Icon name="LockClosedFill" size={16} /> {cell.getValue().locked}
          </>
        ),
        meta: { tableCellProps: { flex: true } },
        enableColumnFilter: false,
        enableSorting: false,
      }
    ),

    columnHelper.accessor(
      ({ studentGroups }) => {
        const notLockedStudentGroups = studentGroups.filter(({ locked }) => !locked);
        const availableCapacity = notLockedStudentGroups.reduce(
          (acc, { capacity, numberOfRegisteredStudents }) => acc + Math.max(0, capacity - numberOfRegisteredStudents),
          0
        );

        return availableCapacity;
      },
      {
        id: 'available-capacity',
        header: t('headers.available-capacity'),
        meta: { tableCellProps: { isNumeric: true } },
        enableColumnFilter: false,
      }
    ),

    columnHelper.accessor('numberOfRegisteredStudents', {
      id: 'numberOfRegisteredStudents',
      header: t('headers.registered'),
      cell: ({ row, cell }) => {
        const { numberOfPendingStudents, studentGroups } = row.original;
        const notLockedStudentGroups = studentGroups.filter(({ locked }) => !locked);
        const availableCapacity = notLockedStudentGroups.reduce(
          (acc, { capacity, numberOfRegisteredStudents }) => acc + Math.max(0, capacity - numberOfRegisteredStudents),
          0
        );
        const exceedsCapacity = numberOfPendingStudents > availableCapacity;

        return (
          <>
            {cell.getValue()}
            {numberOfPendingStudents > 0 && (
              <>
                &ensp;(+{numberOfPendingStudents} {t('values.pending')})
              </>
            )}
            {exceedsCapacity && <Icon name="ExclamationCircle" title={t('values.exceeds-capacity')} />}
          </>
        );
      },
      meta: { tableCellProps: { flex: true } },
      enableColumnFilter: false,
    }),
  ];

  if (query.loading) {
    return <Spinner ariaValueText={t('loading')} />;
  }
  if (query.error || !query.data) {
    return <>Error</>;
  }

  return query.data.marblesCourseOfferings.length ? (
    <DataTable
      data={query.data.marblesCourseOfferings}
      columns={columns}
      initialState={initialState}
      tableId={TABLE_STORAGE_KEYS.DEPARTMENT_COURSE_OFFERINGS}
    />
  ) : (
    <EmptyStateDataDisplay label={t('empty-state.label')} paragraph={t('empty-state.paragraph')} />
  );
}
