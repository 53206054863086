import { useState } from 'react';
import { clsx } from 'clsx';
import { Icon } from '@uva-glass/component-library';

import styles from './CopyButton.module.css';

interface Props {
  data: string;
}

const DELAY = 3000;

export function CopyButton({ data }: Props) {
  const [copied, setCopied] = useState(false);

  function onClick() {
    navigator.clipboard.writeText(data);
    setCopied(true);
    const t = globalThis.setTimeout(() => {
      setCopied(false);
      globalThis.clearTimeout(t);
    }, DELAY);
  }

  return (
    <button
      className={clsx(styles['copy-button'], { [styles['copy-button--success']]: copied })}
      type="button"
      aria-hidden="true"
      onClick={onClick}
    >
      <Icon name="DocumentDuplicate" />
    </button>
  );
}
