import { useMutation, useQuery } from '@apollo/client';
import { useOverlayTriggerState } from '@react-stately/overlays';
import {
  Button,
  ButtonGroup,
  FeedbackBox,
  Icon,
  Label,
  ModalDialog,
  Spinner,
  FormField,
} from '@uva-glass/component-library';
import { useId, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { ChangeEvent, FormEvent } from 'react';
import type {
  GetRequirementByIdQuery,
  GetRulesByDepartmentIdQuery,
  GetRulesByDepartmentIdQueryVariables,
  UpdateRequirementRulesMutation,
  UpdateRequirementRulesMutationVariables,
} from 'types/__generated__';

import { Select } from 'components/Select/Select';
import { UPDATE_REQUIREMENT_RULES } from 'graphql/mutations/updateRequirementRules';
import { GET_RULES_BY_DEPARTMENT_ID } from 'graphql/queries/getRulesByDepartmentId';
import { useGetReadableMutationStatus } from 'hooks/useGetReadableMutationStatus';
import { MutationStatus } from 'types/__generated__';
import { alphabeticalSortBy } from 'util/alphabeticalSortBy';
import { createRulesInRequirement } from 'util/createRulesInRequirement';

interface Props {
  requirement: NonNullable<GetRequirementByIdQuery['requirement']>;
}

export function LinkRuleInRequirement({ requirement }: Props) {
  const { t } = useTranslation('entry-requirements', { keyPrefix: 'link-rule-in-requirement' });
  const readableMutationStatus = useGetReadableMutationStatus();
  const { close, isOpen, open } = useOverlayTriggerState({});
  const [ruleId, setRuleId] = useState<string>();
  const [inputError, setInputError] = useState<string>();
  const [mutate, mutation] = useMutation<UpdateRequirementRulesMutation, UpdateRequirementRulesMutationVariables>(
    UPDATE_REQUIREMENT_RULES
  );
  const selectId = useId();
  const feedbackId = useId();

  const query = useQuery<GetRulesByDepartmentIdQuery, GetRulesByDepartmentIdQueryVariables>(
    GET_RULES_BY_DEPARTMENT_ID,
    { variables: { departmentId: requirement.department.id, academicYear: requirement.academicYear.id } }
  );

  function resetAndClose() {
    setInputError(undefined);
    setRuleId(undefined);
    close();
  }

  function onChange(event: ChangeEvent<HTMLSelectElement>) {
    setInputError(undefined);
    setRuleId(event.target.value);
  }

  function onSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (!ruleId) return setInputError(t('modal.select-a-rule'));

    const newRulesInRequirement = [
      ...createRulesInRequirement(requirement),
      { ruleId, priority: null, priorityLimit: null, includeInRetry: false },
    ];

    mutate({
      variables: {
        requirementId: requirement.id,
        rules: newRulesInRequirement,
      },
      onCompleted(data) {
        const mutationStatus = data?.updateRequirementRules.mutationStatus;

        if (mutationStatus === MutationStatus.Success) {
          resetAndClose();
          return;
        }

        setInputError(readableMutationStatus(mutationStatus || 'unknown'));
      },
      onError() {
        setInputError(readableMutationStatus('unknown'));
      },
    });
  }

  if (query.loading) return null;

  if (!query.data) return null;

  const { rules } = query.data;

  const existingRuleIds = requirement.rulesInRequirement.map((ruleInRequirement) => ruleInRequirement.rule.id);
  const rulesStillToBeAdded = rules.filter(({ id }) => existingRuleIds.includes(id) === false);

  return (
    <>
      <Button variant="secondary" onClick={open} disabled={!rulesStillToBeAdded.length}>
        <Icon name="Link" size={20} />
        {t('trigger')}
      </Button>

      <ModalDialog
        title={t('modal.title')}
        isOpen={isOpen}
        onClose={resetAndClose}
        onSubmit={onSubmit}
        buttons={
          <ButtonGroup reversed>
            <Button key="confirm" variant="primary" type="submit" disabled={mutation.loading}>
              {t('modal.buttons.link')}
            </Button>
            <Button key="cancel" variant="secondary" onClick={resetAndClose} disabled={mutation.loading}>
              {t('modal.buttons.cancel')}
            </Button>
          </ButtonGroup>
        }
        isDismissable={!mutation.loading}
      >
        {rulesStillToBeAdded.length ? (
          <FormField outerSpace>
            <Label htmlFor={selectId}>{t('modal.select-a-rule')}</Label>
            <Select id={selectId} onChange={onChange} aria-describedby={inputError ? feedbackId : undefined}>
              <Select.Option value=""></Select.Option>
              {rulesStillToBeAdded.sort(alphabeticalSortBy('name')).map((rule) => (
                <Select.Option key={rule.id} value={rule.id}>
                  {rule.name}
                </Select.Option>
              ))}
            </Select>
            {inputError && <FeedbackBox id={feedbackId} level="error" feedback={inputError} />}
          </FormField>
        ) : (
          <Spinner ariaValueText={t('modal.loading')} />
        )}
      </ModalDialog>
    </>
  );
}
