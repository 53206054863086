import { useMutation } from '@apollo/client';

import type { StoreObject } from '@apollo/client';
import type {
  DeregisterStudentForCourseMutation,
  DeregisterStudentForCourseMutationVariables,
  GetRegisteredPursuitsByCourseOfferingIdQuery,
  GetRegisteredPursuitsByCourseOfferingIdQueryVariables,
} from 'types/__generated__';

import { DEREGISTER_STUDENT_FOR_COURSE } from 'graphql/mutations/deregisterStudentForCourse';
import { GET_COURSE_OFFERING_BY_ID } from 'graphql/queries/getCourseOfferingById';
import { GET_REGISTERED_PURSUITS_BY_COURSE_OFFERING_ID } from 'graphql/queries/getRegisteredPursuitsByCourseOfferingId';
import { MutationStatus } from 'types/__generated__';

/** @todo figure out if the GET_COURSE_OFFERING query needs to rely on the cache less. */
export const useDeregisterStudentForCourse = (academicYear: number) =>
  useMutation<DeregisterStudentForCourseMutation, DeregisterStudentForCourseMutationVariables>(
    DEREGISTER_STUDENT_FOR_COURSE,
    {
      update(cache, { data }) {
        if (data?.deregisterStudentForCourse.mutationStatus !== MutationStatus.Success) return;

        const { coursePursuitId, courseOfferingId } = data.deregisterStudentForCourse;

        if (!courseOfferingId) throw new Error('Missing courseOfferingId');

        const coursePursuitToEvict: StoreObject = { __typename: 'CoursePursuit', id: coursePursuitId };
        const courseOffering: StoreObject = { __typename: 'MarblesCourseOffering', id: courseOfferingId };

        // evict the CoursePursuit
        cache.evict({ id: cache.identify(coursePursuitToEvict) });
        cache.gc();

        // update the cache for `GET_REGISTERED_PURSUITS_BY_COURSE_OFFERING_ID`
        const registered = cache.updateQuery<
          GetRegisteredPursuitsByCourseOfferingIdQuery,
          GetRegisteredPursuitsByCourseOfferingIdQueryVariables
        >(
          { query: GET_REGISTERED_PURSUITS_BY_COURSE_OFFERING_ID, variables: { courseOfferingId, academicYear } },
          (cached) =>
            cached
              ? {
                  courseOfferingPursuits: [
                    ...cached.courseOfferingPursuits.filter(({ id }) => id !== courseOfferingId),
                  ],
                }
              : undefined
        );

        // update the number of registered students for the CourseOffering
        cache.modify({
          id: cache.identify(courseOffering),
          fields: {
            numberOfRegisteredStudents(currentValue) {
              return registered?.courseOfferingPursuits.length ?? currentValue;
            },
          },
        });
      },
      refetchQueries({ data }) {
        const mutationStatus = data?.deregisterStudentForCourse.mutationStatus;

        if (mutationStatus === MutationStatus.Success) return [];

        return [GET_COURSE_OFFERING_BY_ID, GET_REGISTERED_PURSUITS_BY_COURSE_OFFERING_ID];
      },
    }
  );
