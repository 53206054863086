import { gql } from '@apollo/client';

export const REMOVE_TOPIC = gql`
  mutation removeTopic($topicId: String!) {
    removeTopic(input: { topicId: $topicId }) {
      topicId
      mutationStatus
      topicSetId
    }
  }
`;
