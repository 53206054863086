import { useQuery } from '@apollo/client';

import type { GetTopicSetsByDepartmentIdQuery, GetTopicSetsByDepartmentIdQueryVariables } from 'types/__generated__';

import { GET_TOPIC_SETS_BY_DEPARTMENT_ID } from 'graphql/queries/getTopicSetsByDepartmentId';

export const useGetTopicSetsByDepartmentId = (
  departmentId: GetTopicSetsByDepartmentIdQueryVariables['departmentId'],
  academicYear: number
) =>
  useQuery<GetTopicSetsByDepartmentIdQuery, GetTopicSetsByDepartmentIdQueryVariables>(GET_TOPIC_SETS_BY_DEPARTMENT_ID, {
    variables: { departmentId, academicYear },
  });
