import type { IconProps } from '@uva-glass/component-library';
import type { StatusIndicatorProps } from 'components/StatusIndicator/StatusIndicator';

import { StatusIndicator } from 'components/StatusIndicator/StatusIndicator';

const iconNameMap: Partial<Record<StatusIndicatorProps['status'], IconProps['name']>> = {
  completed: 'CheckCircleFillMini',
  fail: 'MinusCircleFillMini',
};

export const EntryRequirementStatus = (props: StatusIndicatorProps) => (
  <StatusIndicator {...props} name={iconNameMap[props.status]} />
);
