import { useId, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { FormEvent, ChangeEvent } from 'react';
import type { SetStudentGroupCapacityMutationVariables } from 'types/__generated__';

import { useSetStudentGroupCapacity } from './hooks/useSetStudentGroupCapacity';

import { Input } from 'components/Input/Input';
import { MutationStatus } from 'types/__generated__';

type Props = SetStudentGroupCapacityMutationVariables;

const InputRE = new RegExp('^[0-9]+$');

export function SetStudentGroupCapacity({ capacity, studentGroupId }: Props) {
  const { t } = useTranslation('course-offering', { keyPrefix: 'set-student-group-capacity' });
  const [isPristine, setIsPristine] = useState(true);
  const capacityInputId = useId();
  const [mutate, mutation] = useSetStudentGroupCapacity();
  const [value, setValue] = useState(capacity);

  function handleMutation() {
    // prevent unnecessary mutations
    if (isPristine) return;

    mutate({
      variables: { studentGroupId, capacity: value },
      optimisticResponse: {
        setStudentGroupCapacity: {
          mutationStatus: MutationStatus.Success,
          studentGroupId,
          configuredCapacity: value,
        },
      },
    });
  }

  function onSubmit(event: FormEvent) {
    event.preventDefault();
    handleMutation();
  }

  function onChange(event: ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;

    setValue((prevValue) => {
      let returnValue;

      if (value === '') {
        returnValue = null;
      } else if (InputRE.test(value)) {
        returnValue = parseInt(value, 10);
      } else returnValue = prevValue;

      setIsPristine(returnValue === capacity);

      return returnValue;
    });
  }

  function onBlur() {
    handleMutation();
  }

  return (
    <form onSubmit={onSubmit}>
      <label className="visually-hidden" htmlFor={capacityInputId}>
        {t('label')}
      </label>
      <Input
        value={value ?? ''} // value can be `null`, use the empty string in that case
        onChange={onChange}
        disabled={mutation.loading}
        id={capacityInputId}
        inputMode="numeric"
        isNumeric
        onBlur={onBlur}
        pattern="[0-9]*"
        placeholder="–"
        fullWidth
      />
    </form>
  );
}
