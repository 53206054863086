import { createColumnHelper } from '@tanstack/react-table';
import { Link, generatePath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { Spinner, StatusPill } from '@uva-glass/component-library';

import type {
  GetCourseOfferingGroupSwapsByIdQuery,
  GetGroupsByCourseOfferingIdQuery,
  GetGroupsByCourseOfferingIdQueryVariables,
} from 'types/__generated__';

import styles from './CourseOfferingGroupSwap.module.css';

import { DataTable } from 'App/shared/DataTable/DataTable';
import { DateTime } from 'App/shared/DateTime/DateTime';
import { DualTextCell } from 'components/DualTextCell/DualTextCell';
import { GET_GROUPS_BY_COURSE_OFFERING_ID } from 'graphql/queries/getGroupsByCourseOfferingId';
import { getGroupIdsWithAvailableCapacity } from 'util/getGroupIdsWithAvailableCapacity';
import { GroupSwapModal } from 'App/CourseOffering/CourseOfferingGroupSwap/GroupSwapModal/GroupSwapModal';
import { STUDENTS } from 'routes';
import { TABLE_STORAGE_KEYS } from 'util/storagekeys';
import { useFormattedDate } from 'hooks/useFormattedDate';

type RowData = GetCourseOfferingGroupSwapsByIdQuery['courseOfferingGroupSwaps'][number];

interface Props {
  courseOfferingId: string;
  groupSwaps: GetCourseOfferingGroupSwapsByIdQuery['courseOfferingGroupSwaps'];
  userCanProcessGroupSwapRequests: boolean;
}

const columnHelper = createColumnHelper<RowData>();

const initialState = {
  sorting: [{ id: 'pending-creation-date', desc: false }],
};

export function PendingCourseOfferingGroupSwapTable({
  courseOfferingId,
  groupSwaps,
  userCanProcessGroupSwapRequests,
}: Props) {
  const { t } = useTranslation('course-offering', { keyPrefix: 'course-offering-group-swap.sections.pending' });
  const formattedDate = useFormattedDate();

  const query = useQuery<GetGroupsByCourseOfferingIdQuery, GetGroupsByCourseOfferingIdQueryVariables>(
    GET_GROUPS_BY_COURSE_OFFERING_ID,
    { variables: { courseOfferingId } }
  );

  if (query.loading) return <Spinner ariaValueText={''} />;

  if (query.error || !query.data?.marblesCourseOffering) return <>Error</>;

  const { studentGroups } = query.data.marblesCourseOffering;

  const columns = [
    columnHelper.accessor('creationDate', {
      id: 'pending-creation-date',
      header: t('headers.date-request'),
      cell: ({ cell }) => (
        <>
          {cell.getValue() ? (
            <DateTime isoDate={cell.getValue()} format={{ dateStyle: 'medium', timeStyle: 'short' }} />
          ) : (
            t('values.not-applicable')
          )}
        </>
      ),
      filterFn: (row, _, filterValue: string) =>
        formattedDate(row.original.creationDate, { dateStyle: 'medium', timeStyle: 'short' })
          .toLocaleLowerCase()
          .includes(filterValue.toLocaleLowerCase()),
    }),

    columnHelper.accessor(({ student }) => student.studentNumber, {
      id: 'pending-student-number',
      header: t('headers.student-number'),
    }),

    columnHelper.accessor(({ student }) => student.name, {
      id: 'pending-student-name',
      header: t('headers.student-name'),
      cell: ({ cell, row }) => (
        <Link to={generatePath(`${STUDENTS}/:studentId`, { studentId: row.original.student.id })}>
          {cell.getValue()}
        </Link>
      ),
      meta: { tableCellProps: { noWrap: true } },
      sortingFn: 'text',
    }),

    columnHelper.accessor(({ studentGroup }) => studentGroup, {
      id: 'pending-current-group',
      header: t('headers.current-group'),
      cell: ({ row }) => {
        return row.original.studentGroup ? (
          <DualTextCell upperText={row.original.studentGroup.name} lowerText={row.original.studentGroup.code} />
        ) : (
          t('values.deleted-group')
        );
      },
      filterFn: (row, _, filterValue: string) => {
        const isDeletedValue = t('values.deleted-group').toLocaleLowerCase().includes(filterValue.toLocaleLowerCase());

        return Boolean(
          (row.original.studentGroup &&
            (row.original.studentGroup.name.toLocaleLowerCase().includes(filterValue.toLocaleLowerCase()) ||
              row.original.studentGroup.code.toLocaleLowerCase().includes(filterValue.toLocaleLowerCase()))) ||
            (isDeletedValue && (row.original.studentGroup === null || undefined))
        );
      },
    }),

    columnHelper.accessor(({ studentGroup }) => studentGroup, {
      id: 'pending-capacity',
      header: t('headers.capacity'),
      cell: ({ row }) =>
        row.original.studentGroup
          ? `${row.original.studentGroup.numberOfRegisteredStudents}/${row.original.studentGroup.capacity}`
          : '0/0',
      enableColumnFilter: false,
      sortingFn: (rowA, rowB) => {
        const getNumberOfRegisteredStudents = (group: RowData['studentGroup']) => {
          // eslint-disable-next-line @typescript-eslint/no-magic-numbers
          return group?.numberOfRegisteredStudents ?? -1;
        };

        const numberOfRegisteredStudentsA = getNumberOfRegisteredStudents(rowA.original.studentGroup);
        const numberOfRegisteredStudentsB = getNumberOfRegisteredStudents(rowB.original.studentGroup);

        return numberOfRegisteredStudentsA - numberOfRegisteredStudentsB;
      },
    }),

    columnHelper.accessor(({ requestedStudentGroupIds }) => requestedStudentGroupIds, {
      id: 'pending-status-desired-groups',
      header: t('headers.status-desired-groups'),
      cell: ({ row }) => {
        const possibleGroups = getGroupIdsWithAvailableCapacity(row.original.requestedStudentGroupIds, studentGroups);

        return (
          <StatusPill
            status={possibleGroups.length ? 'possible' : 'impossible'}
            label={possibleGroups.length ? t('values.possible') : t('values.impossible')}
          />
        );
      },
      enableColumnFilter: false,
      sortingFn: (row) => {
        const isPossible = getGroupIdsWithAvailableCapacity(row.original.requestedStudentGroupIds, studentGroups);
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        return isPossible ? -1 : 1;
      },
    }),

    columnHelper.accessor('id', {
      id: 'pending-actions',
      header: () => <span className="visually-hidden">{t('headers.actions')}</span>,
      enableColumnFilter: false,
      enableSorting: false,
      cell: ({ row, cell }) => {
        const possibleGroups = getGroupIdsWithAvailableCapacity(row.original.requestedStudentGroupIds, studentGroups);

        return (
          userCanProcessGroupSwapRequests && (
            <GroupSwapModal
              groupSwapRequestId={cell.getValue()}
              student={{ name: row.original.student.name, studentNumber: row.original.student.studentNumber }}
              currentGroup={row.original.studentGroup}
              requestedGroupIds={row.original.requestedStudentGroupIds}
              allGroups={studentGroups}
              nonSwappable={possibleGroups.length < 1}
            />
          )
        );
      },
      meta: { tableCellProps: { hasButton: true } },
    }),
  ];

  return (
    <section className={styles['course-offering-group-swap__table-section']}>
      <header className={styles['course-offering-group-swap__table-header']}>
        <h2 className={styles['course-offering-group-swap__table-heading']}>{t('heading')}</h2>
      </header>
      {groupSwaps.length ? (
        <DataTable
          columns={columns}
          data={groupSwaps}
          initialState={initialState}
          tableId={TABLE_STORAGE_KEYS.PENDING_COURSE_OFFERING_GROUP_SWAP_TABLE}
        />
      ) : (
        <p className={styles['course-offering-group-swap__table-empty']}>{t('empty')}</p>
      )}
    </section>
  );
}
