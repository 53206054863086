import { gql } from '@apollo/client';

export const TOPIC_SET_FRAGMENT = gql`
  fragment topicSet on TopicSet {
    id
    name
    title {
      NL
      EN
    }
    topics {
      id
      title {
        NL
        EN
      }
      topicSetId
    }
  }
`;
