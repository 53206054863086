import { clsx } from 'clsx';
import { Icon } from '@uva-glass/component-library';

import type { OptgroupHTMLAttributes, OptionHTMLAttributes, SelectHTMLAttributes } from 'react';
import type { UISelectVariant } from 'types/UserInterface';

import styles from './Select.module.css';

type OptGroupProps = Omit<OptgroupHTMLAttributes<HTMLOptGroupElement>, 'className' | 'style'>;

type OptionProps = Omit<OptionHTMLAttributes<HTMLOptionElement>, 'className' | 'style'>;

interface Props extends Omit<SelectHTMLAttributes<HTMLSelectElement>, 'className' | 'style'> {
  id: string;
  fullWidth?: boolean;
  variant?: UISelectVariant;
}

const OptionGroup = ({ children, ...restProps }: OptGroupProps) => <optgroup {...restProps}>{children}</optgroup>;

const Option = ({ children, ...restProps }: OptionProps) => <option {...restProps}>{children}</option>;

export const Select = ({ children, fullWidth, variant = 'regular', ...restProps }: Props) => (
  <div className={styles['select-container']}>
    <select
      {...restProps}
      className={clsx(styles['select'], {
        [styles['select--full-width']]: fullWidth,
        [styles['select--small']]: variant === 'small',
        [styles['select--regular']]: variant === 'regular',
      })}
    >
      {children}
    </select>
    <Icon className={styles['select-container-icon']} name="CheveronDown" size={16} />
  </div>
);

Select.Option = Option;
Select.OptionGroup = OptionGroup;
