import { useMutation } from '@apollo/client';

import type { StoreObject } from '@apollo/client';
import type { UnlockGroupMutation, UnlockGroupMutationVariables } from 'types/__generated__';

import { UNLOCK_GROUP } from 'graphql/mutations/unlockGroup';
import { GET_COURSE_OFFERING_BY_ID } from 'graphql/queries/getCourseOfferingById';
import { MutationStatus } from 'types/__generated__';

export const useUnlockGroup = () =>
  useMutation<UnlockGroupMutation, UnlockGroupMutationVariables>(UNLOCK_GROUP, {
    update(cache, { data }) {
      if (data?.unlockStudentGroup.mutationStatus !== MutationStatus.Success) return;

      const { studentGroupId } = data.unlockStudentGroup;
      const studentGroup: StoreObject = { __typename: 'StudentGroup', id: studentGroupId };

      cache.modify({
        id: cache.identify(studentGroup),
        fields: {
          locked() {
            return false;
          },
        },
      });
    },
    refetchQueries({ data }) {
      const mutationStatus = data?.unlockStudentGroup?.mutationStatus;

      if (mutationStatus === MutationStatus.Success) return [];

      return [GET_COURSE_OFFERING_BY_ID];
    },
  });
