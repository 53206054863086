import { Fragment } from 'react';
import { useOverlayTriggerState } from '@react-stately/overlays';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { IconButton, Icon, ButtonGroup } from '@uva-glass/component-library';

import type {
  Plan as PlanType,
  RequiredPlanInput,
  RequirementNode,
  Rule,
  Subplan,
  UpdateRequiredPlanMutation,
  UpdateRequiredPlanMutationVariables,
} from 'types/__generated__';

import styles from './Plan.module.css';
import { RemoveRequiredPlan } from './RemoveRequiredPlan';

import { RequiredPlanForm } from 'App/RequirementRules/Rule/RequiredPlanForm/RequiredPlanForm';
import { UPDATE_REQUIRED_PLAN } from 'graphql/mutations/updateRequiredPlan';

interface Props {
  canBeRemoved?: boolean;
  plan: PlanType;
  subplans?: Subplan[] | null;
  ruleId: Rule['id'];
  nodeId: RequirementNode['id'];
  canEdit: boolean;
}

export function Plan({ canBeRemoved, plan, subplans, ruleId, nodeId, canEdit }: Props) {
  const { t } = useTranslation('requirement-rules', { keyPrefix: 'plan' });
  const { open, close, isOpen } = useOverlayTriggerState({});
  const [mutate, mutation] = useMutation<UpdateRequiredPlanMutation, UpdateRequiredPlanMutationVariables>(
    UPDATE_REQUIRED_PLAN
  );

  function onMutate(planInput: RequiredPlanInput) {
    mutate({
      variables: {
        nodeId,
        ruleId,
        plan: planInput,
      },
      onCompleted: close,
    });
  }

  return (
    <div className={styles['plan']}>
      <div>
        {t('terms.plan')} {plan.code}: {plan.description}
        <br />
        {subplans &&
          [...subplans]
            .sort((subplan1, subplan2) => Number(subplan1.code) - Number(subplan2.code))
            .map((subplan) => (
              <Fragment key={subplan.code}>
                {t('terms.subplan')} {subplan.code}: {subplan.description}
                <br />
              </Fragment>
            ))}
      </div>

      {canEdit && (
        <ButtonGroup>
          <IconButton aria-label={t('buttons.edit')} onClick={open}>
            <Icon name="PencilSquare" />
          </IconButton>

          {isOpen && (
            <RequiredPlanForm
              close={close}
              code={plan.code}
              isLoading={mutation.loading}
              isOpen={isOpen}
              modalTitle={t('edit-plan')}
              mutate={onMutate}
              subplans={subplans}
            />
          )}
          <RemoveRequiredPlan plan={plan} ruleId={ruleId} nodeId={nodeId} isDisabled={!canBeRemoved} />
        </ButtonGroup>
      )}
    </div>
  );
}
