import { gql } from '@apollo/client';

export const GET_COURSE_OFFERING_GROUP_SWAPS_BY_ID = gql`
  query getCourseOfferingGroupSwapsById($courseOfferingId: String!) {
    courseOfferingGroupSwaps(courseOfferingId: $courseOfferingId) {
      id
      studentId
      student {
        id
        name
        studentNumber
      }
      studentGroup {
        id
        name
        code
        capacity
        numberOfRegisteredStudents
        locked
        groupSwapDemand
      }
      newStudentGroup {
        id
        name
        code
      }
      requestedStudentGroupIds
      processedBy
      updateDate
      creationDate
      expirationDate
      status
    }
  }
`;
