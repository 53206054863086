import { useApolloClient } from '@apollo/client';
import { useEffect } from 'react';

import { logout } from 'services/authService';

export function Logout() {
  const client = useApolloClient();

  useEffect(() => {
    async function asyncLogout() {
      try {
        await client.clearStore();
        await logout();
        window.location.replace('https://engine.surfconext.nl/logout');
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
    }

    asyncLogout();
  }, [client]);

  return null;
}
