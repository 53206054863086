import { gql } from '@apollo/client';

import { PARTIAL_REQUIREMENT_FRAGMENT } from 'graphql/fragments/partialRequirementFragment';

export const GET_REQUIREMENTS_BY_DEPARTMENT_ID = gql`
  query getRequirementsByDepartmentId(
    $academicYear: Int!
    $departmentId: String!
    $includeParentDepartments: Boolean!
  ) {
    requirements(
      academicYear: $academicYear
      departmentId: $departmentId
      includeParentDepartments: $includeParentDepartments
    ) {
      ...partialRequirement
    }
  }
  ${PARTIAL_REQUIREMENT_FRAGMENT}
`;
