import { useTranslation } from 'react-i18next';

import { AuthorisationTable } from 'App/Authorisations/AuthorisationTable/AuthorisationTable';
import { AddAuthorisation } from 'App/Authorisations/AddAutorisation/AddAuthorisation';
import { Page } from 'components/Page/Page';

export function Authorisations() {
  const { t } = useTranslation('authorisations', { keyPrefix: 'authorisations-page' });
  return (
    <Page title={t('title')} actions={<AddAuthorisation />}>
      <section>
        <AuthorisationTable />
      </section>
    </Page>
  );
}
