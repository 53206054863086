import { gql } from '@apollo/client';

export const SET_STUDENT_GROUP_TOPIC = gql`
  mutation setTopicForStudentGroup($studentGroupId: String!, $topicId: String) {
    setStudentGroupTopic(input: { studentGroupId: $studentGroupId, topicId: $topicId }) {
      studentGroupId
      topic {
        id
        title {
          NL
          EN
        }
      }
      mutationStatus
    }
  }
`;
