import { useMutation } from '@apollo/client';

import type { StoreObject } from '@apollo/client';
import type {
  GetUserAuthorisationsQuery,
  GetUserAuthorisationsQueryVariables,
  RevokeAuthorisationMutation,
  RevokeAuthorisationMutationVariables,
} from 'types/__generated__';

import { GET_USER_AUTHORISATIONS } from 'graphql/queries/getUserAuthorisations';
import { MutationStatus } from 'types/__generated__';
import { REVOKE_AUTHORISATION } from 'graphql/mutations/revokeAuthorisation';

export const useRevokeAuthorisation = (authorisationId: number, username: string) =>
  useMutation<RevokeAuthorisationMutation, RevokeAuthorisationMutationVariables>(REVOKE_AUTHORISATION, {
    update(cache, { data }) {
      if (data?.marblesRevokeAuthorisation.mutationStatus !== MutationStatus.Success) return;

      const authorisationToEvict: StoreObject = { __typename: 'RoleAssignment', id: authorisationId };

      cache.updateQuery<GetUserAuthorisationsQuery, GetUserAuthorisationsQueryVariables>(
        {
          query: GET_USER_AUTHORISATIONS,
          variables: { username },
        },
        (cached) =>
          cached && cached.marblesUser
            ? {
                marblesUser: {
                  ...cached.marblesUser,
                  roleAssignments: cached.marblesUser.roleAssignments.filter(
                    (assignment) => assignment.id !== authorisationId
                  ),
                },
              }
            : undefined
      );

      cache.evict({ id: cache.identify(authorisationToEvict) });
      cache.gc();
    },
  });
