import { gql } from '@apollo/client';

export const UNLOCK_GROUP = gql`
  mutation unlockGroup($groupId: String!) {
    unlockStudentGroup(input: { studentGroupId: $groupId }) {
      mutationStatus
      studentGroupId
    }
  }
`;
