import { gql } from '@apollo/client';

import { RULE_FRAGMENT } from 'graphql/fragments/ruleFragment';

export const ADD_REQUIRED_COURSES = gql`
  mutation addRequiredCourses($ruleId: String!, $description: BilingualStringInput!, $courses: RequiredCoursesInput!) {
    addRequiredCourses(input: { ruleId: $ruleId, description: $description, courses: $courses }) {
      mutationStatus
      rule {
        ...rule
      }
    }
  }
  ${RULE_FRAGMENT}
`;
