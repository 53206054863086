import { useLazyQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import type {
  GetOfferingRequirementsExportLinkQuery,
  GetOfferingRequirementsExportLinkQueryVariables,
} from 'types/__generated__';

import { GET_OFFERING_REQUIREMENTS_EXPORT_LINK } from 'graphql/queries/getOfferingRequirementsExportLink';
import { downloadFile } from 'util/downloadFile';

export function ExportCoursesAndRequirements({
  departmentId,
  academicYear,
}: GetOfferingRequirementsExportLinkQueryVariables) {
  const { t } = useTranslation('course-offerings', { keyPrefix: 'course-offerings' });

  const [lazyQuery] = useLazyQuery<
    GetOfferingRequirementsExportLinkQuery,
    GetOfferingRequirementsExportLinkQueryVariables
  >(GET_OFFERING_REQUIREMENTS_EXPORT_LINK, {
    variables: { departmentId, academicYear },
    fetchPolicy: 'network-only',
  });

  async function onClick() {
    const { data } = await lazyQuery();

    if (data) {
      downloadFile(data.courseOfferingRequirementsExport.url);
    }
  }

  return (
    <button type="button" onClick={onClick}>
      {t('export-action-list.options.courses-and-requirements')}
    </button>
  );
}
