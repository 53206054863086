import { useMutation } from '@apollo/client';

import type { StoreObject } from '@apollo/client';
import type {
  GetCourseOfferingByIdQuery,
  GetCourseOfferingByIdQueryVariables,
  GetGroupsByCourseOfferingIdQuery,
  GetGroupsByCourseOfferingIdQueryVariables,
  GetPendingPursuitsByCourseOfferingIdQuery,
  GetPendingPursuitsByCourseOfferingIdQueryVariables,
  GetTopicSetsByDepartmentIdQuery,
  GetTopicSetsByDepartmentIdQueryVariables,
  SetTopicSetByCourseOfferingIdMutation,
  SetTopicSetByCourseOfferingIdMutationVariables,
  TopicSet,
} from 'types/__generated__';

import { SET_TOPIC_SET_BY_COURSE_OFFERING_ID } from 'graphql/mutations/setTopicSetByCourseOfferingId';
import { GET_GROUPS_BY_COURSE_OFFERING_ID } from 'graphql/queries/getGroupsByCourseOfferingId';
import { GET_PENDING_PURSUITS_BY_COURSE_OFFERING_ID } from 'graphql/queries/getPendingPursuitsByCourseOfferingId';
import { MutationStatus } from 'types/__generated__';
import { GET_COURSE_OFFERING_BY_ID } from 'graphql/queries/getCourseOfferingById';
import { GET_TOPIC_CAPACITY_BY_COURSE_OFFERING_ID } from 'graphql/queries/getTopicCapacityByCourseOfferingId';
import { GET_TOPIC_SETS_BY_DEPARTMENT_ID } from 'graphql/queries/getTopicSetsByDepartmentId';

export const useSetTopicSetByCourseOfferingId = ({
  academicYear,
  departmentId,
}: GetTopicSetsByDepartmentIdQueryVariables) =>
  useMutation<SetTopicSetByCourseOfferingIdMutation, SetTopicSetByCourseOfferingIdMutationVariables>(
    SET_TOPIC_SET_BY_COURSE_OFFERING_ID,
    {
      update(cache, { data }) {
        if (data?.setCourseOfferingTopicSet.mutationStatus !== MutationStatus.Success) return;

        const { courseOfferingId, topicSet } = data.setCourseOfferingTopicSet;

        const query = cache.readQuery<GetCourseOfferingByIdQuery, GetCourseOfferingByIdQueryVariables>({
          query: GET_COURSE_OFFERING_BY_ID,
          variables: { courseOfferingId },
        });

        const courseOffering: StoreObject = { __typename: 'MarblesCourseOffering', id: courseOfferingId };

        cache.modify({
          id: cache.identify(courseOffering),
          fields: {
            topicSet() {
              return topicSet ?? null;
            },
          },
        });

        // getting topic set id from cache (in case topic set has been unlinked) or from the mutation result
        const topicSetId = (query?.marblesCourseOffering?.topicSet?.id || topicSet?.id) as string;

        // update the list of topic sets by setting or unsetting the id of the course offering for which we just
        // linked or unlinked the topic set
        cache.updateQuery<GetTopicSetsByDepartmentIdQuery, GetTopicSetsByDepartmentIdQueryVariables>(
          {
            query: GET_TOPIC_SETS_BY_DEPARTMENT_ID,
            variables: { departmentId, academicYear },
          },
          (cached) => {
            if (!cached) return undefined;

            const selectedTopicSet = cached.topicSets.find(({ id }) => id === topicSetId) as TopicSet;

            const updatedTopicSet = {
              ...selectedTopicSet,
              courseOfferings: topicSet
                ? [...selectedTopicSet.courseOfferings, { id: courseOfferingId }]
                : selectedTopicSet.courseOfferings.filter(({ id }) => id !== courseOfferingId),
            };

            const newCache = cached
              ? {
                  topicSets: cached.topicSets.map((cachedTopicSet) =>
                    cachedTopicSet.id === topicSetId ? updatedTopicSet : cachedTopicSet
                  ),
                }
              : undefined;

            return newCache;
          }
        );

        // This is the same behaviour as is implemented in Marbles, however, it should never actually change anything
        // because the interface does not allow TopicSet modifications when there are PENDING CoursePursuits.
        cache.updateQuery<
          GetPendingPursuitsByCourseOfferingIdQuery,
          GetPendingPursuitsByCourseOfferingIdQueryVariables
        >(
          { query: GET_PENDING_PURSUITS_BY_COURSE_OFFERING_ID, variables: { courseOfferingId, academicYear } },
          (cached) =>
            cached
              ? {
                  courseOfferingPursuits: [
                    ...cached.courseOfferingPursuits.map((courseOfferingPursuit) => ({
                      ...courseOfferingPursuit,
                      topicId: null,
                    })),
                  ],
                }
              : undefined
        );

        cache.updateQuery<GetGroupsByCourseOfferingIdQuery, GetGroupsByCourseOfferingIdQueryVariables>(
          { query: GET_GROUPS_BY_COURSE_OFFERING_ID, variables: { courseOfferingId } },
          (cached) => {
            if (!cached?.marblesCourseOffering) return undefined;

            const { marblesCourseOffering } = cached;
            const studentGroups = marblesCourseOffering.studentGroups.map((studentGroup) => ({
              ...studentGroup,
              topic: null,
            }));

            return { marblesCourseOffering: { ...marblesCourseOffering, studentGroups } };
          }
        );
      },
      refetchQueries({ data }) {
        const mutationStatus = data?.setCourseOfferingTopicSet.mutationStatus;

        // Re-fetching the entire course offering and capacity qeuries to display the correct data
        // on screen
        if (
          // Getting a Duplicate status means that the topic set has already been linked or unlinked
          // in another browser tab or by another user.
          mutationStatus === MutationStatus.Duplicate ||
          // Getting a CourseOfferingPendingPursuits status means that, while the current user was
          // looking at his or her screen, students have been added to the list of pending students
          // in another tab or by another user.
          mutationStatus === MutationStatus.CourseOfferingPendingPursuits
        ) {
          return [GET_TOPIC_CAPACITY_BY_COURSE_OFFERING_ID, GET_COURSE_OFFERING_BY_ID];
        }

        // no refetch needed when the mutation didn't succeed or when the topic set was unlinked
        if (mutationStatus !== MutationStatus.Success || data?.setCourseOfferingTopicSet.topicSet === null) return [];

        // refetching topic capacity; this query cannot be updated in cache, because its aggregated data
        // cannot be related to with IDs from the linked topic set or the current course offering
        return [GET_TOPIC_CAPACITY_BY_COURSE_OFFERING_ID];
      },
    }
  );
