import { useQuery } from '@apollo/client';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { EmptyStateDataDisplay, Spinner } from '@uva-glass/component-library';

import type { GetPursuitsByStudentIdQuery, GetPursuitsByStudentIdQueryVariables, Student } from 'types/__generated__';

import { ArchivedStudentPursuitsTable } from 'App/Student/StudentPursuits/ArchivedStudentPursuitsTable';
import { PendingStudentPursuitsTable } from 'App/Student/StudentPursuits/PendingStudentPursuitsTable';
import { RegisteredStudentPursuitsTable } from 'App/Student/StudentPursuits/RegisteredStudentPursuitsTable';
import { GET_PURSUITS_BY_STUDENT_ID } from 'graphql/queries/getPursuitsByStudentId';
import { useGroupSwapRequestStatus } from 'hooks/useGroupSwapRequestStatus';
import { CoursePursuitStatus } from 'types/__generated__';

type StudentPartial = Pick<Student, 'id' | 'studentNumber' | 'name'>;

interface Props {
  student: StudentPartial;
}

export function StudentPursuits({ student }: Props) {
  const { t } = useTranslation('student', { keyPrefix: 'student-pursuits' });

  const query = useQuery<GetPursuitsByStudentIdQuery, GetPursuitsByStudentIdQueryVariables>(
    GET_PURSUITS_BY_STUDENT_ID,
    { variables: { studentId: student.id }, fetchPolicy: 'cache-and-network' }
  );
  const groupSwapRequestStatus = useGroupSwapRequestStatus();

  const getGroupSwapStatus = useCallback(
    (coursePursuitId: string) => {
      if (!query.data?.studentGroupSwaps) return;

      const matchingGroupSwapRequest = query.data.studentGroupSwaps.find(
        (groupSwap) => groupSwap.coursePursuitId === coursePursuitId
      );

      return matchingGroupSwapRequest
        ? groupSwapRequestStatus[matchingGroupSwapRequest.status]
        : groupSwapRequestStatus.None;
    },
    [groupSwapRequestStatus, query.data?.studentGroupSwaps]
  );

  if (query.loading) return <Spinner ariaValueText={''} />;
  if (query.error || !query.data) return <>Error</>;

  const groupAndSortStudentPursuitsByYear = (studentPursuits: typeof query.data.studentPursuits) => {
    const groupedByYear: Record<string, typeof query.data.studentPursuits> = {};

    studentPursuits.forEach((studentPursuit) => {
      const academicYear = studentPursuit.selectedOffering?.academicYear;
      if (academicYear) {
        const year = academicYear.name;
        if (!groupedByYear[year]) {
          groupedByYear[year] = [];
        }
        groupedByYear[year].push(studentPursuit);
      }
    });

    const sortedPursuits = Object.entries(groupedByYear)
      .map(([year, studentPursuits]) => ({ year, studentPursuits }))
      .sort((pursuitsA, pursuitsB) => pursuitsB.year.localeCompare(pursuitsA.year));

    return sortedPursuits;
  };

  const customSortPendingStudentPursuits = (studentPursuits: typeof query.data.studentPursuits) => {
    return studentPursuits.sort((pursuitA, pursuitB) => {
      const clusterIdA = pursuitA.selectedOffering?.constrainedChoiceClusterId;
      const clusterIdB = pursuitB.selectedOffering?.constrainedChoiceClusterId;
      const indexA = pursuitA.clusterPreference?.index || 0;
      const indexB = pursuitB.clusterPreference?.index || 0;

      /* eslint-disable @typescript-eslint/no-magic-numbers */
      // sort pursuits without cluster to be first
      if (clusterIdA === null && clusterIdB !== null) return -1;
      if (clusterIdA !== null && clusterIdB === null) return 1;

      // sort clusters of the same id together
      if (clusterIdA !== undefined && clusterIdB !== undefined && clusterIdA !== null && clusterIdB !== null) {
        if (clusterIdA < clusterIdB) return -1;
        if (clusterIdA > clusterIdB) return 1;

        // sort clusters by index acending
        if (clusterIdA === clusterIdB) {
          if (indexA < indexB) return -1;
          if (indexA > indexB) return 1;
        }
      }
      return 0;
      /* eslint-enable @typescript-eslint/no-magic-numbers */
    });
  };

  const pendingStudentPursuits = groupAndSortStudentPursuitsByYear(
    query.data.studentPursuits.filter(({ coursePursuitStatus }) => {
      return coursePursuitStatus === CoursePursuitStatus.Pending;
    })
  );

  const registeredStudentPursuits = groupAndSortStudentPursuitsByYear(
    query.data.studentPursuits.filter(({ coursePursuitStatus, selectedOffering }) => {
      return coursePursuitStatus === CoursePursuitStatus.Registered && !selectedOffering?.academicYear.isArchived;
    })
  );

  const archivedStudentPursuits = query.data.studentPursuits.filter(({ coursePursuitStatus, selectedOffering }) => {
    return coursePursuitStatus === CoursePursuitStatus.Registered && selectedOffering?.academicYear.isArchived;
  });

  return pendingStudentPursuits.length || registeredStudentPursuits.length || archivedStudentPursuits.length ? (
    <>
      {pendingStudentPursuits.length > 0 &&
        pendingStudentPursuits.map(({ year, studentPursuits }) => {
          const customSortedStudentPursuits = customSortPendingStudentPursuits(studentPursuits);
          return (
            <PendingStudentPursuitsTable
              key={year}
              studentPursuits={customSortedStudentPursuits}
              student={student}
              year={year}
            />
          );
        })}

      {registeredStudentPursuits.length > 0 &&
        registeredStudentPursuits.map(({ year, studentPursuits }) => {
          return (
            <RegisteredStudentPursuitsTable
              key={year}
              studentPursuits={studentPursuits}
              getGroupSwapStatus={getGroupSwapStatus}
              student={student}
              year={year}
            />
          );
        })}

      {archivedStudentPursuits.length > 0 && (
        <ArchivedStudentPursuitsTable
          studentPursuits={archivedStudentPursuits}
          getGroupSwapStatus={getGroupSwapStatus}
        />
      )}
    </>
  ) : (
    <EmptyStateDataDisplay label={t('empty-state.label')} paragraph={t('empty-state.paragraph')} />
  );
}
