import { Trans, useTranslation } from 'react-i18next';
import { useOverlayTriggerState } from '@react-stately/overlays';
import { Button, IconButton, Icon, ModalDialog, ButtonGroup } from '@uva-glass/component-library';

import type { MutationTuple } from '@apollo/client';
import type { FormEvent } from 'react';
import type {
  GetRequirementByIdQuery,
  UpdateRequirementRulesMutation,
  UpdateRequirementRulesMutationVariables,
} from 'types/__generated__';

interface Props {
  requirement: NonNullable<GetRequirementByIdQuery['requirement']>;
  ruleInRequirementId: string | null;
  updateRequirementRulesMutation: MutationTuple<
    UpdateRequirementRulesMutation,
    UpdateRequirementRulesMutationVariables
  >;
}

export function UnlinkRuleInRequirement({ requirement, ruleInRequirementId, updateRequirementRulesMutation }: Props) {
  const { t } = useTranslation('entry-requirements', { keyPrefix: 'unlink-rule-in-requirement' });
  const { open, close, isOpen } = useOverlayTriggerState({});
  const [mutate, mutation] = updateRequirementRulesMutation;
  const ruleInRequirement = requirement.rulesInRequirement.find(
    (ruleInRequirement) => ruleInRequirement.id === ruleInRequirementId
  );

  function onSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const newRulesInRequirement = requirement.rulesInRequirement
      .filter((ruleInRequirement) => ruleInRequirement.id !== ruleInRequirementId)
      .map(({ rule, priority, priorityLimit, includeInRetry }) => ({
        ruleId: rule.id,
        priority,
        priorityLimit,
        includeInRetry,
      }));

    mutate({
      variables: {
        requirementId: requirement.id,
        rules: newRulesInRequirement,
      },
      onCompleted: close,
    });
  }

  return (
    <>
      <IconButton aria-label={t('trigger')} onClick={open}>
        <Icon name="LinkSlash" />
      </IconButton>

      <ModalDialog
        title={t('modal.title')}
        isOpen={isOpen}
        onClose={close}
        onSubmit={onSubmit}
        buttons={
          <ButtonGroup>
            <Button onClick={close} variant="secondary">
              {t('modal.buttons.cancel')}
            </Button>
            <Button variant="destructive-primary" disabled={mutation.loading} type="submit">
              {t('modal.buttons.unlink')}
            </Button>
          </ButtonGroup>
        }
      >
        <p>
          <Trans
            t={t}
            i18nKey="modal.description"
            values={{ rule: ruleInRequirement?.rule.name }}
            components={{ strong: <strong /> }}
          />
        </p>
        <p>{t('modal.link_count', { count: requirement.courseOfferings.length })}</p>
      </ModalDialog>
    </>
  );
}
