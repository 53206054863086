import { useMutation, useQuery } from '@apollo/client';
import { useOverlayTriggerState } from '@react-stately/overlays';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  Button,
  IconButton,
  Icon,
  FeedbackBox,
  ModalDialog,
  ButtonGroup,
  Fieldset,
  MultiSelect,
} from '@uva-glass/component-library';

import type { ChangeEvent } from 'react';
import type {
  Career,
  GetCareersQuery,
  RuleFragment,
  UpdateRequiredCareerMutation,
  UpdateRequiredCareerMutationInput,
  UpdateRequiredCareerMutationVariables,
} from 'types/__generated__';

import { UPDATE_REQUIRED_CAREER } from 'graphql/mutations/updateRequiredCareer';
import { GET_CAREERS } from 'graphql/queries/getCareers';
import { useCurrentLanguage } from 'hooks/useCurrentLanguage';
import { useGetReadableMutationStatus } from 'hooks/useGetReadableMutationStatus';
import { MutationStatus } from 'types/__generated__';

type CareerIds = UpdateRequiredCareerMutationInput['careerIds'];

interface Props {
  careers: Career[];
  nodeId: string;
  ruleId: RuleFragment['id'];
}

export function EditRequiredCareer({ careers, ruleId, nodeId }: Props) {
  const { t } = useTranslation('requirement-rules', { keyPrefix: 'edit-required-career' });
  const currentLanguage = useCurrentLanguage();
  const { open, close, isOpen } = useOverlayTriggerState({});

  const [careerIds, setCareerIds] = useState<CareerIds>(careers.map((career) => career.id));
  const [careerIdsError, setCareerIdsError] = useState<string>();

  const readableMutationStatus = useGetReadableMutationStatus();
  const query = useQuery<GetCareersQuery>(GET_CAREERS);
  const [mutate, mutation] = useMutation<UpdateRequiredCareerMutation, UpdateRequiredCareerMutationVariables>(
    UPDATE_REQUIRED_CAREER
  );

  function resetAndClose() {
    setCareerIds(careers.map((career) => career.id));
    setCareerIdsError(undefined);

    close();
  }

  function onCareerIdsInputChange(event: ChangeEvent<HTMLInputElement>) {
    const { checked, value } = event.target;

    setCareerIdsError(undefined);
    setCareerIds((prevState) => (checked ? [...prevState, value] : prevState.filter((careerId) => careerId !== value)));
  }

  function onSubmit(event: ChangeEvent<HTMLFormElement>) {
    event.preventDefault();

    if (careerIds.length === 0) {
      setCareerIdsError(t('modal.error.no-career-ids'));
      return;
    }

    mutate({
      variables: { ruleId, nodeId, careerIds },
      onCompleted(data) {
        if (data.updateRequiredCareer.mutationStatus !== MutationStatus.Success) {
          setCareerIdsError(readableMutationStatus(data.updateRequiredCareer.mutationStatus));
          return;
        }

        close();
      },
      onError() {
        setCareerIdsError(readableMutationStatus('unknown'));
      },
    });
  }

  return (
    <>
      <IconButton aria-label={t('trigger')} onClick={open}>
        <Icon name="PencilSquare" />
      </IconButton>

      {query.data?.careers && (
        <ModalDialog
          title={t('modal.title')}
          isOpen={isOpen}
          onClose={resetAndClose}
          buttons={
            <ButtonGroup reversed>
              <Button variant="primary" type="submit" disabled={mutation.loading}>
                {t('modal.buttons.save')}
              </Button>
              <Button variant="secondary" onClick={resetAndClose} disabled={mutation.loading}>
                {t('modal.buttons.cancel')}
              </Button>
            </ButtonGroup>
          }
          onSubmit={onSubmit}
          wide
        >
          <Fieldset legend={t('modal.careers')}>
            <span>
              <Trans t={t} i18nKey="modal.student-must-be-registered" components={{ strong: <strong /> }} />
            </span>
            <MultiSelect>
              {query.data.careers.map(({ id, code, description }) => (
                <MultiSelect.MultiSelectItem
                  key={id}
                  label={`${code} ${description[currentLanguage]}`}
                  checked={careerIds.includes(id)}
                  value={id}
                  onChange={onCareerIdsInputChange}
                />
              ))}
            </MultiSelect>
            {careerIdsError && <FeedbackBox level="error" feedback={careerIdsError} />}
          </Fieldset>
        </ModalDialog>
      )}
    </>
  );
}
