import { gql } from '@apollo/client';

import { STUDENT_FRAGMENT } from 'graphql/fragments/studentFragment';

export const GET_STUDENT_BY_STUDENT_ID = gql`
  query getStudentByStudentId($studentId: String!) {
    student(studentId: $studentId) {
      ...student
      hasCreditLimitException
    }
  }
  ${STUDENT_FRAGMENT}
`;
