import { gql } from '@apollo/client';

import { PARTIAL_REQUIREMENT_FRAGMENT } from 'graphql/fragments/partialRequirementFragment';

export const COPY_REQUIREMENT = gql`
  mutation copyRequirement($requirementId: String!, $name: String!, $departmentId: String!) {
    copyRequirement(input: { requirementId: $requirementId, name: $name, departmentId: $departmentId }) {
      mutationStatus
      requirement {
        ...partialRequirement
      }
    }
  }
  ${PARTIAL_REQUIREMENT_FRAGMENT}
`;
