import { useTranslation } from 'react-i18next';
import { Card, Fieldset } from '@uva-glass/component-library';

import type { ChangeEvent } from 'react';

import { useSetCourseOfferingCreditLimitException } from './hooks/useSetCourseOfferingCreditLimitException';

import { UserAction, MutationStatus } from 'types/__generated__';
import { ToggleSwitch } from 'components/ToggleSwitch/ToggleSwitch';
import { useGetCourseOffering } from 'hooks/useGetCourseOffering';

const ID_DESCRIPTION = 'course-offering-credit-limit-exception-setting-description';

export function CreditLimitException() {
  const { t } = useTranslation('course-offering', { keyPrefix: 'course-offering-credit-limit-exception-setting' });
  const { marblesCourseOffering } = useGetCourseOffering();

  const {
    id: courseOfferingId,
    hasCreditLimitException: checked,
    constrainedChoiceCluster,
    department,
    academicYear,
  } = marblesCourseOffering;
  const userCanEditCourseSettings = department.allowedUserActions.includes(UserAction.EditCourseSettings);

  const [mutate, mutation] = useSetCourseOfferingCreditLimitException();

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const hasCreditLimitException = event.target.checked;

    mutate({
      variables: { courseOfferingId, hasCreditLimitException },
      optimisticResponse: {
        setCourseOfferingCreditLimitException: {
          mutationStatus: MutationStatus.Success,
          courseOfferingId,
          hasCreditLimitException,
        },
      },
    });
  };

  return (
    <Card highContrast>
      <Fieldset
        legend={t('legend')}
        description={constrainedChoiceCluster === null ? t('description') : t('description-cluster')}
        descriptionId={ID_DESCRIPTION}
      >
        <div>
          <ToggleSwitch
            onChange={onChange}
            checked={checked}
            disabled={!userCanEditCourseSettings || constrainedChoiceCluster !== null || academicYear.isArchived}
            loading={mutation.loading}
            label={t('label')}
            labelPosition="before"
            aria-describedby={ID_DESCRIPTION}
          />
        </div>
      </Fieldset>
    </Card>
  );
}
