import { useMutation } from '@apollo/client';

import type {
  AddTopicMutation,
  AddTopicMutationVariables,
  GetTopicsByTopicSetIdQuery,
  GetTopicsByTopicSetIdQueryVariables,
} from 'types/__generated__';

import { MutationStatus } from 'types/__generated__';
import { ADD_TOPIC } from 'graphql/mutations/addTopic';
import { GET_TOPICS_BY_TOPIC_SET_ID } from 'graphql/queries/getTopicsByTopicSetId';
import { GET_TOPIC_SETS_BY_DEPARTMENT_ID } from 'graphql/queries/getTopicSetsByDepartmentId';

export const useAddTopic = () =>
  useMutation<AddTopicMutation, AddTopicMutationVariables>(ADD_TOPIC, {
    update(cache, { data }) {
      if (data?.addTopic.mutationStatus !== MutationStatus.Success) return;

      const { topic } = data.addTopic;

      if (!topic) {
        throw new Error('No "topic" in payload');
      }

      cache.updateQuery<GetTopicsByTopicSetIdQuery, GetTopicsByTopicSetIdQueryVariables>(
        {
          query: GET_TOPICS_BY_TOPIC_SET_ID,
          variables: { topicSetId: topic.topicSetId },
        },
        (cached) =>
          cached && cached.topicSet
            ? {
                topicSet: {
                  id: topic.topicSetId,
                  topics: [...cached.topicSet.topics, topic],
                },
              }
            : {
                topicSet: {
                  id: topic.topicSetId,
                  topics: [topic],
                },
              }
      );
    },
    // we want to refetch the whole list of topic sets for the current department if the topic
    // is not found on mutation trigger
    refetchQueries: ({ data }) => {
      if (data && data?.addTopic.mutationStatus === MutationStatus.TopicSetNotFound) {
        return [GET_TOPIC_SETS_BY_DEPARTMENT_ID];
      }
      return [];
    },
  });
