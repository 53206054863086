import { useApolloClient } from '@apollo/client';
import { useParams } from 'react-router-dom';

import type { GetDepartmentsQuery } from 'types/__generated__';

import { GET_DEPARTMENTS } from 'graphql/queries/getDepartments';

export function useGetDepartmentDetails() {
  const { departmentId } = useParams<string>();
  const client = useApolloClient();

  if (!departmentId) throw new Error('No department id found.');

  const cachedDepartments = client.readQuery<GetDepartmentsQuery>({
    query: GET_DEPARTMENTS,
  });

  if (!cachedDepartments?.marblesDepartments) {
    throw new Error(
      'The useGetDepartmentDetail hook can only be used in a child of a component that performed the GET_DEPARTMENTS query'
    );
  }

  const department = cachedDepartments.marblesDepartments.find(({ id }) => id === departmentId);

  if (!department) {
    throw new Error(`Could not find department by id ${departmentId}`);
  }

  return department;
}
