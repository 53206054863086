import { useOverlayTriggerState } from '@react-stately/overlays';
import { useTranslation } from 'react-i18next';
import { Button, IconButton, Icon, ModalDialog, ButtonGroup } from '@uva-glass/component-library';

import type { RejectPendingCoursePursuitMutationVariables, Student } from 'types/__generated__';

import { useRejectPendingCoursePursuit } from './hooks/useRejectPendingCoursePursuit';

type StudentPartial = Pick<Student, 'name' | 'studentNumber'>;

interface StudentAndCourseForRejection extends RejectPendingCoursePursuitMutationVariables {
  student: StudentPartial;
}

interface Props {
  studentAndCourse: StudentAndCourseForRejection | null;
  academicYear: number;
  isDisabled: boolean;
}

export function RejectStudentForCourseModal({ studentAndCourse, academicYear, isDisabled }: Props) {
  const { t } = useTranslation('common', { keyPrefix: 'reject-student-for-course' });
  const { close, isOpen, open } = useOverlayTriggerState({});

  const [mutate, mutation] = useRejectPendingCoursePursuit(academicYear);

  const handleClick = (coursePursuitId: string) => () => {
    mutate({
      variables: { coursePursuitId },
      onCompleted: close,
    });
  };

  if (!studentAndCourse) return null;

  const { coursePursuitId, student } = studentAndCourse;
  const { name, studentNumber } = student;

  return (
    <>
      <IconButton
        variant="destructive"
        aria-label={t('trigger')}
        title={t('trigger')}
        onClick={open}
        disabled={isDisabled}
      >
        <Icon name="UserMinusFill" />
      </IconButton>
      <ModalDialog
        title={t('modal.title')}
        buttons={
          <ButtonGroup>
            <Button variant="secondary" disabled={mutation.loading} onClick={close}>
              {t('modal.buttons.cancel')}
            </Button>
            <Button variant="destructive-primary" disabled={mutation.loading} onClick={handleClick(coursePursuitId)}>
              {t('modal.buttons.confirm')}
            </Button>
          </ButtonGroup>
        }
        isOpen={isOpen}
        onClose={close}
        isDismissable={!mutation.loading}
      >
        <p>{t('modal.description', { name, studentNumber })}</p>
      </ModalDialog>
    </>
  );
}
