import type { ReactNode, HTMLAttributes } from 'react';

import styles from './FormWrapper.module.css';

interface Props extends HTMLAttributes<HTMLFormElement> {
  children: ReactNode;
}

export const FormWrapper = ({ children, ...restProps }: Props) => (
  <form className={styles['form-wrapper']} {...restProps}>
    {children}
  </form>
);
