import { DateTime } from 'luxon';

import type { EnrolmentFragment } from 'types/__generated__';

type MappedEnrolment = {
  plan: string;
  startDate: number;
  subplans?: string[];
  endDate?: number;
};

type MapperOptions = { includeSubplans?: boolean; includeDates?: boolean };

/**
 * Enrolments mapper function
 *
 * Rurns objects with plan names and (where applicable) subplan names. Start and/or end dates
 * are only included when the start date lies in the future and when the end date lies within
 * three months from the current date.
 */
export function enrolmentsMapper(
  enrolments: EnrolmentFragment[],
  { includeSubplans, includeDates }: MapperOptions = {}
): MappedEnrolment[] {
  return (
    enrolments
      // only include any enrolments where the end date is later than the start date
      .filter(({ startDate, endDate }) => Date.parse(endDate) > Date.parse(startDate))
      .map((enrolment) => {
        const startDate = Date.parse(enrolment.startDate);
        const endDate = Date.parse(enrolment.endDate);
        const datePlusThreeMonths = DateTime.now().plus({ months: 3 }).toMillis();

        const endsWithinThreeMonths = endDate < datePlusThreeMonths;

        const subplanDescriptions = enrolment.subplans.map(({ description }) => description);

        const mappedEnrolment: MappedEnrolment = {
          plan: enrolment.plan.description,
          startDate: startDate,
        };

        if (includeSubplans) {
          mappedEnrolment.subplans = subplanDescriptions;
        }

        if (!includeDates) return mappedEnrolment;

        if (endsWithinThreeMonths) {
          mappedEnrolment.endDate = endDate;
        }

        return mappedEnrolment;
      })
  );
}
