import { createColumnHelper } from '@tanstack/react-table';
import { Trans, useTranslation } from 'react-i18next';
import { Icon, Periods } from '@uva-glass/component-library';

import type { GetConstrainedChoiceClusterByIdQuery } from 'types/__generated__';

import styles from './CoursesTable.module.css';
import { RemoveCourseOffering } from './RemoveCourseOffering';

import { DataTable } from 'App/shared/DataTable/DataTable';
import { TABLE_STORAGE_KEYS } from 'util/storagekeys';
import { useAuthorisation } from 'providers/AuthorisationProvider';
import { useCurrentLanguage } from 'hooks/useCurrentLanguage';
import { useGetAcademicYearDetails } from 'hooks/useGetAcademicYearDetails';
import { UserAction } from 'types/__generated__';

type RowData = NonNullable<GetConstrainedChoiceClusterByIdQuery['constrainedChoiceCluster']>['offerings'][number];

interface Props {
  academicYear: number;
  cluster: NonNullable<GetConstrainedChoiceClusterByIdQuery['constrainedChoiceCluster']>;
  departmentId: string;
}

const columnHelper = createColumnHelper<RowData>();

const initialState = { sorting: [{ id: 'course', desc: false }] };

export function CoursesTable({ academicYear, cluster, departmentId }: Props) {
  const { t } = useTranslation('constrained-choices', { keyPrefix: 'course-offerings' });
  const currentLanguage = useCurrentLanguage();
  const userCanEditConstrainedChoiceClusters = useAuthorisation(UserAction.EditConstrainedChoiceClusters, departmentId);
  const academicYearDetails = useGetAcademicYearDetails(academicYear);

  const { offerings } = cluster;

  const columns = [
    columnHelper.accessor(({ info }) => info?.catalogNumber, {
      id: 'catalog-number',
      header: t('table.headers.catalog-number'),
    }),

    columnHelper.accessor(({ info }) => info?.title[currentLanguage], {
      id: 'course',
      header: t('table.headers.course'),
    }),

    columnHelper.accessor(({ info }) => info?.ec, {
      id: 'ec',
      header: t('table.headers.ec'),
    }),

    columnHelper.display({
      id: 'period',
      header: t('table.headers.period'),
      cell: ({ row }) => (
        <div className={styles['courses-table__period']}>
          <Periods
            periods={row.original.periods}
            tooltipTranslations={{ periods: t('periods.periods'), period: t('periods.period'), and: t('periods.and') }}
          />
        </div>
      ),
    }),

    columnHelper.display({
      id: 'actions',
      cell: ({ row }) =>
        userCanEditConstrainedChoiceClusters &&
        !academicYearDetails.isArchived && (
          <RemoveCourseOffering
            clusterName={cluster.title[currentLanguage]}
            clusterId={cluster.id}
            courseOffering={row.original}
            isDisabled={row.original.numberOfPendingStudents > 0}
          />
        ),
      meta: { tableCellProps: { hasButton: true } },
    }),
  ];

  return offerings.length > 0 ? (
    <DataTable
      columns={columns}
      data={offerings}
      initialState={initialState}
      enableColumnFilters={false}
      tableId={TABLE_STORAGE_KEYS.COURSES_TABLE}
    />
  ) : (
    <div className={styles['courses-table__no-courses-content']}>
      <Icon name="InformationCircleFillMini" className={styles['courses-table__no-courses-icon']} />
      &nbsp;
      <Trans
        t={t}
        i18nKey="no-courses"
        context={userCanEditConstrainedChoiceClusters && !academicYearDetails.isArchived ? 'with-editRights' : ''}
      />
    </div>
  );
}
