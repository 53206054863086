import { useMutation } from '@apollo/client';

import type { StoreObject } from '@apollo/client';
import type {
  GetRequirementsByDepartmentIdQuery,
  GetRequirementsByDepartmentIdQueryVariables,
  RemoveRequirementMutation,
  RemoveRequirementMutationVariables,
} from 'types/__generated__';

import { REMOVE_REQUIREMENT } from 'graphql/mutations/removeRequirement';
import { GET_REQUIREMENTS_BY_DEPARTMENT_ID } from 'graphql/queries/getRequirementsByDepartmentId';
import { MutationStatus } from 'types/__generated__';

export const useRemoveRequirement = () =>
  useMutation<RemoveRequirementMutation, RemoveRequirementMutationVariables>(REMOVE_REQUIREMENT, {
    update(cache, { data }) {
      if (data?.removeRequirement.mutationStatus !== MutationStatus.Success) return;

      const { requirementId, academicYear, departmentId } = data.removeRequirement;

      if (!academicYear || !departmentId) {
        throw new Error('No "academicYear" or "departmentId" in payload');
      }
      const requirementToEvict: StoreObject = { __typename: 'Requirement', id: requirementId };

      cache.updateQuery<GetRequirementsByDepartmentIdQuery, GetRequirementsByDepartmentIdQueryVariables>(
        {
          query: GET_REQUIREMENTS_BY_DEPARTMENT_ID,
          variables: { academicYear, departmentId, includeParentDepartments: true },
        },
        (cached) =>
          cached
            ? { requirements: cached.requirements.filter((requirement) => requirement.id !== requirementId) }
            : undefined
      );

      cache.evict({ id: cache.identify(requirementToEvict) });
      cache.gc();
    },
  });
