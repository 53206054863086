import { gql } from '@apollo/client';

import { COURSE_INFO_FRAGMENT } from 'graphql/fragments/courseInfoFragment';
import { PERIOD_FRAGMENT } from 'graphql/fragments/periodFragment';
import { STUDENT_GROUP_FRAGMENT } from 'graphql/fragments/studentGroupFragment';

export const GET_COURSE_OFFERINGS = gql`
  query getCourseOfferings($departmentId: String!, $academicYear: Int!) {
    marblesCourseOfferings(departmentId: $departmentId, academicYear: $academicYear) {
      id
      status
      info {
        ...courseInfo
      }
      topicSet {
        id
        title {
          NL
          EN
        }
      }
      periods {
        ...period
      }
      studentGroups {
        ...studentGroup
      }
      numberOfRegisteredStudents
      numberOfPendingStudents
    }
  }
  ${PERIOD_FRAGMENT}
  ${STUDENT_GROUP_FRAGMENT}
  ${COURSE_INFO_FRAGMENT}
`;
