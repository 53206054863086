import { ButtonGroup } from '@uva-glass/component-library';

import type { InfoRequirement } from 'types/__generated__';

import styles from './InfoRequirementNode.module.css';
import { EditInfoRequirement } from './EditInfoRequirement';
import { RemoveInfoRequirement } from './RemoveInfoRequirement';

import { useCurrentLanguage } from 'hooks/useCurrentLanguage';

interface Props {
  requirement: InfoRequirement;
  ruleId: string;
  canEdit: boolean;
}

export function InfoRequirementNode({ requirement, ruleId, canEdit }: Props) {
  const currentLanguage = useCurrentLanguage();

  return (
    <div className={styles['info-requirement-node']}>
      <div className={styles['info-requirement-node__header']}>
        <strong>{requirement.description[currentLanguage]}</strong>

        {canEdit && (
          <ButtonGroup>
            <EditInfoRequirement ruleId={ruleId} requirement={requirement} />
            <RemoveInfoRequirement ruleId={ruleId} requirement={requirement} />
          </ButtonGroup>
        )}
      </div>
    </div>
  );
}
