import { createContext, useContext, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import type { PropsWithChildren } from 'react';
import type { ToastProps } from 'components/Toast/Toast';

import { ToastContainer } from 'components/ToastContainer/ToastContainer';
import { Toast } from 'components/Toast/Toast';

const ToastContext = createContext<{
  toasts: ToastProps[];
  addToast: (newToast: Omit<ToastProps, 'id'>) => void;
  removeToast: (id: ToastProps['id']) => unknown;
} | null>(null);

export function ToastProvider({ children }: PropsWithChildren) {
  const [toasts, setToasts] = useState<ToastProps[]>([]);

  function addToast(newToast: Omit<ToastProps, 'id'>) {
    setToasts((prev) => [...prev, { id: uuidv4(), ...newToast }]);
  }

  function removeToast(id: ToastProps['id']) {
    setToasts((prev) => prev.filter((toast) => toast.id !== id));
  }

  return (
    <ToastContext.Provider value={{ toasts, addToast, removeToast }}>
      <ToastContainer>
        {toasts.map(({ id, variant, title, content }) => (
          <Toast key={id} id={id} variant={variant} title={title} content={content} />
        ))}
      </ToastContainer>
      {children}
    </ToastContext.Provider>
  );
}

export function useToast() {
  const context = useContext(ToastContext);

  if (!context) throw new Error('useToast can only be used in a ToastProvider');

  return context;
}
