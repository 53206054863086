import { useOverlayTriggerState } from '@react-stately/overlays';
import { Trans, useTranslation } from 'react-i18next';
import { useState } from 'react';
import {
  Button,
  IconButton,
  Icon,
  FeedbackBox,
  ModalDialog,
  ButtonGroup,
  FormField,
} from '@uva-glass/component-library';

import type { FormEvent, ChangeEvent } from 'react';

import styles from './GroupSwapModal.module.css';

import {
  MutationStatus,
  type GetCourseOfferingGroupSwapsByIdQuery,
  type GetGroupsByCourseOfferingIdQuery,
  type GroupSwapRequest,
  type Student,
} from 'types/__generated__';
import { GroupDetails } from 'App/CourseOffering/CourseOfferingGroupSwap/GroupSwapModal/GroupDetails';
import { GroupSelector } from 'App/CourseOffering/CourseOfferingGroupSwap/GroupSwapModal/GroupSelector/GroupSelector';
import { useProcessGroupSwap } from 'App/CourseOffering/CourseOfferingGroupSwap/GroupSwapModal/hooks/useProcessGroupSwap';
import { useGetReadableMutationStatus } from 'hooks/useGetReadableMutationStatus';

type StudentPartial = Pick<Student, 'name' | 'studentNumber'>;

interface Props {
  groupSwapRequestId: GroupSwapRequest['id'];
  student: StudentPartial;
  currentGroup: GetCourseOfferingGroupSwapsByIdQuery['courseOfferingGroupSwaps'][number]['studentGroup'];
  requestedGroupIds: GetCourseOfferingGroupSwapsByIdQuery['courseOfferingGroupSwaps'][number]['requestedStudentGroupIds'];
  allGroups: NonNullable<GetGroupsByCourseOfferingIdQuery['marblesCourseOffering']>['studentGroups'];
  nonSwappable: boolean;
}

export function GroupSwapModal({
  groupSwapRequestId,
  student,
  currentGroup,
  requestedGroupIds,
  allGroups,
  nonSwappable,
}: Props) {
  const { t } = useTranslation('course-offering', { keyPrefix: 'course-offering-group-swap.group-swap-modal' });
  const { close, isOpen, open } = useOverlayTriggerState({});
  const readableMutationStatus = useGetReadableMutationStatus();

  const [error, setError] = useState<string>();
  const [selectedNewGroupId, setSelectedNewGroupId] = useState<string>();
  const [mutate, mutation] = useProcessGroupSwap();

  function resetAndClose() {
    setSelectedNewGroupId('');
    setError('');
    close();
  }

  function onSelect(event: ChangeEvent<HTMLInputElement>) {
    const groupId = event.target.value;

    setError('');
    setSelectedNewGroupId(groupId);
  }

  function onSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (!selectedNewGroupId || selectedNewGroupId.trim() === '') {
      setError(t('no-new-group-error'));
      return;
    }

    mutate({
      variables: { groupSwapRequestId, studentGroupId: selectedNewGroupId },
      onCompleted(data) {
        const { mutationStatus } = data.processGroupSwap;

        if (mutationStatus === MutationStatus.Success) {
          resetAndClose();
          return;
        }

        setError(readableMutationStatus(mutationStatus));
      },
      onError(error) {
        if (error.graphQLErrors.length) {
          setError(readableMutationStatus(MutationStatus.Corrupt));
        } else {
          setError(readableMutationStatus(MutationStatus.Error));
        }
      },
    });
  }

  const { name, studentNumber } = student;

  return (
    <>
      <IconButton aria-label={t('trigger')} onClick={open}>
        <Icon name={nonSwappable ? 'ListMagnify' : 'Swap'} />
      </IconButton>

      <ModalDialog
        title={nonSwappable ? t('title-view') : t('title')}
        buttons={
          <ButtonGroup>
            <Button variant="secondary" disabled={mutation.loading} onClick={resetAndClose}>
              {nonSwappable ? t('buttons.close') : t('buttons.cancel')}
            </Button>
            {!nonSwappable && (
              <Button variant="primary" disabled={mutation.loading} type="submit">
                {t('buttons.swap-group')}
              </Button>
            )}
          </ButtonGroup>
        }
        isOpen={isOpen}
        onClose={resetAndClose}
        isDismissable={!mutation.loading}
        onSubmit={onSubmit}
      >
        <div className={styles['group-swap-modal__content']}>
          <p className={styles['group-swap-modal__description']}>
            <Trans
              t={t}
              i18nKey={nonSwappable ? 'description-view' : 'description'}
              values={{ name, studentNumber }}
              components={{ strong: <strong /> }}
            />
          </p>

          <div className={styles['group-swap-modal__section']}>
            <h2 className={styles['group-swap-modal__section-header']}>{t('current-group-header')}</h2>
            <div className={styles['group-swap-modal__group-wrapper']}>
              <GroupDetails group={currentGroup} isLocked={currentGroup?.locked || false} isCurrentGroup />
            </div>
          </div>

          <FormField>
            <GroupSelector
              requestedGroupIds={requestedGroupIds}
              allGroups={allGroups}
              onGroupSelect={onSelect}
              showNonSwappable={nonSwappable}
            />
          </FormField>

          {error && <FeedbackBox level="error" feedback={error} />}
        </div>
      </ModalDialog>
    </>
  );
}
