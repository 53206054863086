import { useMutation } from '@apollo/client';
import { useOverlayTriggerState } from '@react-stately/overlays';
import {
  Button,
  ButtonGroup,
  FeedbackBox,
  Icon,
  Label,
  ModalDialog,
  Spinner,
  FormField,
} from '@uva-glass/component-library';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { ChangeEvent, FormEvent } from 'react';
import type {
  UploadCreditLimitExceptionsMutation,
  UploadCreditLimitExceptionsMutationVariables,
} from 'types/__generated__';

import styles from './UploadCreditLimitExceptionsModal.module.css';

import { UploadResults } from 'App/shared/UploadResults/UploadResults';
import { UploadRowErrors } from 'App/shared/UploadRowErrors/UploadRowErrors';
import { Input } from 'components/Input/Input';
import { UPLOAD_CREDIT_LIMIT_EXCEPTIONS } from 'graphql/mutations/uploadCreditLimitExceptions';
import { useGetReadableMissingColumn } from 'hooks/useGetReadableMissingColumns';
import { useGetReadableMutationStatus } from 'hooks/useGetReadableMutationStatus';
import { MutationStatus } from 'types/__generated__';
import { ACCEPTED_FILE_TYPES } from 'util/acceptedFileTypes';

const INPUT_FILE_ID = 'file-upload';
const UPLOAD_FILE_BUTTON_ID = 'file-upload-button';
const INPUT_FILE_ERROR_ID = 'file-upload-error';

type UploadCreditLimitExceptionsResult = UploadCreditLimitExceptionsMutation['uploadCreditLimitExceptions']['result'];
type UploadCreditLimitMissingColumns =
  UploadCreditLimitExceptionsMutation['uploadCreditLimitExceptions']['missingColumns'];

export function UploadCreditLimitExceptionsModal() {
  const { t } = useTranslation('student', { keyPrefix: 'import-credit-limit-exceptions-modal' });
  const readableMutationStatus = useGetReadableMutationStatus();
  const readableMissingColumn = useGetReadableMissingColumn();
  const { close, isOpen, open } = useOverlayTriggerState({});

  const [error, setError] = useState('');
  const [file, setFile] = useState<File>();
  const [results, setResults] = useState<UploadCreditLimitExceptionsResult>([]);
  const [currentMissingColumns, setCurrentMissingColumns] = useState<UploadCreditLimitMissingColumns>(null);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const [mutate, mutation] = useMutation<
    UploadCreditLimitExceptionsMutation,
    UploadCreditLimitExceptionsMutationVariables
  >(UPLOAD_CREDIT_LIMIT_EXCEPTIONS);
  const errorRows = results.filter(({ rowStatus }) => rowStatus !== MutationStatus.Success);
  const successRowCount = results.length - errorRows.length;

  function reset() {
    setFile(undefined);
    setError('');
    setResults([]);
    setCurrentMissingColumns(null);
    mutation.reset();
  }

  function resetAndClose() {
    reset();
    close();
  }

  function onChange(event: ChangeEvent<HTMLInputElement>) {
    setError('');
    const { files } = event.target;

    if (!files) return;

    const file = files[0];

    if (!ACCEPTED_FILE_TYPES.includes(file.type)) {
      setError(t('modal.errors.unsupported-type'));
      if (fileInputRef.current) {
        // reset the value of the <input type="file">
        fileInputRef.current.value = '';
      }
      return;
    }

    setFile(file);
  }

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (!file) {
      setError(t('modal.errors.file-missing'));
      return;
    }

    const { data } = await mutate({
      variables: { file },
    });

    if (!data?.uploadCreditLimitExceptions) {
      setError(readableMutationStatus('unknown'));
      return;
    }

    const { mutationStatus, result, missingColumns } = data.uploadCreditLimitExceptions;

    if (mutationStatus === MutationStatus.Success) {
      setResults(result);
      return;
    }

    if (mutationStatus === MutationStatus.UploadMissingColumns) {
      setCurrentMissingColumns(missingColumns);
      setError(readableMutationStatus(mutationStatus, { details: missingColumns ?? undefined }));
      return;
    }

    setError(readableMutationStatus(mutationStatus));
  }

  return (
    <>
      <div className={styles['upload-credit-limit-exceptions-content']}>
        <label htmlFor={UPLOAD_FILE_BUTTON_ID}>{t('title')}</label>
        <Button id={UPLOAD_FILE_BUTTON_ID} onClick={open} variant="secondary">
          <Icon name="ArrowDownOnSquare" size={20} />
          {t('trigger')}
        </Button>
      </div>

      <ModalDialog
        isDismissable={!mutation.loading}
        isOpen={isOpen}
        noValidate={true}
        onClose={resetAndClose}
        onSubmit={handleSubmit}
        title={t('modal.title')}
        buttons={
          <>
            {!mutation.called && (
              <ButtonGroup reversed>
                <Button variant="primary" type="submit" disabled={mutation.loading}>
                  {t('modal.buttons.import')}
                </Button>

                <Button onClick={resetAndClose} variant="secondary">
                  {t('modal.buttons.cancel')}
                </Button>
              </ButtonGroup>
            )}

            {mutation.called && !mutation.loading && (
              <ButtonGroup reversed>
                <Button onClick={resetAndClose} variant="primary">
                  {t('modal.buttons.close')}
                </Button>
                <Button onClick={reset} variant="secondary">
                  {t('modal.buttons.reset-and-retry')}
                </Button>
              </ButtonGroup>
            )}
          </>
        }
        wide
      >
        {!mutation.called && (
          <>
            <p>{t('modal.description')}</p>

            <FormField outerSpace>
              <Label htmlFor={INPUT_FILE_ID}>{t('modal.choose-file')}</Label>
              <Input
                id={INPUT_FILE_ID}
                type="file"
                accept={ACCEPTED_FILE_TYPES.join(',')}
                onChange={onChange}
                title={t('modal.file-type')}
                aria-describedby={error ? INPUT_FILE_ERROR_ID : undefined}
                ref={fileInputRef}
              />
            </FormField>

            {error && <FeedbackBox id={INPUT_FILE_ERROR_ID} level="error" feedback={error} />}
          </>
        )}

        {mutation.called && mutation.loading && <Spinner ariaValueText={t('results-modal.loading')} />}

        {mutation.called &&
          !mutation.loading &&
          (results.length > 0 ? (
            <UploadResults>
              {successRowCount > 0 && (
                <FeedbackBox level="success" feedback={t('results-modal.success', { count: successRowCount })} />
              )}
              {errorRows.length > 0 && (
                <>
                  <FeedbackBox level="error" feedback={t('results-modal.failure', { count: errorRows.length })} />
                  <UploadRowErrors errorRows={errorRows} />
                </>
              )}
            </UploadResults>
          ) : (
            <>
              {error && <FeedbackBox level="error" feedback={error} />}
              {currentMissingColumns ? (
                <ul>
                  {currentMissingColumns.map((column, index) => (
                    <li key={index}>{readableMissingColumn(column)}</li>
                  ))}
                </ul>
              ) : (
                <p>{t('results-modal.nothing-imported')}</p>
              )}
            </>
          ))}
      </ModalDialog>
    </>
  );
}
