import { useOverlayTriggerState } from '@react-stately/overlays';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button, IconButton, Icon, FeedbackBox, ModalDialog, ButtonGroup } from '@uva-glass/component-library';

import type { FormEvent } from 'react';
import type { GetRuleByIdQueryVariables, Plan, RequirementNode } from 'types/__generated__';

import { useGetReadableMutationStatus } from 'hooks/useGetReadableMutationStatus';
import { useRemoveRequiredPlan } from 'hooks/useRemoveRequiredPlan';
import { MutationStatus } from 'types/__generated__';

interface Props {
  isDisabled?: boolean;
  plan: Plan;
  ruleId: GetRuleByIdQueryVariables['ruleId'];
  nodeId: RequirementNode['id'];
}

export function RemoveRequiredPlan({ isDisabled, plan, ruleId, nodeId }: Props) {
  const { t } = useTranslation('requirement-rules', { keyPrefix: 'remove-required-plan' });

  const [error, setError] = useState<string>();
  const { open, close, isOpen } = useOverlayTriggerState({});
  const readableMutationStatus = useGetReadableMutationStatus();

  const [mutate, mutation] = useRemoveRequiredPlan();

  function onCompleted() {
    setError(undefined);
    close();
  }

  function onSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    mutate({
      variables: { ruleId, nodeId },
      onCompleted(data) {
        const { mutationStatus } = data.removeRequiredPlan;

        if (mutationStatus === MutationStatus.Success) {
          onCompleted();
          return;
        }

        setError(readableMutationStatus(mutationStatus));
      },
      onError() {
        setError(readableMutationStatus('unknown'));
      },
    });
  }

  return (
    <>
      <IconButton aria-label={t('trigger')} onClick={open} variant="destructive" disabled={isDisabled}>
        <Icon name={isDisabled ? 'TrashStrikethrough' : 'Trash'} />
      </IconButton>

      <ModalDialog
        title={t('modal.title')}
        isOpen={isOpen}
        onClose={onCompleted}
        onSubmit={onSubmit}
        buttons={
          <ButtonGroup>
            <Button onClick={onCompleted} variant="secondary">
              {t('modal.buttons.cancel')}
            </Button>
            <Button variant="destructive-primary" disabled={mutation.loading} type="submit">
              {t('modal.buttons.remove')}
            </Button>
          </ButtonGroup>
        }
      >
        <p>
          <Trans
            t={t}
            i18nKey="modal.description"
            values={{ name: plan?.description }}
            components={{ strong: <strong /> }}
          />
        </p>
        {error && <FeedbackBox level="error" feedback={error} />}
      </ModalDialog>
    </>
  );
}
